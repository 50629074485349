import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  root: {
    marginBottom: '10px',
    border: "none",
    backgroundColor: "#FFF",
    fontFamily: "'DM Mono', monospace",
    letterSpacing: "1.5px",
    '& div': {
      height: "32px",
      '& input': {
        padding: "18.5px 35px",
        color: "#8F95A3",
        fontSize: "14px",
      }
    },
    '& label': {
      transform: "translate(35px, 9px) scale(1);",
      color: "#8F95A3",
      fontSize: "14px"
    }
  },
  searchIcon: {
    top: '6px',
    left: '6px',
    position: 'absolute',
    fontSize: "22px",
    zIndex: "1"
  },
  clearIcon: {
    color: "#383A3D",
    position: "absolute",
    right: "11px",
    top: "5px",
    cursor: "pointer",
    fontSize: "23px"
  }
});

export default useStyles;

