import { Accordion, AccordionDetails, AccordionSummary, Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, ListItem, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { chunk, isEmpty, map, min, toUpper } from 'lodash';
import React from 'react'
import reactStringReplace from 'react-string-replace';
import SearchTextField from '../../../SearchTextField';
import { EXTRACTED_CRITERIAS, keywords } from '../constants';
import useStyles, { ItemDate, ItemName, TooltipBlock, TooltipBlockSmall } from '../styles';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const DialogEncontros = ({
    modalContent,
    onClickCriterioExtraido,
    modalDate,
    setEncontroModalOpen,
    encontroModalOpen,
    items=[]
})=>
{
    console.log(modalContent)
    const [textSearchHighlight, setTextSearchHighlight] = React.useState('');
    const clearInputHighlight = () => {
        setTextSearchHighlight('')
    }
    const setHighlight = (text) => { 

        const terms = [
            textSearchHighlight,
            ...keywords
        ]; 
        if(!text) return <></>;
        // quebra o texto em array
        let splitedText = text.split(/(\[\w+\(\w\)\s\w+\s\w+\])|(\[\w+\(?\w?\)?\])/gim)
        // remove os undefineds
        splitedText = splitedText && splitedText.filter((item) => item);
        let indexedText = {};
        const regex = new RegExp(`(${terms.filter(item => item).join('|')})`, 'g');
        chunk(splitedText,2).forEach((item,i)=>{
            if(!indexedText[item[0]]) indexedText[item[0]] = ''
            indexedText[item[0]] += '\n'+item[1] + '\n--------\n'
        })
        return <>
            {map(indexedText,(value, key)=> (
                <Accordion className={classes.accordion}> 
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                    {reactStringReplace(value, regex,
                        (match, i) =>
                        <Typography
                            key={'text.highlight.'+i}
                            display="inline"
                            style={{backgroundColor: keywords.includes(match) ?  "yellow" : '#16B8A760'}}
                        >
                        {match}
                        </Typography>
                    )}
                    </AccordionDetails>
                </Accordion>
            ))}
        </> 
    }
    const classes = useStyles();
    if(!modalContent) return <></>
    return (
        <Dialog
            open={encontroModalOpen}
            onClose={() => setEncontroModalOpen(false)}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle className={classes.modalTitle} id="scroll-dialog-title">
            <Grid container>
                <Grid item md={12}>
                <Box display="flex" mb={2}>
                    <Box width="100%">
                    {modalContent.local_encontro ? modalContent.local_encontro : ''}
                    {modalContent.source ? modalContent.source.local_encontro : ''}
                    <ItemDate style={{marginLeft: '15px'}}>{modalDate}</ItemDate>
                    </Box>
                    <IconButton onClick={() => setEncontroModalOpen(false)} style={{padding: '3px', borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.04)'}} aria-label="close">
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>
                </Grid>
                <Grid item md={12}>
                <form noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
                    <SearchTextField label="Busca por palavra-chave"
                    value={textSearchHighlight}
                    onChange={e => setTextSearchHighlight(e.target.value)}
                    fullWidth={true} clearInput={clearInputHighlight}
                    />
                </form>
                </Grid>
            </Grid>
            </DialogTitle>
            <DialogContent component={Box}>
            <Box display="flex" flexDirection="column">
                { 
                !isEmpty(modalContent.criterios_extraidos_json?.peep) ||
                !isEmpty(modalContent.criterios_extraidos_json?.spo2) ||
                !isEmpty(modalContent.criterios_extraidos_json?.fio2) ?
                    <ListItem>
                    <TooltipBlock>
                        <ListItem key="criterios">
                        <TooltipBlock>
                            <ItemName><ItemDate> {toUpper('critérios extraídos')}</ItemDate></ItemName>
                            <Box display="flex" justifyContent={'space-between'}>
                            {EXTRACTED_CRITERIAS.filter(filteredCriteria => !isEmpty(modalContent.criterios_extraidos_json[filteredCriteria])).map( (criteria,index) => (
                            <Box>
                                <TooltipBlockSmall
                                    key={`Tooltip.CriteriosExtraidos.${index}`}
                                    onClick={() => onClickCriterioExtraido(modalContent, criteria)}
                                >
                                <Box display="flex" alignItems="middle">
                                    <ItemName>
                                    {`${criteria}: `}
                                    {min(modalContent.criterios_extraidos_json[criteria])}
                                    {criteria !== 'peep' ? '%' : ''}
                                    </ItemName>
                                    <AssessmentIcon  />
                                </Box>
                                </TooltipBlockSmall> 
                            </Box>
                            ))}
                            </Box>
                        </TooltipBlock>
                        </ListItem>
                    </TooltipBlock>
                    </ListItem> 
                : '' 
                }
                <ListItem>
                <TooltipBlock>
                    <ItemName style={{whiteSpace: "pre-wrap"}}>{
                    setHighlight(modalContent.termosTexto)
                    }</ItemName>
                </TooltipBlock>
                </ListItem>
            </Box>
            </DialogContent>
        </Dialog>
    )
}