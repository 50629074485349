import React from 'react'
import { Box, RootRef } from '@material-ui/core'

export const TimelineDrawerPanel = ({ toolbar, children, contentRef }) => {
  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <Box flex='0 0 auto'>
        {toolbar}
      </Box>
      <Box flex='1 1 auto' overflow='auto' ref={contentRef}>
        {children}
      </Box>
    </Box>
  )
}
