import React, { useMemo } from 'react';
import { VerticalBarChart } from '../VerticalBarChart';
import { getIndicatorValue } from '../../../../../utils/formatters/formatDashboardIndicators'

import { useTheme } from '@material-ui/core';
import { useSelectedCompetence } from '../../slices';

const randomValue = () => Math.floor(Math.random() * 10 * (Math.random() * 20));

export const InfectionsBySex = ({ indicators }) => {
  const competence = useSelectedCompetence()

  const theme = useTheme();
  const isLoading = false;
  const dataset = useMemo(
    () => [
      {
        label: 'HOMENS',
        color: theme.palette.timeline.secondary.blue,
        values: [
          {
            label: 'CONFIRMADO',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmadas_masculino', competence),
          },
          {
            label: 'NÃO CONFIRMADO',
            value: getIndicatorValue(indicators, 'infeccoes_confirmadas_masculino', competence),
          },
        ],
      },
      {
        label: 'MULHERES',
        color: theme.palette.timeline.secondary.purple,
        values: [
          {
            label: 'CONFIRMADO',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmadas_feminino', competence),
          },
          {
            label: 'NÃO CONFIRMADO',
            value: getIndicatorValue(indicators, 'infeccoes_confirmadas_feminino', competence),
          },
        ],
      },
    ],
    [theme, competence, indicators]
  );

  return (
    <VerticalBarChart
      isLoading={isLoading}
      dataset={dataset}
      aspectRatio={1}
      // height={200}
    />
  );
};
