import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { professionalOrderWeightENUM } from '../../../../constants/professionalOrderWeight';
import { sortObjectByExternalWeight } from '../../../../utils';
import { selectTimelineEncontroActive } from './selectTimelineEncontroActive';
import { parseTextoEncontro } from '../../../../utils/parseTextoEncontro';

const memoEmpty = {};
export const selectTimelineEncontrosAccordion = createSelector(
  selectTimelineEncontroActive,
  (encontro) => {
    console.log(encontro);
    const text = {
      termos_texto: encontro?.termos_texto,
      texto_evolucao_agg: encontro?.texto_evolucao_agg
    };

    if (!text.termos_texto && !text.fst_evo_medica) return memoEmpty;

    const indexedText = parseTextoEncontro(text);

    const accordion = sortObjectByExternalWeight(indexedText)(
      professionalOrderWeightENUM,
      professionalOrderWeightENUM['[DEMAIS]']
    );

    return accordion;
  }
);
export const useTimelineEncontrosAccordion = () =>
  useSelector(selectTimelineEncontrosAccordion);
