import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import useStyles from './styles';
import ISACard from '../ISACard';
import Slider from '@material-ui/core/Slider';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

const InfectionTypeFormField = (props) => {
  const { infection, changeData, values, isLoading } = props;
  const MAX_VALUE = 100;
  const classes = useStyles();

  function valuetext(value) {
    return `${value}%`;
  }

  const marks = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];

  const [value, setValue] = useState(values[infection]);

  useEffect(() => {
    setValue(values[infection]);
  }, [values])

  const handleChange = (event, newValue) => {
    if (isEqual(newValue[0], 0)) {
      newValue[0]++;
    }
    if (isEqual(newValue[1], 100)) {
      newValue[1]--;
    }

    setValue(newValue);
    changeData(infection, newValue);
  };

  return (
    <Grid item xs={12} sm={12} lg={6}>
      <ISACard title="Esse controle mudará os valores das infecções" loading={isLoading}>
        <Grid item className={classes.itemPercentages} container direction="row" justify="space-evenly">
          <Typography variant="body1">Baixo: 0% - {values.geral[1]}%</Typography>
          <Typography variant="body1">Médio: {values.geral[1]}% - {values.geral[0]}%</Typography>
          <Typography variant="body1">Alto: {values.geral[0]}% - {MAX_VALUE}%</Typography>
        </Grid>
        <Grid className={classes.itemContainer} container>
          <Slider
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            classes={{rail: classes.rail, track: classes.track, thumb: classes.thumb, valueLabel: classes.valueLabel}}
            step={1}
            marks={marks}
            min={0}
            max={100}
            value={value}
            onChange={handleChange}
          />
        </Grid>
      </ISACard>
    </Grid>
  );
};

InfectionTypeFormField.propTypes = {
  infection: PropTypes.string.isRequired,
  changeData: PropTypes.func.isRequired,
  values: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
});

export default connect(mapStateToProps, null)(InfectionTypeFormField);
