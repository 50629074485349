import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { selectHistoryInfections } from './selectHistoryInfections';
import { selectHistoryPatientName } from './selectHistoryPatientName';
import { selectHistoryOrderBy } from './selectHistoryOrderBy';
import { selectHistoryPage } from './selectHistoryPage';
import { selectHistoryItemsPerPage } from './selectHistoryItemsPerPage';
import { selectHistoryAssessment } from './selectHistoryAssessment';
import { selectHistoryLocation } from './selectHistoryLocation';
import { selectHistoryEvaluator } from './selectHistoryEvaluator';
import {
  selectEndDate,
  selectStartDate,
} from '../../../../../templates/DateRangeTemplate';
import { infectionTypesEnum } from '../../../../../constants/infections';

const assessmentStatus = {
  'all': undefined,
  'positiva': 'positiva',
  'negativa': 'negativa',
  'comunitaria': 'comunitaria'
}

export const selectHistoryFilters = createSelector(
  [
    selectHistoryInfections,
    selectHistoryPatientName,
    selectHistoryPage,
    selectHistoryItemsPerPage,
    selectHistoryOrderBy,
    selectHistoryAssessment,
    selectHistoryLocation,
    selectHistoryEvaluator,
    selectStartDate,
    selectEndDate,
  ],
  (
    infections,
    name,
    page,
    itemsPerPage,
    orderBy,
    assessment,
    location,
    evaluator,
    startDate,
    endDate
  ) => {
    const isCommunity = assessmentStatus[assessment] === infectionTypesEnum.comunitaria;

    const getFormStatus = () => {
      if(isCommunity) return assessmentStatus['positiva']
      if(assessment) return assessmentStatus[assessment]

      return assessmentStatus.all;
    }

    const getInfectionTypes = () => {
      if(isCommunity) return [infectionTypesEnum.comunitaria]
      // Se o filtro de avaliações for positiva, não deve retornar infecções comunitárias confirmadas
      if(assessment === assessmentStatus['positiva']) return infections.filter(infection => infection !== 'comunitaria')

      return infections
    }

    return {
      infectionType: getInfectionTypes(),
      itensPerPage: itemsPerPage,
      name,
      page,
      location,
      evaluator,
      orderBy,
      startDate,
      endDate,
      status: getFormStatus()
  }}
);
export const useHistoryFilters = () => useSelector(selectHistoryFilters);
