import { uniq } from 'lodash';

export const availableVitalSigns = [
  {
    short: 'TEMP',
    fullName: 'Temperatura',
    unit: 'GRAUS CELSIUS',
  },
  {
    short: 'FR',
    fullName: 'Frequência Respiratória',
    unit: 'MPM',
  },
  {
    short: 'PAS',
    fullName: 'Pressão Arterial Sistólica',
    unit: 'mmHg',
  },
  {
    short: 'FC',
    fullName: 'Frequencia cardíaca',
    unit: 'BPM',
  },
  {
    short: 'OXIMETRIA',
    fullName: 'Oximetria',
    unit: '%',
  },
  {
    short: 'PAD',
    fullName: 'PAD',
    unit: 'mmHg',
  },
  {
    short: 'HGT',
    fullName: 'HGT',
    unit: 'MGDL',
  },
  {
    short: 'DIUR',
    fullName: 'DIUR',
    unit: 'Mililitros',
  },
  {
    short: 'DOR',
    fullName: 'DOR',
    unit: 'Valor',
  },
  {
    short: 'PAM',
    fullName: 'Pressão Arterial Média',
    unit: 'mmHg',
  },
  {
    short: 'O2',
    fullName: 'O2',
    unit: 'LITROS/MIN',
  },
  {
    short: 'PA',
    fullName: 'PA',
    unit: 'mmHg',
  },
  {
    short: 'PA.',
    fullName: 'PA.',
    unit: 'mmHg',
  },
];
export const EXTRACTED_CRITERIAS = ['peep', 'fio2'];

export const keywords = [
  'vesical',
  'ventilatório',
  'ventilação',
  'venoso',
  'urocultura',
  'urináriourinária',
  'urgência',
  'tremor',
  'tosse',
  'temperatura',
  'taquicardia',
  'SVD',
  'supuração',
  'suprapúbica',
  'spo2',
  'sondagem',
  'sibilos',
  'sibilância',
  'séptico',
  'sepse',
  'SECREÇÃO',
  'rubor',
  'ronco',
  'purulenta',
  'Pseudomembranosa',
  'pleural',
  'pneumonia',
  'piúria',
  'periférico',
  'peep',
  'pav',
  'oxigênio',
  'opacidade',
  'oligúria',
  'O2',
  'nitrito',
  'miccional',
  'mediastinite',
  'mecânica',
  'leucopenia',
  'leucocitúria',
  'endoftalmite',
  'eritema',
  'febre',
  'fibrose',
  'fio2',
  'foco',
  'hemocultura',
  'hiperemia',
  'hipotensão',
  'hipotermia',
  'infecção',
  'infiltrado',
  'intubação',
  'leucocitose',
  'abscesso',
  'flogístico',
  'dispneia',
  'dispnéia',
  'secrecao',
  'secretivo',
  'dessaturacao',
  'dessaturação',
  'taquipneia',
  'taquipnéia',
  'pus',
  'coleção',
  'colecao',
  'ferida operatoria',
  'ferida operatória',
  'fo',
  'cvp',
  'cateter venoso central',
  'cvc',
  'svd',
  'vm',
  'curativo',
  'flogisticos',
  'flogísticos',
  'pneumonia',
  'BCP',
  'PNM',
  'ardência',
  'disúria',
  'disuria',
  'drenagem',
  'aspiracao',
  'aspiração',
  'diurese',
  'disúria',
  'disuria',
  'ventilacao mecanica',
  'hipotenso',
  'taquicárdico',
  'taquicardico',
  'infeccoes',
  'exsudato',
  'opacidade',
  'infiltrado',
  'cavitação',
  'derrame pleural',
  'abscesso',
  'coleção',
  'secreção purulenta',
  'cnaf',
  'alto fluxo',
  'schilley',
  'sonda',
  'Antibioticos',
  'Amoxicilina',
  'Amoxicilina clavulanato',
  'Ampicilina',
  'Ampicilina sulbactam',
  'Oxacilina',
  'Penicilina',
  'Penicilina benzatina',
  'Penicilina G Potassica',
  'Piperacilina tazobactam',
  'Cefalexina',
  'Cefalotina',
  'Cefazolina',
  'Cefepime',
  'Cefepima',
  'Cefotaxima',
  'Cefoxitina',
  'Ceftazidima',
  'Ceftazidima avibactam',
  'Ceftriaxona',
  'Cefuroxima',
  'Doxiciclina',
  'Linezolida',
  'Azitromicina',
  'Claritromicina',
  'Eritromicina',
  'Ciprofloxacino',
  'Levofloxacino',
  'Norfloxacino',
  'Polimixina B',
  'Tigeciclina',
  'Amicacina',
  'Gentamicina',
  'Clindamicina',
  'Metronidazol',
  'Daptomicina',
  'Vancomicina',
  'Teicoplanina',
  'Nitrofurantoina',
  'Sulfadiazina',
  'Sulfametoxazol trimetoprim',
  'Meropenem',
  'Ertapenem',
  'Imipenem',
  'Aciclovir',
  'Oseltamivir',
  'Anfotericina B',
  'Fluconazol',
  'Anidulafungina',
  'Micafungina',
  'Voriconazol',
  'Metronidazol',
  'Rifampicina',
  'ayre',
  'fralda',
  'portocath',
  'monolumen',
  'duplo lumen',
  'deiscência',
  'extubação',
  'extubado',
  'papagaio',
  'comadre',
  'neutropenia',
  'cnaf',
  'cateter nasal de alto fluxo',
]

export const regexKeywords = new RegExp(
  `(${keywords.filter((item) => item).join('|')})`,
  'gim'
);

export const createRegexKeywords = (keywordsList = keywords) =>
  new RegExp(
    `(${uniq(
      keywordsList
        .filter((k) => (typeof k === 'string' ? !!k : !!k?.keyword))
        .map((k) =>
          typeof k === 'string'
            ? `\\b\\w*${k.replaceAll("|", "\\w*\\b|")}\\w*\\b`
            : `${k.keyword}`
        )
    ).join('|')})`,
    'gim'
  );
