import { createSelector } from '@reduxjs/toolkit';
import { mapValues, groupBy, uniq, flatMap } from 'lodash';
import { useSelector } from 'react-redux';
import { selectTimelineIndicators } from './selectTimelineIndicators';

/**
 * Todos os indicadores por data
 */
export const selectTimelineIndicatorsDates = createSelector(
  selectTimelineIndicators,
  (groupedIndicators) => {
    if (!groupedIndicators) return;

    const groupedDates = mapValues(groupedIndicators, (items) =>
      groupBy(items, '_meta.date')
    );

    if (groupedIndicators.suspeitas) {
      const dates = uniq(
        flatMap(groupedIndicators.suspeitas, '_meta.dateRange')
      );
      groupedDates.suspeitas = Object.fromEntries(
        dates.map((date) => [
          date,
          groupedIndicators.suspeitas.filter((suspicion) =>
          suspicion._meta.dateRange.includes(date)
          ),
        ])
      );
    }

    return groupedDates;
  }
);
export const useTimelineIndicatorsDates = () => {
  return useSelector(selectTimelineIndicatorsDates);
};
