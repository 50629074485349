import React, { useMemo } from 'react';
import { useGetSettingsQuery } from '../../../services';
import { probabilityLevelsEnum } from '../../../constants/probabilities';
import {
  formatInfectionLabel,
  formatProbabilityLevel,
  formatInfectionProbability,
} from '../../../utils/formatters';
import { Chip } from './Chip';

const chipProbabilityLevelVariantMap = {
  [probabilityLevelsEnum.ALTA]: 'high',
  [probabilityLevelsEnum.MEDIA]: 'med',
  [probabilityLevelsEnum.BAIXA]: 'low',
};

export const InfectionChip = (props) => {
  const { infection, probability, ...chipProps } = props;

  const { data: settings = { alta: 100, media: 75, baixa: 50 } } =
    useGetSettingsQuery('');

  const label = useMemo(
    () =>
      typeof probability === 'number'
        ? formatInfectionProbability(infection, probability)
        : formatInfectionLabel(infection),
    [infection, probability]
  );

  const variant = useMemo(
    () =>
      typeof probability === 'number'
        ? chipProbabilityLevelVariantMap[
            formatProbabilityLevel(settings, probability)
          ]
        : undefined,
    [settings, probability]
  );

  return <Chip label={label} variant={variant} {...chipProps} />;
};
