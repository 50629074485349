import React from 'react';
import { Box } from '@material-ui/core';
import { useTimelineActiveIndicator } from '../../../slices';
import { PaperFact } from '../../../../../components/molecules/PaperFact';

export const TimelineDrawerLaudos = () => {
  const items = useTimelineActiveIndicator();
  const highlight = items.some((item) => item.criterio === 'sim');

  return (
    <Box padding={2}>
      {items.map((item, index) => (
        <PaperFact
          key={`laudo.${index}`}
          theme={item.criterio === 'sim' ? 'danger' : 'default'}
          title={item.descricao_laudo}
          content={item.laudo_texto}
          highlight={highlight}
        />
      ))}
    </Box>
  );
}
