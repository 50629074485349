import { formatProbability, formatProbabilityLevel } from '../../../../../../utils/formatters';
import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';
import upperCase from 'lodash/upperCase';

const captalizedNames = ['geral', 'outra', 'traqueo'];

export function formatItems(indicators, suspicions, settings) {
  const generalIndicator = {
    label: 'Geral',
    value: formatProbability(indicators.prob_perc),
    level: formatProbabilityLevel(settings, indicators.prob_perc),
  };

  const formattedSuspicions = suspicions.map((suspicion) => {
    const value = formatProbability(suspicion.probability);
    const level = formatProbabilityLevel(settings, suspicion.probability);
    const label = captalizedNames.includes(suspicion.name)
      ? upperFirst(lowerCase(suspicion.name))
      : upperCase(suspicion.name);

    return { label, value, level };
  });

  return [ generalIndicator, ...formattedSuspicions ];
}
