import React, { useMemo, useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';

import { DropMenu, DropMenuItem } from '../../../components/molecules/DropMenu';
import { Chip } from '../../../components/atoms/Chip';
import {
  formatSuspicionLabel,
  formatDateRange,
} from '../../../utils/formatters';

import { useGetPatientHospitalizationQuery } from '../../../services';

import { TimelineNavigation } from './TimelineNavigation';
import { TimelineTitle } from './TimelineTitle';
import { PatientCovidIcon } from './PatientCovidIcon';
import {
  useTimelineIndicator,
  useTimelinePatientId,
  useTimelinePatientDetails,
} from '../slices';
import { IndicatorsTitle } from '../TimelineIndicators';
import { PatientNeutropeniaIcon } from './PatientNeutropeniaIcon';
import { PatientGmrIcon } from './PatientGmrIcon';
import { useHistory } from 'react-router-dom';
import {LoggedUserCompaniesForm} from "../../../components/organisms/LoggedUserCompaniesForm"

export const TimelineHeading = React.memo(({ setIsLoadingPatientHospitalization, companyId }) => {
  let history = useHistory();

  const patientId = useTimelinePatientId();
  const patient = useTimelinePatientDetails();

  const patientName = patient?.nome || '';
  const patientAge = patient?.idade_hoje || '';

  const suspicions = useTimelineIndicator('suspeitas');
  const [selectedSuspicion, setSelectedSuspicion] = useState(suspicions?.[0]);

  const { data: patientHospitalizations, error: patientHospError, isLoading: isLoadingPatientHosp } = useGetPatientHospitalizationQuery(
    patientId,
    {
      skip: !patientId,
    }
  );

  const hospitalization = useMemo(() => {
    if (!patientHospitalizations || !selectedSuspicion) {
      return;
    };
    // return patientHospitalizations[0]
    return patientHospitalizations.find(
      (hospitalization) =>
        hospitalization.id === selectedSuspicion.id &&
        !!hospitalization.internacao
    );
  }, [patientHospitalizations, selectedSuspicion]);

  useEffect(() => {
    if(!isLoadingPatientHosp) {
      setIsLoadingPatientHospitalization(false);
    }
    if(!!patientHospError && patientHospError.status === 404) {
      history.push("/nao-permitido")
    }
  }, [patientHospError, isLoadingPatientHosp]);

  useEffect(() => {
    if (!suspicions?.length) {
      setSelectedSuspicion(undefined);
    } else {
      setSelectedSuspicion(suspicions?.[0]);
    }
  }, [suspicions, setSelectedSuspicion]);

  return (
    <Grid container alignItems="flex-start" spacing={2}>
      <Grid item>
        <TimelineNavigation patientId={patientId} />
      </Grid>
      <Grid item>
        <Box mb={1}>
          <TimelineTitle>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              {patientName && patientAge && `${patientName} - ${patientAge} ANOS`}
              <PatientGmrIcon />
              <PatientNeutropeniaIcon />
            </Box>
          </TimelineTitle>
        </Box>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {!!suspicions?.length && (
            <Box display="flex" alignItems="center">
              <Box mr="8px">
                <IndicatorsTitle style={{ fontSize: '13px' }}>
                  Suspeitas:
                </IndicatorsTitle>
              </Box>
              <Box display="flex" alignItems="center">
                <Chip
                  label={formatSuspicionLabel(
                    selectedSuspicion || suspicions[0],
                    true
                  )}
                  style={{
                    marginRight: '4px',
                  }}
                />
                {suspicions.length > 1 && (
                  <DropMenu
                    buttonProps={{
                      component: Chip,
                      label: `+${suspicions.length - 1}`,
                    }}
                    menuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                  >
                    {suspicions.map((suspicion, i) => (
                      <DropMenuItem
                        key={suspicion.id}
                        title={formatSuspicionLabel(suspicion, true)}
                        selected={suspicion.id === selectedSuspicion?.id}
                        onClick={() => setSelectedSuspicion(suspicion)}
                      />
                    ))}
                  </DropMenu>
                )}
              </Box>
            </Box>
          )}
          <Box mx={1} color="grey.50">
            |
          </Box>
          {/* TODO: Data da internação */}
          {!!hospitalization && (
            <Box display="flex" alignItems="center">
              <Box mr="8px">
                <IndicatorsTitle style={{ fontSize: '13px' }}>
                  Internação:
                </IndicatorsTitle>
              </Box>
              <Box display="flex" alignItems="center">
                <Chip
                  label={formatDateRange(
                    {
                      start: hospitalization.internacao.DTHRAtendimentoParsed,
                      end: hospitalization.internacao.DTHRAltaParsed,
                    },
                    true
                  )}
                  mr="4px"
                />
              </Box>
            </Box>
          )}
          <Box mx={1} color="grey.50">     |
          </Box>
          {!!companyId && 
            (
              <LoggedUserCompaniesForm variant="dense" companyId={companyId} ></LoggedUserCompaniesForm>
            )
          }
        </Box>
        
      </Grid>
    </Grid>
  );
})
