import React from 'react';
import { Button, Box } from '@material-ui/core';
import { RiAlertFill } from 'react-icons/ri'
import { FeedbackMessage } from '../../../atoms/FeedbackMessage';
import { Dialog, DialogToolbar } from '../../../molecules/Dialog';

export const AssessmentAnalysisFormAlert = (props) => {
  const { open, onCancel, onConfirm, loading, alertText, hideSendButton } = props;

  return (
    <Dialog
      loading={loading}
      title=""
      open={open}
      onClose={onCancel}
      maxWidth="sm"
      footer={
        <DialogToolbar borderTop>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outlined" color="primary" onClick={onCancel}>
              Cancelar
            </Button>
            {!hideSendButton && <Button
              variant="contained"
              color="primary"
              onClick={onConfirm}
              disabled={loading}
            >
              Sim, enviar
            </Button>}
          </Box>
        </DialogToolbar>
      }
    >
      <FeedbackMessage
        title="Alerta!"
        subtitle={alertText}
        icon={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin="auto"
            bgcolor="#ff943013"
            width="80px"
            height="80px"
            borderRadius="60px"
          >
            <RiAlertFill color='#ff9430' size={30} />
          </Box>
        }
      />
    </Dialog>
  );
};
