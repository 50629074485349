import { withStyles } from '@material-ui/core';
import React from 'react';
import { useParseTermosAchados } from '../../../../../utils/parseTermosAchados';
import { useSetTimelineSearchWord } from '../../../slices';
import {
  IndicatorTermsList,
  useIndicatorTerms,
} from '../../IndicatorTermsList';
import { ExtractedCriteria } from './ExtractedCriteria';

export const LocationTitle = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '17px',
    color: theme.palette.neutral.black['72'],
    marginBottom: '8px',
  },
}))(({ classes, ...props }) => <div {...props} className={classes.root} />);

export const TermsEvolucao = ({ item }) => {
  const {
    _meta: { date, indicator },
    local_encontro: location,
    termos_texto: text,
    termos_achados,
  } = item;

  // Termos retornados pela IA
  const aiKeywords = useParseTermosAchados(termos_achados);

  const terms = useIndicatorTerms({ aiKeywords, text });

  const setTimelineSearchWord = useSetTimelineSearchWord();

  return (
    <>
      <LocationTitle mb={1}>{location}</LocationTitle>
      <ExtractedCriteria encontro={item} />
      <IndicatorTermsList
        date={date}
        indicator={indicator}
        terms={terms}
        onClick={setTimelineSearchWord}
      />
    </>
  );
};
