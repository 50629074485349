import React, {useCallback, useEffect, useRef, useState} from "react";
import {connect} from 'react-redux';
import ReactDOM from 'react-dom';
import moment from 'moment';
import {isEmpty, filter, isNil, isEqual, find, toUpper, min, chain} from 'lodash';
import {patientVitalSignalsHistogramDataAction} from '../../../../actions/PatientActions';
import {togglePtientHistogramDialog} from '../../../../actions/PatientActions';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid'; 
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {
  TitleDate,
  TooltipContainer,
  List,
  ListItem,
  ItemName,
  ItemPercentual,
  ItemDate,
  Title,
  ItemText,
  TooltipBlock, TooltipContent,
  ItemHint,
  Tag, TooltipBlockSmall, ItemTextSmall, ItemDateSmall, ChipPositivo, ChipPositivoInline, TooltipGraphItem
} from "./styles";
import GraphDialogComponent from "../../../organisms/GraphDialog";
import HistogramChart from "../../HistogramChart";
import useStyles from './styles';
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useGetPatientFactsQuery } from "../../../../services/patient/patient.service";
import { useTimelinePatientId } from "../../../../pages/Timeline/slices";
import { availableVitalSigns, EXTRACTED_CRITERIAS } from "./constants";
import { filterGraphData, formatTags, parseGraphData } from "./helpers";
import { DialogEncontros } from "./DialogEncontros";
const TooltipDefault = (props) => {
  console.log('TooltipDefault')
  // Required
  const {
    label,
    date,
    items,
    sinaisVitais = [], 
    setPatientHistogram,
    onClickEvent, 
    openModal = false,
  } = props;
  const id = useTimelinePatientId();
  const {
      data: patientFacts,
      isLoading: patientFactsLoading,
  } = useGetPatientFactsQuery(id, { skip: !id });

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const HIGHLIGHT_TAGS = ['covid'];
  const HIGHTLIGHT_TAG_PROPS = {
    color: '#FE3C4C',
    backgroundColor: 'rgba(254, 60, 76, 0.1)',
    border: '1px solid rgba(254, 60, 76, 0.2)'
  };

  
  setPatientHistogram(date);

  const tooltipListRef = useRef(null);
  // const [tooltipDialog, setTooltipDialog] = useState(<></>);
  const [open, setOpen] = useState(false);
  const [dataset, setDataset] = useState([]);
  const [dialogTitle, setDialogTitle] = useState('');
  const [changedDate, setChangedDate] = useState(new Date());
  const [unimedida, setUnimedida] = useState('');
  const [encontroModalOpen, setEncontroModalOpen] = useState(openModal);
  const [modalContent, setModalContent] = useState('');
  const [modalDate, setModalDate] = useState('');
  const [title, setTitle] = useState(label);

  const filterVitalSigns = type => sinaisVitais.filter((sinal) => sinal._source.tipo_sinal === type)

  /**
   * Fecha o modal
   */
  const handleClose = () => {
    // setTooltipDialog(null);
    setOpen(false);

    tooltipListRef.current.focus();
  }


  useEffect(() => {
    if(openModal) {
      handleModalOpen('evolução', items[0]?.startAt)
    }
  }, [openModal])
  const handleRemoveTooltip = () => {
    const component = ReactDOM.findDOMNode(
      document.getElementById('rootTooltip')
    );
    ReactDOM.unmountComponentAtNode(component);
  }
  const handleModalOpen = (label, date) => {
    switch (label) {
      case 'encontros':
        const encontro = items.find((fato) =>
          moment(fato.source.dt_encontro).isSame(date)
        );
        setModalContent(encontro);
        setEncontroModalOpen(true);
        setModalDate(moment(date).format('DD/MM'));
        break;
      case 'evolução':
        setModalContent(items[0]);
        setEncontroModalOpen(true);
        setModalDate(date);
        break;
      default:
        break;
    }
  };

 

  /**
   * Verifica se o nome do sinal vital clicado está na lista de sinais disponíveis declarado na constante
   * `availableVitalSigns`
   * @param itemName
   * @returns {boolean}
   */
  const isAvailableForDisplay = itemName => {
    // alert(itemName)
    return !isEmpty(filter(availableVitalSigns, vs => isEqual(vs.short, itemName)))
  }

  

  /**
   * Retorna true caso a tag seja marcada como visualização especial baseado na const HIGHLIGHT_TAGS
   * @see HIGHLIGHT_TAGS
   * @param tag
   * @return {boolean}
   */
  const isHightLight = (tag) => {
    return HIGHLIGHT_TAGS.includes(tag.replace(' ', ''));
  }
  const onClickCriterioExtraido = (item, criterio) => {
    const facts = filter(patientFacts, item => item._index === "encontros");
    let graphData = filterGraphData(facts, item, 'dt_encontro')
    graphData = chain(graphData)
      .filter((graphItem)=>{
        return min(graphItem._source.criterios_extraidos_json[criterio])
      })
      .map((graphItem)=>{
        const minValue = min(graphItem._source.criterios_extraidos_json[criterio]);
        return {
          date: new Date(new Date(graphItem._source.dt_encontro).toDateString()),
          _source: {
            tipo_sinal: criterio,
            valor: minValue,
            aux: graphItem._source,
          }
        }
      })
      .orderBy("date", "asc")
      .value()
    setDataset(graphData);
    setOpen(true);
    setDialogTitle(`Critérios Extraídos: ${criterio}`);
    setChangedDate(new Date(new Date(item.dt_encontro).toDateString()));
    setUnimedida('');
  }
  /**
   * Clica para abrir o modal
   * @param item
   */
  const onClick = useCallback((item) => {
    if(!patientFacts) return;
    try {
      if(isEqual(item.item_exame,"LEUCÓCITOS")){
        let graphData = parseGraphData(patientFacts, "LEUCÓCITOS", item)

        setDataset(graphData);
        setOpen(true);
        setDialogTitle("LEUCÓCITOS");
        setChangedDate(new Date(new Date(item.dthr_pedido).toDateString()));
        setUnimedida('leucócitos/mm3');
      } else  
      if(isEqual(item.item_exame,"PROTEINA C REATIVA")){
        let graphData = parseGraphData(patientFacts, "PROTEINA C REATIVA", item)

        setDataset(graphData);
        setOpen(true);
        setDialogTitle("PROTEINA C REATIVA");
        setChangedDate(new Date(new Date(item.dthr_pedido).toDateString()));
        setUnimedida('mg/L');

      } else   // Verifica se o evento de click está ativo para este indicador
      if(onClickEvent) {
        const displayObject = find(availableVitalSigns, vs => isEqual(vs.short, item.name));
        setDataset(onClickEvent(item));
        setOpen(true);
        setDialogTitle(displayObject.fullName);
        setChangedDate(item.date);
        if(displayObject.unit){
          setUnimedida(displayObject.unit);
        }
      } else {
        if (!isNil(item) && sinaisVitais.length) {
          if (isAvailableForDisplay(item.name)) {
            const displayObject = find(availableVitalSigns, vs => isEqual(vs.short, item.name))
            setOpen(true);
            setDataset(filterVitalSigns(displayObject.short));
            setDialogTitle(displayObject.fullName);
            setChangedDate(item.date);
            if(displayObject.unit){
              setUnimedida(displayObject.unit);
            }
          }
        }
      }

    } catch (e) {
      console.error('Objeto exame não definido.', e);
    }

  },[patientFacts]);

  useEffect(() => {
    if (!isNil(label)) {
      // Altera o nome de encontros para evolução (O label é usado para vários campos e conflita nas lógicas)
      if (isEqual(label, 'encontros')) setTitle('Evolução')
    }
  }, [label]);

  return (
    <React.Fragment key={label}>
      <TooltipContainer key={'TooltipDefaultContainer-'+label}>
      <Title>
          <Box display="flex" justifyContent="space-between">
            <Box>
              { title }
              { date ? <TitleDate>{moment(date, 'YYYY-MM-DD').format('DD/MM')}</TitleDate> : ''}
            </Box>
            <IconButton onClick={handleRemoveTooltip} style={{padding: '3px', margin: '0 3px 5px', borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.04)'}} aria-label="close">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Title>
        <TooltipContent key={'TooltipDefaultTooltipContent-'+label}>
          <List ref={tooltipListRef}>
            {!items.length ? <small>Nenhum registro encontrado</small> : ''}
            {label !== 'sinais-vitais' && items.map((item, index) => {
              if(label !== 'encontros') {
                return (
                  <React.Fragment key={label+index}>
                    <ListItem
                        className={
                          (item.highlight ? ' highlight ' : '') +
                          (isAvailableForDisplay(item.name) ? ' hover ' : '')+
                          (isEqual(label, 'Exames') || isEqual(label, 'exames') ? 'exames-item ' : '')+
                          ( isEqual(item.title, 'exames') &&
                            isEqual(item.source.item_exame,"LEUCÓCITOS") ? ' hover ' : '')}
                        onClick={() => onClick(item)}>
                      { isEqual(label, 'Exames') ||  isEqual(label, 'exames') ?
                        <>
                          <Accordion
                            className={classes.accordion}
                            square={true}
                            expanded={(expanded && expanded === 'panel'+index)}
                            onChange={handleChange('panel'+index)}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon color="action" />}
                                aria-controls={"panel"+index+"bh-content"}
                                id={"panel"+index+"bh-header"}
                              >
                                <TooltipBlock>
                                  {item.name &&
                                    <ItemName details={item.details}>
                                      {item.name}
                                      {item.beforeName &&
                                        <ItemDate>
                                          &nbsp;{item.beforeName}
                                        </ItemDate>
                                       }
                                      { find(item.details, {positivo: 'sim'}) && <ChipPositivoInline
                                        color="secondary"
                                        size="small"
                                        label="Positivo"
                                      />}
                                    </ItemName>
                                  }

                                </TooltipBlock>
                              </AccordionSummary>
                              <AccordionDetails
                                className={classes.accordionDetails}>
                                {item.details && item.details.map((detail, detailIndex) =>
                                    <TooltipBlockSmall
                                      key={`Tooltip.Detail.${detailIndex}`}
                                      onClick={() => onClick(detail)}
                                      highlight={detail.criterio === 'sim'}
                                      hasClick={
                                        isEqual(detail.item_exame, 'LEUCÓCITOS') ||
                                        isEqual(detail.item_exame, 'PROTEINA C REATIVA')
                                      }
                                      lastItem={item.details.length === detailIndex + 1}>
                                      <ItemTextSmall highlight={detail.criterio === 'sim'}>
                                        {`${detail.item_exame}: ${detail.resultado}`}
                                      </ItemTextSmall>
                                      <ItemDateSmall>
                                        {`${detail.pedido}`}
                                      </ItemDateSmall>
                                      {
                                        isEqual(detail.item_exame, 'LEUCÓCITOS')||
                                        isEqual(detail.item_exame, 'PROTEINA C REATIVA') ?
                                          <TooltipGraphItem>
                                            <AssessmentIcon color={detail.criterio === 'sim' ? 'secondary'  : 'primary' } />
                                          </TooltipGraphItem>
                                          : ''
                                      }
                                      { isEqual(detail.positivo, 'sim') ? <ChipPositivo
                                        color="secondary"
                                        size="small"
                                        label="Positivo"
                                      /> : ''}

                                    </TooltipBlockSmall>
                                  ) 
                                }
                              </AccordionDetails>
                          </Accordion>
                        </>
                        :
                        <>
                          <TooltipBlock>
                          {item.name ? <ItemName details={item.details}>{item.name}{item.beforeName ? <ItemDate>&nbsp;{item.beforeName}</ItemDate> : ''}</ItemName> : ''}
                          {item.perc ? <ItemPercentual>{item.perc}</ItemPercentual> : ''}
                          </TooltipBlock>
                        </>
                      }
                      {
                        !isEqual(item.title, 'exames') && (
                          item.text ?
                            <TooltipBlock>
                               <ItemText>{item.text}</ItemText>
                            </TooltipBlock>
                          : ''
                        )
                      }
                      {item.startAt || item.endAt ?
                        <TooltipBlock>
                          {item.startAt ? <ItemDate>{item.startAt}</ItemDate> : ''}
                          {item.endAt ? <ItemDate> - {item.endAt}</ItemDate> : ''}
                        </TooltipBlock>
                      :''}

                      {item.hint && item.hint.length > 0 ? <TooltipBlock><ItemHint>{item.hint}</ItemHint></TooltipBlock> : ''}
                      {item.fullText && item.fullText.length > 0 ? item.fullText.map(item => <TooltipBlock><ItemHint>{item}</ItemHint></TooltipBlock>) : ''}

                    </ListItem>
                    {item.termosTexto !== undefined ?
                    <>
                      <ListItem key="observações">
                        <TooltipBlock style={{maxHeight: '92px', overflow: 'hidden', textoverflow: 'ellipsis'}}>
                         <ItemName><ItemDate> {toUpper('observações')}</ItemDate></ItemName>
                         {item.termosTexto ? <ItemName style={{width: '100%', whiteSpace: "pre-wrap"}}>{item.termosTexto.split("/*").join("\n")}</ItemName>  : ''}
                        </TooltipBlock>
                      </ListItem>
                      {
                        isEmpty(item.criterios_extraidos_json?.peep) &&
                        isEmpty(item.criterios_extraidos_json?.spo2) &&
                        isEmpty(item.criterios_extraidos_json?.fio2) ?
                        <button className={classes.saibaMais} onClick={() => handleModalOpen('evolução', item.startAt)}>Saiba mais</button>
                        : ''
                      }
                    </>
                    : ''}
                    {
                      !isEmpty(item.criterios_extraidos_json?.peep) ||
                      !isEmpty(item.criterios_extraidos_json?.spo2) ||
                      !isEmpty(item.criterios_extraidos_json?.fio2) ?
                      <>
                        <ListItem key="criterios">
                          <TooltipBlock style={{maxHeight: '92px', overflow: 'hidden', textoverflow: 'ellipsis'}}>
                            <ItemName><ItemDate> {toUpper('critérios extraídos')}</ItemDate></ItemName>
                            {EXTRACTED_CRITERIAS.filter(filteredCriteria => !isEmpty(item.criterios_extraidos_json[filteredCriteria])).map(criteria => (
                              <>
                              <ItemName style={{width: '100%', whiteSpace: "pre-wrap"}}>
                                {`${criteria}: `}
                                {min(item.criterios_extraidos_json[criteria])}
                                {criteria !== 'peep' ? '%' : ''}
                                {/* {item.criterios_extraidos_json[criteria].map((value, index) =>
                                   `${value}${criteria !== 'peep' ? '%' : ''}${index < item.criterios_extraidos_json[criteria].length - 1 ? ', ' : ''}`
                                )} */}
                              </ItemName>
                              </>
                            ))
                            }
                          </TooltipBlock>
                        </ListItem>
                        <button className={classes.saibaMais} onClick={() => handleModalOpen('evolução', item.startAt)}>Saiba mais</button>
                      </>
                      : ''
                    }
                  </React.Fragment>
                )
              } else {
                if(item.termosAchados !== null) {
                  const parsedCriterias = JSON.parse(item.criterios_extraidos_json)
                  return (
                    <>
                      <ListItem style={{cursor: 'pointer'}} onClick={() => handleModalOpen('encontros', moment(item.source.dt_encontro))}>
                        <TooltipBlock>
                          {item.source.dt_encontro ? <ItemName className={classes.dataEncontro}>{moment(item.source.dt_encontro).format('DD/MM')}</ItemName> : ''}
                          {item.source.local_encontro ? <ItemName className={classes.dataEncontro}> - {item.source.local_encontro}</ItemName> : ''}
                          <div style={{display: 'flex',flexWrap: 'wrap'}}>
                          {
                            !isEmpty(item.termosAchados) &&
                            formatTags(item.termosAchados, 'termos').map((tag) => <Tag key={tag} {...HIGHTLIGHT_TAG_PROPS} isHighLight={isHightLight(tag)}>{tag}</Tag>)
                          }
                          {

                            !isEmpty(parsedCriterias.fio2 || parsedCriterias.spo2 || parsedCriterias.peep) &&
                            <Grid container>
                              <Box display="flex" flex="1" flexDirection="column" mt={2} mb={1}>
                                <ItemName className={classes.dataEncontro}>CRITÉRIOS EXTRAÍDOS</ItemName>
                                <Divider />
                                <Box mt={1} display="flex" flexWrap="wrap">
                                  {formatTags(item.criterios_extraidos_json, 'criterios')
                                    .map((tag) =>
                                      <Tag
                                        key={tag}
                                        {...HIGHTLIGHT_TAG_PROPS}
                                        style={{ width: 'fit-content', marginTop: 3 }}
                                        isHighLight={isHightLight(tag)}>{tag}: {' '}
                                        {min(parsedCriterias[tag])}
                                        {tag !== 'peep' ? '%' : ''}
                                      </Tag>
                                    )
                                  }
                                </Box>
                              </Box>
                            </Grid>
                          }
                          </div>
                        </TooltipBlock>
                      </ListItem>
                    </>
                    )
                }
              }

            })}

          </List>
        </TooltipContent>
      </TooltipContainer>
      {dataset && <GraphDialogComponent title={dialogTitle} open={open} unit={unimedida} setOpen={handleClose}>
        <HistogramChart dataset={dataset} date={changedDate ? changedDate : date }/>
      </GraphDialogComponent>}
      {modalContent && <DialogEncontros 
        modalContent={modalContent}
        onClickCriterioExtraido={onClickCriterioExtraido}
        modalDate={modalDate}
        setEncontroModalOpen={setEncontroModalOpen}
        encontroModalOpen={encontroModalOpen}
        items={items} 
      />}
      
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    patientFacts: state.timeline.patientFacts,
  };
}
const mapDispatchToProps = (dispatch) => ({
  setPatientHistogram: (referenceDate) =>
    dispatch(patientVitalSignalsHistogramDataAction(referenceDate)),
  showHistogramDialog: (show = true) =>
    dispatch(togglePtientHistogramDialog(show))
});

export default connect(mapStateToProps, mapDispatchToProps)(TooltipDefault);
