import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';

import { SuspicionCard } from '../SuspicionCard';

import { TimelineSidebarSection } from './TimelineSidebarSection';

export const TimelineSidebarSuspicions = ({ suspicions, loading }) => {
  useEffect(() => {
    // console.log('suspicions', suspicions);
  }, [suspicions]);

  return (
    <TimelineSidebarSection
      title="Suspeitas"
      badge={suspicions.length}
      loading={loading}
    >
      {!suspicions?.length ? (
        <Typography variant="body2">Nenhuma suspeita encontrada.</Typography>
      ) : (
        <>
          {suspicions.map((suspicion) => (
            <SuspicionCard
              key={suspicion.id}
              suspicion={suspicion}
              style={{ marginBottom: '12px' }}
            />
          ))}
        </>
      )}
    </TimelineSidebarSection>
  );
};
