import { getAllUnities } from '../services/api/unit/unit.service'

export const actionTypes = {
  GET_UNITIES_DATA: 'GET_UNITIES_DATA'
}

const unitDataAction = (unitData) => ({
  type: actionTypes.GET_UNITIES_DATA,
  unitData
});

export const getUnitDataAction = () => async (dispatch) => {
  const unitData = await getAllUnities();

  dispatch(unitDataAction(unitData));
}
