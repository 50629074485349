import { Box, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  patientEvolutionText: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    lineHeight: '19px',
    display: 'block',
    marginBottom: '24px',
    color: theme.palette.neutral.black[88],
  },
}));

export const MeetingReport = withStyles((theme) => ({
  root: {
    fontFamily: theme.MuiTypography.family.secondary,
    fontSize: '14px',
    lineHeight: '19px',
    display: (props) => props.display || 'block',
    marginBottom: (props) => (props.noMargin ? 0 : '24px'),
    color: theme.palette.neutral.black[100],
    backgroundColor: (props) => props.highlight && theme.palette.highlight.main,
    whiteSpace: 'break-spaces',
  },
}))(Typography);

export const PatientNameBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    marginBottom: '12px',
    padding: '12px',
    borderRadius: '8px',
  },
}))(Box);

export const EvolutionPageContainer = withStyles((theme) => ({
  root: {
    margin: '0 auto',
    maxWidth: '610px',
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(13.5),
  },
}))(Box);
export const EvolutionPageHeader = withStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}))(Box);

export const EvolutionPageTitle = withStyles((theme) => ({
  root: {
    fontFamily: theme.MuiTypography.family.main,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '22px',
    color: theme.palette.neutral.black[100],
    margin: 0,
  },
}))(Typography);

export const EvolutionPageDescription = withStyles((theme) => ({
  root: {
    fontFamily: theme.MuiTypography.family.main,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '0',
    color: theme.palette.neutral.black[72],
  },
}))(Typography);

export default useStyles;
