import { useMemo, useCallback } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectTimelineIndicatorsDates } from './selectTimelineIndicatorsDates';

const memoEmpty = []
export const makeSelectTimelineEntry = () => {
  const selectTimelineEntry = createSelector(
    selectTimelineIndicatorsDates,
    (_, cursor) => cursor,
    (indicatorsDates, cursor) => {
      if (!indicatorsDates || !cursor.indicator || !cursor.date) return memoEmpty;
      return indicatorsDates[cursor.indicator]?.[cursor.date] || memoEmpty;
    }
  );

  return selectTimelineEntry
}
/**
 * retorna os dados do indicador pelo cursor recebido
 */
export const useTimelineEntry = ({ indicator, date }) => {
  const select = useMemo(makeSelectTimelineEntry, [])
  const cursor = useMemo(() => ({ indicator, date}), [indicator, date])
  
  const selector = useCallback(
    (state) => select(state, cursor),
    [cursor, select]
  );
  return useSelector(selector);
};
