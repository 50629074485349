
import { useMemo } from 'react';
import moment from 'moment';
import { useTimelineMaxDate } from '../../slices';

export const useIndicatorsPeriod = (offsetDays = 30) => {
  const maxDate = useTimelineMaxDate();
  const endDate = useMemo(() => moment(maxDate).endOf('day'), [maxDate]);
  const startDate = useMemo(() => moment(endDate).add({ days: -offsetDays }), [
    endDate,
    offsetDays,
  ]);

  const period = useMemo(() => ({ endDate, startDate }), [endDate, startDate]);

  return period;
};
