import React, { useMemo } from 'react';
import { Checkbox, Switch, useFormControl } from '@material-ui/core';
import { FormControlLabel } from './styles';

export const SwitchOption = (props) => {
  const formControl = useFormControl();
  const formControlDisabled =
    typeof formControl.disabled !== 'undefined' && formControl.disabled;
  const disabled = formControlDisabled || props.disabled;

  const control = useMemo(() => <Switch color="primary" />, [disabled]);

  return <FormControlLabel control={control} {...props} disabled={disabled} />;
};
