import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectTimelineIndicatorsDates } from './selectTimelineIndicatorsDates';
import {
  selectTimelineExams,
  groupExamsByDateAndName,
} from './selectTimelineExams';
import { mapValues } from 'lodash';

const memoEmpty = [];
export const selectTimelineExamsGmr = createSelector(
  [selectTimelineIndicatorsDates, selectTimelineExams],
  (indicatorsDates, timelineExams) => {
    const examsFacts = indicatorsDates?.exames;
    if (!examsFacts || !timelineExams?.length) return memoEmpty;

    // Apenas os resultados que possuem gmr, agrupados pelo nome do exame
    return groupExamsByDateAndName(
      mapValues(examsFacts, (exams) => exams.filter(({ gmr }) => gmr === 'sim'))
    ).filter(({ hasGmr }) => hasGmr);

    // Todos os resultados são exibidos agrupados pelo nome do exame, filtrados apenas exames com algum resultado gmr.
    return timelineExams
      .filter(({ hasGmr }) => hasGmr)
      .map((group) => ({
        ...group,
        exams: group.exams.filter(({ hasGmr }) => hasGmr),
      }));
  }
);

export const useTimelineExamsGmr = () => useSelector(selectTimelineExamsGmr);
