import React, { useCallback } from 'react';
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
} from '@material-ui/lab/';
import { withStyles } from '@material-ui/styles';

const ToggleButtonGroup = withStyles((theme) => ({
  root: {},
  groupedHorizontal: {
    '&:not(first-child)': {
      borderLeftColor: `${theme.palette.primary.main} !important`,
    },
  },
}))(MuiToggleButtonGroup);

const ToggleButton = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: 'transparent',
    fontFamily: theme.MuiTypography.family.secondary,
    fontSize: theme.MuiTypography.sizes.xxs,
    textTransform: 'initial',
    fontWeight: 400,
    color: theme.palette.primary.main,
    letterSpacing: 0,
    width: 140,
    padding: '8px 13px',
  },
}))(MuiToggleButton);

export const DataTableHeaderToggleGroup = (props) => {
  const { options, value, onChange } = props;

  const handleToggleChange = useCallback(
    (event, newOption) => {
      if (onChange) {
        onChange(newOption);
      }
    },
    [onChange]
  );

  return (
    <ToggleButtonGroup value={value} exclusive onChange={handleToggleChange}>
      {options.map((option, index) => (
        <ToggleButton key={index} value={option.value}>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
