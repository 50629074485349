import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import SettingsTemplate from '../../templates/Settings';
import {Typography, Box, Button, Table, TableCell, TableHead, TableBody, TableRow} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import useStyles from './styles';
import {saveInfectionParams} from "../../actions/SettingsActions";
import {connect} from "react-redux";
import {withTheme} from "@material-ui/core/styles";
import { useGetHealthStatusQuery, useRefreshLogstashMutation } from '../../services';

const HealthPage = (props) => {
  const classes = useStyles();
  const { data: healthStatus, refetch: healthStatusRefetch } = useGetHealthStatusQuery();
  const [refreshLogstash, { loading, error }] = useRefreshLogstashMutation();

  const handleResetLogstash = () => {
    refreshLogstash();
  }

  const isOperacional = healthStatus?.status === 'Operando';

  useEffect(() => {
    if (!isOperacional) {
      const timeoutId = setInterval(() => {
        healthStatusRefetch();
      }, 5000)

      return () => {
        clearInterval(timeoutId)
      }
    }
  }, [healthStatusRefetch, isOperacional])

  return (
    <SettingsTemplate>
      <Grid classes={{ root: classes.container }} container>
        <Box>
        <Box mt={2}>
          <h1 classes={{ root: classes.pageTitle }}>
            Status do sistema:
          </h1>
          <Typography classes={{ root: classes.pageDescription }} variant="body1">
            Aqui você irá encontrar informações sobre a disponibilidade do sistema
          </Typography>
          {isOperacional ? (
            <Box>
              <Alert icon={
                <CheckIcon classes={{ root: classes.checkIcon }} />
              } classes={{ root: classes.alertCard }} severity="success">
                <h3>
                  Sistema operacional
                </h3>
                <Typography classes={{ root: classes.cardDescription }} variant="body1">
                  Tudo funcionando normalmente :)
                </Typography>
              </Alert>
            </Box>
          ) : (
            <Box>
              <Alert icon={
                <ErrorOutlineIcon classes={{ root: classes.alertIcon }} />
              } classes={{ root: classes.alertCard }} severity="warning">
                <h3>
                  Processando dados
                </h3>
                <Typography classes={{ root: classes.cardDescription }} variant="body1">
                  Reiniciando dados do sistema.
                </Typography>
              </Alert>
            </Box>
          )}
        </Box>
        <Box classes={{ root: classes.tableContainer }} mt={2}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead classes={{root: classes.tableHead}}>
              <TableRow>
                <TableCell classes={{root: classes.tableStyle}} colSpan="2">
                  <Box display="flex" alignItems="center" margin="5px 0">
                    <Box flex="1">
                      <h3 className={classes.tableTitle}>Histórico</h3>
                      <Typography classes={{ root: classes.tableDescription }} variant="body1">
                        Log das últimas atividades do sistema
                      </Typography>
                    </Box>
                    <Button classes={{ root: classes.resetButton }} variant="outlined" color="primary" onClick={handleResetLogstash}>Reiniciar logstash</Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{root: classes.tableStyle}}>
              {healthStatus?.logs?.map(log => (
                <TableRow classes={{root: classes.tableRow}}>
                  <TableCell classes={{root: classes.tableStyle}}>
                    <Typography classes={{root: classes.itemText}} variant="body1">
                      {new Date(log.createdAt).toLocaleDateString('pt-BR', {
                        dateStyle: 'short',
                      })}, {''}
                      {new Date(log.createdAt).toLocaleTimeString('pt-BR')}
                    </Typography>
                  </TableCell>
                  <TableCell classes={{root: classes.tableStyle}}>
                    <Typography classes={{root: classes.itemText}} variant="body2">{log.key} - {log.value}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        </Box>
      </Grid>
    </SettingsTemplate>
  )
};

export default withTheme(HealthPage);
