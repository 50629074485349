import React, { useLayoutEffect, useRef } from 'react';
import D3HorizontalBarChart from './D3HorizontalBarChart';
import { HorizontalBarChartContainer } from './HorizontalBarChartContainer';

export const HorizontalBarChart = (props) => {
  const { isLoading, dataset, ...rest } = props;
  const refChart = useRef();

  useLayoutEffect(() => {
    if (!refChart.current) return;

    const chart = new D3HorizontalBarChart({
      container: refChart.current,
      dataset,
      isLoading,
    });

    const updateSize = () => chart.changeSizes();
    updateSize();

    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [isLoading, refChart, dataset]);

  return <HorizontalBarChartContainer ref={refChart} {...rest} />;
};
