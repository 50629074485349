import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setLocation } from '../slice';

export const useSetHistoryLocation = () => {
  const dispatch = useDispatch();

  const handleSetHistoryLocation = useCallback(
    (location) => dispatch(setLocation(location)),
    [dispatch]
  );

  return handleSetHistoryLocation;
};
