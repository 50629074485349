import styled from "styled-components";
import { Switch, FormControlLabel, Select, FormControl } from "@material-ui/core";

export const MySwitch = styled(Switch)( props=> ({
    marginLeft: '20px!important'
}));

export const MyFormControlLabel = styled(FormControlLabel)( props=> ({
    '.MuiFormControlLabel-label': {
        fontFamily: 'DM Mono!important',
        fontStyle: 'normal!important',
        fontWeight: 'normal!important',
        fontSize: '13px!important',
        lineHeight: '17px!important',
        color: '#383A3D!important',
    }
}));

export const MyFormControlSelect = styled(FormControl)( props=> ({
    margin: '0px!important',
    borderRadius: '8px!important',
    fontSize: '13px!important',
    lineHeight: '17px!important',
    color: '#383A3D!important',
    width: '100%!important',
    marginBottom: '15px!important',
    '#demo-simple-select-filled-label' : {
        fontFamily: 'DM Mono!important',
        fontSize: '13px!important',
        lineHeight: '17px!important',
        color: '#383A3D!important',
        marginTop: '-4px!important',
    }
}));

export const MySelect = styled(Select)( props=> ({
    background: '#F5F5F5!important',
    borderRadius: '8px!important',
    '&:before' : {
        content: 'none!important'
    },
    '&:after' : {
        content: 'none!important'
    },
    '.MuiSelect-root': {
        borderRadius: '8px!important',
        paddingTop: '18px!important',
    }
}));