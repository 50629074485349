import React, { useMemo, useCallback } from 'react';
import { ButtonBase, useTheme } from '@material-ui/core';
import InsertChartOutlined from '@material-ui/icons/InsertChartOutlined';

import { ExamTitle } from './ExamTitle';
import { examIsCulture } from '../../../slices';
import { withStyles } from '@material-ui/styles';

export const ExamResultButton = withStyles(() => ({
  root: {
    width: '100%',
    textAlign: 'left',
  },
}))(ButtonBase);

const examsWithHistogram = ['LEUCÓCITOS', 'PROTEINA C REATIVA'];

export const ExamResultItem = (props) => {
  const { exam, onOpenHistogram } = props;
  const theme = useTheme();

  const { exame, item_exame, criterio, positivo, resultado } = exam;

  const hasCriteria = criterio === 'sim';
  const isPositive = positivo === 'sim';
  const isCulture = useMemo(() => examIsCulture(exame), [exame]);
  const title = `${item_exame}: ${resultado}`;
  const hasAction = useMemo(() => examsWithHistogram.includes(item_exame), [
    item_exame,
  ]);
  const action = useMemo(() => {
    if (!hasAction) return undefined;
    return (
      <InsertChartOutlined
        style={{ marginTop: '8px' }}
        htmlColor={
          hasCriteria
            ? theme.palette.timeline.probability['500']
            : theme.palette.neutral.gray.md
        }
      />
    );
  }, [hasAction, theme, hasCriteria]);

  const handleAction = useCallback(() => onOpenHistogram(exam), [
    onOpenHistogram,
    exam,
  ]);

  const WrapperComponent = hasAction ? ExamResultButton : 'div';

  return (
    <WrapperComponent onClick={hasAction ? handleAction : undefined}>
      <ExamTitle
        positive={isPositive}
        criteria={hasCriteria}
        culture={isCulture}
        title={title}
        action={action}
        size='small'
      />
    </WrapperComponent>
  );
};
