import { infectionTypesEnum } from "../../constants/infections"
import { upperFirst } from 'lodash'

export const infectionTypesLabels = {
  [infectionTypesEnum.ipcs]: 'IPCS',
  [infectionTypesEnum.isc]: 'ISC',
  [infectionTypesEnum.itu]: 'ITU',
  [infectionTypesEnum.pav]: 'PAV',
  [infectionTypesEnum.pnm]: 'PNM',
  [infectionTypesEnum.traqueo]: 'Traqueo',
}

/**
 * Recebe uma string como nome da infecção:
 * 
 * Se a string for membro do enum infectionTypesLabels, retornará o label definido no enum.
 * 
 * Se não, formatará a string com maiúsculas
 * 
 * @param {string} infection Nome da infecção
 * @returns Label formatada da infecção
 * 
 * @example
 * formatInfectionLabel('ipcs'); // IPCS // infectionTypesLabels['ipcs']
 * formatInfectionLabel('traqueo'); // Traqueo // infectionTypesLabels['traqueo']
 * formatInfectionLabel('outra'); // OUTRA // string.toUpperCase()
 */
export const formatInfectionLabel = (infection) => {
  if( typeof infection !== 'string' ) return '';

  return infectionTypesLabels[infection] || upperFirst(infection);
}
