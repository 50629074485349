import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { PageTemplate, PageHeading, PageTitle } from '../PageTemplate';
import { SelectInput } from '../../components/molecules/Form';

export const DashboardTemplate = withStyles(() => ({
  root: {},
  container: {},
  heading: {},
  headingContainer: {},
  headingTitle: {},
  headingAction: {
    maxWidth: '323px',
  },
  headingDivider: {},
  title: {},
  cardClass: {
    padding: "10px"
  }
}))(function StyledDateRangeTemplate({
  classes,
  className,
  children,
  title,
  info,
  divider,
  handleChangeCompetence,
  selectedCompetence,
  competencesOptions,
  extraActions
}) {
  return (
    <PageTemplate
      classes={{
        root: clsx(classes.root, className),
        container: classes.container,
      }}
    >
      <PageHeading
        classes={{
          root: classes.heading,
          container: classes.headingContainer,
          title: classes.headingTitle,
          action: classes.headingAction,
          divider: classes.headingDivider,
        }}
        title={
          <PageTitle info={info} className={classes.title}>
            {title}
          </PageTitle>
        }
        divider={divider}
        action={
          [
            ...(extraActions && extraActions.length ? extraActions : []),
            <SelectInput
              margin="10px"
              padding="10px 20px"
              bgcolor="white"
              options={competencesOptions}
              value={selectedCompetence}
              onChange={handleChangeCompetence}
            />
          ]
        }
      />
      {children}
    </PageTemplate>
  );
});
