import React from 'react';
import Forbidden from '../../templates/Forbidden/index';
import { Body, Warning } from './styles';
import { connect } from 'react-redux';

const ForbiddenPage = (props) => {
    return (
        <Forbidden>
            <Body>
                403
            </Body>
            <Warning>
                Ops! Parece que você não tem autorização para acessar essa página!
            </Warning>
        </Forbidden>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ForbiddenPage);
