import TooltipDefault from "./Tooltips/TooltipDefault";
import React from "react";
import {uniqBy, map, sortBy, keys, filter, each, orderBy, isEmpty, isEqual, max, min, forOwn, cloneDeep, maxBy} from 'lodash';
import {getDates, groupByDate, groupMedicamentosColor, groupMovimentacaoColor} from "./Helper";
import moment from 'moment';
import { orderExams } from "./Tooltips/helpers";
import { keywords, regexKeywords } from "./Tooltips/constants";
import reactStringReplace from "react-string-replace";
import { Typography } from "@material-ui/core";

export class ChartCollection {
  data = []
  infections = []
  allDates = {}
  highlightDates =[]

  constructor(data, infections, groupBy) {
    let patientData = cloneDeep(data);
    this.infections = cloneDeep(infections);
    this.groupBy = groupBy;
    this.data = this.parse(patientData, this.infections);

    // Define o valor de highlight de infecção caso exista highlight para controlar o highlight de suspeitas
    this.infectionHasHighlight = false;
  }

  /**
   * Prepara os dados da collection para serem consumidos
   * @param data
   * @param infections
   * @returns {*}
   */
  parse(data, infections) {
    let pretty = map(data, item => {
      switch (item._index) {
        case 'laudos':
          item._index = "Laudos";
          item = this.setDate(item, 'dthr_pedido');
          break;
        case 'procedimentos':
          item._index = "Procedimentos";
          item = this.setDate(item, 'dthr_procedimento');
          break;
        case 'sinais-vitais':
          item._index = "Sinais vitais";
          item = this.setDate(item, 'dthr_coleta');
          break;
        case 'medicamentos':
          item._index = "Antimicrobianos";
          item.criterio = "sim";
          item = this.setDate(item, 'dthr_prescricao');
          break;
        case 'exames':
          item._index = "Exames";
          item._source.valor = item._source.resultado;
          item = this.setDate(item, 'dthr_pedido');
          break;
        case 'encontros':
          item._index = "Evolução";
          item = this.setDate(item, 'dt_encontro');
          break;
        case 'suspeitas':
          item._index = "Suspeitas";
          item = this.setDate(item, 'dt_inicio', 'dt_fim');
          this.highlightDates.push([new Date(item._source.dt_inicio), new Date(item._source.dt_fim)])
          break;
        default:
          break;

      }
      return item;
    });

    // Adicionando as infecções do paciente no roll de dados
    each(infections, item => {
      item._index = "Infecções";
      item = this.setDate(item, 'dt_infeccao');
      // item = this.setDate(item, 'dt_inicio', 'dt_fim');
      pretty.push(item)
    });

    // Organiza as datas coletadas por ordem crescente
    this.allDates = map(
      sortBy(
        keys(this.allDates), item => new Date(item).getTime()
      ),
      item => new Date(item)
    );

    return pretty;
  }

  /**
   * Pega todas as datas do sistema
   * @returns Date[]
   */
  getDates() {
    const dates = getDates(this.allDates[0], this.allDates[this.allDates.length - 1]);
    let result = null;
    // Agrupa as datas do eixo por mês
    if (this.groupBy === 'month') {
      result = {}
      dates.forEach((date) => {
        let month = date.getFullYear() + "-" + date.getMonth() + "-01";
        result[month] = true;
      })
      result = map(keys(result), o => new Date(o));
    } else {
      result = dates;
    }
    return result
  }

  /**
   * Define a data de início e fim dos eventos em um atributo padrão para todos objetos
   * @param item objeto
   * @param initialIndex atributo da data inicial
   * @param finalIndex atributo da data final
   * @returns {*} item com dois novos atributos. Datetime que é a data original e date com apenas o dia, mês e ano
   */
  setDate(item, initialIndex, finalIndex = null) {
    let initialDate = null;
    // Possui data inicial e data final
    if (initialIndex && finalIndex) {
      item.datetime = [
        moment(item._source[initialIndex], 'YYYY-MM-DD').toDate(),
        moment(item._source[finalIndex], 'YYYY-MM-DD').toDate(),
      ];

      let day0 = item.datetime[0].getDate();
      let day1 = item.datetime[1].getDate();
      if (this.groupBy === 'month') {
        day0 = 1
        day1 = 1
      }
      initialDate = new Date(item.datetime[0].getFullYear(), item.datetime[0].getMonth(), day0);
      let finalDate = new Date(item.datetime[1].getFullYear(), item.datetime[1].getMonth(), day1);
      item.date = [initialDate, finalDate];
      // Grava a data do fato
      this.allDates[initialDate] = true;
      this.allDates[finalDate] = true;
    }
    // Apenas data inicial
    if (initialIndex && !finalIndex) {
      item.datetime = new Date(item._source[initialIndex]);
      let day0 = item.datetime.getDate();
      if (this.groupBy === 'month') {
        day0 = 1
      }
      initialDate = new Date(item.datetime.getFullYear(), item.datetime.getMonth(), day0);
      item.date = initialDate;

      // Grava a data do evento
      this.allDates[initialDate] = true;
    }
    return item;
  }

  /**
   * Lista os labels do gráfico
   * @returns {(string)[]}
   */
  getLabels() {
    // Busca os índices únicos
    let labels = uniqBy(this.data, '_index').map(item => item._index);
    // Ordena o array
    labels = sortBy(labels, label => {
      switch (label) {
        case 'Laudos':
          return 7;
        case 'Procedimentos':
          return 6;
        case 'Sinais vitais':
          return 5;
        case 'Antimicrobianos':
          return 4;
        case 'Exames':
          return 3;
        case 'Evolução':
          return 2;
        case 'Suspeitas':
          return 1;
        case 'Infecções':
          return 0
        default:
          return 7;

      }
    });

    labels = ["Suspeitas"].concat(labels)

    return labels;
  }

  /**
   * Verifica se um facto é destacado
   * @param date
   * @returns {boolean}
   */
  isHighlight(date) {
    return filter(this.highlightDates, highlightdate => {
      let auxDate = highlightdate[0]
      const firstDay = new Date(auxDate.getFullYear(), auxDate.getMonth(), auxDate.getDate()).getTime()
      auxDate = highlightdate[1]
      const lastDay = new Date(auxDate.getFullYear(), auxDate.getMonth(), auxDate.getDate()).getTime()
      return  firstDay <= date.getTime() && date.getTime() <= lastDay
    }).length > 0
  }

  /**
   * Pega os detalhes de um Diagnóstico
   * @param patient
   * @returns {[]}
   */
  getDiagnosticoDetalhe(patient){
    let infeccao = []
    Object.keys(patient).forEach((key) => {
      const [ pred, infection ] = key.split('_');

      if(pred === 'pred' && patient[infection] > 0.5 && patient[key] === 1) {
        const infectionNameUppercased = infection.toUpperCase();
        const infectionPercentage = parseFloat(patient[infection] * 100)
          .toFixed(1);
        infeccao.push({name: `${infectionNameUppercased}`, perc:`${infectionPercentage} %`});
      }
    });
    return infeccao;
  }

  /**
   * Lista os diagnósticos
   * @returns {object[]}
   */
  getDiagnosticos() {
    const index = "Suspeitas"
    const entries = filter(this.data, item => item._index === index)
    const entriesByDate = groupByDate(entries);
    return map(entriesByDate, entry => {
      return {
        date: [entry[0].date[0], entry[0].date[1]],
        total: '',
        highlight: this.infectionHasHighlight,
        component: <TooltipDefault
          label={index}
          date={entry[0]._source.dt_infeccao}
          items={map(this.getDiagnosticoDetalhe(entry[0]._source), item => {
            return {
              name: item.name,
              startAt: entry[0].date[0].getDate() + "/" + (entry[0].date[0].getMonth() + 1),
              endAt: entry[0].date[1].getDate() + "/" + (entry[0].date[1].getMonth() + 1),
              perc: item.perc,
              highlight: true
            }
          })}
        />
      }
    })
  }

  /**
   * Pega a lista de infecções
   * @returns {object[]}
   */
  getInfeccoes() {
    const index = "Infecções"
    const entries = filter(this.data, item => item._index === index)
    const entriesByDate = groupByDate(entries);
    return map(entriesByDate, entry => {
      const componentItems = [
        {
          highlight: ( entry[0]._source.prob_perc >= 0.5),
          name: 'Geral: '+ (Math.round(entry[0]._source.prob_perc * 100)) + '%',
          startAt: entry[0].date.getDate() + "/" + (entry[0].date.getMonth() + 1),
        },
        {
          highlight: ( entry[0]._source.traqueo >= 0.5),
          name: 'Traqueo: '+ (Math.round(entry[0]._source.traqueo * 100)) + '%',
          startAt: entry[0].date.getDate() + "/" + (entry[0].date.getMonth() + 1),
        },
        {
          highlight: ( entry[0]._source.pnm >= 0.5),
          name: 'PNM: '+ (Math.round(entry[0]._source.pnm * 100)) + '%',
          startAt: entry[0].date.getDate() + "/" + (entry[0].date.getMonth() + 1),
        },
        {
          highlight: ( entry[0]._source.pav >= 0.5),
          name: 'PAV: '+ (Math.round(entry[0]._source.pav * 100)) + '%',
          startAt: entry[0].date.getDate() + "/" + (entry[0].date.getMonth() + 1),
        },
        {
          highlight: ( entry[0]._source.itu >= 0.5),
          name: 'ITU: '+ (Math.round(entry[0]._source.itu * 100)) + '%',
          startAt: entry[0].date.getDate() + "/" + (entry[0].date.getMonth() + 1),
        },
        {
          highlight: ( entry[0]._source.isc >= 0.5),
          name: 'ISC: '+ (Math.round(entry[0]._source.isc * 100)) + '%',
          startAt: entry[0].date.getDate() + "/" + (entry[0].date.getMonth() + 1),
        },
        {
          highlight: ( entry[0]._source.ipcs >= 0.5),
          name: 'IPCS: '+ (Math.round(entry[0]._source.ipcs * 100)) + '%',
          startAt: entry[0].date.getDate() + "/" + (entry[0].date.getMonth() + 1),
        },

      ];
      // Adiciona na classe o controle do highlight para ser utilizado nas suspeitas
      this.infectionHasHighlight = this.infectionHasHighlight ? true :
        !isEmpty(componentItems.filter((data) => data.highlight));

      return {
        date: entry[0].date,
        total: entry.length > 1 ? entry.length : '',
        highlight: (
          entry[0]._source.prob_perc >= 0.5 ||
          entry[0]._source.prob_perc_traqueo >= 0.5 ||
          entry[0]._source.prob_perc_pnm >= 0.5 ||
          entry[0]._source.prob_perc_pav >= 0.5 ||
          entry[0]._source.prob_perc_isc >= 0.5 ||
          entry[0]._source.prob_perc_itu >= 0.5 ||
          entry[0]._source.prob_perc_ipcs >= 0.5
        ),
        component: <TooltipDefault
          label={index}
          date={entry[0].date}
          items={componentItems}
        />
      }
    })
  }

  /**
   * Pega a lista de movimentações
   * @returns {object[]}
   */
  getMovimentacao() {
    // O nome Movimentação passa a ser chamado Evolução (pelo menos no display)
    const index = "Evolução"
    const entries = filter(this.data, item => item._index === index)
    const entriesByDate = groupMovimentacaoColor(groupByDate(entries));
    return map(entriesByDate, entry => {
      return {
        date: entry.date,
        total: '',
        highlight: this.isHighlight(entry.date),
        color: entry.color,
        component: <TooltipDefault
          label={index}
          date={entry.date}
          items={[{
              ...entry._source,
              highlight: this.isHighlight(entry.datetime),
              termosTexto: entry._source.termos_texto,
              termosAchados: entry._source.termos_achados,
              criterios_extraidos_json: JSON.parse(entry._source.criterios_extraidos),
              name: entry._source.local_encontro,
              startAt: moment.utc(entry.date).format('DD/MM'),
              endAt:  entry.date === entry.lastdate || entry.lastdate === undefined ? null : moment(entry.lastdate, 'YYYY-MM-DD').format('DD/MM'),
            }]
          }
        />
      }
    })
  }

  /**
   * Pega a lista de exames
   * @returns {object[]}
   */
  getExames() {
    let debbugDate = new Date();
    function getDifferenceInDays(date1, date2) {
      const diffInMs = Math.abs(date2 - date1);
      return diffInMs;
    }
    const index = "Exames";
    const entries = orderBy(filter(this.data, item => item._index === index), item => item._source.dthr_pedido)
    const entriesByDate = groupByDate(entries);
    let newEntriesByDate = [];
    // Organiza os itens do array por tipo
    each(entriesByDate, (dateItem, index)=> {
      newEntriesByDate[index] = []
      newEntriesByDate[index]['details'] = dateItem[0];
      newEntriesByDate[index]['details']['length'] = dateItem.length;
      each(dateItem, (exameItem)=> {
        if(!newEntriesByDate[index][exameItem._source.exame]){
          newEntriesByDate[index][exameItem._source.exame] = [];
        }
        newEntriesByDate[index][exameItem._source.exame].push(exameItem);
      });
    });

    return map(newEntriesByDate, entry => {
      let circle = [],
        highlight = [],
        triangle = [],
        triangle2 = [],
        diamond = [];
      // Verifica se existe algum destaque dentro dos itens
      forOwn(entry,(value, key) => {
        if(Array.isArray(value)) {
          circle =  circle.length === 0  ? filter(entry[key],item =>
            ( item._source.exame.search('HEMOCULTURA') >= 0 ||  item._source.exame.search('UROCULTURA') >= 0 ||  item._source.exame.search('CULTURA QUANTITATIVA') >= 0) &&
            item._source.positivo === 'sim') : circle;
          highlight = highlight.length === 0 ? filter(entry[key], { _source: { criterio :'sim' } }) : highlight;
          // Verifica e existe a palavra em um dos itens do array
          triangle =  triangle.length === 0  ? filter(entry[key],item => item._source.exame.search('HEMOCULTURA') >= 0 ||  item._source.exame.search('CULTURA QUANTITATIVA') >= 0) : triangle;
          triangle2 = triangle2.length === 0   ? filter(entry[key],item => item._source.exame.search('BACTERIOLOGICO') >= 0 ||  item._source.exame.search('BACTERIOSCOPIA') >= 0) : triangle2;
          diamond =   diamond.length === 0   ? filter(entry[key],item => item._source.exame.search('UROCULTURA') >= 0) : diamond;
        }
      })
      let itens = [];

      forOwn(entry, (value, key)  => {
        // console.log(value)
        // const dt_entrega = moment.utc(item._source.dthr_entrega);
        if(Array.isArray(value)) {
          let details = [];
          // Adiciona os detalhes do exame
          value.forEach((exame)=> {
            exame._source.entrega = moment(exame._source.dthr_pedido).format('DD/MM/YYYY hh:mm');
            exame._source.pedido = moment(exame._source.dthr_pedido).format('DD/MM/YYYY hh:mm');
            details.push(exame._source);
          })


          details = orderBy(details, orderExams, "asc");
          // List os tipos de exames e verifica se existe algum item com destaque
          itens.push({
            highlight: details.filter(item => isEqual(item.criterio, "sim")).length > 0,
            fullText: [],
            details: details,
            name: key,
            // startAt: `ENTREGA: ${dt_entrega.format('DD/MM H:mm')}`
          })
        }
      })
      itens = orderBy(itens, (item) => item.highlight=== true ? 0 : 1);
      // console.log(getDifferenceInDays(debbugDate, new Date()))
      return {
        date: entry['details'].date,
        total: entry['details'].length > 1 ? entry['details'].length : '',
        circle: circle.length > 0,
        highlight: highlight.length > 0,
        triangle: triangle.length > 0,
        triangle2: triangle2.length > 0,
        diamond: diamond.length > 0,
        component: <TooltipDefault
          label={'exames'}
          date={entry['details'].date}
          items={itens}
        />
      }
    })
  }

  /**
   * Pega a lista de exames
   * @returns {JSX.Element}
   */
  getExamesAll(onlyHighlights = false) {
    const index = "Exames";
    const entriesByIndex = filter(this.data, item => item._index === index && isEqual(item._source.criterio, "sim"))
    const suspicionsEntries = filter(this.data, item => item._index == 'Suspeitas')
    let entries = orderBy(entriesByIndex, item => moment(item._source.dthr_pedido).unix(), 'desc');
    // Implementa regra para exibir apenas os exames da data do exame mais recente até 30 dias atrás
    const maxDate = maxBy(suspicionsEntries, (item) => {
      return moment(item._source.dt_infeccao).unix()
    });
    if(maxDate){

      let less30Days = moment(maxDate._source.dt_infeccao).subtract(30, "days")
      entries = filter(entries, (detail) => moment(detail._source.dthr_pedido).isAfter(less30Days));
    }
    let newEntries = [];
    // Organiza os itens do array por tipo
    each(entries, (exameItem, index)=> {
      const dthr_pedido = moment(exameItem._source.dthr_pedido).format("YYYY-MM-DD")
      if(!newEntries[exameItem._source.exame]){
        newEntries[exameItem._source.exame] = {};
      }
      if(!newEntries[exameItem._source.exame][dthr_pedido]){
        newEntries[exameItem._source.exame][dthr_pedido] = [];
      }
      newEntries[exameItem._source.exame][dthr_pedido].push(exameItem);
    });
    let itens = [];
    forOwn(newEntries, (entryType, keyType)  => {
      forOwn(entryType, (value, key)  => {
        // console.log(value)
        const dthr_pedido = moment(key);
        if(Array.isArray(value)) {
          let details = [];
          // Adiciona os detalhes do exame
          value.forEach((exame)=> {
            exame._source.entrega = moment(exame._source.dthr_entrega).format('DD/MM/YYYY hh:mm');
            exame._source.pedido = moment(exame._source.dthr_pedido).format('DD/MM/YYYY hh:mm');
            details.push(exame._source);
          });
          // List os tipos de exames e verifica se existe algum item com destaque
          const isHighLight = details.filter(item => isEqual(item.criterio, "sim")).length > 0;
          itens.push({
            highlight: isHighLight,
            dthr_pedido: dthr_pedido,
            fullText: [],
            details: details,
            name: `${dthr_pedido.format('DD/MM/YYYY')} - ${keyType}`,
            // startAt: `ENTREGA: ${dt_entrega.format('DD/MM H:mm')}`
          })
        }
      })
    })
    itens = orderBy(itens, (item) => moment(item.dthr_pedido), 'desc');
    if(onlyHighlights){
      itens = filter(itens, (item) => item.highlight=== true);
      itens = map(itens, (item)=>{
        item.details = orderBy(item.details, orderExams, "asc");

        return item;
      })
    }
    return <TooltipDefault
      label={'exames'}
      items={itens}
    />
  }

  /**
   * Pega a lista de medicamentos
   * @returns {object[]}
   */
  getMedicamentos() {
    const index = "Antimicrobianos"
    const entries = filter(this.data, item => item._index === index)
    this.data = this.data.map(data=> {
      if(data._index === index){
        data._source.criterio = 'sim'
      }
      return data;
    })
    // const entriesByDate = groupByDate(entries);
    const entriesByDate = groupMedicamentosColor(groupByDate(entries));
    return map(entriesByDate, entry => {
      return {
        date: entry[0].date,
        color: entry[0].color,
        total: entry.length > 1 ? entry.length : '',
        highlight: this.isHighlight(entry[0].date),
        component: <TooltipDefault
          label={index}
          date={entry[0].date}
          items={map(entry, item => {
            return {
              name: item._source.medicamento,
              // highlight: this.isHighlight(entry[0].date),
              startAt: moment.utc(item.datetime).format('DD/MM H[H] m')
            }
          })}
        />
      }
    })
  }

  /**
   * Pega a lista de sinais vitais
   * @returns {object[]}
   */
  getSinaisVitais() {
    const index = "Sinais vitais"
    const entries = filter(this.data, item => item._index === index)
    let entriesByDate = groupByDate(entries);
    entriesByDate = entriesByDate.map((entries)=> {
      const distinct = uniqBy(entries, function(n) {
        return n._source.tipo_sinal;
      });
      const changeDateType = distinct.map(entry => {
        const byType = filter(entries, item => item._source.tipo_sinal ===  entry._source.tipo_sinal);
        const values = byType.map(type => parseFloat(type._source.valor.replace(',', '.')))
        entry._source.valor = min(values) + ' - ' + max(values);
        entry._source.dthr_coleta =  moment.utc(entry._source.dthr_coleta);
        return entry;
      });
      return sortBy(changeDateType, [function(o) {

        switch(o._source.tipo_sinal){
          case 'TEMP':
            return 0;
          case 'FR':
            return 1;
          case 'OXIMETRIA':
            return 2;
          case 'O2':
            return 3;

          case 'FC':
            return 4;

          case 'PAS':
            return 5;
          case 'PAD':
            return 6;
          case 'PAM':
            return 7;
          case 'HGT':
            return 8;
          case 'DOR':
            return 9;
          case 'PA':
            return 10;
          case 'PA.':
            return 11;

          default:
            return 12;
        }
      }]);
    });
    return map(entriesByDate, entry => {
      return {
        date: entry[0].date,
        total: entry.length > 1 ? entry.length : '',
        highlight: entry.filter(item => item._source.criterio && item._source.criterio === 'sim').length,
        component: <TooltipDefault
          label={index}
          date={entry[0].date}
          items={map(entry, item => {
            return {
              highlight: item._source.criterio && item._source.criterio === 'sim',
              beforeName: '',
              name: item._source.tipo_sinal,
              unimedida: item._source.unimedida,
              startAt: item._source.unimedida+ ': '+item._source.valor,
            }
          })}
        />
      }
    })
  }

  /**
   * Lista os dados para os sinais vitais. +10 dias e -10 dias
   * @param componentData
   * @returns {dataset[]}
   */
  getSinaisVitaisChartTooltip(componentData){
    const currentDate = componentData.date;
    const subDays = currentDate.setDate(currentDate.getDate() - 10);
    const addDays = currentDate.setDate(currentDate.getDate() + 20);
    currentDate.setDate(currentDate.getDate() - 10);
    const sinaisVitais = filter(this.data, item => {
      return item._index === "Sinais vitais" && // Filtra apenas Sinais Vitais
        item.date.getTime() >= subDays && item.date.getTime() <= addDays // Busca dados entre as datas
    })

    return orderBy(sinaisVitais, (item) => {
      return item.date;
    });
  }

  getLeucocitosChartTooltip(componentData){
    const currentDate = componentData.date;
    const subDays = currentDate.setDate(currentDate.getDate() - 10);
    const addDays = currentDate.setDate(currentDate.getDate() + 20);
    currentDate.setDate(currentDate.getDate() - 10);
    const exames = filter(this.data, item => {
      return isEqual(item._index,"Exames") &&
        item.date.getTime() >= subDays &&
        item.date.getTime() <= addDays &&
        isEqual(item._source.item_exame,"LEUCÓCITOS")

      // item.// Busca dados entre as datas
    })
    return orderBy(exames, (item) => {
      return item.date;
    }, 'desc');
  }


  /**
   * Pega a lista de laudos
   * @returns {object[]}
   */
  getNotas() {
    const index = "Laudos"
    const entries = filter(this.data, item => item._index === index)
    const entriesByDate = groupByDate(entries);

    return map(entriesByDate, entry => {
      return {
        date: entry[0].date,
        total: entry.length > 1 ? entry.length : '',
        highlight: this.isHighlight(entry[0].date),
        component: <TooltipDefault
          label={index}
          date={entry[0].date}
          items={map(entry, item => {
            return {
              // startAt: '10h32 - Perfil prof. | Unidade',
              startAt: item._source.descricao_laudo,
              text: reactStringReplace(item._source.laudo_texto, regexKeywords,
                (match, i) =>
                <Typography
                  key={'text.highlight.notas.'+i}
                  display="inline"
                  style={{backgroundColor: keywords.includes(match) ?  "yellow" : '#16B8A760'}}
                >
                  {match}
                </Typography>
              ),
              highlight: this.isHighlight(entry[0].date),
            }
          })}
        />
      }
    })
  }

  /**
   * Lista os procedimentos
   * @returns {({date: Date, highlight: boolean, total: number, component: *}|{date: [*, *], total: number}|{date: [*, *], highlight: boolean, total: number}|{date: [*, *], highlight: boolean, total: number})[]}
   */
  getProcedimentos() {
    const index = "Procedimentos"
    const entries = filter(this.data, item => item._index === index)
    const entriesByDate = groupByDate(entries);
    return map(entriesByDate, entry => {
      return {
        date: entry[0].date,
        total: entry.length > 1 ? entry.length : '',
        highlight: this.isHighlight(entry[0].date),
        component: <TooltipDefault
          label="Procedimentos"
          date={entry[0].date}
          items={map(entry, item => {
            return {
              name: reactStringReplace(item._source.texto_cirurgia, regexKeywords,
                (match, i) =>
                <Typography
                  key={'text.highlight.notas.'+i}
                  display="inline"
                  style={{backgroundColor: keywords.includes(match) ?  "yellow" : '#16B8A760'}}
                >
                  {match}
                </Typography>
              ),
              startAt: 'Duração: '+item._source.tempo_cirurgia,
              highlight: this.isHighlight(entry[0].date),
            }
          })}
        />
      }
    })
  }

  /**
   * Define o background
   * @returns {({date: [*, *]}|{date: [*, *]})[]}
   */
  getEvidencias() {
    const index = "Suspeitas"
    const entries = filter(this.data, item => item._index === index)
    const entriesByDate = groupByDate(entries);
    return map(entriesByDate, entry => {
      return {
        date: [entry[0].date[0], entry[0].date[1]]
      }
    })
  }

}
