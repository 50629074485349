import React, { useState, useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Box, Button } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useToggleState } from '../../../../utils/useToggleState';
import { Snackbar } from '../../../atoms/Snackbar';

import { AssessmentAnalysisFormConfirmation } from './AssessmentAnalysisFormConfirmation';
import { AssessmentAnalysisFormAlert } from './AssessmentAnalysisFormAlert';
import { useAssessmentAnalysisFormContext } from '.';
import { formatErrorResponse } from '../../../../utils/formatters';
import { DangerButtonOutline } from '../../../atoms/DangerButtonOutline';
import { useTimelineAssessments } from '../../../../pages/Timeline/slices';

const GENERIC_INFECTIONS = ['itu', 'pnm', 'pav', 'traqueo', 'isc'];

const INFECTIONS_RESPIRATORY = ['pnm', 'pav', 'traqueo'];

export const AssessmentAnalysisFormSubmit = () => {
  const [alertText, setAlertText] = useState('');
  const [hideSendButton, setHideSendButton] = useState(false);
  // Estado do provider
  const {
    onSubmit,
    onCancel,
    onFinish,
    onEdit,
    isEditing,
    isLoading,
    isError,
    errorResponse,
    isSuccess,
  } = useAssessmentAnalysisFormContext();

  // Estado do formik
  const {
    values,
    resetForm,
    isSubmitting,
    isValidating,
    isValid,
    dirty,
    setSubmitting,
  } = useFormikContext();

  const isValidSubmission = dirty && !isValidating && isValid && isSubmitting;

  // Dialog de confirmação
  const {
    open: confirmOpen,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm,
  } = useToggleState();

  // Dialog de alerta
  const {
    open: alertOpen,
    handleOpen: handleOpenAlert,
    handleClose: handleCloseAlert,
  } = useToggleState();

  const assessments = useTimelineAssessments();

  // Executa submissão do formulário
  const handleConfirmSubmit = useCallback(() => {
    if (isEditing) {
      onEdit(values);
    } else {
      onSubmit(values);
    }
  }, [values, onSubmit]);

  // Finaliza a submissão e reseta o estado das mutations
  const handleOnCloseSnackbars = useCallback(
    (event, reason) => {
      // Previne o snackbar de aviso de cancelamento da requisição de fechar automaticamente antes de ser exibido.
      // Ao clicar no backdrop do dialogo de confirmação, a requisição é cancelada e o snackbar precisa estar visível.
      if (
        reason === 'clickaway' &&
        event?.target?.classList.contains('MuiDialog-container')
      )
        return false;

      // Callback de finalização
      onFinish();
    },
    [onFinish]
  );

  // Finalização da submissão
  const handleFinishFormSubmit = useCallback(() => {
    // Fecha o dialog
    handleCloseConfirm();
    handleCloseAlert();

    // Re habilita o form
    setSubmitting(false);
  }, [handleCloseConfirm, handleCloseAlert, setSubmitting]);

  // Cancela a requisição, fecha o dialog e reseta o estado de submitting do formik
  const handleCancelSubmit = useCallback(() => {
    // Cancela a request
    onCancel();
    // Finaliza a submissão
    handleFinishFormSubmit();
  }, [onCancel, handleFinishFormSubmit]);

  // Cancela a requisição, fecha o dialog e reseta o estado de submitting do formik
  const handleCancelAlert = useCallback(() => {
    // Cancela a request
    onCancel();
    // Finaliza a submissão
    handleFinishFormSubmit();
  }, [onCancel, handleFinishFormSubmit]);

  // Abre confirmação ao fazer o submit do form
  useEffect(() => {
    if (isValidSubmission) {
      if (!isEditing) {
        const formInfectionDate = values.assessmentDateInfection;
        const formInfectionName = values.assessmentInfection;

        const infection = assessments.find((assessment) => {
          const formEvaluationDate = moment(assessment.avalDtInfec);

          const evaluationDifferenceDays = formEvaluationDate.diff(
            formInfectionDate,
            'days'
          );

          const hasInfectionInPeriod =
            evaluationDifferenceDays >= -14 && evaluationDifferenceDays <= 14;

          return hasInfectionInPeriod;
        });

        if (infection?.tipoInfeccao) {
          setHideSendButton(false);
          const infectionName = infection.tipoInfeccao;

          if (INFECTIONS_RESPIRATORY.includes(formInfectionName) && INFECTIONS_RESPIRATORY.includes(infectionName)) {
            setAlertText(
              'Você já fez uma avaliação de outra infecção respiratória nos últimos 14 dias.'
            );
            setHideSendButton(true);
            handleOpenAlert();
            return;
          }

          if (formInfectionName === 'ipcs') {
            setAlertText(
              'Já existe outra avaliação de infecção em um período de 14 dias. Deseja avaliar uma infecção primária de corrente sanguínea ou secundária a infecção previamente avaliada?'
            );
            handleOpenAlert();
            return;
          }
          if (
            GENERIC_INFECTIONS.includes(formInfectionName) &&
            formInfectionName === infectionName
          ) {
            setAlertText(
              `Já existe outra avaliação da mesma infecção nos últimos 14 dias.`
            );
            setHideSendButton(true);
            handleOpenAlert();
            return;
          }

          handleOpenConfirm();

          return;
        }
      }

      handleOpenConfirm();
    }
  }, [isValidSubmission, handleOpenConfirm, assessments, values, setAlertText]);

  // Finalização com sucesso no envio
  useEffect(() => {
    if (isSuccess) {
      // Reseta estado do formulário
      resetForm();
      // Finaliza a submissão
      handleFinishFormSubmit();
    }
  }, [isSuccess, handleFinishFormSubmit, resetForm]);

  // Erro padrão
  const errorMessage = useMemo(
    () =>
      formatErrorResponse(
        errorResponse,
        'Ocorreu um erro no envio da sua avaliação.'
      ),
    [errorResponse]
  );

  return (
    <>
      {/* Botões de submit */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {!isEditing && (
          <DangerButtonOutline type="reset" disabled={isSubmitting}>
            Limpar campos
          </DangerButtonOutline>
        )}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting}
        >
          Enviar
        </Button>
      </Box>
      {/* Snackbars */}
      <Snackbar
        onClose={handleOnCloseSnackbars}
        severity="success"
        open={isSuccess}
        title="Operação realizada:"
        subtitle="A avaliação foi enviada com sucesso!"
      />
      <Snackbar
        key={errorMessage}
        onClose={handleOnCloseSnackbars}
        severity="error"
        open={isError}
        title="Operação não realizada:"
        subtitle={errorMessage}
      />
      {/* Dialog de confirmação */}
      <AssessmentAnalysisFormConfirmation
        loading={isLoading}
        open={confirmOpen}
        onCancel={handleCancelSubmit}
        onConfirm={handleConfirmSubmit}
      />
      {/* Dialog de alerta */}
      <AssessmentAnalysisFormAlert
        loading={isLoading}
        open={alertOpen}
        hideSendButton={hideSendButton}
        onCancel={handleCancelAlert}
        onConfirm={handleConfirmSubmit}
        alertText={alertText}
      />
    </>
  );
};
