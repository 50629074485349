import { getAssessmentsHistory } from '../services/api/history/history.service';
import { getAssessedInfections } from '../services/api/common/assessed.service';
import { removeAssessment } from '../services/api/history/history.service';

export const actionTypes = {
  GET_ASSESSMENTS_HISTORY: 'GET_ASSESSMENTS_HISTORY',
  GET_ASSESSMENTS_HISTORY_ERROR: 'GET_ASSESSMENTS_HISTORY_ERROR',
  SET_ASSESSMENT_HISTORY_LOADING: 'SET_ASSESSMENT_HISTORY_LOADING',
  REMOVE_ASSESSMENT: 'REMOVE_ASSESSMENT'
};

/**
 * Assessments History request success action creator
 *
 * @returns {{infectionTypes: *, type: string}}
 * @param assessmentsHistory
 * @param assessedInfections
 * @param filterParams
 */
const assessmentsHistorySuccessAction = (
  assessmentsHistory,
  assessedInfections,
  filterParams
) => ({
  type: actionTypes.GET_ASSESSMENTS_HISTORY,
  assessmentsHistory,
  assessedInfections,
  filterParams
});
/**
 * AssessmentsRemove
 *
 * @returns {{type: string}}
 */
const removeAssessmentByID = () => ({
  type: actionTypes.REMOVE_ASSESSMENT
});

export const removeAssessmentAction = (assessmentId) => async (dispatch) => {
  if(assessmentId){
    dispatch(removeAssessmentByID());
    return removeAssessment(assessmentId);
  } 
};
/**
 * AssessmentsHistory request error action creator
 *
 * @returns {{type: string}}
 */
const assessmentsHistoryErrorAction = () => ({
  type: actionTypes.GET_ASSESSMENTS_HISTORY_ERROR,
});

/**
 * AssessmentsHistory loading action creator
 *
 * @returns {{type: string}}
 */
const setLoadingAction = () => ({
  type: actionTypes.SET_ASSESSMENT_HISTORY_LOADING
});

export const assessmentsHistory = (filterParams) => async (dispatch) => {
  try {
    const {
      initialDate,
      finalDate,
    } = filterParams;

    dispatch(setLoadingAction());

    const assessmentsHistory = await getAssessmentsHistory(filterParams);
    const assessedInfections = await getAssessedInfections({
      initialDate,
      finalDate,
    });
    dispatch(
      assessmentsHistorySuccessAction(assessmentsHistory, assessedInfections, filterParams)
    );
  } catch (error) {
    dispatch(assessmentsHistoryErrorAction());
  }
};
