import React, { useMemo, useCallback } from 'react';
import { Box, Typography, Collapse } from '@material-ui/core';
import { Chip } from '../../../../../components/atoms/Chip';
import { useToggleState } from '../../../../../utils/useToggleState';
import useStyles from './styles';
import { useSetTimelineSearchWord } from '../../../slices';

export const TermItem = (props) => {
  const { term, count } = props;
  const classes = useStyles();
  const setSearchWord = useSetTimelineSearchWord();

  const handleClick = useCallback(() => setSearchWord(term), [
    setSearchWord,
    term,
  ]);

  return (
    <Chip
      label={`${term} (${count})`}
      className={classes.chip}
      onClick={handleClick}
    />
  );
};

export const TermsList = (props) => {
  const { terms } = props;
  const classes = useStyles();

  const { open, handleToggle } = useToggleState();

  const termsWithCount = useMemo(() => {
    return (terms || []).filter(({ count }) => !!count);
  }, [terms]);

  return (
    <>
      <Box mb="8px" display="flex" flexWrap="wrap">
        {termsWithCount.map(({ keyword: term, count }, index) => (
          <Collapse
            key={`term-${term}`}
            in={index < 5 || open}
            timeout="auto"
          >
            <TermItem term={term} count={count} />
          </Collapse>
        ))}
      </Box>
      {termsWithCount.length > 5 && (
        <Typography
          color="primary"
          className={classes.seeMore}
          onClick={handleToggle}
        >
          {open ? 'Ver menos' : 'Ver mais'}
        </Typography>
      )}
    </>
  );
};
