import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    //background: #F5F5F5;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 16px 'Nunito', serif;
  }

  h1, h2, h3, h4, h5, h6, strong{
    font-weight: 600;
  }

  button {
    cursor: pointer;
  }

  .active {
    font-weight: bold;
  }

  .container {
    margin-top: 96px;
  }
  /* #root {
    max-width: 960px;
    margin: 0 auto;
    padding: 40px 20px;
  } */
`;
