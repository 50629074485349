import { createSlice } from '@reduxjs/toolkit';
import {
  infectionTypesEnum,
  infectionTypesList,
  noInfection,
  otherInfection,
} from '../../../../constants/infections';

export const historyInitialState = {
  infections: [
    ...infectionTypesList,
    otherInfection,
    noInfection,
  ],
  page: 1,
  itemsPerPage: 10,
  name: '',
  probabilities: [],
  orderBy: 'dt_infeccao',
  assessment: 'all',
  community: [],
  location: [],
  evaluator: [],
};

const historyPageSlice = createSlice({
  name: 'historyPageSlice',
  initialState: historyInitialState,
  reducers: {
    resetFilters: (state) => {
      Object.assign(state, {
        infections: historyInitialState.infections,
        probabilities: historyInitialState.probabilities,
        location: historyInitialState.location,
        evaluator: historyInitialState.evaluator,
        assessment: historyInitialState.assessment,
        page: historyInitialState.page,
      });
    },
    setInfections: (state, { payload }) => {
      if (payload?.length) {
        state.infections = payload;
      } else {
        state.infections = historyInitialState.infections;
      }
      state.page = historyInitialState.page;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setItemsPerPage: (state, { payload }) => {
      state.itemsPerPage = payload;
      state.page = historyInitialState.page;
    },
    setName: (state, { payload }) => {
      state.name = payload;
      state.page = historyInitialState.page;
    },
    setProbabilities: (state, { payload }) => {
      state.probabilities = payload;
      state.page = historyInitialState.page;
    },
    setOrderBy: (state, { payload }) => {
      state.orderBy = payload;
    },
    setAssessment: (state, { payload }) => {
      state.assessment = payload;
      state.page = historyInitialState.page;
    },
    setCommunity: (state, { payload }) => {
      state.community = payload;
    },
    setLocation: (state, { payload }) => {
      state.location = payload;
      state.page = historyInitialState.page;
    },
    setEvaluator: (state, { payload }) => {
      state.evaluator = payload;
      state.page = historyInitialState.page;
    },
  },
});

export const {
  resetFilters,
  setInfections,
  setPage,
  setItemsPerPage,
  setName,
  setProbabilities,
  setOrderBy,
  setAssessment,
  setCommunity,
  setLocation,
  setEvaluator,
} = historyPageSlice.actions;
export default historyPageSlice.reducer;
