import { createApi } from '@reduxjs/toolkit/query/react'
import {  baseQueryWithReauth } from '../baseQuery'

export const microorganismsApi = createApi({
  reducerPath: 'microorganismsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getMicroorganisms: build.query({
      query: () => ({
        url: `/microorganisms`,
        method: 'GET'
      }),
    }),
  }),
})

export const { useGetMicroorganismsQuery } = microorganismsApi
