import React, { useEffect } from 'react';
import { useLastMonthIndicatorItems } from '../../hooks/useLastMonthIndicatorItems';
import { useMedicinesPeriods } from './useMedicinesPeriods';
import { MedicinesCard } from './MedicinesCard';

export const PopoverContentAntimicrobianos = ({ indicator }) => {
  const items = useLastMonthIndicatorItems(indicator);
  const medicines = useMedicinesPeriods(items);

  return <MedicinesCard medicines={medicines} />;
};
