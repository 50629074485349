import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectTimelineCursor } from './selectTimelineCursor';
import { selectTimelineDates } from './selectTimelineDates';

// Retorna as datas disponíveis para o indicador selecionado
export const selectTimelineAvailableDates = createSelector(
  [selectTimelineDates, selectTimelineCursor],
  (timelineDates, cursor) => {
    if (!timelineDates || !cursor.indicator) return;

    return timelineDates[cursor.indicator];
  }
);
export const useTimelineAvailableDates = () => useSelector(selectTimelineAvailableDates);

export const selectTimelineDateCursorIndex = createSelector(
  [selectTimelineAvailableDates, selectTimelineCursor],
  (availableDates, cursor) => {
    if (!availableDates?.length || !cursor.date) return;
    return availableDates.indexOf(cursor.date.valueOf());
  }
);

// retorna a próxima data disponível para o indicador selecionado
export const selectTimelineNextDay = createSelector(
  [selectTimelineAvailableDates, selectTimelineDateCursorIndex],
  (availableDates, dateCursorIndex) => {
    return availableDates?.[dateCursorIndex - 1];
  }
);
export const useTimelineNextDay = () => useSelector(selectTimelineNextDay);

// retorna a data anterior disponível para o indicador selecionado
export const selectTimelinePrevDay = createSelector(
  [selectTimelineAvailableDates, selectTimelineDateCursorIndex],
  (availableDates, dateCursorIndex) => {
    return availableDates?.[dateCursorIndex + 1];
  }
);
export const useTimelinePrevDay = () => useSelector(selectTimelinePrevDay);
