import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectTimelineIndicatorsDates } from './selectTimelineIndicatorsDates';
import { mapValues, uniq, isEqual } from 'lodash';

export const selectTimelineMedicamentosAlternations = createSelector(
  [selectTimelineIndicatorsDates],
  (indicatorsDates) => {
    if (!indicatorsDates?.medicamentos) return;

    const medicines = mapValues(indicatorsDates.medicamentos, (items) =>
      uniq(items.map((item) => item.medicamento).sort())
    );

    const sortedDates = Object.keys(medicines).sort((a, b) => a - b);

    const alternatedMedicines = sortedDates.reduce(
      (acc, date, index) => {
        const currentItem = medicines[date];
        const previousItem = medicines[sortedDates[index - 1]];
        const hasChanged = !isEqual(previousItem, currentItem);

        if (hasChanged) {
          acc.changeCount += 1;
        }

        acc.dates[date] = acc.changeCount;

        return acc;
      },
      { dates: {}, changeCount: 0 }
    );

    return alternatedMedicines.dates;
  }
);
export const useTimelineMedicamentosAlternations = () =>
  useSelector(selectTimelineMedicamentosAlternations);
