import { indicatorsEnum } from '../../constants/indicators';

export const indicatorsLabelsEnum = {
  [indicatorsEnum['sinais-vitais']]: 'Sinais vitais',
  [indicatorsEnum['exames']]: 'Exames',
  [indicatorsEnum['laudos']]: 'Laudos',
  [indicatorsEnum['movimentacao']]: 'Evolução',
  [indicatorsEnum['procedimentos']]: 'Procedimentos',
  [indicatorsEnum['medicamentos']]: 'Antimicrobianos',
  [indicatorsEnum['encontros']]: 'Evolução',
  [indicatorsEnum['suspeitas']]: 'Suspeitas',
  [indicatorsEnum['avaliacoes']]: 'Avaliações',
  [indicatorsEnum['infeccoes']]: 'Infecções',
}

/**
 * @param {string} indicator Chave do indicador
 * @returns Label formatada
 *
 * @example
 * formatIndicatorLabel('sinais-vitais'); // Sinais vitais
 * formatIndicatorLabel('movimentacao'); // Evolução
 */
 export const formatIndicatorLabel = (indicator) => {
  if( typeof indicator !== 'string' ) return '';
  return indicatorsLabelsEnum[indicator] || indicator;
}
