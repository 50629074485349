import React from 'react';
import { Divider } from '@material-ui/core';
import { indicatorsEnum } from '../../../../../constants/indicators';
import { IndicatorDateCard } from '../../IndicatorDateCard';
import { ExamGroup } from './ExamGroup';

export const ExamsDatesList = React.memo(function MemoExamsDatesList({
  examDates,
  onOpenHistogram,
}) {
  return (
    <>
      {examDates.map(({ date, momentDate, exams }, cardIndex) => (
        <IndicatorDateCard
          key={date}
          date={date}
          indicator={indicatorsEnum.exames}
          momentDate={momentDate}
          style={{ marginTop: cardIndex !== 0 ? '12px' : 0 }}
        >
          <div style={{ marginTop: '-8px' }}>
            {exams.map((group, groupIndex) => (
              <React.Fragment key={group.exam}>
                {groupIndex !== 0 && <Divider />}
                <ExamGroup group={group} onOpenHistogram={onOpenHistogram} />
              </React.Fragment>
            ))}
          </div>
        </IndicatorDateCard>
      ))}
    </>
  );
});
