import React, { useMemo } from 'react';
import { Collapse } from '@material-ui/core';
import { ExamTitle } from './ExamTitle';
import { ExamResults } from './ExamResults';
import { useToggleState } from '../../../../../utils/useToggleState';
import { ExamButton } from './ExamButton';
import { ExamChevron } from './ExamChevron';

export const ExamGroup = (props) => {
  const { group, onOpenHistogram } = props;
  const { exam, items, isCulture, hasPositive, hasCriteria } = group;
  const { open, handleToggle } = useToggleState();
  const content = useMemo(
    () => (
      <div>
        <ExamResults results={items} onOpenHistogram={onOpenHistogram} />
      </div>
    ),
    [items, onOpenHistogram]
  );

  return (
    <>
      <ExamButton
        onClick={handleToggle}
        style={{ backgroundColor: open ? 'white' : undefined }}
      >
        <ExamTitle
          title={exam}
          culture={isCulture}
          positive={hasPositive}
          criteria={hasCriteria}
          action={
            <ExamChevron
              style={{ transform: `rotate(${open ? 90 : -90}deg)` }}
            />
          }
        />
      </ExamButton>
      <Collapse
        in={open}
        style={{ backgroundColor: open ? 'white' : undefined }}
      >
        {content}
      </Collapse>
    </>
  );
};
