import { createSelector } from '@reduxjs/toolkit';
import { selectTimelineIndicatorsDates } from './selectTimelineIndicatorsDates';
import { selectTimelineCursor } from './selectTimelineCursor';
import { useSelector } from 'react-redux';

/**
 * retorna os dados do indicador selecionado no cursor
 */
const memoEmpty = [];
export const selectTimelineActiveIndicator = createSelector(
  [selectTimelineIndicatorsDates, selectTimelineCursor],
  (indicatorsDates, cursor) => {
    if (!indicatorsDates || !cursor.indicator || !cursor.date) return memoEmpty;
    return (
      indicatorsDates[cursor.indicator]?.[cursor.date.valueOf()] || memoEmpty
    );
  }
);
export const useTimelineActiveIndicator = () => {
  return useSelector(selectTimelineActiveIndicator);
};
