import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { selectPatientsEvaluated } from './selectPatientsEvaluated';
import { selectPatientsInfections } from './selectPatientsInfections';
import { selectPatientsName } from './selectPatientsName';
import { selectPatientsOrderBy } from './selectPatientsOrderBy';
import { selectPatientsPage } from './selectPatientsPage';
import { selectPatientsProbabilities } from './selectPatientsProbabilities';
import { selectPatientsItemsPerPage } from './selectPatientsItemsPerPage';
import {
  selectEndDate,
  selectStartDate,
} from '../../../../../templates/DateRangeTemplate';
import { probabilityLevelsEnum } from '../../../../../constants/probabilities';

export const selectPatientsFilters = createSelector(
  [
    selectPatientsEvaluated,
    selectPatientsInfections,
    selectPatientsProbabilities,
    selectPatientsName,
    selectPatientsPage,
    selectPatientsItemsPerPage,
    selectPatientsOrderBy,
    selectStartDate,
    selectEndDate,
  ],
  (
    evaluated,
    infections,
    probabilities,
    name,
    page,
    itemsPerPage,
    orderBy,
    startDate,
    endDate
  ) => {
    const filters = {
      infectionType: infections,
      probability: {
        lowProbability: probabilities.includes(probabilityLevelsEnum.BAIXA),
        mediumProbability: probabilities.includes(probabilityLevelsEnum.MEDIA),
        highProbability: probabilities.includes(probabilityLevelsEnum.ALTA),
      },
      itensPerPage: itemsPerPage,
      name,
      page,
      orderBy,
      startDate,
      endDate,
      generalSuspectsFetch: true
    };

    switch (evaluated) {
      case 'avaliadas':
        filters.evaluated = true;
        break;
      case 'nao-avaliadas':
        filters.evaluated = false;
        break;
      default:
        break;
    }

    return filters;
  },
);
export const usePatientsFilters = () => useSelector(selectPatientsFilters);
