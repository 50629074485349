import React, { useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import { min } from 'lodash';
import { Box, Divider } from '@material-ui/core';

import { EXTRACTED_CRITERIAS } from '../../../../../components/atoms/TimelineChart/Tooltips/constants';
import GraphDialogComponent from '../../../../../components/organisms/GraphDialog';
import { Card } from '../../../../../components/atoms/Card';
import HistogramChart from '../../../../../components/atoms/HistogramChart';
import { useIndicatorItemsByPeriod } from '../../hooks/useIndicatorItemsByPeriod';
import { indicatorsEnum } from '../../../../../constants/indicators';
import { ExtractedCriteriaList } from './ExtractedCriteriaList';

export const extractCriteriaFromEncontro = (encontro) => {
  const { criterios_extraidos_json } = encontro;

  return EXTRACTED_CRITERIAS.map((key) => ({
    key,
    value: criterios_extraidos_json[key] || [],
  })).filter((item) => item.value.length && min(item.value));
};

const usePeriodExtractedCriteria = ({ date, offset = 7 }) => {
  const startDate = useMemo(
    () => moment(date).add({ days: -offset }).startOf('day'),
    [date, offset]
  );
  const endDate = useMemo(
    () => moment(date).add({ days: offset }).endOf('day'),
    [date, offset]
  );
  const periodItems = useIndicatorItemsByPeriod({
    indicator: indicatorsEnum.encontros,
    startDate,
    endDate,
  });
  const periodCriteria = useMemo(() => {
    return periodItems
      .map((encontro) => ({
        encontro,
        criteria: extractCriteriaFromEncontro(encontro),
      }))
      .filter((e) => e.criteria.length);
  }, [periodItems]);
  return periodCriteria;
};

export const ExtractedCriteria = ({ encontro, ...cardProps }) => {
  const {
    _meta: { momentTime: date, momentDate },
  } = encontro;
  const periodCriteria = usePeriodExtractedCriteria({ date });

  const [selectedCriteria, setSelectedCriteria] = useState(null);
  const [open, setOpen] = useState(false);
  const histogramDate = useMemo(() => momentDate.toDate(), [momentDate]);

  const criteria = useMemo(() => {
    return extractCriteriaFromEncontro(encontro);
  }, [encontro]);

  const dataset = useMemo(() => {
    if (!selectedCriteria) return [];

    return periodCriteria
      .filter((item) =>
        min(item.encontro.criterios_extraidos_json[selectedCriteria])
      )
      .map((item) => {
        const minValue = min(
          item.encontro.criterios_extraidos_json[selectedCriteria]
        );

        return {
          date: item.encontro._meta.momentDate.toDate(),
          _source: {
            tipo_sinal: selectedCriteria,
            valor: minValue,
            aux: item.encontro,
          },
        };
      });
  }, [periodCriteria, selectedCriteria]);

  const handleClick = useCallback(
    (criteria) => {
      setSelectedCriteria(criteria);
      setOpen(true);
    },
    [setSelectedCriteria, setOpen]
  );

  return (
    <>
      {!!criteria.length && (
        <Card
          radius="small"
          shadow={false}
          {...cardProps}
          style={{
            padding: 0,
            marginBottom: '8px',
            ...cardProps?.style
          }}
        >
          <ExtractedCriteriaList onClick={handleClick} items={criteria} />
        </Card>
      )}
      <GraphDialogComponent
        title={
          selectedCriteria ? `Critérios Extraídos: ${selectedCriteria}` : ''
        }
        open={open}
        unit={''}
        setOpen={setOpen}
      >
        {!!dataset.length && (
          <HistogramChart dataset={dataset} date={histogramDate} />
        )}
      </GraphDialogComponent>
    </>
  );
};
