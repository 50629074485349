import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { assessmentStatusesEnum } from '../../../constants/assessments';
import { formatAssessmentStatusLabel } from '../../../utils/formatters';
import { Card } from '../../../components/atoms/Card';
import { InfectionChip } from '../../../components/atoms/Chip';
import { includes, isEqual } from 'lodash';

import {
  AssessmentCardText,
  AssessmentCardTitle,
  AssessmentCardAuthor,
  AssessmentCardSubtitle,
  AssessmentCardContent,
} from './styles';
import { AssessmentCardMenu } from './AssessmentCardMenu';

const assessmentCardVariantMap = {
  [assessmentStatusesEnum.positiva]: 'success',
  [assessmentStatusesEnum.negativa]: 'danger',
  [assessmentStatusesEnum.inconclusiva]: 'default',
};

const formatComment = (comment) => {
  if (!comment || typeof comment !== 'string') return <></>;
  return comment
    .trim()
    .replace(/\n+/gim, '\n')
    .split('\n')
    .map((line, lineIndex) => (
      <React.Fragment key={lineIndex}>
        {line}
        <br />
      </React.Fragment>
    ));
};

export const AssessmentCard = (props) => {
  const { assessment, hideMenu, ...cardProps } = props;

  const {
    status: status,
    avaliacaoData: assessmentDate,
    tipoInfeccao: infection,
    avalDtInfec: infectionDate,
    avaliacaoResponsavel: author,
    comentario: comment,
    localInfeccao: location,
    comunitaria: communitary,
    relacionadoCateterSonda: relatedToCatheter,
    relacionado: related,
  } = assessment;

  const variant = communitary ? 'danger' : assessmentCardVariantMap[status];

  const statusLabel = useMemo(
    () => formatAssessmentStatusLabel(status),
    [status]
  );

  const formattedDate = useMemo(
    () => moment(assessmentDate).format(`ddd, L, HH[h]mm`),
    [assessmentDate]
  );

  const formattedInfectionDate = useMemo(
    () => moment(infectionDate).format('L'),
    [infectionDate]
  );

  const subtitle = useMemo(() => {
    const subtitleItems = [];

    if (communitary) {
      subtitleItems.push('Comunitario');
    }

    if (location && location !== 'undefined') {
      subtitleItems.push(location);
    }

    return subtitleItems.join(' • ');
  }, [communitary, location]);

  const title = `${statusLabel}: ${formattedInfectionDate}`;

  return (
    <Card {...cardProps}>
      <Box
        mb="12px"
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Box flex="0 0 auto">
            <InfectionChip
              size="small"
              variant={variant}
              infection={infection}
              style={{ textTransform: 'uppercase' }}
            />
          </Box>
          <Box flex="1 1 100%" paddingLeft="6px">
            <AssessmentCardTitle variant={variant}>{title}</AssessmentCardTitle>
          </Box>
        </Box>
        <Box flex="0 0 auto">
          {!hideMenu && <AssessmentCardMenu assessment={assessment} />}
        </Box>
      </Box>
      <Box>
        <AssessmentCardSubtitle mb="12px">{subtitle}</AssessmentCardSubtitle>
        {comment && (
          <AssessmentCardContent mb="12px">
            {formatComment(comment)}
          </AssessmentCardContent>
        )}
        {includes(['ipcs', 'itu'], infection) && (
          <>
            <AssessmentCardContent mb="12px">
              Relacionada a cateter/sonda?&nbsp;
              <InfectionChip
                size="small"
                variant={relatedToCatheter || related ? 'success' : 'danger'}
                infection={relatedToCatheter || related ? ' Sim ' : ' Não '}
              />

            </AssessmentCardContent>
            <AssessmentCardContent mb="12px">
              Relacionada a
              {isEqual(infection, 'ipcs') ? ' Cateter Venoso Central (CVC)? ' : '' }
              {isEqual(infection, 'itu') ? ' Sonda Vesical de Demora (SVD)? ' : '' }

              <InfectionChip
                size="small"
                variant={related ? 'success' : 'danger'}
                infection={related ? ' Sim ' : ' Não '}
              />
            </AssessmentCardContent>
          </>
        )}
        <AssessmentCardAuthor>{author}</AssessmentCardAuthor>
        <AssessmentCardText component="time">
          {formattedDate}
        </AssessmentCardText>
      </Box>
    </Card>
  );
};
