import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { parseTermosAchados } from '../../../../utils/parseTermosAchados';
import { selectTimelineEncontroActive } from './selectTimelineEncontroActive';

const memoEmpty = [];
export const selectTimelineEncontroTerms = createSelector(
  selectTimelineEncontroActive,
  (encontro) => {
    if (!encontro) return memoEmpty;
    return parseTermosAchados(encontro.termos_achados);
  }
);
export const useTimelineEncontroTerms = () =>
  useSelector(selectTimelineEncontroTerms);
