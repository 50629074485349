import React from 'react';
import PropTypes from 'prop-types';
import { GridToolbar } from '@mui/x-data-grid';
import { DataGrid, DataGridContainer } from './styles';
import { DEFAULT_PAGINATION, ROW_PER_PAGE_OPTIONS } from './constants';
import { useMemo } from 'react';

export default function DataTable(props) {
  const {
    columns,
    rows,
    header,
    loading,
    pagination: paginationOptions,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    ...dataGridProps
  } = props;

  const pagination = useMemo(
    () => ({
      ...paginationOptions,
      page: paginationOptions.page - 1,
    }),
    [paginationOptions]
  );

  const {
    components = {},
    componentsProps = {},
    ...otherDataGridProps
  } = dataGridProps;

  const {
    toolbar: toolbarProps,
    pagination: paginationProps,
    ...otherComponentsProps
  } = componentsProps;

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      loading={loading}
      components={{
        Toolbar: 'div',
        ...components,
      }}
      componentsProps={{
        toolbar: {
          children: header,
          ...toolbarProps,
        },
        pagination: {
          labelRowsPerPage: 'Itens por página:',
          labelDisplayedRows: ({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : 'mais que' + to}`,
          ...paginationProps,
        },
        ...otherComponentsProps,
      }}
      paginationMode="server"
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      rowCount={pagination.totalCount}
      rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      page={pagination.page}
      pageSize={pagination.itemsPerPage}
      headerHeight={40}
      disableColumnMenu
      disableSelectionOnClick
      hideFooterSelectedRowCount
      onSortModelChange={onSortModelChange}
      {...otherDataGridProps}
    />
  );
}

DataTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  header: PropTypes.element,
  loading: PropTypes.bool,
  onPageChange: PropTypes.func,
};

DataTable.defaultProps = {
  columns: [],
  rows: [],
  header: null,
  pagination: DEFAULT_PAGINATION,
  onPageChange: () => {},
};
