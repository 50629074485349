import { Box, withStyles } from '@material-ui/core';

export const TimelineTitle = withStyles(theme => ({
  root: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '17px',
    color: theme.palette.dark.secondary,
    [theme.breakpoints.down('md')]: {
      marginTop: '15px'
    }
  }
}))(Box)
