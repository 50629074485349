import React from 'react';
export const CheckIconDefault = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="4" fill="#F7F7F7" />
    <rect
      x="0.5"
      y="0.5"
      width="19"
      height="19"
      rx="3.5"
      stroke="#3E4247"
      strokeOpacity="0.48"
    />
  </svg>
);

export const CheckIconChecked = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="4" fill="#16B8A7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.08917 11.9107L15.8333 4.16667L17.0118 5.34518L8.08917 14.2678L3.33325 9.51185L4.51176 8.33334L8.08917 11.9107Z"
      fill="white"
    />
  </svg>
);
