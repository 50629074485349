import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { groupBy } from 'lodash';
import moment from 'moment';
import { selectTimelinePatientFacts } from './selectTimelinePatientFacts';
import { selectTimelineAssessments } from './selectTimelineAssessments';

/**
 * Todos os indicadores
 */
export const selectTimelineIndicators = createSelector(
  [selectTimelinePatientFacts, selectTimelineAssessments],
  (facts, assessments) => {
    if (!facts || !assessments) return;
    const groupedIndicators = groupBy(facts, '_meta.indicator');

    if (groupedIndicators.suspeitas) {
      // O indicador de infecções é uma cópia do indicador de suspeita
      groupedIndicators.infeccoes = groupedIndicators.suspeitas.sort((a, b) => {
        return new Date(a.dt_inicio) > new Date(b.dt_inicio) ? -1 : 1
      }).map(
        (suspicion) => {
          return {
            ...suspicion,
            _meta: {
              ...suspicion._meta,
              indicator: 'infeccoes',
            },
          };
        }
      );
    }

    if (assessments.length) {
      groupedIndicators.infeccoes = groupedIndicators.infeccoes || [];
      // O indicador de infecções também agrupa entradas de avaliações
      const assessmentsInfections = assessments
        .filter(
          (assessment) =>
            assessment.status === 'positiva' && !assessment.comunitaria
        )
        .map((assessment) => {
          const momentTime = moment(assessment.avalDtInfec);
          const momentDate = momentTime.clone().startOf('day');
          const date = momentDate.valueOf();

          return {
            ...assessment,
            _meta: {
              indicator: 'infeccoes',
              momentTime,
              momentDate,
              date,
            },
          };
        });

      groupedIndicators.infeccoes = [
        ...groupedIndicators.infeccoes,
        ...assessmentsInfections,
      ];
    }

    return groupedIndicators;
  }
);
export const useTimelineIndicators = () => {
  return useSelector(selectTimelineIndicators);
};
