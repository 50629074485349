import React, { useMemo } from 'react';

import { useTimelineDateRange } from '../slices';
import {
  SQUARE_WIDTH,
  SQUARE_HEIGHT,
  ENTRY_X_CENTER,
  ENTRY_Y_CENTER,
} from './constants';
import { useTimelineChartContext } from './TimelineChartProvider';

export const DatesLegends = () => {
  const dateRange = useTimelineDateRange();
  const { xScale } = useTimelineChartContext();

  const datesLegends = useMemo(() => {
    const xCenter = ENTRY_X_CENTER;
    const yCenter = ENTRY_Y_CENTER;

    return (
      <g data-name="date-legends">
        {dateRange?.map((date) => {
          const timestamp = date.valueOf();
          return (
            <g transform={`translate(${xScale(timestamp)} 0)`} key={timestamp}>
              <rect
                width={SQUARE_WIDTH}
                height={SQUARE_HEIGHT}
                x={0}
                y={0}
                fill="transparent"
              />
              <g transform={`translate(${xCenter} ${yCenter})`}>
                <text
                  fontSize="11px"
                  fontFamily="DM Mono"
                  stroke="#3E4247"
                  strokeWidth={0}
                  opacity={0.72}
                  textAnchor="middle"
                  fontWeight={400}
                  letterSpacing='0.03em'
                  textRendering="optimizeLegibility"
                  x={0}
                  y={-3}
                >
                  {date.format('MMM').toUpperCase()}
                </text>
                <text
                  fontSize="11px"
                  fontFamily="DM Mono"
                  stroke="#3E4247"
                  strokeWidth={0}
                  textAnchor="middle"
                  fontWeight={400}
                  letterSpacing='0.03em'
                  textRendering="optimizeLegibility"
                  x={0}
                  y={11}
                >
                  {date.format('DD')}
                </text>
              </g>
            </g>
          );
        })}
      </g>
    );
  }, [dateRange, xScale]);

  return <g data-name="x-legends">{datesLegends}</g>;
};
