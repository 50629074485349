import { createSelector } from '@reduxjs/toolkit';
import { selectPatientsSlice } from './selectPatientsSlice';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { patientsInitialState } from '../slice';

export const selectPatientsFiltersCount = createSelector(
  selectPatientsSlice,
  (patientsState) =>
    [
      !isEqual(patientsState.infections, patientsInitialState.infections),
      !isEqual(patientsState.probabilities, patientsInitialState.probabilities),
    ].filter(Boolean).length
);
export const usePatientsFiltersCount = () =>
  useSelector(selectPatientsFiltersCount);
