import React, { useMemo } from 'react';
import { HorizontalBarChart } from '../HorizontalBarChart';
import { getIndicatorValue } from '../../../../../utils/formatters/formatDashboardIndicators'

import { useTheme } from '@material-ui/core';
import { useSelectedCompetence } from '../../slices';

const randomValue = () => Math.floor(Math.random() * 10 * (Math.random() * 20));

export const InfectionsByLocal = ({ indicators }) => {
  const competence = useSelectedCompetence();
  const theme = useTheme();
  const isLoading = false;
  const dataset = useMemo(
    () => [
      {
        label: 'CONFIRMADO',
        color: 'rgba(43, 172, 118, 0.74)',
        values: [
          {
            label: 'Centro Cirúrgico',
            value: getIndicatorValue(indicators, 'infeccoes_confirmados_por_local_Centro Cirúrgico', competence),
          },
          {
            label: 'Unidade 2B',
            value: getIndicatorValue(indicators, 'infeccoes_confirmados_por_local_Unidade 2B', competence),
          },
          {
            label: 'Unidade 5',
            value: getIndicatorValue(indicators, 'infeccoes_confirmados_por_local_Unidade 5', competence),
          },
          {
            label: 'Unidade 6',
            value: getIndicatorValue(indicators, 'infeccoes_confirmados_por_local_Unidade 6', competence),
          },
          {
            label: 'Unidade 7',
            value: getIndicatorValue(indicators, 'infeccoes_confirmados_por_local_Unidade 7', competence),
          },
          {
            label: 'Unidade 2C',
            value: getIndicatorValue(indicators, 'infeccoes_confirmados_por_local_Unidade 2C', competence),
          },
          {
            label: 'UTI Adulto ',
            value: getIndicatorValue(indicators, 'infeccoes_confirmados_por_local_UTI adulto', competence),
          },
        ],
      },
      {
        label: 'NÃO CONFIRMADO',
        color: 'rgba(254, 72, 105, 0.74)',
        values: [
          {
            label: 'Centro Cirúrgico',
            value: 0,
          },
          {
            label: 'Unidade 2B',
            value: 0,
          },
          {
            label: 'Unidade 5',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmados_por_localUnidade 5', competence),
          },
          {
            label: 'Unidade 6',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmados_por_localUnidade 6', competence),
          },
          {
            label: 'Unidade 7',
            value: 0,
          },
          {
            label: 'Unidade 2C',
            value: 0,
          },
          {
            label: 'UTI Adulto ',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmados_por_localUTI adulto', competence),
          },
        ],
      },
    ],
    [theme, competence, indicators]
  );

  return (
    <HorizontalBarChart
      isLoading={isLoading}
      dataset={dataset}
      // aspectRatio={3.8}
      height={dataset[0].values.length * 64}
    />
  );
};
