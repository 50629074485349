import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setItemsPerPage } from '../slice';

export const useSetPatientsItemsPerPage = () => {
  const dispatch = useDispatch();

  const handleSetPatientsItemsPerPage = useCallback(
    (itemsPerPage) => dispatch(setItemsPerPage(itemsPerPage)),
    [dispatch]
  );

  return handleSetPatientsItemsPerPage;
};
