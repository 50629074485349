/**
 * Uma infecção pode ser considerada de probabilidade ALTA, MEDIA ou BAIXA
 *
 * @constant
 * @type {{ ALTA: 'ALTA', MEDIA: 'MEDIA', BAIXA: 'BAIXA' }}
 */
 export const probabilityLevelsEnum = {
  ALTA: 'ALTA',
  MEDIA: 'MEDIA',
  BAIXA: 'BAIXA',
};

/**
 * Uma infecção pode ser considerada de probabilidade ALTA, MEDIA ou BAIXA
 *
 * @constant
 * @type {['ALTA', 'MEDIA', 'BAIXA']}
 */
export const probabilityLevelsList = Object.keys(
  probabilityLevelsEnum
);
