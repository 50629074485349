import React from 'react';
import { Box } from '@material-ui/core';
import { indicatorsEnum } from '../../../../constants/indicators';
import { TimelineDrawerAntimicrobianos } from './TimelineDrawerAntimicrobianos';
import { TimelineDrawerEvolucao } from './TimelineDrawerEvolucao';
import { TimelineDrawerExames } from './TimelineDrawerExames';
import { TimelineDrawerInfeccoes } from './TimelineDrawerInfeccoes';
import { TimelineDrawerLaudos } from './TimelineDrawerLaudos';
import { TimelineDrawerProcedimentos } from './TimelineDrawerProcedimentos';
import { TimelineDrawerSinaisVitais } from './TimelineDrawerSinaisVitais';
import { TimelineDrawerSuspeitas } from './TimelineDrawerSuspeitas';

export const timelineDrawerContentMap = {
  [indicatorsEnum['suspeitas']]: TimelineDrawerSuspeitas,
  [indicatorsEnum['sinais-vitais']]: TimelineDrawerSinaisVitais,
  [indicatorsEnum['procedimentos']]: TimelineDrawerProcedimentos,
  [indicatorsEnum['laudos']]: TimelineDrawerLaudos,
  [indicatorsEnum['infeccoes']]: TimelineDrawerInfeccoes,
  [indicatorsEnum['exames']]: TimelineDrawerExames,
  [indicatorsEnum['encontros']]: TimelineDrawerEvolucao,
  [indicatorsEnum['medicamentos']]: TimelineDrawerAntimicrobianos,
};

export const TimelineDrawerContent = ({ indicator, date }) => {
  const ContentComponent = timelineDrawerContentMap[indicator] || 'div';

  return (
    <Box
      flex="1 1 auto"
      margin="-16px"
      width="calc(100% + 32px)"
      height="calc(100% + 32px)"
    >
      <ContentComponent indicator={indicator} date={date} />
    </Box>
  );
};
