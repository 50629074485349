import { actionTypes } from "../actions/SettingsActions";
import { cloneDeep } from "lodash";

const initialState = {
  isLoading: true,
  infections: {
    pav: {
      ranges: [
        { from: 70, to: 100 },
        { from: 50, to: 69 },
        { from: 0, to: 49 },
      ],
    },
    ipcs: {
      ranges: [
        { from: 70, to: 100 },
        { from: 50, to: 69 },
        { from: 0, to: 49 },
      ],
    },
    traqueo: {
      ranges: [
        { from: 70, to: 100 },
        { from: 50, to: 69 },
        { from: 0, to: 49 },
      ],
    },
    isc: {
      ranges: [
        { from: 70, to: 100 },
        { from: 50, to: 99 },
        { from: 0, to: 49 },
      ],
    },
    itu: {
      ranges: [
        { from: 70, to: 100 },
        { from: 20, to: 69 },
        { from: 0, to: 49 },
      ],
    },
    pnm: {
      ranges: [
        { from: 70, to: 100 },
        { from: 30, to: 89 },
        { from: 0, to: 49 },
      ],
    },
    geral: {
      ranges: [
        { from: 70, to: 100 },
        { from: 30, to: 89 },
        { from: 0, to: 49 },
      ],
    },
  }
};

export const settingsReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.SET_INFECTION_PARAMS:
      const lowRange = [0, action.range[0]];
      const mediumRange = [action.range[0], action.range[1]];
      const highRange = [(action.range[1]), 100];

      return {
        ...state,
        isLoading: false,
        infections: {
          ...state.infections,
          [action.infection]: {
            ranges: [
              { from: highRange[0], to: highRange[1] },
              { from: mediumRange[0], to: mediumRange[1] },
              { from: lowRange[0], to: lowRange[1] },
            ]
          }
        }
      }
    case actionTypes.GET_INFECTION_PARAMS_SUCCESS:
      const infections = cloneDeep(state.infections);
      action.infectionParams.forEach((infectionParam) => {
        const [, prob, name ] = infectionParam.meta.split('-');
        const valueIndex = prob === 'baixa' ? 2 : prob === 'media' ? 1 : 0;
        infections[name].ranges[valueIndex].to = parseInt(infectionParam.value);
        // if (valueIndex < 2) {
        //   infections[name].ranges[valueIndex + 1].to = infections[name].ranges[valueIndex].to
        // }
      });

      return {
        ...state,
        isLoading: false,
        infections
      }
    case actionTypes.SET_SETTINGS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    default: {
      return {
        ...state
      }
    }
  }

}
