import React from 'react';
import { useSelectedCompetence } from '../../slices'
import { Grid } from '@material-ui/core';
import {
  RiPsychotherapyLine as PatientsIcon,
  RiStethoscopeLine as ExamsIcon,
  RiFileUserLine as EvolutionIcon,
  RiCapsuleLine as MedicinesIcon,
  RiThermometerLine as VitalSignsIcon,
} from 'react-icons/ri';

import { IndicatorStat } from './IndicatorStat';
import { getIndicatorValue } from '../../../../../utils/formatters/formatDashboardIndicators';

const ProceduresIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.69 12.9181L17.459 14.6861C11.449 20.6961 6.49898 20.6961 2.25598 19.2821L17.812 3.72607L21.348 7.26107L15.691 12.9181H15.69ZM12.862 12.9181L18.519 7.26107L17.812 6.55407L6.31398 18.0521C9.04598 18.1591 11.672 17.1451 14.581 14.6361L12.862 12.9181Z"
      fill="#16B8A7"
    />
  </svg>
);

export const DashboardIndicators = (props) => {
  const { indicators, selectedCompetence } = props;
  const competence = useSelectedCompetence();

  if(!indicators) {
    return ''
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={4} lg={2}>
        <IndicatorStat
          icon={<PatientsIcon />}
          title="Pacientes"
          value={getIndicatorValue(indicators, 'pacientes_masculino', competence) + getIndicatorValue(indicators, 'pacientes_feminino', competence)}
          helper="Média 1 mês"
        />
      </Grid>
      <Grid item sm={4} lg={2}>
        <IndicatorStat
          icon={<ExamsIcon />}
          title="Exames"
          value={getIndicatorValue(indicators, 'exames', competence)}
          helper="Média 1 mês"
        />
      </Grid>
      <Grid item sm={4} lg={2}>
        <IndicatorStat
          icon={<ProceduresIcon />}
          title="Procedimentos"
          value={getIndicatorValue(indicators, 'procedimentos', competence)}
          helper="Média 1 mês"
        />
      </Grid>
      <Grid item sm={4} lg={2}>
        <IndicatorStat
          icon={<EvolutionIcon />}
          title="Evoluções"
          value={getIndicatorValue(indicators, 'evolucoes', competence)}
          helper="Média 1 mês"
        />
      </Grid>
      <Grid item sm={4} lg={2}>
        <IndicatorStat
          icon={<MedicinesIcon />}
          title="Antibióticos"
          value={getIndicatorValue(indicators, 'medicamentos', competence)}
          helper="Média 1 mês"
        />
      </Grid>
      <Grid item sm={4} lg={2}>
        <IndicatorStat
          icon={<VitalSignsIcon />}
          title="Sinais Vitais"
          value={getIndicatorValue(indicators, 'sinais vitais', competence)}
          helper="Média 1 mês"
        />
      </Grid>
    </Grid>
  );
};
