import React, { useState } from 'react'
import moment from 'moment';
import { Box } from '@material-ui/core'
import { useTimelineActiveIndicator, useTimelineIndicators } from '../../../slices'
import InsertChartOutlined from '@material-ui/icons/InsertChartOutlined';
import { vitalSignsList } from '../../../../../constants/vitalSigns';
import GraphDialogComponent from '../../../../../components/organisms/GraphDialog';
import HistogramChart from '../../../../../components/atoms/HistogramChart';
import { TimelineDrawerListItem } from '../../TimelineDrawerListItem';
import { formatItems } from './util';
import { formatUnitsOfMeasureLabel } from '../../../../../utils/formatters'
import { orderBy } from 'lodash-es';

export const TimelineDrawerSinaisVitais = ({ indicator, date }) => {
  const vitalSigns = useTimelineActiveIndicator()
  const { 'sinais-vitais': allItems } = useTimelineIndicators();
  const items = formatItems(vitalSigns);

  const [open, setOpen] = useState(false);
  const [dataset, setDataset] = useState({})
  const [grapTitle, setGrapTitle] = useState('');
  const [grapUnit, setGrapUnit] = useState('');

  const onClickGraph = (vitalSign) => {
    setOpen(true);

    const currentDate = new Date(date);
    const subDays = moment(currentDate).add({ days: -7 }).startOf('day').toDate()
    const addDays = moment(currentDate).add({ days: 7 }).endOf('day').toDate()

    const vitalSignsGraphData = orderBy(allItems.filter(item => item.tipo_sinal === vitalSign)
    .filter(item => {

      const date = new Date(item.dthr_coleta);

      return date.getTime() >= subDays.getTime() &&
        date.getTime() <= addDays.getTime()
    })
    .map(item => {
      return {
        date: new Date(new Date(item.dthr_coleta).toLocaleDateString('en-US', { dateStyle: 'short' })),
        _source: {
          ...item
        }
      }
    }), '_source.dthr_coleta', 'asc')

    setDataset(vitalSignsGraphData)
    setGrapTitle(vitalSign)
    setGrapUnit(vitalSignsGraphData[0]._source.unimedida)
  }


  return (
      <Box padding={2}>
        { vitalSignsList.map((sign, i) => (
          items[sign] && <TimelineDrawerListItem
            key={i}
            label={items[sign].signalType}
            value={formatUnitsOfMeasureLabel(items[sign])}
            hasCriterias={items[sign].hasCriterias}
            icon={<InsertChartOutlined />}
            handleClick={() => onClickGraph(sign)} />
          ))}

        <GraphDialogComponent title={grapTitle} open={open} unit={grapUnit} setOpen={() => setOpen(false)}>
          <HistogramChart dataset={dataset} date={new Date(new Date(vitalSigns[0].dthr_coleta).toLocaleDateString('en-US', { dateStyle: 'short' }))}/>
        </GraphDialogComponent>
      </Box>
  )
}
