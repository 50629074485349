import { createSelector } from '@reduxjs/toolkit';
import { selectHistorySlice } from './selectHistorySlice';
import { useSelector } from 'react-redux';

export const selectHistoryItemsPerPage = createSelector(
  selectHistorySlice,
  (historyState) => historyState.itemsPerPage
);
export const useHistoryItemsPerPage = () =>
  useSelector(selectHistoryItemsPerPage);
