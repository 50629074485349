import styled from "styled-components";
import { Chip, withStyles } from "@material-ui/core";

export const HorizontalChipsSuccess = styled.div`
  padding: 0 0 0 0;
`;

export const SmallChip = withStyles((theme) => ({
  root: {
    borderColor: ({status = 'default'}) => theme.palette.pill[status].main,
    background: ({status = 'default'}) => theme.palette.pill[status].secondary,
    height: 20
  },
  label: {
    color: ({status = 'default'}) => theme.palette.pill[status].text,
    fontSize: theme.MuiTypography.sizes.small,
    fontFamily: theme.MuiTypography.family.secondary,
    paddingRight: 8,
    paddingLeft: 8,
  }
}))(Chip);


export const MyChip = styled(Chip)( props=> ({
  borderColor: '#8F95A3',
  height: '27px!important',
  marginRight: 5,
  '& span': {
    fontSize: 13,
    fontFamily: 'DM Mono',
  },
  '&.success': {
    background: 'rgba(38, 191, 114, 0.2);',
    color: '#1C8C54',
    border: '1px solid rgba(38, 191, 114, 0.5)',
    paddingLeft: '7px',
    '.MuiChip-icon': {
      color: '#1C8C54',
      fontSize: '16px',
      '&:hover': {
        color: '#1C8C54'
      }
    }
  },
  '@media (max-width: 960px)': {
    marginBottom: '8px',
  }
}));
