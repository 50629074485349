import styled from 'styled-components';

export const Timeline = styled.div`
  padding-top: 20px;
  .MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
    border-color: white;
  }
`;
export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 94px;
  padding-right: 30px;
`;
