import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { assessmentsApi } from '../../../../services';
import { selectTimelinePatientId } from './selectTimelinePatientId';
import { orderBy } from 'lodash';
import { HIDE_FROM } from '../../../hideAnonymousEvaluation';

/**
 * Ordena e mapeia avaliacoes da timeline
 * @param {*} assessmentsResponse
 */
export const mapTimelineAssessments = (assessmentsResponse) => {
  const { results: assessments, _metadata } = assessmentsResponse;

  // Pendente fazer verificação pelas roles
  const username = localStorage.getItem('username');
  const filteredAssessments = assessments.filter((assessment) => {
    if (HIDE_FROM.includes(username)) {
      return assessment.avaliacaoResponsavel !== 'qualis_roboisa_anonimo';
    }

    return true;
  });

  // Ordena e expõe o lista de avaliações
  const orderedAssessments = orderBy(
    filteredAssessments,
    ['avaliacaoData'],
    ['desc']
  );

  return orderedAssessments;
};

// Selector que mescla o patientId da timeline, busca a query de assessments com este id e mapeia o resultado
export const selectTimelineAssessmentsSelector = createSelector(
  selectTimelinePatientId,
  (patientId) => assessmentsApi.endpoints.getAssessmentsList.select(patientId)
);
export const selectTimelineAssessmentsData = createSelector(
  [(state) => state, selectTimelineAssessmentsSelector],
  (state, selector) => selector(state)?.data
);
export const selectTimelineAssessments = createSelector(
  selectTimelineAssessmentsData,
  (data) => (data ? mapTimelineAssessments(data) : [])
);
export const useTimelineAssessments = () => {
  return useSelector(selectTimelineAssessments);
};
