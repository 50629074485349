import { createSelector } from '@reduxjs/toolkit';
import { selectPatientsSlice } from './selectPatientsSlice';
import { useSelector } from 'react-redux';

export const selectPatientsProbabilities = createSelector(
  selectPatientsSlice,
  (patientsState) => patientsState.probabilities
);
export const usePatientsProbabilities = () =>
  useSelector(selectPatientsProbabilities);
