export const settingsRequestBody = (infParams =[]) => {
  const infections = {
    metas: []
  };

  infParams.forEach((infectionName) => {
    const { metas } = infections;
    metas.push(
      `prob-baixa-${infectionName}`,
      `prob-media-${infectionName}`,
      `prob-alta-${infectionName}`
    );
  });

  infections.metas.push(
    `prob-baixa-geral`,
    `prob-media-geral`,
    `prob-alta-geral`
  );
  return infections;
};