import React, { useCallback } from 'react';
import {
  MenuItem as MuiMenuItem,
  ListItemText as MuiListItemText,
  withStyles,
  Icon as MuiIcon,
} from '@material-ui/core';
import { useDropMenuContext } from './DropMenuProvider';

const MenuItem = withStyles((theme) => ({
  root: {
    padding: '12px',
    minWidth: '162px',
  },
}))(MuiMenuItem);

const ListItemText = withStyles((theme) => ({
  root: {
    margin: (props) => (props.spaceLeft ? '0 0 0 16px' : 0),
  },
  primary: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
  },
}))(MuiListItemText);

const Icon = withStyles((theme) => ({
  root: {
    marginRight: '20px',
  },
}))(MuiIcon);

export const DropMenuItem = (props) => {
  const { icon: IconComponent, onClick, title, ...menuItemProps } = props;
  const { handleClose } = useDropMenuContext();

  const handleClick = useCallback(
    (e) => {
      handleClose();
      if (onClick) onClick(e);
    },
    [handleClose, onClick]
  );

  return (
    <MenuItem {...menuItemProps} onClick={handleClick}>
      {IconComponent && <Icon component={IconComponent} />}
      <ListItemText primary={title} spaceLeft={!!IconComponent} />
    </MenuItem>
  );
};
