import styled from "styled-components";
import { Chip } from "@material-ui/core";

export const HorizontalChipsBasic = styled.div`
  padding: 0 0 0 0;
`;

export const MyChip = styled(Chip)( props=> ({
  borderColor: props.iswarning === "true" ? '#FE3C4C !important': '#8F95A3',
  height: '27px!important',
  marginRight: '5px!important',
  marginBottom: 7,
  '& svg': {
    position: 'absolute',
    right: '9px',
    height: '17px',
  },
  '& span': {
    fontSize: 13,
    fontFamily: 'DM Mono',
  },
  '&.basico': {
    height: '23px!important',
    color: props.iswarning === "true" ? '#FE3C4C': '#8F95A3',
    paddingRight: props.iswarning === "true" ? '13px': '0',
  },
  '@media (max-width: 960px)': {
    marginBottom: '8px',
  }
}));

export const ToolTipContainer = styled.div`
  position: relative;
  display: inline-flex;
  .MuiChip-label {cursor: pointer};
`;

export const ToolTipBody = styled.div`
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 1;
  transition: 500ms ease-out;
`;

