import { probabilityLevelsEnum } from '../../constants/probabilities';

/**
 * Retorna o label que representa uma probabilidade de infecção
 *
 * @param {{ baixa: number, media: number }} settings objeto settings com as configurações de probabilidades
 * @param {number} probability Valor da probabilidade, de 0 a 1
 * @returns {'ALTA' | 'MEDIA' | 'BAIXA'}
 * 
 * @example
 * const settings = { media: 50, baixa: 25 }
 * 
 * formatProbabilityLevel(settings, .50) // ALTA
 * formatProbabilityLevel(settings, .25) // MEDIA
 * formatProbabilityLevel(settings, .15) // BAIXA
 */
export const formatProbabilityLevel = (settings, probability) => {
  if (!settings) return probabilityLevelsEnum.BAIXA;

  if (probability >= settings.media / 100) {
    return probabilityLevelsEnum.ALTA;
  } else if (probability >= settings.baixa / 100) {
    return probabilityLevelsEnum.MEDIA;
  } else {
    return probabilityLevelsEnum.BAIXA;
  }
};
