import moment from 'moment';

export const patientEvolutionRequestBody = (
  paciente_id,
  initialDate = moment('2020-01-01'),
  finalDate = moment()
) => ({
  size: 22000,
  from: 0,
  query: {
    bool: {
      should: [
        {
          bool: {
            must: {
              term: { prontuario: paciente_id },
            },
            filter: {
              term: {
                _index: 'encontros',
              },
            },
            should: [
              {
                range: {
                  dt_encontro: {
                    gte: initialDate.toISOString(),
                    lte: finalDate.toISOString(),
                  },
                },
              },
            ],
          },
        },
      ],
    },
  },
});
