import React from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { PageTemplate, PageHeading, PageTitle } from '../PageTemplate';
import { DateRange } from './DateRange';

export const DateRangeTemplate = withStyles(() => ({
  root: {},
  container: {},
  heading: {},
  headingContainer: {},
  headingTitle: {},
  headingAction: {},
  headingDivider: {},
  title: {},
}))(function StyledDateRangeTemplate({
  classes,
  className,
  children,
  title,
  info,
  divider,
  dateRange,
  extraActions
}) {
  return (
    <PageTemplate
      classes={{
        root: clsx(classes.root, className),
        container: classes.container,
      }}
    >
      <PageHeading
        classes={{
          root: classes.heading,
          container: classes.headingContainer,
          title: classes.headingTitle,
          action: classes.headingAction,
          divider: classes.headingDivider,
        }}
        title={
          <PageTitle info={info} className={classes.title}>
            {title}
          </PageTitle>
        }
        divider={divider}
        action={
          [
            ...(extraActions && extraActions.length ? extraActions : []),
            <DateRange />
          ]
        }
      />
      {children}
    </PageTemplate>
  );
});
