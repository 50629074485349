import React from 'react';
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export const TimelineIndicatorsSkeleton = () => {
  return (
    <Grid container ml="4px" spacing={1}>
      <Grid item xs="auto">
        <Skeleton
          variant="rect"
          width={87}
          height={21}
          style={{ borderRadius: '10000em' }}
        />
      </Grid>
      <Grid item xs="auto">
        <Skeleton
          variant="rect"
          width={108}
          height={21}
          style={{ borderRadius: '10000em' }}
        />
      </Grid>
      <Grid item xs="auto">
        <Skeleton
          variant="rect"
          width={58}
          height={21}
          style={{ borderRadius: '10000em' }}
        />
      </Grid>
      <Grid item xs="auto">
        <Skeleton
          variant="rect"
          width={122}
          height={21}
          style={{ borderRadius: '10000em' }}
        />
      </Grid>
      <Grid item xs="auto">
        <Skeleton
          variant="rect"
          width={72}
          height={21}
          style={{ borderRadius: '10000em' }}
        />
      </Grid>
      <Grid item xs="auto">
        <Skeleton
          variant="rect"
          width={108}
          height={21}
          style={{ borderRadius: '10000em' }}
        />
      </Grid>
      <Grid item xs="auto">
        <Skeleton
          variant="rect"
          width={58}
          height={21}
          style={{ borderRadius: '10000em' }}
        />
      </Grid>
    </Grid>
  );
};
