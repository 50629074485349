import React from 'react';
import { FieldControl } from './FieldControl';

export const withFieldControl = (InputComponent) =>
  React.forwardRef((props, ref) => {
    const { margin, controlClasses, ...inputProps } = props;
    const { error, disabled, label, helperText, fullWidth } = inputProps;

    return (
      <FieldControl
        classes={controlClasses}
        error={error}
        disabled={disabled}
        label={label}
        helperText={helperText}
        fullWidth={fullWidth}
        margin={margin}
        disabled={disabled}
      >
        <InputComponent {...inputProps} ref={ref} />
      </FieldControl>
    );
  });
