import { useMemo, useCallback } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectTimelineSlice } from './selectTimelineSlice';

/**
 * Seleciona os indicadores visitados
 */
export const selectTimelineVisited = createSelector(
  selectTimelineSlice,
  (timeline) => timeline.visited
);
export const useTimelineVisited = () => useSelector(selectTimelineVisited);

/**
 * Seleciona as datas visitadas para o indicador passado por parametro
 */
export const makeSelectTimelineVisitedIndicator = () => {
  const selectTimelineVisitedIndicator = createSelector(
    selectTimelineSlice,
    (_, indicator) => indicator,
    (timeline, indicator) => timeline.visited[indicator]
  );

  return selectTimelineVisitedIndicator;
};
export const useTimelineVisitedIndicator = (indicator) => {
  const select = useMemo(makeSelectTimelineVisitedIndicator, []);
  const selector = useCallback((state) => select(state, indicator), [
    indicator,
    select,
  ]);
  return useSelector(selector);
};
