import { useCallback } from 'react';
import {
  useTimelineCursor,
  useTimelineNextIndicator,
  useTimelinePrevIndicator,
} from '../selectors';
import { useSetTimelineCursor } from './useSetTimelineCursor';

export const useSetTimelineCursorIndicator = () => {
  const setCursor = useSetTimelineCursor();
  const { date } = useTimelineCursor();

  const setIndicator = useCallback(
    (indicator) => setCursor({ indicator, date }),
    [setCursor, date]
  );

  return setIndicator;
};

export const useSetTimelineNextIndicator = () => {
  const setIndicator = useSetTimelineCursorIndicator();
  const nextIndicator = useTimelineNextIndicator();
  const setNextIndicator = useCallback(() => {
    if (!nextIndicator) return;
    setIndicator(nextIndicator);
  }, [setIndicator, nextIndicator]);

  return setNextIndicator;
};

export const useSetTimelinePrevIndicator = () => {
  const setIndicator = useSetTimelineCursorIndicator();
  const prevIndicator = useTimelinePrevIndicator();
  const setPrevIndicator = useCallback(() => {
    if (!prevIndicator) return;
    setIndicator(prevIndicator);
  }, [setIndicator, prevIndicator]);

  return setPrevIndicator;
};
