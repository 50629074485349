import React, { useEffect, useState } from "react";
import { array, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
  MySwitch,
  MyFormControlLabel,
  MySelect,
  MyFormControlSelect
} from "./styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const TopFilter = (props) => {
  const {
    infectionTypes =  [],
    sendInfectionsToParent = () => {},
    sendOnlyInconclusiveToParent = () => {},
    sendOrderByToParent = () => {},
    infections = [],
    onlyInconclusive = true,
    orderBy = '',
    showOnlyInconclusiveFilter = true
  } = props;

  const classes = useStyles();
  const [orderByField, setOrderByField] = useState('');
  const [selectedInfections, setInfections] = useState(() => []);
  const [onlyInconclusiveInfections, setOnlyInconclusiveInfections] = useState(true);

  const handleChange = (event) => {
    const { value } = event.target;

    setOrderByField(value);
    sendOrderByToParent(value);
  };

  const handleFilter = (event, newInfections) => {
    setInfections(newInfections);
    sendInfectionsToParent(newInfections);
  };

  const handleChangeSwitch = () => {
    setOnlyInconclusiveInfections(!onlyInconclusiveInfections);
    sendOnlyInconclusiveToParent(!onlyInconclusiveInfections);
  };

  useEffect(() => {
    setInfections(infections);
    setOnlyInconclusiveInfections(onlyInconclusive);
    setOrderByField(orderBy)
  }, [
    orderBy,
    infections,
    onlyInconclusive
  ]);

  return(
    <Grid container spacing={2}>
      <Grid item xs={12} md={2}>
        <MyFormControlSelect variant="filled" className={classes.formControl}
               size="small">
          <InputLabel id="demo-simple-select-filled-label">Ordenar por</InputLabel>
          <MySelect
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={orderByField}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Nenhum</em>
            </MenuItem>
            <MenuItem value={'name'}>Nome</MenuItem>
            <MenuItem value={'dtInfeccao'}>Data de infecção</MenuItem>
          </MySelect>
        </MyFormControlSelect>
      </Grid>

      <Grid item xs={12} md={7}>
        <ToggleButtonGroup value={selectedInfections} onChange={handleFilter}
             aria-label="text formatting">
          {
            infectionTypes.map((infectionType) => {
              return <ToggleButton key={infectionType} value={infectionType} aria-label={infectionType}>
                {infectionType}
              </ToggleButton>;
            })
          }
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

TopFilter.propTypes = {
  infectionTypes: array.isRequired,
  sendInfectionsToParent: func,
  onlyInconclusive: bool,
  showOnlyInconclusiveFilter: bool
};

/*const mapStateToProps = (state) => ({
  globalStateInfections: state.pendingAssessmentsFilter.infections,
  globalStateOnlyInconclusive: state.pendingAssessmentsFilter.onlyInconclusive,
  globalStateOrderBy: state.pendingAssessmentsFilter.orderByField
});*/

export default connect(null, null) (TopFilter);
