import moment from 'moment';

const pendingAssessmentsRequestBody = ({
   initialDate,
   finalDate,
   highProbability,
   mediumProbability,
   lowProbability,
   infectionsFilter,
   orderByField,
   nome
 }) => {

  const probabilities = [];

  if(highProbability)
    probabilities.push('ALTA');

  if(mediumProbability)
    probabilities.push('MEDIA');

  if(lowProbability)
    probabilities.push('BAIXA');

  let infections;
  if(infectionsFilter.length) {
    infections = `,"minimum_should_match": 1,"should" : [${infectionsFilter.map((infection) => {return `{"range":{"pred_${infection}":{"gte": 1}}}`})}]`
  } else infections = '';

  let probabilitiesStr;

  if(probabilities.length)
    probabilitiesStr = `,"filter": [{"terms": {"prob_grupo_isa": ${JSON.stringify(probabilities)}}}]}}`;
  else probabilitiesStr = '}}';

  let filterNome = "";
  if(nome && nome.length)
    filterNome = `{"query_string": {"query": "*${nome}*", "default_field": "nome"}}, `;

  return (`{"index": "suspeitas"}
{"size": 30000, "from": 0,"query":{"bool" : {"must" : [${filterNome} {"range": {"dt_infeccao": {"gte": "${moment(initialDate).toISOString()}","lte": "${moment(finalDate).toISOString()}"}}}] ${infections}${probabilitiesStr},"sort": ${[orderByField === 'nome' ? `{"nome": {"order": "asc"}}` : `{"dt_infeccao": {"order": "desc"}}`]}}
{"index": ["laudos","encontros","medicamentos","exames","procedimentos","sinais-vitais"]}
{"size": 0,"from": 0,"query": {"bool": {"should": [{"bool": {"minimum_should_match": 1,"should": [{"range": {"dthr_pedido": {"gte": "${moment(initialDate).toISOString()}","lte": "${moment(finalDate).toISOString()}"}}},{  "range": {"dthr_entrega_laudo": {"gte": "${moment(initialDate).toISOString()}","lte": "${moment(finalDate).toISOString()}"}}}],"filter": {"term": {"_index": "laudos"}}}},{"bool": {"minimum_should_match": 1,"should": [{"range": {"dt_encontro": {"gte": "${moment(initialDate).toISOString()}","lte": "${moment(finalDate).toISOString()}"}}}],"filter": {"term": {"_index": "encontros"}}}},{"bool": {"minimum_should_match": 1,"should": [{"range": {"dthr_pedido": {"gte": "${moment(initialDate).toISOString()}","lte": "${moment(finalDate).toISOString()}"}}},{  "range": {"dthr_entrega": {"gte": "${moment(initialDate).toISOString()}","lte": "${moment(finalDate).toISOString()}"}}}],"filter": {"term": {"_index": "exames"}}}},{"bool": {"minimum_should_match": 1,"should": [{"range": {"dthr_coleta": {"gte": "${moment(initialDate).toISOString()}","lte": "${moment(finalDate).toISOString()}"}}}],"filter": {"term": {"_index": "sinais-vitais"}}}},{"bool": {"minimum_should_match": 1,"should": [{"range": {"dthr_prescricao": {"gte": "${moment(initialDate).toISOString()}","lte": "${moment(finalDate).toISOString()}"}}}],"filter": {"term": {"_index": "medicamentos"}}}},{"bool": {"minimum_should_match": 1,"should": [{"range": {"dthr_criacao": {"gte": "${moment(initialDate).toISOString()}","lte": "${moment(finalDate).toISOString()}"}}},{  "range": {"dthr_fim_procedimento": {"gte": "${moment(initialDate).toISOString()}","lte": "${moment(finalDate).toISOString()}"}}}],"filter": {"term": {"_index": "procedimentos"}}}}]}},"aggs": {"my_buckets": {"composite": {"size": 30000,"sources": [{"paciente": { "terms": { "field": "paciente_id" } }}]},"aggregations": {"by_index": {"terms": { "field": "_index"}}}}}}

`);
};

export default pendingAssessmentsRequestBody;
