import React from 'react';
import { Box } from '@material-ui/core';
import { useTimelineActiveIndicator } from '../../../slices';
import { useSuspicionInfections } from '../../../../../utils/hooks/useSuspicionInfections';
import { useGetSettingsQuery } from '../../../../../services';
import { TimelineDrawerListItem } from '../../TimelineDrawerListItem';
import { formatItems } from './util';

export const TimelineDrawerSuspeitas = () => {
  const { data: settings } = useGetSettingsQuery('');
  const indicators = useTimelineActiveIndicator();
  const firstIndicator = indicators?.[0] || {};
  const suspicions = useSuspicionInfections(firstIndicator);
  const items = formatItems(firstIndicator, suspicions, settings);

  return (
    <Box padding={2}>
      {items.map(({ label, value, level }, index) => (
        <TimelineDrawerListItem
          key={`${label}-${value}-${index}`}
          label={label}
          value={value}
          level={level}
        />
      ))}
    </Box>
  );
};
