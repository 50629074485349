import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../baseQuery';
import { transformResponseHits } from '../common/utils/transformResponse';
import { allPatientRequestBody } from './requestBody/patient.all.body';
import { patientRequestBody } from './requestBody/patient.body';
import { patientFactsRequestBody } from './requestBody/patient.facts.body';
import { patientEvolutionRequestBody } from './requestBody/patient.evolution.body';
import { patientPillsRequestBody } from './requestBody/patient.pills.body';
import moment from 'moment';
import { timeDays } from 'd3';
import { orderBy } from 'lodash';

export const patientApi = createApi({
  reducerPath: 'patientApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 1,
  endpoints: (build) => ({
    /**
     * Busca os dados do paciente
     */
    getPatientById: build.query({
      query: (patientId) => ({
        url: `/pacientes/${patientId}`,
        method: 'GET'
      })
    }),
    getPatient: build.query({
      query: (patientId) => ({
        url: `/elk/`,
        method: 'POST',
        body: {
          path: `pacientes/_search?filter_path=_scroll_id,hits.hits._source,hits.total&scroll=1m`,
          body: patientRequestBody(patientId),
        },
      }),
      transformResponse: (response) => {
        try {
          const patient = response.hits.hits[0]._source;
          return patient;
        } catch (e) {
          return {};
        }
      },
    }),
    getAllPatient: build.query({
      query: () => ({
        url: `/elk/`,
        method: 'POST',
        body: {
          path: `pacientes/_search?filter_path=_scroll_id,hits.hits._source,hits.total&scroll=1m`,
          body: allPatientRequestBody(),
        },
      }),
      transformResponse: transformResponseHits,
    }),
    /**
     * Busca os dados detalhados de um paciente
     * Medicamentos, Supeitas, Laudos, Movimentação e outros...
     */
    getPatientFacts: build.query({
      query: (patientId) => ({
        url: `/elk/`,
        method: 'POST',
        body: {
          path: `suspeitas,infeccoes,laudos,encontros,exames,sinais-vitais,medicamentos,procedimentos/_search?filter_path=hits.hits._source,hits.hits._index,hits.total&scroll=1m`,
          body: patientFactsRequestBody(patientId),
        },
      }),
      transformResponse: (data) => {
        const response = data.hits ? data.hits.hits || [] : [];

        const indicatorsDateKeys = {
          'sinais-vitais': 'dthr_coleta',
          exames: 'dthr_pedido',
          laudos: 'dthr_pedido',
          procedimentos: 'dthr_procedimento',
          medicamentos: 'dthr_prescricao',
          encontros: 'dt_encontro',
          suspeitas: 'dt_infeccao',
        };

        const items = response.map((item) => {
          const indicator = item._index;
          const momentTime = moment(
            item._source[indicatorsDateKeys[indicator]]
          );
          const momentDate = momentTime.clone().startOf('day');
          const date = momentDate.valueOf();

          const meta = {
            indicator,
            momentTime,
            momentDate,
            date,
          };

          if (indicator === 'suspeitas') {
            const momentStart = moment(item._source.dt_inicio);
            const momentEnd = moment(item._source.dt_fim);
            meta.momentStart = momentStart;
            meta.momentEnd = momentEnd;
            meta.dateRange = timeDays(
              momentStart.valueOf(),
              momentEnd.clone().add({ days: 1 }).valueOf()
            ).map((d) => d.getTime());
          }

          if (indicator === 'encontros') {
            item._source.criterios_extraidos_json = JSON.parse(
              item._source.criterios_extraidos
            );
          }

          if (indicator === 'exames') {
            if ( item._source.item_exame === "LEUCÓCITOS") {
              item._source.criterio = "sim";
            }
          }

          // if (indicator === 'sinais-vitais') {
          //   item._source.parsedValue = parseFloat(
          //     parseFloat(item._source.valor.replace(',', '.')).toFixed(1)
          //   );
          // }

          item._source._meta = meta;

          return item;
        });

        return orderBy(items, ['_source._meta.date'], ['desc']);
      },
    }),
    /**
     * Busca os dados detalhados de um paciente
     * Medicamentos, Supeitas, Laudos, Movimentação e outros...
     */
    getPatientEvolution: build.query({
      query: ({ patientId, initialDate, finalDate }) => ({
        url: `/elk/`,
        method: 'POST',
        body: {
          path: `encontros/_search?filter_path=hits.hits._source,hits.hits._index,hits.total&scroll=1m`,
          body: patientEvolutionRequestBody(patientId), // TODO: ADICIONAR INITIAL DATE E FINALDATE PARA NOVO REQUEST
        },
      }),
      transformResponse: (data, meta, args) => {
        const response = data.hits ? data.hits.hits : [];

        const items = response.map((item) => {
          const indicator = item._index;

          const momentTime = moment(item._source.dt_encontro);

          const momentDate = momentTime.clone().startOf('day');
          const date = momentDate.valueOf();

          const meta = {
            indicator,
            momentTime,
            momentDate,
            date,
          };

          item._source.criterios_extraidos_json = JSON.parse(
            item._source.criterios_extraidos
          );

          item._source._meta = meta;

          return item;
        });

        const ordered = orderBy(items, ['_source._meta.date'], ['desc']);

        // const itemsOfDate = ordered.filter((item) =>
        //   moment(item._source.dt_encontro).isSame(args.finalDate)
        // );

        return ordered;
      },
    }),

    getPatientPills: build.query({
      query: (patientId) => ({
        url: `/elk/`,
        method: 'POST',
        body: {
          path: `laudos,encontros,exames,sinais-vitais,medicamentos,procedimentos/_search?filter_path=aggregations.by_index.buckets`,
          body: patientPillsRequestBody({ patientId: patientId }),
        },
      }),
      transformResponse: (payload) => payload.aggregations?.by_index?.buckets,
    }),

    getPatientHospitalization: build.query({
      query: (patientId) => ({
        url: `/suspeitas/${patientId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetPatientByIdQuery,
  useGetPatientQuery,
  useGetPatientFactsQuery,
  useGetPatientEvolutionQuery,
  useGetPatientPillsQuery,
  useGetAllPatientQuery,
  useGetPatientHospitalizationQuery,
} = patientApi;
