import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {bool, func} from "prop-types";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Grid } from "@material-ui/core";
import { useGetSettingsQuery } from "../../../services";

const FormDialogProbabilidade = (props) => {
  const {
    open,
    setOpen,
    sendProbabilitiesToParent,
    globalHighProbability,
    globalMediumProbability,
    globalLowProbability
  } = props;

  const {
    data: settings,
  } = useGetSettingsQuery('')
  console.log(settings)

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilter = () => {
    sendProbabilitiesToParent({
      high: highProbability,
      medium: mediumProbability,
      low: lowProbability
    });
    setOpen(false);
  };

  const [highProbability, setHighProbability] = useState(true);
  const [mediumProbability, setMediumProbability] = useState(true);
  const [lowProbability, setLowProbability] = useState(true);

  useEffect(() => {
    setHighProbability(globalHighProbability);
    setMediumProbability(globalMediumProbability);
    setLowProbability(globalLowProbability);
  },[
    globalMediumProbability,
    globalHighProbability,
    globalLowProbability
  ]);
  if(!settings) return ''

  return (
    <Dialog onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Probabilidade
        <IconButton className="closeButton"
                    aria-label="close"
                    onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid>
          <FormControlLabel
            className="border labelDialog"
            labelPlacement="start"
            label={`Alta probabilidade (maior que ${settings.media}%)`}
            control={
              <Switch checked={highProbability}
                      onChange={() => setHighProbability(!highProbability)}
                      name="highProbability"
                      color="primary"
              />
            }
          />
        </Grid>
        <Grid>
          <FormControlLabel
            className="border labelDialog"
            labelPlacement="start"
            label={`Média probabilidade (entre ${settings.baixa}% e ${settings.media}%)`} 
            control={
              <Switch checked={mediumProbability}
                      onChange={() => setMediumProbability(!mediumProbability)}
                      name="mediumProbability"
                      color="primary"/>
            }
          />
        </Grid>
        <Grid>
          <FormControlLabel
            className="labelDialog"
            labelPlacement="start"
            label={`Baixa probabilidade (menor que ${settings.baixa}%)`} 
            control={
              <Switch checked={lowProbability}
                      onChange={() => setLowProbability(!lowProbability)}
                      name="lowProbability"
                      color="primary"/>
            }
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className="buttonDialog" autoFocus onClick={handleFilter}>
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

FormDialogProbabilidade.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
  sendProbabilitiesToParent: func
};

const mapStateToProps = (state) => ({
  globalHighProbability: state.pendingAssessmentsFilter.highProbability,
  globalMediumProbability: state.pendingAssessmentsFilter.mediumProbability,
  globalLowProbability: state.pendingAssessmentsFilter.lowProbability
});

export default connect(mapStateToProps, null) (FormDialogProbabilidade);
