import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

export const Card = withStyles((theme) => ({
  root: {},
  // Colors variants
  white: {
    backgroundColor: theme.palette.common.white,
  },
  gray: {
    backgroundColor: theme.palette.grey['50'],
  },
  // Shadow
  shadow: {
    boxShadow: '0px 1px 3px -1px rgba(0, 0, 0, 0.4)',
  },
  outlined: {
    border: `1px solid ${theme.palette.divider}`,
  },
  // Sizes (Padding)
  small: {
    padding: '8px',
  },
  large: {
    padding: '16px 12px',
  },
  //
  smallRadius: {
    borderRadius: '6px',
  },
  largeRadius: {
    borderRadius: '8px',
  },
}))(
  React.forwardRef(function StyledCard(props, ref) {
    const {
      children,
      classes,
      size,
      radius,
      color,
      shadow: hasShadow,
      outlined: hasOutline,
      ...divProps
    } = props;

    const className = clsx(
      props.className,
      classes.root,
      classes[color],
      classes[size],
      classes[radius === 'small' ? 'smallRadius' : 'largeRadius'],
      hasShadow && classes.shadow,
      hasOutline && classes.outlined
    );

    return (
      <div {...divProps} className={className} ref={ref}>
        {children}
      </div>
    );
  })
);
Card.defaultProps = {
  color: 'white',
  size: 'large',
  radius: 'large',
  shadow: true,
  outlined: false,
};
