import React from 'react';
import {Icon, Tooltip, Typography} from "@material-ui/core";
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#393a3e',
    fontFamily: 'DM Mono'
  },
  arrow: {
    color: '#393a3e',
  },
  title: {
    fontSize: '10px',
    textTransform: 'uppercase',
    color: '#FFF',
    margin: '12px 8px'
  },
  list: {
    background: 'transparent',
    marginLeft: '28px',
  },
  content: {
    fontSize: '12px',
    color: '#FFF'
  },
  icon: {
    color: '#8f95a4',
    display: 'inline-block',
    '& svg': {
      fontSize: '22px'
    }
    },
}));

const EvaluationTooltip = (props) => {
  const classes = useStyles();
  const {
    ruleTitle,
    rules
  } = props;

  return (
    <Tooltip
      arrow
      classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
      title={
      <div style={{paddingTop: '6px'}}>
        <Typography className={classes.title} align="center" display="inline">Um paciente é considerado {ruleTitle} quando:</Typography>
        <ul style={{margin: '7px 0px'}}>
          {
            rules?.map((data) =>
            <li key={data} className={classes.list}>{data}</li>)
          }
        </ul>
      </div>
    }>
      <Icon className={classes.icon} fontSize="small" component="div">
        <InfoIcon />
      </Icon>
    </Tooltip>
  )
};

EvaluationTooltip.propTypes = {
  ruleTitle: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EvaluationTooltip;
