import { createSelector } from '@reduxjs/toolkit';
import { selectTimelineSlice } from './selectTimelineSlice';
import { useSelector } from 'react-redux';
import removeAccents from 'remove-accents';

export const selectTimelineSearchWord = createSelector(
  selectTimelineSlice,
  (timeline) =>
    !timeline.searchWord
      ? ''
      : removeAccents(timeline.searchWord).replace(/[^a-zA-Z0-9 ]/g, '')
);
export const useTimelineSearchWord = () =>
  useSelector(selectTimelineSearchWord);
