import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, withStyles, alpha } from '@material-ui/core';
import { DropMenu, DropMenuItem } from '../../molecules/DropMenu';
import {
  DeleteCommentDialog,
  useDeleteCommentDialog,
} from './DeleteCommentDialog';
import {
  SingleCommentContext,
  useSingleCommentContext,
} from './SingleCommentContext';
import { useCommentsContext } from './CommentsContext';
import { useToggleState } from '../../../utils/useToggleState';

const SingleMessageBox = withStyles((theme) => ({
  root: {
    backgroundColor: (props) =>
      props.highlight
        ? alpha(theme.palette.primary.main, 0.1)
        : theme.palette.neutral.gray.xlt,
    borderRadius: '8px',
    padding: '8px',
    marginTop: theme.spacing(1),
  },
}))(Box);

const SingleMessageText = withStyles((theme) => ({
  root: {
    fontFamily: theme.MuiTypography.family.secondary,
    color: theme.palette.neutral.black[88],
    fontSize: '13px',
    lineHeight: '1.3',
    marginBottom: '2px',
  },
}))(Box);
const SingleMessageHeader = withStyles((theme) => ({
  root: {
    fontFamily: theme.MuiTypography.family.secondary,
    color: theme.palette.neutral.black[72],
    fontSize: '13px',
    lineHeight: '18px',
    marginBottom: '2px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))(Box);

const SingleMessageFooter = withStyles((theme) => ({
  root: {
    fontFamily: theme.MuiTypography.family.secondary,
    color: theme.palette.neutral.black[48],
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '2px',
  },
}))(Box);

const ChatCommentMenu = () => {
  const { onOpen: openDeleteCommentDialog, ...deleteCommentDialogProps } =
    useDeleteCommentDialog();
  const { handleStartEdit } = useCommentsContext();

  const comment = useSingleCommentContext();

  const handleEdit = useCallback(() => {
    handleStartEdit(comment);
  }, [handleStartEdit, comment]);

  return (
    <>
      <DropMenu menuProps={{ style: { zIndex: 1500 } }}>
        <DropMenuItem title="Editar" onClick={handleEdit} />
        <DropMenuItem title="Excluir" onClick={openDeleteCommentDialog} />
      </DropMenu>
      <DeleteCommentDialog {...deleteCommentDialogProps} />
    </>
  );
};

const ChatCommentMessage = () => {
  const { author, content, date, id } = useSingleCommentContext();
  const { edit } = useCommentsContext();
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    if (edit.id === id) {
      setHighlight(true);
      return () => setHighlight(false);
    }
  }, [edit]);

  return (
    <SingleMessageBox highlight={highlight}>
      <SingleMessageHeader>
        <span>{author}</span>
        <ChatCommentMenu />
      </SingleMessageHeader>
      <SingleMessageText>{content}</SingleMessageText>
      <SingleMessageFooter>{date}</SingleMessageFooter>
    </SingleMessageBox>
  );
};

export const ChatCommentMessages = () => {
  const { comments } = useCommentsContext();
  const scrollRef = useRef();

  useEffect(() => {
    if (comments && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [comments, scrollRef]);

  return (
    <Box overflow="auto">
      {comments.map((comment, index) => {
        return (
          <SingleCommentContext.Provider value={comment} key={comment.id}>
            <ChatCommentMessage />

            {index === comments.length - 1 && (
              <Box visibility="hidden" ref={scrollRef}></Box>
            )}
          </SingleCommentContext.Provider>
        );
      })}
    </Box>
  );
};

ChatCommentMessages.propTypes = {
  messages: PropTypes.array,
};
