import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import {
  withStyles,
  Badge as MuiBadge,
  Button as MuiButton,
  Box,
} from '@material-ui/core';

export const DataGrid = withStyles((theme) => ({
  root: {
    fontFamily: theme.MuiTypography.family.main,
    backgroundColor: theme.palette.white.main,
    color: theme.palette.neutral.black[72],
    borderRadius: '8px',
    // minHeight: '560px',

    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'initial !important',
    },

    '& .MuiDataGrid-overlay': {
      zIndex: 1,
    },

    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus':
      {
        outline: 'none',
      },

    // ROW
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },

    // HEADER
    '& .MuiDataGrid-columnsContainer': {
      borderTop: `1px solid ${theme.palette.neutral.gray.md}`,
    },

    // PAGINATION
    '& .MuiTablePagination-root': {
      width: '100%',
      color: theme.palette.neutral.black[72],
      '& .MuiToolbar-root': {
        display: 'flex',
        width: '100%',
        minHeight: '72px',
        borderTop: `1px solid ${theme.palette.neutral.gray.md}`,
      },
      '& .MuiToolbar-root > p:first-of-type': {
        fontFamily: theme.MuiTypography.family.main,
        fontSize: theme.MuiTypography.sizes.sm,
        letterSpacing: 0,
      },
      '& .MuiToolbar-root > p:last-of-type': {
        fontFamily: theme.MuiTypography.family.secondary,
        fontSize: theme.MuiTypography.sizes.xxs,
        letterSpacing: 0,
      },
      '& .MuiToolbar-root .MuiTablePagination-spacer': {
        display: 'none',
      },
      '& .MuiToolbar-root .MuiTablePagination-selectRoot': {
        marginRight: 'auto',
      },
      '& .MuiToolbar-root .MuiTablePagination-selectRoot > .MuiSelect-root': {
        paddingLeft: '10px',
        paddingBottom: '4px',
        fontSize: theme.MuiTypography.sizes.xxs,
        letterSpacing: 0,
      },
      '& .MuiToolbar-root .MuiTablePagination-selectRoot > svg': {
        width: '0.8em',
        height: '0.8em',
        top: 'calc(50% - 9px)',
      },
      '& .MuiTablePagination-actions ': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
  },
  columnHeader: {
    '& .MuiDataGrid-columnHeaderTitle': {
      // fontWeight: 600,
      fontSize: theme.MuiTypography.sizes.sm,
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      paddingLeft: 0,
    },
  },
  row: {
    maxHeight: 'initial !important',
    '&:hover': {
      backgroundColor: 'none',
    },
  },

  cell: {
    fontFamily: theme.MuiTypography.family.secondary,
    fontSize: theme.MuiTypography.sizes.xxs,
    flexWrap: 'wrap',
    maxHeight: 'initial !important',
    whiteSpace: 'normal !important',
    lineHeight: '1 !important',
    display: 'flex !important',
    alignItems: 'center',
  },
}))(MuiDataGrid);

export const Badge = withStyles((theme) => ({
  badge: {
    // top: '50%',
    // right: '13px',
    // transform: 'translateY(-50%)',
    background: theme.palette.white.main,
  },
}))(MuiBadge);

export const Button = withStyles((theme) => ({
  root: {
    // paddingRight: theme.spacing(5),
    // paddingLeft: theme.spacing(2.3),
    maxWidth: '136px',
    fontFamily: theme.MuiTypography.family.secondary,
    fontSize: theme.MuiTypography.sizes.xxs,
    letterSpacing: 0,
  },
}))(MuiButton);

export const FilterCount = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    background: theme.palette.white.main,
    fontFamily: theme.MuiTypography.family.secondary,
    fontSize: theme.MuiTypography.sizes.xs,
    marginLeft: theme.spacing(1),
    borderRadius: '100%',
    lineHeight: '17px',
    width: 17,
    height: 17,
  },
}))(Box);
