import React, { useRef, useEffect } from 'react';
import { Box, Typography, useTheme, withStyles } from '@material-ui/core';
import { useToggleState } from '../../../utils/useToggleState';
import { useTimelineExamsGmr } from '../slices';
import { IndicatorPopover } from '../TimelineIndicators/IndicatorPopover';
import {
  ExamsDatesList,
  ExamsHistogram,
  useExamsHistogram,
} from '../TimelineIndicators/PopoverContent/PopoverContentExames';

const GmrIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 19.1667C5 16.9392 2.5 14.717 2.5 12.5V4.16668C2.5 3.52702 2.7455 3.37835 3.42494 2.96692C3.63066 2.84235 3.87616 2.69368 4.16667 2.50001C4.30149 2.41012 7.13601 0.833344 10 0.833344C12.496 0.833344 14.5833 1.66668 15.8333 2.50001C16.1238 2.69368 16.3693 2.84235 16.5751 2.96692C17.2545 3.37835 17.5 3.52702 17.5 4.16668C17.5164 4.34771 17.5084 8.19794 17.5032 10.6626V10.6627C17.5014 11.503 17.5 12.1822 17.5 12.5C17.5 14.7222 15 16.9445 10 19.1667ZM15.8333 12.5C15.8333 12.3081 15.8338 11.9865 15.8349 11.4622L15.8382 9.83453L15.8409 8.42483C15.8439 6.56471 15.8431 4.96819 15.8413 4.47441L15.7269 4.40338L15.7268 4.40335C15.4573 4.23617 15.1061 4.01829 14.9088 3.88676C13.6786 3.06661 11.8835 2.50001 10 2.50001C8.19841 2.50001 5.8572 3.37607 5.09117 3.88676C4.89387 4.01829 4.54265 4.23618 4.27318 4.40335L4.27316 4.40337L4.16667 4.46946V12.5C4.16667 13.7571 6.02426 15.474 10.0008 17.335C13.9768 15.4781 15.8333 13.7619 15.8333 12.5ZM12.7441 6.91075L9.16666 10.4882L7.25592 8.57742L6.07741 9.75593L9.16666 12.8452L13.9226 8.08926L12.7441 6.91075Z"
      fill="#FB6F6C"
    />
  </svg>
);

const GmrText = withStyles((theme) => ({
  root: {
    fontSize: '11px',
    textTransform: 'uppercase',
    letterSpacing: '0.03em',
    fontFamily: theme.MuiTypography.family.secondary,
  },
}))(Typography);

export const PatientGmrIcon = () => {
  const theme = useTheme();
  const gmrExamsDates = useTimelineExamsGmr();
  const hasGmr = !!gmrExamsDates?.length;

  const wrapperRef = useRef(null);
  const { open, handleOpen, handleClose } = useToggleState();

  const {
    cursor: histogramCursor,
    handleClose: handleCloseHistogram,
    handleOpen: handleOpenHistogram,
  } = useExamsHistogram();

  return !hasGmr ? null : (
    <>
      <Box
        ref={wrapperRef}
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ color: theme.palette.pill.negativa.main, cursor: 'pointer' }}
        ml="8px"
        onClick={handleOpen}
      >
        <Box mr="4px" lineHeight="1">
          <GmrIcon />
        </Box>
        <GmrText>GMR(+)</GmrText>
      </Box>
      <IndicatorPopover
        anchorEl={wrapperRef.current}
        open={open}
        onClose={handleClose}
      >
        <Box>
          <ExamsDatesList
            examDates={gmrExamsDates}
            onOpenHistogram={handleOpenHistogram}
          />
        </Box>
      </IndicatorPopover>
      <ExamsHistogram cursor={histogramCursor} onClose={handleCloseHistogram} />
    </>
  );
};
