import { Box, withStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

export const Alert = withStyles((theme) => ({
  root: {
    padding: '15px 25px',
  },
  icon: {
    alignItems: 'center',
  },
  filledSuccess: {
    background: theme.palette.primary.main,
  },
}))(MuiAlert);
Alert.defaultProps = {
  elevation: 6,
  variant: 'filled',
};

export const Title = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
  },
}))(Box);
Title.defaultProps = {
  component: 'h3'
};

export const Subtitle = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '17px',
  },
}))(Box);
Subtitle.defaultProps = {
  component: 'p'
};
