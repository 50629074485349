import React, { useCallback, useMemo } from 'react';
import { Card } from '../../../../components/atoms/Card';
import { useSetTimelineCursor } from '../../slices';
import { IndicatorDateCardHeader } from './IndicatorDateCardHeader';

export const IndicatorDateCard = (props) => {
  const { date, momentDate, indicator, children, ...cardProps } = props;
  const formattedDate = useMemo(() => momentDate.format('DD/MM/YYYY'), [
    momentDate,
  ]);

  const setTimelineCursor = useSetTimelineCursor();
  const handleClickChevron = useCallback(
    () => setTimelineCursor({ indicator, date }),
    [setTimelineCursor, indicator, date]
  );

  return (
    <Card color="gray" size="small" shadow={false} {...cardProps}>
      <IndicatorDateCardHeader
        dateLabel={formattedDate}
        onClick={handleClickChevron}
      >
        {children}
      </IndicatorDateCardHeader>
    </Card>
  );
};
