import moment from 'moment';
import { actionTypes } from '../actions/AssessmentsHistoryFiltersActions';

const initialState = {
  initialDate: moment().subtract(3, 'months'),
  finalDate: moment(),
  orderByField: 'dt_infeccao',
  positive: true,
  negative: true,
  infections: []
};

export const assessmentsHistoryFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ASSESSMENTS_HISTORY_INITIAL_DATE:
      return {
        ...state,
        initialDate: action.initialDate,
      };
    case actionTypes.SET_ASSESSMENTS_HISTORY_FINAL_DATE:
      return {
        ...state,
        finalDate: action.finalDate
      };
    case actionTypes.SET_ASSESSMENTS_HISTORY_ORDERBY_FILTER:
      return {
        ...state,
        orderByField: action.orderByField
      };
    case actionTypes.SET_ASSESSMENTS_HISTORY_INFECTION_FILTER:
      return {
        ...state,
        infections: action.infections
      };
    case actionTypes.SET_STATUS:
      return {
        ...state,
        positive: action.positive,
        negative: action.negative,
      };
    case actionTypes.RESET_ASSESSMENTS_HISTORY_FILTERS:
      return {
        ...state,
        initialDate: moment().startOf('year'),
        finalDate: moment(),
        orderByField: '',
        positive: true,
        negative: true,
      };
    case actionTypes.RESET_DATES:
      return {
        ...state,
        initialDate: moment().startOf('year'),
        finalDate: moment(),
      };
    case actionTypes.RESET_STATUS:
      return {
        ...state,
        positive: true,
        negative: true,
      };
    default:
      return state;
  }
};
