/**
 * Indicador de óbito relacionado a infecção
 *
 * @constant
 * @type {{sim: 'sim', nao: 'nao', naoAvaliado: 'nao-avaliado'}}
 */
export const deathFromInfectionsEnum = {
  sim: 'sim',
  nao: 'nao',
  naoAvaliado: 'nao-avaliado',
};
