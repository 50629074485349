import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { TextHighlight } from '../../atoms/TextHighlight';

const PaperFact = (props) => {
  const { title, content, highlight, theme, ...boxProps } = props;
  const style = useStyles();

  return (
    <Box className={`${style.paper} ${theme}`} {...boxProps}>
      {highlight && <Box className={`${style.dot} ${theme}`}></Box>}
      <Box>
        <Typography className={style.title}>{title}</Typography>
        <TextHighlight className={style.content}>{content}</TextHighlight>
      </Box>
    </Box>
  )
};

PaperFact.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  highlight: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'danger']),
};

PaperFact.defaultProps = {
  theme: 'default',
  highlight: false,
};

export default PaperFact;
