import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

export const MedicineTitleText = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '18px',
    color: theme.palette.neutral.black['88'],
    marginBottom: '2px',
  },
}))(({ classes, className, ...props }) => (
  <div {...props} className={clsx(classes.root, className)} />
));
