import { createSelector } from '@reduxjs/toolkit';
import { mapValues } from 'lodash';
import { useSelector } from 'react-redux';
import { selectTimelineEncontrosAccordion } from './selectTimelineEncontrosAccordion';
import { selectTimelineSearchWord } from './selectTimelineSearchWord';
import { selectTimelineSearchRegex } from './selectTimelineSearchRegex';

const memoEmpty = {};
export const selectTimelineSearchResults = createSelector(
  [
    selectTimelineEncontrosAccordion,
    selectTimelineSearchRegex,
    selectTimelineSearchWord,
  ],
  (accordion, regex, searchWord) => {
    if (!accordion || !searchWord) return memoEmpty;
    return mapValues(accordion, (text) => text.match(regex)?.length || 0);
  }
);
export const useTimelineSearchResults = () =>
  useSelector(selectTimelineSearchResults);

export const selectTimelineSearchResultsInstances = createSelector(
  [selectTimelineSearchResults],
  (accordionResults) => {
    return Object.entries(accordionResults).reduce(
      (acc, [p, results]) => {
        if (results) {
          acc.professionals[p] = [acc.total + 1, acc.total + results];
          const accumulated = acc.total + results;
          acc.total = accumulated;
        }
        return acc;
      },
      { total: 0, professionals: {} }
    );
  }
);
export const useTimelineSearchResultsInstances = () =>
  useSelector(selectTimelineSearchResultsInstances);

export const selectTimelineSearchResultsTotal = createSelector(
  [selectTimelineSearchResultsInstances],
  (results) => {
    return results.total;
  }
);
export const useTimelineSearchResultsTotal = () =>
  useSelector(selectTimelineSearchResultsTotal);
