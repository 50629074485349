import React, { useMemo } from 'react';
import { Drawer as MuiDrawer, Box } from '@material-ui/core';
import { useDrawerContext } from './DrawerContext';
import { withStyles } from '@material-ui/core/styles';

export const DrawerBasePaperWrapper = withStyles({
  root: {},
})(
  React.forwardRef(({ boxComponent, ...boxProps }, ref) => (
    <Box ref={ref} component={boxComponent} {...boxProps} />
  ))
);

export const DrawerBase = (props) => {
  const { children, width, PaperProps, ...drawerProps } = props;
  const { open, onClose } = useDrawerContext();

  const drawerPaperProps = useMemo(
    () => ({
      ...PaperProps,
      height: '100%',
      width,
      component: DrawerBasePaperWrapper,
      boxComponent: PaperProps?.component,
    }),
    [width, PaperProps]
  );

  return (
    <MuiDrawer
      open={open}
      onClose={onClose}
      PaperProps={drawerPaperProps}
      ModalProps={{
        disableEnforceFocus: true,
      }}
      {...drawerProps}
    >
      {children}
    </MuiDrawer>
  );
};
DrawerBase.defaultProps = {};
