import { createContext, useContext } from 'react';

export const SingleCommentContext = createContext({
  id: undefined,
  author: undefined,
  date: undefined,
  content: undefined,
  editing: false,
});
export const useSingleCommentContext = () => useContext(SingleCommentContext);
