import React, { useMemo } from 'react';
import { useTheme } from '@material-ui/core';
import { DonutsChart } from '../DonutsChart';
import { getIndicatorValue } from '../../../../../utils/formatters/formatDashboardIndicators'
import { useSelectedCompetence } from '../../slices';

export const SuspicionsByProbability = ({ indicators }) => {
  const competence = useSelectedCompetence();
  const theme = useTheme();
  const isLoading = false;
  const dataset = useMemo(
    () => [
      {
        label: 'ALTA',
        value: getIndicatorValue(indicators, 'suspeitas_alta_prob', competence),
        color: theme.palette.timeline.probability.ALTA,
      },
      {
        label: 'MEDIA',
        value: getIndicatorValue(indicators, 'suspeitas_media_prob', competence),
        color: theme.palette.timeline.probability.MEDIA,
      },
    ],
    [theme, competence, indicators]
  );

  return <DonutsChart isLoading={isLoading} dataset={dataset} />;
};
