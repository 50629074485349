import React, { useMemo, useCallback } from 'react';
import { IconButton, ButtonGroup, withStyles } from '@material-ui/core';
import { ArrowBack, ArrowForward, List } from '@material-ui/icons';
import { findIndex, parseInt } from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import { NavigationButton } from './NavigationButton';

export const TimelineNavigation = ({ patientId }) => {
  let location = useLocation();
  let history = useHistory();
  const list = location?.state?.idList;

  // Altera para a página de um paciente
  const moveToPatient = useCallback(
    (id) => {
      history.replace({
        pathname: '/paciente/' + id,
        state: { idList: list },
      });
    },
    [history, list]
  );

  const currentIndex = useMemo(() => {
    return !list?.length || !patientId
      ? null
      : findIndex(list, (currentId) => currentId === parseInt(patientId));
  }, [list, patientId]);

  const handleBackToList = useCallback(() => {
    history.push('/pacientes');
  }, [history]);

  const handlePrev = useCallback(() => {
    if (!list || typeof currentIndex !== 'number' || !list[currentIndex - 1])
      return;
    moveToPatient(list[currentIndex - 1]);
  }, [list, currentIndex]);

  const handleNext = useCallback(() => {
    if (!list || typeof currentIndex !== 'number' || !list[currentIndex + 1])
      return;
    moveToPatient(list[currentIndex + 1]);
  }, [list, currentIndex]);

  return (
    <ButtonGroup variant="contained" color="primary">
      <NavigationButton onClick={handleBackToList}>
        <List />
      </NavigationButton>
      {typeof currentIndex === 'number' && currentIndex > 0 && (
        <NavigationButton onClick={handlePrev}>
          <ArrowBack />
        </NavigationButton>
      )}
      {typeof currentIndex === 'number' && currentIndex < list.length - 1 && (
        <NavigationButton onClick={handleNext}>
          <ArrowForward />
        </NavigationButton>
      )}
    </ButtonGroup>
  );
};
