import React, { useMemo } from 'react';
import { Box, Typography, useTheme, withStyles } from '@material-ui/core';
import { covidInfection } from '../../../constants/infections';
import { useTimelineIndicator } from '../slices';
import { parseTermosAchados } from '../../../utils/parseTermosAchados';
import { indicatorsEnum } from '../../../constants/indicators';
import { examsReferenceValues, examsTypeEnum } from '../../../constants/exams';

const NeutropeniaIcon = () => (
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.226.948a1 1 0 011.548 0l.304.371a1 1 0 001.248.249l.423-.228a1 1 0 011.43.593l.14.46a1 1 0 001.057.706l.478-.048a1 1 0 011.095 1.095l-.049.478a1 1 0 00.707 1.058l.46.138a1 1 0 01.593 1.431l-.227.423a1 1 0 00.248 1.248l.371.304a1 1 0 010 1.548l-.371.304a1 1 0 00-.248 1.248l.227.423a1 1 0 01-.593 1.43l-.46.14a1 1 0 00-.706 1.057l.048.478a1 1 0 01-1.096 1.095l-.477-.049a1 1 0 00-1.058.707l-.138.46a1 1 0 01-1.431.593l-.423-.227a1 1 0 00-1.248.248l-.304.371a1 1 0 01-1.548 0l-.304-.371a1 1 0 00-1.248-.248l-.423.227a1 1 0 01-1.43-.593l-.14-.46a1 1 0 00-1.057-.706l-.478.048a1 1 0 01-1.095-1.096l.048-.477a1 1 0 00-.706-1.058l-.46-.138a1 1 0 01-.593-1.431l.228-.423a1 1 0 00-.249-1.248l-.371-.304a1 1 0 010-1.548l.371-.304a1 1 0 00.249-1.248l-.228-.423a1 1 0 01.593-1.43l.46-.14a1 1 0 00.706-1.057l-.048-.478a1 1 0 011.095-1.095l.478.048a1 1 0 001.058-.706l.138-.46a1 1 0 011.431-.593l.423.228a1 1 0 001.248-.249l.304-.371z"
      fill="#FB6F6C"
    />
    <path
      opacity=".16"
      d="M6.783 5.877a.926.926 0 011.434 0c.28.343.765.44 1.155.23a.926.926 0 011.325.549.926.926 0 00.979.654.926.926 0 011.014 1.014c-.044.44.23.851.654.98.56.168.826.808.549 1.324a.926.926 0 00.23 1.155.926.926 0 010 1.434.926.926 0 00-.23 1.155.926.926 0 01-.549 1.325.926.926 0 00-.654.979.926.926 0 01-1.014 1.014.926.926 0 00-.98.654.926.926 0 01-1.324.549.926.926 0 00-1.155.23.926.926 0 01-1.434 0 .926.926 0 00-1.155-.23.926.926 0 01-1.325-.549.926.926 0 00-.979-.654.926.926 0 01-1.014-1.014.926.926 0 00-.654-.98.926.926 0 01-.549-1.324.926.926 0 00-.23-1.155.926.926 0 010-1.434.926.926 0 00.23-1.155.926.926 0 01.549-1.325.926.926 0 00.654-.979.926.926 0 011.014-1.014c.44.044.851-.23.98-.654a.926.926 0 011.324-.549c.39.21.875.113 1.155-.23z"
      fill="#fff"
    />
    <path
      opacity=".16"
      d="M11.235.936a.988.988 0 011.53 0c.299.366.815.469 1.232.245a.988.988 0 011.413.585.988.988 0 001.044.698.988.988 0 011.082 1.082.988.988 0 00.698 1.044c.598.18.88.863.585 1.413a.988.988 0 00.245 1.232.988.988 0 010 1.53.988.988 0 00-.245 1.232.988.988 0 01-.585 1.413.988.988 0 00-.698 1.044.988.988 0 01-1.082 1.082.988.988 0 00-1.044.698.988.988 0 01-1.413.585.988.988 0 00-1.232.245.987.987 0 01-1.53 0 .988.988 0 00-1.232-.245.988.988 0 01-1.413-.585.988.988 0 00-1.044-.698.988.988 0 01-1.082-1.082.988.988 0 00-.698-1.044.988.988 0 01-.585-1.413.988.988 0 00-.245-1.232.988.988 0 010-1.53.988.988 0 00.245-1.232.988.988 0 01.585-1.413.988.988 0 00.698-1.044.988.988 0 011.082-1.082.988.988 0 001.044-.698.988.988 0 011.413-.585.988.988 0 001.232-.245z"
      fill="#fff"
    />
    <path
      opacity=".16"
      d="M9.235 2.936a.988.988 0 011.53 0c.299.366.815.469 1.232.245a.988.988 0 011.413.585.988.988 0 001.044.698.988.988 0 011.082 1.082.988.988 0 00.698 1.044c.598.18.88.863.585 1.413a.988.988 0 00.245 1.232.988.988 0 010 1.53.988.988 0 00-.245 1.232.988.988 0 01-.585 1.413.988.988 0 00-.698 1.044.988.988 0 01-1.082 1.082.988.988 0 00-1.044.698.988.988 0 01-1.413.585.988.988 0 00-1.232.245.988.988 0 01-1.53 0 .988.988 0 00-1.232-.245.988.988 0 01-1.413-.585.988.988 0 00-1.044-.698.988.988 0 01-1.082-1.082.988.988 0 00-.698-1.044.988.988 0 01-.585-1.413.988.988 0 00-.245-1.232.988.988 0 010-1.53.988.988 0 00.245-1.232.988.988 0 01.585-1.413.988.988 0 00.698-1.044.988.988 0 011.082-1.082.988.988 0 001.044-.698.988.988 0 011.413-.585.988.988 0 001.232-.245z"
      fill="#fff"
    />
    <path
      opacity=".16"
      d="M9.426 4.702a.74.74 0 011.148 0 .74.74 0 00.924.184.74.74 0 011.06.439.74.74 0 00.783.523.74.74 0 01.81.811.74.74 0 00.524.784.74.74 0 01.44 1.06.74.74 0 00.183.923.74.74 0 010 1.148.74.74 0 00-.184.924.74.74 0 01-.439 1.06.74.74 0 00-.523.783.74.74 0 01-.811.81.74.74 0 00-.784.524.74.74 0 01-1.06.44.74.74 0 00-.923.183.74.74 0 01-1.148 0 .74.74 0 00-.924-.184.74.74 0 01-1.06-.439.74.74 0 00-.783-.523.74.74 0 01-.81-.811.74.74 0 00-.524-.784.74.74 0 01-.44-1.06.74.74 0 00-.183-.923.74.74 0 010-1.148.74.74 0 00.184-.924.74.74 0 01.439-1.06.74.74 0 00.523-.783.74.74 0 01.811-.81.74.74 0 00.784-.524.74.74 0 011.06-.44c.312.168.7.091.923-.183z"
      fill="#fff"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.774.947a1 1 0 00-1.548 0l-.304.372a1 1 0 01-1.248.248l-.423-.227a1 1 0 00-1.43.593l-.14.46a1 1 0 01-1.057.706l-.478-.048a1 1 0 00-1.095 1.095l.048.478a1 1 0 01-.706 1.058l-.46.138a1 1 0 00-.593 1.43l.228.424a1 1 0 01-.249 1.248l-.371.303a1 1 0 000 1.55l.371.303a1 1 0 01.249 1.248l-.228.423a1 1 0 00.593 1.43l.46.139a1 1 0 01.706 1.058l-.048.477a1 1 0 001.095 1.095l.478-.048a1 1 0 011.058.707l.138.46a1 1 0 001.431.593l.423-.228a1 1 0 011.248.248l.304.372a1 1 0 001.548 0l.304-.372a1 1 0 011.248-.248l.423.228a1 1 0 001.43-.593l.14-.46a1 1 0 011.057-.707l.478.048a1 1 0 001.095-1.095l-.049-.477a1 1 0 01.707-1.058l.46-.139a1 1 0 00.593-1.43l-.227-.424a1 1 0 01.248-1.247l.371-.304a1 1 0 000-1.549l-.371-.303a1 1 0 01-.248-1.248l.227-.423a1 1 0 00-.593-1.43l-.46-.14a1 1 0 01-.706-1.057l.048-.478a1 1 0 00-1.096-1.095l-.477.048a1 1 0 01-1.058-.706l-.138-.46a1 1 0 00-1.431-.593l-.423.227a1 1 0 01-1.248-.248l-.304-.372zm0 1a1 1 0 00-1.548 0l-.144.177a1 1 0 01-1.248.248l-.2-.108a1 1 0 00-1.43.593l-.066.218a1 1 0 01-1.058.706l-.226-.022a1 1 0 00-1.095 1.095l.022.226a1 1 0 01-.706 1.058l-.218.065a1 1 0 00-.593 1.43l.108.201a1 1 0 01-.248 1.248l-.176.144a1 1 0 000 1.548l.176.144a1 1 0 01.248 1.248l-.108.2a1 1 0 00.593 1.43l.218.067a1 1 0 01.706 1.057l-.022.226a1 1 0 001.095 1.095l.226-.022a1 1 0 011.058.706l.065.218a1 1 0 001.43.593l.201-.108a1 1 0 011.248.248l.144.176a1 1 0 001.548 0l.144-.176a1 1 0 011.248-.248l.2.108a1 1 0 001.43-.593l.067-.218a1 1 0 011.057-.706l.226.022a1 1 0 001.095-1.095l-.022-.226a1 1 0 01.706-1.057l.218-.066a1 1 0 00.593-1.43l-.108-.201a1 1 0 01.248-1.248l.176-.144a1 1 0 000-1.548l-.176-.144a1 1 0 01-.248-1.248l.108-.2a1 1 0 00-.593-1.43l-.218-.066a1 1 0 01-.706-1.058l.022-.226a1 1 0 00-1.095-1.095l-.226.022a1 1 0 01-1.057-.706l-.066-.218a1 1 0 00-1.43-.593l-.201.108a1 1 0 01-1.248-.248l-.144-.176z"
      fill="#FB6F6C"
    />
  </svg>
);

const NeutropeniaText = withStyles((theme) => ({
  root: {
    fontSize: '11px',
    textTransform: 'uppercase',
    letterSpacing: '0.03em',
    fontFamily: theme.MuiTypography.family.secondary,
  },
}))(Typography);

export const PatientNeutropeniaIcon = () => {
  const theme = useTheme();
  const exams = useTimelineIndicator(indicatorsEnum.exames);

  /**
   * Verifica nos exames se paciente deve ser sinalizado com
   * neutropenia, baseado no valor de referência
   * @return {boolean}
   */
  const hasNeutropenia = useMemo(() => {
    if (!exams?.length) return false;

    /**
     * Verifica se exame é do tipo leucócitos
     * @param {*} exam
     */
    const isExamLeucocito = (exam) =>
      exam.item_exame === examsTypeEnum['LEUCÓCITOS'];

    /**
     * Verifica se existe algum resultado abaixo do indicado
     * para definir neutropenia
     * @param {*} exam
     */
    const hasNeutropenia = (exam) =>
      exam.resultado < examsReferenceValues['NEUTROPENIA'];

    exams.filter(isExamLeucocito).some(hasNeutropenia);
  }, [exams]);

  return !hasNeutropenia ? null : (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ color: theme.palette.pill.negativa.main }}
      ml="8px"
    >
      <Box mr="4px" lineHeight="1">
        <NeutropeniaIcon />
      </Box>
      <NeutropeniaText>Neutropenia</NeutropeniaText>
    </Box>
  );
};
