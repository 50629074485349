import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../baseQuery';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Dashboard'],
  endpoints: (build) => ({
    getDashboardIndicators: build.query({
      query: () => {
        return {
          url: `/dashboard/indicators`,
          method: 'GET',
        }
      }
    })
  })
})

export const {
  useGetDashboardIndicatorsQuery
} = dashboardApi;
