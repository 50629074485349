import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import SettingsTemplate from '../../templates/Settings';
import InfectionsListData from './infectionsListMock';
import InfectionsForm from '../../components/organisms/InfectionsForm';
import {Title} from './styles';
import {Slide, Snackbar, Typography, Box, Button, Chip, Table, TableCell, TableHead, TableBody, TableRow} from '@material-ui/core';
import useStyles from './styles';
import SaveIcon from '@material-ui/icons/Save';
import {saveInfectionParams} from "../../actions/SettingsActions";
import {connect} from "react-redux";
import {AlertDefault} from "../../components/atoms/AssessmentSnackbar/styles";
import {Alert} from "@material-ui/lab";
import {withTheme} from "@material-ui/core/styles";
import { useGetHealthStatusQuery, useRefreshLogstashMutation } from '../../services';
import { Done } from '@material-ui/icons';

const SettingsPage = (props) => {
  const classes = useStyles();
  const {
    sendInfectionParams,
    infectionParams
  } = props;
  const [isOpened, setIsOpened] = useState(false);
  const { data: healthStatus } = useGetHealthStatusQuery();
  const [refreshLogstash, { loading, error }] = useRefreshLogstashMutation();

  console.log({healthStatus})

  const handleClose = () => {
    setIsOpened(false);
  }

  const handleSubmit = (e) => {
    sendInfectionParams(infectionParams);
    setIsOpened(true);
  };

  const handleResetLogstash = () => {
    // console.log({refreshLogstash})
    refreshLogstash();
  }

  return (
    <SettingsTemplate>
      <Grid container>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{marginBottom: '40px'}}
        >
          <Grid item>
            <Title>Configurações</Title>
            {/*<Typography className={classes.lastUpdate}>Última atualização: {lastUpdate}</Typography>*/}
          </Grid>
          <Grid
            item
            container
            spacing={3}
            justify="flex-end"
            alignItems="center"
            className={classes.buttons}
          >
            <Grid item>
              <Button
                className={classes.saveButton}
                onClick={() => handleSubmit()}
                variant="contained"
                size="medium"
                color="primary"
                endIcon={<SaveIcon />}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <InfectionsForm infectionsList={InfectionsListData} />
        </Grid>
      </Grid>
      { /* Feedback de salvamento de configuração */}
      <Snackbar
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        open={isOpened}
        onClose={handleClose}
        autoHideDuration={5000}
        transitioncomponent={<Slide />}
      >
        <AlertDefault {...props}>
          <Alert severity="success">
            <h3>Configuração salva com sucesso</h3>
            <p>Sucesso ao salvar as configurações de infecção.</p>
          </Alert>
        </AlertDefault>
      </Snackbar>
    </SettingsTemplate>
  )
};
const mapDispatchToProps = (dispatch) => ({
  sendInfectionParams: (infectionList) =>
    dispatch(saveInfectionParams(infectionList))
});

const mapStateToProps = (state) => ({
  infectionParams: state.settings.infections,
});

export default  connect(mapStateToProps, mapDispatchToProps)(withTheme(SettingsPage));
