import React from 'react';
import { Box } from '@material-ui/core';
import { Comments } from '../../../components/organisms/Comments';
import { AssessmentAnalysis } from '../../../components/organisms/AssessmentAnalysis';
import { useTimelinePatientId } from '../slices';

export const TimelineActions = (props) => {
  const patientId = useTimelinePatientId();
  const {companyId} = props;
  return (
    <Box display="flex" justifyContent="flex-end" mb="16px">
      <Box ml="16px" flex="1 1 auto">
        {!!patientId && <AssessmentAnalysis patientId={patientId} companyId={companyId}/>}
      </Box>
    </Box>
  );
};
