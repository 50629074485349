import React, { useMemo } from 'react';
import { MedicineRangeItem } from './MedicineRangeItem';
import { MedicineTitleText } from './MedicineTitleText';
import { MedicineRangeText } from './MedicineRangeText';

export const MedicineRanges = ({ medicine, range }) => {
  const medicineTitle = useMemo(() => {
    return medicine.replace(/\_/gm, ' ');
  }, [medicine]);

  return (
    <>
      <MedicineTitleText>{medicineTitle}</MedicineTitleText>
      {range.map(({ startMoment, endMoment }, rangeIndex) => (
        <MedicineRangeText key={rangeIndex}>
          <MedicineRangeItem startMoment={startMoment} endMoment={endMoment} />
          {rangeIndex !== range.length - 1 && <>,</>}
        </MedicineRangeText>
      ))}
    </>
  );
};
