import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { selectTimelineSlice } from './selectTimelineSlice';

export const selectTimelineCursorRaw = createSelector(
  selectTimelineSlice,
  (timeline) => timeline.cursor
);

export const selectTimelineCursorRawDate = createSelector(
  selectTimelineCursorRaw,
  (cursor) => cursor?.date
);
export const selectTimelineCursorRawIndicator = createSelector(
  selectTimelineCursorRaw,
  (cursor) => cursor?.indicator
);

/**
 * selector da seleção da timeline
 */
const emptyCursor = {
  indicator: undefined,
  date: undefined,
};
export const selectTimelineCursor = createSelector(
  [selectTimelineCursorRawIndicator, selectTimelineCursorRawDate],
  (indicator, date) => {
    return indicator && date
      ? {
          indicator,
          date: moment(date),
        }
      : emptyCursor;
  }
);
export const useTimelineCursor = () => useSelector(selectTimelineCursor);
