import React from "react";
import Paper from "@material-ui/core/Paper";
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import {Date, MaxProb, PatientCard, PatientName, LastEvaluation} from "./styles";
import HorizontalChips from '../HorizontalChips/index'
import {array, object, string, bool} from 'prop-types';
import {useHistory} from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from 'react-redux';
import HorizontalChipsBasicNoPatientFactsComponent from "../HorizontalChipsBasic/no-patientfacts";
import {PatientCardChips} from "../PatientCardChips/PatientCardChips";

const PatientCardComponent = (props) => {
  const history = useHistory();
  const {
    url = null,
    patient,
    idList,
    loading,
    settings,
    patientSuspicionsFetching,
    // infectionParams
  } = props;


  const parsedPatient = [patient].map(item => ({
    ...item,
    pred_ipcs: parseFloat(item.pred_ipcs),
    pred_isc: parseFloat(item.pred_isc),
    pred_itu: parseFloat(item.pred_itu),
    pred_pav: parseFloat(item.pred_pav),
    pred_pnm: parseFloat(item.pred_pnm),
    pred_traqueo: parseFloat(item.pred_traqueo),
  }))

  const dateFmt = 'DD/MM/Y';
  const handleClick = () => {
    if (url) history.push({
      pathname: url,
      state: { idList: idList }
    });
  };

  return (
    <PatientCard onClick={handleClick} className={url ? 'pointer' : ''} data-testid="patient-card">
      { patientSuspicionsFetching ? <LinearProgress /> : '' }
      <Paper elevation={1}>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={12} sm={6} md={6}>
            <PatientName>{ patient.paciente?.name ?? '--' }</PatientName>
            <Date>
              {`${moment(patient.dtInicio, 'YYYY-MM-DD').format(dateFmt)}
              - ${moment(patient.dtFim, 'YYYY-MM-DD').format(dateFmt)}`}
            </Date>
            <LastEvaluation>
              Última avaliação: {patient?.data_avaliacao ? moment(patient.data_avaliacao, 'YYYY-MM-DD').format(dateFmt) : '-'}
            </LastEvaluation>
          </Grid>
          <Grid item md={6} lg={5}>
            <PatientCardChips settings={settings} patient={patient}/>
          </Grid>
        </Grid>
      </Paper>
    </PatientCard>
  );
};


const mapStateToProps = (state) => ({
  loading: state.pendingAssessments.loading,
  // infectionParams: state.settings.infections,
});

export default connect(mapStateToProps, null) (PatientCardComponent);
