import { cloneDeep, filter, isEqual, maxBy, minBy, orderBy } from "lodash";
import moment from "moment";
import { SubExamsSortEnum } from "../../../../constants/exams";
import { EXTRACTED_CRITERIAS } from "./constants";

export const parseGraphData = (patientFacts, typeName, item)=>{
    let exames = cloneDeep(patientFacts.filter(item => item._index === "exames" && item._source?.item_exame === typeName));
    exames = exames.filter(item => item._source?.item_exame === typeName);
    const currentDate = item.date ? item.date : new Date(item.dthr_pedido);
    const subDays = currentDate.setDate(currentDate.getDate() - 10);
    const addDays = currentDate.setDate(currentDate.getDate() + 20);
    let grapData = orderBy(exames.filter((exame)=>{
        const date = new Date(exame._source.dthr_pedido);
        return date.getTime() >= subDays &&
        date.getTime() <= addDays
    }).map(exame => {
        exame.date = new Date(new Date(exame._source.dthr_pedido).toDateString());
        exame._source.tipo_sinal = typeName;
        exame._source.valor = exame._source.resultado;
        return exame
    }), '_source.dthr_entrega', 'asc')
    return grapData
} 
/**
 * Filtra os fatos de um paciente para gerar o gráfico do tooltip
 * @param {array} patientFacts Array de fatos dos pacientes
 * @param {object} item Objecto do fato que será filtrado
 * @param {string} dateLabel labal da data que será filtrada
 * @returns array de fatos
 */
export const filterGraphData = (patientFacts, item, dateLabel)=>{
    const items = cloneDeep(patientFacts); 
    const currentDate = new Date(new Date(item[dateLabel]).toDateString());
    const subDays = currentDate.setDate(currentDate.getDate() - 10);
    const addDays = currentDate.setDate(currentDate.getDate() + 20); 
    const graphData = filter(items, (currentItem)=>{
        const date = new Date(currentItem._source[dateLabel]);
        return date.getTime() >= subDays &&
            date.getTime() <= addDays
    })
    return graphData;
}

export const formatTags = (tags, type) => {
    if (type === 'termos') {
      const newTags = [];
      tags.replace('[', '').replace(']', '').split(',').forEach((tag) => {
        newTags.push(tag.split("'").join(""));
      })
      return newTags;
    }

    if (type === 'criterios') {
      const newCriterias = [];
      const parsedTags = JSON.parse(tags);

      EXTRACTED_CRITERIAS.forEach((criteria) => {
        if(parsedTags[criteria].length > 0) {
          newCriterias.push(criteria)
        }
      })

      return newCriterias
    }
}


export const orderExams = (item,i) => {
  const enumIndex = SubExamsSortEnum[item.item_exame]
  return typeof enumIndex === 'undefined' ? SubExamsSortEnum['[DEFAULT]'] : enumIndex;
}

