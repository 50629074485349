import Keycloak from 'keycloak-js';

export const keycloakConnect = Keycloak({
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_BASE_URL,
});

export const keycloakProviderInitConfig = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  'auth-server-url': process.env.REACT_APP_KEYCLOAK_BASE_URL,
  'ssl-required': 'external',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  resource: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  'public-client': true,
  'verify-token-audience': true,
  'use-resource-role-mappings': true,
  'confidential-port': 0,
  onLoad: 'login-required',
  checkLoginIframe: false,
};

export function kcAuth() {
  const username = keycloakConnect.tokenParsed.preferred_username;
  localStorage.setItem('username', username)
  localStorage.setItem('token', keycloakConnect.token)
}
