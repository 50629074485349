import React, { useMemo, useCallback } from 'react';
import {
  Typography,
  Box,
  Button,
  Collapse,
  withStyles,
} from '@material-ui/core';
import { AddCircleOutline as AddCircleOutlineIcon } from '@material-ui/icons';
import { SwitchOption, FieldControl } from '../../../../molecules/Form';
import { MicroorganismsRepeater } from './MicroorganismsRepeater';

export const MicroorganismsInputLabel = withStyles((theme) => ({
  root: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    fontFamily: 'Nunito',
    color: theme.palette.neutral.black[100],
    textTransform: 'initial',
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
}))(Typography);

export const MicroorganismsInput = (props) => {
  const {
    loading,
    options,
    name,
    label,
    value,
    disabled,
    helperTexts,
    errors,
    onChange,
    onAppend,
    onRemove,
  } = props;

  const error = !!errors?.length;
  const helperText = typeof helperTexts === 'string' ? helperTexts : undefined;

  const valuesArray = useMemo(() => (Array.isArray(value) ? value : []), [
    value,
  ]);

  const handleSwitchInputChange = useCallback(
    ({ currentTarget }) => {
      const { checked } = currentTarget;
      if (onChange) onChange(checked ? [] : false);
      if (onAppend && checked) onAppend();
    },
    [onChange, onAppend]
  );

  const handleAddMicroorganism = useCallback(() => {
    if (onAppend) onAppend({ resistant: false });
  }, [onAppend]);

  return (
    <FieldControl disabled={disabled} helperText={helperText} error={error}>
      <SwitchOption
        label={<MicroorganismsInputLabel>{label}</MicroorganismsInputLabel>}
        labelPlacement="start"
        checked={!!value}
        onChange={handleSwitchInputChange}
        style={{ marginLeft: 0, justifyContent: 'space-between' }}
        disabled={disabled}
      />
      <Collapse in={!!value}>
        <div>
          <Box mt={1.5}>
            <MicroorganismsRepeater
              values={valuesArray}
              name={name}
              errors={errors}
              helperTexts={helperTexts}
              options={options}
              loading={loading}
              disabled={disabled}
              onRemove={onRemove}
            />
          </Box>
          <Button
            variant="text"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddMicroorganism}
            style={{ textTransform: 'initial', marginTop: 8 }}
            disabled={disabled}
          >
            Adicionar outro
          </Button>
        </div>
      </Collapse>
    </FieldControl>
  );
};
