import React, { createContext, createRef, useContext } from 'react';
import { useDropMenuState } from './useDropMenuState';

export const DropMenuContext = createContext({
  open: false,
  handleOpen: () => {},
  handleClose: () => {},
  ref: createRef(null),
});
export const useDropMenuContext = () => useContext(DropMenuContext);

export const DropMenuProvider = ({ children }) => {
  const state = useDropMenuState();

  return (
    <DropMenuContext.Provider value={state}>
      {children}
    </DropMenuContext.Provider>
  );
};
