import { getDashboardInfectionStats } from '../services/api/dashboard/dashboard.service';
import { getPatientsWithPendingAssessments } from '../services/api/pending/pending.service';
import { getAssessedInfections } from '../services/api/common/assessed.service';

export const actionTypes = {
  GET_SUSPICIOUS_INFECTIONS: 'GET_SUSPICIOUS_INFECTIONS',
  GET_SUSPICIOUS_INFECTIONS_ERROR: 'GET_SUSPICIOUS_INFECTIONS_ERROR',
  SET_DASHBOARD_LOADING: 'SET_DASHBOARD_LOADING',
};

const suspiciousInfectionsActionSuccess = (dashboardInformations) => ({
  type: actionTypes.GET_SUSPICIOUS_INFECTIONS,
  dashboardInformations,
});

const suspiciousInfectionsActionError = () => ({
  type: actionTypes.GET_SUSPICIOUS_INFECTIONS_ERROR,
});

/**
 * Suspicious infections loading action creator
 *
 * @type {{type: string}}
 */
const setLoadingAction = () => ({
  type: actionTypes.SET_DASHBOARD_LOADING,
});

export const dashboardInformations = (filterParams) => async (dispatch) => {
  try {
    dispatch(setLoadingAction());

    const dashboardStats = await getDashboardInfectionStats(
      filterParams.initialDate,
      filterParams.finalDate,
      filterParams.infectionParams
    );
    const suspiciousInfections = await getPatientsWithPendingAssessments(
      filterParams
    );
    const assessedInfections = await getAssessedInfections({ ...filterParams });

    dispatch(
      suspiciousInfectionsActionSuccess({
        dashboardStats,
        suspiciousInfections,
        assessedInfections,
        filterParams,
      })
    );
  } catch (error) {
    dispatch(suspiciousInfectionsActionError());
  }
};
