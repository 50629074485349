import { makeStyles, alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 16,
    background: theme.palette.grey[50],
    borderRadius: 8,
    fontFamily: 'DM Mono',
    fontSize: 14,
    marginBottom: 8,
  },
  title: {
    fontFamily: 'nunito',
    color: theme.palette.neutral.black[100],
    fontSize: 15,
    fontWeight: 700,
    margin: '16px 0 8px',
  },
  cardTitle: {
    color: theme.palette.neutral.black[100],
    fontSize: 14,
  },
  cardContent: {
    color: theme.palette.neutral.black[72],
    marginTop: 4,
    fontSize: 14,
    whiteSpace: 'break-spaces',
  },
}));
