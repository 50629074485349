import { formatInfectionLabel } from './formatInfectionLabel'
import { formatProbability } from './formatProbability'

/**
 * Dado o nome de uma infecção e valor de probabilidade, retorna uma label no formato:
 * 
 * @param {string} infection Nome da infecção
 * @param {number} probability Probabilidade de infecção de 0 a 1
 * @returns {string} Label formatada.
 * 
 * @example
 * formatInfectionProbability('ipcs', 0.5); // 'IPCS 50,00%'
 * formatInfectionProbability('traqueo', 0.197); // 'Traqueo 19,70%'
 */
export const formatInfectionProbability = (infection, probability) => {
  return `${formatInfectionLabel(infection)}: ${formatProbability(probability)}`;
};
