import React from 'react'
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

export const DateTitle = withStyles((theme) => ({
  root: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.neutral.black['72'],
    margin: '8px 0',
  },
}))(({ classes, className, ...props }) => (
  <div className={clsx(classes.root, className)} {...props} />
));
