import React, { useLayoutEffect, useRef, useState} from "react";
import D3TimelineChart from "./D3TimelineChart";
import {TimelineChart, TimelineLoader, TimelineTooltip} from "./styles";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
// import {getPatientInfection} from "../../../services/api/patient/patient.service";
import {TimelineContainer} from "../../../pages/Timeline/style";
import {setTimelineReadyAction} from "../../../actions/TimelineAction";
import Subtitles from "../Subtitles"
import { useGetSuspicionsQuery } from "../../../services/suspicions/suspicions.service";
import { useGetPatientFactsQuery } from "../../../services/patient/patient.service";


let timelineChart = null;

const TimelineComponent = (props) => {
  const { patientId: id } = props;

  // Gráfico
  const refTimeline = useRef();

  // Variables
  const [chart, setChart] = useState(null);
  // const [loading, setLoading] = useState(true);

  const {
      data: suspicions, 
      isLoading: suspicionsLoading, 
      isFetching: suspicionsisFetching, 
  } = useGetSuspicionsQuery(id, {
    skip: !id
  }); 

  const {
      data: patientFacts, 
      isLoading: patientFactsLoading, 
      isFetching: patientFactsisFetching, 
  } = useGetPatientFactsQuery(id, {
    skip: !id
  }); 

  // Atualiza dimensões do gráfico
  const updateSize = _ => {
    console.log('updateSize')
    if (timelineChart) {
      timelineChart.changeWidth();
    }
  }; 
  
  const debounce = (func) =>{
    let timer;
    return function(event){
      if(timer) clearTimeout(timer);
      timer = setTimeout(func,100,event);
    };
  }
  useLayoutEffect(() => { 
    console.log('inicia timeline')
    if(!id || suspicionsLoading || patientFactsLoading || suspicionsisFetching || patientFactsisFetching) { return; }
      // inicializa o gráfico
      timelineChart = new D3TimelineChart(refTimeline, patientFacts, suspicions); 
      // setLoading(false); 
      window.removeEventListener('resize', debounce(updateSize))
      window.addEventListener('resize',  debounce(updateSize));
      timelineChart.changeSizes();
      
      return () => window.removeEventListener('resize',  debounce(timelineChart.changeSizes()))
        
  },[suspicions, patientFacts, id]); 

  if(patientFactsLoading || suspicionsLoading || patientFactsisFetching || suspicionsisFetching){
    return <TimelineLoader><CircularProgress color="primary"/></TimelineLoader>
  }

  // Nenhuma informação encontrada
  if(!patientFacts){
    return (<TimelineContainer>
      Nenhuma informação encontrada
    </TimelineContainer>)
  }


  return <TimelineContainer style={{opacity: suspicionsisFetching || patientFactsisFetching ? 0.3 : 1}}>
    <TimelineChart ref={refTimeline}/>
    <Subtitles/>
    <TimelineTooltip id="rootTooltip"/>
  </TimelineContainer>
};

const mapStateToProps = (state) => {
  return {
    patientInfections: state.timeline.patientInfections,
    patientFacts: state.timeline.patientFacts,
    timelineLoading: state.timeline.timelineLoading
  }};

const mapDispatchToProps = (dispatch) => ({
  setTimelineReady: (patientId) =>
    dispatch(setTimelineReadyAction(patientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimelineComponent);
