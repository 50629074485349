import { IconButton, withStyles } from '@material-ui/core';

export const NavigationButton = withStyles((theme) => ({
  root: {
    padding: '11px 8px',
    width: '40px',
    background: theme.palette.neutral.gray.xxlt,
    borderRadius: '4px',
    color: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    '& svg': {
      fontSize: '18px',
    }
  },
}))(IconButton);
NavigationButton.defaultProps = {};
