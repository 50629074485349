import { createApi } from '@reduxjs/toolkit/query/react'
import {  baseQueryWithReauth } from '../baseQuery'

export const healthStatusApi = createApi({
  reducerPath: 'healthStatusApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['HealthStatus'],
  endpoints: (build) => ({
    getHealthStatus: build.query({
      query: () => ({
        url: `/health`,
        method: 'GET'
      }),
      providesTags: ['HealthStatus'],
    }),
    refreshLogstash: build.mutation({
      query: () => ({
        url: `/elk/refresh`,
        method: 'PUT'
      }),
      invalidatesTags: ['HealthStatus'],
    })
  }),
})

export const { useGetHealthStatusQuery, useRefreshLogstashMutation } = healthStatusApi
