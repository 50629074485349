import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { bool, func } from 'prop-types';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid } from '@material-ui/core';

const FormDialogStatus = (props) => {
  const {
    open,
    setOpen,
    sendStatusesToParent,
    globalPositiveStatus,
    globalNegativeStatus,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilter = () => {
    sendStatusesToParent({
      positive: postiveStatus,
      negative: negativeStatus,
    });
    setOpen(false);
  };

  const [postiveStatus, setPositiveStatus] = useState(true);
  const [negativeStatus, setNegativeStatus] = useState(true);

  useEffect(() => {
    setPositiveStatus(globalPositiveStatus);
    setNegativeStatus(globalNegativeStatus);
  }, [
    globalPositiveStatus,
    globalNegativeStatus
  ]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Status
        <IconButton
          className="closeButton"
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid>
          <FormControlLabel
            className="border labelDialog"
            labelPlacement="start"
            label="Positiva"
            control={
              <Switch
                checked={postiveStatus}
                onChange={() => setPositiveStatus(!postiveStatus)}
                name="highProbability"
                color="primary"
              />
            }
          />
        </Grid>
        <Grid>
          <FormControlLabel
            className="labelDialog"
            labelPlacement="start"
            label="Negativa"
            control={
              <Switch
                checked={negativeStatus}
                onChange={() => setNegativeStatus(!negativeStatus)}
                name="mediumProbability"
                color="primary"
              />
            }
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className="buttonDialog" autoFocus onClick={handleFilter}>
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FormDialogStatus.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
  sendStatusesToParent: func,
};

const mapStateToProps = (state) => ({
  globalPositiveStatus: state.assessmentsHistoryFilter.positive,
  globalNegativeStatus: state.assessmentsHistoryFilter.negative,
});

export default connect(mapStateToProps, null)(FormDialogStatus);
