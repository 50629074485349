import React from 'react';
import { ButtonGroup, IconButton, withStyles } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import {
  useTimelineCursor,
  useTimelineNextDay,
  useTimelinePrevDay,
  useSetTimelineNextDay,
  useSetTimelinePrevDay,
} from '../../slices';

export const DateNavButton = withStyles((theme) => ({
  root: {
    padding: '11px 8px',
    width: '40px',
    background: theme.palette.neutral.gray.xxlt,
    borderRadius: '4px',
    color: theme.palette.neutral.black['100'],
    '.MuiButtonGroup-groupedContainedPrimary:not(:last-child)&': {
      borderColor: theme.palette.common.white,
    },
    '& svg': {
      fontSize: '18px',
    },
  },
}))(IconButton);
DateNavButton.defaultProps = {};

export const DateNavigation = () => {
  const nextDay = useTimelineNextDay()
  const setNextDay = useSetTimelineNextDay();

  const prevDay = useTimelinePrevDay()
  const setPrevDay = useSetTimelinePrevDay();

  return (
    <ButtonGroup variant="contained" color="primary">
      <DateNavButton onClick={setNextDay} disabled={!nextDay}>
        <ArrowBack />
      </DateNavButton>
      <DateNavButton onClick={setPrevDay} disabled={!prevDay}>
        <ArrowForward />
      </DateNavButton>
    </ButtonGroup>
  );
};
