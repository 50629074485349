import React, { useMemo } from 'react';
import { Box, Divider, Button } from '@material-ui/core';

import { TimelineDrawerPanel } from '../../TimelineDrawerPanel';

import {
  useTimelineEncontroActive,
  useTimelinePatientId,
} from '../../../slices';
import useStyles from './styles';
import {
  ExtractedCriteria,
  extractCriteriaFromEncontro,
} from '../../../TimelineIndicators/PopoverContent/PopoverContentEvolucao/ExtractedCriteria';
import { AccordionEvolucao } from './AccordionEvolucao';
import { TermsList } from './TermsList';
import { SearchInput } from './SearchInput';
import { useIndicatorTerms } from '../../../TimelineIndicators/IndicatorTermsList/useIndicatorTerms';
import { useTimelineEncontroTerms } from '../../../slices/selectors/selectTimelineEncontroTerms';

export const TimelineDrawerEvolucao = ({ indicator, date }) => {
  const classes = useStyles();
  const patientID = useTimelinePatientId();

  const encontro = useTimelineEncontroActive();

  const completeEvolutionUrl = useMemo(
    () =>
      `/paciente/${patientID}/evolucao-completa/?dt_encontro=${encontro.dt_encontro}`,
    [encontro]
  );

  const hasCriterias = useMemo(
    () => !!encontro && !!extractCriteriaFromEncontro(encontro).length,
    [encontro]
  );

  const aiKeywords = useTimelineEncontroTerms();
  const aiTerms = useIndicatorTerms({
    text: encontro.termos_texto,
    keywords: aiKeywords,
  });

  return (
    <TimelineDrawerPanel
      toolbar={
        <>
          <Box padding="16px" className={classes.locationTitle}>
            Local: {encontro?.local_encontro}
          </Box>
          <Divider style={{ marginLeft: '16px', marginRight: '16px' }} />
          <Box px="16px" mt="12px" mb="8px">
            <SearchInput />
          </Box>
        </>
      }
    >
      <Box padding="0 16px">
        <TermsList terms={aiTerms} />

        {hasCriterias && (
          <Box mt="20px">
            <h5 className={classes.subtitle}>Critérios extraídos</h5>
            <ExtractedCriteria
              style={{ marginTop: '8px' }}
              color="gray"
              encontro={encontro}
            />
          </Box>
        )}
        <Box mt="20px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <h5 className={classes.subtitle}>Observações</h5>
            <Button
              className={classes.seeCompleteEvolutionBtn}
              href={completeEvolutionUrl}
              target="_blank"
            >
              Ver evolução completa
            </Button>
          </Box>

          <Box bgcolor="#F7F7F7" borderRadius="6px" mt="6px">
            <AccordionEvolucao encontro={encontro} />
          </Box>
        </Box>
      </Box>
    </TimelineDrawerPanel>
  );
};
