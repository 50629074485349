import { formatDateRange } from './formatDateRange'

/**
 * Recebe dados de uma suspeita como parâmetro e retorna uma label com o período
 * 
 * @param {Suspicion} suspicion._source Dados da suspeita
 * @returns {string} ex: '01/02-10/02/2021'
 */
export const formatSuspicionLabel = ({ dt_inicio, dt_fim }, fullDate = false) => {
  return formatDateRange({ start: dt_inicio, end: dt_fim }, fullDate)
}