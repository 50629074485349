import React from 'react';
import { Box, withStyles } from '@material-ui/core';

export const FeedbackMessageTitle = withStyles((theme) => ({
  root: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '27px',
    color: theme.palette.neutral.black['100'],
  },
}))(Box);

export const FeedbackMessageSubTitle = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '18px',
    color: theme.palette.neutral.black['72'],
  },
}))(Box);

export const FeedbackMessage = ({ icon, title, subtitle, children }) => {
  return (
    <Box
      textAlign="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box flex="1 1 auto">
        <Box mb="20px">{icon}</Box>
        <FeedbackMessageTitle mb="8px">{title}</FeedbackMessageTitle>
        <FeedbackMessageSubTitle mb="24px">{subtitle}</FeedbackMessageSubTitle>
        {children}
      </Box>
    </Box>
  );
};
