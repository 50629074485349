import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { SelectInput } from '../../../components/molecules/Form';
import { formatSuspicionLabel } from '../../../utils/formatters';
import {
  useGetSuspicionsQuery,
  useGetAssessmentsListQuery,
} from '../../../services';

import { TimelineSidebarWrapper } from './TimelineSidebarWrapper';
import { TimelineSidebarSuspicions } from './TimelineSidebarSuspicions';
import { TimelineSidebarAssessments } from './TimelineSidebarAssessments';
import {
  useTimelineIndicator,
  useTimelineAssessments,
  useTimelinePatientId,
} from '../slices';
import { ChatCButton } from '../../../components/organisms/ChatComments/ChatCommentButton';
import { ChatComment } from '../../../components/organisms/ChatComments/ChatComment';

const noFilterValue = 'all';
export const TimelineSidebar = () => {
  // Timeline state
  const patientId = useTimelinePatientId();
  const suspicions = useTimelineIndicator('suspeitas');
  const assessments = useTimelineAssessments();

  // Estado dos filtros
  const [suspicionFilterValue, setSuspicionFilterValue] =
    useState(noFilterValue);

  const handleSelectChange = useCallback(
    (e) => {
      if (e?.target?.value) setSuspicionFilterValue(e.target.value);
    },
    [setSuspicionFilterValue]
  );

  // Estado das suspeitas
  const { isLoading: isLoadingSuspicions, isFetching: isFetchingSuspicions } =
    useGetSuspicionsQuery(patientId, {
      skip: !patientId,
    });

  const filteredSuspicions = useMemo(() => {
    if (suspicionFilterValue === noFilterValue) {
      return suspicions;
    } else {
      return suspicions.filter(
        (suspicion) => suspicion.id === suspicionFilterValue
      );
    }
  }, [suspicions, suspicionFilterValue]);

  // Estado das avaliações
  const { isLoading: isLoadingAssessments, isFetching: isFetchingAssessments } =
    useGetAssessmentsListQuery(patientId, {
      skip: !patientId,
    });

  // As opções do select dependem do carregamento das suspeitas
  const suspicionFilterOptions = useMemo(
    () => [
      { value: noFilterValue, label: 'Todas as janelas' },
      ...suspicions.map((suspicion) => ({
        value: suspicion.id,
        label: formatSuspicionLabel(suspicion),
      })),
    ],
    [suspicions]
  );

  useEffect(() => {
    const lastSuspicion = suspicionFilterOptions[1]?.value;

    setSuspicionFilterValue(lastSuspicion)
  }, [suspicions])

  return (
    <TimelineSidebarWrapper
      loading={isFetchingSuspicions || isFetchingAssessments}
      heading={
        <SelectInput
          margin="none"
          bgcolor="white"
          options={suspicionFilterOptions}
          value={suspicionFilterValue}
          onChange={handleSelectChange}
        />
      }
    >
      <TimelineSidebarSuspicions
        suspicions={filteredSuspicions}
        loading={isLoadingSuspicions}
      />
      <TimelineSidebarAssessments
        assessments={assessments}
        loading={isLoadingAssessments}
      />
    </TimelineSidebarWrapper>
  );
};
