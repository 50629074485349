import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectTimelineSlice } from './selectTimelineSlice';

/**
 * patient id selector
 */
 export const selectTimelinePatientId = createSelector(
    selectTimelineSlice,
    (timeline) => timeline.patientId
);
export const useTimelinePatientId = () => useSelector(selectTimelinePatientId);
