import React from 'react';
import { Menu as MuiMenu, Fade, withStyles } from '@material-ui/core';
import { useDropMenuContext } from './DropMenuProvider';

const Menu = withStyles((theme) => ({
  list: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
}))(MuiMenu);

export const DropMenuList = (props) => {
  const { open, ref, handleClose } = useDropMenuContext();
  return (
    <Menu
      open={open}
      anchorEl={ref.current}
      onClose={handleClose}
      TransitionComponent={Fade}
      getContentAnchorEl={props?.anchorOrigin?.vertical ? null : undefined}
      {...props}
    />
  );
};
