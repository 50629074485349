import { useMemo } from 'react';

/**
 * Recebe uma string que representa um array "['calafrio', 'cvc', 'pneumonia', 'plaquetas', 'febre', 'lesao_pulmonar', 'temperatura']" e
 *
 * Retorna um array ['calafrio', 'cvc', 'pneumonia', 'plaquetas', 'febre', 'lesao_pulmonar', 'temperatura']
 *
 * @param termosAchados Uma string representando um array
 * @return {string[]} Um array de strings
 */
export const parseTermosAchados = (termosAchados) =>
  (termosAchados || '')
    .replace(/\[|\]|\'|\s/gim, '')
    .replace(/\_/gm, ' ')
    .trim()
    .split(',');

export const useParseTermosAchados = (termos_achados = '') => {
  return useMemo(() => parseTermosAchados(termos_achados), [termos_achados]);
};
