import React from 'react';

import { withStyles } from '@material-ui/core';
import { useTimelineInfectionAssessments } from '../../../slices';
import { AssessmentCard } from '../../../AssessmentCard';

export const InfectionAssessmentsTitle = withStyles((theme) => ({
  root: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '19px',
    color: theme.palette.neutral.black['100'],
    marginBottom: theme.spacing(1),
  },
}))(({ classes, children }) => <div className={classes.root}>{children}</div>);

export const InfectionAssessments = ({ date }) => {
  const assessments = useTimelineInfectionAssessments(date);

  return !assessments?.length ? null : (
    <>
      <InfectionAssessmentsTitle>Avaliações:</InfectionAssessmentsTitle>
      {assessments.map((assessment) => (
        <AssessmentCard
          key={assessment.id}
          assessment={assessment}
          color="gray"
          shadow={false}
          style={{
            marginBottom: '12px',
          }}
        />
      ))}
    </>
  );
};
