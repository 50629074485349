import React, { useMemo } from 'react';
import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { Box, Button, Divider } from '@material-ui/core';
import { Drawer, DrawerToolbar } from '../../../components/molecules/Drawer';
import {
  CheckboxField,
  MultiselectField,
  RadioGroupField,
} from '../../../components/molecules/Form';
import {
  infectionTypesEnum,
  otherInfection,
  otherInfectionLabel,
  noInfection,
  noInfectionLabel,
} from '../../../constants/infections';
import {
  useHistoryInfections,
  useResetHistoryFilters,
  useSetHistoryInfections,
  useHistoryAssessment,
  useSetHistoryAssessment,
  useHistoryLocation,
  useHistoryEvaluator,
  useSetHistoryLocation,
  useSetHistoryEvaluator,
} from './slices';
import { useGetHospitalUnityQuery } from '../../../services'

import { DangerButtonOutline } from '../../../components/atoms/DangerButtonOutline';

const infectionsOptions = [
  {
    value: infectionTypesEnum.ipcs,
    label: infectionTypesEnum.ipcs.toUpperCase(),
  },
  {
    value: infectionTypesEnum.isc,
    label: infectionTypesEnum.isc.toUpperCase(),
  },
  {
    value: infectionTypesEnum.itu,
    label: infectionTypesEnum.itu.toUpperCase(),
  },
  {
    value: infectionTypesEnum.pav,
    label: infectionTypesEnum.pav.toUpperCase(),
  },
  {
    value: infectionTypesEnum.pnm,
    label: infectionTypesEnum.pnm.toUpperCase(),
  },
  {
    value: infectionTypesEnum.traqueo,
    label: infectionTypesEnum.traqueo.toUpperCase(),
  },
  { value: otherInfection, label: otherInfectionLabel },
  { value: noInfection, label: noInfectionLabel },
];

const assessmentOptions = [
  {
    value: 'all',
    label: 'Todos',
  },
  {
    value: 'positiva',
    label: 'Confirmada',
  },
  {
    value: 'negativa',
    label: 'Descartada',
  },
  {
    value: 'comunitaria',
    label: 'Infecção comunitária'
  }
];

const evaluatorOptions = [
  {
    label: 'admin',
    value: 'admin',
  },
  {
    label: 'Rodrigo Pires',
    value: 'Rodrigo Pires',
  },
  {
    label: 'Stephani Lukasewicz',
    value: 'Stephani Lukasewicz',
  },
];

export const HistoryFilterDrawer = ({ open, onClose, isLoading }) => {
  const infections = useHistoryInfections();
  const assessment = useHistoryAssessment();
  const location = useHistoryLocation();
  const evaluator = useHistoryEvaluator();

  const setInfections = useSetHistoryInfections();
  const setAssessment = useSetHistoryAssessment();
  const setLocation = useSetHistoryLocation();
  const setEvaluator = useSetHistoryEvaluator();

  const handleSubmit = useCallback(
    (values, actions) => {
      // console.log("Submit filtro histórico", values);
      setInfections(values.infections);
      setAssessment(values.assessment);
      setLocation(values.location);
      setEvaluator(values.evaluator);
      actions.setSubmitting(false);
    },
    [setInfections, setAssessment, setLocation, setEvaluator]
  );

  return (
    <Formik
      initialValues={{ infections, assessment, location, evaluator }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Drawer
        title="Filtrar"
        open={open}
        loading={isLoading}
        onClose={onClose}
        PaperProps={{ component: Form }}
        footer={
          <DrawerToolbar borderTop wide>
            <FormFooter loading={isLoading} />
          </DrawerToolbar>
        }
      >
        <Box margin="8px 16px">
          <FormBody loading={isLoading} />
        </Box>
      </Drawer>
    </Formik>
  );
};

const FormFooter = ({ loading }) => {
  const handleReset = useResetHistoryFilters();

  return (
    <Box display="flex" justifyContent="space-between">
      <DangerButtonOutline
        type="reset"
        onClick={handleReset}
        disabled={loading}
      >
        Limpar filtro
      </DangerButtonOutline>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
      >
        Aplicar
      </Button>
    </Box>
  );
};

const FormBody = ({ loading }) => {
  const {
    data: hospitalUnity,
    isLoading: isLoadingLocations,
    error: errorLoadingLocations,
  } = useGetHospitalUnityQuery();
  const locations = useMemo(
    () =>
      (hospitalUnity || []).map((location) => {
        const { unidades: value } = location._source;
        return { value, label: value };
      }),
    [hospitalUnity]
  );

  const locationOptions = useMemo(
    () => [
      { value: '', label: 'Selecione o local', disabled: true },
      ...locations,
    ],
    [locations]
  );

  return (
    <Box>
      <RadioGroupField
        label={`Avaliação`}
        options={assessmentOptions}
        name="assessment"
        disabled={loading}
      />
      <Box my={2}>
        <Divider />
      </Box>
      <CheckboxField
        label={`Tipo de Infecção`}
        options={infectionsOptions}
        name="infections"
        disabled={loading}
      />
      <Box my={2}>
        <Divider />
      </Box>
      <MultiselectField
        label={`Local de infecção`}
        placeholder={'Selecione o local'}
        options={locationOptions}
        name="location"
      />
      <Box my={2}>
        <Divider />
      </Box>
      <MultiselectField
        label={`Avaliador (a)`}
        placeholder={'Selecione o(a) avaliador(a)'}
        options={evaluatorOptions}
        name="evaluator"
      />
    </Box>
  );
};
