import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { indicatorsEnum } from '../../../../constants/indicators';
import { selectTimelineCursor } from './selectTimelineCursor';
import { selectTimelineActiveIndicator } from './selectTimelineActiveIndicator';

export const selectTimelineEncontroActive = createSelector(
  [selectTimelineActiveIndicator, selectTimelineCursor],
  ([encontro] = [], { indicator }) => {
    if (indicator !== indicatorsEnum.encontros || !encontro) return undefined;
    return encontro;
  }
);
export const useTimelineEncontroActive = () =>
  useSelector(selectTimelineEncontroActive);
