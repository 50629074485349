import React from 'react';
import { StyledChip } from './styles';

export const Chip = React.forwardRef((props, ref) => {
  const {
    label,
    icon,
    chevron,
    onClick,
    size,
    variant,
    outlined,
    children,
    component,
    ...chipProps
  } = props;

  return (
    <StyledChip
      size={size}
      variant={variant}
      outlined={outlined}
      onClick={onClick}
      {...chipProps}
      component={!onClick ? 'div' : undefined}
      ref={ref}
    >
      {icon}
      {label && <div style={{ flex: '1 1 auto' }}>{label}</div>}
    </StyledChip>
  );
});
Chip.defaultProps = {
  label: undefined, // Texto do chip
  variant: 'gray', // gray, primary, success, warning, danger
  size: 'medium', // medium, small, large
  outlined: false, // exibe a borda
  chevron: false, // Exibe o icone
};
