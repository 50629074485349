import { Paper as MuiPaper, withStyles } from '@material-ui/core'
import MuiAutocomplete from '@material-ui/lab/Autocomplete';

export const Paper = withStyles(theme => ({
  root: {
    borderRadius: '8px',
  }
}))(MuiPaper)
Paper.defaultProps = {
  elevation: 8,
}

export const Autocomplete = withStyles(theme => ({
  option: {
    padding: '12px',
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
  },
  loading: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
  },
  noOptions: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
  }
}))(MuiAutocomplete)
Autocomplete.defaultProps = {
}