import React, { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { withFormikField } from '../utils';
import { TextInput } from '../TextInput';

import { Paper, Autocomplete } from './styles';

const getOptionSelected = (o, v) => o === v || o.value === v;

export const AutocompleteInput = React.forwardRef((props, ref) => {
  const {
    onChange,
    value,
    options,
    label,
    placeholder,
    error,
    helperText,
    margin,
    disabled,
    fullWidth,
  } = props;

  const getOptionLabel = useCallback(
    (selectedOptionOrValue) => {
      const selectedOption =
        typeof selectedOptionOrValue === 'string'
          ? options.find((o) => o.value === selectedOptionOrValue)
          : selectedOptionOrValue;
      return selectedOption.label;
    },
    [options]
  );

  const getOptionDisabled = useCallback(
    (option) => !!(disabled || option.disabled),
    [disabled]
  );

  const validOptions = useMemo(() => options.filter(o => o.value !== ''), [options])

  const placeholderOption = useMemo(
    () => options.find((o) => o.value === ''),
    [options]
  );

  const selectedOption = useMemo(
    () => options.find((o) => o.value === value) || undefined,
    [options, value]
  );

  const renderInput = useCallback(
    (params) => {
      const { InputProps, inputProps, InputLabelProps, ...rest } = params;
      return (
        <TextInput
          {...rest}
          inputProps={{
            ...inputProps,
            className: '',
            value:
              inputProps.value === placeholderOption?.label
                ? ''
                : inputProps.value,
          }}
          {...InputProps}
          placeholder={placeholderOption?.label || placeholder}
          label={label}
          error={error}
          helperText={helperText}
          margin={margin}
          disabled={disabled}
          fullWidth={fullWidth}
        />
      );
    },
    [
      placeholderOption,
      placeholder,
      label,
      error,
      helperText,
      margin,
      disabled,
      fullWidth,
    ]
  );

  const handleChange = useCallback(
    (_, option) => {
      if (onChange && typeof option?.value !== 'undefined') {
        onChange(option.value);
      }
    },
    [onChange]
  );

  return (
    <Autocomplete
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      getOptionDisabled={getOptionDisabled}
      renderInput={renderInput}
      {...props}
      options={validOptions}
      value={selectedOption}
      onChange={handleChange}
      ref={ref}
    />
  );
});
AutocompleteInput.defaultProps = {
  noOptionsText: 'Nenhuma opção encontrada',
  loadingText: 'Carregando...',
  openText: 'Abrir menu de seleção',
  closeText: 'Fechar menu de seleção',
  clearText: 'Limpar seleção',
  closeIcon: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#3E4247" fill-opacity="0.72" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 9.29289L13.182 6.11091L13.8891 6.81802L10.7071 10L13.8891 13.182L13.182 13.8891L10 10.7071L6.81804 13.8891L6.11094 13.182L9.29292 10L6.11094 6.81802L6.81804 6.11091L10 9.29289Z"
        fill="white"
      />
    </svg>
  ),
  PaperComponent: Paper,
};

export const AutocompleteField = withFormikField(
  React.forwardRef((props, ref) => {
    const { setFieldValue, validateOnChange } = useFormikContext();
    const { name } = props;

    const handleChange = useCallback(
      (value) => setFieldValue(name, value, validateOnChange),
      [setFieldValue, name, validateOnChange]
    );

    return <AutocompleteInput {...props} onChange={handleChange} ref={ref} />;
  })
);
