import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { orderBy, groupBy, map, min, max } from 'lodash';
import moment from 'moment';
import { selectTimelineIndicatorsDates } from './selectTimelineIndicatorsDates';

const memoEmpty = [];
export const selectTimelineVitalSigns = createSelector(
  selectTimelineIndicatorsDates,
  (indicatorsDates) => {
    const vitalSignsFacts = indicatorsDates?.['sinais-vitais'];
    if (!vitalSignsFacts) return memoEmpty;

    const mappedVitalSigns = orderBy(
      map(vitalSignsFacts, (items, dateKey) => {
        const date = +dateKey;
        const momentDate = moment(date);

        const signals = map(
          groupBy(items, 'tipo_sinal'),
          (signalItems, signal) => {
            const values = map(signalItems, 'parsedValue');

            return {
              signal,
              measure: signalItems[0]?.unimedida,
              hasCriteria: signalItems.some((item) => item.criterio === 'sim'),
              min: min(values),
              max: max(values),
              date,
              momentDate,
            };
          }
        );

        return {
          date,
          momentDate,
          items,
          signals,
          hasCriteria: signals.some((signal) => signal.hasCriteria),
        };
      }),
      ['date'],
      ['desc']
    );

    return mappedVitalSigns;
  }
);
export const useTimelineVitalSigns = () =>
  useSelector(selectTimelineVitalSigns);
