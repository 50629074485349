import styled from "styled-components";
export const PatientCardListContainer = styled.div`
  background: #F5F5F5;
  border-radius: 8px;
  padding: 16px;
  overflow: hidden;
  position: relative;

  .MuiLinearProgress-root {
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
`;
export const PatientCardListHeader = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #383A3D;
  margin-bottom: 16px;
`;
export const MyNavigation = styled.div`

`;
export const PatientCardListBody = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 260px);
  padding-right: 15px;
  @media (max-width: 960px) {
    padding-right: 0;
    max-height: 400px;
  }
`;
