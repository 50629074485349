import React from 'react';

export const FilterIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66671 7.33333C7.42415 7.33333 6.38008 6.48349 6.08405 5.33333H1.33337V4H6.08405C6.38008 2.84985 7.42415 2 8.66671 2C10.1395 2 11.3334 3.19391 11.3334 4.66667C11.3334 6.13943 10.1395 7.33333 8.66671 7.33333ZM12.6667 4H14.6667V5.33333H12.6667V4ZM5.33337 14C4.09081 14 3.04675 13.1502 2.75072 12H1.33337V10.6667H2.75072C3.04675 9.51652 4.09081 8.66667 5.33337 8.66667C6.80613 8.66667 8.00004 9.86057 8.00004 11.3333C8.00004 12.8061 6.80613 14 5.33337 14ZM9.33337 12H14.6667V10.6667H9.33337V12ZM6.66671 11.3333C6.66671 12.0697 6.06975 12.6667 5.33337 12.6667C4.59699 12.6667 4.00004 12.0697 4.00004 11.3333C4.00004 10.597 4.59699 10 5.33337 10C6.06975 10 6.66671 10.597 6.66671 11.3333ZM10 4.66667C10 5.40305 9.40309 6 8.66671 6C7.93033 6 7.33337 5.40305 7.33337 4.66667C7.33337 3.93029 7.93033 3.33333 8.66671 3.33333C9.40309 3.33333 10 3.93029 10 4.66667Z"
      fill="white"
    />
  </svg>
);
