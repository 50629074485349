import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetFilters } from '../slice';

export const useResetPatientsFilters = () => {
  const dispatch = useDispatch();

  const handleResetPatientsFilters = useCallback(
    () => dispatch(resetFilters()),
    [dispatch]
  );

  return handleResetPatientsFilters;
};
