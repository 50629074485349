import React, { useEffect, useState } from 'react';
import { withStyles, Divider, Box, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { array } from 'prop-types';

export const PageHeading = withStyles((theme) => ({
  root: {},
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2.5),
    marginBottom: theme.spacing(3),
  },
  title: {
    flex: '1 0 auto',
    paddingRight: theme.spacing(2),
  },
  action: {
    flex: '0 0 auto',
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
}))(function StyledPageHeading({ classes, className, title, action, divider }) {
  
  const [actions, setActions] = useState([]);

  useEffect(() => {
    if (action) {
      return setActions(!Array.isArray(action) ? Array.of(action) : action);
    }
    return [];

  }, [action])
  
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.container}>
        <div className={classes.title}>{title}</div>
        {actions.length && (
          <Box display="flex" flexDirection='row' justifyContent='end' style={{gap: '5px'}}>
            {actions.map(action => <div className={classes.action}>{action}</div>)}
          </Box> 
        )}
      </div>
      {divider && (
        <div className={classes.divider}>
          <Divider />
        </div>
      )}
    </div>
  );
});
