import React from 'react';
import { Grid } from '@material-ui/core';
import { DashboardPanel } from '../../DashboardLayout';
import { SuspicionsByProbability } from './SuspicionsByProbability';
import { SuspicionsByInfection } from './SuspicionsByInfection';

export const DashboardSuspicionCharts = ({ indicators }) => {
  if(!indicators) {
    return ''
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={3}>
        <DashboardPanel title="Por probabilidade">
          <SuspicionsByProbability indicators={indicators} />
        </DashboardPanel>
      </Grid>
      <Grid item sm={6}>
        <DashboardPanel title="Por tipo de infecção">
          <SuspicionsByInfection indicators={indicators} />
        </DashboardPanel>
      </Grid>
    </Grid>
  );
};
