import React, { useLayoutEffect, useRef } from 'react';
import D3DonutsChart from './D3DonutsChart';
import { DonutsChartContainer } from './DonutsChartContainer';

export const DonutsChart = (props) => {
  const { isLoading, dataset } = props;
  const refChart = useRef();

  useLayoutEffect(() => {
    if (!refChart.current) return;

    const chart = new D3DonutsChart({
      container: refChart.current,
      dataset,
      isLoading,
    });

    const updateSize = () => chart.changeSizes();
    updateSize();

    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [isLoading, refChart, dataset]);

  return <DonutsChartContainer ref={refChart} />;
};
