import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { selectTimelineSlice } from './selectTimelineSlice';

/**
 * selector do popover de indicador aberto
 */
export const selectIndicatorPopover = createSelector(
  selectTimelineSlice,
  (timeline) => timeline.indicatorPopover
);
export const useIndicatorPopover = () => useSelector(selectIndicatorPopover);

export const makeSelectIndicatorPopoverIsActive = () => {
  const selector = createSelector(
    selectIndicatorPopover,
    (_, indicator) => indicator,
    (indicatorPopover, indicator) => indicatorPopover === indicator
  );
  return selector;
};
export const useIndicatorPopoverIsActive = (indicator) => {
  const select = useMemo(makeSelectIndicatorPopoverIsActive, []);
  const selector = useCallback((state) => select(state, indicator), [
    indicator,
    select,
  ]);
  return useSelector(selector);
};
