import { createSelector } from '@reduxjs/toolkit';
import { selectPatientsSlice } from './selectPatientsSlice';
import { useSelector } from 'react-redux';

export const selectPatientsItemsPerPage = createSelector(
  selectPatientsSlice,
  (patientsState) => patientsState.itemsPerPage
);
export const usePatientsItemsPerPage = () =>
  useSelector(selectPatientsItemsPerPage);
