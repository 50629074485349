import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useGetSettingsQuery } from '../../../../../services';
import { useTimelineActiveIndicator } from '../../../slices';
import { TimelineDrawerListItem } from '../../TimelineDrawerListItem';
import { formatItems } from './util';
import { InfectionAssessments } from './InfectionAssessments';

export const TimelineDrawerInfeccoes = ({ date }) => {
  const { data: settings } = useGetSettingsQuery('');
  const indicators = useTimelineActiveIndicator();
  const infections = useMemo(
    () => indicators?.find((inf) => typeof inf.max_prob !== 'undefined'),
    [indicators]
  );
  const listItems = useMemo(
    () => (!!infections ? formatItems(infections, settings) : []),
    [infections, settings]
  );

  return (
    <Box padding={2}>
      {!!listItems.length && (
        <Box mb={2}>
          {listItems.map(({ label, value, level }, i) => (
            <TimelineDrawerListItem
              key={i}
              label={label}
              value={value}
              level={level}
            />
          ))}
        </Box>
      )}
      <InfectionAssessments date={date} />
    </Box>
  );
};
