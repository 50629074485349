import React from 'react';

export const DeleteIcon = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8" cy="8.50003" r="8" fill="#3E4247" fillOpacity="0.48" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00012 7.93435L10.5457 5.38876L11.1114 5.95445L8.5658 8.50003L11.1114 11.0456L10.5457 11.6113L8.00012 9.06572L5.45453 11.6113L4.88885 11.0456L7.43443 8.50003L4.88885 5.95445L5.45453 5.38876L8.00012 7.93435Z"
      fill="white"
    />
  </svg>
);
