import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { orderBy, groupBy, map, min, max } from 'lodash';
import moment from 'moment';
import { selectTimelineIndicatorsDates } from './selectTimelineIndicatorsDates';
import {
  cultureExamsList,
  TopExamsSortEnum,
  TopExamsSortEnumKeys,
} from '../../../../constants/exams';
import { orderExams } from '../../../../components/atoms/TimelineChart/Tooltips/helpers';

export const getTopLevelExamOrder = (examName) => {
  const enumKey = TopExamsSortEnumKeys.find((key) =>
    examName.toUpperCase().includes(key.toUpperCase())
  );

  const enumIndex = TopExamsSortEnum[enumKey];
  return typeof enumIndex === 'undefined'
    ? TopExamsSortEnum['[DEFAULT]']
    : enumIndex;
};

export const examIsCulture = (exam) =>
  cultureExamsList.some((cultureExam) =>
    exam.includes(cultureExam.toUpperCase())
  );

export const orderTopLevelExams = (exams) =>
  orderBy(
    orderBy(orderBy(exams, 'exame', 'asc'), 'dthr_pedido', 'asc'),
    (exam) => {
      return getTopLevelExamOrder(exam.exame);
    },
    'asc'
  );

export const groupExamsByDateAndName = (examsFacts) => {
  return orderBy(
    map(examsFacts, (items, dateKey) => {
      const date = +dateKey;
      const momentDate = moment(date);

      const exams = map(groupBy(items, 'exame'), (examItems, exam) => {
        return {
          exam,
          items: orderBy(
            orderBy(examItems, 'dthr_pedido', 'asc'),
            orderExams,
            'asc'
          ),
          hasGmr: examItems.some((item) => item.gmr === 'sim'),
          hasCriteria: examItems.some((item) => item.criterio === 'sim'),
          hasPositive: examItems.some((item) => item.positivo === 'sim'),
          isCulture: examIsCulture(exam),
          date,
          momentDate,
        };
      });

      return {
        date,
        momentDate,
        allItems: items,
        exams: orderBy(orderBy(exams, 'exam', 'asc'), (exam) => {
          return getTopLevelExamOrder(exam.exam);
        }),
        hasGmr: exams.some((exam) => exam.hasGmr),
        hasCriteria: exams.some((exam) => exam.hasCriteria),
        hasPositive: exams.some((exam) => exam.hasPositive),
        hasCulture: exams.some((exam) => exam.isCulture),
      };
    }),
    ['date'],
    ['desc']
  );
};

const memoEmpty = [];
export const selectTimelineExams = createSelector(
  selectTimelineIndicatorsDates,
  (indicatorsDates) => {
    const examsFacts = indicatorsDates?.exames;
    if (!examsFacts) return memoEmpty;

    const mappedExams = groupExamsByDateAndName(examsFacts);

    return mappedExams;
  }
);
export const useTimelineExams = () => useSelector(selectTimelineExams);
