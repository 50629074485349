import React, { useEffect, useState } from "react";
import { PacientesTemplate } from "../../templates/Pacientes/styles";
import ReactMarkdown from 'react-markdown'
import rehypeHighlight from 'rehype-highlight'
import { Grid } from "@material-ui/core";
import { Container } from "./styles";
const Changelog = (props) => { 
  const readmePath = require("./CHANGELOG.md");
  const [text, setText] = useState('');
  useEffect(()=>{
    fetch(readmePath)
    .then((r) => r.text())
    .then(text => {
      console.log(text)
      setText(text)
    }) 
  },[])
  return (
    <PacientesTemplate>
      <Container>
        <Grid container>
          <Grid item>
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>{text}</ReactMarkdown>
          </Grid>
        </Grid>
      </Container>
    </PacientesTemplate>
  );
};
 
export default Changelog;
