import { useMemo } from 'react';
import { concat, uniq, sortBy, map } from 'lodash';
import {
  createRegexKeywords,
  keywords as defaultKeywords,
} from '../../../../components/atoms/TimelineChart/Tooltips/constants';

const emptyKeywords = [];
export const useIndicatorTerms = (hookProps) => {
  let {
    keywords = defaultKeywords,
    aiKeywords = emptyKeywords,
    text = '',
  } = hookProps;


  // MAP keywords para padrão de regex
  keywords = keywords.map(keyword => keyword.replace(/\\/g, "").replaceAll("w*", "\\w*").replaceAll("w*\\", "w* \\").split("|")).flat(1);
  
  // Regex que inclui termos da IA e termos estáticos da constante 'keywords'
  const regexKeywords = useMemo(
    () => createRegexKeywords(uniq(concat(keywords, aiKeywords))),
    [aiKeywords]
  );

  // Objeto com contagem de termos encontrados no texto, ex: { cvc: 12, infeccao: 6 }
  const termsCount = useMemo(
    () =>
      {
        if (!text) return {}
        return (text.toLowerCase().match(regexKeywords) || []).reduce(
          (acc, keyword) => ({
            ...acc,
            [keyword?.toLowerCase()]: (acc[keyword?.toLowerCase()] || 0) + 1,
          }),
          {}
        )
      },
      
    [text, regexKeywords]
  );



  // Lista de termos ordenados alfabeticamente
  // [{ keyword: 'cvc', count: 12, variant: 'white' }, { keyword: 'infeccao', count: 6, variant: 'warning' }]
  const terms = useMemo(
    () =>
      sortBy(
        map(termsCount, (count, word) => {
          const keyword = word?.toLowerCase();
          // Termos sugeridos pela IA possuem destaque
          let variant = 'white';
          if (aiKeywords.includes(keyword)) {
            variant = 'primary';
          }
          return {
            keyword,
            count,
            variant,
          };
        }),
        'keyword'
      ),
    [termsCount, aiKeywords]
  );
  return terms;
};
