import React, { useCallback } from 'react';
import { FieldArray } from 'formik';
import { MicroorganismsInput } from './MicroorganismsInput';

export const MicroorganismsFieldInput = (props) => {
  const { inputProps, ...arrayFieldProps } = props;
  const { form, push, remove } = arrayFieldProps;
  const { name } = inputProps;
  const {
    setFieldValue,
    setFieldError,
    setFieldTouched,
    validateOnChange,
  } = form;

  const handleChange = useCallback(
    (nextValue) => setFieldValue(name, nextValue, false),
    [setFieldValue, setFieldError, setFieldTouched, name, validateOnChange]
  );

  const handleAppend = useCallback(
    (item) =>
      push({
        microorganism: item?.microorganism ?? '',
        resistant: item?.resistant ?? '',
      }),
    [push]
  );

  const { touched, errors } = form;

  const feedbackProps = {};

  if (touched[name] && errors[name]) {
    feedbackProps.errors = errors[name];
    feedbackProps.helperTexts = errors[name];
  }

  if (form.isSubmitting) {
    feedbackProps.disabled = true;
  }

  return (
    <MicroorganismsInput
      {...inputProps}
      value={form.values[name]}
      onChange={handleChange}
      onRemove={remove}
      onAppend={handleAppend}
      {...feedbackProps}
    />
  );
};

export const MicroorganismsField = React.memo((props) => {
  const { name } = props;

  return (
    <FieldArray
      name={name}
      component={(arrayFieldProps) => (
        <MicroorganismsFieldInput {...arrayFieldProps} inputProps={props} />
      )}
    />
  );
});
