/**
 * Enum de possíveis infecções
 *
 * @constant
 * @type {{ ipcs: 'IPCS', isc: 'ISC', itu: 'ITU', pav: 'PAV', pnm: 'PNM', traqueo: 'Traqueo' }}
 */
export const infectionTypesEnum = {
  ipcs: 'ipcs',
  isc: 'isc',
  itu: 'itu',
  pav: 'pav',
  pnm: 'pnm',
  traqueo: 'traqueo',
  comunitaria: 'comunitaria',
};

/**
 * Lista de possíveis infecções
 *
 * @constant
 * @type {['ipcs', 'isc', 'itu', 'pav', 'pnm', 'traqueo']}
 */
export const infectionTypesList = Object.keys(infectionTypesEnum);

/**
 * Infecção não identificada
 *
 * @constant
 * @type {'outra'}
 */
export const otherInfection = 'outra';
export const otherInfectionLabel = 'Outro';

/**
 * Sem infecção
 *
 * @constant
 * @type {'sem infecção'}
 */
export const noInfection = 'sem infecção';
export const noInfectionLabel = 'Sem critério p/ infecção';

/**
 * Caso específico extraído dos indicadores
 *
 * @constant
 * @type {'covid'}
 */
export const covidInfection = 'covid';

/**
 * Potenciais de contaminação
 */
export const contaminationPotentialsEnum = /** @type {const} */ ({
  limpa: 'limpa',
  potencialmente_contaminada: 'potencialmente_contaminada',
  contaminada: 'contaminada',
  infectada: 'infectada',
  sem_informacoes_suficientes: 'sem_informacoes_suficientes',
});

/**
 * Classificação da infecção
 */
export const infectionClassificationsEnum = /** @type {const} */ ({
  superficial: 'superficial',
  profunda: 'profunda',
  orgao_cavidade: 'orgao_cavidade',
  sem_informacoes_suficientes: 'sem_informacoes_suficientes',
});
