import moment from 'moment';
import {isEqual, isEmpty, difference} from 'lodash';

class Dataset {
  /**
   * Returns an array with a range of dates between a min and max
   * chunked by fortnights
   *
   * @param firstDate
   * @param lastDate
   * @returns {Array}
   */
  generateDateRange(
    firstDate = moment().startOf('month'),
    lastDate = moment().endOf('month')
  ) {
    const dateList = [];
    const initialDate = moment(firstDate).clone();
    const finalDate = moment(lastDate).clone();

    if (initialDate.date() <= 15) initialDate.startOf('month');
    else initialDate.set('date', 15);

    if (finalDate.date() > 15) finalDate.set('date', 15);
    else finalDate.startOf('month');

    for (let newDate = initialDate.clone(); newDate <= finalDate; ) {
      dateList.push({
        date: newDate.clone(),
        value: 0,
      });

      if (newDate.date() >= 15) newDate.add(1, 'month').startOf('month');
      else newDate.set('date', 15);
    }
    return dateList;
  }

  findInRange(range = [], date = moment()) {
    if (!range.length) return;

    for (let a = 0; a < range.length; a++) {
      if (moment.utc(date).month() === moment.utc(range[a].date).month()) {
        if (
          (moment.utc(range[a].date).date() === 1 && date.date() <= 15) ||
          (moment.utc(range[a].date).date() === 15 && date.date() > 15)
        )
          return a;
      }
    }
  }

  /**
   * Returns an object specifically shaped to feed the
   * 'Infections Pert-type on period' chart.
   *
   * @param suspiciousInfections
   * @param initialDate
   * @param finalDate
   * @returns {Array}
   */
  getIsaInfectionCountPerTypeOnPeriod(
    suspiciousInfections = [],
    initialDate,
    finalDate
  ) {
    if (!suspiciousInfections.length) return [];

    const [sampleElement = {}] = suspiciousInfections;
    const infectionTypes = Object.keys(sampleElement);
    const infectionsCountPerTypeOnPeriod = [];

    infectionTypes.shift();

    infectionTypes.forEach((infectionType) => {
      infectionsCountPerTypeOnPeriod.push({
        name: infectionType,
        data: this.generateDateRange(initialDate, finalDate),
      });
    });

    if (infectionTypes.length) {
      suspiciousInfections.forEach((infection) => {
        const infectionDate = moment.utc(infection.key.by_date);
        const dateIndex = this.findInRange(
          this.generateDateRange(initialDate, finalDate),
          infectionDate
        );

        if (dateIndex !== undefined) {
          infectionsCountPerTypeOnPeriod.forEach((infectionOnPeriod) => {
            const { name, data } = infectionOnPeriod;
            const {
              buckets: [{ doc_count }],
            } = infection[name];

            data[dateIndex].value += doc_count;
          });
        }
      });
    }

    return infectionsCountPerTypeOnPeriod;
  }

  /**
   * Returns an object specifically shaped to feed the
   * 'ISA Performance' chart.
   *
   * @param suspiciousInfections
   * @param assessedArray
   * @param initialDate
   * @param finalDate
   * @returns {*[]}
   */
  getIsaPerformanceChartData(
    suspiciousInfections,
    assessedArray,
    initialDate,
    finalDate
  ) {
    const assessedInfectionsOnPeriod = {};
    const datePeriod = this.generateDateRange(initialDate, finalDate);

    datePeriod.forEach((date) => {
      assessedInfectionsOnPeriod[date.date.format('DD/MM/Y')] = {
        date: date.date,
        positivo: {
          value: 0,
        },
        negativo: {
          value: 0,
        },
        alta: {
          value: 0,
        },
        media: {
          value: 0,
        },
        baixa: {
          value: 0
        }
      };
    });

    suspiciousInfections.forEach((infection) => {
      const {
        key: { by_date },
        grupos: { buckets: groupBucket = [] },
      } = infection;

      const currentInfectionDate = moment(by_date);

      if (currentInfectionDate.date() <= 15)
        currentInfectionDate.set('date', 1);
      else currentInfectionDate.set('date', 15);

      const {
        [currentInfectionDate.format('DD/MM/Y')]: assessed,
      } = assessedInfectionsOnPeriod;

      const { alta, media, baixa } = assessed;

      groupBucket.forEach((bucket) => {

        const { key, doc_count } = bucket;

        switch(key) {
          case 'ALTA':
            alta.value += doc_count;
            break;
          case 'MEDIA':
            media.value += doc_count;
            break;
          case 'BAIXA':
            baixa.value += doc_count;
            break;
          default:
            return;
        }
      });
    });

    assessedArray.forEach((assessment) => {
      const {
        pacientes_hits: {
          hits: { hits },
        },
      } = assessment;

      hits.forEach((hit) => {
        const {
          _source: { avaliacao_status, dt_infeccao },
        } = hit;

        const currentInfectionDate = moment(dt_infeccao);

        if (currentInfectionDate.date() <= 15)
          currentInfectionDate.set('date', 1);
        else currentInfectionDate.set('date', 15);

        const {
          [currentInfectionDate.format('DD/MM/Y')]: assessed,
        } = assessedInfectionsOnPeriod;

        const { positivo, negativo } = assessed;

        if (avaliacao_status === 'positiva') positivo.value += 1;
        else if (avaliacao_status === 'negativa') negativo.value += 1;
      });
    });

    return Object.keys(assessedInfectionsOnPeriod).map(
      (key) => assessedInfectionsOnPeriod[key]
    );
  }

  extractPatientPredictedInfection(patient = {}) {
    return Object.keys(patient)
      .filter(
        (property) =>
          property.split('_')[0] === 'pred' && patient[property] === 1
      )
      .map((infection) => infection.split('_')[1]);
  }

  /**
   * Removes patients whose predicted infections have been assessed
   *
   * @param patientsWithSuspicion
   * @param assessmentsArray
   */
  filterPatientsWithPendingAssessments(
    patientsWithSuspicion,
    assessmentsArray
  ) {
    const patientsWithAssessments = this.patientsWithAssessments(patientsWithSuspicion.map(patient => patient._source), assessmentsArray);
    return patientsWithSuspicion.filter((assessments) => {
      const {
        _source: { paciente_id }
      } = assessments;

      return patientsWithAssessments.every((suspicion) => {
        return suspicion.paciente_id != paciente_id
      });
    });

  }

  /**
   * Return patients whose assessments comply with specified filter param
   *
   * @param patients
   * @param assessments
   * @param status
   * @returns {*}
   */
  patientsWithAssessments(
    patients,
    assessments,
    status = { positiva: true, negativa: true }
  ) {
    const assessedPatients = [];

    if(!patients) return

    // eslint-disable-next-line
    assessments.some((assessment) => {
      const patient = patients.find((patient) => isEqual(patient.paciente_id, assessment.key));
      if (patient && assessment.key === patient.paciente_id) {
        const {
          pacientes_hits: {
            hits: { hits = [] },
          },
        } = assessment;

        const predictedInfections = this.extractPatientPredictedInfection(patient).sort();
        const assessedInfections = hits.map((hit) => hit._source.tipo_infeccao).sort();

        /**
         * Verifica se o arr2 está contido no arr1
         * @param _arr1
         * @param _arr2
         * @returns {boolean}
         */
        function arraysEqual(_arr1, _arr2) {
          return isEmpty(difference(_arr2, _arr1));
        }

        const allPredictionsAssessed = () => {
          if(!assessedInfections.includes('sem infecção') && !arraysEqual(assessedInfections, predictedInfections)) {
            return false;
          }

          return true;
        }

        if(!allPredictionsAssessed())
          return false;

        hits.forEach((hit) => {
          const {
            _source: { avaliacao_status, dt_infeccao },
            _source,
          } = hit;

          if (
            moment(dt_infeccao).isSame(patient.dt_infeccao) &&
            status[avaliacao_status] === true
          ) {
            const currentAssessmentIndex = assessedPatients.length - 1;

            if (
              assessedPatients.length &&
              assessedPatients[currentAssessmentIndex].paciente_id ===
                patient.paciente_id
            ) {
              assessedPatients[currentAssessmentIndex].assessments.push({
                ..._source,
              });
            } else {
              assessedPatients.push({
                ...patient,
                assessments: [
                  {
                    ..._source,
                  },
                ],
              });
            }
          }
        });
      } else return false;
    });
    return assessedPatients;
  }
}

export default new Dataset();
