import React from 'react';
import { IconButton, withStyles } from '@material-ui/core';
import { useDialogContext } from '../DialogContext';

export const DialogCloseButton = withStyles((theme) => ({
  root: {
    padding: 9,
    backgroundColor: theme.palette.neutral.gray.xxlt,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.neutral.gray.xlt,
    }
  },
}))(IconButton);
DialogCloseButton.defaultProps = {
  children: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.00001 6.17504L10.7123 2.46273L11.5373 3.28769L7.82496 7L11.5373 10.7123L10.7123 11.5373L7.00001 7.82496L3.2877 11.5373L2.46274 10.7123L6.17505 7L2.46274 3.28769L3.2877 2.46273L7.00001 6.17504Z"
        fill="#3E4247"
      />
    </svg>
  ),
};

export const SmallDialogCloseButton = withStyles((theme) => ({
  root: {
    padding: 3,
    backgroundColor: theme.palette.neutral.black['48'],
    '&:hover': {
      backgroundColor: theme.palette.neutral.black['72'],
    }
  },
}))(IconButton);
SmallDialogCloseButton.defaultProps = {
  children: (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.99978 5.29289L9.18176 2.11091L9.88887 2.81802L6.70689 6L9.88887 9.18198L9.18176 9.88909L5.99978 6.70711L2.8178 9.88909L2.11069 9.18198L5.29267 6L2.11069 2.81802L2.8178 2.11091L5.99978 5.29289Z"
        fill="white"
      />
    </svg>
  ),
};

export const DialogClose = (props) => {
  const { component : ButtonComponent = DialogCloseButton } = props;
  const { onClose } = useDialogContext();
  return <ButtonComponent onClick={onClose} />;
};
DialogClose.defaultProps = {
  component: DialogCloseButton,
}