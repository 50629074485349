import { createSlice } from '@reduxjs/toolkit';

export const patientsInitialState = {
  infections: [],
  page: 1,
  itemsPerPage: 10,
  name: '',
  probabilities: [],
  orderBy: '',
  evaluated: 'todas',
  todayAge: 0
};

const patientsPageSlice = createSlice({
  name: 'patientsPageSlice',
  initialState: patientsInitialState,
  reducers: {
    resetFilters: (state) => {
      Object.assign(state, {
        infections: patientsInitialState.infections,
        probabilities: patientsInitialState.probabilities,
        page: patientsInitialState.page,
      });
    },
    setInfections: (state, { payload }) => {
      if (payload?.length) {
        state.infections = payload;
      } else {
        state.infections = patientsInitialState.infections;
      }
      state.page = patientsInitialState.page;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setItemsPerPage: (state, { payload }) => {
      state.itemsPerPage = payload;
      state.page = patientsInitialState.page;
    },
    setName: (state, { payload }) => {
      state.name = payload;
      state.page = patientsInitialState.page;
    },
    setProbabilities: (state, { payload }) => {
      state.probabilities = payload;
      state.page = patientsInitialState.page;
    },
    setOrderBy: (state, { payload }) => {
      state.orderBy = payload;
    },
    setEvaluated: (state, { payload }) => {
      state.evaluated = payload;
    },
    resetFieldsCreate: (state) => {
      Object.assign(state, {
        name: patientsInitialState.name,
        todayAge: patientsInitialState.todayAge,
      });
    }
  },
});

export const {
  resetFilters,
  setInfections,
  setPage,
  setItemsPerPage,
  setName,
  setProbabilities,
  setOrderBy,
  setEvaluated,
  resetFieldsCreate
} = patientsPageSlice.actions;
export default patientsPageSlice.reducer;
