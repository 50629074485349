import React, { useMemo } from 'react';
import { useTheme } from '@material-ui/core';
import { useTimelineEntry } from '../../../slices';
import { SquareEntry } from '../SquareEntry';

export const IndicatorEntryLaudos = (props) => {
  const { indicator, date, onClick } = props;
  const items = useTimelineEntry({ indicator, date });
  const theme = useTheme();
  const hasCriteria = useMemo(
    () => items.some((item) => item.criterio === 'sim'),
    [items]
  );

  const color = hasCriteria
    ? theme.palette.timeline.probability.MEDIA
    : theme.palette.timeline.probability.BAIXA;

  return (
    <SquareEntry
      indicator={indicator}
      date={date}
      onClick={onClick}
      color={color}
    />
  );
};
