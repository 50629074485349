import React from 'react';
import { useProbabilityColor } from '../../../../../utils/hooks';
import { useTimelineEntry } from '../../../slices';
import { SquareEntry } from '../SquareEntry';

export const IndicatorEntrySuspeitas = (props) => {
  const { indicator, date, onClick } = props;
  const items = useTimelineEntry({ indicator, date });
  const probability = items?.[0]?.max_prob || 0
  const color = useProbabilityColor(probability)

  return (
    <SquareEntry
      indicator={indicator}
      date={date}
      onClick={onClick}
      color={color}
    />
  );
};
