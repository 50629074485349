import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setEvaluated } from '../slice';

export const useSetPatientsEvaluated = () => {
  const dispatch = useDispatch();

  const handleSetPatientsEvaluated = useCallback(
    (evaluated) => dispatch(setEvaluated(evaluated)),
    [dispatch]
  );

  return handleSetPatientsEvaluated;
};
