import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setAssessment } from '../slice';

export const useSetHistoryAssessment = () => {
  const dispatch = useDispatch();

  const handleSetHistoryAssessment = useCallback(
    (assessment) => dispatch(setAssessment(assessment)),
    [dispatch]
  );

  return handleSetHistoryAssessment;
};
