import { withStyles, Input } from "@material-ui/core";

export const BaseInput = withStyles(theme => ({
  root: {
    backgroundColor: ({ bgcolor }) => bgcolor ? bgcolor : theme.palette.neutral.gray.xxlt,
    border: ({ bgcolor }) => `2px solid ${bgcolor ? bgcolor : theme.palette.neutral.gray.xxlt}`,
    boxSizing: 'border-box',
    borderRadius: '8px',
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
    transition: '.2s border-color ease 0s',
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-error': {
      borderColor: theme.palette.timeline.feedback.danger,
    },
    '& .MuiInput-input': {
      padding: '10px 12px',
    },
    '& input::placeholder': {
      opacity: 1,
      color: theme.palette.neutral.black['48'],
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  }
}))(Input)
BaseInput.defaultProps = {
  disableUnderline: true,
}