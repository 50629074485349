import React from 'react';
import clsx from 'clsx';
import { Divider, withStyles } from '@material-ui/core';
import { Card } from '../../../../components/atoms/Card';
import { StatTitle } from './StatTitle';
import { StatValue } from './StatValue';

export const StatStackCard = withStyles(() => ({
  root: {
    display: 'flex',
    padding: 0,
    borderRadius: 5,
  },
}))(Card);
StatStackCard.defaultProps = {
  outlined: true,
  shadow: false,
};

export const StatStackItem = withStyles(() => ({
  root: {
    textAlign: 'center',
    flexGrow: 1,
    padding: '16px 10px',
  },
}))(({ classes, className, label, value }) => (
  <div className={clsx(classes.root, className)}>
    <StatTitle>{label}</StatTitle>
    <StatValue>{value}</StatValue>
  </div>
));

export const StatStack = ({ items = [] }) => {
  return (
    <StatStackCard>
      {items.map(({ label, value }, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Divider orientation="vertical" flexItem />}
          <StatStackItem label={label} value={value} />
        </React.Fragment>
      ))}
    </StatStackCard>
  );
};
