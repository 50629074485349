import { indicatorsEnum } from './indicators';

/**
 * Textos explicativo dos indicadores
 */
// prettier-ignore
export const indicatorsText = {
  [indicatorsEnum.suspeitas]: `Apresenta a janela de infecção suspeita. Ao clicar nos dias da janela é possível identificar a probabilidade de infecção para cada topografia.`,
  [indicatorsEnum.infeccoes]: `Apresenta a data provável da infecção suspeita com a probabilidade para cada topografia de infecção.`,
  [indicatorsEnum.encontros]: `Apresenta os textos de evoluções extraídas pela inteligência artificial. Estes textos não estão na íntegra, sendo apresentados apenas os trechos que contém os termos e critérios para a infecção identificada. Termos considerados importantes para os critérios de infecção estão destacados no texto. Ao clicar em “Saiba Mais” é possível acessar a evolução completa do dia.`,
  [indicatorsEnum.exames]: `Apresenta os exames microbiológicos e laboratoriais do paciente. Para exames microbiológicos é possível encontrar o dado do material coletado, microorganismo identificado e perfil de sensibilidade. Para leucócitos e PCR é possível acessar o gráfico dos dados, sendo apresentada marcação em vermelho para resultados fora do limite de normalidade.`,
  [indicatorsEnum.medicamentos]: `Apresenta os antimicrobianos em uso dia a dia  pelo paciente na internação.`,
  [indicatorsEnum["sinais-vitais"]]: `Apresenta todos os sinais vitais do paciente coletados no dia. Todos os sinais vitais possuem gráficos com apresentação dos limites de normalidade. Sinais vitais alterados são apresentados em vermelho no gráfico.`,
  [indicatorsEnum.procedimentos]: `Apresenta as descrições dos procedimentos cirúrgicos e ambulatoriais realizados pelo paciente durante a internação. Termos considerados importantes para os critérios de infecção estão destacados no texto.`,
  [indicatorsEnum.laudos]: `Apresenta os laudos dos exames de imagem realizados pelo paciente durante a internação. Termos considerados importantes para os critérios de infecção estão destacados no texto.`
}
