import React, { useRef, useState } from 'react';
import {
  TextField as MuiTextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Clear, SearchOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

const TextField = withStyles((theme) => ({
  root: {
    width: 316,

    '& .MuiFilledInput-root': {
      borderRadius: '6px',
      backgroundColor: theme.palette.neutral.gray.xxlt2,
      color: theme.palette.neutral.black[48],
    },
    '& .MuiFilledInput-input': {
      paddingTop: '12px',
      paddingBottom: '13px',
      fontSize: theme.MuiTypography.sizes.xxs,
      '&::-webkit-search-decoration,&::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration':
        {
          display: 'none',
        },
    },
    '& .MuiFilledInput-input::-webkit-clear-button': {
      display: 'none',
    },
    '& .MuiFilledInput-input::placeholder': {
      opacity: 1,
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)':
      {
        marginTop: 0,
      },
  },
}))(MuiTextField);

export const DataTableSearch = ({ onSearch }) => {
  const ref = useRef();
  const [text, setText] = useState('');
  return (
    <TextField
      inputRef={ref}
      id="search"
      variant="filled"
      type="search"
      placeholder="Buscar paciente"
      value={text}
      onChange={(e) => setText(e.target.value)}
      onKeyPress={(e) => e.key === 'Enter' && onSearch(text)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        ),
        endAdornment: !!text && (
          <IconButton
            size="small"
            onClick={() => {
              setText('');
              onSearch('');
            }}
          >
            <Clear />
          </IconButton>
        ),
        disableUnderline: true,
      }}
    />
  );
};
