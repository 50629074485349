import styled from 'styled-components';
// import Container from "@material-ui/core/Container";

export const PacientesTemplate = styled.div`
  padding-top: 20px;
`;
export const FilterGroup = styled.div`
  padding-top: 20px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 94px;
  padding-right: 30px;
`;
