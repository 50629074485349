import React, { useCallback } from 'react';
import { Chip } from '../../../../components/atoms/Chip';
import { useSetTimelineCursor } from '../../slices';

export const IndicatorTermsList = ({ date, indicator, terms, onClick }) => {
  const setTimelineCursor = useSetTimelineCursor();
  const handleTermClick = useCallback(
    (term) => {
      setTimelineCursor({ date, indicator });
      if (onClick) onClick(term);
    },
    [date, indicator, onClick]
  );

  return !terms?.length ? (
    <>Nenhum termo encontrado</>
  ) : (
    <>
      {terms.map((term, termIndex) => (
        <Chip
          key={`${term.keyword}-${termIndex}`}
          label={`${term.keyword} (${term.count})`}
          onClick={() => handleTermClick(term.keyword)}
          variant={term.variant}
          size="large"
          style={{
            marginRight: '2px',
            marginBottom: '4px',
          }}
        />
      ))}
    </>
  );
};
