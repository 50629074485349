import { sendRequest } from '../api';
import assessedInfectionsRequestBody from './query';
import {keycloakConnect} from "../../../keycloak";

const ENDPOINT = `avaliacoes/_search?filter_path=aggregations.terms.buckets.key,
aggregations.terms.buckets.pacientes_hits.hits.hits._source&scroll=1m`;

export async function getAssessedInfections(filters) {
  const token = keycloakConnect.token
  return await sendRequest({
    endpoint: ENDPOINT,
    data: assessedInfectionsRequestBody(filters),
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
}
