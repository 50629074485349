import React, { useMemo } from 'react';
import { Checkbox, useFormControl } from '@material-ui/core';
import { FormControlLabel } from './styles';
import { CheckIconDefault, CheckIconChecked } from './CheckboxIcons';

export const CheckboxOption = (props) => {
  const formControl = useFormControl();
  const formControlDisabled =
    typeof formControl.disabled !== 'undefined' && formControl.disabled;
  const disabled = formControlDisabled || props.disabled;

  const control = useMemo(
    () => (
      <Checkbox
        color="primary"
        icon={<CheckIconDefault />}
        checkedIcon={<CheckIconChecked />}
      />
    ),
    [disabled]
  );

  return <FormControlLabel control={control} {...props} disabled={disabled} />;
};
