import React from 'react';
import PropTypes from 'prop-types';
import reactStringReplace from 'react-string-replace';
import { Typography } from '@material-ui/core';
import { keywords as defaultKeywords } from '../../atoms/TimelineChart/Tooltips/constants';

const TextHighlight = (props) => {
  const { children, keywords, color, ...typographyProps } = props;
  const regexKeywords = new RegExp(`(${keywords.filter(item => item).join('|')})`, 'gi');

  const contentWithMarkup = reactStringReplace(children, regexKeywords, (match, matchIndex) => (
    <span key={`text.highlight.${matchIndex}`} style={{backgroundColor: color}}>
      {match}
    </span>
  ));

  return (
    <Typography display="block" as="p" {...typographyProps}>
      {contentWithMarkup}
    </Typography>
  );
};

TextHighlight.propTypes = {
  children: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
};

TextHighlight.defaultProps = {
  children: '',
  keywords: defaultKeywords,
  color: 'yellow',
};

export default TextHighlight;
