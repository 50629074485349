import styled from 'styled-components'

export const Header = styled.header`

`
export const Title = styled.h2`
  display: inline-block;
  margin-right: 8px;
    @media (max-width: 960px) {
            margin-bottom: 20px;
        }
`
export const Pacientes = styled.div`
    width: 100%;
    max-width: 100%;
    padding-left: 109px;
`
