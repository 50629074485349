import { MenuItem as MuiMenuItem, Menu as MuiMenu, withStyles } from '@material-ui/core'

export const Menu = withStyles(theme => ({
  paper: {
    borderRadius: '8px',
  },
  border: '0px !important'
}))(MuiMenu);

export const MenuItem = withStyles(theme => ({
  root: {
    padding: '12px',
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
  }
}))(MuiMenuItem)