import React from 'react';
import { Grid } from '@material-ui/core';
import { DashboardPanel } from '../../DashboardLayout';
import { PatientsBySex } from './PatientsBySex';
import { PatientsByAge } from './PatientsByAge';
import { PatientsByLocal } from './PatientsByLocal';

export const DashboardPatientCharts = ({ indicators }) => {
  if(!indicators) {
    return ''
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={3}>
        <DashboardPanel title="Pacientes por sexo">
          <PatientsBySex indicators={indicators} />
        </DashboardPanel>
      </Grid>
      <Grid item sm={6}>
        <DashboardPanel title="Pacientes por faixa etária">
          <PatientsByAge indicators={indicators} />
        </DashboardPanel>
      </Grid>
      <Grid item sm={3}>
        <DashboardPanel title="Pacientes por local">
          <PatientsByLocal indicators={indicators} />
        </DashboardPanel>
      </Grid>
    </Grid>
  );
};
