import React from "react";
import {HorizontalChipsError, MyChip} from "./style";
import Close from '@material-ui/icons/Close';
import DoneAll from '@material-ui/icons/DoneAll';
import {string} from "prop-types";

const ChipsError = (props) => {
  const title = props.title || '';
  return (
    <HorizontalChipsError>
      <MyChip
        label={title}
        variant="outlined"
        className="error"
        icon={props.community ? <DoneAll/> : <Close/>}
      />
    </HorizontalChipsError>
  )
}

ChipsError.propTypes = {
  title: string.isRequired,
};

export default ChipsError;
