import React, { useCallback } from 'react';
import { Chip } from '../../../components/atoms/Chip';
import { infectionTypesList } from '../../../constants/infections';
import { infectionTypesLabels } from '../../../utils/formatters';

export const formatToPercentageString = (value = 0, decimals) =>
  parseFloat(value * 100)
    .toFixed(decimals)
    .replace('.', ',') + '%';

export const getPatientInfections = (patient) => {
  /* Filtra infecções que devem ser exibidas */
  const infections = Object.keys(patient)
    .filter(
      (key) => infectionTypesList.includes(key) && patient[`pred_${key}`] === 1
    )
    .map((key) => {
      return {
        label: infectionTypesLabels[key],
        value: patient[key],
        percentual: formatToPercentageString(patient[key], 1),
      };
    });
  return infections;
};
