import moment from 'moment';
import { actionTypes } from '../actions/PendingAssessmentsFiltersActions';

const initialState = {
  initialDate: moment().subtract(3, 'months'),
  finalDate: moment(),
  highProbability: true,
  mediumProbability: true,
  lowProbability: true,
  onlyInconclusive: false,
  orderByField: 'dt_infeccao',
  infections: []
};

export const pendingAssessmentsFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PENDING_ASSESSMENTS_INITIAL_DATE:
      return {
        ...state,
        initialDate: action.initialDate,
      };
    case actionTypes.SET_PENDING_ASSESSMENTS_FINAL_DATE:
      return {
        ...state,
        finalDate: action.finalDate
      };
    case actionTypes.SET_PROBABILITY_FILTER:
      return {
        ...state,
        highProbability: action.high,
        mediumProbability: action.medium,
        lowProbability: action.low
      };
    case actionTypes.SET_ONLY_INCONCLUSIVE_FILTER:
      return {
        ...state,
        onlyInconclusive: action.onlyInconclusive
      };
    case actionTypes.SET_PENDING_ASSESSMENTS_ORDERBY_FILTER:
      return {
        ...state,
        orderByField: action.orderByField
      };
    case actionTypes.SET_PENDING_ASSESSMENTS_INFECTION_FILTER:
      return {
        ...state,
        infections: action.infections
      };
    case actionTypes.RESET_PENDING_ASSESSMENTS_FILTERS:
      return {
        ...state,
        initialDate: moment().startOf('year'),
        finalDate: moment(),
        highProbability: true,
        mediumProbability: true,
        lowProbability: true
      };
    default:
      return state;
  }
};
