import React, { useEffect } from 'react';
import { Box, Divider } from '@material-ui/core';

import { useLastMonthIndicatorItems } from '../../hooks/useLastMonthIndicatorItems';
import { useGroupIndicatorByDate } from '../../hooks/useGroupIndicatorByDate';
import { IndicatorDateCard } from '../../IndicatorDateCard';
import { TermsLaudos } from './TermsLaudos';

export const PopoverContentLaudos = ({ indicator }) => {
  const items = useLastMonthIndicatorItems(indicator);
  const dates = useGroupIndicatorByDate(items);

  return (
    <>
      {dates.map(({ date, momentDate, items }, cardIndex) => (
        <IndicatorDateCard
          key={date}
          style={{
            marginTop: cardIndex !== 0 ? '8px' : 0
          }}
          date={date}
          momentDate={momentDate}
          indicator={indicator}
        >
          {items
            .reduce((acc, item) => {
              if (!acc.length) {
                acc.push(Object.assign({}, item));
              } else {
                acc[0].laudo_texto += item.laudo_texto;
              }
              return acc;
            }, [])
            .map((item, itemIndex) => (
              <React.Fragment key={`${date}-${itemIndex}`}>
                <TermsLaudos item={item} />
                {itemIndex !== 0 && <Divider />}
              </React.Fragment>
            ))}
        </IndicatorDateCard>
      ))}
    </>
  );
};
