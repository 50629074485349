import React, { useCallback } from 'react';
import { IconButton, Icon } from '@material-ui/core';
import { useDropMenuContext } from './DropMenuProvider';

export const DropMenuIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.5 7.5C3.675 7.5 3 8.175 3 9C3 9.825 3.675 10.5 4.5 10.5C5.325 10.5 6 9.825 6 9C6 8.175 5.325 7.5 4.5 7.5ZM13.5 7.5C12.675 7.5 12 8.175 12 9C12 9.825 12.675 10.5 13.5 10.5C14.325 10.5 15 9.825 15 9C15 8.175 14.325 7.5 13.5 7.5ZM7.5 9C7.5 8.175 8.175 7.5 9 7.5C9.825 7.5 10.5 8.175 10.5 9C10.5 9.825 9.825 10.5 9 10.5C8.175 10.5 7.5 9.825 7.5 9Z"
      fill="#3E4247"
      fill-opacity="0.72"
    />
  </svg>
);

export const DropMenuButton = (props) => {
  const { icon: IconComponent = DropMenuIcon, ...iconButtonProps } = props;
  const { ref, handleOpen } = useDropMenuContext();
  const { component: ButtonComponent = IconButton, onClick } = iconButtonProps;

  const handleClick = useCallback(
    (e) => {
      handleOpen();
      if (onClick) onClick(e);
    },
    [handleOpen, onClick]
  );

  return (
    <ButtonComponent ref={ref} {...iconButtonProps} onClick={handleClick}>
      <Icon component={IconComponent} />
    </ButtonComponent>
  );
};
