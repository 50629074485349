
import styled from 'styled-components'
import {Box} from '@material-ui/core'
export const Container = styled(Box)`
    max-width: 830px;
    margin: auto;
    padding-left: 100px;
    padding-right: 20px;
    color: #3E4247;
    margin-bottom: 50px;
    h1,h2,h3,h4,h5 { 
        margin: 16px 0;
    }
    h1 {
        font-size: 36px;
    }
    p {
        margin: 16px 0;
    }
    ul {
        padding: 24px 40px;
        background: #F7F7F7;
        border-radius: 12px; 
    }
    li {
        padding: 3px 0;
    }
`
export const Title = styled.h2`
    @media (max-width: 960px) {
        margin-bottom: 20px;
    }
`
