import { getInfectionTypes } from '../services/api/infection/infection.service';

export const actionTypes = {
  GET_INFECTION_TYPES: 'GET_INFECTION_TYPES',
  GET_INFECTION_TYPES_ERROR: 'GET_INFECTION_TYPES_ERROR'
};

/**
 * InfectionTypes request success action creator
 *
 * @param infectionTypes
 * @returns {{infectionTypes: *, type: string}}
 */
const infectionTypesAction = infectionTypes => ({
  type: actionTypes.GET_INFECTION_TYPES,
  infectionTypes
});

/**
 * InfectionTypes request error action creator
 *
 * @returns {{type: string}}
 */
const infectionTypeError = () => ({
  type: actionTypes.GET_INFECTION_TYPES_ERROR,
});

export const infectionTypes = () => async dispatch => {
  try {
    const infectionTypes = await getInfectionTypes();
    dispatch(infectionTypesAction(infectionTypes));

  } catch(error) {
    dispatch(infectionTypeError());
  }
};
