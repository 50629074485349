import { useState, useCallback } from 'react';

export const useExamsHistogram = () => {
  const [cursor, setCursor] = useState(false);

  const handleClose = useCallback(() => setCursor(false), [setCursor]);
  const handleOpen = useCallback((item) => setCursor(item), [setCursor]);

  return { cursor, handleClose, handleOpen };
};
