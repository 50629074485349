import styled from 'styled-components';

export const SettingsTemplate = styled.div`
  padding-top: 20px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 94px;
  padding-right: 30px;
`;
