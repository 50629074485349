import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setName } from '../slice';

export const useSetPatientsName = () => {
  const dispatch = useDispatch();

  const handleSetPatientsName = useCallback(
    (name) => dispatch(setName(name)),
    [dispatch]
  );

  return handleSetPatientsName;
};
