
export const patientRequestBody = (paciente_id) => ({
    size: 11360,
    from: 0,
    query: {
      bool: {
        minimum_should_match: 1,
        should: [
          {
            bool: {
              must: {
                term: { id: paciente_id },
              },
            },
          },
        ],
      },
    },
  });



  