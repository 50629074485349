import {
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormHelperText as MuiFormHelperText,
  withStyles,
} from '@material-ui/core';

export const FormControl = withStyles((theme) => ({
  root: {
    opacity: ({ disabled }) => (disabled ? 0.75 : 1),
    '& label + .MuiInput-formControl': {
      marginTop: '8px',
    },
  },
  marginNormal: {
    marginTop: 0,
    marginBottom: '8px',
  },
  marginDense: {
    marginTop: 0,
    marginBottom: '4px',
  },
}))(MuiFormControl);

export const InputLabel = withStyles((theme) => ({
  root: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '19px',
    transform: 'scale(1)',
    position: 'relative',
    color: theme.palette.neutral.black['72'],
    '&.Mui-disabled': {
      color: theme.palette.neutral.black['48'],
    },
    '&.Mui-error': {
      color: theme.palette.timeline.feedback.danger,
    },
  },
}))(MuiInputLabel);

export const FormHelperText = withStyles((theme) => ({
  root: {
    fontFamily: 'Nunito',
    '&.Mui-error': {
      color: theme.palette.timeline.feedback.danger,
    },
  },
}))(MuiFormHelperText);
