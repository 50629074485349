import React from 'react';
import { Box, Dialog as MuiDialog } from '@material-ui/core';
import { DialogProvider } from './DialogContext';
import { DialogPanel, DialogHeader } from './DialogPanel';

export const Dialog = (props) => {
  const {
    loading,
    open,
    onClose,
    children,
    title,
    header,
    footer,
    ...dialogProps
  } = props;

  return (
    <DialogProvider open={open} onClose={onClose}>
      <MuiDialog open={open} onClose={onClose} {...dialogProps}>
          <DialogPanel
            loading={loading}
            header={
              <>
                {typeof title === 'string' ? (
                  <DialogHeader title={title} />
                ) : (
                  title || header
                )}
              </>
            }
            footer={footer}
          >
            <Box padding="0 15px">
              {children}
            </Box>
          </DialogPanel>
      </MuiDialog>
    </DialogProvider>
  );
};
Dialog.defaultProps = {
  fullWidth: true,
  maxWidth: 'xs',
};
