import React from 'react';
import { ENTRY_X_CENTER, ENTRY_Y_CENTER } from '../../constants';
import { useLabelStroke } from './useLabelStroke';

export const SquareLabel = (props) => {
  const { label, background } = props;
  const stroke = useLabelStroke(background);

  return (
    <g
      transform={`translate(${ENTRY_X_CENTER} ${ENTRY_Y_CENTER})`}
      style={{ pointerEvents: 'none' }}
    >
      <text
        fontSize="12px"
        fontFamily="DM Mono"
        stroke={stroke}
        opacity={0.72}
        textAnchor="middle"
        x={0}
        y={3}
      >
        {label}
      </text>
    </g>
  );
};
