import React from 'react';
import {withStyles, withTheme} from "@material-ui/core/styles";
import PlatformVersionTypography from '../../atoms/PlatformVersionText';
import Box from "@material-ui/core/Box";

const VersionBox = withStyles(() => ({
  root: {
    backgroundColor: '#3b847c',
    padding: '6px 8px 6px 16px'
  }
}))(Box);

const PlatformVersion = () => {
  return (
    <>
    <VersionBox>
      <PlatformVersionTypography />
    </VersionBox>
    </>
  );
}

export default withTheme(PlatformVersion);
