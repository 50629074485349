import React, { useMemo, useEffect } from 'react';
import { useTimelineCursor } from '../slices';
import { useTimelineChartContext } from './TimelineChartProvider';
import { SQUARE_WIDTH, SQUARE_HEIGHT, ENTRY_X_CENTER } from './constants';
import { useTimelineKeyboard } from '../useTimelineKeyboard';

export const TimelineCursor = () => {
  // Handlers para navegação pelo teclado
  useTimelineKeyboard();

  const cursor = useTimelineCursor();
  const {
    xScale,
    yScale,
    clampScrollX,
    scrollApi,
    viewBoxCenterX,
    viewBoxWidth,
  } = useTimelineChartContext();

  const cursorX = useMemo(
    () => (!cursor.date ? 0 : xScale(cursor.date.valueOf())),
    [xScale, cursor]
  );

  useEffect(() => {
    if (!cursor.indicator || !cursor.date) return;
    scrollApi.start({ cancel: true });
    const nextX = viewBoxCenterX - cursorX - ENTRY_X_CENTER;

    scrollApi.start({
      x: clampScrollX(nextX),
    });
  }, [cursor, cursorX, scrollApi, viewBoxCenterX, clampScrollX, viewBoxWidth]);

  const entryCursor = useMemo(() => {
    const strokeWidth = 2;
    const halfStrokeWidth = strokeWidth / 2;

    const x = cursorX + halfStrokeWidth;
    const y = yScale(cursor.indicator) + halfStrokeWidth;

    return (
      <>
        {cursor.indicator && cursor.date && (
          <g
            style={{
              transform: `translate(${x}px, ${y}px)`,
              transition: 'ease-in 90ms transform 0s',
            }}
          >
            <rect
              stroke="#3E4247"
              strokeOpacity="0.48"
              strokeWidth={strokeWidth}
              fill="transparent"
              width={SQUARE_WIDTH - strokeWidth}
              height={SQUARE_HEIGHT - strokeWidth}
              x={0}
              y={0}
            />
          </g>
        )}
      </>
    );
  }, [cursor, xScale, yScale]);

  return <g data-name="cursor">{entryCursor}</g>;
};
