import {
  instableServerError,
  invalidRequestError,
  defaultRequestError,
  abortedRequestError,
} from '../../constants/requests';

/**
 * Dado um objeto de erro do RTK Query,
 *
 * Retornará a mensagem de erro mais adequada para situações de erros de conexão ou cancelamento da requisição.
 *
 * @param {import('@reduxjs/toolkit/query').FetchBaseQueryError | import('@reduxjs/toolkit/query').SerializedError} errorResponse
 * @param {(errorResponse) => string} extractMessage Seletor do erro no corpo da resposta, caso existente.
 * @param {string} standardErrorMessage Mensagem padrão para erros não identificados pela resposta ou status da requisição
 */
export const formatErrorResponse = (
  errorResponse,
  standardErrorMessage = defaultRequestError,
  extractMessage = (r) => r?.data?.message
) => {
  try {
    // Houve resposta com descrição do erro
    const responseErrorMessage = extractMessage(errorResponse);
    if (responseErrorMessage) return responseErrorMessage;
  } catch (e) {}

  // Houve resposta com status de erro
  const serverErrorMessage =
    errorResponse?.status >= 500
      ? // Internal error/Gateway/etc
        instableServerError
      : // Bad Request/Not found/etc
      errorResponse?.status >= 400
      ? invalidRequestError
      : undefined;

  // O erro ocorreu pois o usuário cancelou a requisição
  const abortionErrorMessage =
    errorResponse?.name === 'AbortError' ? abortedRequestError : undefined;

  const errorMessage =
    abortionErrorMessage || serverErrorMessage || standardErrorMessage;

  return errorMessage;
};
