import { Typography, withStyles } from '@material-ui/core';

export const DrawerTitle = withStyles(() => ({
  root: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '27px',
  }
}))(Typography)