import { getPatientsWithPendingAssessments } from '../services/api/pending/pending.service';
import { getAssessedInfections } from '../services/api/common/assessed.service';

export const actionTypes = {
  GET_PENDING_ASSESSMENTS: 'GET_PENDING_ASSESSMENTS',
  GET_PENDING_ASSESSMENTS_ERROR: 'GET_PENDING_ASSESSMENTS_ERROR',
  SET_PENDING_ASSESSMENTS_LOADING: 'SET_PENDING_ASSESSMENTS_LOADING'
};

/**
 * PendingAssessments request success action creator
 *
 * @param suspiciousInfections
 * @param assessedInfections
 * @param onlyInconclusive
 * @returns {{infectionTypes: *, type: string}}
 */
const pendingAssessmentsSuccessAction = (
  suspiciousInfections,
  assessedInfections,
  onlyInconclusive
) => ({
  type: actionTypes.GET_PENDING_ASSESSMENTS,
  suspiciousInfections,
  assessedInfections,
  onlyInconclusive,
});

/**
 * Pending Assessments request error action creator
 *
 * @returns {{type: string}}
 */
const pendingAssessmentsErrorAction = () => ({
  type: actionTypes.GET_PENDING_ASSESSMENTS_ERROR,
});

const setLoadingAcion = () => ({
  type: actionTypes.SET_PENDING_ASSESSMENTS_LOADING,
});

export const getPendingAssessments = (pendingAssessmentsFilters) => async (
  dispatch
) => {
  try {
    const {
      initialDate,
      finalDate,
      onlyInconclusive,
    } = pendingAssessmentsFilters;

    dispatch(setLoadingAcion());

    const suspiciousInfections = await getPatientsWithPendingAssessments(
      pendingAssessmentsFilters
    );
    const assessedInfections = await getAssessedInfections({
      initialDate,
      finalDate,
    });

    dispatch(
      pendingAssessmentsSuccessAction(
        suspiciousInfections,
        assessedInfections,
        onlyInconclusive
      )
    );
  } catch (error) {
    console.log(error)
    dispatch(pendingAssessmentsErrorAction());
  }
};
