import { fromPairs } from 'lodash';

export const sortObjectByExternalWeight = (obj) => (weight, defaultWeight) => {
  return fromPairs(
    Object.entries(obj).sort(
      ([firstKey], [secondKey]) =>
        (weight[secondKey] || defaultWeight) -
        (weight[firstKey] || defaultWeight)
    )
  );
};
