export const rows = [
  {
    id: 'a64924c4be82e886723e80f1b6fb8e55',
    dtInfeccao: '2022-01-05T00:00:00.000Z',
    probPerc: 0.315421096372,
    maxProb: 0.315421096372,
    criterio: null,
    cdAtendimento: '6019796',
    paciente: {
      id: '281518',
      sex: 'M',
      birthDate: '1929-09-09T00:00:00.000Z',
      todayAge: 92,
      name: 'AVELINO',
    },
    paciente_id: '281518',
    dtInicio: '2022-01-02T00:00:00.000Z',
    dtFim: '2022-01-09T00:00:00.000Z',
    pred_pnm: 0,
    pnm: 0.33,
    pred_traqueo: 1,
    traqueo: 0.4,
    pred_pav: 0,
    pav: 0.31,
    pred_itu: 0,
    itu: 0.33,
    pred_isc: 0,
    isc: 0.3,
    pred_ipcs: 0,
    ipcs: 0.36,
    avaliado: false,
    outraInfeccao: null,
    data_avaliacao: null,
    responsavel_avaliacao: null,
    probGrupoIsa: 'baixa',
  },
  {
    id: '0f87dcb272c3454bdc2a175fdd37b002',
    dtInfeccao: '2022-01-05T00:00:00.000Z',
    probPerc: 0.448229377451,
    maxProb: 0.448229377451,
    criterio: null,
    cdAtendimento: '6015418',
    paciente: {
      id: '96326',
      sex: 'F',
      birthDate: '1941-05-26T00:00:00.000Z',
      todayAge: 80,
      name: 'CARMELINDA',
    },
    paciente_id: '96326',
    dtInicio: '2021-12-30T00:00:00.000Z',
    dtFim: '2022-01-06T00:00:00.000Z',
    pred_pnm: 0,
    pnm: 0.49,
    pred_traqueo: 1,
    traqueo: 0.52,
    pred_pav: 0,
    pav: 0.32,
    pred_itu: 0,
    itu: 0.35,
    pred_isc: 0,
    isc: 0.3,
    pred_ipcs: 0,
    ipcs: 0.36,
    avaliado: false,
    outraInfeccao: null,
    data_avaliacao: null,
    responsavel_avaliacao: null,
    probGrupoIsa: 'baixa',
  },
  {
    id: 'f0e6cea9660e70397c1df81f80b6dc05',
    dtInfeccao: '2022-01-05T00:00:00.000Z',
    probPerc: 0.316889829012,
    maxProb: 0.316889829012,
    criterio: null,
    cdAtendimento: '6017104',
    paciente: {
      id: '33679',
      sex: 'F',
      birthDate: '1946-02-10T00:00:00.000Z',
      todayAge: 75,
      name: 'CLEMENTINA',
    },
    paciente_id: '33679',
    dtInicio: '2021-12-27T00:00:00.000Z',
    dtFim: '2022-01-03T00:00:00.000Z',
    pred_pnm: 0,
    pnm: 0.38,
    pred_traqueo: 1,
    traqueo: 0.38,
    pred_pav: 0,
    pav: 0.32,
    pred_itu: 0,
    itu: 0.37,
    pred_isc: 0,
    isc: 0.3,
    pred_ipcs: 0,
    ipcs: 0.36,
    avaliado: true,
    outraInfeccao: '',
    data_avaliacao: '2022-01-12T17:51:57.122-03:00',
    responsavel_avaliacao: 'admin',
    probGrupoIsa: 'baixa',
  },
  {
    id: '6f9ed6a1b678acc2d29ddb91a31fb40f',
    dtInfeccao: '2022-01-05T00:00:00.000Z',
    probPerc: 0.644113833167,
    maxProb: 0.644113833167,
    criterio: null,
    cdAtendimento: '5954651',
    paciente: {
      id: '30473',
      sex: 'M',
      birthDate: '1951-05-11T00:00:00.000Z',
      todayAge: 70,
      name: 'JOSE',
    },
    paciente_id: '30473',
    dtInicio: '2021-12-12T00:00:00.000Z',
    dtFim: '2021-12-19T00:00:00.000Z',
    pred_pnm: 0,
    pnm: 0.38,
    pred_traqueo: 1,
    traqueo: 0.86,
    pred_pav: 1,
    pav: 0.91,
    pred_itu: 0,
    itu: 0.44,
    pred_isc: 0,
    isc: 0.35,
    pred_ipcs: 0,
    ipcs: 0.36,
    avaliado: true,
    outraInfeccao: '',
    data_avaliacao: '2022-01-18T11:42:02.742-03:00',
    responsavel_avaliacao: 'admin',
    probGrupoIsa: 'media',
  },
  {
    id: 'd9261bc574d51d12e3da8fe1f4180978',
    dtInfeccao: '2022-01-05T00:00:00.000Z',
    probPerc: 0.304677713785,
    maxProb: 0.304677713785,
    criterio: null,
    cdAtendimento: '6018486',
    paciente: {
      id: '159637',
      sex: 'F',
      birthDate: '1986-04-03T00:00:00.000Z',
      todayAge: 35,
      name: 'LUCIANE',
    },
    paciente_id: '159637',
    dtInicio: '2021-12-27T00:00:00.000Z',
    dtFim: '2022-01-03T00:00:00.000Z',
    pred_pnm: 0,
    pnm: 0.32,
    pred_traqueo: 1,
    traqueo: 0.38,
    pred_pav: 0,
    pav: 0.31,
    pred_itu: 0,
    itu: 0.33,
    pred_isc: 0,
    isc: 0.28,
    pred_ipcs: 0,
    ipcs: 0.36,
    avaliado: true,
    outraInfeccao: '',
    data_avaliacao: '2022-02-14T15:22:11.436-03:00',
    responsavel_avaliacao: 'admin',
    probGrupoIsa: 'baixa',
  },
  {
    id: 'd391f1ef7cf4c8ca3e2d6e317f4fc259',
    dtInfeccao: '2022-01-05T00:00:00.000Z',
    probPerc: 0.300613636787,
    maxProb: 0.300613636787,
    criterio: null,
    cdAtendimento: '6015102',
    paciente: {
      id: '87461',
      sex: 'M',
      birthDate: '1985-07-19T00:00:00.000Z',
      todayAge: 36,
      name: 'Não Identificado',
    },
    paciente_id: '87461',
    dtInicio: '2022-01-02T00:00:00.000Z',
    dtFim: '2022-01-09T00:00:00.000Z',
    pred_pnm: 0,
    pnm: 0.32,
    pred_traqueo: 1,
    traqueo: 0.36,
    pred_pav: 0,
    pav: 0.31,
    pred_itu: 0,
    itu: 0.33,
    pred_isc: 0,
    isc: 0.28,
    pred_ipcs: 0,
    ipcs: 0.36,
    avaliado: false,
    outraInfeccao: null,
    data_avaliacao: null,
    responsavel_avaliacao: null,
    probGrupoIsa: 'baixa',
  },
  {
    id: 'a2d3fe882a88d36786e4a3995990eced',
    dtInfeccao: '2022-01-05T00:00:00.000Z',
    probPerc: 0.304700278574,
    maxProb: 0.304700278574,
    criterio: null,
    cdAtendimento: '6022145',
    paciente: {
      id: '329141',
      sex: 'F',
      birthDate: '1941-05-16T00:00:00.000Z',
      todayAge: 80,
      name: 'Não Identificado',
    },
    paciente_id: '329141',
    dtInicio: '2022-01-02T00:00:00.000Z',
    dtFim: '2022-01-09T00:00:00.000Z',
    pred_pnm: 0,
    pnm: 0.36,
    pred_traqueo: 1,
    traqueo: 0.36,
    pred_pav: 0,
    pav: 0.31,
    pred_itu: 0,
    itu: 0.33,
    pred_isc: 0,
    isc: 0.28,
    pred_ipcs: 0,
    ipcs: 0.36,
    avaliado: false,
    outraInfeccao: null,
    data_avaliacao: null,
    responsavel_avaliacao: null,
    probGrupoIsa: 'baixa',
  },
  {
    id: '15581a292831622a1662f7f0ecedad9f',
    dtInfeccao: '2022-01-05T00:00:00.000Z',
    probPerc: 0.302757951571,
    maxProb: 0.302757951571,
    criterio: null,
    cdAtendimento: '6007771',
    paciente: {
      id: '461457',
      sex: 'F',
      birthDate: '1964-07-27T00:00:00.000Z',
      todayAge: 57,
      name: 'Não Identificado',
    },
    paciente_id: '461457',
    dtInicio: '2021-12-17T00:00:00.000Z',
    dtFim: '2021-12-24T00:00:00.000Z',
    pred_pnm: 0,
    pnm: 0.33,
    pred_traqueo: 1,
    traqueo: 0.39,
    pred_pav: 0,
    pav: 0.31,
    pred_itu: 0,
    itu: 0.35,
    pred_isc: 0,
    isc: 0.29,
    pred_ipcs: 0,
    ipcs: 0.36,
    avaliado: false,
    outraInfeccao: null,
    data_avaliacao: null,
    responsavel_avaliacao: null,
    probGrupoIsa: 'baixa',
  },
  {
    id: '43ce8637573a70c6546aaf3a4a61e028',
    dtInfeccao: '2022-01-05T00:00:00.000Z',
    probPerc: 0.311971429689,
    maxProb: 0.311971429689,
    criterio: null,
    cdAtendimento: '6021883',
    paciente: {
      id: '461881',
      sex: 'F',
      birthDate: '1997-01-11T00:00:00.000Z',
      todayAge: 24,
      name: 'Não Identificado',
    },
    paciente_id: '461881',
    dtInicio: '2021-12-31T00:00:00.000Z',
    dtFim: '2022-01-07T00:00:00.000Z',
    pred_pnm: 0,
    pnm: 0.33,
    pred_traqueo: 1,
    traqueo: 0.36,
    pred_pav: 0,
    pav: 0.3,
    pred_itu: 0,
    itu: 0.34,
    pred_isc: 0,
    isc: 0.29,
    pred_ipcs: 0,
    ipcs: 0.36,
    avaliado: false,
    outraInfeccao: null,
    data_avaliacao: null,
    responsavel_avaliacao: null,
    probGrupoIsa: 'baixa',
  },
  {
    id: '84f5519a795e2e0471d0c0d7a9ac0989',
    dtInfeccao: '2022-01-05T00:00:00.000Z',
    probPerc: 0.295468024324,
    maxProb: 0.295468024324,
    criterio: null,
    cdAtendimento: '6019189',
    paciente: {
      id: '95804',
      sex: 'F',
      birthDate: '1970-04-11T00:00:00.000Z',
      todayAge: 51,
      name: 'Não Identificado',
    },
    paciente_id: '95804',
    dtInicio: '2022-01-02T00:00:00.000Z',
    dtFim: '2022-01-09T00:00:00.000Z',
    pred_pnm: 0,
    pnm: 0.32,
    pred_traqueo: 1,
    traqueo: 0.36,
    pred_pav: 0,
    pav: 0.31,
    pred_itu: 0,
    itu: 0.33,
    pred_isc: 0,
    isc: 0.28,
    pred_ipcs: 0,
    ipcs: 0.36,
    avaliado: false,
    outraInfeccao: null,
    data_avaliacao: null,
    responsavel_avaliacao: null,
    probGrupoIsa: 'baixa',
  },
];
export const ROW_PER_PAGE_OPTIONS = [10, 20, 30, 50];
export const DEFAULT_PAGINATION = {
  itemsPerPage: 10,
  page: 1,
  pageCount: 10,
  totalCount: 10,
};
