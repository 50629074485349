import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

export const DonutsChartContainer = withStyles(() => ({
  root: {
    position: 'relative',

    '& svg': {
      display: 'block',
    },

    '& path.domain': {
      stroke: '#d5d6db',
    },

    '& .donutsChart': {
      cursor: 'pointer',
      transition: 'opacity ease .1s',
      '&:hover': {
        opacity: .8,
      }
    },

    '& .tick': {
      fontFamily: 'DM Mono',
      fontSize: '10px',
      color: '#383a3d',
      display: 'inline-block',
    },

    '& .legend-container': {
      textAlign: 'center',
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '100%',
      margin: '0 -4px'
    },

    '& .legend': {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '0 4px',
    },

    '& .bullet': {
      display: 'block',
      width: '14px',
      height: '14px',
      borderRadius: '4px',
      marginRight: '10px',
    },
  },
}))(
  React.forwardRef(function ForwardedDonutsChartContainer(props, ref) {
    const { classes, className, ...rest } = props;
    return (
      <div className={clsx(classes.root, className)} ref={ref} {...rest} />
    );
  })
);
