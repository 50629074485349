import { actionTypes } from '../actions/PendingAssessmentsActions';
import Dataset from '../services/Dataset';
import moment from 'moment';

const initialState = {
  loading: false,
  list: []
};

function appendPatientPills(patientsWithSuspicion, pillsList) {
  const patientsArray = [];
  const pills = pillsList.flatMap((pill) => {
    return {
      paciente_id: pill.key.paciente,
      pills: [...pill.by_index.buckets],
    };
  });

  patientsWithSuspicion.forEach((patient) => {
    const {
      _source: { paciente_id },
      _source,
    } = patient;

    const pillIndex = pills.findIndex(
      (pill) => pill.paciente_id === paciente_id
    );

    if (pillIndex >= 0) {
      patientsArray.push({
        ..._source,
        pills: [...pills[pillIndex].pills],
      });
    } else {
      patientsArray.push({
        ..._source,
        pills: [],
      });
    }
  });

  return patientsArray;
}

/**
 * Filter patients and returns only the ones who has inconclusive assessment
 * @param patientsWithSuspicion
 * @param assessmentArray
 * @returns {*}
 */
function filterPatientsWithInconclusiveAssessments(
  patientsWithSuspicion,
  assessmentArray
) {
  const inconclusiveAssessments = [];
  assessmentArray.forEach((assessment) => {
    const {
      key,
      pacientes_hits: {
        hits: { hits },
      },
    } = assessment;

    const inconclusiveAssessment = hits.find((hit) => {
      const { _source } = hit;

      return _source.avaliacao_status === 'inconclusiva';
    });

    if (inconclusiveAssessment) {
      inconclusiveAssessments.push({
        paciente_id: key,
        ...inconclusiveAssessment._source,
      });
    }
  });

  return patientsWithSuspicion.filter((patient) => {
    return inconclusiveAssessments.some((inconclusiveAssessment) => {
      const {
        _source: { paciente_id, dt_infeccao },
      } = patient;
      return (
        paciente_id === inconclusiveAssessment.paciente_id &&
        moment
          .utc(inconclusiveAssessment.dt_infeccao)
          .isSame(moment.utc(dt_infeccao))
      );
    });
  });
}

export const pendingAssessmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PENDING_ASSESSMENTS_LOADING:
      return {
        loading: true,
        list: [
          ...state.list
        ]
      };
    case actionTypes.GET_PENDING_ASSESSMENTS:
      const {
        suspiciousInfections: {
          responses: [
            {
              hits: { hits: patientsWithSuspicion = [] },
            },
            {
              aggregations: {
                my_buckets: { buckets: pillsList = [] },
              },
            },
          ],
        },
        onlyInconclusive,
      } = action;

      const {
        terms: { buckets: assessedArray = [] },
      } = action.assessedInfections.aggregations || {
        terms: { buckets: [] },
      };

      if (!onlyInconclusive) {
        const patientsWithoutAssessments = Dataset.filterPatientsWithPendingAssessments(
          patientsWithSuspicion,
          assessedArray
        );

        patientsWithSuspicion.length = 0;
        patientsWithSuspicion.push(...patientsWithoutAssessments);
      } else {
        const patientsWithInconclusiveAssessments = filterPatientsWithInconclusiveAssessments(
          patientsWithSuspicion,
          assessedArray
        );
        patientsWithSuspicion.length = 0;
        patientsWithSuspicion.push(...patientsWithInconclusiveAssessments);
      }

      const patientsArray = appendPatientPills(
        patientsWithSuspicion,
        pillsList
      );

      return {
        loading: false,
        list: [
          ...patientsArray
        ]
      };
    case actionTypes.GET_PENDING_ASSESSMENTS_ERROR:
      return {
        loading: false,
        list: [
          ...state.list
        ]
      };
    default:
      return state;
  }
};
