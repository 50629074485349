import { alpha, createTheme } from '@material-ui/core/styles';
import { probabilityLevelsEnum } from '../constants/probabilities';

export const isaTheme = createTheme({
  MuiTypography: {
    root: {
      fontFamily: ['Nunito', 'sans-serif'],
    },
    family: {
      main: ['Nunito', 'sans-serif'],
      secondary: ['DM Mono', 'monospace'],
    },
    sizes: {
      small: 10,
      xs: 11,
      xxs: 13,
      sm: 14,
    },
  },
  palette: {
    primary: {
      main: '#16B8A7',
      contrastText: '#FFF',
    },
    dark: {
      text: '#1C8C54',
      main: '#3F8F87',
      secondary: '#383A3D',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FF9430',
    },
    gray: {
      main: '#F5F5F5',
      50: '#F7F7F7',
    },
    high: {
      main: '#FD333F',
    },
    white: {
      main: '#FFFFFF',
    },
    highlight: {
      main: '#fed8a6',
    },
    pill: {
      positiva: {
        text: '#1C8C54',
        main: '#26BF72',
        secondary: '#26BF7233',
        contrastText: '#FFF',
      },
      negativa: {
        text: '#FE3C4C',
        main: '#FE3C4C',
        secondary: '#FE3C4C1A',
        contrastText: '#FFF',
      },
      inconclusiva: {
        text: '#8F95A3',
        main: '#8F95A3',
        secondary: '#fff',
        contrastText: '#FFF',
      },
      default: {
        text: '#8F95A3',
        main: '#8F95A3',
        secondary: '#fff',
        contrastText: '#FFF',
      },
    },
    neutral: {
      black: {
        100: '#3E4247',
        72: alpha('#3E4247', 0.72),
        88: alpha('#3E4247', 0.88),
        48: alpha('#3E4247', 0.48),
        16: alpha('#3E4247', 0.16),
      },
      gray: {
        md: '#DEDEDE',
        xlt: '#F2F2F2',
        xxlt: '#F5F7F7',
        xxlt2: '#F7F7F7'
      },
    },
    timeline: {
      probability: {
        '000': '#FFDE67',
        [probabilityLevelsEnum.BAIXA]: '#FFDE67', // '000'
        100: '#FFC656',
        200: '#FFA756',
        [probabilityLevelsEnum.MEDIA]: '#FFA756', // '200'
        300: '#FB8E6C',
        400: '#FB6F6C',
        [probabilityLevelsEnum.ALTA]: '#FB6F6C', // '400'
        500: '#EE5855',
      },
      secondary: {
        purple: '#9D84E8',
        blue: '#74BBE2',
        green: '#2BAC76',
      },
      feedback: {
        success: '#3C9C0E',
        danger: '#EB2031',
      },
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        fontFamily: ['DM Mono', 'monospace'],
        fontWeight: 'normal',
      },
    },
    MuiButton: {
      root: {
        fontFamily: ['DM Mono', 'monospace'],
        fontWeight: 'normal',
        '&.buttonDialog': {
          background: '#16B8A7',
          borderRadius: 8,
          border: 0,
          color: 'white',
          height: 40,
          padding: '0 30px',
          boxShadow: 'none',
          fontSize: 14,
          textTransform: 'none',
          margin: '15px 0',
          marginRight: '15px',
          '&-clean': {
            background: 'none',
            color: '#16B8A7',
            padding: '0',
            '&:hover': {
              background: 'none',
            },
          },
        },
      },
      outlined: {
        borderRadius: 8,
        height: 40,
        padding: '0 30px',
        textTransform: 'none',
      },
      contained: {
        background: '#16B8A7',
        borderRadius: 8,
        border: 0,
        color: 'white',
        height: 40,
        padding: '0 30px',
        boxShadow: 'none',
        fontSize: 14,
        textTransform: 'none',
      },
    },
    MuiToggleButtonGroup: {
      root: {
        width: '100%',
        overflow: 'auto',
        borderRadius: '8px',
      },
    },
    MuiToggleButton: {
      root: {
        background: '#F5F5F5',
        width: '16.66%',
        minWidth: '70px',
        borderRadius: '8px',
        fontSize: 10,
        textAlign: 'center',
        letterSpacing: '0.08em',
        textTransform: 'uppercase',
        fontFamily: 'DM Mono',
        color: '#383A3D',
        border: 'none',
        padding: '13px 13px',
        '&.Mui-selected': {
          background: '#16B8A7',
          color: '#fff',
        },
        '&:hover': {
          background: '#16B8A7!important',
          color: '#fff',
        },
      },
    },

    MuiContainer: {
      root: {},
    },

    MuiIconButton: {
      root: {
        '&.closeButton': {
          width: '24px!important',
          height: '24px!important',
          background: '#F5F5F5!important',
          borderRadius: '4px!important',
          color: '#383A3D!important',
          padding: '0!important',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '0',
          '&> .MuiIconButton-label > .MuiSvgIcon-root': {
            fontSize: '18px!important',
            lineHeight: '18px!important',
          },
        },
      },
    },

    MuiDialogTitle: {
      root: {
        paddingTop: '24px',
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '21px',
        lineHeight: '29px',
        color: '#383A3D',
        '&> .MuiTypography-root': {
          position: 'relative',
          minHeight: '24px',
        },
      },
    },

    MuiDialogContent: {
      root: {
        minWidth: '568px',
        '&.MuiDialogContent-dividers': {
          borderTop: 'none',
        },
      },
    },

    MuiDialogActions: {
      root: {
        '.button': {
          root: {
            background: '#16B8A7',
            borderRadius: 8,
            border: 0,
            color: 'white',
            height: 40,
            padding: '0 30px',
            boxShadow: 'none',
            fontSize: 14,
            textTransform: 'none',
          },
        },
        '&.spaceBetween': {
          justifyContent: 'space-between',
          paddingLeft: '27px',
        },
      },
    },

    MuiTypography: {
      root: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
      },
      subtitle1: {
        fontSize: 12,
      },
      body1: {
        fontFamily: 'Nunito',
      },
      body2: {
        fontFamily: 'DM Mono',
        fontWeight: 400,
        letterSpacing: '0.08em',
        color: '#383A3D',
        fontSize: 10,
      },
      button: {
        fontStyle: 'italic',
      },
    },

    MuiFormControlLabel: {
      root: {
        '&.labelDialog': {
          justifyContent: 'space-between',
          width: '100%',
          marginLeft: '0!important',
          marginRight: '0!important',
          padding: '15px 0',
          '&.border': {
            borderBottom: '1px solid rgba(143, 149, 163, 0.32)',
          },
        },
      },
    },

    MuiButtonGroup: {
      root: {
        background: '#F5F5F5',
        boxShadow: 'none!important',
        '&> span': {
          borderColor: '#fff!important',
        },
      },
    },
  },
});
