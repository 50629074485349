import React, { useMemo } from 'react'
import { DrawerContext } from './DrawerContext';
import { DrawerBase } from './DrawerBase';

/**
 * 
 * @param {import('@material-ui/core').DrawerProps} props 
 * @returns 
 */
export const DrawerProvider = (props) => {
  const { open, onClose, children, ...drawerProps } = props;
  
  const contextState = useMemo(() => ({ open, onClose }), [open, onClose])

  return (
    <DrawerContext.Provider value={contextState}>
      <DrawerBase {...drawerProps}>
        {children}
      </DrawerBase>
    </DrawerContext.Provider>
  )
}
