import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Chip } from '../../../../../components/atoms/Chip';

export const ExamTitle = withStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  bullet: {
    flex: '0 0 auto',
    width: '8px',
    height: '8px',
    borderRadius: '100%',
    backgroundColor: theme.palette.timeline.probability[500],
    marginTop: '1em',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(.5),
  },
  titleContainer: {
    flex: '1 1 100%',
    padding: theme.spacing(1, 0),
  },
  title: {
    display: 'inline-block',
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: theme.palette.neutral.black['100'],
    marginRight: theme.spacing(0.5),
  },
  chip: {
    display: 'inline-block',
    marginTop: '4px'
  },
  action: {
    flex: '0 0 auto',
    marginLeft: '4px',
    marginRight: '4px',
  },
  // Size variants
  smallBullet: {},
  normalBullet: {},
  // Size variants
  smallTitle: {
    fontSize: '12px',
    lineHeight: '17px',
  },
  normalTitle: {
    fontSize: '13px',
    lineHeight: '18px',
  },
}))(function StyledExamTitle(props) {
  const {
    classes,
    className,
    positive,
    criteria,
    culture,
    title,
    action,
    size,
    ...divProps
  } = props;

  return (
    <div
      {...divProps}
      className={clsx(classes.root, classes[`${size}Title`], className)}
    >
      <div
        className={clsx(classes.bullet, classes[`${size}Bullet`])}
        style={{ opacity: criteria ? 1 : 0 }}
      />
      <div className={classes.titleContainer}>
        <div className={clsx(classes.title)}>{title}</div>
        {positive && (
          <Chip
            className={classes.chip}
            size="large"
            label="Positivo"
            variant={culture ? 'danger' : 'warning'}
          />
        )}
      </div>
      <div className={classes.action}>{action}</div>
    </div>
  );
});
ExamTitle.defaultProps = {
  size: 'normal',
};
