import React from 'react';
import { Box } from '@material-ui/core';
import { indicatorsEnum } from '../../../../constants/indicators';
import { PopoverContentSinaisVitais } from './PopoverContentSinaisVitais';
import { PopoverContentAvaliacoes } from './PopoverContentAvaliacoes';
import { PopoverContentEvolucao } from './PopoverContentEvolucao';
import { PopoverContentExames } from './PopoverContentExames';
import { PopoverContentAntimicrobianos } from './PopoverContentAntimicrobianos';
import { PopoverContentLaudos } from './PopoverContentLaudos';
import { PopoverContentProcedimentos } from './PopoverContentProcedimentos';

// Indicadores que tem conteúdos customizados por popover
const popoverContentComponentMap = {
  [indicatorsEnum['avaliacoes']]: PopoverContentAvaliacoes,
  [indicatorsEnum['encontros']]: PopoverContentEvolucao,
  [indicatorsEnum['sinais-vitais']]: PopoverContentSinaisVitais,
  [indicatorsEnum['exames']]: PopoverContentExames,
  [indicatorsEnum['medicamentos']]: PopoverContentAntimicrobianos,
  [indicatorsEnum['laudos']]: PopoverContentLaudos,
  [indicatorsEnum['procedimentos']]: PopoverContentProcedimentos,
};

export const PopoverContent = React.memo(function MemoPopoverContent({
  indicator,
}) {
  const ContentComponent = popoverContentComponentMap[indicator] || 'div';

  return (
    <Box>
      <ContentComponent indicator={indicator} />
    </Box>
  );
});
