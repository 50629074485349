import * as d3 from 'd3'
import {isEmpty, uniq} from "lodash";
import moment from "moment";

/**
 * Gráfico de barras
 */

// Estilos
let margin = {
  top: 20,
  bottom: 60,
  left: 40,
  right: 20
};

let height = 400 - margin.top - margin.bottom;
let width = 0;

export default class D3HistogramChart {

  // Containers
  svg = null;
  container = null;
  xAxisGroup = null;
  yAxisGroup = null;
  startRed = [0,1];
  safeLine = 0;
  // TEMPERATURE_START_RED = 38;
  // RESPIRATORY_FREQUENCY_START_RED = 20;
  // RESPIRATORY_FREQUENCY_SAFE_LINE = 20;
  // TEMPERATURE_SAFE_LINE= 36;
  //
  // PAS_START_RED = 100;
  // PAS_SAFE_LINE = 100;

  DIRECTION = 'up';
  PAS_DIRECTION = 'down';

  constructor(element, dataset, date) {
    if(isEmpty(dataset)) return
    this.container = element.current;
    this.dataset = this.sortDataSet(dataset);
    this.date = date;
    this.signalType = dataset[0]._source.tipo_sinal
  }

  sortDataSet(dataset) {
    if(isEmpty(dataset)) return
    return dataset.sort((a, b) => new Date(a.date) - new Date(b.date))
  }

  changeSizes() {
    if(isEmpty(this.dataset)) return
    d3.select(this.container).selectAll('svg').remove();
    this.setup();
    this.update();
  }

  setup() {
    if(isEmpty(this.dataset)) return
    width = this.container.getBoundingClientRect().width - margin.left - margin.right;

    this.svg = d3.select(this.container)
      .append('svg')
      .attr('width', '100%')
      .attr('height', height + margin.bottom + margin.top)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    this.xAxisGroup = this.svg.append('g')
      .attr('transform', `translate(0, ${height})`)

    this.yAxisGroup = this.svg.append('g')

    // Determina o valor para campo vermelho
    // TEMPERATURE_START_RED = 38;
    // RESPIRATORY_FREQUENCY_START_RED = 20;
    // RESPIRATORY_FREQUENCY_SAFE_LINE = 20;
    // TEMPERATURE_SAFE_LINE= 36;
    //
    // PAS_START_RED = 100;
    // PAS_SAFE_LINE = 100;
    switch(this.signalType) {
      case 'PROTEINA C REATIVA': 
        this.startRed = [0, 5];
        this.safeLine = 4.99;
        break;
      case 'LEUCÓCITOS':
        this.startRed = [4000, 12000];
        this.safeLine = -40000
        break;
      case 'TEMP':
        this.startRed = [35,37.9];
        this.safeLine = 36
        break;
      case 'FR':
        this.startRed = [-1,20];
        this.safeLine = 20;
        break;
      case 'PAS':
        this.startRed = [90,130];
        this.safeLine = (90+130)/2;
        break;
      case 'HGT':
        this.startRed = [70,99];
        this.safeLine = (70+99)/2;
        break;
      case 'PAM':
        this.startRed = [70,100];
        this.safeLine = (70+100)/2;
        break;
      case 'DIUR':
        this.startRed = [1,99999];
        this.safeLine = 0;
        break;
      case 'FC':
        this.startRed = [85,100];
        this.safeLine = 0;
        break;
      case 'OXIMETRIA':
        this.startRed = [95,9999];
        this.safeLine = 0;
        break;
      case 'PAD':
        this.startRed = [0,85];
        this.safeLine = 0;
        break;

      default:
        this.startRed = [0,999999]

     /*

      {
        short: 'PAD',
          fullName: 'PAD',
        unit: 'MMHG'
      },
      {
        short: 'DOR',
          fullName: 'DOR',
        unit: 'Valor'
      }*/
    }
  }

  update() {
    if(isEmpty(this.dataset)) return
    // EIXO X
    const xValues = uniq(this.dataset.map(data=>data.date.getTime()))
    var x = d3.scaleBand()
      .domain(xValues.reverse())
      .range([0, width]);

    var xAxisCall = d3.axisBottom(x)
      .tickSizeOuter(1)
      .ticks(xValues.length)
      .tickFormat(d3.timeFormat('%d/%m'))


    // d3.select('')

    this.xAxisGroup.call(xAxisCall)
    if(xValues.length > 12){
      this.xAxisGroup.selectAll("text")
        .attr("transform", "rotate(-45)")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
    }
    this.xAxisGroup.selectAll("text")
      .attr('class', d => {
        return d === this.date.getTime() ? 'text-highlight' : ''
      })


    const yMin = d3.min(this.dataset, d => {
      return parseFloat(d._source.valor);
    });

    const yMax = d3.max(this.dataset, d => {
      return parseFloat(d._source.valor)
    });

    // EIXO Y
    const y = d3.scaleLinear()
      .domain([yMin*0.85, yMax * 1.05])
      .range([height, 0]);

    const yAxisCall = d3.axisLeft(y)
      .tickSizeOuter(0);

    this.yAxisGroup.call(yAxisCall)
      .call(g => g.selectAll(".tick line")
        .remove());

    d3.selectAll(".tick").select('text').style('font-weight', d => d)

    this.svg
      .selectAll('.y-line')
      .data(yAxisCall.scale().ticks())
      .enter()
      .append('line')
      .attr('class', 'yline')
      .attr('x1', 1)
      .attr('y1',(d) => y(d))
      .attr('y2',(d) => y(d))
      .attr('x2',width)
      .attr('stroke', '#f3f3f3')

    this.svg
      .selectAll('.x-line')
      .data(xValues)
      .enter()
      .append('line')
      .attr('class', 'x-line')
      .attr('x1', (d) => x(d.valueOf())+Math.abs(x.bandwidth()/2))
      .attr('y1',0)
      .attr('y2',height)
      .attr('x2',(d) => x(d.valueOf()) + Math.abs(x.bandwidth()/2))
      .attr('stroke', '#f3f3f3')

    // this.svg
    //   .selectAll('.reference-line')
    //   .data(xValues)
    //   .enter()
    //   .append('line')
    //   .attr('class', 'reference-line')
    //   .attr('x1', 1)
    //   .attr('x2',width)
    //   .attr('y2',(d) => y(this.safeLine))
    //   .attr('y1',(d) => y(this.safeLine))
    //   .attr('stroke-dasharray',"4")
    //   .attr('stroke', '#3bb8a7')
    //   .attr('stroke-width', 1)

    this.svg
      .selectAll('rect')
      .data(xValues)
      .enter()
      .append('rect')

      .attr('x', (d) => (x(d.valueOf())+Math.abs(x.bandwidth()/2))-Math.abs(x.bandwidth()/8))
      .attr('y', (d) => {
        const values = this.dataset.filter((item)=> item.date.valueOf() === d)
          .map((item)=> parseFloat(item._source.valor.toString().replace(",",".")))
        return y(d3.max(values))
      })

      .attr('width', Math.abs(x.bandwidth()/4))
      .attr('height', (d) => {
        const values = this.dataset.filter((item)=> item.date.valueOf() === d)
          .map((item)=> parseFloat(item._source.valor.toString().replace(",",".")))
        const height = y(d3.min(values)) -y(d3.max(values));
        return height > 0 ? height : 5
      })
      .style('fill', d => {
        // Filtra registro do dia especificado em "d". Retorna a lista de todas as medições do dia
        const values = this.dataset.filter((item)=> item.date.valueOf() === d)
          .map((item)=> parseFloat(item._source.valor.toString().replace(",",".")))

        // Estilos do gradiente
        const grad = this.svg.append('defs')
          .append('linearGradient')
          .attr('id', 'grad-'+d)
          .attr("x1", "0%")
          .attr("x2", "0%")
          .attr("y1", "0%")
          .attr("spreadMethod", "pad")
          .attr("y2", "100%");

        // Maior valor do dia - o limite máximo
        const limitTop = d3.max(values) - this.startRed[1];
        const limitBottom = this.startRed[0]-d3.min(values);

        // Tamanho total (Amplitude)
        const height = d3.max(values) - d3.min(values);
        const percentual = 100 * limitTop/height;
        const percentualBottom = height > 0 ? 100 * limitBottom/height : 0;

        let colorBeforeLimit = "#FE3C4C"
        let colorAfterLimit = "#3bb8a7"
        if(this.DIRECTION === 'down'){
          colorBeforeLimit = '#3bb8a7';
          colorAfterLimit = '#FE3C4C';
        }

        // Verifica se o maior grau é maior que o limite
        if(limitTop > 0 || limitBottom > 0){
          // Adiciona o vermelho do gradiente
          grad.append("stop")
            .attr('class', 'start')
            .attr("offset", (height <= 0 ? 100 : percentual) + '%')
            .attr("stop-color", colorBeforeLimit)
            .attr("stop-opacity", 1);

        }
        // adiciona o verde 10% abaixo do vermelho
        grad.append("stop")
          .attr('class', 'start')
          .attr("offset", (height <= 0 ? 100 : percentual) + '%')
          .attr("stop-color", colorAfterLimit)
          .attr("stop-opacity", 1);

        grad.append("stop")
          .attr('class', 'start')
          .attr("offset", (limitBottom <= 0 ? 100 : 100-percentualBottom) + '%')
          .attr("stop-color", colorAfterLimit)
          .attr("stop-opacity", 1);
        grad.append("stop")
          .attr('class', 'start')
          .attr("offset", (limitBottom <= 0 ? 100 : 100-percentualBottom) + '%')
          .attr("stop-color", colorBeforeLimit)
          .attr("stop-opacity", 1);

        // finaliza com o verde
        grad.append("stop")
          .attr('class', 'end')
          .attr("offset", "100%")
          .attr("stop-color", (limitBottom <= 0 ? colorAfterLimit : colorBeforeLimit))
          .attr("stop-opacity", 1);

        return 'url(#grad-'+d+')';
      })
      .attr('stroke', 0)
      .on('mousemove', d => this.mousemove(d, 'aaa'))
      .on('mouseleave', d => this.mouseleave())
  }
  mouseleave() {
    d3.select('.tooltip').remove()
  }

  mousemove(d, titulo) {
    const values = this.dataset.filter((item)=> item.date.valueOf() === d)
      .map((item)=> parseFloat(item._source.valor.toString().replace(",",".")))
    d3.select(this.container).node().getBoundingClientRect();
    d3.select('.tooltip').remove()
    d3.select('#GraphDialog')
      .append('div')
      .attr('class', 'tooltip')
      .style("left", (d3.event.pageX + 10) + "px")
      .style("top", (d3.event.pageY + 10) + "px")
      .html(moment(d).format('DD/MM')+ '<br/>'+d3.min(values) + '  - ' + d3.max(values))
      .append('div')

  }
}
