import React from 'react';
import { Box, Grid, Fade, Divider } from '@material-ui/core';
import { IndicatorsTitle } from './IndicatorsTitle';
import {
  useGetPatientPillsQuery,
  useGetPatientFactsQuery,
} from '../../../services';

import { IndicatorItem } from './IndicatorItem';

import {
  useTimelineIndicators,
  useTimelineAssessments,
  useTimelinePatientId,
} from '../slices';
import { TimelineIndicatorsProvider } from './IndicatorsProvider';
import { TimelineIndicatorsSkeleton } from './TimelineIndicatorsSkeleton';

export const TimelineIndicatorsList = ({ loading }) => {
  const patientId = useTimelinePatientId();

  const {
    data: patientPills,
    isLoading: isLoadingPatientPills,
    isFetching: isFetchingPatientPills,
  } = useGetPatientPillsQuery(patientId, {
    skip: !patientId,
  });

  const {
    isLoading: isLoadingPatientFacts,
    isFetching: isFetchingPatientFacts,
  } = useGetPatientFactsQuery(patientId, {
    skip: !patientId,
  });

  const isLoading =
    loading ||
    isFetchingPatientFacts ||
    isLoadingPatientFacts ||
    isFetchingPatientPills ||
    isLoadingPatientPills;
  const isIndicatorsVisible = !isLoading && !!patientPills?.length;

  const indicators = useTimelineIndicators();
  const assessments = useTimelineAssessments();

  // useEffect(() => {
  //   // console.log('indicators changed', indicators);
  // }, [indicators]);

  return loading ? (
    <TimelineIndicatorsSkeleton />
  ) : (
    <Fade in={isIndicatorsVisible} mountOnEnter unmountOnExit>
      <Grid container ml="4px" spacing={1}>
        {!!assessments.length && (
          <IndicatorItem
            key="avaliacoes"
            indicator="avaliacoes"
            isHighlighted
          />
        )}
        {patientPills?.map(({ key: indicator }) => (
          <IndicatorItem key={indicator} indicator={indicator} />
        ))}
      </Grid>
    </Fade>
  );
};

export const TimelineIndicators = ({ loading }) => {
  return (
    <Box pt="16px">
      <Divider />
      <Box display="flex" alignItems="center" py="16px">
        <Box mr={1}>
          <IndicatorsTitle>Indicadores: </IndicatorsTitle>
        </Box>
        <TimelineIndicatorsList loading={loading} />
      </Box>
      <Divider />
    </Box>
  );
};
