import moment from 'moment';
import { string, mixed, object, boolean, array } from 'yup';
import {
  otherInfection,
  noInfection,
  infectionTypesEnum,
} from '../../../../constants/infections';

export const AssessmentAnalysisFormSchema = object()
  .shape({
    /**
     * Qual infecção deseja avaliar?
     * - Obrigatório
     */
    assessmentInfection: string().required('O tipo de infecção é obrigatório'),

    /**
     * Local da infecção
     * - Dados alimentados pelo Endpoint (unidades)
     * - Condicional: Se o valor “Qual infecção deseja avaliar?” for igual a “Não houve critério para infecção” este campo deve ser desativado e o valor definido como null.
     *
     * O local da infecção só é obrigatório caso um tipo de infecção seja identificado
     */
    otherInfection: string().when('assessmentInfection', {
      is: otherInfection,
      then: string().required('Informe a infecção.'),
      otherwise: string().notRequired(),
    }),

    /**
     * Local da infecção
     * - Dados alimentados pelo Endpoint (unidades)
     * - Condicional: Se o valor “Qual infecção deseja avaliar?” for igual a “Não houve critério para infecção” este campo deve ser desativado e o valor definido como null.
     *
     * O local da infecção só é obrigatório caso um tipo de infecção seja identificado
     * ou se a infecção selecionada for diferente de 'Infecção comunitária'
     */
    infectionLocation: string().when('assessmentInfection', {
      is: (assessmentInfection) =>
        assessmentInfection === 'comunitaria' ||
        assessmentInfection === 'sem infecção',
      then: string().notRequired(),
      otherwise: string().required('O local da infecção é obrigatório.'),
    }),

    /**
     * Período da infecção
     * - Dados alimentados pelo Endpoint (suspeitas)
     * - Obrigatório
     *
     * O período da infecção (Janela de suspeita) refere-se ao id de uma infecção naquele período de suspeita
     */
    //suspicionId: string().required('Selecione uma janela de suspeita'),

    /**
     * Data da infecção
     * - Escolher uma data menor ou igual a hoje
     * - Condicional: Se o valor “Qual infecção deseja avaliar?” for igual a “Não houve critério para infecção” este campo deve ser desativado e o valor deve ser null
     */
    assessmentDateInfection: mixed()
      .notRequired()
      .test('dateMax', 'Data máxima inválida', (momentDate) => {
        return !momentDate || momentDate.isBefore(moment());
      }),

    /**
     * Como você avalia a suspeita desta infecção?
     * - Valores:
     *    - Confirmada (positiva)
     *    - Descartada (negativa)
     *    - Inconclusiva (inconclusiva)
     * - Condicional: Se o valor “Qual infecção deseja avaliar?” for igual a “Não houve critério para infecção” este campo deve ser obrigatoriamente marcado como Descartada.
     */
    assessmentStatus: string().when('assessmentInfection', {
      is: noInfection,
      then: string().notRequired(),
      otherwhise: string().required(''),
    }),

    /**
     * Comentário
     * - Opcional
     */
    assessmentComment: string().notRequired(),

    /**
     * Microorganismos resistentes
     */
    microorganisms: mixed()
      .when({
        is: false,
        then: boolean().notRequired(),
        otherwise: array().of(
          object()
            .shape({
              microorganism: string().required('Obrigatório'),
              resistant: boolean(),
            })
        ),
      })
      .required(),

    /**
     * Innserir avaliação de ITU e IPCS se está relacionada ou não a cateter/sonda
     * Na etapa de avaliar, precisa ter a opção de:
     * Está Relacionada ou Não está relacionada a cateter,para as infecções ITU e IPCS
     */
    relatedToCatheter: boolean().when('assessmentInfection', {
      is: (assessmentInfection) =>
        [infectionTypesEnum.ipcs, infectionTypesEnum.itu].includes(
          assessmentInfection
        ),
      then: boolean().required('Obrigatório'),
      otherwhise: boolean().notRequired(),
    }),

    /**
     * Precisamos poder sinalizar, no momento de avaliar,
     * se a IPCS era relacionada ou não a CVC,
     * e se a ITU era relacionada ou não a SVD.
     *
     * Incluir após a definição da infecção se esta esta relacionada a cateter.
     */
    relatedToCVC: boolean().when(['assessmentInfection', 'relatedToCatheter'], {
      is: (assessmentInfection, relatedToCatheter) =>
        assessmentInfection === infectionTypesEnum.ipcs && relatedToCatheter,
      then: boolean().required('Obrigatório'),
      otherwhise: boolean().notRequired(),
    }),
    relatedToSVD: boolean().when(['assessmentInfection', 'relatedToCatheter'], {
      is: (assessmentInfection, relatedToCatheter) =>
        assessmentInfection === infectionTypesEnum.itu && relatedToCatheter,
      then: boolean().required('Obrigatório'),
      otherwhise: boolean().notRequired(),
    }),

    /**
     * Inserir na avaliação Infecção Comunitária Necessita-se ter a possibilidade de avaliar como Infecção Comunitária:
     * Discordar -> Infecção comunitária
     */
    communityInfection: boolean().notRequired(),
  })
  .defined();
