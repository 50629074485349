import * as d3 from 'd3';
import { upperFirst } from 'lodash';

import {
  formatNumber,
  formatProbability,
} from '../../../../../utils/formatters';

/**
 * Gráfico de barras
 */

// Estilos
let margin = {
  top: 28,
  bottom: 62,
  left: 34,
  right: 34,
};

export default class D3DonutsChart {
  // Containers
  svg = null;
  container = null;
  height = 0;
  width = 0;

  constructor({ container, dataset: originalDataset, isLoading }) {
    this.isLoading = isLoading;
    this.container = container;
    this.containerSelection = d3.select(container);

    let dataset = isLoading
      ? [{ label: 'Carregando', color: '#cccccc', value: 1 }]
      : originalDataset;

    this.dataset = Object.fromEntries(
      dataset.map(({ label, value }) => [label, value])
    );
    this.total = dataset.reduce((a, b) => a + b.value, 0);
    this.legends = dataset.map(({ label, color }) => ({ label, color }));
    this.legendsColors = dataset.map((d) => d.color);

    this.colorScale = d3
      .scaleOrdinal()
      .domain(this.dataset)
      .range(this.legendsColors);

    const pie = d3.pie().value((d) => d.value);

    this.pieData = pie(d3.entries(this.dataset));

    if (dataset.length) {
      this.setup();
      this.update();
    }
  }

  /**
   * Monta o gráfico com base no tamanho do container
   */
  changeSizes() {
    this.containerSelection.selectAll('svg').remove();
    this.setup();
    this.update();
  }

  /**
   * Configura o gráfico
   */
  setup() {
    const containerWidth = this.container.getBoundingClientRect().width;

    // Pie Width
    const pieWidth = containerWidth - margin.left - margin.right;
    const halfPieWidth = pieWidth / 2;
    const translateX = halfPieWidth + margin.left;
    const translateY = halfPieWidth + margin.top;
    const transform = `translate(${translateX}, ${translateY})`;

    // A altura é baseada na largura para que o gráfico seja sempre na resolução 1:1
    const containerHeight = pieWidth + margin.top + margin.bottom;

    // Update das variaveis globais
    this.width = containerWidth;
    this.height = containerHeight;

    this.svg = this.containerSelection
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr('transform', transform);
  }

  /**
   * Atualiza os dados do gráfico
   */
  update() {
    const outerRadius = (this.width - (margin.left + margin.right)) / 2;
    const innerRadius = outerRadius * 0.8;

    this.svg
      .selectAll('donutsChart')
      .data(this.pieData)
      .enter()
      .append('path')
      .attr('d', d3.arc().innerRadius(innerRadius).outerRadius(outerRadius))
      .attr('fill', (d) => this.colorScale(d.data.key))
      .attr('stroke', 'white')
      .attr('class', 'donutsChart')
      .style('stroke-width', '2')
      .on('mousemove', (d) => this.mousemove(d.data.key, d.value))
      .on('mouseleave', (d) => this.mouseleave());

    this.containerSelection.select('.legend-container').remove();
    this.containerSelection.append('div').attr('class', 'legend-container');

    const legends = this.containerSelection
      .select('.legend-container')
      .selectAll('.legend')
      .data(this.legends)
      .enter()
      .append('div')
      .attr('class', 'legend');

    if (!this.isLoading) {
      legends
        .append('div')
        .attr('class', 'bullet')
        .style('background-color', (d) => d.color)
        .attr('rx', 4);
    }

    legends
      .append('div')
      .text((d) => d.label)
      .attr('class', 'tick');
  }

  /**
   * Handle
   */
  mouseleave() {
    d3.select('.tooltip').remove();
  }

  /**
   * Handle
   */
  mousemove(title, value) {
    d3.select('.tooltip').remove();
    if (this.isLoading) return;

    d3.select('#root')
      .append('div')
      .attr('class', 'tooltip')
      .style('left', d3.event.pageX + 10 + 'px')
      .style('top', d3.event.pageY + 10 + 'px')
      .html(
        `${upperFirst(title.toLowerCase())}: <br/>${formatNumber(
          value
        )}<br />${formatProbability(value / this.total)}`
      )
      .append('div');
  }
}
