import styled from "styled-components";

export const BagedButton = styled.div `
  width: 100%;
  .MuiBadge-root{
    width: 100%;
    position: relative;
    .MuiBadge-badge{
      position: absolute;
      top: 50%;
      right: 25px;
      border-radius: 15px;
    }
  }
  .MuiButtonBase-root {
    width: 100%;
    background: #ffffff!important;
    margin: 5px 0!important;
    justify-content: start!important;
    padding: 0!important;
    width: 100%!important;
    height: auto;
    .MuiButton-label {
      color: #383A3D;
      font-size: 13px!important;
      font-family: 'Nunito'!important;
      font-weight: 600!important;
      display: flex;
      padding: 15px 20px!important;
      text-align: left;
    }
  }
`