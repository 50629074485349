import {
  withStyles,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core';

export const FormControlLabel = withStyles((theme) => ({
  label: {
    fontFamily: theme.MuiTypography.family.secondary,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
    color: theme.palette.neutral.black['100'],
    '&.Mui-disabled': {
      color: theme.palette.neutral.black['48'],
    },
  },
}))(MuiFormControlLabel);
