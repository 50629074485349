import React, { useMemo, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useTimelinePatientId } from '../slices';
import {
  ENTRY_Y_LENGTH,
  INDICATOR_LEGEND_X_LENGTH,
  Y_AXIS_WIDTH,
} from './constants';

import { useTimelineChartContext } from './TimelineChartProvider';
import { DatesLegends } from './DatesLegends';
import { IndicatorsLegends } from './IndicatorsLegends';
import { TimelineCursor } from './TimelineCursor';
import { TimelineScroll } from './TimelineScroll';
import { TimelineVisited } from './TimelineVisited';
import { TimelineNavigation } from './TimelineNavigation';

export const TimelineSvg = withStyles(() => ({
  root: {
    marginTop: Y_AXIS_WIDTH,
    display: 'block',
    '& .indicator-entry': {
      transition: 'opacity .15s ease',
      '&:hover': {
        opacity: 0.85,
      },
    },
  },
}))(Box);
TimelineSvg.defaultProps = {
  component: 'svg',
  xmlns: 'http://www.w3.org/2000/svg',
};

export const TimelineChartLayout = ({ children }) => {
  const patientId = useTimelinePatientId();

  const {
    wrapperRef,
    scrollApi,
    svgWidth,
    svgHeight,
  } = useTimelineChartContext();

  useEffect(() => {
    scrollApi.start({
      x: 0,
      immediate: true,
    });
  }, [patientId, scrollApi]);

  const indicatorsLegends = useMemo(() => {
    return (
      <>
        <rect
          data-name="indicator-legends-background"
          fill="#fff"
          x={0}
          y={0}
          width={INDICATOR_LEGEND_X_LENGTH}
          height={svgHeight}
        />
        <g
          data-name="indicator-legends"
          transform={`translate(0 ${ENTRY_Y_LENGTH})`}
        >
          <IndicatorsLegends />
        </g>
      </>
    );
  }, [svgHeight]);

  return (
    <Box ref={wrapperRef}>
      <TimelineSvg
        width={svgWidth}
        height={svgHeight}
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      >
        {/* Container com as entradas da timeline */}
        <g
          data-name="container"
          transform={`translate(${INDICATOR_LEGEND_X_LENGTH} 0)`}
        >
          <TimelineScroll>
            {/* Legendas das datas */}
            <DatesLegends />

            {/* Conteúdo da timeline */}
            <g data-name="heatmap" transform={`translate(0 ${ENTRY_Y_LENGTH})`}>
              {/* Corpo do gráfico */}
              <>{children}</>

              {/* Entradas visitadas */}
              <TimelineVisited />

              {/* Cursor ativo */}
              <TimelineCursor />
            </g>
          </TimelineScroll>
        </g>

        {/* Container das legendas de indicadores */}
        <>{indicatorsLegends}</>
      </TimelineSvg>

      {/* Navegação com o corpo do gráfico duplicado */}
      <Box
        marginTop="14px"
        marginLeft={`${INDICATOR_LEGEND_X_LENGTH}px`}
        height="48px"
      >
        <TimelineNavigation>{children}</TimelineNavigation>
      </Box>
    </Box>
  );
};
