import React from 'react';
import { useFieldFeedback } from './useFieldFeedback';

export const withFieldFeedback = (InputComponent) =>
  React.forwardRef((props, ref) => {
    const { field, form, ...rest } = props;
    const feedback = useFieldFeedback(field.name);

    return <InputComponent {...field} {...rest} {...feedback} ref={ref} />;
  });
