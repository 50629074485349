import React from 'react';
import { Collapse } from '@material-ui/core';
import { FormControl, InputLabel, FormHelperText } from './styles';

export const FieldControl = (props) => {
  const {
    classes,
    error,
    disabled,
    label,
    helperText,
    fullWidth,
    margin,
    children,
  } = props;

  return (
    <FormControl
      classes={classes}
      fullWidth={fullWidth}
      error={error}
      disabled={disabled}
      margin={margin}
    >
      {label && <InputLabel shrink>{label}</InputLabel>}
      {children}
      <Collapse in={!!helperText}>
        <FormHelperText>{helperText}</FormHelperText>
      </Collapse>
    </FormControl>
  );
};
FieldControl.defaultProps = {
  fullWidth: true,
  margin: 'normal',
};
