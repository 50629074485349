import moment from 'moment';

const assessedInfectionsRequestBody = ({ initialDate, finalDate }) => ({
  size: 1000,
  from: 0,
  query: {
    bool: {
      must:{
        range: {
          dt_infeccao: {
            gte: moment(initialDate).toISOString(),
            lte: moment(finalDate).toISOString(),
          },
        },
      },
      should: [
        { range: { pred_traqueo: { gte: 1 } } },
        { range: { pred_pav: { gte: 1 } } },
        { range: { pred_ipcs: { gte: 1 } } },
        { range: { pred_isc: { gte: 1 } } },
        { range: { pred_itu: { gte: 1 } } },
        { range: { pred_pnm: { gte: 1 } } },
      ],
    },
  },
  sort: [{ dt_infeccao: 'desc' }],
  aggs: {
    terms: {
      terms: { field: 'paciente_id', size: 15000 },
      aggs: {
        pacientes_hits: {
          top_hits: {
            _source: {},
            sort: [{ dt_infeccao: { order: 'desc' } }],
            size: 5000,
          },
        },
      },
    },
  },
});

export default assessedInfectionsRequestBody;
