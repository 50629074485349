import React, { createContext, useContext, useMemo, useCallback } from 'react';
import useMeasure from 'react-use-measure';
import { useSpring } from 'react-spring';
import { scaleTime, scaleBand } from 'd3';
import {
  useTimelineDateRange,
  useTimelineMaxDate,
  useTimelineMinDate,
} from '../slices';
import {
  CHART_INDICATORS,
  ENTRY_X_LENGTH,
  ENTRY_Y_LENGTH,
  INDICATOR_LEGEND_X_LENGTH,
} from './constants';

export const TimelineChartContext = createContext({});
export const useTimelineChartContext = () => useContext(TimelineChartContext);

export const TimelineChartProvider = ({ children }) => {
  const dateRange = useTimelineDateRange();
  const maxDate = useTimelineMaxDate();
  const minDate = useTimelineMinDate();

  const [wrapperRef, wrapperRect] = useMeasure({ debounce: 300 });

  const containerWidth = useMemo(
    () => (dateRange?.length || 0) * ENTRY_X_LENGTH,
    [dateRange]
  );
  const containerHeight = useMemo(
    () => CHART_INDICATORS.length * ENTRY_Y_LENGTH,
    []
  );

  const svgWidth = wrapperRect.width;
  const svgHeight = containerHeight + ENTRY_Y_LENGTH;

  const viewBoxWidth = svgWidth - INDICATOR_LEGEND_X_LENGTH;
  const viewBoxHeight = containerHeight;

  const viewBoxCenterX = viewBoxWidth / 2;

  const xScale = useMemo(() => {
    return scaleTime()
      .domain([maxDate, minDate])
      .range([0, containerWidth - ENTRY_X_LENGTH || 0]);
  }, [minDate, maxDate, containerWidth]);

  const yScale = useMemo(() => {
    return scaleBand()
      .domain(CHART_INDICATORS)
      .range([0, CHART_INDICATORS.length * ENTRY_Y_LENGTH]);
  }, []);

  const maxScroll = Math.min(0, viewBoxCenterX - containerWidth);
  const [{ x: scrollX }, scrollApi] = useSpring(() => ({ x: 0 }));

  const clampScrollX = useCallback((nextX) => {
    return Math.max(maxScroll, Math.min(0, nextX))
  }, [maxScroll])

  const contextState = useMemo(
    () => ({
      wrapperRef,
      wrapperRect,
      containerWidth,
      containerHeight,
      svgWidth,
      svgHeight,
      viewBoxWidth,
      viewBoxHeight,
      viewBoxCenterX,
      xScale,
      yScale,
      maxScroll,
      clampScrollX,
      scrollX,
      scrollApi,
    }),
    [
      wrapperRef,
      wrapperRect,
      containerWidth,
      containerHeight,
      svgWidth,
      svgHeight,
      viewBoxWidth,
      viewBoxHeight,
      viewBoxCenterX,
      xScale,
      yScale,
      maxScroll,
      clampScrollX,
      scrollX,
      scrollApi,
    ]
  );

  return (
    <TimelineChartContext.Provider value={contextState}>
      {children}
    </TimelineChartContext.Provider>
  );
};
