import React, { useMemo } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTimelineDateRange } from '../slices';
import {
  CHART_INDICATORS,
  X_AXIS_WIDTH,
  Y_AXIS_WIDTH,
  ENTRY_Y_LENGTH,
  SQUARE_HEIGHT,
} from './constants';
import { useTimelineChartContext } from './TimelineChartProvider';

export const TimelineGridBackground = ({ loading }) => {
  const { containerWidth, viewBoxWidth, yScale } = useTimelineChartContext();

  return (
    <>
      {CHART_INDICATORS.map((indicator, i) => {
        const y = yScale(indicator);

        return (
          <g key={indicator}>
            <Skeleton
              component="rect"
              fill={i % 2 === 0 ? '#f7f7f7' : '#f5f5f5'}
              variant="rect"
              width={loading ? viewBoxWidth : containerWidth}
              height={SQUARE_HEIGHT}
              x={0}
              y={y}
              animation={loading ? 'pulse' : false}
            />
          </g>
        );
      })}
    </>
  );
};

export const TimelineGrid = ({ loading }) => {
  const dateRange = useTimelineDateRange();
  const {
    yScale,
    xScale,
    containerWidth,
    containerHeight,
  } = useTimelineChartContext();

  const xAxisLines = useMemo(() => {
    return (
      <>
        {dateRange?.map((date) => {
          const timestamp = date.valueOf();
          const x = xScale(timestamp) - X_AXIS_WIDTH / 2;
          return (
            <line
              key={timestamp}
              y1={0}
              y2={containerHeight}
              x1={x}
              x2={x}
              stroke="#fff"
              strokeWidth={X_AXIS_WIDTH}
            />
          );
        })}
      </>
    );
  }, [xScale, containerHeight, dateRange]);

  const yAxisLines = useMemo(() => {
    return (
      <>
        {CHART_INDICATORS.map((indicator) => {
          const y = yScale(indicator) + ENTRY_Y_LENGTH - Y_AXIS_WIDTH / 2;
          return (
            <line
              key={indicator}
              x1={0}
              x2={containerWidth}
              y1={y}
              y2={y}
              stroke="#fff"
              strokeWidth={Y_AXIS_WIDTH}
            />
          );
        })}
      </>
    );
  }, [yScale, containerWidth]);

  return (
    <g data-name="grid">
      <TimelineGridBackground loading={loading} />
      <g data-name="x-axis">{xAxisLines}</g>
      <g data-name="y-axis">{yAxisLines}</g>
    </g>
  );
};
