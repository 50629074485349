import moment from 'moment';
import { actionTypes } from '../actions/DashboardPeriodFilterActions';

const initialState = {
  dashboard: {
    initialDate: moment().subtract(1, 'months').startOf('month'),
    finalDate: moment().subtract(1, 'months').endOf('month')
  },
};

export const dashboardPeriodFilterActions = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.SET_DASHBOARD_INITIAL_DATE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          initialDate: action.initialDate
        }
      };
    case actionTypes.SET_DASHBOARD_FINAL_DATE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          finalDate: action.finalDate
        }
      };
    case actionTypes.SET_DASHBOARD_DATA:
      const patientsWithSuspicion = action.patientsWithSuspicion;
      const infectionParams = action.infectionParams;
      // console.log(patientsWithSuspicion,'bbb')
      // console.log(patientsWithSuspicion, infectionParams)
      const low = patientsWithSuspicion.filter((patient) => {
        //
        // return patient._source.max_prob <= infectionParams.geral.ranges[1].from/100;
        return patient._source.prob_grupo_isa === 'BAIXA';
      });
      const medium = patientsWithSuspicion.filter((patient) => {
        // return patient._source.max_prob <= infectionParams.geral.ranges[1].to/100 &&
          // patient._source.max_prob > infectionParams.geral.ranges[1].from/100;

        return patient._source.prob_grupo_isa === 'MEDIA';
      });
      const hight = patientsWithSuspicion.filter((patient) => {
        // console.log(patient_source, infectionParams.geral.ranges[1].from)
        // return patient._source.max_prob > infectionParams.geral.ranges[1].to/100
        return patient._source.prob_grupo_isa === 'ALTA';
      });
      const total = patientsWithSuspicion.length;
      // console.log(low, low.length, medium,medium.length, hight, hight.length)

      return {
        ...state,
        info: {
          total,
          low: low.length,
          medium: medium.length,
          hight: hight.length
        }
      };
    default:
      return state;
  }
};

const getInfectionProbability = () => {

  return [];
}
