import React, { useMemo, useCallback } from 'react';
import { Box, useTheme, withStyles } from '@material-ui/core';
import useMeasure from 'react-use-measure';
import { animated, to } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import { useTimelineChartContext } from './TimelineChartProvider';

export const NavigationSvg = withStyles(() => ({
  root: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    maxHeight: '48px',
    maxWidth: '100%',
  },
}))(Box);
NavigationSvg.defaultProps = {
  component: 'svg',
  xmlns: "http://www.w3.org/2000/svg"
};

export const TimelineNavigation = ({ children }) => {
  const theme = useTheme();
  const {
    scrollApi,
    scrollX,
    clampScrollX,
    viewBoxCenterX,
    viewBoxWidth,
    viewBoxHeight,
    containerWidth,
    containerHeight,
  } = useTimelineChartContext();

  const [navRef, navRect] = useMeasure({ debounce: 300 });
  const trailWidth = navRect.width;

  const navDragGesture = useDrag(
    useCallback(
      ({ down, delta: [dx] }) => {
        scrollApi.start({ cancel: true });
        const currentX = scrollX.get();
        const currentDelta = dx * (containerWidth / trailWidth);
        const nextX = currentX - currentDelta;

        scrollApi.start({
          x: clampScrollX(nextX),
          immediate: down,
        });
      },
      [scrollApi, scrollX, clampScrollX, containerWidth, trailWidth]
    ),
    useMemo(
      () => ({
        axis: 'x',
        filterTaps: true,
        pointer: {
          touch: !!navigator.maxTouchPoints,
        },
        threshold: 0,
      }),
      []
    )
  );

  const handleTrailClick = useCallback(
    (e) => {
      scrollApi.start({ cancel: true });
      const eventX = e.nativeEvent.offsetX;

      const nextX = -(eventX * (containerWidth / trailWidth) - viewBoxCenterX);

      scrollApi.start({
        x: clampScrollX(nextX),
      });
    },
    [containerWidth, trailWidth, scrollApi, clampScrollX, viewBoxCenterX]
  );

  if (!containerWidth || !containerHeight) return null;

  return (
    <NavigationSvg
      ref={navRef}
      width={containerWidth}
      height={containerHeight}
      viewBox={`0 0 ${containerWidth} ${containerHeight}`}
    >
      <g data-name="navigation-heatmap" style={{ pointerEvents: 'none' }}>
        {children}
      </g>
      <rect
        data-name="navigation-trail"
        fill="transparent"
        x={0}
        y={0}
        width={containerWidth}
        height={containerHeight}
        style={{ cursor: 'pointer' }}
        onClick={handleTrailClick}
      />
      <animated.g
        data-name="navigation-pan"
        style={{
          touchAction: 'none',
          cursor: 'grab',
          x: to(scrollX, (x) => -x),
        }}
        {...navDragGesture()}
      >
        <rect
          rx={15}
          fill={theme.palette.primary.main}
          fillOpacity={0.4}
          strokeWidth={10}
          stroke={theme.palette.primary.main}
          width={viewBoxWidth - 10}
          height={viewBoxHeight - 10}
          x={5}
          y={5}
        />
      </animated.g>
    </NavigationSvg>
  );
};
