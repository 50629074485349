import {
  getPatientFacts, getPatientInfection,
} from '../services/api/patient/patient.service';

export const actionTypes = {
  GET_PATIENT_FACTS: 'GET_PATIENT_FACTS',
  GET_PATIENT_INFECTION_TIMELINE: 'GET_PATIENT_INFECTION_TIMELINE',
  SET_TIMELINE_LOADING: 'SET_TIMELINE_LOADING',
  SET_TIMELINE_READY: 'SET_TIMELINE_READY'
}
/**
 * Lista os fatos de um paciente
 * @param patientId
 * @returns {function(*): Promise<void>}
 */
export const getPatientFactsAction = (patientId) => async (dispatch) => {
  const patientFacts = await getPatientFacts(patientId);
  dispatch({
    type: actionTypes.GET_PATIENT_FACTS,
    payload: patientFacts,
  });
}
export const getPatientTimelineInfectionAction = (patientId) => async (dispatch) => {
  const patientFacts = await getPatientInfection(patientId);
  dispatch({
    type: actionTypes.GET_PATIENT_INFECTION_TIMELINE,
    payload: patientFacts,
  });
}

export const setTimelineLoadingAction = (patientId) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_TIMELINE_LOADING,
    payload: {
      loading: patientId
    },
  });
}

export const setTimelineReadyAction = (patientId) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_TIMELINE_READY,
    payload: {
      loading: patientId
    },
  });
}
