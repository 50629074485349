import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles';

export const Title = styled.h2`
    margin-bottom: 20px;
    @media (max-width: 960px) {
        margin-bottom: 20px;
    }
`

const useStyles = makeStyles((theme) => ({
    container: {
      maxWidth: 700,
      margin: 'auto'
    },
    pageTitle: {
      fontSize: 32
    },
    pageDescription: {
      marginTop: 8,
      fontSize: 15,
      color: theme.palette.neutral.black['100']
    },
    alertCard: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 12,
      padding: '10px 16px',
      marginTop: 16
    },
    checkIcon: {
      width: 56,
      height: 56,
      padding: 18,
      background: '#2BAC76',
      color: '#fff',
      borderRadius: '50%',
      boxShadow: '0px 4px 12px #2BAC7666'
    },
    alertIcon: {
      width: 56,
      height: 56,
      padding: 18,
      background: '#E2AC39',
      color: '#fff',
      borderRadius: '50%',
      boxShadow: '0px 4px 12px #E2AC3966'
    },
    cardDescription: {
      fontWeight: 600,
      fontSize: 14,
      color: theme.palette.neutral.black['72'],
      marginTop: 4,
    },
    tableTitle: {
      fontWeight: 600,
      fontSize: 20,
      color: '#3E4247',
      fontFamily: 'Nunito'
    },
    tableDescription: {
      fontSize: 14,
      fontWeight: 400,
      marginTop: 5,
      color: '#3E4247B8'
    },
    lastUpdate: {
        fontFamily: 'DM Mono',
        fontSize: '13px',
        color: '#8F95A3',
        margin: '-17px 0px 0px'
    },
    saveButton: {
        textTransform: 'uppercase',
        fontFamily: theme.MuiTypography.root.fontFamily,
        fontWeight: 'bold'
    },
    resetButton: {
      padding: '5px 15px',
      height: 'auto'
    },
    buttons: {
      width: 'auto'
    },
    tableContainer: {
      border: '1px solid #3E424729',
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
      marginBottom: 10
    },
    tableStyle: {
      'border': 'none',
    },
    tableRow: {
      'border-bottom': '1px solid #3E424729',
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      }
    },
    tableHead: {
      background: 'none',
      borderBottom: '1px solid #3E424729'
    },
    itemText: {
      fontFamily: 'DM Mono',
      fontWeight: '400',
      fontSize: '13px',
      color: '#3E4247B8'
    },
}));

export default useStyles;
