/**
 * Status da avaliação de uma suspeita
 *
 * Uma suspeita pode ser avaliada como um dos seguintes status.
 *
 * @constant
 * @type {{positiva: 'positiva', negativa: 'negativa', inconclusiva: 'inconclusiva'}}
 */
export const assessmentStatusesEnum = {
  'positiva': 'positiva',
  'negativa': 'negativa',
  'inconclusiva': 'inconclusiva',
};

/**
 * Status da avaliação de uma suspeita
 *
 * Uma suspeita pode ser avaliada como um dos seguintes status.
 *
 * @constant
 * @type {['positiva', 'negativa', 'inconclusiva']}
 */
export const assessmentStatusesList = Object.keys(assessmentStatusesEnum)