import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { CHART_INDICATORS } from '../TimelineChart/constants';
import { indicatorsEnum } from '../../../constants/indicators';

const initialVisited = Object.fromEntries(
  CHART_INDICATORS.map((indicator) => [indicator, []])
);

const initialState = {
  patientId: undefined,
  cursor: undefined,
  visited: initialVisited,
  indicatorPopover: undefined,
  searchWord: '',
  searchInstance: 0,
};

const timelinePageSlice = createSlice({
  name: 'timelinePageSlice',
  initialState: initialState,
  reducers: {
    setTimelinePatientId: (state, { payload: patientId }) => {
      state.patientId = patientId || undefined;
      state.cursor = undefined;
      state.visited = initialVisited;
      state.searchWord = initialState.searchWord;
      state.searchInstance = initialState.searchInstance;
    },
    resetTimelineCursor: (state) => {
      state.cursor = undefined;
    },
    setTimelineCursor: (state, { payload = {} }) => {
      const { indicator, date } = payload;
      const momentDate = moment(date).startOf('day');
      const timestamp = momentDate.valueOf();

      if (indicator !== indicatorsEnum.encontros) {
        state.searchWord = initialState.searchWord;
        state.searchInstance = initialState.searchInstance;
      }

      if (indicator && date && momentDate.isValid()) {
        state.cursor = {
          indicator: indicator,
          date: timestamp,
        };

        if (!state.visited[indicator].includes(timestamp)) {
          state.visited[indicator] = [...state.visited[indicator], timestamp];
        }
      } else {
        state.cursor = undefined;
      }
    },
    setIndicatorPopover: (state, { payload = undefined }) => {
      state.indicatorPopover =
        state.indicatorPopover === payload ? undefined : payload;
    },
    setSearchWord: (state, { payload = '' }) => {
      if (state.searchWord !== payload) {
        state.searchWord = payload;
      }
    },
    setSearchInstance: (state, { payload = 0 }) => {
      state.searchInstance = payload;
    },
  },
});
export const {
  setTimelinePatientId,
  setTimelineCursor,
  resetTimelineCursor,
  setIndicatorPopover,
  setSearchWord,
  setSearchInstance,
} = timelinePageSlice.actions;
export default timelinePageSlice.reducer;
