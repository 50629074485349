import React, { useEffect } from 'react';
import { Box, Divider } from '@material-ui/core';

import { useLastMonthIndicatorItems } from '../../hooks/useLastMonthIndicatorItems';
import { useGroupIndicatorByDate } from '../../hooks/useGroupIndicatorByDate';
import { IndicatorDateCard } from '../../IndicatorDateCard';
import { TermsEvolucao } from './TermsEvolucao';

export const PopoverContentEvolucao = ({ indicator }) => {
  const items = useLastMonthIndicatorItems(indicator);
  const dates = useGroupIndicatorByDate(items);

  return (
    <>
      {dates.map(({ date, momentDate, items }, cardIndex) => (
        <IndicatorDateCard
          key={date}
          style={{
            marginTop: cardIndex !== 0 ? '8px' : 0
          }}
          momentDate={momentDate}
          date={date}
          indicator={indicator}
        >
          {items.map((item, itemIndex) => (
            <React.Fragment key={`${date}-${itemIndex}`}>
              {itemIndex !== 0 && <Divider />}
              <TermsEvolucao item={item} />
            </React.Fragment>
          ))}
        </IndicatorDateCard>
      ))}
    </>
  );
};
