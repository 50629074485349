import React from 'react';
import { DialogCloseButton, SmallDialogCloseButton } from '../../Dialog'
import { useDrawerContext } from '../DrawerBase';

export const DrawerClose = ({ small }) => {
  const { onClose } = useDrawerContext();
  const ButtonComponent =
    small ? SmallDialogCloseButton : DialogCloseButton;

  return <ButtonComponent onClick={onClose} />;
};
DrawerClose.defaultProps = {
  small: false,
}