import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedCompetence } from '../slice';

export const useSetCompetence = () => {
  const dispatch = useDispatch();

  const handleSetCompetence = useCallback(
    (competence) => dispatch(setSelectedCompetence(competence)),
    [dispatch]
  );

  return handleSetCompetence;
};
