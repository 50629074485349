import React from 'react';

import { Fab, Badge, withStyles } from '@material-ui/core';
import { useCommentsContext } from './CommentsContext';

const BADGE_DIMESIONS = {
  sizes: {
    container: '56px',
    badge: '17px',
  },
  position: {
    top: '12px',
    right: '8px',
  },
  font: {
    badge: '11px',
  },
};

export const ChatCIcon = ({ open }) =>
  open ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.5858L18.364 4.22183L19.7782 5.63604L13.4143 12L19.7782 18.364L18.364 19.7782L12 13.4142L5.63608 19.7782L4.22187 18.364L10.5858 12L4.22187 5.63604L5.63608 4.22183L12 10.5858Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 21.8042L12.0868 18H20C21.1046 18 22 17.1046 22 16V4C22 2.89543 21.1046 2 20 2H4C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H6V21.8042ZM11.5132 16L8 18.1958V16H4V4H20V16H11.5132ZM7 13V11H14V13H7ZM7 7V9H16V7H7Z"
        fill="white"
      />
    </svg>
  );

const ReallyFloatingFab = withStyles(() => ({
  root: {
    position: 'absolute',
    bottom: '0',
    right: '0',
  },
}))(Fab);

const StyledBadge = withStyles((theme) => ({
  root: {
    width: BADGE_DIMESIONS.sizes.container,
    height: BADGE_DIMESIONS.sizes.container,
  },
  badge: {
    backgroundColor: theme.palette.timeline.probability[500],
    color: theme.palette.white.main,
    lineHeight: BADGE_DIMESIONS.sizes.badge,
    minWidth: BADGE_DIMESIONS.sizes.badge,
    minHeight: BADGE_DIMESIONS.sizes.badge,
    width: BADGE_DIMESIONS.sizes.badge,
    height: BADGE_DIMESIONS.sizes.badge,
    top: BADGE_DIMESIONS.position.top,
    right: BADGE_DIMESIONS.position.right,
    border: `1px solid ${theme.palette.white.main}`,
    fontSize: BADGE_DIMESIONS.font.badge,
  },
}))(Badge);

export const ChatCommentButton = () => {
  const { handleToggle: toggleChat, comments, open } = useCommentsContext();
  return (
    <StyledBadge
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      badgeContent={comments.length}
    >
      <ReallyFloatingFab color="primary" onClick={toggleChat}>
        <ChatCIcon open={open} />
      </ReallyFloatingFab>
    </StyledBadge>
  );
};
