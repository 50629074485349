/**
 * Instância do Intl.NumberFormat para formatações percentuais
 * 
 * @constant
 * @type {Intl.NumberFormat}
 */
export const probabilityFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

/**
 * Recebe um valor percentual no primeiro parâmetro e retorna uma versão formatada do valor percentual.
 * 
 * Por padrão o valor passado deve ser entre 0 e 1, representando respectivamente 0% e 100%;
 * 
 * Caso o valor esteja entre 0 e 100,
 * o segundo parâmetro deve ser passado como true para que a divisão ocorra antes da formatação.
 * 
 * @param {number} value Valor da probabilidade (entre 0 e 1, sendo 1 referente a 100%)
 * @param {number} shouldDivideBy100 Defina como true caso o valor da probabilidade já esteja no formato 0 a 100.
 * 
 * @example
 * formatProbability(0.13); // 13,00%
 * formatProbability(0.139); // 13,90%
 * formatProbability(0.1393); // 13,93%
 * 
 * @example
 * formatProbability(12, true); // 12,00%
 * formatProbability(97.5, true); // 97,50%
 * 
 */
export const formatProbability = (value, shouldDivideBy100 = false) => {
  return probabilityFormatter.format(shouldDivideBy100 ? value / 100 : value);
}