import React, { useState, useCallback, useRef, useEffect } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Input,
  Typography,
  withStyles,
  LinearProgress,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { ChatCommentMessages } from './ChatCommentMessages';

import { useCommentsContext } from './CommentsContext';

const ChatCommentHeader = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Box);

const ChatCommentHeaderText = withStyles((theme) => ({
  root: { color: theme.palette.primary.contrastText },
}))(Typography);

const ChatCommentHeaderBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    minWidth: 'initial',
    padding: 0,
  },
}))(IconButton);

const ChatCommentContent = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: '16px 16px 12px',
    height: '310px',
    overflowY: 'auto',
  },
}))(Box);

const ChatCommentWriteBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutral.gray.xlt,
    padding: '6px 0 6px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 'auto',
  },
}))(Box);

const ChatCommentWriteInput = withStyles((theme) => ({
  root: {
    color: theme.palette.neutral.black[48],
    fontSize: '13px',
    fontFamily: theme.MuiTypography.family.secondary,
    border: 'none',
    width: '100%',
    paddingRight: '12px',
    height: '56px'
  },
}))(Input);

const ChatCommentWriteBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    minWidth: 'initial',
  },
}))(IconButton);

const ChatCommentEditingBtns = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
  },
  groupedTextHorizontal: {
    borderColor: `${theme.palette.neutral.black[16]} !important`,
  },
}))(ButtonGroup);

const SendIcon = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.740112 0.252029L2.10512 9.80706L2.87687 9.99999L2.10512 10.1929L0.740112 19.748L20.236 9.99999L0.740112 0.252029ZM3.89483 8.19293L3.25984 3.74796L15.7639 9.99999L3.25984 16.252L3.89483 11.8071L11.1231 9.99999L3.89483 8.19293Z"
      fill="#16B8A7"
    />
  </svg>
);

const ChatCommentWritable = () => {
  const {
    edit,
    handleCancelEdit,
    handleCreateComment,
    handleEditComment,
    isCreatingComment,
    isEditingComment,
    errorCreatingComment,
  } = useCommentsContext();
  const inputRef = useRef();

  const [message, setMessage] = useState('');

  useEffect(() => {
    const { editing, id, content } = edit;
    if (editing) {
      setMessage(content);
      inputRef.current.focus();
    }
  }, [edit]);

  const handleSubmit = useCallback(() => {
    if (edit.editing) {
      handleEditComment(edit.id, message);
      setMessage('');
      handleCancelEdit();
    } else {
      handleCreateComment(message);
      setMessage('');
    }
  }, [handleCreateComment, message, edit]);

  const cancelEdit = useCallback(() => {
    setMessage('');
    handleCancelEdit();
  }, [handleCancelEdit, edit]);

  const handleChange = useCallback(
    (e) => setMessage(e.target.value),
    [setMessage]
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (!e.target.value) return;

      if (e.key === 'Enter') {
        setMessage(e.target.value);
        handleSubmit();
      }
      if (edit.editing && e.key === 'Escape') {
        setMessage('');
        handleCancelEdit();
      }
    },
    [message]
  );

  return (
    <ChatCommentWriteBox>
      <ChatCommentWriteInput
        placeholder="Escreva um comentário..."
        disableUnderline={true}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        value={message}
        inputRef={inputRef}
      />
      {edit.editing ? (
        <ChatCommentEditingBtns variant="text">
          <ChatCommentWriteBtn onClick={cancelEdit}>
            <CloseOutlined fontSize="medium"></CloseOutlined>
          </ChatCommentWriteBtn>
          <ChatCommentWriteBtn onClick={handleSubmit}>
            <SendIcon />
          </ChatCommentWriteBtn>
        </ChatCommentEditingBtns>
      ) : (
        <ChatCommentWriteBtn onClick={handleSubmit}>
          <SendIcon />
        </ChatCommentWriteBtn>
      )}
    </ChatCommentWriteBox>
  );
};

export const ChatCommentBox = () => {
  const {
    isFetchingComments,
    handleClose,
    open,
    isEditingComment,
    isCreatingComment,
    isDeletingComment,
  } = useCommentsContext();

  const scrollBoxRef = useRef();

  useEffect(() => {
    if (open && scrollBoxRef.current) {
      scrollBoxRef.current.scrollTo(0, 9999);
    }
  }, [open]);

  return (
    <Box
      width="350px"
      height="420px"
      display={open ? 'flex' : 'none'}
      flexDirection="column"
      position="absolute"
      bottom="64px"
      right="0"
      border="1px solid white"
      borderRadius="8px"
      overflow="hidden"
      boxShadow="0px 8px 32px -12px rgba(0, 0, 0, 0.72)"
    >
      <ChatCommentHeader
        height="54px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="16px"
      >
        <ChatCommentHeaderText variant="body1">
          Comentários
        </ChatCommentHeaderText>
        <ChatCommentHeaderBtn onClick={handleClose}>
          <CloseOutlined fontSize="small"></CloseOutlined>
        </ChatCommentHeaderBtn>
      </ChatCommentHeader>
      {(isEditingComment || isCreatingComment || isDeletingComment) && (
        <LinearProgress />
      )}
      <ChatCommentContent ref={scrollBoxRef}>
        <ChatCommentMessages />
      </ChatCommentContent>
      <ChatCommentWritable />
    </Box>
  );
};
