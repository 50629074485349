import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {func, bool, string} from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {GraphContent} from "./styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
const style = {
  DialogTitle: {
    position: 'relative',
  },
  CloseIcon: {
    marginRight: 20
  }
}
const useStyles = makeStyles(style);
const GraphDialogComponent = (props) => {
  const classes = useStyles();

  const {
    graphDialog = React.createRef(),
    setOpen,
    open,
    title,
    unit = ''
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} ref={graphDialog} id="GraphDialog">
      <DialogTitle onClose={handleClose} className={classes.DialogTitle}>
        {title}
        <IconButton className="closeButton" aria-label="close" onClick={handleClose} style={style.CloseIcon}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <GraphContent>
          {props.children}
        </GraphContent>
      </DialogContent>
    </Dialog>
  )
};

GraphDialogComponent.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
  title: string.isRequired
};

export default GraphDialogComponent;
