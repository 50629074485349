import styled from "styled-components";

export const PatientName = styled.h4`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #383A3D;
`;
export const Date = styled.div`
  font-size: 13px;
  line-height: 17px;
  color: #8F95A3;
  font-family: 'DM Mono';
`;
export const MaxProb = styled.div`
  font-size: 13px;
  line-height: 17px;
  color: #8F95A3;
  font-family: 'DM Mono';
`;

export const PatientCard = styled.div`
  &.pointer{
    cursor: pointer;
  }
  .MuiPaper-root {
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    border: none;
    &:hover{
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    }
  }
  .sc-fznZeY {
    text-align: right;
  }
`;

export const LastEvaluation = styled.p`
  color: #16B8A7;
  font-size: 13px;
  font-family: 'DM Mono';
  margin-top: 5px;
`;
