import { unitsOfMeasureEnum } from '../../constants/unitsOfMeasure'

/**
 * Recebe os valores dos sinais vitais e retorna uma label com a unidade de medida e os valores formatados
 * 
 * @param {Object} sign Dados do sinal vital
 * @returns {string} ex: 'bpm: 87 - 108'
 */
export const formatUnitsOfMeasureLabel = (sign) => {
    const measure = unitsOfMeasureEnum[sign.measure]

    if (measure === '%')
      return `${sign.min}${measure} - ${sign.max}${measure}`
    
    let measureLabel = '';
    if( measure )
      measureLabel = `${measure}: `

    return `${measureLabel}${sign.min} - ${sign.max}`
}