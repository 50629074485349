import React from 'react'
import { withStyles } from '@material-ui/core';
import clsx from 'clsx'

export const MedicineRangeText = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '17px',
    color: theme.palette.neutral.black['48'],
  },
}))(({ classes, className, ...props }) => (
  <div {...props} className={clsx(classes.root, className)} />
));
