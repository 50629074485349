import { useCallback, useMemo } from 'react';
import { useToggleState } from '../../../../utils/useToggleState';
import { useCommentsContext } from '../CommentsContext';
import { useSingleCommentContext } from '../SingleCommentContext';

export const useDeleteCommentDialog = () => {
  const { handleDeleteComment, isDeletingComment, errorDeletingComment } =
    useCommentsContext();
  const { id } = useSingleCommentContext();
  const { open, handleOpen, handleClose } = useToggleState();

  const handleCancel = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleConfirm = useCallback(
    () => handleDeleteComment(id),
    [id, handleDeleteComment]
  );

  const result = useMemo(
    () => ({
      open,
      onCancel: handleCancel,
      onConfirm: handleConfirm,
      onOpen: handleOpen,
      loading: isDeletingComment,
    }),
    [open, handleCancel, handleConfirm, handleOpen, isDeletingComment]
  );

  return result;
};
