import React, { useState } from "react";
import { func } from 'prop-types';
import {
  ClearAll,
  FilterSidebarContainer,
  FilterSidebarBody,
  FilterSidebarHeader
} from "./styles";
import BagedButton from '../../molecules/BagedButton/index';
import FormDialogProbabilidade from "../FormDialogProbabilidade";
import FormDialogStatusComponent from "../FormDialogStatusComponent";
import DoneAllIcon from '@material-ui/icons/DoneAll';

export const FilterSidebar = (props) => {
  const {
    notifyParentOnProbabilityChange = () => {},
    notifyParentOnReset = () => {},
    notifyParentOnStatusChange = () => {},
    historico = false
  } = props;
  const [openProbabilidade, setOpenProbabilidade] = useState(false);
  const handleOpenProbabilidade = (e) => setOpenProbabilidade(true);
  const [openStatus, setOpenStatus] = useState(false);
  const handleOpenStatus = (e) => setOpenStatus(true);

  const getPageFilter = () => {
    if(historico){
      return (
        <>
          <BagedButton
            title="Status"
            onClick={handleOpenStatus}
            icon={<DoneAllIcon/>}
          />
          <FormDialogStatusComponent
            open={openStatus}
            setOpen={setOpenStatus}
            sendStatusesToParent={notifyParentOnStatusChange}
          />
        </>
      )
    } else {
      return (
        <>
          <BagedButton
            title="Probabilidade de infecção"
            onClick={handleOpenProbabilidade}
          />
          <FormDialogProbabilidade
            open={openProbabilidade}
            setOpen={setOpenProbabilidade}
            sendProbabilitiesToParent={notifyParentOnProbabilityChange}
          />
        </>
      )
    }
  };

  return (
    <FilterSidebarContainer>
      <FilterSidebarHeader>
        Filtros
        <ClearAll onClick={() => notifyParentOnReset()}>Limpar</ClearAll>
      </FilterSidebarHeader>
      <FilterSidebarBody>
        {getPageFilter()}
      </FilterSidebarBody>
    </FilterSidebarContainer>
  )
};

FilterSidebar.propTpes = {
  notifyParentOnProbabilityChange: func,
  notifyParentOnReset: func,
  notifyParentOnStatusChange: func
};
