import { useTheme } from '@material-ui/core';
import { useProbabilitySettings } from './useProbabilitySettings';
import { formatProbabilityLevel } from '../formatters/formatProbabilityLevel';

export const useProbabilityColor = (probability) => {
  const theme = useTheme();
  const settings = useProbabilitySettings();
  const probabilityLevel = formatProbabilityLevel(settings, probability);

  return theme.palette.timeline.probability[probabilityLevel];
};
