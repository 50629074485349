import React from 'react';
import { Chip } from '../../atoms/Chip';

const XIcon = (props) => (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00002 3.79314L7.182 0.611157L7.88911 1.31826L4.70713 4.50024L7.88911 7.68222L7.182 8.38933L4.00002 5.20735L0.818042 8.38933L0.110936 7.68222L3.29292 4.50024L0.110936 1.31826L0.818042 0.611157L4.00002 3.79314Z"
      fill="currentColor"
      fillOpacity="0.74"
    />
  </svg>
);
const CheckedIcon = (props) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.85355 7.64645L9.5 3L10.2071 3.70711L4.85355 9.06066L2 6.20711L2.70711 5.5L4.85355 7.64645Z"
      fill="currentColor"
    />
  </svg>
);
export const DataTableAssessment = ({ assessment }) => {
  const { status, tipoInfeccao } = assessment;

  if (tipoInfeccao === 'comunitaria') {
    return (
      <Chip
        variant="danger"
        label={tipoInfeccao.toUpperCase()}
        icon={<CheckedIcon style={{ marginRight: '4px' }} />}
      />
    );
  }

  if (status === 'positiva') {
    return (
      <Chip
        variant="success"
        icon={<CheckedIcon style={{ marginRight: '4px' }} />}
        label={tipoInfeccao.toUpperCase()}
      />
    );
  }

  if (status === 'negativa') {
    return (
      <Chip
        variant="danger"
        label={tipoInfeccao.toUpperCase()}
        icon={<XIcon style={{ marginRight: '4px' }} />}
      />
    );
  }

  if (status === 'inconclusiva') {
    return <Chip variant="gray" label={tipoInfeccao.toUpperCase()} />;
  }

  return '--';
};
