import React from "react";
import {Timeline, Container} from './styles';

const TimelineTemplate = (props) => {
  return (
    <Timeline>

      <Container>
        {props.children}
      </Container>
    </Timeline>
  )
}

export default TimelineTemplate
