import React from 'react';
import { Box, Popover, withStyles } from '@material-ui/core';
import { SmallDialogCloseButton } from '../../../../components/molecules/Dialog';
import { PopoverScrollContent } from './PopoverScrollContent';

const popoverProps = {
  anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
  transformOrigin: { horizontal: 'left', vertical: 'top' },
  PaperProps: {
    style: {
      marginTop: '4px',
      borderRadius: '8px',
      width: 320,
      boxShadow: '0px 8px 32px -12px rgba(0, 0, 0, 0.2)',
      maxHeight: 300,
      display: 'flex',
      pointerEvents: 'all',
    },
  },
};

const StyledPopover = withStyles(() => ({
  // root: { pointerEvents: 'none' },
}))(Popover);

export const IndicatorPopover = React.memo(function IndicatorPopoverMemo(
  props
) {
  const { anchorEl, open, onClose, children } = props;

  return (
    <StyledPopover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      {...popoverProps}
      keepMounted
      getContentAnchorEl={undefined}
      // disableAutoFocus
      disableEnforceFocus
    >
      <SmallDialogCloseButton
        onClick={onClose}
        style={{ position: 'absolute', right: 0, top: 0 }}
      />
      <PopoverScrollContent>{children}</PopoverScrollContent>
    </StyledPopover>
  );
});
