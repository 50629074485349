import React, { useEffect, useState, useCallback } from 'react';
import { ButtonGroup, IconButton, withStyles } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useQuery } from './utils';
import moment from 'moment';
import { useHistory, useLocation, Link } from 'react-router-dom';
export const DateNavButton = withStyles((theme) => ({
  root: {
    padding: '9px',
    width: '32px',
    height: '32px',
    minWidth: 'initial',
    background: theme.palette.neutral.gray.xxlt,
    borderRadius: '6px 0px 0px 6px',
    color: theme.palette.neutral.black['100'],
    '.MuiButtonGroup-groupedContainedPrimary:not(:last-child)&': {
      borderColor: theme.palette.common.white,
    },
    '& svg': {
      fontSize: '18px',
    },
    '& + &': {
      borderRadius: '0px 6px 6px 0px',
    },
  },
}))(IconButton);
DateNavButton.defaultProps = {};

const ChevronLeft = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.49171 7.00004L9.7459 11.2542L8.92094 12.0792L3.8418 7.00004L8.92094 1.9209L9.7459 2.74586L5.49171 7.00004Z"
      fill="#3E4247"
    />
  </svg>
);

const ChevronRight = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.50858 7.00004L4.25439 2.74586L5.07935 1.9209L10.1585 7.00004L5.07935 12.0792L4.25439 11.2542L8.50858 7.00004Z"
      fill="#3E4247"
    />
  </svg>
);

export const PatientEvolutionDateNavigation = ({ entries }) => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const [dates, setDates] = useState([]);
  const [nextDay, setNextDay] = useState(null);
  const [prevDay, setPrevDay] = useState(null);

  const setNavigation = (dates) => {
    const meetingDate = moment(query.get('dt_encontro'));

    const todaysIndex = dates.findIndex((date) =>
      moment(date).isSame(meetingDate)
    );

    if (todaysIndex === -1) return; // TODO: TRATAR DATA NAO ENCONTRADA

    const hasPreviousDate = todaysIndex > 0;
    if (hasPreviousDate) {
      const yesterday = dates[todaysIndex - 1];
      setPrevDay(yesterday);
    } else {
      setPrevDay(null);
    }

    //prettier-ignore
    const hasNextDate = (todaysIndex >= 0 && todaysIndex <= dates.length - 1);
    if (hasNextDate) {
      const tomorrow = dates[todaysIndex + 1];
      setNextDay(tomorrow);
    } else {
      setNextDay(null);
    }
  };

  useEffect(() => {
    if (entries) {
      const dates = entries.map((item) => item._source.dt_encontro);
      setDates(dates);
      setNavigation(dates);
    }
  }, [entries]);

  useEffect(() => {
    setNavigation(dates);
  }, [query.get('dt_encontro')]);

  const navigateYesterday = () => {
    history.push(`${location.pathname}?dt_encontro=${prevDay}`);
  };

  const navigateTomorrow = () => {
    history.push(`${location.pathname}?dt_encontro=${nextDay}`);
  };

  return (
    <ButtonGroup variant="contained" color="primary">
      <DateNavButton disabled={!prevDay} onClick={navigateYesterday}>
        <ChevronLeft />
      </DateNavButton>
      <DateNavButton disabled={!nextDay} onClick={navigateTomorrow}>
        <ChevronRight />
      </DateNavButton>
    </ButtonGroup>
  );
};
