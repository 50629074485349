import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectTimelineIndicatorsDates } from './selectTimelineIndicatorsDates';
import { mapValues, uniq, isEqual } from 'lodash';

export const selectTimelineEncontrosAlternations = createSelector(
  [selectTimelineIndicatorsDates],
  (indicatorsDates) => {
    if (!indicatorsDates?.encontros) return;

    const locations = mapValues(indicatorsDates.encontros, (items) =>
      uniq(items.map((item) => item.local_encontro).sort())
    );

    const sortedDates = Object.keys(locations).sort((a, b) => a - b);

    const alternatedLocations = sortedDates.reduce(
      (acc, date, index) => {
        const currentItem = locations[date];
        const previousItem = locations[sortedDates[index - 1]];
        const hasChanged = !isEqual(previousItem, currentItem);

        if (hasChanged) {
          acc.changeCount += 1;
        }

        acc.dates[date] = acc.changeCount;

        return acc;
      },
      { dates: {}, changeCount: 0 }
    );

    return alternatedLocations.dates;
  }
);
export const useTimelineEncontrosAlternations = () =>
  useSelector(selectTimelineEncontrosAlternations);
