import { infectionTypesReducer } from './InfectionTypesReducer';
import { combineReducers } from 'redux';
import { suspiciousInfectionsReducer } from './SuspiciousInfectionsReducer';
import { dashboardPeriodFilterActions } from './DashboardPeriodFilterActions';
import { pendingAssessmentsReducer } from './PendingAssessementsReducer';
import { pendingAssessmentsFiltersReducer } from './PendingAssessmentsFiltersReducer';
import { assessmentsHistoryFiltersReducer } from './AssessmentsHistoryFiltersReducer';
import { assessmentsHistoryReducer } from './AssessmentsHistoryReducer';
import { patientDataReducer } from './PatientDataReducer';
import { timelineReducer } from "./TimelineReducer";
import { unitiesReducer } from './UnitiesReducer'
import { settingsReducer } from './SettingsReducer';
import { patientReducer } from './PatientVrReducer';


import { assessmentsApi } from '../services/assessments/assessments.service'
import { suspicionsApi } from '../services/suspicions/suspicions.service';
import { patientApi } from '../services/patient/patient.service';
import { patientCommentsApi } from '../services/patientComments/patientComments.service';
import { patientSuspicionsApi } from '../services/patientSuspicions/patientSuspicions.service';
import { patientEvaluationsApi } from '../services/evaluations/evaluations.service';
import { patientPrescriptionsApi } from '../services/prescriptions/patientPrescriptions.service';
import { hospitalUnityApi } from '../services/hospitalUnity/hospitalUnity.service';
import { infectionApi } from '../services/infection/infection.service';
import { dashboardApi } from '../services/dashboard/dashboard.service';
import { microorganismsApi } from '../services/microorganisms/microorganisms.service';

import timelinePageSlice from '../pages/Timeline/slices'
import patientsPageSlice from '../pages/Pacientes/v2/slices'
import historyPageSlice from '../pages/Historico/v2/slices'
import dashboardPageSlice from '../pages/Dashboard/v2/slices'

import horizontalChipsBasicReducer from '../components/molecules/HorizontalChipsBasic/slices'
import { settingsApi } from '../services/settings/settings.service';
import { healthStatusApi } from '../services/health/health.service';
import { createPatientApi } from '../services/api/createPatient/createPatient.service';
import { companiesApi } from '../services/companies/companies.service';


const Reducers = combineReducers({

  [assessmentsApi.reducerPath]: assessmentsApi.reducer,
  [patientApi.reducerPath]: patientApi.reducer,
  [patientCommentsApi.reducerPath]: patientCommentsApi.reducer,
  [suspicionsApi.reducerPath]: suspicionsApi.reducer,
  [patientSuspicionsApi.reducerPath]: patientSuspicionsApi.reducer,
  [patientEvaluationsApi.reducerPath]: patientEvaluationsApi.reducer,
  [patientPrescriptionsApi.reducerPath]: patientPrescriptionsApi.reducer,
  [hospitalUnityApi.reducerPath]: hospitalUnityApi.reducer,
  [infectionApi.reducerPath]: infectionApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [healthStatusApi.reducerPath]: healthStatusApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [microorganismsApi.reducerPath]: microorganismsApi.reducer,
  [createPatientApi.reducerPath]: createPatientApi.reducer,
  [companiesApi.reducerPath]: companiesApi.reducer,

  timelinePageSlice,
  patientsPageSlice,
  historyPageSlice,
  dashboardPageSlice,
  horizontalChipsBasic: horizontalChipsBasicReducer,


  infectionTypes: infectionTypesReducer,
  periods: dashboardPeriodFilterActions,
  pendingAssessments: pendingAssessmentsReducer,
  assessmentsHistory: assessmentsHistoryReducer,
  suspiciousInfections: suspiciousInfectionsReducer,
  pendingAssessmentsFilter: pendingAssessmentsFiltersReducer,
  assessmentsHistoryFilter: assessmentsHistoryFiltersReducer,
  patientTimeline: patientDataReducer,
  unities: unitiesReducer,
  timeline: timelineReducer,
  settings: settingsReducer,
  patientVr: patientReducer,



});

export default Reducers;
