import * as d3 from 'd3'
import ReactDOM from "react-dom";


export const D3Drag = ({margin, width, AxisWidth}) => {

  const container = d3.select('.chart-container');

  let deltaX = 0;
  let currentX = margin.left;

  /**
   * Drag iniciado
   * @param d
   */
  const dragstarted = (d) => {
    // guarda a posição inicial do mouse
    deltaX = currentX - d3.event.x;
    d3.select('.TimelineSVG')
      .classed('dragstart',true);
    const component = ReactDOM.findDOMNode(document.getElementById('rootTooltip'))
    ReactDOM.unmountComponentAtNode(component)
  }

  const dragended = (d) => {
    d3.select('.TimelineSVG')
      .classed('dragstart',false);
  }
  /**
   * Movendo o cursor
   * @param d
   */
  const dragged = (d) => {
    if(AxisWidth < width) return;

    // calcula o quanto a posição do mouse foi alterada com base na posição inicial
    currentX = deltaX + d3.event.x;

    // Trava o limite máximo
    if(currentX > 0) currentX = 0;

    // Limite mínimo
    if(currentX <= width - AxisWidth) currentX = width - AxisWidth

    // Atualiza posição do container do eixo
    container
      .attr("transform", `translate(${currentX},0)`);
  }

  // const dragended = (d) => {}

  return d3.drag()
    .on("start", (d) => dragstarted(d))
    .on("drag", (d) => dragged(d))
    .on("end", (d) => dragended(d));
}
