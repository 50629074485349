import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../baseQuery';

export const patientCommentsApi = createApi({
  reducerPath: 'patientCommentsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['PatientComments'],
  endpoints: (build) => ({
    getPatientComments: build.query({
      query: (patientId) => ({
        url: `/pacientes/comentarios/${patientId}`,
        method: 'GET',
      }),
      transformResponse: (entries) => {
        return entries
          .filter((entry) => entry?.comment && !entry.deletedAt)
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      },
      providesTags: ['PatientComments'],
    }),
    createPatientComment: build.mutation({
      query({ patientId, message }) {
        return {
          url: `/pacientes/comentarios`,
          method: 'POST',
          body: {
            paciente_id: patientId,
            feito_por: localStorage.getItem('username'),
            comentario: message,
          },
        };
      },
      invalidatesTags: ['PatientComments'],
    }),
    editPatientComment: build.mutation({
      query({ commentId, patientId, message }) {
        return {
          url: `/pacientes/comentarios/${commentId}`,
          method: 'PUT',
          body: {
            paciente_id: patientId,
            feito_por: localStorage.getItem('username'),
            comentario: message,
          },
        };
      },
      invalidatesTags: ['PatientComments'],
    }),
    deletePatientComment: build.mutation({
      query(commentId) {
        return {
          url: `/pacientes/comentarios/${commentId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['PatientComments'],
    }),
  }),
});

export const {
  useGetPatientCommentsQuery,
  useCreatePatientCommentMutation,
  useEditPatientCommentMutation,
  useDeletePatientCommentMutation,
} = patientCommentsApi;
