import React from 'react';
import {
  IndicatorTermsList,
  useIndicatorTerms,
} from '../../IndicatorTermsList';

export const TermsLaudos = ({ item }) => {
  const {
    _meta: { date, indicator },
    laudo_texto: text,
  } = item;

  const terms = useIndicatorTerms({ text });

  return <IndicatorTermsList date={date} indicator={indicator} terms={terms} />;
};
