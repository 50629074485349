import React, {useEffect} from "react";
import {useKeycloak} from "@react-keycloak/web";
import { useHistory } from "react-router-dom"

export default function SignIn() {
  const history = useHistory();

  const [keycloak, initialized] = useKeycloak();

  useEffect(() => {
    if (initialized) {
      if (keycloak.authenticated) {
        // A `home` apenas redireciona para o /dashboard
        history.push('/dashboard');
      }
    }
    // O login é forçado em todas as rotas no componente do router (/routes/index.jsx)
  }, [initialized, keycloak, history]);

  return (
    <h1>loading...</h1>
  );
}

