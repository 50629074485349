import {each, filter, orderBy, values} from "lodash";

/**
 * Organiza os registros por data
 * @param entries
 * @returns {any[]}
 */
export const groupByDate = (entries) => {
    let entriesByDate = [];
    // Organiza os registros por data
    each(entries, (entry) => {
        if (!entriesByDate[entry.date]) {
            entriesByDate[entry.date] = [];
        }
        entriesByDate[entry.date].push(entry)
    });
    return values(entriesByDate);
}

/**
 * Agrupa os dados por Movimentação
 * @param entries
 * @returns {[]}
 */
export const groupByMovimentacao = (entries) => {
    var entriesByMovimentacao = [];
    let lastEntry = '';
    entries = orderBy(entries, (item) => {
        return item[0].date;
    })
    // Organiza os registros por data
    entries.forEach((currentEntry) => {
        const local_encontro = currentEntry[0]._source.local_encontro
        if (local_encontro !== lastEntry) {
            lastEntry = local_encontro;
            currentEntry[0].lastdate = currentEntry[0].date;
            entriesByMovimentacao.push(currentEntry[0]);
        } else {
            entriesByMovimentacao[entriesByMovimentacao.length - 1].lastdate = currentEntry[0].date;
        }
    });

    return entriesByMovimentacao;
}
/**
 * Define as cores do medicamento a cada variação na medicação
 * @param entries
 * @returns {[]}
 */
export const groupMedicamentosColor = (entries) => {
    let entriesByMovimentacao = [];
    let compare = null;
    const color = ['#C94EBF', '#822CF9', '#1681FF', '#6BCAFF'];
    let colorIndex = -1;
    entries = orderBy(entries, (item) => {
        return item[0].date;
    });
    // Adiciona o atributo compare com os dados que devem ser comparados
    entries = entries.map((item) => {
        // Ordena por nome
        item = orderBy(item, (source) => {
            return source._source.medicamento;
        }, 'ASC');

        // Adiciona o parâmetro de comparação
        return item.map((source) => {
            source.compare = {
                // dose: source._source.dose,
                // frequencia: source._source.frequencia,
                medicamento: source._source.medicamento,
                // unidade: source._source.unidade,
            }
            return source;
        })
    });
    // Atribui as cores de acordo com a variação
    entries.forEach((currentEntry) => {
        const currentCompare = currentEntry.map((item) => item.compare)
        if (!isEqual(currentCompare, compare)) {
            compare = currentCompare;
            colorIndex = colorIndex >= 3 ? 0 : colorIndex + 1;
        }
        currentEntry[0].color = color[colorIndex];
        entriesByMovimentacao.push(currentEntry);
    });

    return entriesByMovimentacao
}


/**
 * Insere a cor da movimentação no objeto
 * @param entries
 * @returns {[]}
 */
export const groupMovimentacaoColor = (entries) => {
    let entriesByMovimentacao = [];
    let lastEntry = '';
    const color = ['#ef63bb', '#c473fb ', '#f48ca8', '#4b4ebc'];
    let colorIndex = -1;
    entries = orderBy(entries, (item) => {
        return item[0].date;
    });
    entries.forEach((currentEntry) => {
        const local_encontro = currentEntry[0]._source.local_encontro
        if (local_encontro !== lastEntry) {
            lastEntry = local_encontro;
            colorIndex = colorIndex >= 3 ? 0 : colorIndex + 1;
        }
        currentEntry[0].color = color[colorIndex];
        entriesByMovimentacao.push(currentEntry[0]);
    });
    return entriesByMovimentacao;
}
/**
 * Monta um array de datas a partir de uma data inicial e final
 * @param startDate
 * @param endDate
 * @returns {[]}
 */
export const getDates = (startDate, endDate) => {
    let dates = [],
        currentDate = startDate,
        addDays = function (days) {
            let date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
    while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
    }
    return dates;
};


/**
 * Verifica se dois arrays de objetos são iguais
 * @param value
 * @param other
 * @returns {boolean}
 */
const isEqual = function (value, other) {
    // Get the value type
    var type = Object.prototype.toString.call(value);

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) return false;

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    // Compare the length of the length of the two items
    var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    // Compare two items
    var compare = function (item1, item2) {

        // Get the object type
        var itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
            if (!isEqual(item1, item2)) return false;
        }

        // Otherwise, do a simple comparison
        else {

            // If the two items are not the same type, return false
            if (itemType !== Object.prototype.toString.call(item2)) return false;

            // Else if it's a function, convert to a string and compare
            // Otherwise, just compare
            if (itemType === '[object Function]') {
                if (item1.toString() !== item2.toString()) return false;
            } else {
                if (item1 !== item2) return false;
            }
        }
    };

    // Compare properties
    if (type === '[object Array]') {
        for (var i = 0; i < valueLen; i++) {
            if (compare(value[i], other[i]) === false) return false;
        }
    } else {
        for (var key in value) {
            if (value.hasOwnProperty(key)) {
                if (compare(value[key], other[key]) === false) return false;
            }
        }
    }

    // If nothing failed, return true
    return true;
};

/**
 * Configurações de data do moment
 * @type {{dateTime: string, date: string, months: string[], shortMonths: string[], shortDays: string[], periods: [string, string], days: string[], time: string}}
 */
export const localized = {
    "dateTime": "%A, %e de %B de %Y. %X",
    "date": "%d/%m/%Y",
    "time": "%H:%M:%S",
    "periods": ["AM", "PM"],
    "days": ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
    "shortDays": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    "months": ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    "shortMonths": ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
};

export const getHistogramData = (all, currentDate) => {
  const subDays = currentDate.setDate(currentDate.getDate() - 10);
  const addDays = currentDate.setDate(currentDate.getDate() + 20);
  currentDate.setDate(currentDate.getDate() - 10);
  const graphData = filter(all, item => {
    return item.date.getTime() >= subDays && item.date.getTime() <= addDays // Busca dados entre as datas
  })

  return orderBy(graphData, (item) => {
    return item.date;
  });
}
