import React, { useMemo } from 'react';
import { DonutsChart } from '../DonutsChart';
import { getIndicatorValue } from '../../../../../utils/formatters/formatDashboardIndicators'
import { useSelectedCompetence } from '../../slices';

export const PatientsByLocal = ({ indicators }) => {
  const competence = useSelectedCompetence();
  const isLoading = false;
  const dataset = useMemo(
    () => [
      {
        label: 'UTI Adulto',
        value: getIndicatorValue(indicators, 'pacientes_por_local_13 UTI ADULTA', competence),
        color: '#81E6FC',
      },
      {
        label: 'Unidade 7',
        value: getIndicatorValue(indicators, 'pacientes_por_local_12 POSTO 7', competence),
        color: '#4EC6BA',
      },
      {
        label: 'Unidade 6',
        value: getIndicatorValue(indicators, 'pacientes_por_local_10 POSTO 6', competence),
        color: '#74bbe2',
      },
      {
        label: 'Unidade 2C',
        value: getIndicatorValue(indicators, 'pacientes_por_local_6 POSTO 2C', competence),
        color: '#9d84e8',
      },
      {
        label: 'Unidade 2B',
        value: getIndicatorValue(indicators, 'pacientes_por_local_5 POSTO 2B', competence),
        color: '#ff613d',
      },
    ],
    [competence, indicators]
  );

  return <DonutsChart isLoading={isLoading} dataset={dataset} />;
};
