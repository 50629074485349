import { useMemo } from 'react';
import { infectionTypesList, otherInfection } from '../../constants/infections';

/**
 * Retorna a lista de infecções encontradas em um objeto de suspeita
 *
 * @param {suspicion._source} suspicion._source Dados da suspeita
 */
export const getSuspicionInfections = (suspicion) => {
  const foundInfections = infectionTypesList
    .filter((infection) => suspicion[`pred_${infection}`] === 1)
    .map((infection) => {
      return {
        suspicion: suspicion.id,
        name: infection,
        probability: +suspicion[infection],
      };
    });

  if (foundInfections.length > 0) {
    return foundInfections;
  } else {
    return [
      {
        suspicion: suspicion.id,
        name: otherInfection,
        probability: +suspicion.max_prob,
      },
    ];
  }
};

/**
 * Memoiza e retorna a lista de infecções encontradas em um objeto de suspeita
 *
 * @param {suspicion._source} suspicion._source Dados da suspeita
 */
export const useSuspicionInfections = (suspicion) => {
  return useMemo(() => getSuspicionInfections(suspicion), [suspicion]);
};
