import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { formatNumber } from '../../../../../utils/formatters/formatNumber';
import { StatIcon } from '../StatIcon';
import { StatTitle } from '../StatTitle';
import { StatValue } from '../StatValue';

export const SuspicionStat = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    flex: '1 0 auto',
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
  value: {},
  icon: {
    flex: '0 0 auto',
    marginLeft: theme.spacing(2),
  },
}))(function StyledSuspicionStat(props) {
  const { classes, className, title, value, icon, color } = props;

  const valueLabel = useMemo(
    () => (typeof value === 'number' ? formatNumber(value) : value),
    [value]
  );

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.content}>
        <StatTitle className={classes.title}>{title}</StatTitle>
        <StatValue className={classes.value}>{valueLabel}</StatValue>
      </div>
      <StatIcon className={classes.icon} color={color}>
        {icon}
      </StatIcon>
    </div>
  );
});
