import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setOrderBy } from '../slice';

export const useSetPatientsOrderBy = () => {
  const dispatch = useDispatch();

  const handleSetPatientsOrderBy = useCallback(
    (orderBy) => dispatch(setOrderBy(orderBy)),
    [dispatch]
  );

  return handleSetPatientsOrderBy;
};
