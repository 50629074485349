import React, { useMemo } from 'react'
import { Box, LinearProgress, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom';

import { TimelineDrawerListItem } from '../../TimelineDrawerListItem';
import { useTimelineActiveIndicator } from '../../../slices';
import { formatAntimicrobialsLabel } from '../../../../../utils/formatters'
import { sortItems } from './util/sortItems'
import { useStyles } from './styles';
import { useGetPatientPrescriptionsQuery } from '../../../../../services/prescriptions/patientPrescriptions.service';
import moment from 'moment';

export const TimelineDrawerAntimicrobianos = ({ indicator, date }) => {
  const antimicrobials = useTimelineActiveIndicator()
  const items = useMemo(() => sortItems(antimicrobials), [antimicrobials])
  const classes = useStyles();
  const { id } = useParams();
  const { data: prescriptions, isFetching: prescriptionsLoading } = useGetPatientPrescriptionsQuery({
    prescriptionDate: date,
    patientId: id
  })

  const renderedJustifications = useMemo(() => {
    if (prescriptionsLoading) return <LinearProgress />;

    return (!!prescriptions && prescriptions.length > 0) && (
      <>
        <Typography className={classes.title}>Justificativas</Typography>
        {prescriptions?.map((prescription) => (
          <Box className={classes.container}>
            <Typography className={classes.cardTitle}>
              {moment(prescription.date_infection).format('hh:mm')}
              {' '}- Infecção: {prescription.infection_name}
            </Typography>
            <Typography className={classes.cardContent}>{prescription.clinical_history}</Typography>
          </Box>
        )).sort((a, b) => a.date_infection - b.date_infection)}
      </>
    )
  }, [prescriptionsLoading, prescriptions])

  return (
    <Box padding={2}>
      {items.map((item) => (
        <TimelineDrawerListItem
          key={item.id}
          label={formatAntimicrobialsLabel(item)}
        />
      ))}

      {renderedJustifications}
    </Box>
  )
}
