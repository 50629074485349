import React, {useState} from "react";
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './styles';
import TableBody from "@material-ui/core/TableBody";
import {Table, TableCell, TableContainer, TableRow} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
// import Paper from "@material-ui/core/Paper";

const Subtitles = (props) =>{
  const classes = useStyles();
  const {
    patientVr
  } = props;

  const [subtitleOpen, setSubtitleOpen] = useState(false);
  const [vrOpen, setVrOpen] = useState(false);

  const handleOpen = () => {
    setSubtitleOpen(true);
  };

  const handleClose = () => {
    setSubtitleOpen(false);
  };

  const handleVrOpen = () => {
    setVrOpen(true)
  }

  const handleVrClose = () => {
    setVrOpen(false)
  }

  return (
    <>
      <Button classes={{root: classes.dialogButton}} id="subtitleButton" onClick={handleOpen} variant="outlined" color="primary">
          Legenda
      </Button>
      <Button classes={{root: classes.vrDialogButton}} id="subtitleButton" onClick={handleVrOpen} variant="outlined" color="primary">
          Valores de referência
      </Button>
      <Dialog
        open={subtitleOpen}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Legenda
          <IconButton onClick={handleClose} style={{padding: '3px', borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.04)'}} aria-label="close">
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{root: classes.root}}>
          <DialogContentText id="scroll-dialog-description">
            <Grid item classes={{root: classes.itemContainer}}>
              <Typography classes={{root: classes.itemTitle}} variant="h6">Geral</Typography>
              <div>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="6" cy="6" r="5.5" fill="#FE3C4C" stroke="white"/>
                </svg>
                <Typography classes={{root: classes.itemText}} variant="body1">Bolinha: laudos realizados</Typography>
              </div>
            </Grid>
            <Grid item classes={{root: classes.itemContainer}}>
              <Typography classes={{root: classes.itemTitle}} variant="h6">Exames</Typography>
              <div>
                <svg style={{marginRight: '10px'}} width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.74421 16.4679L2.49614 16.0337L2.74421 16.4679L13.7286 10.191C14.7364 9.6152 14.7364 8.16215 13.7286 7.58631L2.74421 1.30949C1.74422 0.738067 0.5 1.46012 0.5 2.61185V15.1655C0.5 16.3172 1.74422 17.0393 2.74421 16.4679Z" fill="#FE3C4C" stroke="white"/>
                </svg>
                <Typography classes={{root: classes.itemText}} variant="body1">Setas vermelhas: indicam o período em que o paciente está com suspeita de infecção</Typography>
              </div>
              <div>
                <svg style={{marginRight: '10px'}} width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.74421 16.4679L2.49614 16.0337L2.74421 16.4679L13.7286 10.191C14.7364 9.6152 14.7364 8.16215 13.7286 7.58631L2.74421 1.30949C1.74422 0.738067 0.5 1.46012 0.5 2.61185V15.1655C0.5 16.3172 1.74422 17.0393 2.74421 16.4679Z" fill="#8F95A3" stroke="#8F95A3"/>
                </svg>
                <Typography classes={{root: classes.itemText}} variant="body1">Setas cinzas: indicam o período em que o paciente está com suspeita, mas com baixa probabilidade de infecção</Typography>
              </div>
            </Grid>
            <Grid item classes={{root: classes.itemContainer}}>
              <Typography classes={{root: classes.itemTitle}} variant="h6">Evolução</Typography>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <div style={{width: '100%'}}>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#8ACDCE" stroke="white"/>
                  </svg>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#46AACE" stroke="white"/>
                  </svg>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#3577AE" stroke="white"/>
                  </svg>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#24448E" stroke="white"/>
                  </svg>
                </div>
                <Typography classes={{root: classes.itemText}} variant="body1">Muda de cor quando o paciente muda de unidade</Typography>
              </div>
            </Grid>
            <Grid item classes={{root: classes.itemContainer}}>
              <Typography classes={{root: classes.itemTitle}} variant="h6">Exames</Typography>
              <div>
                <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="6" cy="6" r="6.5" fill="#880ABF" stroke="white"></circle>
                  <path class="polygon" d="M 0,0 5,-10 10,0 Z" stroke="white"  style={{ transform: 'translate(2.5px, 9px) scale(0.7)', strokeWidth: '1.5px', strokeLinejoin: 'bevel',
    strokeLinecap: 'bevel' }} fill="#9c27b0"></path>
                </svg>
                <Typography classes={{root: classes.itemText}} variant="body1">É exibido quando há HEMOCULTURA POSITIVA</Typography>
              </div>
              <div>
                <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="6" cy="6" r="6.5" fill="#FE3C4C" stroke="white"></circle>
                  <rect width="7" height="7" rx="0.5" fill="#FE3C4C" stroke="white" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 11.0136 5.9)"></rect>
                </svg>
                <Typography classes={{root: classes.itemText}} variant="body1">É exibido quando há UROCULTURA POSITIVA</Typography>
              </div>
              <div>
                <svg style={{transform: 'rotate(270deg)'}} width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.74421 16.4679L2.49614 16.0337L2.74421 16.4679L13.7286 10.191C14.7364 9.6152 14.7364 8.16215 13.7286 7.58631L2.74421 1.30949C1.74422 0.738067 0.5 1.46012 0.5 2.61185V15.1655C0.5 16.3172 1.74422 17.0393 2.74421 16.4679Z" fill="#880ABF" stroke="white"/>
                </svg>
                <Typography classes={{root: classes.itemText}} variant="body1">É exibido quando há HEMOCULTURA</Typography>
              </div>
              <div>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="-0.707107" width="9" height="9" rx="0.5" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 13.0136 6.61768)" fill="#FE3C4C" stroke="white"/>
                </svg>
                <Typography classes={{root: classes.itemText}} variant="body1">É exibido quando há UROCULTURA</Typography>
              </div>
            </Grid>
            <Grid item classes={{root: classes.itemContainer}}>
              <Typography classes={{root: classes.itemTitle}} variant="h6">Antimicrobianos</Typography>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <div style={{width: '100%'}}>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#EE8B97" stroke="white"/>
                  </svg>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#DB5087" stroke="white"/>
                  </svg>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#973490" stroke="white"/>
                  </svg>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#880ABF" stroke="white"/>
                  </svg>
                </div>
                <Typography classes={{root: classes.itemText}} variant="body1">Muda de cor quando muda prescrição de antibiótico. Qualquer mudança nos medicamentos ou dosagem é considerada.</Typography>
              </div>
            </Grid>
            <Grid item classes={{root: classes.itemContainer}}>
              <Typography classes={{root: classes.itemTitle}} variant="h6">Sinais vitais</Typography>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <div style={{width: '100%'}}>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#D6E4FF" stroke="white"/>
                  </svg>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#ADC9FF" stroke="white"/>
                  </svg>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#2D69E0" stroke="white"/>
                  </svg>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#2E55A3" stroke="white"/>
                  </svg>
                </div>
                <Typography classes={{root: classes.itemText}} variant="body1">Muda de cor quando tem sinais vitais alterados:</Typography>
                <ul style={{listStyle: 'none'}}>
                  <li><Typography classes={{root: classes.itemText}} variant="body1"> - FC 85-100;</Typography></li>
                  <li><Typography classes={{root: classes.itemText}} variant="body1"> - TEMP maior ou igual a 37,8 ou menor que 35;</Typography></li>
                  <li><Typography classes={{root: classes.itemText}} variant="body1"> - FC maior que 100 ou menor que 60;</Typography></li>
                  <li><Typography classes={{root: classes.itemText}} variant="body1"> - FR maior que 20;</Typography></li>
                  <li><Typography classes={{root: classes.itemText}} variant="body1"> - PAS menor que 100 ou PAD menor que 60;</Typography></li>
                  <li><Typography classes={{root: classes.itemText}} variant="body1"> - Oximetria menor que 95%.</Typography></li>
                </ul>
              </div>
            </Grid>
            <Grid item classes={{root: classes.itemContainer}}>
              <Typography classes={{root: classes.itemTitle}} variant="h6">Laudos</Typography>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <div style={{width: '100%'}}>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#F9D063" stroke="white"/>
                  </svg>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#F0A04B" stroke="white"/>
                  </svg>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#E77235" stroke="white"/>
                  </svg>
                  <svg style={{marginRight: '2px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5" fill="#C74E29" stroke="white"/>
                  </svg>
                </div>
                <Typography classes={{root: classes.itemText}} variant="body1">Pontua os laudos realizados.</Typography>
              </div>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={vrOpen}
        onClose={handleVrClose}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Valores de referência
          <IconButton onClick={handleVrClose} style={{padding: '3px', borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.04)'}} aria-label="close">
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{root: classes.root}}>
          <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead classes={{root: classes.tableHead}}>
                <TableRow>
                  <TableCell classes={{root: classes.tableStyle}}>
                    <Typography classes={{root: classes.itemText}} variant="body1">VR</Typography>
                  </TableCell>
                  <TableCell classes={{root: classes.tableStyle}}>
                    <Typography classes={{root: classes.itemText}} variant="body1">Valor</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody classes={{root: classes.tableStyle}}>
                {patientVr.map(vr => {
                  const { item_exame, resultado } = vr._source;
                  return (
                    <TableRow key={item_exame} classes={{root: classes.tableRow}}>
                      <TableCell classes={{root: classes.tableStyle}}>
                        <Typography style={{color: '#16B8A7'}} classes={{root: classes.itemText}} variant="body1">{item_exame}:</Typography>
                      </TableCell>
                      <TableCell classes={{root: classes.tableStyle}}>
                        <Typography  style={{color: '#8F95A3'}} classes={{root: classes.itemText}} variant="body2">{resultado}</Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    patientVr: state.patientVr.patientVr,
  }
}

export default connect(mapStateToProps)(withTheme(Subtitles));

