import React, { useMemo } from 'react';
import { VerticalBarChart } from '../VerticalBarChart';

import { useTheme } from '@material-ui/core';
import { StatStack } from '../../DashboardStat';
import { getIndicatorValue } from '../../../../../utils/formatters/formatDashboardIndicators'
import { useSelectedCompetence } from '../../slices';

const randomValue = () => Math.floor(Math.random() * 10 * (Math.random() * 20));

export const PatientsByAge = ({ indicators }) => {
  const competence = useSelectedCompetence();
  const theme = useTheme();
  const isLoading = false;
  const dataset = useMemo(
    () => [
      {
        label: 'HOMENS',
        color: theme.palette.timeline.secondary.blue,
        values: [
          {
            label: 'ATÉ 10',
            value: getIndicatorValue(indicators, 'pacientes_masculino_faixa_etaria_ate_10', competence),
          },
          {
            label: '11-20',
            value: getIndicatorValue(indicators, 'pacientes_masculino_faixa_etaria_ate_11_20', competence),
          },
          {
            label: '21-30',
            value: getIndicatorValue(indicators, 'pacientes_masculino_faixa_etaria_ate_21_30', competence),
          },
          {
            label: '31-40',
            value: getIndicatorValue(indicators, 'pacientes_masculino_faixa_etaria_ate_31_40', competence),
          },
          {
            label: '41-50',
            value: getIndicatorValue(indicators, 'pacientes_masculino_faixa_etaria_ate_41_50', competence),
          },
          {
            label: '51-60',
            value: getIndicatorValue(indicators, 'pacientes_masculino_faixa_etaria_ate_51_60', competence),
          },
          {
            label: '61-70',
            value: getIndicatorValue(indicators, 'pacientes_masculino_faixa_etaria_ate_61_70', competence),
          },
          {
            label: '71-80',
            value: getIndicatorValue(indicators, 'pacientes_masculino_faixa_etaria_ate_71_80', competence),
          },
          {
            label: '81-90',
            value: getIndicatorValue(indicators, 'pacientes_masculino_faixa_etaria_ate_81_90', competence),
          },
          {
            label: '91-100',
            value: getIndicatorValue(indicators, 'pacientes_masculino_faixa_etaria_ate_91_100', competence),
          },
          {
            label: '101+',
            value: getIndicatorValue(indicators, 'pacientes_masculino_faixa_etaria_ate_100_mais', competence),
          },
        ],
      },
      {
        label: 'MULHERES',
        color: theme.palette.timeline.secondary.purple,
        values: [
          {
            label: 'ATÉ 10',
            value: getIndicatorValue(indicators, 'pacientes_feminino_faixa_etaria_ate_10', competence),
          },
          {
            label: '11-20',
            value: getIndicatorValue(indicators, 'pacientes_feminino_faixa_etaria_ate_11_20', competence),
          },
          {
            label: '21-30',
            value: getIndicatorValue(indicators, 'pacientes_feminino_faixa_etaria_ate_21_30', competence),
          },
          {
            label: '31-40',
            value: getIndicatorValue(indicators, 'pacientes_feminino_faixa_etaria_ate_31_40', competence),
          },
          {
            label: '41-50',
            value: getIndicatorValue(indicators, 'pacientes_feminino_faixa_etaria_ate_41_50', competence),
          },
          {
            label: '51-60',
            value: getIndicatorValue(indicators, 'pacientes_feminino_faixa_etaria_ate_51_60', competence),
          },
          {
            label: '61-70',
            value: getIndicatorValue(indicators, 'pacientes_feminino_faixa_etaria_ate_61_70', competence),
          },
          {
            label: '71-80',
            value: getIndicatorValue(indicators, 'pacientes_feminino_faixa_etaria_ate_71_80', competence),
          },
          {
            label: '81-90',
            value: getIndicatorValue(indicators, 'pacientes_feminino_faixa_etaria_ate_81_900', competence),
          },
          {
            label: '91-100',
            value: getIndicatorValue(indicators, 'pacientes_feminino_faixa_etaria_ate_91_100', competence),
          },
          {
            label: '101+',
            value: getIndicatorValue(indicators, 'pacientes_feminino_faixa_etaria_ate_100_mais', competence),
          },
        ],
      },
    ],
    [theme, competence, indicators]
  );

  return (
    <>
      <StatStack
        items={[
          { label: 'Mediana Geral', value: getIndicatorValue(indicators, 'pacientes_geral_faixa_etaria_mediana', competence)},
          { label: 'Mediana Homens', value: getIndicatorValue(indicators, 'pacientes_masculino_faixa_etaria_mediana', competence)},
          { label: 'Mediana Mulheres', value: getIndicatorValue(indicators, 'pacientes_feminino_faixa_etaria_mediana', competence)},
        ]}
      />
      <VerticalBarChart
        isLoading={isLoading}
        dataset={dataset}
        aspectRatio={2.55}
        // height={200}
      />
    </>
  );
};
