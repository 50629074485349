import React, { useMemo, useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { ChatCommentBox } from './ChatCommentBox';
import { ChatCommentButton } from './ChatCommentButton';
import { Box } from '@material-ui/core';

import { CommentsContext } from './CommentsContext';

import { useTimelinePatientId } from '../../../pages/Timeline/slices';

import {
  useGetPatientCommentsQuery,
  useCreatePatientCommentMutation,
  useEditPatientCommentMutation,
  useDeletePatientCommentMutation,
} from '../../../services';
import { useToggleState } from '../../../utils/useToggleState';
import { useEditComment } from './useEditComment';

export const ChatComment = () => {
  const patientId = useTimelinePatientId();
  // Drawer state
  const { open, handleOpen, handleClose, handleToggle } = useToggleState();

  // Edit state
  const { edit, handleStartEdit, handleCancelEdit } = useEditComment();

  // Request da lista
  const {
    data,
    isLoading,
    isFetching,
    error: errorFetchingComments,
    refetch: refetchComments,
  } = useGetPatientCommentsQuery(patientId, {
    skip: !patientId,
  });
  const isFetchingComments = isLoading || isFetching;

  // Lista de comentários
  const comments = useMemo(() => {
    return (data || []).map((commentEntry) => ({
      id: commentEntry.id,
      author: commentEntry.madeBy,
      date: moment(commentEntry.updatedAt).format('DD/MM/YYYY, hh:mm'),
      content: commentEntry.comment,
    }));
  }, [data]);

  // Create
  const [
    createComment,
    { isLoading: isCreatingComment, error: errorCreatingComment },
  ] = useCreatePatientCommentMutation();
  const handleCreateComment = useCallback(
    (message) => createComment({ patientId, message }),
    [patientId, createComment]
  );

  // Edit
  const [
    editComment,
    { isLoading: isEditingComment, error: errorEditingComment },
  ] = useEditPatientCommentMutation();
  const handleEditComment = useCallback(
    (commentId, message) => editComment({ commentId, patientId, message }),
    [patientId, editComment]
  );

  // Delete
  const [
    deleteComment,
    { isLoading: isDeletingComment, error: errorDeletingComment },
  ] = useDeletePatientCommentMutation();
  const handleDeleteComment = useCallback(
    (commentId) => deleteComment(commentId),
    [patientId, deleteComment]
  );

  // Refresh na lista quando drawer abre
  useEffect(() => {
    if (open) refetchComments();
  }, [open]);

  useEffect(() => {
    if (comments) {
    }
  }, [comments]);

  return (
    <CommentsContext.Provider
      value={{
        // Chat
        open,
        handleOpen,
        handleClose,
        handleToggle,
        // Fetch
        comments,
        isFetchingComments,
        errorFetchingComments,
        // Create
        handleCreateComment,
        isCreatingComment,
        errorCreatingComment,
        // Edit
        edit,
        handleStartEdit,
        handleCancelEdit,

        handleEditComment,
        isEditingComment,
        errorEditingComment,
        // Delete
        handleDeleteComment,
        isDeletingComment,
        errorDeletingComment,
      }}
    >
      <Box position="fixed" bottom="16px" right="12px" zIndex={1400}>
        <ChatCommentBox />
        <ChatCommentButton />
      </Box>
    </CommentsContext.Provider>
  );
};
