import styled from "styled-components";

export const FilterSidebarContainer = styled.div`
    background: #F5F5F5;
    border-radius: 8px;
    padding: 16px;
    text-transform: uppercase;


`;
export const FilterSidebarHeader = styled.div`
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #383A3D;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const ClearAll = styled.div`
    font-family: 'DM Mono';
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #16B8A7;
    cursor: pointer
`;
export const FilterSidebarBody = styled.div`

`;


