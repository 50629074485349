import { withStyles, Box } from '@material-ui/core';

export const VerticalBarChartContainer = withStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
    paddingBottom: ({ aspectRatio }) =>
      aspectRatio ? `${100 / aspectRatio}%` : undefined,

    '& svg': {
      position: 'absolute',
      inset: 0,
    },

    '& path.domain': {
      stroke: '#d5d6db',
    },

    '& .tick': {
      fontFamily: 'DM Mono',
      fontSize: '10px',
      color: '#383A3D',
    },

    '& [data-entries]': {
      '& rect': {
        outline: '2px solid white',
      },

      '& rect, & circle': {
        cursor: 'pointer',
        transition: 'opacity ease .1s',
        '&:hover': {
          opacity: 0.8,
        },
      },
    },

    '& .legend-container': {
      textAlign: 'center',
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '100%',
      margin: '0 -4px',
    },

    '& .legend': {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '0 4px',
    },

    '& .bullet': {
      display: 'block',
      width: '14px',
      height: '14px',
      borderRadius: '4px',
      marginRight: '10px',
    },
  },
}))(Box);
