import React from 'react';
import { useTheme } from '@material-ui/core';
import { formatUnitsOfMeasureLabel } from '../../../../../utils/formatters';
import { useCallback } from 'react';
import { VitalSignButton } from './VitalSignButton';

export const VitalSignItem = (props) => {
  const { item, onClick } = props;

  const handleClick = useCallback(() => {
    if (onClick) onClick(item);
  }, [onClick, item]);

  return (
    <VitalSignButton
      onClick={handleClick}
      signal={item.signal}
      measurement={formatUnitsOfMeasureLabel(item)}
    />
  );
};
