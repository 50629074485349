import React, {useState, useImperativeHandle, forwardRef, useEffect} from 'react';
import {array, func} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import PatientCard from '../../molecules/PatientCard/index';
import {
  PatientCardListContainer,
  PatientCardListBody,
  PatientCardListHeader,
} from './styles';
import { sortedUniq } from 'lodash';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import SearchTextField from '../../atoms/SearchTextField';
import { useGetSettingsQuery } from '../../../services';

const PatientCardList = forwardRef((props, ref) => {
  const {
    patientList,
    handleFilterNome,
    patientSuspicions,
    patientSuspicionsLoading,
    patientSuspicionsFetching,
    refetchPatientSuspicions,
    setFilterParams,
    inputNomePaciente,
    setInputNomePaciente,
    handleLimparNome
  } = props;
  const idList = sortedUniq(patientSuspicions?.results.map((patient) => parseInt(patient.paciente_id)));
  const [page, setPage] = useState(100);
  const {
    data: settings,
  } = useGetSettingsQuery('')
  const handleBuscarPaciente = (e) => {
    handleFilterNome(inputNomePaciente);
    e.preventDefault()
  }
  const increment = 10;
  const getLoadMore = () => {
    if (patientSuspicions?._metadata.pageCount > patientSuspicions?._metadata.page) {
      return (
        <Grid item xs={12} key="loadmore-button">
          <Box display="flex" justifyContent="center" m={1} p={1}>
            <Button color="primary" onClick={handleClickLoadMore}>
              Carregar mais
            </Button>
          </Box>
        </Grid>
      );
    }
  };
  useImperativeHandle(ref, () => ({
    resetPagination() {
      setPage(100);
    },
  }));

  const handleClickLoadMore = () => {
    setFilterParams(prevState => ({ ...prevState, itensPerPage: prevState.itensPerPage + increment }))
  }
  if(!settings || patientSuspicionsLoading || !patientSuspicions){
    return 'carregando...'
  }
  return (
    <PatientCardListContainer>
      <form noValidate autoComplete="off" onSubmit={handleBuscarPaciente}>
        <SearchTextField label="Busca por paciente ou prontuário"
                   value={inputNomePaciente}
                   onChange={e => setInputNomePaciente(e.target.value)}
                   fullWidth={true} clearInput={handleLimparNome}
        />
      </form>
      <PatientCardListHeader
        data-testid="patient-list__count"
        data-count={patientList.length}
      >
        Exibindo {patientSuspicions?._metadata?.totalCount} registros
      </PatientCardListHeader>
      <PatientCardListBody>
        <Grid container spacing={2}>
          {patientSuspicions?.results
            .map((patient, index) => (
              <Grid item xs={12} key={patient.id}>
                <PatientCard
                  url={`paciente/${patient.paciente_id}`}
                  loading={patientSuspicionsLoading}
                  idList={idList}
                  patient={patient}
                  settings={settings}
                  patientSuspicionsFetching={patientSuspicionsFetching}
                />
              </Grid>
            ))}
          {getLoadMore()}
        </Grid>
      </PatientCardListBody>
    </PatientCardListContainer>
  );
});

PatientCardList.props = {
  patientList: array,
  handleFilterNome: func,
};

export default PatientCardList;
