import React, { useMemo } from 'react';
import { ButtonBase, withStyles, alpha } from '@material-ui/core';
import clsx from 'clsx';

export const StyledChip = withStyles((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    verticalAlign: 'middle',
    borderRadius: '10000em',
    transition: 'color .2s ease 0s',
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: '2px 7px',
    '& svg': {
      color: 'inherit',
      display: 'block',
    },
  },
  outlined: {
    border: '1px solid transparent',
  },
  // Size variants
  small: {
    fontSize: '11px',
    lineHeight: '15px',
  },
  medium: {
    fontSize: '12px',
    lineHeight: '17px',
  },
  large: {
    fontSize: '13px',
    lineHeight: '18px',
  },
  extralarge: {
    fontSize: '13px',
    lineHeight: '18px',
    padding: '7px 12px'
  },
  // Color variants
  gray: {
    color: theme.palette.neutral.black['72'],
    backgroundColor: theme.palette.neutral.gray.xxlt,
    borderColor: theme.palette.neutral.black['16'],
    '&:hover, &:active': {
      backgroundColor: theme.palette.neutral.gray.xlt,
    },
  },
  white: {
    color: theme.palette.neutral.black['72'],
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.neutral.black['16'],
    '&:hover, &:active': {
      backgroundColor: theme.palette.neutral.gray.xlt,
    },
  },
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderColor: alpha(theme.palette.primary.main, 0.2),
    '&:hover, &:active': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
  },
  success: {
    color: theme.palette.timeline.feedback.success,
    backgroundColor: alpha(theme.palette.timeline.feedback.success, 0.1),
    borderColor: alpha(theme.palette.timeline.feedback.success, 0.2),
    '&:hover, &:active': {
      backgroundColor: alpha(theme.palette.timeline.feedback.success, 0.2),
    },
  },
  warning: {
    color: theme.palette.warning.main,
    backgroundColor: alpha(theme.palette.warning.main, 0.1),
    borderColor: alpha(theme.palette.warning.main, 0.2),
    '&:hover, &:active': {
      backgroundColor: alpha(theme.palette.warning.main, 0.2),
    },
  },
  danger: {
    color: theme.palette.timeline.feedback.danger,
    backgroundColor: alpha(theme.palette.timeline.feedback.danger, 0.1),
    borderColor: alpha(theme.palette.timeline.feedback.danger, 0.2),
    '&:hover, &:active': {
      backgroundColor: alpha(theme.palette.timeline.feedback.danger, 0.2),
    },
  },
  high: {
    color: alpha(theme.palette.neutral.black['100'], 0.88),
    backgroundColor: alpha(theme.palette.timeline.probability['400'], 0.2),
    borderColor: alpha(theme.palette.timeline.probability['400'], 0.3),
    '&:hover, &:active': {
      backgroundColor: alpha(theme.palette.timeline.probability['400'], 0.3),
    },
  },
  med: {
    color: alpha(theme.palette.neutral.black['100'], 0.88),
    backgroundColor: alpha(theme.palette.timeline.probability['200'], 0.2),
    borderColor: alpha(theme.palette.timeline.probability['200'], 0.3),
    '&:hover, &:active': {
      backgroundColor: alpha(theme.palette.timeline.probability['200'], 0.3),
    },
  },
  low: {
    color: alpha(theme.palette.neutral.black['100'], 0.88),
    backgroundColor: alpha(theme.palette.timeline.probability['000'], 0.2),
    borderColor: alpha(theme.palette.timeline.probability['000'], 0.3),
    '&:hover, &:active': {
      backgroundColor: alpha(theme.palette.timeline.probability['000'], 0.3),
    },
  },
}))(
  React.forwardRef(function StyledButtonBase(props, ref) {
    const {
      children,
      variant,
      size,
      outlined: isOutlined,
      classes,
      ...buttonBaseProps
    } = props;
    const {
      outlined,
      // size
      small,
      medium,
      large,
      extralarge,
      // color
      gray,
      white,
      primary,
      success,
      warning,
      danger,
      high,
      med,
      low,
      ...buttonBaseClasses
    } = classes;

    return (
      <ButtonBase
        {...buttonBaseProps}
        ref={ref}
        classes={{
          ...buttonBaseClasses,
          root: clsx([
            buttonBaseClasses.root,
            classes[variant],
            classes[size],
            isOutlined ? classes['outlined'] : undefined,
          ]),
        }}
      >
        {children}
      </ButtonBase>
    );
  })
);
