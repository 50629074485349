import React, { useMemo, useCallback } from 'react';
import { min } from 'lodash';
import { ExtractedCriteriaItemButton } from './ExtractedCriteriaItemButton';

export const ExtractedCriteriaItem = (props) => {
  const { criteria, value, onClick } = props;

  const minValue = useMemo(() => min(value), [value]);

  const handleClick = useCallback(() => {
    if (onClick) onClick(criteria);
  }, [criteria, onClick]);

  return (
    <ExtractedCriteriaItemButton onClick={handleClick}>
      {criteria} - {minValue}
      {criteria === 'fio2' && '%'}
    </ExtractedCriteriaItemButton>
  );
};
