import {actionTypes} from "../actions/TimelineAction";
const initialState = {
  patientFacts: [],
  patientInfections: [],
  loading: true
}
export const timelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PATIENT_FACTS:
      return {
        ...state,
        patientFacts: action.payload
      };

    case actionTypes.GET_PATIENT_INFECTION_TIMELINE:
      return {
        ...state,
        patientInfections: action.payload
      };

    case actionTypes.SET_TIMELINE_LOADING:
      return {
        ...state,
        timelineLoading: action.payload
      };

    case actionTypes.SET_TIMELINE_READY:
      return {
        ...state,
        timelineReady: action.payload
      };

    default:
      return state;
  }
}
