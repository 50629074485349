import React, {useEffect, useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import { any, array, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { Title, Header } from "./styles";
import PacientesTemplate from '../../templates/Pacientes';
import DateRangeInput from "../../components/molecules/DateRangeInput/index";
import TopFilter from "../../components/organisms/TopFilter";
import PatientCardList from "../../components/organisms/PatientCardList";
import { FilterSidebar } from "../../components/organisms/FilterSidebar";
import { FilterGroup } from "../../templates/Pacientes/styles";
import { getPendingAssessments } from '../../actions/PendingAssessmentsActions';
import {
  setProbabilityFilterAction,
  setInfectionFilterAction,
  setOnlyInconclusiveFilterAction,
  setOrderByFilterAction,
  resetFiltersAction,
  setPendingAssessmentsPeriodDate,
} from '../../actions/PendingAssessmentsFiltersActions';
import EvaluationTooltip from "../../components/atoms/EvaluationTooltip";
import {setDashboardPeriodDate} from "../../actions/DashboardPeriodFilterActions";
import { useGetInfectionTypesQuery } from '../../services';
import { useGetPatientSuspicionsQuery } from '../../services/patientSuspicions/patientSuspicions.service'

const Pacientes = (props) => {
  const {
    // infectionTypes,
    patientList,
    initialDate,
    finalDate,
    highProbability,
    mediumProbability,
    lowProbability,
    onlyInconclusive,
    orderByField,
    getPendingAssessmentList,
    updateProbabilityFilter,
    updateInfectionFilter,
    updatedOnlyInconclusiveFilter,
    updateOrderByFilter,
    resetFilters,
    infections,
    setFinalDate,
    setInitialDate,
    globalStateOrderBy,
    globalStateInfections,
    globalStateOnlyInconclusive
  } = props;


  const [inputNomePaciente, setInputNomePaciente] = useState('');
  const [filterParams, setFilterParams] = useState({
    itensPerPage: 10,
    page: 1,
    name: '',
    infectionType: '',
    probability: {},
    startDate: initialDate,
    endDate: finalDate,
    orderBy: orderByField
  });

  const { data: infectionTypes } = useGetInfectionTypesQuery();
  const {
    data: patientSuspicions,
    isLoading: patientSuspicionsLoading,
    isFetching: patientSuspicionsFetching,
    refetch: refetchPatientSuspicions,
  } = useGetPatientSuspicionsQuery(filterParams);

  const patientCardRef = useRef();
  const EVALUATION_RULES = [
    'Não possui nenhuma avaliação;',
    'Robô ISA identificou uma ou mais suspeitas, mas todas elas ainda não foram avaliadas;',
    // 'A mesma já estiver avaliada. Será necessário excluir a avaliação para depois avaliar;'
  ];
  useEffect(() => {
    patientCardRef.current.resetPagination();
    setFilterParams(prevState => ({
      ...prevState,
      infectionType: infections,
      startDate: initialDate,
      endDate: finalDate,
      orderBy: orderByField,
      probability: {
        highProbability,
        mediumProbability,
        lowProbability
      }
    }));
  }, [
    initialDate,
    finalDate,
    highProbability,
    mediumProbability,
    lowProbability,
    onlyInconclusive,
    orderByField,
    infections,
  ]);

  const handleFilterNome = () => {
    setFilterParams(prevState => ({
      ...prevState,
      name: inputNomePaciente
    }));
  }

  const handleLimparNome = () => {
    setFilterParams(prevState => ({
      ...prevState,
      name: ''
    }));

    setInputNomePaciente('');
  }

  return (
    <PacientesTemplate>
      <Header>
        <Grid container alignItems="center">
          <Grid item sm={12} md={8}>
            <Title>
              Avaliações pendentes
            </Title>
            <EvaluationTooltip ruleTitle="Pendente" rules={EVALUATION_RULES}/>
          </Grid>
          <Grid item xs={12} md={4}>
            <DateRangeInput
              initialDate={[initialDate, setInitialDate]}
              finalDate={[finalDate, setFinalDate]}
              />
          </Grid>
        </Grid>
      </Header>
      <FilterGroup>
        <TopFilter
          sendOnlyInconclusiveToParent={updatedOnlyInconclusiveFilter}
          sendInfectionsToParent={updateInfectionFilter}
          sendOrderByToParent={updateOrderByFilter}
          infectionTypes={infectionTypes}
          onlyInconclusive={globalStateOnlyInconclusive}
          orderBy={globalStateOrderBy}
          infections={globalStateInfections}
        />
      </FilterGroup>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <PatientCardList
            ref={patientCardRef}
            patientList={patientList}
            patientSuspicions={patientSuspicions}
            patientSuspicionsLoading={patientSuspicionsLoading}
            refetchPatientSuspicions={refetchPatientSuspicions}
            patientSuspicionsFetching={patientSuspicionsFetching}
            handleFilterNome={handleFilterNome}
            setFilterParams={setFilterParams}
            inputNomePaciente={inputNomePaciente}
            setInputNomePaciente={setInputNomePaciente}
            handleLimparNome={handleLimparNome}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FilterSidebar
            notifyParentOnReset={resetFilters}
            notifyParentOnProbabilityChange={updateProbabilityFilter}
          />
        </Grid>
      </Grid>
    </PacientesTemplate>
  )
};

Pacientes.propTypes = {
  infectionTypes: array,
  patientList: array,
  initialDate: any,
  finalDate: any,
  highProbability: bool,
  mediumProbability: bool,
  lowProbability: bool,
  onlyInconclusive: bool,
  getPendingAssessmentList: func,
  updatePendingAssessmentsInitialDate: func,
  updatePendingAssessmentsFinalDate: func,
  updateProbabilityFilter: func,
  updateInfectionFilter: func,
  updatedOnlyInconclusiveFilter: func,
  updateOrderByFilter: func,
  resetFilters: func,
  infections: array
};

const mapStateToProps = (state) => ({
  infectionTypes: state.infectionTypes,
  // TODO remover reducers não utilizadas
  // initialDate: state.periods.dashboard.initialDate,
  patientList: state.pendingAssessments.list,
  initialDate: state.periods.dashboard.initialDate,
  finalDate: state.periods.dashboard.finalDate,
  highProbability: state.pendingAssessmentsFilter.highProbability,
  mediumProbability: state.pendingAssessmentsFilter.mediumProbability,
  lowProbability: state.pendingAssessmentsFilter.lowProbability,
  onlyInconclusive: state.pendingAssessmentsFilter.onlyInconclusive,
  orderByField: state.pendingAssessmentsFilter.orderByField,
  infections: state.pendingAssessmentsFilter.infections,
  globalStateInfections: state.pendingAssessmentsFilter.infections,
  globalStateOnlyInconclusive: state.pendingAssessmentsFilter.onlyInconclusive,
  globalStateOrderBy: state.pendingAssessmentsFilter.orderByField
});

const mapDispatchToProps = (dispatch) => ({
  getPendingAssessmentList: (filterParams) =>
    dispatch(getPendingAssessments(filterParams)),

  setInitialDate: (date, initial) =>
    dispatch(setDashboardPeriodDate(date, initial)),
  setFinalDate: (date, initial = false) =>
    dispatch(setDashboardPeriodDate(date, initial)),
  // TODO remover actions não utilizadas
  updatePendingAssessmentsInitialDate: (date) =>
    dispatch(setPendingAssessmentsPeriodDate(date)),
  // TODO remover actions não utilizadas
  updatePendingAssessmentsFinalDate: (date) =>
    dispatch(setPendingAssessmentsPeriodDate(date, false)),

  updateProbabilityFilter: (probabilities) =>
    dispatch(setProbabilityFilterAction(probabilities)),

  updateInfectionFilter: (infections) =>
    dispatch(setInfectionFilterAction(infections)),

  updatedOnlyInconclusiveFilter: (onlyInconclusive) =>
    dispatch(setOnlyInconclusiveFilterAction(onlyInconclusive)),

  updateOrderByFilter: (fieldName) =>
    dispatch(setOrderByFilterAction(fieldName)),

  resetFilters: () =>
    dispatch(resetFiltersAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(Pacientes)
