import React from 'react';
import {HorizontalChipsSuccess, MyChip} from './style';
import DoneAll from '@material-ui/icons/DoneAll';
import {string} from 'prop-types';

const ChipsSuccess = (props) => {
  const {
    title = ''
  } = props;

  return (
    <HorizontalChipsSuccess>
      <MyChip
        label={title}
        variant="outlined"
        className="success"
        icon={<DoneAll/>}
      />
    </HorizontalChipsSuccess>
  );
};

ChipsSuccess.propTypes = {
  title: string.isRequired,
};

export default ChipsSuccess;
