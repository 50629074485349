import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

export const StatTitle = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '11px',
    lineHeight: '15px',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: theme.palette.neutral.black['72'],
  },
}))(function StyledStatTitle({ classes, className, children }) {
  return <div className={clsx(classes.root, className)}>{children}</div>;
});
