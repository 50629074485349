import { assessmentStatusesEnum } from "../../constants/assessments"

export const assessmentsStatusesLabelsEnum = {
  [assessmentStatusesEnum.positiva]: 'Confirmada',
  [assessmentStatusesEnum.negativa]: 'Descartada',
  [assessmentStatusesEnum.inconclusiva]: 'Inconclusiva',
}

/**
 * @param {string} assessmentStatus Status da avaliação
 * @returns Label formatada
 * 
 * @example
 * formatAssessmentStatusLabel('positiva'); // Confirmada
 * formatAssessmentStatusLabel('negativa'); // Descartada
 * formatAssessmentStatusLabel('inconclusiva'); // Inconclusiva
 */
export const formatAssessmentStatusLabel = (assessmentStatus) => {
  if( typeof assessmentStatus !== 'string' ) return '';
  return assessmentsStatusesLabelsEnum[assessmentStatus] || assessmentStatus;
}
