import { createApi } from '@reduxjs/toolkit/query/react'
import {  baseQueryWithReauth } from '../baseQuery'
import { transformResponseHits } from '../common/utils/transformResponse'
import { allSuspicionsRequestBody, suspicionsRequestBody } from './suspicions.body'

export const suspicionsApi = createApi({
  reducerPath: 'suspicionsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({

    getSuspicions: build.query({
      query: (patientId) => ({
        url: `/elk/`,
        method: 'POST',
        body: {
          path: `suspeitas/_search?filter_path=_scroll_id,hits.hits._source,hits.total&scroll=1m`,
          body: suspicionsRequestBody(patientId)
        },
      }),
      transformResponse: transformResponseHits,
    }),
    getAllSuspicions: build.query({
      query: (filter) => ({
        url: `/elk/`,
        method: 'POST',
        body: {
          path: `suspeitas/_search?filter_path=_scroll_id,hits.hits._source,hits.total&scroll=1m`,
          body: allSuspicionsRequestBody(filter)
        },
      }),
      transformResponse: transformResponseHits,
    }),

    
  }),
})

export const { 
  useGetSuspicionsQuery,
  useGetAllSuspicionsQuery
} = suspicionsApi
