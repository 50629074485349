import styled from 'styled-components'

export const Header = styled.header`

`
export const Body = styled.div`
  color: #16B8A7;
  font-family: "DM Mono";
  font-weight: bold;
  text-align: center;
  font-size: 70px;
  margin-top: 30vh;
`

export const Warning = styled.div`
  color: #3F8F87;
  text-align: center;
`

export const Title = styled.h2`
  display: inline-block;
  margin-right: 8px;
    @media (max-width: 960px) {
      margin-bottom: 20px;
    }
`
