import moment from 'moment';

export const actionTypes = {
  SET_ASSESSMENTS_HISTORY_DATES: 'SET_ASSESSMENTS_HISTORY_DATES',
  SET_ASSESSMENTS_HISTORY_INITIAL_DATE: 'SET_ASSESSMENTS_HISTORY_INITIAL_DATE',
  SET_ASSESSMENTS_HISTORY_FINAL_DATE: 'SET_ASSESSMENTS_HISTORY_FINAL_DATE',
  SET_ASSESSMENTS_HISTORY_ORDERBY_FILTER: 'SET_ASSESSMENTS_HISTORY_ORDERBY_FILTER',
  SET_ASSESSMENTS_HISTORY_INFECTION_FILTER: 'SET_ASSESSMENTS_HISTORY_INFECTION_FILTER',
  SET_STATUS: 'SET_STATUS',
  RESET_ASSESSMENTS_HISTORY_FILTERS: 'RESET_ASSESSMENTS_HISTORY_FILTERS',
  RESET_STATUS: 'RESET_STATUS',
  RESET_DATES: 'RESET_DATES'
};

/**
 * Assessments history initial date action
 *
 * @param initialDate
 * @returns {{type: string, initialDate: *}}
 */
const setAssessmentHistoryInitialDateAction = initialDate => ({
  type: actionTypes.SET_ASSESSMENTS_HISTORY_INITIAL_DATE,
  initialDate
});

/**
 * Assessments history final date action
 *
 * @param finalDate
 * @returns {{finalDate: *, type: string}}
 */
const setAssessmentHistoryFinalDateAction = finalDate => ({
  type: actionTypes.SET_ASSESSMENTS_HISTORY_FINAL_DATE,
  finalDate
});

/**
 * Assessments history dates action creator
 *
 * @param date
 * @param initial
 * @returns {Function}
 */
export const setAssessmentsHistoryPeriodDate = (date, initial = true) => dispatch => {
  if(!moment(date).isValid()) {
    return;
  }

  if(initial) {
    dispatch(setAssessmentHistoryInitialDateAction(moment(date)));
  } else {
    dispatch(setAssessmentHistoryFinalDateAction(moment(date)))
  }
};

/**
 * Infection filter action
 *
 * @param infections
 * @returns {{infections: Array, type: *}}
 */
export const setInfectionFilterAction = (infections = []) => ({
  type: actionTypes.SET_ASSESSMENTS_HISTORY_INFECTION_FILTER,
  infections
});


/**
 * Order by filter action creator
 *
 * @param orderByField
 * @returns {{type: string, status: boolean}}
 */
export const setOrderByFilterAction = (orderByField = '') => ({
  type: actionTypes.SET_ASSESSMENTS_HISTORY_ORDERBY_FILTER,
  orderByField
});


/**
 * Reset filter action creator
 *
 * @returns {{type: string}}
 */
export const resetFiltersAction = () => ({
  type: actionTypes.RESET_ASSESSMENTS_HISTORY_FILTERS,
});


/**
 * Reset date filter action creator
 *
 * @returns {{type: string}}
 */
export const resetDateFilterAction = () => ({
  type: actionTypes.RESET_DATES
});


/**
 * Reset status filter action
 *
 * @returns {{type: string}}
 */
export const resetStatusFilterAction = () => ({
  type: actionTypes.RESET_STATUS
});


/**
 * Status filter action creator
 *
 * @returns {{type: string}}
 */
export const setStatusFilterAction = (
  {positive = true,
    negative = true,
    unnotified = false}) => ({
  type: actionTypes.SET_STATUS,
  positive,
  negative,
  unnotified
});
