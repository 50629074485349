export const setInfectionParamsRequestBody = (infParams) => {
  const infectionNames = Object.keys(infParams);
  const infections = {
    metas: []
  };

  infectionNames.forEach((infectionName ) => {
    infParams[infectionName].ranges.forEach((range, index) => {

      switch(index) {
        case 0:
          infections.metas.push({
            meta: `prob-alta-${infectionName}`, value: range.to
          });
          break;
        case 1:
          infections.metas.push({
            meta: `prob-media-${infectionName}`, value: range.to
          });
          break;
        case 2:
          infections.metas.push({
            meta: `prob-baixa-${infectionName}`, value: range.to
          });
          break;
        default:
          infections.metas.push({
            meta: `prob-baixa-${infectionName}`, value: range.to
          });
        break;
      }
    });
  });
  return infections;
};

export const getInfectionParamsRequestBody = (infParams) => {
  const infections = {
    metas: []
  };

  infParams.forEach((infectionName) => {
    const { metas } = infections;
    metas.push(
      `prob-baixa-${infectionName}`,
      `prob-media-${infectionName}`,
      `prob-alta-${infectionName}`
    );
  });

  infections.metas.push(
    `prob-baixa-geral`,
    `prob-media-geral`,
    `prob-alta-geral`
  );
  return infections;
};
