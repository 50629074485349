import { setInfectionParamsRequestBody, getInfectionParamsRequestBody} from "./query";
import {sendRequest} from "../api";
import {keycloakConnect} from "../../../keycloak";

const ENDPOINTS = {
  getInfectionParams: '/config/dados',
  setInfectionParams: '/config/registrar'
};

/**
 * Sends the infection params settings
 *
 * @returns {Promise<void>}
 * @param infectionParams
 */
export async function setInfectionParams(infectionParams) {
  const { token } = keycloakConnect;
  return await sendRequest({
    endpoint: ENDPOINTS.setInfectionParams,
    data: setInfectionParamsRequestBody(infectionParams),
    headers: {
      'Authorization': `Bearer ${token}`
    },
    assessment: false,
    config: true
  });
}

/**
 * Gets the infection params settings
 *
 * @returns {Promise<void>}
 * @param infectionParams
 */
export async function getInfectionParams(infectionParams) {
  const { token } = keycloakConnect;
  return await sendRequest({
    endpoint: ENDPOINTS.getInfectionParams,
    data: getInfectionParamsRequestBody(infectionParams),
    headers: {
      'Authorization': `Bearer ${token}`
    },
    assessment: false,
    config: true
  });
}

