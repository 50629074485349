import React from 'react';
import { SettingsTemplate, Container } from './styles';

export default (props) =>
    <SettingsTemplate>
        <Container>
            {props.children}
        </Container>
    </SettingsTemplate>

