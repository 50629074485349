import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setSearchInstance } from '../slice';

export const useSetTimelineSearchInstance = () => {
  const dispatch = useDispatch();

  return useCallback((instance) => dispatch(setSearchInstance(instance)), [
    dispatch,
  ]);
};
