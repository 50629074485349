import React, { useEffect, useMemo, useState } from 'react'
import styles, { StyledTable, StyledTableCell, StyledTableRow } from '../styles';
import { connect } from 'react-redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { upperFirst, isEmpty, map, each, find, cloneDeep, capitalize, lowerCase, orderBy } from 'lodash';
import { useGetExportPatientEvaluationsQuery } from '../../../services/evaluations/evaluations.service';
import moment from 'moment';
import ChipsError from '../../../components/molecules/HorizontalChipsError';
import ChipsSuccess from '../../../components/molecules/HorizontalChipsSuccess';
import { useHistory } from 'react-router-dom';
import { Box, Button, Grid, TableBody, TableHead } from '@material-ui/core';
import { ChipsBasic } from '../../../components/molecules/ChipsBasic/ChipsBasic';
import CsvDownloader from 'react-csv-downloader';
import { HIDE_FROM } from '../../hideAnonymousEvaluation';

const HistoricoTableRow = (props) => {
  const {
    filterParams,
    setFilterParams,
    idList,
    patientEvaluations,
    patientEvaluationsLoading,
  } = props;
  const INCREMENT = 10;

  const {
    data: exportPatientEvaluationsData,
    isFetching: isExportPatientEvaluationsLoading
  } = useGetExportPatientEvaluationsQuery(filterParams);

  const [userName, setUserName] = useState(localStorage.getItem('username'));

  const classes = styles();
  const history = useHistory();

  const getAssessmentsChips = (assessment) => {
    const chips = [];

    const { status, tipoInfeccao } = assessment;

    if (status === 'positiva' && tipoInfeccao !== 'comunitaria') {
      chips.push(
        <ChipsSuccess
          key={tipoInfeccao}
          title={tipoInfeccao.toUpperCase()}
        />
      );
    } else if(tipoInfeccao === 'comunitaria') { // Infecção comunitária tem uma trativa específica
      chips.push(
        <ChipsError key={tipoInfeccao} title={tipoInfeccao.toUpperCase()} community />
      );
    } else if (status === 'negativa') {
      chips.push(
        <ChipsError key={tipoInfeccao} title={tipoInfeccao.toUpperCase()} />
      );
    }if (status === 'inconclusiva') {
      chips.push(
        <ChipsBasic key={tipoInfeccao} title={tipoInfeccao.toUpperCase()} />
      );
    }

    return chips;
  };

  const handleClickLoadMore = () => {
    setFilterParams(prevState => ({ ...prevState, itensPerPage: prevState.itensPerPage + INCREMENT }))
  }
  const handleCardClick = (url) => {
    if (url)
      history.push({
        pathname: 'paciente/' + url,
        state: { idList: idList, backTo: '/historico' },
      });
  };
  const getLoadMore = () => {
    const showLoadMore = patientEvaluations?._metadata.totalCount > patientEvaluations?._metadata.itemsPerPage
    return showLoadMore && (
      <StyledTableRow
        key={`patientAssessment.loadmore`}
      >
        <StyledTableCell colSpan={7}>
          <Button color="primary" fullWidth={true} onClick={handleClickLoadMore}>{!patientEvaluationsLoading ? 'Carregar mais' : 'Carregando'}</Button>
        </StyledTableCell>
      </StyledTableRow>
    )
  }
  const assessmentsHistoryCSV = useMemo(()=>{
    if(!exportPatientEvaluationsData) return
    let assessments = cloneDeep(exportPatientEvaluationsData?.results).map((item)=>{
      if(item.localInfeccao === 'undefined' || item.localInfeccao === ''){
        item.localInfeccao = '-'
      }
      item.relacionado ? item.relacionado = 'Sim' : item.relacionado = 'Não'
      item.relacionadoCateterSonda ? item.relacionadoCateterSonda = 'Sim' : item.relacionadoCateterSonda = 'Não'
      item.paciente_id = item.paciente?.id || '';
      item.nome = item.paciente?.name || '';
      item.dtInfeccao = moment(item.dtInfeccao).format('DD/MM/YYYY')
      return item
    });

    if(assessments.length > 0){
      const cols = [
        {
          id: 'nome',
          displayName: 'Nome',
        },
        {
          id: 'id',
          displayName: 'Número do Atendimento',
        },
        {
          id: 'paciente_id',
          displayName: 'Prontuário',
        },
        {
          id: 'dtInfeccao',
          displayName: 'Data da infecção',
        },
        {
          id: 'tipoInfeccao',
          displayName: 'Infecção',
        },
        {
          id: 'localInfeccao',
          displayName: 'Setor/Unidade',
        },
        {
          id: 'status',
          displayName: 'Status',
        },
        {
          id: 'relacionadoCateterSonda',
          displayName: 'Relacionada a cateter/sonda',
        },
        {
          id: 'relacionado',
          displayName: 'Relacionada a Sonda Vesical de Demora (SVD)',
        }
      ];

      console.log({ assessments })

      return <CsvDownloader
        extension=".csv"
        columns={cols}
        datas={assessments.map((item)=>{
          if(item.status === 'positiva'){
            item.status = 'confirmada';
          }
          if(item.status === 'negativa'){
            item.status = 'não confirmada';
          }

          return item
        })}
        separator=";"
        filename={`ROBOISA_avaliacoes_${new Date()}`}
        text="Download CSV"
        style={{
          border: 'none',
          background: 'none',
          color: '#16B8A7',
          fontFamily: 'DM Mono , monospace',
          fontSize: 13
        }}
      />
    }
  })
  return <>
    <Grid container>
      <Grid item xs={10}>
        <h3>Exibindo {patientEvaluations?._metadata.totalCount} registros</h3>
      </Grid>
      <Grid item xs={2}>
        <h3>{assessmentsHistoryCSV}</h3>
      </Grid>
    </Grid>

    <StyledTable aria-label="simple table">
      <TableHead>
        <StyledTableRow className={classes.normalCursor}>
          <StyledTableCell align="left">Paciente</StyledTableCell>
          <StyledTableCell align="left">Data</StyledTableCell>
          <StyledTableCell align="left">Comunitária</StyledTableCell>
          <StyledTableCell align="left">Local</StyledTableCell>
          <StyledTableCell size="small">Avaliação</StyledTableCell>
          <StyledTableCell>Comentário</StyledTableCell>
          <StyledTableCell>Autor</StyledTableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
        {patientEvaluations?.results.filter((assessment)=> {
          if(HIDE_FROM.includes(userName)){
            return assessment.avaliacaoResponsavel != 'qualis_roboisa_anonimo'
          }

          return true;
        })?.map((patientAssessment, index) => (
          <StyledTableRow
            key={`patientAssessment.${patientAssessment.id}`}
            onClick={(e) => handleCardClick(patientAssessment.paciente?.id)}
          >
            {/* Nome do paciente */}
            <StyledTableCell scope="row">
              {patientAssessment.paciente?.name}
            </StyledTableCell>

            {/* Data */}
            <StyledTableCell className={classes.padding1}>
              <List dense disablePadding>
                <ListItem disableGutters>
                  <ListItemText primary={patientAssessment.avalDtInfec ? moment(patientAssessment.avalDtInfec).format('DD/MM/YYYY').toString() : 'Não informado' } />
                </ListItem>
              </List>
            </StyledTableCell>

            {/* Comunitária */}
            <StyledTableCell scope="row">
              <List dense disablePadding>
                <ListItem disableGutters>
                  <ListItemText primary={patientAssessment.comunitaria ? 'Sim' : 'Não'} />
                </ListItem>
              </List>
            </StyledTableCell>

            {/* Local */}
            <StyledTableCell scope="row">
              <List dense disablePadding>
                <ListItem disableGutters>
                  <ListItemText primary={
                    !patientAssessment.localInfeccao || patientAssessment.localInfeccao === 'undefined'
                      ? 'Não informado'
                      : patientAssessment.localInfeccao
                  } />
                </ListItem>
              </List>
            </StyledTableCell>

            {/* Avaliações */}
            <StyledTableCell size="small" className={classes.padding1}>
              {getAssessmentsChips(patientAssessment)}
            </StyledTableCell>

            {/* Comentário */}
            <StyledTableCell
              className={`${classes.grayish} ${classes.padding1}`}
            >
              <List dense disablePadding style={{ maxWidth: '280px' }}>
                {patientAssessment
                  ? (
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            isEmpty(
                              patientAssessment.comentario
                            ) || patientAssessment.comentario ===
                            'undefined'
                              ? 'Avaliação sem comentário'
                              : upperFirst(
                                  patientAssessment.comentario
                                )
                          }
                        />
                      </ListItem>
                    )
                  : 'Sem comentário'}
              </List>
            </StyledTableCell>

          {/* Autor */}
          <StyledTableCell scope="row">
            <List dense disablePadding>
              <ListItem disableGutters>
                <ListItemText primary={patientAssessment.avaliacaoResponsavel ?? '-'} />
              </ListItem>
            </List>
          </StyledTableCell>
          </StyledTableRow>
        ))}
        {getLoadMore()}
      </TableBody>
    </StyledTable>
  </>
}

const mapStateToProps = (state) => ({
  assessmentsHistory: state.assessmentsHistory.list,
  loading: state.assessmentsHistory.loading,
  infectionTypes: state.infectionTypes,
  initialDate: state.periods.dashboard.initialDate,
  finalDate: state.periods.dashboard.finalDate,
  orderByField: state.assessmentsHistoryFilter.orderByField,
  infections: state.assessmentsHistoryFilter.infections,
  positiveAssessments: state.assessmentsHistoryFilter.positive,
  negativeAssessments: state.assessmentsHistoryFilter.negative,
  globalStateInfections: state.assessmentsHistoryFilter.infections,
  globalStateOnlyInconclusive: state.assessmentsHistoryFilter.onlyInconclusive,
  globalStateOrderBy: state.assessmentsHistoryFilter.orderByField,
});


export default connect(mapStateToProps, null)(HistoricoTableRow);
