import React, { useMemo } from 'react';
import { useProbabilityColor } from '../../../../../utils/hooks';
import {
  useTimelineEntry,
  useTimelineInfectionAssessments,
} from '../../../slices';
import { SquareEntry } from '../SquareEntry';
import { useTheme, alpha } from '@material-ui/core';

export const IndicatorEntryInfeccoes = (props) => {
  const { indicator, date, onClick } = props;
  const items = useTimelineEntry({ indicator, date });
  const probability = useMemo(
    () =>
      items?.find((inf) => typeof inf.max_prob !== 'undefined')?.max_prob || 0,
    [items]
  );

  let color = useProbabilityColor(probability);
  const theme = useTheme();
  const infectionAssessments = useTimelineInfectionAssessments(date);
  const hasPositiveAssessments = useMemo(
    () =>
      infectionAssessments.some(
        ({ status, comunitaria }) => status === 'positiva' && !comunitaria
      ),
    [infectionAssessments]
  );

  if (hasPositiveAssessments) {
    color = alpha(theme.palette.timeline.secondary.green, 0.74);
  }

  return (
    <SquareEntry
      indicator={indicator}
      date={date}
      onClick={onClick}
      color={color}
    />
  );
};
