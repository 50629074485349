import React, { useEffect, useCallback } from 'react';
import { Snackbar as MuiSnackbar, Portal, Slide } from '@material-ui/core';

import { useToggleState } from '../../../utils/useToggleState';

import { Alert, Title, Subtitle } from './styles';

export const Snackbar = (props) => {
  const {
    severity,
    title,
    subtitle,
    open,
    onClose,
    anchorOrigin,
    autoHideDuration,
    children,
  } = props;

  const {
    open: isOpen,
    handleClose: handleCloseToggle,
    handleOpen: handleOpenToggle,
  } = useToggleState(!!open);

  const handleClose = useCallback((...args) => {
    if( !onClose ) {
      handleCloseToggle();
    } else {
      const closeResponse = onClose(...args)
      if( closeResponse || closeResponse === void 0) {
        handleCloseToggle();
      }
    }
  }, [handleCloseToggle, onClose]);

  useEffect(() => {
    if (open) {
      handleOpenToggle()
    } else {
      handleClose()
    }
  }, [open, handleOpenToggle, handleClose]);

  return (
    <Portal>
      <MuiSnackbar
        anchorOrigin={anchorOrigin}
        open={isOpen}
        onClose={handleClose}
        autoHideDuration={autoHideDuration}
        transitioncomponent={<Slide />}
      >
        <Alert severity={severity}>
          {title && <Title mb={subtitle || children ? '5px' : 0}>{title}</Title>}
          {subtitle && <Subtitle mb={children ? '5px' : 0}>{subtitle}</Subtitle>}
          {children}
        </Alert>
      </MuiSnackbar>
    </Portal>
  );
};

Snackbar.defaultProps = {
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  autoHideDuration: 5000,
  severity: 'success',
};
