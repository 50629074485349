import React, { useCallback, useMemo } from 'react';
import { Divider, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import { DateInput } from './DateInput';
import { withFieldControl } from '../FieldControl/withFieldControl';

export const RawDateRangeInput = withStyles((theme) => ({
  root: {
    display: 'flex',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: -1,
    order: 1,
    position: 'relative',
  },
  inputField: {},
  input: {
    backgroundColor: theme.palette.common.white,
    '& .MuiInput-input': {
      paddingRight: 0
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
    paddingTop: '2px',
    paddingBottom: '2px'
  },
  startDateField: {
    order: 0,
  },
  endDateField: {
    order: 2,
  },
  startDate: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  endDate: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}))(
  React.forwardRef(function StyledDateRangeInput(props, ref) {
    const {
      classes,
      className,
      startDate,
      endDate,
      onChangeStartDate,
      onChangeEndDate,
      onError,
      minDate,
      maxDate,
      startDatePlaceholder,
      endDatePlaceholder,
      invalidStartDateMessage,
      minStartDateMessage,
      maxStartDateMessage,
      invalidEndDateMessage,
      minEndDateMessage,
      maxEndDateMessage,
      bgcolor,
      error,
      disabled,
    } = props;
    const handleChangeStartDate = useCallback(
      (date) => date?.isValid() && onChangeStartDate?.(date),
      [onChangeStartDate]
    );
    const handleChangeEndDate = useCallback(
      (date) => date?.isValid() && onChangeEndDate?.(date),
      [onChangeEndDate]
    );

    const minStartDate = minDate;
    const minEndDate = useMemo(() => moment(startDate).add({ days: 1 }), [
      startDate,
    ]);
    const maxEndDate = maxDate;
    const maxStartDate = useMemo(() => moment(endDate).add({ days: -1 }), [
      endDate,
    ]);

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <DateInput
          className={clsx(classes.input, classes.startDate)}
          controlClasses={{
            root: clsx(classes.inputField, classes.startDateField),
          }}
          error={error}
          helperText={null}
          disabled={disabled}
          bgcolor={bgcolor}
          margin="none"
          placeholder={startDatePlaceholder}
          value={startDate}
          minDate={minStartDate}
          onChange={handleChangeStartDate}
          onError={onError}
          invalidDateMessage={invalidStartDateMessage}
          minDateMessage={minStartDateMessage}
          maxDateMessage={maxStartDateMessage}
        />
        <DateInput
          className={clsx(classes.input, classes.endDate)}
          controlClasses={{
            root: clsx(classes.inputField, classes.endDateField),
          }}
          error={error}
          helperText={null}
          disabled={disabled}
          bgcolor={bgcolor}
          margin="none"
          placeholder={endDatePlaceholder}
          value={endDate}
          minDate={minEndDate}
          maxDate={maxEndDate}
          onChange={handleChangeEndDate}
          onError={onError}
          invalidDateMessage={invalidEndDateMessage}
          minDateMessage={minEndDateMessage}
          maxDateMessage={maxEndDateMessage}
        />
        <Divider flexItem orientation="vertical" className={classes.divider} />
      </div>
    );
  })
);
RawDateRangeInput.defaultProps = {
  startDatePlaceholder: 'Data inicial',
  endDatePlaceholder: 'Data final',
  invalidStartDateMessage: 'Data inicial inválida',
  minStartDateMessage: 'Data inicial anterior à data mínima permitida',
  maxStartDateMessage: 'Data inicial futura à data final',
  invalidEndDateMessage: 'Data final inválida',
  minEndDateMessage: 'Data final anterior à data inicial',
  maxEndDateMessage: 'Data final futura à data máxima permitida',
};

export const DateRangeInput = withFieldControl(
  React.forwardRef(function ForwardedDateRangeInput(props, ref) {
    const { onError, ...inputProps } = props;
    const { error: _currentError, helperText } = inputProps;
    const currentError = _currentError && helperText;

    const handleError = useCallback(
      (error, date) => {
        if (error && !currentError && currentError !== error) {
          if (onError) onError(error, date);
        }
      },
      [currentError, onError]
    );

    return (
      <RawDateRangeInput
        {...inputProps}
        onError={handleError}
        error={currentError}
        helperText={helperText}
        ref={ref}
      />
    );
  })
);
