import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,

    rail: {
      backgroundColor: 'black'
    },
    track: {
      backgroundColor: '#FF9430'
    },
  },
  itemContainer: {
    padding: '20px 30px',

    root: {
      rail: {
        backgroundColor: 'black'
      },
      track: {
        backgroundColor: '#FF9430'
      },
    }
  },
  noNativeBackground:{
   '& > div': {
     backgroundColor: '#EDEDED',
     transition: 'ease 0.5s',
     
     '&:hover': {
       backgroundColor: '#EDEDED',
       boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)'
      },
      '&:before': {
        backgroundColor: 'transparent',
        transition: 'ease 0.5s'
       }
    },
    marginTop: '20px',
    borderTopLeftRadius: '9px',
    borderTopRightRadius: '9px',
    '@media (max-width: 600px)': {
      marginTop: '0px',
      width: '100%'
    }
  },
  itemPercentages: {
    padding: '10px 0px 50px',
    maxWidth: '60%',
    margin: '0 auto',
    '& p': {
      fontFamily: 'DM Mono',
      fontSize: '13px'
    }
  },
  rail: {
    height: '4px',
    backgroundColor: 'white',
    border: '1px solid #DDE3F0',
    display: 'block',
    opacity: '1'
  },
  track: {
    height: '6px'
  },
  thumb: {
    width: '20px',
    height: '20px',
    marginTop: '-8px'
  },
  valueLabel: {
    marginLeft: '8px'
  }
}));

export default useStyles;
