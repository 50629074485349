import infectionTypesRequestBody from "./query";
import {sendRequest} from "../api";
import {keycloakConnect} from "../../../keycloak";

const ENDPOINT = `suspeitas/_search?filter_path=hits.total,
    aggregations.pav.buckets.doc_count,
    aggregations.ipcs.buckets.doc_count,
    aggregations.traqueo.buckets.doc_count,
    aggregations.isc.buckets.doc_count,
    aggregations.itu.buckets.doc_count,
    aggregations.pnm.buckets.doc_count&scroll=1m`

/**
 * Return the list of infection types in case of success
 * or false otherwise
 *
 * @returns {Promise<AxiosResponse<T>|boolean>}
 */
export async function getInfectionTypes() {
  const token = keycloakConnect.token || localStorage.getItem('token')
  return await sendRequest({
    endpoint: ENDPOINT,
    data: infectionTypesRequestBody,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
}
export function testGetInfectionTypes() {
  const token = keycloakConnect.token
  return sendRequest({
    endpoint: ENDPOINT,
    data: infectionTypesRequestBody,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
}
