import React from "react";
import {PacientesTemplate, Container} from "./styles";

export default (props) =>
  <PacientesTemplate>

    <Container>
      {props.children}
    </Container>
  </PacientesTemplate>
