import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setName } from '../slice';

export const useSetHistoryPatientName = () => {
  const dispatch = useDispatch();

  const handleSetHistoryPatientName = useCallback(
    (name) => dispatch(setName(name)),
    [dispatch]
  );

  return handleSetHistoryPatientName;
};
