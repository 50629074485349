import { Button, withStyles } from '@material-ui/core';

export const DangerButtonOutline = withStyles((theme) => ({
  root: {
    borderColor: ({ disabled }) =>
      disabled
        ? theme.palette.neutral.gray.xlt
        : theme.palette.timeline.feedback.danger,
    color: ({ disabled }) =>
      disabled
        ? theme.palette.neutral.gray.xlt
        : theme.palette.timeline.feedback.danger,
  },
}))(Button);
DangerButtonOutline.defaultProps = {
  color: 'default',
  variant: 'outlined',
};
