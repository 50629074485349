import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../baseQuery';

export const patientSuspicionsApi = createApi({
  reducerPath: 'patientSuspicionsApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 1,
  tagTypes: ['PatientSuspicions'],
  endpoints: (build) => ({
    getPatientSuspicions: build.query({
      query: ({ infectionType, probability, ...allFilters }) => {
        const { highProbability, mediumProbability, lowProbability } = probability;

        // Faz um reduce do array de infecções para joga-las direto na url,
        // por enquanto que não achei um suporte de array no objeto 'params' que recebe
        // os filtros.
        const infectionTypes = infectionType.reduce((acc, item) => {
          return acc.concat(`&infectionType=${item}`)
        }, '')

        // Faz o parse das probabilidades para jogar direto na url.
        const probabilities = `${highProbability ? '&probability=alta' : ''}${mediumProbability ? '&probability=media' : ''}${lowProbability ? '&probability=baixa' : ''}`;

        return {
          url: `/suspeitas?${infectionTypes}${probabilities}`,
          params: { ...allFilters },
          method: 'GET',
        }
      }
    })
  })
})

export const {
  useGetPatientSuspicionsQuery
} = patientSuspicionsApi;
