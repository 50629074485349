import styled from 'styled-components';
import Badge from '@material-ui/core/Badge';

export const IconMenu = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 64px;
`;
export const Content = styled.div`
  background-color: ${(props) => props.theme.palette.dark.main};
  height: 100%;
`;
export const MyBadge = styled(Badge)`
  color: white;
  display: block;
  .MuiButtonBase-root {
    color: white;
  }
  .MuiBadge-badge {
    top: 9px;
    right: 3px;
  }
  .MuiBadge-colorPrimary {
    background-color: white;
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export const Item = styled.div`
  text-align: center;
  padding: 4px 0;
  width: 100%;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
`;

export const Logo = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  width: 100%;

  img {
    width: 26;
    width: 26;
  }
`;
