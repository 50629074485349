import React, { useMemo } from 'react';
import moment from 'moment';
import { indicatorsEnum } from '../../../../../../constants/indicators';
import GraphDialogComponent from '../../../../../../components/organisms/GraphDialog';
import HistogramChart from '../../../../../../components/atoms/HistogramChart';
import { useTimelineIndicator } from '../../../../slices';

export const ExamsHistogram = ({ cursor, onClose }) => {
  const allExams = useTimelineIndicator(indicatorsEnum.exames);

  const date = useMemo(() => {
    if (!cursor?._meta) return undefined;

    return cursor._meta.momentDate.toDate();
  }, [cursor]);

  const dataset = useMemo(() => {
    if (!cursor?._meta || !allExams?.length) return undefined;
    const subDays = moment(cursor._meta.momentDate)
      .add({ days: -7 })
      .startOf('day');
    const addDays = moment(cursor._meta.momentDate)
      .add({ days: 7 })
      .endOf('day');

    return allExams
      .filter(
        ({ item_exame, _meta }) =>
          item_exame === cursor.item_exame &&
          _meta.momentTime.isBetween(subDays, addDays)
      )
      .map((exam) => ({
        date: exam._meta.momentDate.toDate(),
        _source: {
          ...exam,
          valor: exam.resultado,
          date: exam._meta.momentTime.toDate(),
          tipo_sinal: exam.item_exame,
        },
      }));
  }, [cursor, allExams]);
  const histogramOpen = !!dataset && !!date;

  return (
    <GraphDialogComponent
      title={cursor?.item_exame || ''}
      unit={cursor?.item_exame === 'LEUCÓCITOS' ? 'leucócitos/mm3' : 'mg/L'}
      open={histogramOpen}
      setOpen={onClose}
    >
      <HistogramChart dataset={dataset} date={date} />
    </GraphDialogComponent>
  );
};
