import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles';

export const Title = styled.h2`
    margin-bottom: 20px;
    @media (max-width: 960px) {
        margin-bottom: 20px;
    }
`

const useStyles = makeStyles((theme) => ({
    lastUpdate: {
        fontFamily: 'DM Mono',
        fontSize: '13px',
        color: '#8F95A3',
        margin: '-17px 0px 0px'
    },
    saveButton: {
        textTransform: 'uppercase',
        fontFamily: theme.MuiTypography.root.fontFamily,
        fontWeight: 'bold'
    },
    buttons: {
      width: 'auto'
    },
    tableStyle: {
      'border': 'none'
    },
    tableRow: {
      'border-bottom': '1px solid rgba(0,0,0,0.05)',
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      }
    },
    tableHead: {
      background: '#F5F5F5',
    },
    itemText: {
      fontFamily: 'DM Mono',
      fontWeight: '400',
      fontSize: '13px'
    },
}));

export default useStyles;
