import { createSelector } from '@reduxjs/toolkit';
import { selectHistorySlice } from './selectHistorySlice';
import { useSelector } from 'react-redux';

export const selectHistoryOrderBy = createSelector(
  selectHistorySlice,
  (historyState) => {
    return historyState.orderBy
  }
);
export const useHistoryOrderBy = () => useSelector(selectHistoryOrderBy);
