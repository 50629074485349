export const getFieldFeedback = (form, name) => {
  const { touched, errors } = form

  const fieldProps = {}

  if (touched[name] && errors[name]) {
    fieldProps.error = true
    fieldProps.helperText = errors[name]
  }

  if (form.isSubmitting) {
    fieldProps.disabled = true
  }

  return fieldProps
}
