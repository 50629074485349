import { actionTypes } from '../actions/InfectionTypesActions';

const initialState = [];

export const infectionTypesReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.GET_INFECTION_TYPES:
      return [
        ...Object.keys(action.infectionTypes.aggregations)
      ];
    case actionTypes.GET_INFECTION_TYPES_ERROR:
      return [
        ...state
      ];
    default:
      return state;
  }
};
