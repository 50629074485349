import React from 'react';
import { useTheme } from '@material-ui/core';
import { useTimelineEncontrosAlternations } from '../../../slices';
import { SquareEntry } from '../SquareEntry';

export const IndicatorEntryEvolucao = (props) => {
  const { indicator, date, onClick } = props;
  const theme = useTheme();

  const changedTimes = useTimelineEncontrosAlternations()[date];

  const color =
    changedTimes % 2 !== 0
      ? theme.palette.timeline.secondary.blue
      : theme.palette.timeline.secondary.purple;

  return (
    <SquareEntry
      indicator={indicator}
      date={date}
      onClick={onClick}
      color={color}
    />
  );
};
