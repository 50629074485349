import React, { useMemo } from 'react';
import { useTimelineExams } from '../../../slices';
import { useIndicatorsPeriod } from '../../hooks/useIndicatorsPeriod';
import { ExamsHistogram, useExamsHistogram } from './ExamsHistogram';
import { ExamsDatesList } from './ExamsDatesList';

export const PopoverContentExames = () => {
  const allTimelineExams = useTimelineExams();
  const { startDate, endDate } = useIndicatorsPeriod();
  const examDates = useMemo(
    () =>
      allTimelineExams.filter((e) =>
        e.momentDate.isBetween(startDate, endDate)
      ),
    [allTimelineExams, startDate, endDate]
  );

  const {
    cursor: histogramCursor,
    handleClose: handleCloseHistogram,
    handleOpen: handleOpenHistogram,
  } = useExamsHistogram();

  return (
    <>
      <ExamsDatesList
        examDates={examDates}
        onOpenHistogram={handleOpenHistogram}
      />
      <ExamsHistogram cursor={histogramCursor} onClose={handleCloseHistogram} />
    </>
  );
};
