import { useMemo } from 'react';
import { groupBy } from 'lodash';
import moment from 'moment';

export const useGroupIndicatorByDate = (items) => {
  const byDate = useMemo(() => groupBy(items, '_meta.date'), [items]);
  const dates = useMemo(
    () =>
      Object.keys(byDate)
        .sort((a, b) => b - a)
        .map((timestamp) => {
          const date = parseInt(timestamp, 10);

          return {
            date,
            momentDate: moment(date),
            items: byDate[date],
          };
        }),
    [byDate]
  );

  return dates;
};
