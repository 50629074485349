import { withStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export const PageTitleTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: `#000`,
    fontFamily: theme.MuiTypography.family.secondary,
    fontSize: '12px',
  },
  icon: {
    fontSize: 16,
    color: theme.palette.neutral.black['48'],
    marginLeft: theme.spacing(.5)
  },
}))(function StyledPageTitleTooltip({ classes, info }) {
  const { icon: iconClassName, ...tooltipClasses } = classes;

  return (
    <Tooltip classes={tooltipClasses} title={info} placement="right">
      <InfoOutlinedIcon className={iconClassName} />
    </Tooltip>
  );
});

export const PageTitle = withStyles((theme) => ({
  root: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '35px',
    color: theme.palette.neutral.black['100'],
  },
}))(function StyledPageTitle({ classes, className, children, info }) {
  return (
    <div className={clsx(classes.root, className)}>
      {children}
      {info && <PageTitleTooltip info={info} />}
    </div>
  );
});
