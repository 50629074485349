import React, { useCallback, useEffect } from 'react';
import useDebounce from 'react-use/lib/useDebounce';
import { Fade, Divider, IconButton, InputAdornment } from '@material-ui/core';
import { TextInput } from '../../../../../components/molecules/Form';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';

import {
  useSetTimelineSearchInstance,
  useSetTimelineSearchWord,
  useTimelineSearchInstance,
  useTimelineSearchWord,
  useTimelineSearchResultsTotal,
} from '../../../slices';

export const SearchInput = () => {
  const searchWord = useTimelineSearchWord();
  const setSearchWord = useSetTimelineSearchWord();
  const searchResults = useTimelineSearchResultsTotal();
  const searchInstance = useTimelineSearchInstance();
  const setSearchInstance = useSetTimelineSearchInstance();

  const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    setInputValue(searchWord);
  }, [searchWord, setInputValue]);

  useEffect(() => {
    setSearchInstance(searchWord && searchResults ? 1 : 0);
  }, [searchResults, searchWord, setSearchInstance]);

  const handlePrevInstance = useCallback(() => {
    if (!searchResults) return;

    const prevInstance = searchInstance - 1;
    setSearchInstance(prevInstance <= 0 ? searchResults : prevInstance);
  }, [searchResults, searchInstance, setSearchInstance]);

  const handleNextInstance = useCallback(() => {
    if (!searchResults) return;

    const nextInstance = searchInstance + 1;
    setSearchInstance(nextInstance > searchResults ? 1 : nextInstance);
  }, [searchResults, searchInstance, setSearchInstance]);

  const handleInputChange = useCallback((e) => setInputValue(e.target.value), [
    setInputValue,
  ]);

  const handleResetSearchWord = useCallback(() => setSearchWord(''), [
    setSearchWord,
  ]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (searchWord !== inputValue) {
        setSearchWord(inputValue);
      } else {
        handleNextInstance();
      }
    },
    [searchWord, inputValue, setSearchWord, handleNextInstance]
  );

  useDebounce(
    () => {
      if (searchWord === inputValue || inputValue.length === 1) return;
      setSearchWord(inputValue);
    },
    300,
    [inputValue, searchWord, setSearchWord]
  );

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Buscar palavra"
        startAdornment={
          <InputAdornment position="end">
            <SearchIcon size={12} color="inherit" />
          </InputAdornment>
        }
        endAdornment={
          <Fade in={searchWord}>
            <InputAdornment
              position="start"
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
            >
              <div style={{ marginRight: '4px' }}>
                {searchInstance || '0'}/{searchResults || '0'}
              </div>
              <Divider
                orientation="vertical"
                flexItem
                style={{ margin: '0 8px' }}
              />
              <IconButton
                disabled={!searchResults || !searchInstance}
                size="small"
                onClick={handlePrevInstance}
              >
                <KeyboardArrowUpIcon size={12} color="inherit" />
              </IconButton>
              <IconButton
                disabled={!searchResults || !searchInstance}
                size="small"
                onClick={handleNextInstance}
              >
                <KeyboardArrowDownIcon size={12} color="inherit" />
              </IconButton>
              <IconButton
                disabled={!searchWord}
                size="small"
                onClick={handleResetSearchWord}
              >
                <CloseIcon
                  style={{ fontSize: 24, padding: 3 }}
                  color="inherit"
                />
              </IconButton>
            </InputAdornment>
          </Fade>
        }
      />
    </form>
  );
};
