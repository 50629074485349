import React from 'react';
import { Box } from '@material-ui/core';
import { DialogToolbar } from './DialogToolbar';
import { DialogClose } from './DialogClose';
import { DialogTitle } from './DialogTitle';

export const DialogHeader = (props) => {
  const {
    children,
    title,
    wide,
    small,
    border,
    closeButton: CloseButtonComponent = DialogClose,
    closePosition,
  } = props;
  const isCloseBtnLeft = closePosition === 'left';

  return (
    <DialogToolbar borderBottom={border} wide={wide} small={small}>
      <Box display="flex">
        <Box order={isCloseBtnLeft ? '0' : '1'} flex="0 0 auto">
          <DialogClose component={CloseButtonComponent} />
        </Box>
        <Box
          order="0"
          flex="1 1 auto"
          marginLeft={isCloseBtnLeft ? '12px' : 0}
          marginRight={isCloseBtnLeft ? 0 : '12px'}
          display="flex"
          alignItems="center"
        >
          <Box width="100%">
            {typeof title === 'string' ? (
              <DialogTitle>{title}</DialogTitle>
            ) : (
              title || children
            )}
          </Box>
        </Box>
      </Box>
    </DialogToolbar>
  );
};
DialogHeader.defaultProps = {
  wide: false,
  small: false,
  border: false,
  closeButton: DialogClose, // Variante small para o botão fechar
  closePosition: 'right', // left | right
};
