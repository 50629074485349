import React, { useEffect, useCallback, useState } from 'react';
import { Box } from '@material-ui/core';
import { object } from 'prop-types';
import { Chip } from '../../atoms/Chip';
import { infectionTypesList } from '../../../constants/infections';
import { infectionTypesLabels } from '../../../utils/formatters';
import { formatToPercentageString } from './utils';

export const PatientCardChips = (props) => {
  const { patient, settings } = props;

  /**
   * Filtra infecções e renderiza chips com label e valor
   * em percentual da infecção
   */
  const renderPatientInfections = useCallback(() => {
    /* Filtra infecções que devem ser exibidas */
    const infectionKeys = Object.keys(patient).filter(
      (key) => infectionTypesList.includes(key) && patient[`pred_${key}`] === 1
    );
    const hasInfectionsFromList = infectionKeys.length;

    /* Caso não haja infecção da lista, renderiza porcentagem geral com label 'outra' */
    if (!hasInfectionsFromList) {
      const parsedPercentage = formatToPercentageString(patient.maxProb, 2);
      return (
        <Chip
          label={`OUTRA: ${parsedPercentage}`}
          variant="white"
          outlined
          style={{ marginLeft: '4px', marginTop: '8px' }}
        ></Chip>
      );
    }

    /* Cria array com label e valor formatado para renderição */
    const infections = infectionKeys.map((key) => {
      return [
        infectionTypesLabels[key],
        formatToPercentageString(patient[key], 2),
      ];
    });

    /* Renderiza chips */
    return infections.map(([label, percentual], index) => {
      return (
        <Chip
          key={`${label}-${index}`}
          label={`${label}: ${percentual}`}
          style={{ marginLeft: '4px', marginTop: '8px' }}
          variant="white"
          outlined
        ></Chip>
      );
    });
  }, [patient]);

  /**
   * Retorna variant que será atribuído ao chip de probabilidade
   * baseado no valor recebido e configurações do sistema
   * @param maxProb
   * @return {'gray' | 'warning' | 'danger'}
   */
  const getProbabilityVariant = useCallback(
    (maxProb) => {
      const otherInfectionPercentage = parseFloat(maxProb * 100).toFixed(1);

      let variant = 'gray';
      if (
        otherInfectionPercentage >= settings.baixa &&
        otherInfectionPercentage < settings.media
      ) {
        variant = 'warning';
      } else if (otherInfectionPercentage > settings.media) {
        variant = 'danger';
      }

      return variant;
    },
    [patient]
  );

  const patientMaxProb = formatToPercentageString(patient.maxProb, 2);

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="flex-end">
      <Box>
        <Chip
          variant={getProbabilityVariant(patient.maxProb)}
          label={`Probabilidade: ${patientMaxProb}`}
          size="extralarge"
        />
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        flex="1 0 100%"
        justifyContent="flex-end"
      >
        {renderPatientInfections()}
      </Box>
    </Box>
  );
};

PatientCardChips.propTypes = {
  patient: object.isRequired,
};
