import styled from "styled-components";

export const AlertDefault = styled.div`

    .MuiAlert-filledSuccess{
        background: ${(props=> props.theme.palette.primary.main)};
        padding: 15px 25px;
        .MuiAlert-icon{
            align-items: center;
        }
        h3{
            font-family: 'DM Mono';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            margin-bottom: 5px;
        }
        p{
            font-family: 'DM Mono';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 17px;
        }
    }
`;
