import React, { useCallback } from 'react';
import { DropMenu, DropMenuItem } from '../../../components/molecules/DropMenu';
import { useToggleState } from '../../../utils/useToggleState';
import { AssessmentCardMenu } from '../../Timeline/AssessmentCard/AssessmentCardMenu';

const MoreHorizontalIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33333 9.33329C2.59695 9.33329 2 8.73634 2 7.99996C2 7.26358 2.59695 6.66663 3.33333 6.66663C4.06971 6.66663 4.66667 7.26358 4.66667 7.99996C4.66667 8.73634 4.06971 9.33329 3.33333 9.33329ZM8 9.33329C7.26362 9.33329 6.66667 8.73634 6.66667 7.99996C6.66667 7.26358 7.26362 6.66663 8 6.66663C8.73638 6.66663 9.33333 7.26358 9.33333 7.99996C9.33333 8.73634 8.73638 9.33329 8 9.33329ZM11.3333 7.99996C11.3333 8.73634 11.9303 9.33329 12.6667 9.33329C13.403 9.33329 14 8.73634 14 7.99996C14 7.26358 13.403 6.66663 12.6667 6.66663C11.9303 6.66663 11.3333 7.26358 11.3333 7.99996Z"
      fill="#3E4247"
      fillOpacity="0.72"
    />
  </svg>
);
export const HistoryActions = ({ assessment }) => {
  const handleOnOpen = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  return (
    <>
      <AssessmentCardMenu
        assessment={assessment}
        buttonProps={{ icon: MoreHorizontalIcon, onClick: handleOnOpen }}
      />
    </>
  );
};
