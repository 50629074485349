import { createSelector } from '@reduxjs/toolkit';
import { selectPatientsSlice } from './selectPatientsSlice';
import { useSelector } from 'react-redux';

export const selectPatientsInfections = createSelector(
  selectPatientsSlice,
  (patientsState) => patientsState.infections
);
export const usePatientsInfections = () =>
  useSelector(selectPatientsInfections);
