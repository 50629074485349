import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../../baseQuery';

export const createPatientApi = createApi({
  reducerPath: 'createPatientApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['CreatePatient'],
  endpoints: (build) => ({
    postCreatePatient: build.mutation({
      query(submitData) {
        const body = {
          ...submitData
        };

        return {
          url: 'pacientes/',
          method: 'POST',
          body,
        };
      },
      extraOptions: { maxRetries: 1 },
      invalidatesTags: ['CreatePatient'],
      transformResponse: (response) => ({ message: response }),
    }),
  }),
});

export const {
  usePostCreatePatientMutation
} = createPatientApi;
