import React from 'react';
import { DropMenuProvider } from './DropMenuProvider';
import { DropMenuButton } from './DropMenuButton';
import { DropMenuList } from './DropMenuList';

export const DropMenu = ({ children, buttonProps, menuProps }) => (
  <DropMenuProvider>
    <DropMenuButton {...buttonProps} />
    <DropMenuList {...menuProps}>{children}</DropMenuList>
  </DropMenuProvider>
);
DropMenu.defaultProps = {
  buttonProps: { style: { padding: 0, display: 'block' } },
  menuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
};
