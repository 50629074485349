import React from 'react';
import { Box, LinearProgress, withStyles } from '@material-ui/core';

export const DrawerPanelWrapper = withStyles((theme) => ({
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100%',
  },
}))(Box);

export const DrawerPanelToolbar = withStyles((theme) => ({
  root: {
    width: '100%',
    flex: '0 0 auto',
  },
}))(Box);

export const DrawerPanelContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflowY: 'auto',
    width: '100%',
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexFlow: 'column nowrap',
  },
}))(Box);

export const DrawerPanel = ({ loading, header, footer, children }) => {
  return (
    <DrawerPanelWrapper>
      {loading && (
        <LinearProgress
          style={{ position: 'absolute', top: 0, left: 0, right: 0 }}
        />
      )}
      {header && <DrawerPanelToolbar>{header}</DrawerPanelToolbar>}
      <DrawerPanelContent>{children}</DrawerPanelContent>
      {footer && <DrawerPanelToolbar>{footer}</DrawerPanelToolbar>}
    </DrawerPanelWrapper>
  );
};
