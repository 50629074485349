import React, { useLayoutEffect, useRef } from 'react';
import D3HistogramChart from './D3HistogramChart';
import { any } from 'prop-types';
import { GraphContainer } from './styles';
import { isEmpty } from 'lodash';

const HistogramChart = (props) => {
  const { dataset, date } = props;

  const refD3HistogramChart = useRef();

  useLayoutEffect(() => {
    if (isEmpty(dataset)) return;
    const chart = new D3HistogramChart(refD3HistogramChart, dataset, date);

    const updateSize = () => chart.changeSizes();

    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [dataset]);

  return <GraphContainer ref={refD3HistogramChart} />;
};
HistogramChart.propTypes = {
  dataset: any.isRequired,
  date: any.isRequired,
};
export default HistogramChart;
