import React, { useMemo } from 'react';
import { useTimelineDates } from '../slices';
import { CHART_INDICATORS } from './constants';
import { TimelineEntry } from './TimelineEntry';

export const TimelineEntries = () => {
  const timelineDates = useTimelineDates();
  const timelineEntries = useMemo(
    () => (
      <>
        {!!timelineDates &&
          Object.entries(timelineDates).map(([indicator, dates]) => {
            if (!CHART_INDICATORS.includes(indicator)) return null;

            return (
              <g key={indicator} data-name={indicator}>
                {dates.map((date) => (
                  <TimelineEntry key={date} date={date} indicator={indicator} />
                ))}
              </g>
            );
          })}
      </>
    ),
    [timelineDates]
  );

  return <g data-name="entries">{timelineEntries}</g>;
};
