import React from 'react'
import { Box, ButtonBase } from '@material-ui/core'

import { mapProbabilityLevelToTheme } from './util';
import { useStyles } from './styles';

export const TimelineDrawerListItem = (props) => {
  const { label, value, level, hasCriterias, icon, handleClick } = props;

  const theme = mapProbabilityLevelToTheme(level);
  const styleProps = { ...props, theme };
  const style = useStyles(styleProps);

  return (
    <Box className={style.container}>
      {value && <Box className={hasCriterias ? style.dotDanger : style.dot}></Box>}
      <Box className={style.content}>
        <Box className={style.textLabel}>{label}</Box>
        <Box className={style.rightContent}>
          <Box className={style.textValue}>{value}</Box>
          {icon && <ButtonBase
            disableTouchRipple
            className={hasCriterias ? style.dangerIcon : style.icon}
            onClick={handleClick}>{icon}</ButtonBase>}
        </Box>
      </Box>
    </Box>
  );
};
