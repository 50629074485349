import React from 'react';
import { Divider } from '@material-ui/core';
import { VitalSignItem } from './VitalSignItem';

export const VitalSignsList = (props) => {
  const { signals, onClick } = props;

  return (
    <>
      {signals.map((item, itemIndex) => (
        <React.Fragment key={`${item.signal}-${itemIndex}`}>
          {itemIndex !== 0 && (
            <Divider style={{ marginLeft: '24px', marginRight: '8px' }} />
          )}
          <VitalSignItem item={item} onClick={onClick} />
        </React.Fragment>
      ))}
    </>
  );
};
