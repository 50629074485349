import React, { useMemo } from 'react';
import { useTheme } from '@material-ui/core';
import { cultureExamsList } from '../../../../../constants/exams';
import { useTimelineEntry } from '../../../slices';
import { SquareEntry } from '../SquareEntry';

export const IndicatorEntryExames = (props) => {
  const { indicator, date, onClick } = props;
  const theme = useTheme();
  const exams = useTimelineEntry({ indicator, date });

  // Exames do tipo cultura
  const cultures = useMemo(() => {
    return exams.filter((exam) => {
      return cultureExamsList.some((cultureExam) =>
        exam.exame.toUpperCase().includes(cultureExam.toUpperCase())
      );
    });
  }, [exams]);

  // Culturas positivas
  const hasPositiveCulture = useMemo(
    () => cultures.some((exam) => exam.positivo === 'sim'),
    [cultures]
  );

  // Culturas negativas
  const hasNegativeCulture = useMemo(
    () => cultures.some((exam) => exam.positivo !== 'sim'),
    [cultures]
  );

  // Quando há algum exame que não é cultura e que é positivo e/ou com critério
  const hasPositiveOrCriteria = useMemo(() => {
    return exams.some((exam) => {
      return (
        !cultures.includes(exam) &&
        [exam.positivo, exam.criterio].includes('sim')
      );
    });
  }, [exams, cultures]);

  // Por padrão, quando não há nenhum exame positivo ou com critério, a cor é azul
  let color = theme.palette.timeline.secondary.blue;
  if (hasPositiveOrCriteria) {
    color = theme.palette.timeline.probability.BAIXA;
  }
  if (hasNegativeCulture) {
    color = theme.palette.timeline.probability.MEDIA;
  }
  if (hasPositiveCulture) {
    color = theme.palette.timeline.probability.ALTA;
  }

  return (
    <SquareEntry
      indicator={indicator}
      date={date}
      onClick={onClick}
      color={color}
    />
  );
};
