import { createSlice } from '@reduxjs/toolkit';

export const dashboardInitialState = {
  selectedCompetence: 'oneMonth',
};

const dashboardPageSlice = createSlice({
  name: 'dashboardPageSlice',
  initialState: dashboardInitialState,
  reducers: {
    setSelectedCompetence: (state, { payload }) => {
      state.selectedCompetence = payload;
    }
  },
});

export const {
  setSelectedCompetence
} = dashboardPageSlice.actions;
export default dashboardPageSlice.reducer;
