import React, { useMemo } from 'react';
import { GenericDrawer } from './GenericDrawer';

export const defaultSideSheetHeaderProps = {
  small: true,
  wide: true,
  closePosition: 'left',
  closeSmall: false
}

export const SideSheetDrawer = (props) => {
  const { header, ...rest } = props;
  const headerProps = useMemo(() => ({
    ...defaultSideSheetHeaderProps,
    ...header
  }), [header])

  return <GenericDrawer header={headerProps} {...rest} />;
};
SideSheetDrawer.defaultProps = {
  anchor: 'right',
};
