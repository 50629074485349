import { Box, Fade, Collapse, LinearProgress } from '@material-ui/core';
import React from 'react';
import { MicroorganismItemField } from './MicroorganismItemField';

export const MicroorganismsRepeater = (props) => {
  const {
    values,
    name,
    errors,
    helperTexts,
    options,
    disabled,
    onRemove,
    loading,
  } = props;

  return (
    <Box position="relative">
      <Fade in={loading}>
        <Box position="absolute" width="100%" inset="0" bgcolor="common.white">
          <LinearProgress variant="indeterminate" color="primary" />
        </Box>
      </Fade>
      <Collapse in={!loading}>
        {values.map((item, index) => (
          <MicroorganismItemField
            key={`${item.microorganism}-${item.resistant}-${index}`}
            name={`${name}.[${index}]`}
            options={options}
            value={item}
            onRemove={index > 0 ? () => onRemove(index) : undefined}
            errors={errors?.[index]}
            helperTexts={helperTexts?.[index]}
            disabled={disabled}
            loading={loading}
          />
        ))}
      </Collapse>
    </Box>
  );
};
