import React from 'react';
import { AssessmentCard } from '../../../AssessmentCard';
import { useTimelineAssessments } from '../../../slices';

export const PopoverContentAvaliacoes = ({ indicator }) => {
  const assessments = useTimelineAssessments();

  return (
    <>
      {!!assessments?.length &&
        assessments.map((assessment, i) => (
          <AssessmentCard
            key={`${assessment.avaliacao_data}-${i}`}
            assessment={assessment}
            hideMenu
            color="gray"
            style={{
              marginTop: i !== 0 ? '8px' : 0,
            }}
          />
        ))}
    </>
  );
};
