import React, { useState } from 'react';
import moment from 'moment';
import {
  Box,
  Typography,
  SwipeableDrawer,
  ButtonBase,
  Divider,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CancelIcon from '@material-ui/icons/Cancel';
import useStyles from './styles';
import {
  examIsCulture,
  orderTopLevelExams,
  useTimelineActiveIndicator,
  useTimelineIndicators,
} from '../../../slices';
import InsertChartOutlined from '@material-ui/icons/InsertChartOutlined';
import GraphDialogComponent from '../../../../../components/organisms/GraphDialog';
import HistogramChart from '../../../../../components/atoms/HistogramChart';
import { orderExams } from '../../../../../components/atoms/TimelineChart/Tooltips/helpers';
import { Chip } from '../../../../../components/atoms/Chip';
import { cloneDeep, orderBy } from 'lodash-es';
import {
  cultureExamsList,
  examsTypeEnum,
} from '../../../../../constants/exams';
import { useCallback } from 'react';

export const TimelineDrawerExames = ({ indicator, date }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  const [open, setOpen] = useState(false);
  const [dataset, setDataset] = useState({});
  const [grapTitle, setGrapTitle] = useState('');
  const [grapUnit, setGrapUnit] = useState('');

  const parseGraphData = (patientFacts, typeName, item) => {
    let exames = cloneDeep(
      patientFacts.filter((item) => item?.item_exame === typeName)
    );
    const currentDate = item.date ? item.date : new Date(item.dthr_pedido);
    const subDays = moment(currentDate)
      .add({ days: -7 })
      .startOf('day')
      .toDate();
    const addDays = moment(currentDate).add({ days: 7 }).endOf('day').toDate();

    let grapData = orderBy(
      exames
        .filter((exame) => {
          const date = new Date(exame.dthr_pedido);
          return (
            date.getTime() >= subDays.getTime() &&
            date.getTime() <= addDays.getTime()
          );
        })
        .map((exame) => {
          exame.date = new Date(new Date(exame.dthr_pedido).toDateString());
          exame.tipo_sinal = typeName;
          exame.valor = exame.resultado;
          return {
            _index: 'exames',
            _source: {
              ...exame,
            },
            date: exame.date,
          };
        }),
      '_source.dthr_entrega',
      'asc'
    );

    return grapData;
  };

  const activeItems = useTimelineActiveIndicator();
  // Ordenados por cultura e demais regras
  const items = orderTopLevelExams(activeItems);
  const allItems = useTimelineIndicators();
  const classes = useStyles();

  const examesList = [...new Set(items.map((item) => item.exame))];

  const resultadoExamesList = examesList.map((exame) => {
    const filteredExames = orderBy(
      items.filter((item) => item.exame === exame),
      orderExams,
      'asc'
    );

    return { tipoExame: exame, dadosExame: filteredExames };
  });

  const onClickGraph = (item) => {
    setOpen(true);

    const { item_exame } = item;

    setGrapTitle(item_exame);
    setGrapUnit(item_exame === 'LEUCÓCITOS' ? 'leucócitos/mm3' : 'mg/L');

    const indicators = allItems.exames.filter(
      (item) => item.item_exame === item_exame
    );

    const data = parseGraphData(indicators, item_exame, item);

    setDataset(data);
  };

  const handleOpenDrawer = (data) => {
    setDrawerOpen(true);
    setDrawerData(data);
  };

  const getLeucocitosValue = useCallback(
    (dadosExame) => {
      const leucocitosExams = dadosExame.filter(
        (exame) => exame.item_exame === examsTypeEnum.LEUCÓCITOS
      );
      if (!leucocitosExams.length) return false;

      const leucocitosResultsArr = leucocitosExams.map(
        (exame) => exame.resultado
      );
      return Math.max(...leucocitosResultsArr);
    },
    [resultadoExamesList]
  );

  return (
    <Box padding="0px 16px">
      {resultadoExamesList.map((exame) => {
        const { tipoExame, dadosExame } = exame;

        const isCulture = examIsCulture(tipoExame);

        const criterioPositivo = dadosExame.filter(
          (exame) => exame.criterio === 'sim'
        ).length;
        const resultadoPositivo = dadosExame.filter(
          (exame) => exame.positivo === 'sim'
        ).length;

        console.log(dadosExame);
        const dataPedido = new Date(
          dadosExame[0].dthr_pedido
        ).toLocaleDateString('pt-BR', {
          dateStyle: 'short',
        });
        const horaPedido = new Date(
          dadosExame[0].dthr_pedido
        ).toLocaleTimeString('pt-BR', {
          timeStyle: 'short',
        });
        const dataExame = new Date(
          dadosExame[0].dthr_pedido
        ).toLocaleDateString('en-US', {
          dateStyle: 'short',
        });

        //prettier-ignore
        const leucocitos = tipoExame === 'HEMOGRAMA' && getLeucocitosValue(dadosExame);

        return (
          <React.Fragment key={exame.id}>
            <Box className={classes.cardContainer}>
              {criterioPositivo ? (
                <Box className={classes.feedbackCircle} />
              ) : (
                ''
              )}
              <ButtonBase
                className={classes.examItem}
                onClick={() =>
                  handleOpenDrawer({
                    horaPedido,
                    dataPedido,
                    criterioPositivo,
                    tipoExame,
                    dadosExame,
                    dataExame,
                  })
                }
              >
                <Box>
                  <Box display="flex">
                    <Typography className={classes.cardTitle}>
                      {tipoExame}
                    </Typography>
                    {resultadoPositivo ? (
                      <Box ml="8px">
                        <Chip
                          label="Positivo"
                          variant={isCulture ? 'danger' : 'warning'}
                        />
                      </Box>
                    ) : (
                      ''
                    )}
                    {leucocitos && (
                      <Box ml="8px">
                        <Chip
                          label={`${examsTypeEnum.LEUCÓCITOS}: ${leucocitos}`}
                          variant={criterioPositivo ? 'danger' : 'gray'}
                        />
                      </Box>
                    )}
                  </Box>
                  <Typography className={classes.cardInfo}>
                    Pedido: {dataPedido} {horaPedido}
                  </Typography>
                </Box>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </ButtonBase>
            </Box>
            <Divider className={classes.divider} />
          </React.Fragment>
        );
      })}

      <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        className={classes.drawerBottom}
      >
        <Box p={2}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              {drawerData.criterioPositivo ? (
                <Box className={classes.feedbackCircle} />
              ) : (
                ''
              )}
              <Box ml={2} className={classes.drawerTitle}>
                {drawerData.tipoExame}
              </Box>
            </Box>
            <ButtonBase onClick={() => setDrawerOpen(false)}>
              <CancelIcon color="rgba(62, 66, 71, 0.72)" />
            </ButtonBase>
          </Box>

          <Box
            mt={2}
            component={Typography}
            className={classes.dataPedidoContainer}
          >
            Pedido: {drawerData.dataPedido} {drawerData.horaPedido}
          </Box>
          {drawerData?.dadosExame?.map((exame) => {
            return (
              <React.Fragment key={exame.id}>
                <Box className={classes.cardContainer}>
                  {exame.criterio === 'sim' ? (
                    <Box className={classes.feedbackCircle} />
                  ) : (
                    ''
                  )}
                  <ButtonBase
                    disableTouchRipple
                    disabled={
                      exame.item_exame !== 'LEUCÓCITOS' &&
                      exame.item_exame !== 'PROTEINA C REATIVA'
                    }
                    onClick={() => onClickGraph(exame)}
                    className={classes.examItem}
                  >
                    <Box display="flex" flex="1" justifyContent="space-between">
                      <Box display="flex">
                        <Typography className={classes.cardTitle}>
                          {exame.item_exame}: {exame.resultado}{' '}
                        </Typography>
                        {exame.positivo === 'sim' ? (
                          <Box ml="8px">
                            <Chip label="Positivo" variant="danger" />
                          </Box>
                        ) : (
                          ''
                        )}
                      </Box>
                      {exame.item_exame === 'LEUCÓCITOS' ||
                      exame.item_exame === 'PROTEINA C REATIVA' ? (
                        <InsertChartOutlined
                          htmlColor={
                            exame.criterio === 'sim'
                              ? '#F86975'
                              : 'rgba(218, 218, 218, 1)'
                          }
                        />
                      ) : (
                        ''
                      )}
                    </Box>
                  </ButtonBase>
                </Box>
                <Divider className={classes.divider} />
              </React.Fragment>
            );
          })}
        </Box>
      </SwipeableDrawer>

      <GraphDialogComponent
        title={grapTitle}
        open={open}
        unit={grapUnit}
        setOpen={() => setOpen(false)}
      >
        <HistogramChart
          dataset={dataset}
          date={new Date(drawerData.dataExame)}
        />
      </GraphDialogComponent>
    </Box>
  );
};
