export function getIndicatorValue(indicators, indicatorName, competence){
  const indicatorValue = indicators.find(item => item.key === indicatorName)

  if (indicatorValue) {
    const indicatorByCompetence = indicatorValue[competence];

    return indicatorByCompetence ? parseInt(indicatorByCompetence) : 0;
  }

  return 0
}

export function getStatisticValue(indicators, statisticName, competence) {
  const statisticValue = indicators.find(item => item.key === statisticName);

  if (statisticValue) {
    const statisticByCompetence = statisticValue[competence]

    return statisticByCompetence ? parseFloat(statisticByCompetence)?.toFixed(2) : 0
  }

  return 0
}
