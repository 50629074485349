import { createContext, useContext } from 'react';

export const CommentsContext = createContext({
  // Drawer
  open: false,
  handleOpen: () => {},
  handleClose: () => {},
  handleToggle: () => {},
  // Fetch
  comments: [],
  isFetchingComments: false,
  errorFetchingComments: false,
  // Create
  handleCreateComment: (message = '') => {},
  isCreatingComment: false,
  errorCreatingComment: false,
  // Edit
  edit: {},
  handleStartEdit: () => {},
  handleCancelEdit: () => {},

  handleEditComment: (commentId = 0, message = '') => {},
  isEditingComment: false,
  errorEditingComment: false,

  // Delete
  handleDeleteComment: (commentId = 0) => {},
  isDeletingComment: false,
  errorDeletingComment: false,
});
export const useCommentsContext = () => useContext(CommentsContext);
