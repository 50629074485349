import { createSelector } from '@reduxjs/toolkit';
import { selectHistorySlice } from './selectHistorySlice';
import { useSelector } from 'react-redux';

export const selectHistoryProbabilities = createSelector(
  selectHistorySlice,
  (historyState) => historyState.probabilities
);
export const usePatientsProbabilities = () =>
  useSelector(selectHistoryProbabilities);
