import React, { useEffect, useState } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import Route from './Route';
import Pacientes from '../pages/Pacientes/Pacientes';
import Patients from '../pages/Pacientes/v2';
import Historico from '../pages/Historico/index';
import Login from '../pages/Auth/Login/index';
import Forbidden from '../pages/Forbidden/index';

import { useKeycloak } from '@react-keycloak/web';
import { kcAuth } from '../keycloak';

// Pages
import Dashboard from '../pages/Dashboard/v2';
import TimelinePage from '../pages/Timeline/Timeline';
import SettingsPage from '../pages/Settings';
import ChangelogPage from '../pages/Changelog';
import HealthPage from '../pages/Health';
import PatientEvolution from '../pages/PatientEvolution/PatientEvolution';
import History from '../pages/Historico/v2/History';
import { HIDE_FROM } from '../pages/hideAnonymousEvaluation';

export default function Routes() {
  const [keycloak, initialized] = useKeycloak();
  const [userName, setUserName] = useState(localStorage.getItem('username'));
  const history = useHistory();

  useEffect(() => {
    if (initialized) {
      if (keycloak.authenticated) {
        // Quando autenticado, define o username no localstorage
        kcAuth();
      } else {
        // Quando não autenticado, força o login em TODAS as rotas.
        keycloak.login();
      }
    }
  }, [keycloak.authenticated, initialized, history, keycloak]);

  if (!initialized) return null; // <h2 style={{ paddingLeft: 109, paddingTop: 20 }}>Loading...</h2>;

  return (
    <Switch>
      <Route path="/" exact resource={false} component={Login} />
      <Route
        path="/health"
        exact
        resource={false}
        roles={['admin']}
        component={HealthPage}
      />
      {!HIDE_FROM.includes(userName) && (
        <Route
          path="/settings"
          exact
          resource={false}
          roles={['admin']}
          component={SettingsPage}
        />
      )}
      <Route
        path="/dashboard"
        exact
        resource={false}
        roles={['admin']}
        component={Dashboard}
      />
      <Route
        path="/pacientes"
        exact
        resource={false}
        roles={['admin']}
        component={Patients}
      />
      <Route
        path="/historico"
        exact
        resource={false}
        roles={['admin']}
        component={History}
      />
      <Route
        path="/nao-permitido"
        exact
        resource={false}
        roles={['admin']}
        component={Forbidden}
      />
      <Route
        path="/paciente/:id"
        exact
        resource={false}
        roles={['admin']}
        component={TimelinePage}
      />
      <Route
        path="/paciente/:id/evolucao-completa"
        exact
        resource={false}
        roles={['admin']}
        component={PatientEvolution}
      />
      <Route
        path="/changelog"
        exact
        resource={false}
        roles={['admin']}
        component={ChangelogPage}
      />
    </Switch>
  );
}
