import { actionTypes } from '../actions/SuspiciousInfectionsActions';
import Dataset from '../services/Dataset';

const initialState = {
  totalSuspicion: null,
  totalAssessed: null,
  pendingAssessments: null,
  mediumProbability: null,
  highProbability: null,
  vapCount: null,
  tracheoCount: null,
  pneumoCount: null,
  ssiCount: null,
  utiCount: null,
  pbiCount: null,
  countOfinfectionsPerTypeOnPeriod: [],
  isaPerformanceOnPeriod: [],
  loading: false,
};

function assessedAndUnassessedInfectionsCount(
  patientsWithSuspicion,
  assessedArray
) {
  const patientsWithSuspicionCount = patientsWithSuspicion.length;

  Dataset.filterPatientsWithPendingAssessments(
    patientsWithSuspicion,
    assessedArray
  );

  let patientWithAssessmentsCount =
    patientsWithSuspicionCount - patientsWithSuspicion.length;

  const patientsWithoutAssessmentCount =
    patientsWithSuspicionCount - patientWithAssessmentsCount;

  patientsWithSuspicion = Dataset.patientsWithAssessments(patientsWithSuspicion.map(patient => patient._source), assessedArray);

  return [patientsWithSuspicion.length, patientsWithoutAssessmentCount];
}

export const suspiciousInfectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DASHBOARD_LOADING:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_SUSPICIOUS_INFECTIONS:
      const {
        dashboardInformations: {
          dashboardStats: { aggregations = {} },
          dashboardStats,
          suspiciousInfections: {
            responses: [
              {
                hits: { hits: patientsWithSuspicion = [] },
              },
            ],
          },
          assessedInfections,
          filterParams: { initialDate, finalDate },
        },
      } = action;

      const assessedArray = assessedInfections?.aggregations?.terms?.buckets || [];
      const [low, medium, high] = aggregations?.by_group?.buckets;
      const [
        totalAssessed,
        pendingAssessments,
      ] = assessedAndUnassessedInfectionsCount(
        patientsWithSuspicion,
        assessedArray
      );

      const suspiciousInfections = aggregations?.performance?.buckets || [];

      const isaPerformance = Dataset.getIsaPerformanceChartData(
        suspiciousInfections,
        assessedArray,
        initialDate,
        finalDate
      );

      return {
        ...state,
        patientsWithSuspicion: patientsWithSuspicion,
        totalSuspicion: dashboardStats?.hits?.total?.value,
        highProbability: high?.doc_count,
        mediumProbability: medium?.doc_count,
        lowProbability: low?.doc_count,
        totalAssessed,
        pendingAssessments,
        isaPerformanceOnPeriod: [...(isaPerformance || [])],
        countOfinfectionsPerTypeOnPeriod:
          aggregations?.infeccoes_por_dia?.buckets || [],
        vapCount: aggregations?.pav?.buckets?.shift()?.doc_count,
        tracheoCount: aggregations?.traqueo?.buckets?.shift()?.doc_count,
        pneumoCount: aggregations?.pnm?.buckets?.shift()?.doc_count,
        ssiCount: aggregations?.isc?.buckets?.shift()?.doc_count,
        utiCount: aggregations?.itu?.buckets?.shift()?.doc_count,
        pbiCount: aggregations?.ipcs?.buckets?.shift()?.doc_count,
        loading: false,
      };

    case actionTypes.GET_SUSPICIOUS_INFECTIONS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
