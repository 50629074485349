import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  locationTitle: {
    fontFamily: 'DM Mono',
    fontSize: '13px',
    lineHeight: '18px',
  },
  accordionSummary: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '19px',
  },
  accordionDetails: {
    display: 'block!important',
    paddingBottom: 0,
    fontFamily: 'DM Mono',
    fontSize: '13px',
    lineHeight: '19px',
    whiteSpace: 'break-spaces'
  },
  accordion: {
    display: 'block!important',
    background: 'none',
    boxShadow: 'none'
  },
  tableContainer: {
    boxShadow: 'none',
    backgroundColor: theme.palette.gray['50'],
    padding: '0 16px 0 28px',

    '& tr:last-child th': {
      borderBottom: 'none'
    }
  },
  probabilityCircle: {
    width: 8,
    height: 8,
    backgroundColor: '#F86975',
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    left: '-16px',
    transform: 'translate(0, -50%)'
  },
  tableContent: {
    padding: '12px 0',
    paddingRight: '0 !important',
    borderBottom: '1px solid rgba(62, 66, 71, 0.16)',
    fontSize: 13,
    color: theme.palette.neutral.black['100'],
    position: 'relative',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',

    '& svg': {
      color: '#F86975'
    }
  },
  buttonBase: {
    width: '100%'
  },
  chip: {
    marginRight: 5,
    marginTop: 5,
    cursor: 'pointer'
  },
  seeMore: {
    fontFamily: 'DM Mono',
    fontSize: '12px',
    lineHeight: '18px',
    cursor: 'pointer',
  },
  seeCompleteEvolutionBtn: {
    fontFamily: 'DM Mono',
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.primary.main,
    textTransform: 'initial',
  },
  subtitle: {
    fontWeight: 'bold'
  }
}))

export default useStyles;
