import React, { useMemo } from 'react';
import { Box, Select } from '@material-ui/core';

import { withFormikField } from '../utils';
import { withFieldControl } from '../FieldControl';
import { BaseInput } from '../BaseInput';

import { MenuItem, Menu } from './styles';
import DateRangeIcon from '@material-ui/icons/DateRange';

export const BaseSelectInput = (props) => {
  const { options, MenuProps: receivedMenuProps, ...selectProps } = props;
  const { disabled } = selectProps;

  const MenuProps = useMemo(
    () => ({ component: Menu, ...receivedMenuProps }),
    [receivedMenuProps]
  );

  const renderedOptions = useMemo(
    () =>
      (options || []).map((option) => (
        <MenuItem
          key={option.value}
          {...option}
          disabled={disabled || option.disabled}
        >
          <Box display="flex" flexDirection="row" alignItems="center" style={{gap: "5px", "margin-right": "10px"}}>
            {
              !!option?.icon ? (
                option.icon
              ) : (
                <DateRangeIcon fontSize='small'></DateRangeIcon>
              )
            }
            {option.label}
          </Box>
        </MenuItem>
      )),
    [options, disabled]
  );

  return (
    <Select MenuProps={MenuProps} input={<BaseInput />} {...selectProps}>
      {renderedOptions}
    </Select>
  );
};
BaseSelectInput.defaultProps = {
  displayEmpty: true,
};

// Campo com FormControl (label, helperText, disabled)
export const SelectInput = withFieldControl(BaseSelectInput);

// Componente pronto para utilização como formik field
export const SelectField = withFormikField(SelectInput);
