import { useState, useCallback, useMemo } from 'react'

export const useToggleState = (initialOpen = false) => {
  const [open, setOpen] = useState(initialOpen);

  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleToggle = useCallback(() => setOpen((o) => !o), [setOpen]);

  const result = useMemo(
    () => ({ open, handleOpen, handleClose, handleToggle }),
    [open, handleOpen, handleClose, handleToggle]
  );

  return result;
};