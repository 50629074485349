import React, { useMemo, useCallback } from 'react';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  RadioGroup,
} from '@material-ui/core';
import { withFieldControl } from '../FieldControl';
import { withFormikField } from '../utils';
import { CheckboxOption } from './CheckboxOption';

export const BaseCheckboxGroup = (props) => {
  const { options, ...formGroupProps } = props;

  const { name, value } = formGroupProps;
  const renderedOptions = useMemo(
    () =>
      (options || []).map((option) => (
        <CheckboxOption
          key={option.value}
          name={name}
          checked={value?.includes(option.value)}
          {...option}
        />
      )),
    [options, name, value]
  );

  return <FormGroup {...formGroupProps}>{renderedOptions}</FormGroup>;
};
BaseCheckboxGroup.defaultProps = {
  row: true,
};

// Campo com FormControl (label, helperText, disabled)
export const CheckboxGroupInput = withFieldControl(BaseCheckboxGroup);
CheckboxGroupInput.defaultProps = {
  margin: 'none',
};

// Componente pronto para utilização como formik field
export const CheckboxField = withFormikField(CheckboxGroupInput);
