import moment from "moment";

export const patientFactsRequestBody = (
        paciente_id,
        initialDate = moment('2020-01-01'),
        finalDate = moment()
    ) => ({
    size: 30000,
    from: 0,
    query: {
      bool: {
        should: [
          {
            bool: {
              must: {
                term: { paciente_id: paciente_id },
              },
              filter: {
                term: {
                  _index: 'laudos',
                },
              },
            },
          },
          {
            bool: {
              must: {
                term: { paciente_id: paciente_id },
              },
              filter: {
                term: {
                  _index: 'suspeitas',
                },
              },
            },
          },
          {
            bool: {
              must: {
                term: { prontuario: paciente_id },
              },
              filter: {
                term: {
                  _index: 'encontros',
                },
              },
              should: [
                {
                  range: {
                    dt_encontro: {
                      gte: initialDate.toISOString(),
                      lte: finalDate.toISOString(),
                    },
                  },
                },
              ]
            },
          },
          {
            bool: {
              must: {
                term: { paciente_id: paciente_id },
              },
              filter: {
                term: {
                  _index: 'exames',
                },
              },
            },
          },
          {
            bool: {
              must: {
                term: { paciente_id: paciente_id },
              },
              filter: {
                term: {
                  _index: 'sinais-vitais',
                },
              },
            },
          },
          {
            bool: {
              must: {
                term: { paciente_id: paciente_id },
              },
              filter: {
                term: {
                  _index: 'medicamentos',
                },
              },
            },
          },
          {
            bool: {
              must: {
                term: { paciente_id: paciente_id },
              },
              filter: {
                term: {
                  _index: 'procedimentos',
                },
              },
            },
          },
        ],
      },
    },
  });
