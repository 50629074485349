import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { selectTimelineAssessments } from './selectTimelineAssessments';

const memoEmpty = [];
export const makeSelectTimelineInfectionAssessments = () => {
  const selectInfectionAssessments = createSelector(
    selectTimelineAssessments,
    (_, date) => date,
    (assessments, date) =>
      !assessments?.length
        ? memoEmpty
        : assessments
            .filter(
              (assessment) =>
                moment(assessment.avalDtInfec).startOf('day').valueOf() ===
                moment(date).startOf('day').valueOf()
            )
            .sort((a) => (a.status === 'positiva' && !a.comunitaria ? 0 : 1))
  );
  return selectInfectionAssessments;
};

export const useTimelineInfectionAssessments = (date) => {
  const select = useMemo(makeSelectTimelineInfectionAssessments, []);
  const selector = useCallback((state) => select(state, date), [date, select]);
  return useSelector(selector);
};
