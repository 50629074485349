import React, { useEffect, useState, useMemo } from 'react';
import { any, array, func, bool } from 'prop-types';
import moment from 'moment';
import styles, {
  StyledTable,
  StyledTableCell,
  StyledTableContainer,
  StyledTableRow,
} from './styles';
import { Title, Header } from './styles';
import Pacientes from '../../templates/Pacientes/index';
import TopFilter from '../../components/organisms/TopFilter';
import { FilterSidebar } from '../../components/organisms/FilterSidebar';
import { FilterGroup } from '../../templates/Pacientes/styles';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import SearchTextField from "../../components/atoms/SearchTextField";
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { assessmentsHistory } from '../../actions/AssessmentsHistoryActions';
import { useGetPatientEvaluationsQuery } from '../../services/evaluations/evaluations.service'
import {
  setOrderByFilterAction,
  resetFiltersAction,
  setStatusFilterAction,
  setInfectionFilterAction,
  setAssessmentsHistoryPeriodDate,
} from '../../actions/AssessmentsHistoryFiltersActions';
import { useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import ChipsSuccess from '../../components/molecules/HorizontalChipsSuccess';
import ChipsError from '../../components/molecules/HorizontalChipsError';
import DateRangeInput from '../../components/molecules/DateRangeInput/index';
import EvaluationTooltip from '../../components/atoms/EvaluationTooltip';
import {setDashboardPeriodDate} from "../../actions/DashboardPeriodFilterActions";
import CsvDownloader from 'react-csv-downloader';
import { Button } from '@material-ui/core';
import { infectionTypes } from '../../actions/InfectionTypesActions';
import HistoricoTableRow from './HistoricoTableRow/HistoricoTableRow';
import { useGetAllPatientQuery, useGetAssessmentHistoryQuery } from '../../services';
import { useGetAllSuspicionsQuery } from '../../services/suspicions/suspicions.service';
import { LoggedUserCompaniesForm } from '../../components/organisms/LoggedUserCompaniesForm';

const Historico = (props) => {
  const [inputNomePaciente, setInputNomePaciente] = useState('');
  const {
    infectionTypes,
    assessmentsHistory,
    initialDate,
    finalDate,
    orderByField,
    updateInfectionFilter,
    updateOrderByFilter,
    infections,
    positiveAssessments,
    negativeAssessments,
    updateAssessmentsHistoryInitialDate,
    updateAssessmentsHistoryFinalDate,
    updateStatusFilter,
    getAssessmentsHistoryList,
    resetFilters,
    loading,
    globalStateOrderBy,
    globalStateInfections,
    globalStateOnlyInconclusive,
    setFinalDate,
    setInitialDate,
    getInfectionTypes
  } = props;
  const classes = styles();
  const history = useHistory();
  const [idList, setIdList] = useState([]);
  const EVALUATION_RULES = [
    'Robô ISA identificou uma ou mais suspeitas e todas elas foram avaliadas;',
    'Robô ISA não identificou a suspeita (OUTRA) e o paciente possui ao menos uma avaliação;',
  ];

  const [filterParams, setFilterParams] = useState({
    itensPerPage: 10,
    page: 1,
    name: '',
    infectionType: [
        "pav",
        "ipcs",
        "traqueo",
        "isc",
        "itu",
        "pnm",
        'outra',
        'comunitaria',
        'sem infecção'
    ],
    status: '',
    orderBy: orderByField,
  });

  const {
    data: patientEvaluations,
    isFetching: patientEvaluationsLoading
  } = useGetPatientEvaluationsQuery(filterParams);

  const {
    isLoading: isAssessmentHistoryLoading,
    data: assessmentsResult,
    refetch: refetchAssessmentHistory
  } = useGetAssessmentHistoryQuery({
    initialDate,
    finalDate,
    orderByField,
    infectionTypes,
    positiveAssessments,
    negativeAssessments,
  });
  const { isLoading: isAllPatientLoading, data: allPatients } = useGetAllPatientQuery();
  const {
    isLoading: isAllSuspicionsLoading,
    data: allSuspicionsApi,
    refetch: refetchAllSuspicions,
  } = useGetAllSuspicionsQuery({
    initialDate,
    finalDate,
    orderByField,
    infectionTypes,
    positiveAssessments,
    negativeAssessments,
  });

  const [allSuspicions, setAllSuspicions] = useState({});

  useEffect(() => {
    if(allSuspicionsApi) {
      setAllSuspicions(allSuspicionsApi)
    }
  }, [allSuspicionsApi])

  useEffect(() => {
    getInfectionTypes();
  }, [getInfectionTypes]);

  const clearInputNomePaciente = () => {
    setInputNomePaciente("");

    setFilterParams(prevState => ({ ...prevState, name: '' }))
  }

  const getStatusQuery = useMemo(() => {
    const allChecked = positiveAssessments && negativeAssessments;
    const allUnchecked = !positiveAssessments && !negativeAssessments
    const onlyPositiveChecked = positiveAssessments && !negativeAssessments
    const onlyNegativeChecked = !positiveAssessments && negativeAssessments

    if(allChecked) return '';
    if(allUnchecked) return 'inconclusiva';
    if(onlyPositiveChecked) return 'positiva';
    if(onlyNegativeChecked) return 'negativa';
  }, [positiveAssessments, negativeAssessments])


  useEffect(() => {
    let infectionsFilter = infections;
    if(!infections.length) {
      infectionsFilter = [...infectionTypes, 'outra', 'comunitaria', 'sem infecção']
    }

    setFilterParams(prevState => ({
      ...prevState,
      infectionType: infectionsFilter,
      startDate: initialDate,
      endDate: finalDate,
      status: getStatusQuery,
      orderBy: orderByField,
    }));
  }, [
    initialDate,
    inputNomePaciente,
    finalDate,
    orderByField,
    infections,
    positiveAssessments,
    negativeAssessments,
    infectionTypes,
  ]);



  // useEffect(() => {
  //   setIdList(assessmentsHistory.map((item) => item.paciente_id));
  // }, [assessmentsHistory]);

  const handleBuscarHistoricoPorPaciente = (event) => {
    event.preventDefault();

    setFilterParams(prevState => ({
      ...prevState,
      name: inputNomePaciente
    }));
  }


  const assessmentsHistoryCSV = (assessmentsHistory) => {
    let result = []
    assessmentsHistory.forEach((infection) => {
      infection.assessments.forEach((assessment)=>{
        let patientInfection = Object.assign({},infection);
        delete(patientInfection.assessments)
        result.push({
          ...patientInfection,
          ...assessment

        })
      })
    })
    if(result.length > 0){
      const cols = Object.keys(result[0]).map(assessment=>({
        id: assessment,
        displayName: assessment
      }));
      return <CsvDownloader
        extension=".csv"
        columns={cols}
        datas={result}
        filename={`ROBOISA_avaliacoes_${new Date()}`}
        text="Download"
      />
    }


  }

  const TableRows = useMemo(()=> {
    let infectionsFilter = infections;

    if (!infections.length) {
      infectionsFilter = infectionTypes;
    }

    return (
      <HistoricoTableRow
        isAssessmentHistoryLoading={isAssessmentHistoryLoading}
        assessmentsResult={assessmentsResult}
        isAllPatientLoading={isAllPatientLoading}
        allPatients={allPatients}
        isAllSuspicionsLoading={isAllSuspicionsLoading}
        allSuspicions={allSuspicions}
        inputNomePaciente={inputNomePaciente}
        infectionsFilter={infectionsFilter}
        positiveAssessments={positiveAssessments}
        negativeAssessments={negativeAssessments}
        orderByField={orderByField}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        patientEvaluations={patientEvaluations}
        patientEvaluationsLoading={patientEvaluationsLoading}
      />
    )
  },[
    isAssessmentHistoryLoading,
    assessmentsResult,
    isAllPatientLoading,
    allPatients,
    isAllSuspicionsLoading,
    allSuspicions,
    inputNomePaciente,
    infectionTypes,
    infections,
    positiveAssessments,
    negativeAssessments,
    orderByField,
    filterParams,
    patientEvaluations,
    patientEvaluationsLoading,
  ])


  return (
    <Pacientes>
      <Header>
        <Grid container alignItems="center">
          <Grid item sm={12} md={8}>
            <Title>Histórico de avaliações</Title>
            <EvaluationTooltip ruleTitle="Avaliado" rules={EVALUATION_RULES} />
          </Grid>
          <Grid item xs={12} md={4}>
            <LoggedUserCompaniesForm>
            </LoggedUserCompaniesForm>
            <DateRangeInput
              initialDate={[initialDate, setInitialDate]}
              finalDate={[finalDate, setFinalDate]}
            />
          </Grid>
        </Grid>
      </Header>
      <FilterGroup>
        <TopFilter
          showOnlyInconclusiveFilter={false}
          sendInfectionsToParent={updateInfectionFilter}
          sendOrderByToParent={updateOrderByFilter}
          infectionTypes={infectionTypes}
          onlyInconclusive={globalStateOnlyInconclusive}
          orderBy={globalStateOrderBy}
          infections={globalStateInfections}
        />
      </FilterGroup>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <StyledTableContainer component={Paper}>
            {patientEvaluationsLoading && (<LinearProgress />)}
            <form noValidate autoComplete="off" onSubmit={handleBuscarHistoricoPorPaciente}>
              <SearchTextField label="Busca por nome do paciente"
                value={inputNomePaciente}
                onChange={e => setInputNomePaciente(e.target.value)}
                fullWidth={true}
                clearInput={clearInputNomePaciente}
              />
            </form>
            {loading ? <LinearProgress /> : ''}
            {TableRows}
        </StyledTableContainer>

        </Grid>
        <Grid item xs={12} md={3}>
          <FilterSidebar
            historico={true}
            notifyParentOnReset={resetFilters}
            notifyParentOnStatusChange={updateStatusFilter}
          />
        </Grid>
      </Grid>
    </Pacientes>
  );
};

Historico.propTypes = {
  assessmentsHistory: array,
  infectionTypes: array,
  patientList: array,
  initialDate: any,
  finalDate: any,
  positiveAssessments: bool,
  negativeAssessments: bool,
  getAssessmentsHistoryList: func,
  updateAssessmentsHistoryInitialDate: func,
  updateAssessmentsHistoryFinalDate: func,
  updateInfectionFilter: func,
  updateOrderByFilter: func,
  updateStatusFilter: func,
  resetFilters: func,
  infections: array,
  loading: bool,
};

const mapStateToProps = (state) => ({
  assessmentsHistory: state.assessmentsHistory.list,
  loading: state.assessmentsHistory.loading,
  infectionTypes: state.infectionTypes,
  initialDate: state.periods.dashboard.initialDate,
  finalDate: state.periods.dashboard.finalDate,
  orderByField: state.assessmentsHistoryFilter.orderByField,
  infections: state.assessmentsHistoryFilter.infections,
  positiveAssessments: state.assessmentsHistoryFilter.positive,
  negativeAssessments: state.assessmentsHistoryFilter.negative,
  globalStateInfections: state.assessmentsHistoryFilter.infections,
  globalStateOnlyInconclusive: state.assessmentsHistoryFilter.onlyInconclusive,
  globalStateOrderBy: state.assessmentsHistoryFilter.orderByField,
});

const mapDispatchToProps = (dispatch) => ({
  getAssessmentsHistoryList: (filterParams) =>
    dispatch(assessmentsHistory(filterParams)),
  setInitialDate: (date, initial) =>
    dispatch(setDashboardPeriodDate(date, initial)),
  setFinalDate: (date, initial = false) =>
    dispatch(setDashboardPeriodDate(date, initial)),

  // TODO remover actions não utilizadas
  updateAssessmentsHistoryInitialDate: (date) =>
    dispatch(setAssessmentsHistoryPeriodDate(date)),

  // TODO remover actions não utilizadas
  updateAssessmentsHistoryFinalDate: (date) =>
    dispatch(setAssessmentsHistoryPeriodDate(date, false)),

  updateOrderByFilter: (fieldName) =>
    dispatch(setOrderByFilterAction(fieldName)),

  updateInfectionFilter: (infections) =>
    dispatch(setInfectionFilterAction(infections)),

  updateStatusFilter: (status) => dispatch(setStatusFilterAction(status)),
  resetFilters: () => dispatch(resetFiltersAction()),

  getInfectionTypes: () => dispatch(infectionTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Historico);
