import { indicatorsEnum } from '../../../constants/indicators';

export const SQUARE_WIDTH = 40;
export const SQUARE_HEIGHT = 45;

export const ENTRY_X_CENTER = SQUARE_WIDTH / 2;
export const ENTRY_Y_CENTER = SQUARE_HEIGHT / 2;

export const X_AXIS_WIDTH = 1;
export const Y_AXIS_WIDTH = 4;

export const ENTRY_X_LENGTH = SQUARE_WIDTH + X_AXIS_WIDTH
export const ENTRY_Y_LENGTH = SQUARE_HEIGHT + Y_AXIS_WIDTH

export const INDICATOR_LEGEND_WIDTH = 148;
export const INDICATOR_LEGEND_X_LENGTH = INDICATOR_LEGEND_WIDTH + X_AXIS_WIDTH

export const INDICATOR_LEGEND_HEIGHT = SQUARE_HEIGHT;
export const INDICATOR_LEGEND_Y_LENGTH = ENTRY_Y_LENGTH


export const CHART_INDICATORS = [
  indicatorsEnum['suspeitas'],
  indicatorsEnum['infeccoes'],
  indicatorsEnum['encontros'],
  indicatorsEnum['exames'],
  indicatorsEnum['medicamentos'],
  indicatorsEnum['sinais-vitais'],
  indicatorsEnum['procedimentos'],
  indicatorsEnum['laudos'],
];
