// Retorna os indicadores disponíveis para a data selecionada
import { createSelector } from '@reduxjs/toolkit';
import { selectTimelineDates } from './selectTimelineDates';
import { selectTimelineCursor } from './selectTimelineCursor';
import { useSelector } from 'react-redux';
import { keys, pickBy, pick } from 'lodash';
import { CHART_INDICATORS } from '../../TimelineChart/constants';

const empty = [];
export const selectTimelineAvailableIndicators = createSelector(
  [selectTimelineDates, selectTimelineCursor],
  (timelineDates, cursor) => {
    if (!timelineDates || !cursor.date) return empty;

    const availableIndicators = keys(
      pickBy(timelineDates, (dates) => dates.includes(cursor.date.valueOf()))
    );

    return CHART_INDICATORS.filter((indicator) =>
      availableIndicators.includes(indicator)
    );
  }
);
export const useTimelineAvailableIndicators = () =>
  useSelector(selectTimelineAvailableIndicators);

export const selectTimelineIndicatorCursorIndex = createSelector(
  [selectTimelineAvailableIndicators, selectTimelineCursor],
  (availableIndicators, cursor) => {
    if (!availableIndicators?.length || !cursor) return;
    return availableIndicators.indexOf(cursor.indicator);
  }
);

export const useTimelineIndicatorCursorIndex = () =>
  useSelector(selectTimelineIndicatorCursorIndex);

// retorna o próximo indicador disponível para a data selecionada
export const selectTimelineNextIndicator = createSelector(
  [selectTimelineAvailableIndicators, selectTimelineIndicatorCursorIndex],
  (availableIndicators, indicatorCursorIndex) => {
    return availableIndicators?.[indicatorCursorIndex + 1];
  }
);
export const useTimelineNextIndicator = () =>
  useSelector(selectTimelineNextIndicator);

// retorna o próximo indicador disponível para a data selecionada
export const selectTimelinePrevIndicator = createSelector(
  [selectTimelineAvailableIndicators, selectTimelineIndicatorCursorIndex],
  (availableIndicators, indicatorCursorIndex) => {
    return availableIndicators?.[indicatorCursorIndex - 1];
  }
);
export const useTimelinePrevIndicator = () =>
  useSelector(selectTimelinePrevIndicator);
