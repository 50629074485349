import React, { useMemo } from 'react';
import { Divider, Box, Collapse } from '@material-ui/core';
import { ExamResultItem } from './ExamResultItem';
import { groupBy } from 'lodash';
import { useToggleState } from '../../../../../utils/useToggleState';
import { ExamTitle } from './ExamTitle';
import { ExamChevron } from './ExamChevron';
import { ExamButton } from './ExamButton';
import { withStyles } from '@material-ui/core/styles';

const ResistanceProfileTitle = withStyles((theme) => ({
  root: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    color: theme.palette.neutral.black['88'],
  },
}))(Box);

export const ExamResults = (props) => {
  const { results, onOpenHistogram } = props;
  const { resistance, others } = useMemo(() => {
    if (!results?.length) return { resistance: [], others: [] };
    return groupBy(results, ({ resultado = '' }) =>
      ['resistente', 'sensível', 'i'].includes(resultado?.toLowerCase())
        ? 'resistance'
        : 'others'
    );
  }, [results]);

  const { open, handleToggle } = useToggleState();

  return (
    <>
      <>
        {others?.map((exam, resultIndex) => (
          <React.Fragment key={exam.id}>
            {resultIndex !== 0 && (
              <Divider style={{ margin: '0 4px 0 12px' }} />
            )}
            <ExamResultItem exam={exam} onOpenHistogram={onOpenHistogram} />
          </React.Fragment>
        ))}
      </>
      {!!resistance?.length && (
        <>
          <ExamButton
            onClick={handleToggle}
            style={{ backgroundColor: open ? 'white' : undefined }}
          >
            <ExamTitle
              title={
                <ResistanceProfileTitle>
                  Perfil de resistência
                </ResistanceProfileTitle>
              }
              size="small"
              action={
                <ExamChevron
                  style={{ transform: `rotate(${open ? 90 : -90}deg)` }}
                />
              }
            />
          </ExamButton>
          <Collapse
            in={open}
            style={{ backgroundColor: open ? 'white' : undefined }}
          >
            <>
              {resistance.map((exam, resultIndex) => (
                <React.Fragment key={exam.id}>
                  {resultIndex !== 0 && (
                    <Divider style={{ margin: '0 4px 0 12px' }} />
                  )}
                  <ExamResultItem
                    exam={exam}
                    onOpenHistogram={onOpenHistogram}
                  />
                </React.Fragment>
              ))}
            </>
          </Collapse>
        </>
      )}
    </>
  );
};
