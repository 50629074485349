import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setPage } from '../slice';

export const useSetPatientsPage = () => {
  const dispatch = useDispatch();

  const handleSetPatientsPage = useCallback(
    (page) => dispatch(setPage(page)),
    [dispatch]
  );

  return handleSetPatientsPage;
};
