import { createApi } from '@reduxjs/toolkit/query/react'
import {  baseQueryWithReauth } from '../baseQuery'
import { transformResponseHits } from '../common/utils/transformResponse'

export const hospitalUnityApi = createApi({
  reducerPath: 'hospitalUnityApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({ 
    getHospitalUnity: build.query({
      query: (companyId) => ({
        url: `/unities`,
        method: 'GET',
        params: {
          ...(companyId ? {"companyId": companyId} : {})
        },
      }),
      transformResponse: transformResponseHits,
    }),
  }),
})

export const { useGetHospitalUnityQuery } = hospitalUnityApi
