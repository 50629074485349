import React from 'react';
import { useTimelineVisitedIndicator } from '../slices';
import { SquareEntry } from './TimelineEntry';
import { CHART_INDICATORS } from './constants';

export const TimelineVisitedIndicator = ({ indicator }) => {
  const dates = useTimelineVisitedIndicator(indicator);

  return (
    <>
      {dates.map((date) => (
        <SquareEntry
          key={`${indicator}:${date}`}
          date={date}
          indicator={indicator}
          color="#fff"
        />
      ))}
    </>
  );
};

export const TimelineVisited = () => (
  <g
    data-name="visited-entries"
    style={{ pointerEvents: 'none', opacity: 0.32 }}
  >
    {CHART_INDICATORS.map((indicator) => (
      <TimelineVisitedIndicator key={indicator} indicator={indicator} />
    ))}
  </g>
);
