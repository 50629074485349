import { createSelector } from '@reduxjs/toolkit';
import { selectHistorySlice } from './selectHistorySlice';
import { useSelector } from 'react-redux';

export const selectHistoryPatientName = createSelector(
  selectHistorySlice,
  (historyState) => historyState.name
);
export const useHistoryPatientName = () =>
  useSelector(selectHistoryPatientName);
