import React, { useRef, useCallback } from 'react';
import { Chip } from '../../../components/atoms/Chip';
import { formatIndicatorLabel } from '../../../utils/formatters';
import { IndicatorPopover } from './IndicatorPopover';
import { PopoverContent } from './PopoverContent';
import { Grid } from '@material-ui/core';
import { useIndicatorPopoverIsActive, useSetIndicatorPopover } from '../slices';

export const IndicatorItem = (props) => {
  const { indicator, isHighlighted } = props;

  // const isHighlighted = useIndicatorIsHighlighted(indicator);

  const ref = useRef(null);

  const isActive = useIndicatorPopoverIsActive(indicator);

  const setIndicatorPopover = useSetIndicatorPopover();
  const handleClick = useCallback(() => setIndicatorPopover(indicator), [
    setIndicatorPopover,
    indicator,
  ]);
  const handleClose = useCallback(() => setIndicatorPopover(undefined), [
    setIndicatorPopover,
  ]);

  return (
    <>
      <Grid item xs="auto">
        <Chip
          ref={ref}
          label={`${formatIndicatorLabel(indicator)}`}
          onClick={handleClick}
          variant={isHighlighted ? 'danger' : 'gray'}
        />
        <IndicatorPopover
          anchorEl={ref.current}
          open={isActive}
          onClose={handleClose}
          indicator={indicator}
        >
          <PopoverContent indicator={indicator} />
        </IndicatorPopover>
      </Grid>
    </>
  );
};
