import React from 'react';
import { Box } from '@material-ui/core';
import { useTimelineActiveIndicator } from '../../../slices';
import { PaperFact } from '../../../../../components/molecules/PaperFact';
import moment from 'moment';

export const TimelineDrawerProcedimentos = () => {
  const items = useTimelineActiveIndicator();
  const highlight = items.some((item) => item.criterio === 'sim');

  const parseProcedureDate = (date) => {
    return new Date(date).toLocaleTimeString('pt-BR', {
      timeStyle: 'short',
      timeZone: 'UTC'
    })
  }

  return (
    <Box padding={2}>
      {items.map((item, index) => (
        <PaperFact
          key={`procedimento.${index}`}
          theme={item.criterio === 'sim' ? 'danger' : 'default'}
          title={
            <Box>
              {item?.nome_medico && (<Box>Médico: {item.nome_medico}</Box>)}
              {item?.nome_procedimentos && (<Box my="5px">Procedimento(s): {item?.nome_procedimentos} </Box>)}
              <Box>Duração: {item.tempo_cirurgia}</Box>
              <Box mt="5px">
                <Box>Horário do procedimento: {parseProcedureDate(item?.dthr_procedimento)} - {parseProcedureDate(item?.dthr_fim_procedimento)}</Box>
              </Box>
            </Box>
          }
          content={item?.texto_cirurgia}
          highlight={highlight}
        />
      ))}
    </Box>
  );
}
