import React from 'react';
import { Grid } from '@material-ui/core';
import { DashboardPanel } from '../../DashboardLayout';
import { InfectionsBySex } from './InfectionsBySex';
import { InfectionsByAge } from './InfectionsByAge';
import { InfectionsByLocal } from './InfectionsByLocal';

export const DashboardInfectionCharts = ({ indicators }) => {
  if(!indicators) {
    return ''
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={4}>
        <DashboardPanel title="Por sexo">
          <InfectionsBySex indicators={indicators} />
        </DashboardPanel>
      </Grid>
      <Grid item sm={8}>
        <DashboardPanel title="Por faixa etária">
          <InfectionsByAge indicators={indicators} />
        </DashboardPanel>
      </Grid>
      <Grid item sm={12}>
        <DashboardPanel title="Por local">
          <InfectionsByLocal indicators={indicators} />
        </DashboardPanel>
      </Grid>
    </Grid>
  );
};
