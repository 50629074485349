/**
 * Enumerador de Indicadores
 *
 * Uma suspeita pode ser avaliada como um dos seguintes status.
 *
 * @constant
 * @type {{ 'sinais-vitais': 'sinais-vitais', 'exames': 'exames', 'laudos': 'laudos', 'movimentacao': 'movimentacao', 'procedimentos': 'procedimentos', 'medicamentos': 'medicamentos', 'encontros': 'encontros', 'suspeitas': 'suspeitas', 'avaliacoes': 'avaliacoes', 'infeccoes': 'infeccoes' }}
 */
 export const indicatorsEnum = {
  'sinais-vitais': 'sinais-vitais',
  'exames': 'exames',
  'laudos': 'laudos',
  'movimentacao': 'movimentacao',
  'procedimentos': 'procedimentos',
  'medicamentos': 'medicamentos',
  'encontros': 'encontros',
  'suspeitas': 'suspeitas',
  'avaliacoes': 'avaliacoes',
  'infeccoes': 'infeccoes',
};

/**
 * Lista de Indicadores
 *
 * @constant
 * @type {['sinais-vitais', 'exames', 'laudos', 'movimentacao', 'procedimentos', 'medicamentos', 'suspeitas', 'avaliacoes', 'infeccoes']}
 */
export const indicatorsList = Object.keys(indicatorsEnum)
