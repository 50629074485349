import React, { useMemo, useCallback } from 'react';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  RadioGroup,
} from '@material-ui/core';
import { withFieldControl } from '../FieldControl';
import { withFormikField } from '../utils';
import { SwitchOption } from './SwitchOption';
import { useFormikContext } from 'formik';

export const BaseSwitchGroup = (props) => {
  const { options, ...formGroupProps } = props;

  const { name, value } = formGroupProps;

  const renderedOptions = useMemo(
    () =>
      (options || []).map((option) => (
        <SwitchOption
          key={option.value}
          name={name}
          checked={value?.includes(option.value)}
          {...option}
          fullWidth
        />
      )),
    [options, name, value]
  );

  return <FormGroup {...formGroupProps}>{renderedOptions}</FormGroup>;
};
BaseSwitchGroup.defaultProps = {
  row: false,
};

// Campo com FormControl (label, helperText, disabled)
export const SwitchGroupInput = withFieldControl(BaseSwitchGroup);
SwitchGroupInput.defaultProps = {
  margin: 'none',
};

// Componente pronto para utilização como formik field
export const SwitchField = withFormikField(SwitchGroupInput);
