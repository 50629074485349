import React from 'react';
import { withStyles, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import { StatTitle } from '../StatTitle';
import { StatValue } from '../StatValue';
import { StatHelper } from '../StatHelper';
import { DashboardCard } from '../../DashboardLayout';

export const GlobalStatTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: `#000`,
    fontFamily: theme.MuiTypography.family.secondary,
    fontSize: '12px',
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
    fontSize: 16,
  },
}))(function StyledGlobalStatTooltip({ classes, info }) {
  const { icon: iconClassName, ...tooltipClasses } = classes;

  return (
    <Tooltip classes={tooltipClasses} title={info} placement="bottom">
      <InfoOutlinedIcon className={iconClassName} />
    </Tooltip>
  );
});

export const GlobalStat = withStyles((theme) => ({
  root: {
    position: 'relative',
    paddingRight: '40px',
  },
  title: {
    marginBottom: theme.spacing(1),
    minHeight: '30px',
  },
  value: {
    marginBottom: theme.spacing(0.5),
  },
  info: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(1.5),
  },
  helper: {},
}))(function StyledGlobalStat(props) {
  const { classes, className, title, value, info, helper } = props;

  return (
    <DashboardCard className={clsx(classes.root, className)}>
      <GlobalStatTooltip info={info} classes={{ icon: classes.info }} />
      <StatTitle className={classes.title}>{title}</StatTitle>
      <StatValue className={classes.value} size="large">
        {value}
      </StatValue>
      <StatHelper className={classes.helper}>{helper}</StatHelper>
    </DashboardCard>
  );
});
