import React, { useRef, useCallback } from 'react';
import { Menu, MenuItem, Button, withStyles } from '@material-ui/core';
import ChevronDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { indicatorsEnum } from '../../../../constants/indicators';
import {
  useTimelineAvailableIndicators,
  useTimelineCursor,
  useSetTimelineCursorIndicator,
} from '../../slices';
import { CHART_INDICATORS } from '../../TimelineChart/constants';
import { formatIndicatorLabel } from '../../../../utils/formatters';
import { useToggleState } from '../../../../utils/useToggleState';

const indicatorsListLabels = CHART_INDICATORS;

export const SelectButton = withStyles((theme) => ({
  root: {
    padding: '4px 0',
    fontFamily: 'Nunito',
    textTransform: 'none',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 700,
  },
}))(Button);

export const SelectIndicator = () => {
  const { indicator } = useTimelineCursor();
  const {
    open: menuOpen,
    handleOpen: handleOpenMenu,
    handleClose: handleCloseMenu,
  } = useToggleState();
  const listButtonRef = useRef(null);
  const setIndicator = useSetTimelineCursorIndicator();
  const availableIndicators = useTimelineAvailableIndicators();

  const handleChangeIndicator = useCallback(
    (newIndicator) => {
      setIndicator(newIndicator);
      handleCloseMenu();
    },
    [setIndicator, handleCloseMenu]
  );

  return (
    <>
      <SelectButton ref={listButtonRef} variant="text" onClick={handleOpenMenu}>
        {formatIndicatorLabel(indicator)} <ChevronDownIcon color="primary" />
      </SelectButton>
      <Menu
        anchorEl={listButtonRef.current}
        open={menuOpen}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {indicatorsListLabels.map((itemIndicator) => (
          <MenuItem
            key={itemIndicator}
            onClick={() => handleChangeIndicator(itemIndicator)}
            selected={indicator === itemIndicator}
            disabled={!availableIndicators.includes(itemIndicator)}
          >
            {formatIndicatorLabel(itemIndicator)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
