import React, { useRef, useMemo, useCallback } from 'react';
import { DropMenu, DropMenuItem } from '../../../components/molecules/DropMenu';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { useToggleState } from '../../../utils/useToggleState';
import { DeleteAssessmentDialog } from './DeleteAssessmentDialog';
import { formatErrorResponse } from '../../../utils/formatters';
import { useDeleteAssessmentMutation } from '../../../services';
import { useTimelinePatientId } from '../slices';
import {
  AssessmentAnalysis,
  AssessmentAnalysisEdit,
} from '../../../components/organisms/AssessmentAnalysis';

export const AssessmentCardMenu = ({ assessment, ...dropMenuProps }) => {
  const {
    open: deleteConfirmOpen,
    handleOpen: handleOpenDeleteConfirm,
    handleClose: handleCloseDeleteConfirm,
  } = useToggleState();

  const patientId = useTimelinePatientId();

  const {
    open: drawerOpen,
    handleClose: handleDrawerClose,
    handleOpen: handleDrawerOpen,
  } = useToggleState();

  const lastDeleteMutation = useRef(null);
  const [
    deleteAssessment,
    {
      isLoading: isDeleting,
      isSuccess: hasDeleted,
      isError: hadErrorDeleting,
      error: deletingErrorResponse,
    },
  ] = useDeleteAssessmentMutation();

  const deletingErrorMessage = useMemo(
    () => formatErrorResponse(deletingErrorResponse),
    [deletingErrorResponse]
  );

  const handleCancelDeleteRequest = useCallback(() => {
    if (lastDeleteMutation.current) lastDeleteMutation.current.abort();
  }, [lastDeleteMutation]);

  const handleCancelDelete = useCallback(() => {
    handleCancelDeleteRequest();
    handleCloseDeleteConfirm();
  }, [handleCloseDeleteConfirm, handleCancelDeleteRequest]);

  const handleConfirmDelete = useCallback(() => {
    // Executa a mutation
    const mutation = deleteAssessment(assessment.id);

    // Guardamos a mutation para chamá-la depois caso o usuário cancele
    lastDeleteMutation.current = mutation;
  }, [lastDeleteMutation, assessment, deleteAssessment]);

  return (
    <>
      <DropMenu {...dropMenuProps}>
        <DropMenuItem title="Editar" onClick={handleDrawerOpen} />
        <DropMenuItem title="Excluir" onClick={handleOpenDeleteConfirm} />
      </DropMenu>
      {/* Edit Drawer */}
      <AssessmentAnalysisEdit
        patientId={assessment.patient_id}
        open={drawerOpen}
        handleClose={handleDrawerClose}
        assessment={assessment}
      />
      {/* Delete Dialog */}
      <DeleteAssessmentDialog
        open={deleteConfirmOpen}
        loading={isDeleting}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
      {/* Delete Snackbars */}
      <Snackbar
        severity="success"
        open={hasDeleted}
        title="Operação realizada:"
        subtitle="A avaliação foi excluída com sucesso!"
      />
      <Snackbar
        severity="error"
        open={hadErrorDeleting}
        title="Operação não realizada:"
        subtitle={deletingErrorMessage}
      />
    </>
  );
};
