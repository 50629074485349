import React, { useEffect, useCallback } from 'react';
import { Field, useFormikContext } from 'formik';
import { withFieldFeedback } from './withFieldFeedback';
import { CollapsibleGridItem } from '../../../atoms/CollapsibleGridItem';

export const withFormikField = (InputComponent) => {
  const InputWithFeedback = withFieldFeedback(InputComponent);

  return React.forwardRef((props, ref) => {
    const { hidden: isHidden = false, grid, ...fieldProps } = props;
    const { name } = fieldProps;
    const { setFieldValue, setFieldTouched, setFieldError, initialValues } = useFormikContext();
    const initialValue = initialValues[name];
    const resetField = useCallback(() => {
      setFieldValue(name, initialValue);
      setFieldTouched(name, false);
      setFieldError(name, false);
    }, [setFieldValue, setFieldTouched, setFieldError, name, initialValue]);

    useEffect(() => {
      if (isHidden) resetField();
    }, [resetField, isHidden]);

    const field = (
      <Field component={InputWithFeedback} ref={ref} {...fieldProps} />
    );

    if (grid) {
      return (
        <CollapsibleGridItem xs={12} {...grid} in={!isHidden}>{field}</CollapsibleGridItem>
      );
    } else {
      return isHidden ? null : field;
    }
  });
};
