import React, { useCallback } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import TodayIcon from '@material-ui/icons/Today';

import { withFormikField } from '../utils';
import { TextInput } from '../TextInput';
import { useFormikContext } from 'formik';

const DateInputTextFieldComponent = React.forwardRef(
  ({ InputProps, ...props }, ref) => (
    <TextInput {...InputProps} {...props} ref={ref} />
  )
);

export const DateInput = React.forwardRef((props, ref) => (
  <KeyboardDatePicker {...props} ref={ref} />
));
DateInput.defaultProps = {
  disableToolbar: true,
  variant: 'inline',
  autoOk: true,
  margin: 'normal',
  format: 'DD/MM/YYYY',
  TextFieldComponent: DateInputTextFieldComponent,
  keyboardIcon: <TodayIcon fontSize="small" />,
  invalidDateMessage: 'Data inválida',
  maxDateMessage: 'Data futura à máxima',
  minDateMessage: 'Data anterior à mínima',
  onChange: () => {}
};

// Componente pronto para utilização como formik field
export const DateField = withFormikField(
  React.forwardRef((props, ref) => {
    const { setFieldValue, setFieldError, validateOnChange } = useFormikContext();
    const { name, error: _currentError, helperText } = props;
    const currentError = _currentError && helperText;

    const handleChange = useCallback(
      (date) => setFieldValue(name, date, validateOnChange),
      [setFieldValue, name, validateOnChange]
    );

    const handleError = useCallback(
      (error) => {
        if (error && !currentError && currentError !== error) setFieldError(name, error);
      },
      [name, currentError, setFieldError]
    );

    return <DateInput {...props} onChange={handleChange} onError={handleError} ref={ref} />;
  })
);
