import * as d3 from 'd3'
import {ChartCollection} from './ChartCollection';
import {D3Drag} from "./D3Drag";
import D3Plot from "./D3Plot";
import {localized} from "./Helper";

/**
 * Gráfico de barras
 */

// Estilos
let margin = {
  top: 0,
  bottom: 30,
  left: 140,
  right: 0
}

let height = 410;
let width = 800;

export default class D3TimelineChart {
  groupBy = 'day' // day | month

  // Containers
  svg = null;
  container = null;
  chartCollection = null;

  // Distânica entre os itens do eixo (px)
  offsetAxis = 30;

  // Variáveis dos Eixos
  AxisWidth = 0;

  y = null
  yData = [];
  yAxisGroup = null;
  yAxisCall = null;

  x = null
  xData = [];
  xAxisGroup = null;
  xAxisCall = null;

  constructor(element, data, infections) {
    if(!element) return;
    this.container = element.current;
    this.chartCollection = new ChartCollection(data, infections, this.groupBy);
    d3.timeFormatDefaultLocale(localized);
    this.changeSizes()
  }

  /**
   * Ativa quando é identificado o tamanho do container e monta o gráfico com base nestas dimensões
   */
  changeSizes() {
    d3.select(this.container).selectAll('svg').remove();
    this.setupData();
    this.setup();
    this.buildAxis();
    this.drawBackground();
    this.drawLines();
    this.drawBullets();

    const AxisWidth = this.AxisWidth;
    const drag = D3Drag({margin, width, height, AxisWidth});
    drag(d3.select(this.container))
  }

  /**
   * Busca os dados da aplicação
   */
  setupData() {
    this.yData = this.chartCollection.getLabels().reverse();
    this.xData = this.chartCollection.getDates().map(d => d.getTime());

  }
  changeWidth(){
    width = this.container.getBoundingClientRect().width - margin.left - margin.right;
    d3.select(this.container)
      .select('svg')
      .attr('width', width + margin.left + margin.right)
  }

  /**
   * Define o container principal do gráfico
   */
  setup() {
    width = this.container.getBoundingClientRect().width - margin.left - margin.right;
    height = this.container.getBoundingClientRect().height - margin.top - margin.bottom;
    this.svg = d3.select(this.container)
      .append('svg')
      .attr('class', 'TimelineSVG')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.bottom + margin.top)
      .append('g')
      .attr('class', 'chart-container');


  }

  /**
   * Configura os Eixos do Gráfico
   */
  buildAxis() {
    // Eixo X
    this.xAxisGroup = this.svg.append('g')
      .attr('class', 'AxisBottom')
      .attr('transform', `translate(${margin.left}, ${height})`)

    const containerWidth = this.container.getBoundingClientRect().width;
    const minBandWidth = 80;
    const tempWidth = this.xData.length * minBandWidth;
    this.AxisWidth = (containerWidth >= tempWidth ? containerWidth : tempWidth) - margin.left - margin.right;

    const max = d3.max(this.xData);
    const min = d3.min(this.xData);
    const offsetMonth = containerWidth >= tempWidth ? 60 : 30;
    this.offsetAxis = this.groupBy === "month" ? offsetMonth : 1;
    this.x = d3.scaleTime()
      //Eixo invertido
      .domain([
        max + 1000 * 60 * 60 * 48 * this.offsetAxis,
        // só aplica o mínimo se houver rolagem
        min - (containerWidth >= tempWidth && this.groupBy === "month" ? 0 : 1000 * 60 * 60 * 24 * this.offsetAxis),
      ])
      .range([0, this.AxisWidth])

    let timeFormat = this.groupBy === "month" ? '%b/%y' : '%d/%b'
    this.xAxisCall = d3.axisBottom(this.x)
      .tickFormat(d3.timeFormat(timeFormat))
      .ticks(this.chartCollection.getDates().length+(containerWidth > tempWidth ? 1 : 0));

    this.xAxisGroup.call(this.xAxisCall)
      // Incluindo linha indicativa do número no eixo
      // .call(g => g.selectAll(".tick line")
      //   .remove())
      .call(g => g.selectAll(".tick")
        .attr('class', 'AxisLeft__label'))

    // Remove o primeiro elemento fake
    this.svg.selectAll(".AxisLeft__label")
      .filter(function (d, i) { return i === 0;})
      .remove()

    // Eixo Y
    d3.select('.TimelineSVG')
      .append('rect') // Retângulo para cobrir as sobras do drag nos eixos
      .attr('fill', 'white')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', margin.left)
      .attr('height', height + margin.bottom + margin.top)

    this.yAxisGroup = d3.select('.TimelineSVG')
      .append('g')
      .attr('class', 'AxisLeft')
      .attr('transform', `translate(${margin.left},0)`)

    this.y = d3.scaleBand()
      .domain(this.yData)
      .range([height, 0])
      .padding(1);

    this.yAxisCall = d3.axisLeft(this.y)
      .tickSizeOuter(0);

    this.yAxisGroup.call(this.yAxisCall)
      .call(g => g.selectAll(".tick line")
        .remove())
      .call(g => g.selectAll(".tick")
        .attr('class', 'AxisLeft__label'))
  }

  /**
   * Desenha as linhas de acordo com o Eixo Y
   * Este método deve ser chamado quando o yAxisCall estiver pronto
   */
  drawLines() {
    if (!this.yAxisCall) return false;
    const linesY = this.svg.selectAll('.line')
      .data(this.chartCollection.getLabels())

    linesY.enter()
      .append('line')
      .attr('class', 'AxisLeft__line')
      .attr('x1', this.AxisWidth)
      .attr('x2', 0)
      .attr('y1', d => this.y(d) + (this.y.bandwidth() / 2))
      .attr('y2', d => this.y(d) + (this.y.bandwidth() / 2))
      .attr('stroke-width', 1)
      .attr('transform', `translate(${margin.left},0)`)
    const linesX = this.svg.selectAll('.line')
      .data(this.chartCollection.getDates())

    linesX.enter()
      .append('line')
      .attr('class', 'AxisLeft__line')
      .attr('x1', d =>  this.x(d.getTime()))
      .attr('x2', d => this.x(d))
      .attr('y1', 0)
      .attr('y2', height)
      .attr('stroke-dasharray', '4')
      .attr('stroke-width', 1)
      .attr('transform', `translate(${margin.left},0)`)

    // const firstAxisItem = new Date(d3.max(this.xData) + 1000 * 60 * 60 * 24 * this.offsetAxis);
    // this.svg
    //   .append('line')
    //   .attr('class', 'AxisLeft__line')
    //   .attr('x1',this.x(firstAxisItem))
    //   .attr('x2', this.x(firstAxisItem))
    //   .attr('y1', 0)
    //   .attr('y2', height)
    //   .attr('stroke-dasharray', '4')
    //   .attr('stroke-width', 1)
    //   .attr('transform', `translate(${margin.left},0)`)
  }

  /**
   * Desenha o background no gráfico
   */
  drawBackground() {
    const backgrounds = this.svg.selectAll('.background')
      .data(this.chartCollection.getEvidencias())
    backgrounds.enter()
      .append('path')
      .attr('class','background')
      .attr('fill', '#fef7f8')
      .attr('d', d => "M "+
        ( margin.left + this.x(d.date[0].getTime()))+",0 "+
        ( margin.left + this.x(d.date[0].getTime())) +","+height+" "+
        ( margin.left + this.x(d.date[1].getTime()))+","+height+" "+
        ( margin.left + this.x(d.date[1].getTime()))+",0  Z")
  }

  /**
   * Desenha os objetos do gráfico
   */
  drawBullets() {
    this.plot = new D3Plot(this.svg, this.chartCollection, this.x, this.y, margin);
    this.plot.initialize();
  }


}
