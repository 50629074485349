import React, { useCallback } from 'react';
import { any, array, func, bool } from 'prop-types';
import moment from 'moment';

import { Box, Grid, withStyles } from '@material-ui/core';

import { connect } from 'react-redux';
import { assessmentsHistory } from '../../../actions/AssessmentsHistoryActions';
import { useGetAssessmentsEvaluationsQuery } from '../../../services/assessments/assessments.service';
import {
  setOrderByFilterAction,
  resetFiltersAction,
  setStatusFilterAction,
  setInfectionFilterAction,
  setAssessmentsHistoryPeriodDate,
} from '../../../actions/AssessmentsHistoryFiltersActions';
import { useHistory } from 'react-router-dom';

import { setDashboardPeriodDate } from '../../../actions/DashboardPeriodFilterActions';

import { infectionTypes } from '../../../actions/InfectionTypesActions';

import { useHistoryFilters } from './slices/selectors/selectHistoryFilters';
import { DateRangeTemplate } from '../../../templates/DateRangeTemplate';
import { historyColumnsDef } from './historyColumnsDef';
import { DataTableHeader } from '../../../components/organisms/DataTable/DataTableHeader';
import DataTable from '../../../components/organisms/DataTable/DataTable';
import { useHistoryFiltersCount } from './slices/selectors/selectHistoryFiltersCount';
import { useToggleState } from '../../../utils/useToggleState';
import { DataTableFilterBtn } from '../../../components/organisms/DataTable/DataTableFilterBtn';
import {
  useSetHistoryPatientName,
  useSetHistoryPage,
  useSetHistoryItemsPerPage,
} from './slices/callbacks/';
import { HistoryFilterDrawer } from './HistoryDrawer';
import { DownloadCsv } from './DownloadCsv';

import { LoggedUserCompaniesForm } from '../../../components/organisms/LoggedUserCompaniesForm';

const EVALUATION_RULES = [
  'Robô ISA identificou uma ou mais suspeitas e todas elas foram avaliadas;',
  'Robô ISA não identificou a suspeita (OUTRA) e o paciente possui ao menos uma avaliação;',
];

const ActionComponent = ({ isLoading, children }) => {
  const { open, handleClose, handleOpen } = useToggleState();
  const filterCount = useHistoryFiltersCount();
  return (
    <>
      <Box display="flex" flexDirection="row">
        <Box ml="auto">
          <DataTableFilterBtn onClick={handleOpen} filterCount={filterCount} />
          {children}
        </Box>
      </Box>
      <HistoryFilterDrawer open={open} onClose={handleClose} />
    </>
  );
};

const History = (props) => {
  const history = useHistory();

  const filters = useHistoryFilters();
  const setHistoryPage = useSetHistoryPage();

  const handleSearch = useSetHistoryPatientName();
  const handlePageSizeChange = useSetHistoryItemsPerPage();

  const { positiveAssessments, negativeAssessments } = props;

  const {
    data: patientEvaluations,
    isFetching: patientEvaluationsFetching,
    isLoading: patientEvaluationsLoading,
  } = useGetAssessmentsEvaluationsQuery(filters);

  const {
    data: csvData,
    isFetching: csvFetching,
    isLoading: csvLoading,
  } = useGetAssessmentsEvaluationsQuery({
    ...filters,
    page: 1,
    itensPerPage: 1500,
  });

  const isLoading = patientEvaluationsFetching || patientEvaluationsLoading;

  const handlePagination = useCallback(
    (currentPage) => setHistoryPage(currentPage + 1),
    [setHistoryPage]
  );

  const handleRowClick = useCallback(({ row }) => {
    const { paciente } = row;
    if (paciente && paciente.id) history.push(`/paciente/${paciente.id}`);
  }, []);

  return (
    <DateRangeTemplate
      title="Histórico"
      extraActions={[<LoggedUserCompaniesForm></LoggedUserCompaniesForm>]}
      info={`Pendente: ${EVALUATION_RULES.join('')}`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box height="90vh">
            <DataTable
              rows={patientEvaluations?.results}
              columns={historyColumnsDef}
              loading={isLoading}
              pagination={patientEvaluations?._metadata}
              onPageChange={handlePagination}
              onRowClick={handleRowClick}
              onPageSizeChange={handlePageSizeChange}
              header={
                <DataTableHeader
                  onSearch={handleSearch}
                  action={
                      <ActionComponent isLoading={isLoading}>
                        <DownloadCsv
                          disabled={csvLoading}
                          results={csvData?.results}
                        />
                      </ActionComponent>
                  }
                />
              }
            />
          </Box>
        </Grid>
      </Grid>
    </DateRangeTemplate>
  );
};

History.propTypes = {
  assessmentsHistory: array,
  infectionTypes: array,
  patientList: array,
  initialDate: any,
  finalDate: any,
  positiveAssessments: bool,
  negativeAssessments: bool,
  getAssessmentsHistoryList: func,
  updateAssessmentsHistoryInitialDate: func,
  updateAssessmentsHistoryFinalDate: func,
  updateInfectionFilter: func,
  updateOrderByFilter: func,
  updateStatusFilter: func,
  resetFilters: func,
  infections: array,
  loading: bool,
};

const mapStateToProps = (state) => ({
  assessmentsHistory: state.assessmentsHistory.list,
  loading: state.assessmentsHistory.loading,
  infectionTypes: state.infectionTypes,
  initialDate: state.periods.dashboard.initialDate,
  finalDate: state.periods.dashboard.finalDate,
  orderByField: state.assessmentsHistoryFilter.orderByField,
  infections: state.assessmentsHistoryFilter.infections,
  positiveAssessments: state.assessmentsHistoryFilter.positive,
  negativeAssessments: state.assessmentsHistoryFilter.negative,
  globalStateInfections: state.assessmentsHistoryFilter.infections,
  globalStateOnlyInconclusive: state.assessmentsHistoryFilter.onlyInconclusive,
  globalStateOrderBy: state.assessmentsHistoryFilter.orderByField,
});

const mapDispatchToProps = (dispatch) => ({
  getAssessmentsHistoryList: (filterParams) =>
    dispatch(assessmentsHistory(filterParams)),
  setInitialDate: (date, initial) =>
    dispatch(setDashboardPeriodDate(date, initial)),
  setFinalDate: (date, initial = false) =>
    dispatch(setDashboardPeriodDate(date, initial)),

  // TODO remover actions não utilizadas
  updateAssessmentsHistoryInitialDate: (date) =>
    dispatch(setAssessmentsHistoryPeriodDate(date)),

  // TODO remover actions não utilizadas
  updateAssessmentsHistoryFinalDate: (date) =>
    dispatch(setAssessmentsHistoryPeriodDate(date, false)),

  updateOrderByFilter: (fieldName) =>
    dispatch(setOrderByFilterAction(fieldName)),

  updateInfectionFilter: (infections) =>
    dispatch(setInfectionFilterAction(infections)),

  updateStatusFilter: (status) => dispatch(setStatusFilterAction(status)),
  resetFilters: () => dispatch(resetFiltersAction()),

  getInfectionTypes: () => dispatch(infectionTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
