import React, { useMemo } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';

const PlatformVersionTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.white.main,
    opacity: 0.56,
    cursor: 'pointer',
    fontFamily: theme.MuiTypography.family.secondary,
    fontSize: theme.MuiTypography.sizes.xs,
  },
}))(Typography);

const PlatformVersionText = () => {
  let history = useHistory();

  const version = useMemo(() => {
    if (_.isEmpty(process.env.REACT_APP_APP_VERSION)) return '1.0';
    const splitedVersionStr = process.env.REACT_APP_APP_VERSION.split('.');
    splitedVersionStr.pop();
    const majorVersion = splitedVersionStr.join('.');

    return majorVersion;
  }, []);
  return (
    <PlatformVersionTypography
      align="center"
      onClick={() => history.push('/changelog')}
    >
      {version}
    </PlatformVersionTypography>
  );
};

export default withTheme(PlatformVersionText);
