import React from 'react';
import { Grid, Collapse, withStyles } from '@material-ui/core';

export const GridCollapseComponent = React.forwardRef(({ gridComponent, ...rest }, ref) => (
  <Grid item {...rest} component={gridComponent} ref={ref} />
));

export const GridCollapse = withStyles((theme) => ({
  container: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  wrapperInner: {
    paddingTop: ({ spacing }) => theme.spacing(spacing / 2),
    paddingBottom: ({ spacing }) => theme.spacing(spacing / 2),
  },
}))(
  React.forwardRef(({ component, spacing, ...gridCollapseProps }, ref) => (
    <Collapse
      {...gridCollapseProps}
      gridComponent={component}
      component={GridCollapseComponent}
      ref={ref}
    />
  ))
);

/**
 * Este componente mescla o componente Collapse e o componente Grid item do material ui.
 * 
 * Serve para garantir que um grid item colapsado não tenha uma altura mínima com os paddings do grid.
 * 
 * É preciso passar o mesmo atributo spacing passado para o Grid container que englobará este componente.
 * 
 * @ref https://stackoverflow.com/questions/57114936/react-material-ui-collapse-on-grid-breaks-grid
 */
export const CollapsibleGridItem = React.forwardRef((props, ref) => {
  const { spacing = 2, children, ...rest } = props;

  return (
    <GridCollapse {...rest} spacing={spacing} ref={ref}>
      {children}
    </GridCollapse>
  );
});
