import { Box, Grid, IconButton } from '@material-ui/core';
import { AutocompleteField, SelectField } from '../../../../molecules/Form';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

const booleanOptions = [
  { value: '', label: 'Selecione', disabled: true },
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
];

export const MicroorganismItemField = (props) => {
  const {
    options,
    loading,
    name,
    value,
    onRemove,
    disabled,
    errors,
    helperTexts,
  } = props;

  return (
    <Grid container spacing={2}>
      <AutocompleteField
        name={`${name}.microorganism`}
        label="Microrganismo"
        placeholder="Microrganismo"
        options={loading || !options ? [] : options}
        grid={{ xs: 8 }}
        disabled={loading || disabled}
        error={errors?.microorganism}
        helperText={helperTexts?.microorganism}
        loading={loading}
      />
      <Grid item xs={4}>
        <Grid container spacing={2} component={Box} flexWrap="nowrap">
          <Grid
            item
            xs={onRemove ? 'auto' : 12}
            component={Box}
            flexGrow={1}
            flexShrink={1}
          >
            <SelectField
              name={`${name}.resistant`}
              label="Resistente?"
              options={booleanOptions}
              disabled={
                disabled || (!value.microorganism && value.resistant === '')
              }
              error={errors?.resistant}
              helperText={helperTexts?.resistant}
            />
          </Grid>
          {onRemove && (
            <Grid xs="auto" component={Box} pt={5} alignSelf="flex-start">
              <IconButton
                edge="end"
                size="small"
                onClick={onRemove}
                style={{ padding: 3 }}
                disabled={disabled}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
