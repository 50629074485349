import React from 'react';
import { useSelectedCompetence } from '../../slices'
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { DashboardPanel } from '../../DashboardLayout';
import { SuspicionStat } from './SuspicionStat';
import { getIndicatorValue } from '../../../../../utils/formatters/formatDashboardIndicators';

const TotalIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.75 0.75H11.5607L15.75 4.93934V15.75C15.75 16.5784 15.0784 17.25 14.25 17.25H3.75C2.92157 17.25 2.25 16.5784 2.25 15.75V2.25C2.25 1.42157 2.92157 0.75 3.75 0.75ZM9.75 2.25H3.75V15.75H14.25V6.75H11.25C10.4216 6.75 9.75 6.07843 9.75 5.25V2.25ZM11.25 2.56066V5.25H13.9393L11.25 2.56066ZM6 12.75V11.25H10.5V12.75H6ZM6 8.25V9.75H12V8.25H6Z"
      fill="#47ABE2"
    />
  </svg>
);

const HighIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.09717 8.99978H6.13514L7.63514 16.4998H10.3648L11.8648 8.99978H15.9028L8.99999 1.11084L2.09717 8.99978ZM8.99999 3.38873L12.5972 7.49978H10.6351L9.13514 14.9998H8.86485L7.36485 7.49978H5.40282L8.99999 3.38873Z"
      fill="#EE5855"
    />
  </svg>
);

const MedIcon = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 8.36485L8 9.86485V13.9028L15.8889 6.99999L8 0.097168V4.13514L0.5 5.63514V8.36485ZM9.5 3.40282L13.6111 6.99999L9.5 10.5972V8.63514L2 7.13514V6.86485L9.5 5.36485V3.40282Z"
      fill="#FB8E6C"
    />
  </svg>
);

const LowIcon = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9028 8H9.86485L8.36485 0.5H5.63514L4.13514 8H0.097168L6.99999 15.8889L13.9028 8ZM10.5972 9.5L6.99999 13.6111L3.40282 9.5H5.36485L6.86485 2H7.13514L8.63514 9.5H10.5972Z"
      fill="#FFC656"
    />
  </svg>
);

export const DashboardSuspicions = withStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
    display: 'flex',
  },
  item: {
    flex: '1 0 25%',
    '&:not(:first-child)': {
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
}))(function StyledDashboardSuspicions({ indicators, classes, className }) {
  const competence = useSelectedCompetence();
  if(!indicators) {
    return ''
  }

  return (
    <DashboardPanel
      title="Suspeitas por probabilidade"
      classes={{
        root: clsx(classes.root, className),
        content: classes.content,
      }}
    >
      <SuspicionStat
        className={classes.item}
        icon={<TotalIcon />}
        color="info"
        title="Total"
        value={getIndicatorValue(indicators, 'suspeitas_total', competence)}
      />
      <SuspicionStat
        className={classes.item}
        icon={<HighIcon />}
        color="high"
        title="Alta"
        value={getIndicatorValue(indicators, 'suspeitas_alta_prob', competence)}
      />
      <SuspicionStat
        className={classes.item}
        icon={<MedIcon />}
        color="med"
        title="Média"
        value={getIndicatorValue(indicators, 'suspeitas_media_prob', competence)}
      />
      <SuspicionStat
        className={classes.item}
        icon={<LowIcon />}
        color="low"
        title="Baixa"
        value={getIndicatorValue(indicators, 'suspeitas_baixa_prob', competence)}
      />
    </DashboardPanel>
  );
});
