import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

export const DashboardSection = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '27px',
    color: theme.palette.neutral.black['100'],
    marginBottom: theme.spacing(2),
  },
  content: {},
}))(function StyledDashboardSection({ classes, className, children, title }) {
  return (
    <div className={clsx(classes.root, className)}>
      {title && <div className={classes.title}>{title}</div>}
      <div className={classes.content}>{children}</div>
    </div>
  );
});
