import { makeStyles, alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'DM Mono',
    fontSize: '13px',
    lineHeight: '18px',

    '&:not(:first-child)': {
      paddingTop: '16px',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(62, 66, 71, 0.16)',
    paddingBottom: '16px',
    width: '100%',
  },

  textLabel: {
    color: theme.palette.neutral.black['72']
  },

  textValue: {
    color: theme.palette.neutral.black['72']
  },

  dot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: ({ theme }) => theme,
    transform: 'translateY(7px)',
    marginRight: '16px',
  },

  dotDanger: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: theme.palette.timeline.probability['500'],
    transform: 'translateY(11px)',
    marginRight: '16px',
  },

  rightContent: {
    display: 'flex',
    alignItems: 'center'
  },

  icon: {
    color: theme.palette.neutral.black['48'],
    marginLeft: '8px',
    transform: 'translateY(2px)',
  },

  dangerIcon: {
    color: theme.palette.timeline.probability['500'],
    marginLeft: '8px',
    transform: 'translateY(2px)',
  }
}));
