import React from 'react';
import { Form, Formik } from 'formik';
import { Box, Button, Divider } from '@material-ui/core';
import { Drawer, DrawerToolbar } from '../../../components/molecules/Drawer';
import {
  CheckboxField,
  SwitchField,
} from '../../../components/molecules/Form';
import { infectionTypesEnum } from '../../../constants/infections';
import { probabilityLevelsEnum } from '../../../constants/probabilities';
import {
  usePatientsInfections,
  usePatientsProbabilities,
  useResetPatientsFilters,
  useSetPatientsInfections,
  useSetPatientsProbabilities,
} from './slices';
import { useCallback } from 'react';
import { DangerButtonOutline } from '../../../components/atoms/DangerButtonOutline';

const infectionsOptions = Object.entries(infectionTypesEnum).map(([k, v]) => ({
  label: v.toUpperCase(),
  value: k,
}));

const infectionsProbOptions = [
  {
    label: 'Alta',
    value: probabilityLevelsEnum.ALTA,
  },
  {
    label: 'Média',
    value: probabilityLevelsEnum.MEDIA,
  },
  {
    label: 'Baixa',
    value: probabilityLevelsEnum.BAIXA,
  },
];

export const PatientsFilterDrawer = ({ open, onClose, isLoading }) => {
  const infections = usePatientsInfections();
  const probabilities = usePatientsProbabilities();

  const setInfections = useSetPatientsInfections();
  const setProbabilities = useSetPatientsProbabilities();

  const handleSubmit = useCallback(
    (values, actions) => {
      const { infections, probabilities } = values;

      setInfections(infections);
      setProbabilities(probabilities);
      actions.setSubmitting(false);
    },
    [setInfections]
  );

  return (
    <Formik
      initialValues={{ infections, probabilities }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Drawer
        title="Filtrar"
        open={open}
        loading={isLoading}
        onClose={onClose}
        PaperProps={{ component: Form }}
        footer={
          <DrawerToolbar borderTop wide>
            <FormFooter loading={isLoading} />
          </DrawerToolbar>
        }
      >
        <Box margin="8px 16px">
          <FormBody loading={isLoading} />
        </Box>
      </Drawer>
    </Formik>
  );
};

const FormFooter = ({ loading }) => {
  const handleReset = useResetPatientsFilters();

  return (
    <Box display="flex" justifyContent="space-between">
      <DangerButtonOutline
        type="reset"
        onClick={handleReset}
        disabled={loading}
      >
        Limpar filtro
      </DangerButtonOutline>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
      >
        Aplicar
      </Button>
    </Box>
  );
};

const FormBody = ({ loading }) => {
  return (
    <Box>
      <CheckboxField
        label={`Tipo de Infecção`}
        options={infectionsOptions.filter(infection => infection.value !== 'comunitaria')}
        name="infections"
        disabled={loading}
      />
      <Box my={2}>
        <Divider />
      </Box>
      <SwitchField
        label={`Probabilidade de infecção`}
        options={infectionsProbOptions}
        name="probabilities"
        disabled={loading}
      />
    </Box>
  );
};
