import React from 'react';
import { withStyles, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { DashboardCard } from './DashboardCard';

export const DashboardPanel = withStyles((theme) => ({
  root: {
    padding: 0,
  },
  title: {
    padding: theme.spacing(2),
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '19px',
    color: theme.palette.neutral.black['100'],
  },
  content: {
    padding: theme.spacing(2),
  },
}))(function StyledDashboardPanel({
  classes,
  className,
  children,
  title,
  ...cardProps
}) {
  return (
    <DashboardCard className={clsx(classes.root, className)} {...cardProps}>
      {title && (
        <>
          <div className={classes.title}>{title}</div>
          <Divider />
        </>
      )}
      <div className={classes.content}>{children}</div>
    </DashboardCard>
  );
});
