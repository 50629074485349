import React from 'react';
import { Route as ReactRoute } from 'react-router-dom';

const Route = ({ isPrivate = false, roles, resource, component: Component, ...rest }) => {

  // const isAuthorized = routeRoles => {
  //   if (keycloak && routeRoles) {
  //     const access = routeRoles.some(r => {
  //         const realm = keycloak.hasRealmRole(r);
  //         const realResource = keycloak.hasResourceRole(resource);
  //         return realm && (realResource || resource === false);
  //       }) || routeRoles.length === 0
  //   }
  //   return false;
  // }

  return (
    <ReactRoute
      {...rest}
      render={() => {
        return (
          <Component />
        )
      }}
    />
  );
}
export default Route;
