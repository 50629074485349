import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { AssessmentCard } from '../AssessmentCard';
import { TimelineSidebarSection } from './TimelineSidebarSection';

export const TimelineSidebarAssessments = ({ assessments, loading }) => {
  useEffect(() => {
    // console.log('assessments', assessments);
  }, [assessments]);

  return (
    <TimelineSidebarSection
      title="Avaliações"
      badge={assessments.length}
      loading={loading}
    >
      {!assessments?.length ? (
        <Typography variant="body2">Nenhuma avaliação encontrada.</Typography>
      ) : (
        <>
          {assessments.map((assessment, i) => (
            <AssessmentCard
              key={assessment.id}
              assessment={assessment}
              style={{
                marginBottom: '12px',
              }}
            />
          ))}
        </>
      )}
    </TimelineSidebarSection>
  );
};
