import { chunk, isNil } from 'lodash';


const formatedText = (splitedText) => {
  return chunk(splitedText, 1).reduce(
    (acc, [text]) => {
      acc += '\n' + text.replace(/ecg/g, '\n').trim() + '\n--------\n';
      return acc;
    },
    ''
  );
}

/**
 * Dado corpo de texto de um encontro, retorna um objeto mapeando o conteúdo de cada profissional
 *
 * @param {string} text Texto do encontro (encontro.termos_texto || encontro.termos_texto_agg)
 * @returns {Record<string, string>} Registros de cada profissional
 */
export const parseTextoEncontro = (textObject = {}) => {
  if (!textObject.termos_texto && !textObject.fst_evo_medica) return {};
  if (textObject.termos_texto === 'None') {
    textObject.termos_texto = textObject.texto_evolucao_agg
  }

  // Divisão de textos de cada profissional
  const splitedText = (textObject.termos_texto.split(/(\[(?:.*?)\])/gim) || [])
    .filter(Boolean)
    .map((e) => e.trim());

  const splitedTextMedic = (textObject?.fst_evo_medica?.split(/(\[(?:.*?)\])/gim) || [])
    .filter(Boolean)
    .map((e) => e.trim());

  // Hash de textos de cada profissional
  const indexedText = chunk(splitedText, 2).reduce(
    (acc, [professional, text]) => {
      if (!acc[professional]) acc[professional] = '';
      if (professional === '[MEDICO(A)]' && !isNil(textObject.fst_evo_medica)) {
        acc[professional] = formatedText(splitedTextMedic);
      } else {
        acc[professional] += '\n' + text?.replace(/\/\*/g, '\n') + '\n--------\n'
      }

      return acc;
    },
    {}
  );
  return indexedText;
};


