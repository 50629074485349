import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import React, {useState} from "react";
import ReactDOM from "react-dom";
import {withTheme} from "@material-ui/core/styles";
import {RedirectLink} from "./styles";

const ErrorSnackbar = (props) => {
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false);
    const component = ReactDOM.findDOMNode(document.getElementById('snackbars'))
    ReactDOM.unmountComponentAtNode(component)
  }
  const handleRecarregar = () => {
    window.location.reload()
  }
  return (
    <Snackbar open={open} autoHideDuration={999999} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        Não foi possível estabelecer conexão com o servidor.
        <RedirectLink
          {...props}
          onClick={handleRecarregar}
        > Recarregar</RedirectLink>
      </Alert>
    </Snackbar>
  )
}
export default withTheme(ErrorSnackbar);
