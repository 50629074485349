import { createApi } from '@reduxjs/toolkit/query/react'
import {  baseQueryWithReauth } from '../baseQuery'
import { transformResponseHits } from '../common/utils/transformResponse'
import { infectionTypesRequestBody } from './infection.body'

export const infectionApi = createApi({
  reducerPath: 'infectionApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({

    getInfectionTypes: build.query({
      query: () => ({
        url: `/elk/`,
        method: 'POST',
        body: {
          path: `suspeitas/_search?filter_path=hits.total,
                aggregations.pav.buckets.doc_count,
                aggregations.ipcs.buckets.doc_count,
                aggregations.traqueo.buckets.doc_count,
                aggregations.isc.buckets.doc_count,
                aggregations.itu.buckets.doc_count,
                aggregations.pnm.buckets.doc_count&scroll=1m`,
          body: infectionTypesRequestBody
        },
      }),
      transformResponse: ((response) => [...Object.keys(response.aggregations)]),
    }),
  }),
})

export const { useGetInfectionTypesQuery } = infectionApi
