import { useMemo } from 'react';
import { useTheme, getContrastRatio } from '@material-ui/core';

export const useLabelStroke = (background) => {
  const theme = useTheme();
  const stroke = useMemo(() => {
    const dark = theme.palette.neutral.black['100'];
    const light = theme.palette.common.white;
    if (!background) return dark;

    try {
      return getContrastRatio(dark, background) < 4 ? light : dark;
    } catch {
      return dark;
    }
  }, [background, theme]);

  return stroke;
};