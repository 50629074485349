import { useMemo, useRef } from 'react'
import { useToggleState } from '../../../utils/useToggleState'

/**
 * Cria estado utilizado no componente DropMenu com ref e métodos para abrir e fechar
 */
export const useDropMenuState = () => {
  const state = useToggleState()
  const ref = useRef(null)

  const hookResult = useMemo(() => ({ ...state, ref }), [state, ref])

  return hookResult
}
