import moment from 'moment';

export const actionTypes = {
  SET_DASHBOARD_INITIAL_DATE: 'SET_DASHBOARD_INITIAL_DATE',
  SET_DASHBOARD_FINAL_DATE: 'SET_DASHBOARD_FINAL_DATE',
  SET_DASHBOARD_DATA: 'SET_DASHBOARD_DATA',
};

export const setDashboardDataAction = (patientsWithSuspicion,infectionParams) => ({
  type: actionTypes.SET_DASHBOARD_DATA,
  patientsWithSuspicion,
  infectionParams
});

/**
 * Dashboard initial date action
 *
 * @param initialDate
 * @returns {{type: string, initialDate: *}}
 */
const setDashboardInitialDateAction = initialDate => ({
  type: actionTypes.SET_DASHBOARD_INITIAL_DATE,
  initialDate
});

/**
 * Dashboard final date action
 *
 * @param finalDate
 * @returns {{finalDate: *, type: string}}
 */
const setDashboardFinalDateAction = finalDate => ({
  type: actionTypes.SET_DASHBOARD_FINAL_DATE,
  finalDate
});

/**
 * Dashboard dates action creator
 *
 * @param date
 * @param initial
 * @returns {Function}
 */
export const setDashboardPeriodDate = (date, initial = true) => dispatch => {
  if(!moment(date).isValid()) {
    return;
  }

  if(initial) {
    dispatch(setDashboardInitialDateAction(moment(date)));
  } else {
    dispatch(setDashboardFinalDateAction(moment(date)))
  }
};
