import styled from 'styled-components';
// import Container from "@material-ui/core/Container";

export const ForbiddenTemplate = styled.div`
  padding-top: 20px;
  height: 100%;
  min-height: calc(100vh);
`;
export const FilterGroup = styled.div`
  padding-top: 20px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 94px;
  padding-right: 30px;
`;
