import React from 'react';
import { DrawerToolbar } from './DrawerToolbar';
import { DrawerClose } from './DrawerClose';
import { DrawerTitle } from './DrawerTitle';
import { Box } from '@material-ui/core';

export const DrawerHeader = (props) => {
  const { children, title, wide, small, closeSmall, closePosition } = props;
  const isCloseBtnLeft = closePosition === 'left';

  return (
    <DrawerToolbar borderBottom wide={wide} small={small}>
      <Box display="flex">
        <Box
          order={isCloseBtnLeft ? '0' : '1'}
          flex="0 0 auto"
        >
          <DrawerClose small={closeSmall} />
        </Box>
        <Box
          order="0"
          flex="1 1 auto"
          marginLeft={isCloseBtnLeft ? '12px' : 0}
          marginRight={isCloseBtnLeft ? 0 : '12px'}
          display="flex"
          alignItems="center"
        >
          <Box width='100%'>
            {typeof title === 'string' ? (
              <DrawerTitle>{title}</DrawerTitle>
            ) : (
              title || children
            )}
          </Box>
        </Box>
      </Box>
    </DrawerToolbar>
  );
};
DrawerHeader.defaultProps = {
  wide: false,
  small: false,
  closeSmall: false, // Variante small para o botão fechar
  closePosition: 'left', // left | right
};
