import React from 'react';
import moment from 'moment';

import { Box } from '@material-ui/core';
import { formatToPercentageString, getPatientInfections } from './utils';
import { InfectionChip } from '../../../components/atoms/Chip';
import { DataTableSuspicions } from '../../../components/organisms/DataTable/DataTableSuspicions';

export const PatientColumnsDef = [
  {
    field: 'pacienteName',
    headerName: 'Paciente',
    valueGetter: (params) =>
      `${params.row?.paciente?.name}`,
    sortComparator: (v1, v2, cell01, cell02) => {
      const paciente01 = cell01.api.getRow(cell01.id).paciente;
      const paciente02 = cell02.api.getRow(cell02.id).paciente;
      return paciente01.name.localeCompare(paciente02.name);
    },
    minWidth: 240,
    flex: 1,
  },
  {
    field: 'janela',
    headerName: 'Janela',

    flex: 1,
    valueGetter: (params) =>
      `${moment(params.row?.dtInicio).format('DD/MM/YYYY')} - ${moment(
        params.row?.dtFim
      ).format('DD/MM/YYYY')}`,
  },

  {
    field: 'probPerc',
    headerName: 'Probabilidade',
    minWidth: 160,
    flex: 0,
    renderCell: (params) => {
      const percentual = formatToPercentageString(params.row.probPerc, 1);
      return (
        <InfectionChip label={percentual} probability={params.row.probPerc} />
      );
    },
  },
  {
    field: 'suspeitas',
    headerName: 'Suspeitas',
    minWidth: 260,
    flex: 1,
    renderCell: ({ row: paciente }) => (
      <DataTableSuspicions patient={paciente} />
    ),
  },
  {
    field: 'avaliado',
    headerName: 'Avaliado',

    flex: 0,
    minWidth: 140,
    valueGetter: (params) => (params.row?.avaliado ? 'Sim' : 'Não'),
  },
  {
    field: 'data_avaliacao',
    headerName: 'Última Avaliação',

    flex: 0,
    minWidth: 160,
    valueGetter: (params) =>
      params.row.data_avaliacao
        ? moment(params.row.data_avaliacao).format('DD/MM/YYYY')
        : '---',
  },
];
