import React, { useCallback } from 'react';
import { useSetTimelineCursor } from '../../slices';
import { IndicatorEntry } from './IndicatorEntry';

export const TimelineEntry = ({ indicator, date }) => {
  const handleSetCursor = useSetTimelineCursor();
  const handleClick = useCallback(() => {
    handleSetCursor({ indicator, date });
  }, [handleSetCursor, indicator, date]);

  return (
    <IndicatorEntry
      indicator={indicator}
      date={date}
      onClick={handleClick}
    />
  );
};
