import React, { useMemo } from 'react'
import { DialogContext } from './DialogContext';

export const DialogProvider = (props) => {
  const { open, onClose, children } = props;
  const contextState = useMemo(() => ({ open, onClose }), [open, onClose])

  return (
    <DialogContext.Provider value={contextState}>
      {children}
    </DialogContext.Provider>
  )
}
