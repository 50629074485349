import Axios from 'axios';
import ReactDOM from 'react-dom';
import ErrorSnackbar from '../../components/molecules/ErrorSnackbar/index';
import React from 'react';
import { keycloakConnect } from "../../keycloak";

/**
 * Axios instance object used to make api calls
 *
 * @type {AxiosInstance}
 */

export const httpClient = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  }
});
httpClient.interceptors.request.use(
  async (request) => {
    if(keycloakConnect.isTokenExpired(30)){
      await keycloakConnect.updateToken();
    }
    return request;
  }
)
/**
 * AXIOS INTERCEPTOR
 */
// httpClient.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   function (error) {

  //   if(error === 'Not authenticated'){
  //     // keycloakConnect.login();
  //   }

  //   // configuração original
  //   const originalRequest = error.config;
  //   try{ 
  //     originalRequest._try = originalRequest._try ? originalRequest._try : 0;
  //     originalRequest._try++;
  //   }catch(e){
  //     console.log(e)
  //     ReactDOM.render(
  //       <ErrorSnackbar />,
  //       document.getElementById('snackbars')
  //     );
  //   }

  //   if (error.response) {
  //     // Sem authorização
  //     // if (error.response.status === 401) {
  //     //   localStorage.setItem('isa_token', '');
  //     //   AuthService.prepare();
  //     // }
  //     if (error.response.status === 403 || error.response.status === 401) {
  //       // keycloakConnect.login();
  //       // window.location = '/nao-permitido'
  //       // // return;
  //     }
  //     // Servidor fora do ar
  //     if (error.response.status === 500) {

  //       if (originalRequest._try < 4) {
  //         // return httpClient(originalRequest);
  //       } 
  //       ReactDOM.render(
  //         <ErrorSnackbar />,
  //         document.getElementById('snackbars')
  //       );
  //       return Promise.reject(error);
  //     }
  //     if (error.response.status === 400) {
  //       return Promise.reject(error);
  //     }
  //   } else {
  //       ReactDOM.render(
  //         <ErrorSnackbar />,
  //         document.getElementById('snackbars')
  //       );
  //   }
  //   if (window.location.pathname !== '/') {
  //     ReactDOM.render(<ErrorSnackbar />, document.getElementById('snackbars'));
  //   }
  //   return Promise.reject(error);
  // }
// );

/**
 * Assemble the request config object
 *
 * @param endpoint
 * @param data
 * @param headers
 * @param xndjson
 * @param assessment
 * @param config
 * @returns {{method: string}}
 */
export function assembleRequestConfig({
  endpoint = '',
  data = {},
  headers = {},
  xndjson = false,
  assessment = false,
  config = false
}) {
  let requestData = {
    method: 'post',
    data: {
      body: data,
    },
  };

  if (xndjson) {
    requestData.url = `/elk/x-ndjson/${endpoint}`;
    requestData.data = data;
  } else if (assessment) {
    requestData.url = `/elk/avaliar/`;
    requestData.data = data;
  } else if (config) {
    requestData.url = endpoint;
    requestData.data = data;
  } else {
    requestData.url = `/elk/`;
    requestData.data = {
      path: endpoint,
      body: data
    }
  }

  requestData.headers = {
    ...headers
  }

  requestData.headers = {
    Authorization: 'Basic aG9tb2xvZzpJbXFiUkpEQUhqUEo0NEg5RWxRZQ==',// + localStorage.getItem('isa_token'),
    ...headers,
  };
  return requestData;
}

/**
 * Normalizes the response in an one-level array of objects
 * if it has not a aggregations attribute
 *
 * @param response
 * @returns {any[]}
 */
function extractResponseArray(response) {
  const { data } = response;

  if (data.aggregations) {
    return data;
  }

  if (data?.hits?.total?.value) {
    return data.hits?.hits?.flatMap((hit) => {
      return hit._source;
    });
  }

  return data;
}

/**
 * Sends the request to the API and return it's response if status is 200
 * or false
 *
 * @param endpoint
 * @param data
 * @param headers
 * @param assessment
 * @param config
 * @returns {*}
 */
export async function sendRequest({
  endpoint,
  data = {},
  headers = {},
  assessment = false,
  config = false
}) {
  try {
    const xndjson = (headers['Content-Type'] === 'application/x-ndjson');
    const response = await httpClient(
      assembleRequestConfig({
        endpoint,
        data,
        headers,
        xndjson,
        assessment,
        config
      })
    );
    if (response.status === 200 || response.status === 201) {
      return extractResponseArray(response);
    }

    response.catch((err) => {
      console.log(err);
    });
  } catch (error) {
    throw error;
  }
}

export default httpClient;
