import { actionTypes } from '../actions/UnitActions';

const initialState = {
  unitData: [],
}

export const unitiesReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.GET_UNITIES_DATA:
      return {
        ...state,
        unitData: action.unitData
      };

    default:
      return state;
  }
}
