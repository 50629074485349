/**
 * Unidades de medida
 *
 * @constant
 * @type {{'GRAUS CELSIUS': 'cº', 'BPM': 'bpm', 'MPM': 'mpm', '%': '%', 'MGDL': 'mg/dl', 'MMHG': 'mmHg', 'VALOR': '', 'LITROS/MIN': 'FiO2', 'FIO2': 'FiO2' }}
 */
 export const unitsOfMeasureEnum = {
    'GRAUS CELSIUS': 'cº',
    'BPM': 'bpm',
    'MPM': 'mpm',
    '%': '%',
    'MGDL': 'mg/dl',
    'MMHG': 'mmHg',
    'VALOR': '',
    'LITROS/MIN': 'FiO2',
    'FIO2': 'FiO2',
};

/**
 * Unidades de medida
 *
 * @constant
 * @type {{'GRAUS CELSIUS': 'cº', 'BPM': 'bpm', 'MPM': 'mpm', '%': '%', 'MGDL': 'mg/dl', 'MMHG': 'mmHg', 'VALOR': '', 'LITROS/MIN': 'FiO2', 'FIO2': 'FiO2' }}
 */
export const unitsOfMeasureList = Object.keys(unitsOfMeasureEnum);