
/**
 * Agrupa o array por sinal vital e retorna dentro de cada grupo o maior e o menor valor de resultado e se há criterio (valores fora do padrao de referencia)
 * 
 * @param {[]} vitalSigns
 * @returns {[]}
 */
export function formatItems(vitalSigns) {
  const items = vitalSigns.reduce((sign, {tipo_sinal, unimedida, valor, criterio}) => {
    const formattedValue = parseFloat(parseFloat(valor.replace(',', '.')).toFixed(1))
    let [date] = tipo_sinal.split(' ', 1)

    sign[date] = sign[date] || {signalType: tipo_sinal, min: Infinity, max: -Infinity}
    sign[date].measure = unimedida
    sign[date].min = sign[date].min > formattedValue ? formattedValue : sign[date].min
    sign[date].max = sign[date].max < formattedValue ? formattedValue : sign[date].max
    sign[date].hasCriterias = sign[date].hasCriterias || criterio === 'sim' && true

    return sign
  },{})

  return items
}