import React from 'react';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { BagedButton } from './styles';
import { string, func, element } from 'prop-types';

import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';

const BagedButtonComponent = (props) => {
  const title = props.title || '';
  const handleClick = props.onClick || '';
  const icon = props.icon || <NotificationsNoneIcon />;
  return (
    <BagedButton>
      <Badge color="primary">
        <Button
          variant="contained"
          onClick={handleClick}
          startIcon={icon}
          color="primary"
        >
          {title}
        </Button>
      </Badge>
    </BagedButton>
  );
};

BagedButtonComponent.propTypes = {
  title: string.isRequired,
  onClick: func,
  icon: element,
};

export default BagedButtonComponent;
