import React from 'react';
import { Form } from 'formik';
import { Box } from '@material-ui/core';

import { Drawer, DrawerToolbar } from '../../molecules/Drawer';

import {
  AssessmentAnalysisFormBody,
  AssessmentAnalysisFormSubmit,
  useAssessmentAnalysisFormContext,
} from './AssessmentAnalysisForm';

const formDrawerPaperProps = { component: Form };
const drawerHeaderProps = { small: false };
export const AssessmentAnalysisDrawer = (props) => {
  const { open, onClose } = props;
  const { isLoading } = useAssessmentAnalysisFormContext();

  return (
    <Drawer
      title="Avaliar análise"
      open={open}
      onClose={onClose}
      loading={isLoading}
      PaperProps={formDrawerPaperProps}
      header={drawerHeaderProps}
      footer={
        <DrawerToolbar borderTop wide>
          <AssessmentAnalysisFormSubmit />
        </DrawerToolbar>
      }
    >
      <Box margin="8px 16px">
        <AssessmentAnalysisFormBody />
      </Box>
    </Drawer>
  );
};
