import moment from 'moment';

export const actionTypes = {
  SET_PENDING_ASSESSMENTS_DATES: 'SET_PENDING_ASSESSMENTS_DATES',
  SET_PENDING_ASSESSMENTS_INITIAL_DATE: 'SET_PENDING_ASSESSMENTS_INITIAL_DATE',
  SET_PENDING_ASSESSMENTS_FINAL_DATE: 'SET_PENDING_ASSESSMENTS_FINAL_DATE',
  SET_PROBABILITY_FILTER: 'SET_PROBABILITY_FILTER',
  SET_ONLY_INCONCLUSIVE_FILTER: 'SET_ONLY_INCONCLUSIVE_FILTER',
  SET_PENDING_ASSESSMENTS_ORDERBY_FILTER: 'SET_PENDING_ASSESSMENTS_ORDERBY_FILTER',
  SET_PENDING_ASSESSMENTS_INFECTION_FILTER: 'SET_PENDING_ASSESSMENTS_INFECTION_FILTER',
  RESET_PENDING_ASSESSMENTS_FILTERS: 'RESET_PENDING_ASSESSMENTS_FILTERS'
};

/**
 * Pending assessments initial date action
 *
 * @param initialDate
 * @returns {{type: string, initialDate: *}}
 */
const setPendingAssessmentsInitialDateAction = initialDate => ({
  type: actionTypes.SET_PENDING_ASSESSMENTS_INITIAL_DATE,
  initialDate
});

/**
 * Pending assessments final date action
 *
 * @param finalDate
 * @returns {{finalDate: *, type: string}}
 */
const setPendingAssessmentsFinalDateAction = finalDate => ({
  type: actionTypes.SET_PENDING_ASSESSMENTS_FINAL_DATE,
  finalDate
});

/**
 * Pending assessments dates action creator
 *
 * @param date
 * @param initial
 * @returns {Function}
 */
export const setPendingAssessmentsPeriodDate = (date, initial = true) => dispatch => {
  if(!moment(date).isValid()) {
    return;
  }

  if(initial) {
    dispatch(setPendingAssessmentsInitialDateAction(moment(date)));
  } else {
    dispatch(setPendingAssessmentsFinalDateAction(moment(date)))
  }
};

/**
 * High probability filter action creator
 *
 * @returns {{type: string, probabilityFilters: Object}}
 * @param probabilityFilters
 */
export const setProbabilityFilterAction = (probabilityFilters) => ({
  type: actionTypes.SET_PROBABILITY_FILTER,
  ...probabilityFilters
});

/**
 * Only inconclusive filter action creator
 *
 * @param onlyInconclusive
 * @returns {{type: string, status: boolean}}
 */
export const setOnlyInconclusiveFilterAction = (onlyInconclusive = true) => ({
  type: actionTypes.SET_ONLY_INCONCLUSIVE_FILTER,
  onlyInconclusive
});

/**
 * Infection filter action creator
 *
 * @param infections
 * @returns {{infections: Array, type: *}}
 */
export const setInfectionFilterAction = (infections = []) => ({
  type: actionTypes.SET_PENDING_ASSESSMENTS_INFECTION_FILTER,
  infections
});

/**
 * Order by filter action creator
 *
 * @param orderByField
 * @returns {{type: string, status: boolean}}
 */
export const setOrderByFilterAction = (orderByField = '') => ({
  type: actionTypes.SET_PENDING_ASSESSMENTS_ORDERBY_FILTER,
  orderByField
});

/**
 * Order by filter action creator
 *
 * @returns {{type: string, status: boolean}}
 */
export const resetFiltersAction = () => ({
  type: actionTypes.RESET_PENDING_ASSESSMENTS_FILTERS,
});


