import { Box, withStyles } from '@material-ui/core'

export const IndicatorsTitle = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.neutral.black['48'],
    margin: 0,
  }
}))(Box)
IndicatorsTitle.defaultProps = {
  component: 'h3'
}