import React from 'react';
import {MyChip, SmallChip} from './styles';
import {PropTypes} from 'prop-types';

export const ChipsBasic = (props) => {
  const {
    title = '',
    theme = 'default',
    status = 'default'
  } = props;

  return (
    <>
      {theme === 'default' && (
        <MyChip
          label={title}
          variant="outlined"
          status={status}
        />
      )}
      {theme === 'small' && (
        <SmallChip
          label={title}
          variant="outlined"
          status={status}
        />
      )}
    </>
  );
};

ChipsBasic.propTypes = {
  title: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['small', 'default']),
  status: PropTypes.oneOf(['positiva', 'negativa']),
};