import { useMemo } from 'react';
import { groupBy, mapValues, orderBy } from 'lodash';
import moment from 'moment';

/**
 * Dada uma lista de medicinas agrupa e ordena cada um dos períodos de prescrição de cada medicina
 *
 * @param {[]} items Lista de itens do indicador ('medicamentos')
 * @returns {[string, { startDate: number, endDate: number }[]][]}
 */
export const useMedicinesPeriods = (items) => {
  const medicinesPeriods = useMemo(() => {
    const groupedMedicines = groupBy(
      items,
      (item) => `${item.medicamento} ${item.dose} ${item.via} - ${item.unidade}`
    );
    const medicineRanges = mapValues(groupedMedicines, (medicineList) => {
      const dates = medicineList.map((m) => m._meta.date).sort();

      // Lista cada uma das datas iniciais
      const startDates = dates.filter((d) => {
        const prevDay = moment(d).add({ day: -1 }).valueOf();
        const hasPrevDay = dates.includes(prevDay);
        return !hasPrevDay;
      });

      const dateRanges = startDates.map((startDate) => {
        // Para cada data inicial, busca a data final correspondente
        const endDate = dates.find((d) => {
          if (d < startDate) return false;
          const nextDay = moment(d).add({ day: 1 }).valueOf();
          const hasNextDay = dates.includes(nextDay);

          return !hasNextDay;
        });

        return {
          startDate,
          startMoment: moment(startDate),
          endDate,
          endMoment: moment(endDate),
        };
      });

      return orderBy(dateRanges, ['endDate'], ['desc']);
    });

    return orderBy(Object.entries(medicineRanges), ['[1].endDate'], ['desc']);
  }, [items]);

  return medicinesPeriods;
};
