import React from 'react';
import { Box, withStyles } from '@material-ui/core';

export const DialogToolbarWrapper = withStyles((theme) => ({
  root: {
    borderBottom: ({ borderBottom }) =>
      borderBottom
        ? `1px solid ${theme.palette.neutral.black['16']}`
        : undefined,
    borderTop: ({ borderTop }) =>
      borderTop ? `1px solid ${theme.palette.neutral.black['16']}` : undefined,
  },
}))(Box);

// Caixa com espaçamentos e borda inferior
export const DialogToolbar = (props) => {
  const { borderTop, borderBottom, wide, small, children, ...boxProps } = props;
  const paddingSize = small ? '16px' : '20px';

  return (
    <Box width="100%">
      <DialogToolbarWrapper
        borderTop={borderTop}
        borderBottom={borderBottom}
        pt="16px"
        pb="16px"
        pl={wide ? paddingSize : 0}
        pr={wide ? paddingSize : 0}
        ml={!wide ? paddingSize : 0}
        mr={!wide ? paddingSize : 0}
        {...boxProps}
      >
        {children}
      </DialogToolbarWrapper>
    </Box>
  );
};
DialogToolbar.defaultProps = {
  wide: false, // Se a borda cobre toda a toolbar
  small: false, // Variante com espaçamentos do eixo y menores
  borderBottom: false, // Exibe borda inferior
  borderTop: false, // Exibe borda superior
};
