import { useMemo } from 'react';
import { useTimelineIndicator } from '../../slices';

export const useIndicatorItemsByPeriod = ({
  indicator,
  startDate,
  endDate,
}) => {
  const items = useTimelineIndicator(indicator);

  const itemsInPeriodRange = useMemo(() => {
    return items.filter((item) =>
      item._meta.momentDate.isBetween(startDate, endDate)
    );
  }, [items, startDate, endDate]);

  return itemsInPeriodRange;
};
