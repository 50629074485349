import React, { useMemo } from 'react';
import { useTheme } from '@material-ui/core';
import { useTimelineEntry } from '../../../slices';
import { SquareEntry } from '../SquareEntry';

export const IndicatorEntrySinaisVitais = (props) => {
  const { indicator, date, onClick } = props;
  let vitalSigns = useTimelineEntry({ indicator, date });
  const theme = useTheme();

  const hasSomeCriteria = useMemo(
    () => vitalSigns.some((vitalSign) => vitalSign.criterio === 'sim'),
    [vitalSigns]
  );

  const hasTemperatureCriteria = useMemo(() => {
    return vitalSigns.some(
      (vitalSign) =>
        vitalSign.tipo_sinal === 'TEMP' &&
        vitalSign.criterio === 'sim' &&
        !isNaN(vitalSign.valor) ? parseFloat(vitalSign.valor.toString()) >= 38 : false
    );
  }, [vitalSigns]);

  let color = theme.palette.timeline.secondary.blue;
  if (hasSomeCriteria) {
    color = theme.palette.timeline.probability.MEDIA;
  }

  if (hasTemperatureCriteria) {
    color = theme.palette.timeline.probability.ALTA;
  }

  return (
    <SquareEntry
      indicator={indicator}
      date={date}
      onClick={onClick}
      color={color}
    />
  );
};
