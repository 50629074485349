import moment from 'moment';
import {actionTypes} from '../actions/PatientActions';
import {isEqual} from 'lodash';

const initialState = {
  patientFacts: [],
  patientData: null,
  patientPills: null,
  patientInfections: [],
  sendInfectionAssessmentLoading: false,
  patientAssessment: {
    infections: {},
    lastAssessmentSaved: false
  },
  patientHistogram: {
    show: false,
    data: []
  },

};

export const patientDataReducer = (state = initialState, action) => {
  function treatAssessmentStatus(assessmentStatus) {
    switch (assessmentStatus) {
      case 'positiva':
        return 'positive'
      case 'negativo':
        return 'negative'
      default:
        return assessmentStatus
    }
  }

  switch (action.type) {
    case actionTypes.GET_PATIENT_INFECTIONS_SUCCESS:
      return {
        ...state,
        patientInfections: [],
      };
    case actionTypes.GET_PATIENT_INFECTIONS_ERROR:
      return {
        ...state,
        patientInfections: [],
      };
    case actionTypes.GET_PATIENT_DATA:
      const [{_source}] = action.patientData;

      return {
        ...state,
        patientData: {..._source},
        rawPatientData: action.patientData
      };
    case actionTypes.RESET_SAVED_ASSESSMENT_FLAG:
      return {
        ...state,
        lastAssessmentSaved: false
      };
    case actionTypes.GET_PATIENT_PILLS_SUCCESS:
      const {
        aggregations: {
          by_index: {buckets = []},
        },
      } = action.patientPills;

      return {
        ...state,
        patientPills: [...buckets],
      };
    case actionTypes.GET_PATIENT_PILLS_ERROR:
      return {
        ...state,
        patientPills: [],
      };
    case actionTypes.SEND_PATIENT_INFECTION_ASSESSMENT_REQUEST:
      return {
        ...state,
        sendInfectionAssessmentLoading: true,
        patientAssessment: {
          ...state.patientAssessment,
          lastAssessmentSaved: false,
        }
      }
    case actionTypes.SEND_PATIENT_INFECTION_ASSESSMENT_SUCCESS: {
      const {assessmentResults} = action;
      const {
        hits = {}
      } = action.assessmentStatus;
      const newState = {
        ...state,
        sendInfectionAssessmentLoading: false,
      };
      if (isEqual(assessmentResults.assessmentResults, "Avaliação registrada com sucesso!")) {
        const {
          assessmentInfection,
          assessmentStatus,
          infectionDate,
        } = action.assessmentResults;

        newState.patientAssessment = {
          ...newState.patientAssessment,
          rawAssessments: hits,
          // infections: {
          //   ...newState.patientAssessment.infections,
          //   [assessmentInfection]: {
          //     title: upperCase(assessmentInfection),
          //     infectionDate,
          //     assessmentStatus: treatAssessmentStatus(assessmentStatus),
          //     className: assessmentStatus
          //   },
          // },
          lastAssessmentSaved: true
        }
      }

      return newState;
    }

    case actionTypes.GET_PATIENT_INFECTION_ASSESSMENT_STATUS_SUCCESS: {
      const {
        hits = {}
      } = action.assessmentStatus;

      const newState = {
        ...state,
        sendInfectionAssessmentLoading: false,
        patientAssessment: {
          rawAssessments: hits,
          infections: {},
          lastAssessmentSaved: false
        }
      };
      let infectionData = state.patientData;

      if(state.rawPatientData && Array.isArray(state.rawPatientData)){
        const searchInfection = state.rawPatientData.filter((patientInfection) => {
          return patientInfection._source.pred_ipcs === 1 ||
            patientInfection._source.pred_isc === 1 ||
            patientInfection._source.pred_itu === 1 ||
            patientInfection._source.pred_pav === 1 ||
            patientInfection._source.pred_pnm === 1 ||
            patientInfection._source.pred_traqueo === 1 
        });
        if(searchInfection.length > 0){
          infectionData = searchInfection[0]._source;
        }
      }
      


      Object.keys(infectionData).forEach((key) => {
        const [pred, infection] = key.split('_');
        if (pred === 'pred' && infectionData[key] === 1) {
          const infectionNameUppercased = infection.toUpperCase();
          const infectionPercentage = parseFloat(
            infectionData[infection] * 100
          ).toFixed(1);
          const className = ((infectionData.prob_grupo_isa === 'ALTA') ? 'alta-probabilidade' : 
          (infectionData.prob_grupo_isa === 'MEDIA') ? 'media-probabilidade' : '');

          newState.patientAssessment = {
            ...newState.patientAssessment,
            infections: {
              ...newState.patientAssessment.infections,
              [infection]: {
                title: `${infectionNameUppercased} ${infectionPercentage} %`,
                className,
                assessmentStatus: 'unassessed'
              },
            }
          }
        }
      });

      // if (Object.keys(hits).length) {
      //   console.log(hits)
      //   hits.hits.forEach((hit) => {
      //     const {
      //       tipo_infeccao,
      //       avaliacao_status,
      //       id
      //     } = hit._source;
      //     const translatedAssessmentName = `${avaliacao_status.substr(0, avaliacao_status.length - 1)}e`;
          
      //     console.log(avaliacao_status);

      //     // newState.patientAssessment = {
      //     //   ...newState.patientAssessment,
      //     //   infections: {
      //     //     ...newState.patientAssessment.infections,
      //     //     [tipo_infeccao]: {
      //     //       title: tipo_infeccao.toUpperCase(),
      //     //       className: avaliacao_status,
      //     //       assessmentStatus: translatedAssessmentName,
      //     //       id:id
      //     //     },
      //     //   }
      //     // }
      //   });
      // }

      return newState;
    }
    case actionTypes.SEND_PATIENT_INFECTION_ASSESSMENT_ERROR:
    case actionTypes.GET_PATIENT_INFECTION_ASSESSMENT_STATUS_ERROR:
      return {
        ...state,
        sendInfectionAssessmentLoading: false,
        patientAssessment: {
          ...state.patientAssessment,
          infections: {},
          lastAssessmentSaved: false
        }
      };

    case actionTypes.SET_PATIENT_VITAL_SIGNALS_HISTOGRAM_DATA:
      const referenceDate = moment.utc(action.referenceDate);
      const vitalSignals = state.patientFacts.filter(
        (fact) => fact._index === 'Sinais vitais' &&
          fact._source.tipo_sinal === 'TEMP'
      );
      let histogramData = {};

      const groupedVitalSignals = vitalSignals.reduce(
        (groupedVSignals, vitalSignal) => {
          const date = vitalSignal._source.dthr_coleta.split('T')[0];
          if (!groupedVSignals[date]) {
            groupedVSignals[date] = [];
          }
          groupedVSignals[date].push(vitalSignal);
          return groupedVSignals;
        }, {});

      const dates = Object.keys((groupedVitalSignals));

      dates.sort((a, b) => {
        return moment(a).valueOf() - moment(b).valueOf();
      });

      if (dates.length > 11) {
        const referenceDateIndex = dates.findIndex(
          (date) => moment.utc(date).isSame(referenceDate, 'day'));

        if (referenceDateIndex !== -1 && (referenceDateIndex > 10 || dates.length > 21)) {
          const elementsToRemoveBefore = referenceDateIndex - 10;
          const elementsToRemoveAfter = dates.length - (referenceDateIndex - elementsToRemoveBefore);

          if (elementsToRemoveBefore) {
            dates.splice(0, elementsToRemoveBefore);
          }

          if (elementsToRemoveAfter) {
            dates.splice(20, elementsToRemoveAfter);
          }

          // eslint-disable-next-line
          dates.map(date => {
            histogramData = {
              ...histogramData,
              [date]: groupedVitalSignals[date]
            }
          });
        } else {
          Object.assign(histogramData, groupedVitalSignals);
        }
      }

      return {
        ...state,
        patientHistogram: {
          ...state.patientHistogram,
          data: histogramData
        }
      };

    case actionTypes.TOGGLE_PATIENT_HISTOGRAM_DIALOG:
      return {
        ...state,
        patientHistogram: {
          ...state.patientHistogram,
          show: action.show
        }
      };
    case actionTypes.SET_PATIENT_FACTS:
      return {
        ...state,
        patientFacts: [
          ...action.patientFacts
        ]
      };
    default:
      return state;
  }
};
