import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { IconButton, Box, Divider } from '@material-ui/core';
import { DateTitle } from './DateTitle';
import { ChevronRight } from './ChevronRight';

export const IndicatorDateCardHeader = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '-8px',
  },
  container: {
    marginTop: '8px'
  }
}))(function StyledIndicatorDateCardHeader(props) {
  const { onClick, children, dateLabel, classes, className, ...divProps } = props;
  
  return (
    <>
      <div {...divProps} className={clsx(classes.root, className)}>
        <DateTitle>{dateLabel}</DateTitle>
        <IconButton onClick={onClick} size="small">
          <ChevronRight />
        </IconButton>
      </div>
      <Divider />
      <div className={classes.container}>{children}</div>
    </>
  );
});
