import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { formatNumber } from '../../../../../utils/formatters/formatNumber';
import { DashboardCard } from '../../DashboardLayout';
import { StatIcon } from '../StatIcon';
import { StatTitle } from '../StatTitle';
import { StatValue } from '../StatValue';
import { StatHelper } from '../StatHelper';

export const percentFormat = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});

export const IndicatorStat = withStyles((theme) => ({
  root: {
    position: 'relative',
    paddingRight: '56px',
  },
  icon: {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
  value: {
    marginBottom: theme.spacing(0.5),
  },
  helperContainer: {},
  percentage: {},
  helper: {},
}))(function StyledIndicatorStat(props) {
  const { classes, className, title, value, icon, percentage, helper } = props;

  const percentageColor =
    percentage < 0 ? 'danger' : percentage > 0 ? 'success' : 'dark';
  const percentageLabel = useMemo(
    () =>
      `${percentage > 0 ? '+' : ''}${percentFormat.format(percentage / 100)}`,
    [percentage]
  );

  const valueLabel = useMemo(
    () => (typeof value === 'number' ? formatNumber(value) : value),
    [value]
  );

  return (
    <DashboardCard className={clsx(classes.root, className)}>
      <StatIcon className={classes.icon}>{icon}</StatIcon>
      <StatTitle className={classes.title}>{title}</StatTitle>
      <StatValue className={classes.value}>{valueLabel}</StatValue>
      <div className={classes.helperContainer}>
        {typeof percentage !== 'undefined' && (
          <StatHelper className={classes.percentage} color={percentageColor}>
            {percentageLabel}
          </StatHelper>
        )}
        <StatHelper className={classes.helper}>{helper}</StatHelper>
      </div>
    </DashboardCard>
  );
});
