import {
  getPatientInfection,
  getPatientPills,
  getPatientVr,
  getPatientData,
  sendPatientHypotheticalInfectionAssessment,
  getPatientInfectionAssessment,
} from '../services/api/patient/patient.service';

export const actionTypes = {
  SET_PATIENT_FACTS: 'SET_PATIENT_FACTS',
  GET_PATIENT_DATA: 'GET_PATIENT_DATA',
  GET_PATIENT_INFECTIONS_SUCCESS: 'GET_PATIENT_INFECTIONS_SUCCESS',
  GET_PATIENT_INFECTIONS_ERROR: 'GET_PATIENT_INFECTIONS_ERROR',
  GET_PATIENT_PILLS_SUCCESS: 'GET_PATIENT_PILLS_SUCCESS',
  GET_PATIENT_PILLS_ERROR: 'GET_PATIENT_PILLS_ERROR',
  SEND_PATIENT_INFECTION_ASSESSMENT_SUCCESS:
    'SEND_PATIENT_INFECTION_ASSESSMENT_SUCCESS',
  SEND_PATIENT_INFECTION_ASSESSMENT_ERROR:
    'SEND_PATIENT_INFECTION_ASSESSMENT_ERROR',
  SEND_PATIENT_INFECTION_ASSESSMENT_REQUEST: 'SEND_PATIENT_INFECTION_ASSESSMENT_REQUEST',
  GET_PATIENT_INFECTION_ASSESSMENT_STATUS_SUCCESS:
    'GET_PATIENT_INFECTION_ASSESSMENT_STATUS_SUCCESS',
  GET_PATIENT_INFECTION_ASSESSMENT_STATUS_ERROR:
    'GET_PATIENT_INFECTION_ASSESSMENT_STATUS_ERROR',
  RESET_SAVED_ASSESSMENT_FLAG: 'RESET_SAVED_ASSESSMENT_FLAG',
  SET_PATIENT_VITAL_SIGNALS_HISTOGRAM_DATA: 'SET_PATIENT_VITAL_SIGNALS_HISTOGRAM_DATA',
  TOGGLE_PATIENT_HISTOGRAM_DIALOG: 'TOGGLE_PATIENT_HISTOGRAM_DIALOG',
  SEND_PATIENT_INFECTION_ASSESSMENT_STATUS: 'SEND_PATIENT_INFECTION_ASSESSMENT_STATUS',
  GET_PATIENT_VR: 'GET_PATIENT_VR'
};


export const setPatientFactsAction = (patientFacts) => ({
  type: actionTypes.SET_PATIENT_FACTS,
  patientFacts,
});

/**
 * Patient Data request success action creator
 *
 * @param patientData
 * @returns {{patiendData: *, type: string}}
 */
const patientDataAction = (patientData) => ({
  type: actionTypes.GET_PATIENT_DATA,
  patientData,
});

export const getPatientDataAction = (patientId) => async (dispatch) => {
  const patientData = await getPatientData(patientId);
  dispatch(patientDataAction(patientData));
};

const patientInfectionsSuccessAction = (patientInfections) => ({
  type: actionTypes.GET_PATIENT_INFECTIONS_SUCCESS,
  patientInfections,
});

const patientInfectionsErrorAction = () => ({
  type: actionTypes.GET_PATIENT_INFECTIONS_ERROR,
});

export const getPatientInfectionAction = (patientId) => async (dispatch) => {
  try {
    const patientInfections = await getPatientInfection(patientId);
    dispatch(patientInfectionsSuccessAction(patientInfections));
  } catch (error) {
    dispatch(patientInfectionsErrorAction());
  }
};

/**
 * Patient pills request success action creator
 *
 * @param patientPills
 * @returns {{patientId: *, type: string}}
 */
const patientPillsSuccessAction = (patientPills) => ({
  type: actionTypes.GET_PATIENT_PILLS_SUCCESS,
  patientPills,
});

/**
 * Patient pills request error action creator
 *
 * @returns {{type: string}}
 */
const patientPillsErrorAction = () => ({
  type: actionTypes.GET_PATIENT_PILLS_ERROR,
});

export const patientPills = (patientId) => async (dispatch) => {
  try {
    const patientPills = await getPatientPills(patientId);
    dispatch(patientPillsSuccessAction(patientPills));
  } catch (error) {
    dispatch(patientPillsErrorAction(error));
  }
};

const patientVrAction = (patientVr) => ({
  type: actionTypes.GET_PATIENT_VR,
  patientVr,
})

export const getPatientVrAction = (patientId) => async (dispatch) => {
    const patientVr = await getPatientVr(patientId);

    dispatch(patientVrAction(patientVr))
}

/**
 * Patient infection assessment request success action creator
 *
 * @param assessmentResults
 * @returns {{patientId: *, type: string}}
 */

const patientInfectionAssessRequest = () => ({
  type: actionTypes.SEND_PATIENT_INFECTION_ASSESSMENT_REQUEST,
});

const patientInfectionAssessSuccess = (assessmentResults) => ({
  type: actionTypes.SEND_PATIENT_INFECTION_ASSESSMENT_SUCCESS,
  assessmentResults,
});

/**
 * Patient infection assessment request error action creator
 *
 * @returns {{type: string}}
 */
const patientInfectionAssessError = () => ({
  type: actionTypes.SEND_PATIENT_INFECTION_ASSESSMENT_ERROR,
});

export const patientAssessment = (assessmentInformations) => async (
  dispatch
) => {
  dispatch(patientInfectionAssessRequest());

  try {
    const assessmentResults = await sendPatientHypotheticalInfectionAssessment(
      assessmentInformations
    );
    dispatch(
      patientInfectionAssessSuccess({
        assessmentResults,
        ...assessmentInformations,
      })
    );
  } catch (error) {
    dispatch(patientInfectionAssessError(error));
  }
};

const patientInfectionAssessmentRequestSuccess = (assessmentStatus) => ({
  type: actionTypes.GET_PATIENT_INFECTION_ASSESSMENT_STATUS_SUCCESS,
  assessmentStatus,
});

const patientInfectionAssessmentRequestError = () => ({
  type: actionTypes.GET_PATIENT_INFECTION_ASSESSMENT_STATUS_ERROR,
});

export const patientAssessmentStatus = (infectionInformations) => async (
  dispatch
) => {
  try {
    const assessmentStatus = await getPatientInfectionAssessment(
      infectionInformations
    );
    dispatch(patientInfectionAssessmentRequestSuccess(assessmentStatus));
  } catch (error) {
    dispatch(patientInfectionAssessmentRequestError(error));
  }
};

export const resetSavedAssessment = () => ({
  type: actionTypes.RESET_SAVED_ASSESSMENT_FLAG
});

export const patientVitalSignalsHistogramDataAction = (referenceDate) => ({
  type: actionTypes.SET_PATIENT_VITAL_SIGNALS_HISTOGRAM_DATA,
  referenceDate
});

export const togglePtientHistogramDialog = (show = true) => ({
  type: actionTypes.TOGGLE_PATIENT_HISTOGRAM_DIALOG,
  show
})



