import moment from "moment";

export const suspicionsRequestBody = (paciente_id) => ({
    size: 11360,
    from: 0,
    query: {
      bool: {
        minimum_should_match: 1,
        should: [
          {
            bool: {
              must: {
                term: { paciente_id: paciente_id },
              },
            },
          },
        ],
      },
    }
  });

  
export const allSuspicionsRequestBody = ({
  initialDate,
  finalDate,
  orderByField,
  infectionsFilter,
  nome
}) => {


  return {
    size: 20000,
    from: 0,
    query: {
      bool: {
        must: [
          nome && {query_string: { query: `*${nome}*`, default_field: "nome"}},
          { range: {
            dt_infeccao: {
              gte: moment(initialDate).utc().toISOString(),
              lte: moment(finalDate).utc().toISOString(),
            },
          },
        }],
        // minimum_should_match: 1,
        // should: infectionsFilter.map((infection) => {
        //   return {
        //     range: {
        //       [`pred_${infection}`]: { gte: 1 },
        //     },
        //   };
        // }),
      },
    },
    sort: [orderByField === 'nome' ? { nome: 'asc' } : { dt_infeccao: 'desc' }],
  };
};