import React, { useCallback, useMemo } from 'react';
import { useTimelineChartContext } from './TimelineChartProvider';
import { animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';

export const TimelineScroll = ({ children }) => {
  const { scrollApi, scrollX, clampScrollX } = useTimelineChartContext();

  const containerDragGesture = useDrag(
    useCallback(
      ({ down, delta: [dx] }) => {
        scrollApi.start({ cancel: true });
        const currentX = scrollX.get();
        const nextX = currentX + dx;

        scrollApi.start({
          x: clampScrollX(nextX),
          immediate: down,
        });
      },
      [scrollApi, clampScrollX, scrollX]
    ),
    useMemo(
      () => ({
        axis: 'x',
        filterTaps: true,
        pointer: {
          touch: !!navigator.maxTouchPoints,
        },
        threshold: 0,
      }),
      []
    )
  );

  return (
    <animated.g
      data-name="scroll-container"
      {...containerDragGesture()}
      style={{ x: scrollX, touchAction: 'none', cursor: 'grab' }}
    >
      {children}
    </animated.g>
  );
};
