import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import OldTimelineChart from '../../components/atoms/TimelineChart';
import TimelineTemplate from '../../templates/Timeline';
import {
  useGetPatientQuery,
  useGetPatientFactsQuery,
  useGetSuspicionsQuery,
  useGetAssessmentsListQuery,
} from '../../services';

import {
  setTimelinePatientId,
  useTimelinePatientId,
  setTimelineCursor,
  useTimelineCursor,
  useTimelineAvailableIndicators,
} from './slices';
import { TimelineHeading } from './TimelineHeading';
import { TimelineIndicators } from './TimelineIndicators';
import { TimelineSidebar } from './TimelineSidebar';
import { TimelineActions } from './TimelineActions';
import { TimelineDrawer } from './TimelineDrawer';
import { TimelineChart } from './TimelineChart';
import { useTimelineKeyboard } from './useTimelineKeyboard';
import { ChatComment } from '../../components/organisms/ChatComments/ChatComment';


import { useGetPatientHospitalizationQuery, useGetPatientByIdQuery } from '../../services';

const TimelinePage = () => {

  const [isLoadingPatientHospitalization, setIsLoadingPatientHospitalization] = useState(true);

  // Router parameters
  const { id } = useParams();

  // Define o id do paciente da timeline
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTimelinePatientId(id));
  }, [dispatch, id]);


  const patientId = useTimelinePatientId();
  const {
    isLoading: isLoadingPatientQuery,
    isFetching: isFetchingPatientQuery
  } = useGetPatientQuery(patientId, {
    skip: !patientId,
  });

  const {
    isLoading: isLoadingPatient,
    isFetching: isFetchingPatient,
    data: patient 
  } = useGetPatientByIdQuery(patientId, {
    skip: !patientId,
  });


  useEffect(() => {
    console.log(patient);
  }, [isLoadingPatient]);

  const {
    data: patientFacts,
    isLoading: isLoadingPatientFactsQuery,
    isFetching: isFetchingPatientFactsQuery,
  } = useGetPatientFactsQuery(patientId, {
    skip: !patientId,
  });
  const {
    data: patientSuspicions,
    isLoading: isLoadingSuspicionsQuery,
    isFetching: isFetchingSuspicionsQuery,
  } = useGetSuspicionsQuery(patientId, {
    skip: !patientId
  });

  const {
    data: assessmentList,
    isLoading: isLoadingAssessmentsListQuery,
    isFetching: isFetchingAssessmentsListQuery,
  } = useGetAssessmentsListQuery(patientId, {
    skip: !patientId,
  });
  const isLoadingChart =
    isLoadingPatientQuery ||
    isFetchingPatientQuery ||
    isLoadingPatientFactsQuery ||
    isFetchingPatientFactsQuery || 
    isLoadingPatientHospitalization;

  return (
    <TimelineTemplate>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex="0 0 auto">
          <Grid container display="flex" spacing={2}>
            <Grid item xs={12} sm={8} lg={9}>
              <TimelineHeading companyId={patient?.companyId} setIsLoadingPatientHospitalization={setIsLoadingPatientHospitalization}/>
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              {!isLoadingPatient && <TimelineActions companyId={patient?.companyId}/>}
            </Grid>
          </Grid>
        </Box>
        <Box flex="1 0 auto" position="relative" overflow="hidden">
          <Grid container display="flex" spacing={2}>
            <Grid item xs={12} sm={8} lg={9}>
              <Box height="100%" display="flex" flexDirection="column">
                <Box flex="0 0 auto">
                  <TimelineIndicators loading={isLoadingChart} />
                </Box>
                <Box flex="1 0 calc(100vh - 165px)" position="relative">
                  {/* <OldTimelineChart patientId={patientId} /> */}
                  {patientFacts && patientFacts.length === 0 && assessmentList && assessmentList?.results.length === 0 ?
                    (
                      <Box display="flex" height="100%" justifyContent="center" alignItems="center" flexDirection="column">
                        <h2 style={{ color: 'rgba(62, 66, 71, 0.88)', fontSize: '20px' }}>Nenhuma informação encontrada</h2>
                        <p style={{ color: 'rgba(62, 66, 71, 0.48)', fontSize: '16px', marginTop: 4 }}>Não encontramos dados relacionados a este paciente</p>
                      </Box>
                    ) : (
                      <TimelineChart loading={isLoadingChart} />
                    )
                  }
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <Box height="100%" position="relative">
                <TimelineSidebar />
                <ChatComment />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <TimelineDrawer />
    </TimelineTemplate>
  );
};

export default TimelinePage;
