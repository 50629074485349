import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import { ButtonBase, withStyles } from '@material-ui/core';
import { indicatorsEnum } from '../../../../../constants/indicators';
import { useSetTimelineCursor } from '../../../slices';

export const MedicineDateButton = withStyles(() => ({
  root: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
  },
}))(ButtonBase);

export const MedicineDate = ({ date }) => {
  const setTimelineCursor = useSetTimelineCursor();
  const handleClick = useCallback(() => {
    return setTimelineCursor({ indicator: indicatorsEnum.medicamentos, date });
  }, [setTimelineCursor, date]);

  const formattedDate = useMemo(() => date.format('DD/MM/YYYY'), [date]);

  return (
    <MedicineDateButton onClick={handleClick}>
      {formattedDate}
    </MedicineDateButton>
  );
};

export const MedicineRangeItem = ({ startMoment, endMoment }) => {
  return (
    <>
      <MedicineDate date={startMoment} />
      <>-</>
      <MedicineDate date={endMoment} />
    </>
  );
};
