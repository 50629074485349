import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { upperFirst } from 'lodash';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { map } from 'lodash';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStyles from './styles';
import {
  useTimelineSearchInstance,
  useTimelineSearchWord,
  useTimelineEncontroTerms,
  useTimelineKeywordsRegex,
  useTimelineSearchRegex,
  useTimelineEncontrosAccordion,
  useTimelineFirstEncontroAccordion,
  useTimelineSearchResults,
  useTimelineSearchResultsInstances,
} from '../../../slices';
import { HighlightedContent } from './HighlightedContent';

import {
  useTimelinePatientId,
} from '../../../../Timeline/slices';

import {
  useGetPatientEvolutionQuery,
} from '../../../../../services';

export const AccordionItem = (props) => {
  const { professional, textContent, encontro } = props;
  const classes = useStyles();
  const title = useMemo(
    () => upperFirst(professional.replace(/^\[|\]$/g, '').toLowerCase()),
    [professional]
  );

  const [expanded, setExpanded] = useState(false);
  const searchInstance = useTimelineSearchInstance();

  const searchWord = useTimelineSearchWord();
  const aiKeywords = useTimelineEncontroTerms();
  const regex = useTimelineKeywordsRegex();

  const handleChange = useCallback(
    (e, expanded) => {
      setExpanded(expanded);
    },
    [setExpanded]
  );

  const resultsInstances = useTimelineSearchResultsInstances();
  const professionalInstances = resultsInstances.professionals[professional];
  const hasMatches = !!professionalInstances;
  const isCurrentMatch = useMemo(() => {
    if (!searchInstance || !professionalInstances) return false;
    const [start, end] = professionalInstances;
    return searchInstance >= start && searchInstance <= end;
  }, [professionalInstances, searchInstance]);

  const handleScrollToInstance = useCallback(() => {
    const target = document.querySelectorAll(
      `[data-keyword-match="${searchWord}"]`
    )[searchInstance - 1];
    if (!target) return;
    target.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [searchInstance, searchWord]);

  useEffect(() => {
    if (isCurrentMatch && expanded && encontro) {
      handleScrollToInstance();
    }
  }, [isCurrentMatch, expanded, encontro, handleScrollToInstance]);

  const handleEntered = useCallback(() => {
    if (isCurrentMatch) {
      handleScrollToInstance();
    }
  }, [isCurrentMatch, handleScrollToInstance]);

  const shouldExpand = hasMatches && isCurrentMatch;
  useEffect(() => {
    if (shouldExpand && searchInstance) setExpanded(true);
  }, [shouldExpand, setExpanded, searchInstance]);

  return (
    <Accordion
      className={classes.accordion}
      TransitionProps={{
        mountOnEnter: true,
        onEntered: handleEntered,
      }}
      expanded={expanded}
      defaultExpanded={shouldExpand}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={
          expanded
            ? {
              position: 'sticky',
              top: '0px',
              backgroundColor: '#f7f7f7',
            }
            : {}
        }
      >
        <Typography className={classes.accordionSummary}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <HighlightedContent
          textContent={textContent}
          regex={regex}
          searchWord={searchWord}
          aiKeywords={aiKeywords}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export const AccordionEvolucao = ({ encontro }) => {
  const accordion = useTimelineEncontrosAccordion();
  const firstAccordion = useTimelineFirstEncontroAccordion();

  const patientId = useTimelinePatientId();

  const {
    data: patientEvolutionData,
  } = useGetPatientEvolutionQuery(
    { patientId },
    {
      skip: !patientId,
      selectFromResult: (result) => {
        if (!result.isSuccess) return result;
        return {
          ...result,
          data: {
            allItems: result.data,
            itemsOfDate: result.data.filter((item) =>
              moment(item._source.dt_encontro).isSame(encontro?.dt_encontro)
            ),
          },
        };
      },
    }
  );

  const meetingDate = moment(encontro?.dt_encontro);

  const todaysIndex = patientEvolutionData?.allItems.findIndex((date) =>
    moment(date._source.dt_encontro).isSame(meetingDate)
  );

  if (todaysIndex === -1) return; // TODO: TRATAR DATA NAO ENCONTRADA

  const hasPreviousDate = todaysIndex > 0;

  return (
    <>
      {map(hasPreviousDate ? accordion : firstAccordion, (text, professional) => (
        <AccordionItem
          key={professional}
          encontro={encontro}
          professional={professional}
          textContent={text}
        />
      ))}
    </>
  );
};
