/**
 * Sinais vitais
 *
 * @constant
 * @type {{ TEMP: 'TEMP', FR: 'FR', OXIMETRIA: 'OXIMETRIA', O2: 'O2', FC: 'FC', PAS: 'PAS', PAD: 'PAD', PAM: 'PAM', HGT: 'HGT', DOR: 'DOR', PA: 'PA', PAdot: 'PA.' }}
 */
 export const vitalSignsEnum = {
    TEMP: 'TEMP',
    FR: 'FR',
    OXIMETRIA: 'OXIMETRIA',
    O2: 'O2',
    FC: 'FC',
    PAS: 'PAS',
    PAD: 'PAD',
    PAM: 'PAM',
    HGT: 'HGT',
    DOR: 'DOR',
    PA: 'PA',
    PAdot: 'PA.',
};
  
/**
 * Sinais vitais
 *
 * @constant
 * @type {{ TEMP: 'TEMP', FR: 'FR', OXIMETRIA: 'OXIMETRIA', O2: 'O2', FC: 'FC', PAS: 'PAS', PAD: 'PAD', PAM: 'PAM', HGT: 'HGT', DOR: 'DOR', PA: 'PA', PAdot: 'PA.'  }}
 */
export const vitalSignsList = Object.keys(vitalSignsEnum);