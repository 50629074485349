import { useCallback, useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectTimelineIndicators } from './selectTimelineIndicators';

/**
 * Por nome do indicador
 */
const memoEmpty = [];

/**
 * @param {keyof typeof indicatorsEnum} indicator
 */
export const makeSelectTimelineIndicator = () => {
  const selectTimelineIndicator = createSelector(
    selectTimelineIndicators,
    (_, indicator) => indicator,
    (indicators, indicator) => indicators?.[indicator] || memoEmpty
  );

  return selectTimelineIndicator;
};
export const useTimelineIndicator = (indicator) => {
  const select = useMemo(makeSelectTimelineIndicator, []);
  const selector = useCallback((state) => select(state, indicator), [
    indicator,
    select,
  ]);
  return useSelector(selector);
};
