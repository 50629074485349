import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectTimelineIndicatorsDates } from './selectTimelineIndicatorsDates';
import { mapValues, keys, flatMap, uniq } from 'lodash';
import { timeDays } from 'd3';
import moment from 'moment';

export const selectTimelineDates = createSelector(
  selectTimelineIndicatorsDates,
  (indicatorsDates) => {
    if (!indicatorsDates) return;

    return mapValues(indicatorsDates, (dates) =>
      keys(dates)
        .map((date) => parseInt(date))
        .sort((a, b) => b - a)
    );
  }
);
export const useTimelineDates = () => useSelector(selectTimelineDates);

// Retorna todas as datas
export const selectTimelineAllDates = createSelector(
  selectTimelineDates,
  (timelineDates) => {
    if (!timelineDates) return;
    return uniq(flatMap(timelineDates)).sort((a, b) => b - a);
  }
);
export const useTimelineAllDates = () => useSelector(selectTimelineAllDates);

// Retorna a maior data da timeline
export const selectTimelineMaxDate = createSelector(
  selectTimelineAllDates,
  (allDates) => {
    if (!allDates) return;
    return allDates[0];
  }
);
export const useTimelineMaxDate = () => useSelector(selectTimelineMaxDate);

// Retorna a data inicial da timeline
export const selectTimelineMinDate = createSelector(
  selectTimelineAllDates,
  (allDates) => {
    if (!allDates) return;
    return allDates[allDates.length - 1];
  }
);
export const useTimelineMinDate = () => useSelector(selectTimelineMinDate);

// Retorna todas as datas entre a data inicial e a final
export const selectTimelineDateRange = createSelector(
  [selectTimelineMinDate, selectTimelineMaxDate],
  (minDate, maxDate) => {
    if (!minDate || !maxDate) return;
    return timeDays(minDate, moment(maxDate).add({ day: 1 }).valueOf())
      .reverse()
      .map((d) => moment(d));
  }
);
export const useTimelineDateRange = () => useSelector(selectTimelineDateRange);
