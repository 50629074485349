import { createApi } from '@reduxjs/toolkit/query/react'
import {  baseQueryWithReauth } from '../baseQuery'

export const companiesApi = createApi({
  reducerPath: 'companiesApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Companies'],
  endpoints: (build) => ({
    getLoggedUserCompanies: build.query({
      query: () => {
        return {
          url: `/companies/logged-user`,
          method: 'GET',
        }
      }
    })
  })
})

export const { 
  useGetLoggedUserCompaniesQuery
} = companiesApi
