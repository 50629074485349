import { useTheme } from '@material-ui/core';
import React from 'react';

export const RadioIcon = (props) => {
  const { checked, disabled } = props;
  const theme = useTheme()
  
  return (
    <svg
      style={{ opacity: disabled ? 0.48 : 1 }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill="#F5F5F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1Z"
        fill="#8F95A3"
      />
      <circle
        cx="10"
        cy="10"
        r="6"
        fill={disabled ? '#b7b7b7' : theme.palette.primary.main}
        style={{
          transition: `all .15s ease-${checked ? 'in' : 'out'} 0s`,
          transformOrigin: 'center',
          transform: `scale(${checked ? 1 : 0})`,
        }}
      />
    </svg>
  );
};
