import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    padding: '14px 0',
    position: 'relative',
  },
  examItem: {
    marginLeft: 16,
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'left',
  },
  feedbackCircle: {
    width: 8,
    height: 8,
    backgroundColor: '#F86975',
    borderRadius: '50%',
    marginTop: 6,
    position: 'absolute'
  },
  cardTitle: {
    color: theme.palette.neutral.black['72'],
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: 400,
    fontFamily: 'DM Mono',
  },
  cardInfo: {
    color: theme.palette.neutral.black['48'],
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: 400,
    fontFamily: 'DM Mono',
  },
  arrowIcon: {
    color: theme.palette.neutral.black['48'],
    fontSize: 14
  },
  drawerBottom: {
    '& .MuiPaper-root': {
      width: '480px',
      right: 0,
      left: 'auto',
      borderRadius: '8px 8px 0 0',
      maxHeight: 'calc(100% - 82px)',

      '&::-webkit-scrollbar': {
        width: 10
      },

       /* Fundo da barra de rolagem */
      '&::-webkit-scrollbar-track-piece': {
          backgroundColor: 'transparent'
      },
      /* Cor do indicador de rolagem */
      '&::-webkit-scrollbar-thumb:vertical, &::-webkit-scrollbar-thumb:horizontal': {
          backgroundColor: '#c4c4c4',
          borderRadius: 10,
      },
      /* Cor do indicador de rolagem - ao passar o mouse */
      '&::-webkit-scrollbar-thumb:vertical:hover,&::-webkit-scrollbar-thumb:horizontal:hover': {
          backgroundColor: '#b6b6b6'
      }

    }
  },
  drawerTitle: {
    color: theme.palette.neutral.black['72'],
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: 400,
    fontFamily: 'DM Mono',
  },
  dataPedidoContainer: {
    borderTop: '1px solid rgba(62, 66, 71, 0.16)',
    borderBottom: '1px solid rgba(62, 66, 71, 0.16)',
    padding: '12px 0',
    color: theme.palette.neutral.black['48'],
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: 400,
    fontFamily: 'DM Mono',
  },
  divider: {
    backgroundColor: '1px solid rgba(62, 66, 71, 0.16)',
    marginLeft: 16
  }
}))

export default useStyles;
