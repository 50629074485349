import * as d3 from 'd3';
import { mergeWith, add, map, mapValues, flatMap, uniq, groupBy } from 'lodash';

/**
 * Gráfico de barras
 */

// Estilos
let margin = {
  top: 60,
  bottom: 20,
  left: 140,
  right: 20,
};

export default class D3HorizontalBarChart {
  // Containers
  svg = null;
  xAxisGroup = null;
  yAxisGroup = null;
  container = null;
  width = 0;
  height = 400;

  xPaddingInner = null;
  xPaddingOuter = null;
  yGutter = null;

  constructor({
    container,
    dataset: originalDataset = [],
    isLoading,
    xPaddingInner = 0.1,
    xPaddingOuter = 0.25,
    yGutter = 0.02,
  }) {
    const dataset = isLoading
      ? [
          {
            label: 'Carregando',
            color: '#cccccc',
            values: Array.from({ length: 6 }, (_, i) => ({
              label: ''.padStart(i),
              value: Math.random() * (i + 1),
            })),
          },
        ]
      : originalDataset;

    this.linesDataset = dataset.filter((e) => e.type === 'line');
    this.barsDataset = dataset.filter((e) => e.type !== 'line');

    this.container = container;
    this.dataset = dataset;
    this.isLoading = isLoading;
    this.xPaddingInner = xPaddingInner;
    this.xPaddingOuter = xPaddingOuter;
    this.yGutter = yGutter;

    this.containerSelection = d3.select(this.container);

    this.legends = dataset.map(({ label, color }) => ({ label, color }));
    this.groups = map(this.legends, 'label');
    this.colors = map(this.legends, 'color');

    this.values = dataset.map(({ values }) =>
      Object.fromEntries(values.map(({ label, value }) => [label, value]))
    );

    this.totals = mergeWith({}, ...this.values, add);
    this.yLabels = Object.keys(this.totals);

    this.maxX = d3.max(Object.values(this.totals));

    this.stack = this.setupStack();
  }

  setupStack() {
    const generateStack = d3
      .stack()
      .keys(this.groups)
      .offset(d3.stackOffsetNone(1));

    const stack = generateStack(
      this.yLabels.map((yLabel) => {
        return {
          label: yLabel,
          ...Object.fromEntries(
            this.barsDataset.map(({ label, values }) => [
              label,
              values.find((e) => e.label === yLabel)?.value || 0,
            ])
          ),
        };
      })
    );

    return stack;
  }

  changeSizes() {
    this.containerSelection.selectAll('svg').remove();
    this.setup();
    this.update();
  }

  setup() {
    const containerRect = this.container.getBoundingClientRect();

    this.width = containerRect.width;
    this.height = containerRect.height;
    this.chartWidth = this.width - margin.left - margin.right;
    this.chartHeight = this.height - margin.top - margin.bottom;

    this.svg = this.containerSelection
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    this.xAxisLines = this.svg.append('g');

    this.entriesContainer = this.svg.append('g');

    this.xAxisGroup = this.svg.append('g');
    // this.xAxisGroup
    //   .append('rect')
    //   .attr('fill', 'white')
    //   .attr('x', 0)
    //   .attr('y', 0)
    //   .attr('width', this.chartWidth)
    //   .attr('height', margin.top);

    this.yAxisGroup = this.svg.append('g');
  }

  update() {
    const yScale = d3
      .scaleBand()
      .domain(this.yLabels)
      .rangeRound([0, this.chartHeight])
      .paddingInner(this.xPaddingInner)
      .paddingOuter(this.xPaddingOuter);

    const xScale = d3
      .scaleLinear()
      .domain([0, this.maxX * 1.2])
      .range([0, this.chartWidth]);

    const colorScale = d3.scaleOrdinal().domain(this.groups).range(this.colors);

    // Legendas do Eixo X
    const xAxisCall = d3.axisTop(xScale).tickSizeOuter(0);
    // Legendas do Eixo Y
    const yAxisCall = d3.axisLeft(yScale).tickSizeOuter(0);

    if (!this.isLoading) {
      this.xAxisGroup
        .call(xAxisCall)
        .call((g) => g.selectAll('.tick line, .domain').remove());

      this.yAxisGroup
        .call(yAxisCall)
        .call((g) => g.selectAll('.domain').remove());
    }

    // Linhas do eixo Y
    this.xAxisLines
      .selectAll('.line')
      .data(xAxisCall.scale().ticks())
      .enter()
      .append('line')
      .attr('class', 'line')
      .attr('y1', 0)
      .attr('y2', this.chartHeight)
      .attr('x1', xScale)
      .attr('x2', xScale)
      .attr('stroke', 'rgba(143, 149, 163, 0.16)')
      .attr('stroke-width', 1);

    // Barras
    this.entriesContainer
      .append('g')
      .attr('data-entries', 'bars')
      .selectAll('g')
      .data(this.stack)
      .enter()
      .append('g')
      .attr('fill', (d) => colorScale(d.key))
      .attr('data-group', (d) => d.key)
      .selectAll('rect')
      .data((d) =>
        d.map((stackItem) => ({
          value: stackItem.data[d.key],
          valueStart: stackItem[0],
          valueEnd: stackItem[1],
          key: d.key,
          label: stackItem.data.label,
        }))
      )
      .enter()
      .append('rect')
      .attr('x', (d) => xScale(d.valueStart))
      .attr('y', (d) => yScale(d.label))
      .attr('width', (d) => xScale(d.valueEnd) - xScale(d.valueStart))
      .attr('height', yScale.bandwidth())
      .on('mousemove', (d) => this.mousemove(d.label, d.key, d.value))
      .on('mouseleave', () => this.mouseleave());

    // Pontos e linhas
    const linesContainer = this.entriesContainer
      .append('g')
      .attr('data-entries', 'lines')
      .selectAll('g')
      .data(this.linesDataset)
      .enter()
      .append('g')
      .attr('fill', (d) => colorScale(d.label))
      .attr('data-group', (d) => d.label);

    // Linhas
    const line = d3
      .line()
      .x((d) => xScale(d.value))
      .y((d) => yScale(d.label) + yScale.bandwidth() / 2);

    linesContainer
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', (d) => colorScale(d.label))
      .attr('stroke-width', 3)
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .attr('stroke-opacity', 1)
      .attr('d', (d) => line(d.values));

    // Circulos
    linesContainer
      .selectAll('g')
      .data((d) => d.values.map((e) => ({ ...e, parent: d.label })))
      .enter()
      .append('circle')
      .attr('r', 6)
      .attr('cx', (d) => xScale(d.value))
      .attr('cy', (d) => yScale(d.label) + yScale.bandwidth() / 2)
      .on('mousemove', (d) => this.mousemove(d.label, d.parent, d.value))
      .on('mouseleave', () => this.mouseleave());

    // Legendas
    this.containerSelection.select('.legend-container').remove();
    this.containerSelection.append('div').attr('class', 'legend-container');

    const legends = this.containerSelection
      .select('.legend-container')
      .selectAll('.legend')
      .data(this.legends)
      .enter()
      .append('div')
      .attr('class', 'legend');

    if (!this.isLoading) {
      legends
        .append('div')
        .attr('class', 'bullet')
        .style('background-color', (d) => d.color)
        .attr('rx', 4);
    }

    legends
      .append('div')
      .text((d) => d.label)
      .attr('class', 'tick');
  }

  mouseleave() {
    d3.select('.tooltip').remove();
  }

  mousemove(yLabel, group, value) {
    d3.select('.tooltip').remove();

    if (this.isLoading) return;

    const groupIndex = this.groups.indexOf(group);
    const total = this.totals[yLabel];

    const valuesContent = this.values
      .map((v, i) => {
        const fontWeight = i === groupIndex ? 700 : 400;
        return `<span style="font-weight: ${fontWeight};">${this.groups[i]}: ${v[yLabel]}</span>`;
      })
      .reverse()
      .join('<br />');

    d3.select('#root')
      .append('div')
      .attr('class', 'tooltip')
      .style('left', d3.event.pageX + 10 + 'px')
      .style('top', d3.event.pageY + 10 + 'px')
      .html(
        `<div>
          <div>${yLabel}</div><br/>
          <div>
            ${valuesContent}
          </div><br/>
          <div>TOTAL: ${total}</div>
        </div>`
      );
  }
}
