/**
 * Lista de microorganismos
 * Microorganismo relacionado à infecção.
 */
export const microorganismsEnum = /** @type {const} */ ({
  1: 'Acinetobacter',
  2: 'Aspergillus',
  3: 'Bacilo gram negativo',
  4: 'Coccos gram positivos',
  5: 'gram negativo',
  6: 'gram positivo',
  7: 'Bacilo gram negativo não fermentador',
  8: 'Bacilus',
  9: 'Bacterioides',
  10: 'Bordetella',
  11: 'Burcella',
  12: 'Burkholderia',
  13: 'Burkholderia cepacea',
  14: 'Candida',
  15: 'Candida albicans',
  16: 'Candida glabrata',
  17: 'Candida Krusei',
  18: 'Candida lusitaneae',
  19: 'Candida tropicalis',
  20: 'Candida parapsilosis',
  21: 'Citrobacter',
  22: 'Clostridium difficile',
  23: 'Cryptococcus',
  24: 'Elizabethkingia',
  25: 'Enterobacter',
  26: 'Enterococcus',
  27: 'Escherichia coli',
  28: 'Hafnia',
  29: 'Klebsiella',
  30: 'Legionella',
  31: 'Listeria',
  32: 'Moraxella',
  33: 'Morganella',
  34: 'Mycobacterium',
  35: 'Mycoplasma',
  36: 'Neisseria',
  37: 'Pasteurella',
  38: 'Proteus',
  39: 'Providencia',
  40: 'Pseudomonas',
  41: 'Salmonella',
  42: 'Serratia',
  43: 'Shigella',
  44: 'Sphingomonas',
  45: 'Staphylococcus',
  46: 'Staphylococcus aureus',
  47: 'Staphylococcus coagulase negativo',
  48: 'Stenotrophomonas',
  49: 'Streptococcus',
  50: 'Trichosporon',
  51: 'Yersinia',
  // 52: 'Staphylococcus capitis',
  // 53: 'Staphylococcus hominis',
});

/**
 * Valor utilizado quando o microorganismo não é encontrado na lista
 */
export const otherMicroorganism = 'outro';
