import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

export const PopoverScrollContent = withStyles(() => ({
  root: {
    width: '100%',
    maxHeight: '100%',
    padding: '6px',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: 10,
    },

    '&::-webkit-scrollbar-track-piece': {
      backgroundColor: 'transparent',
    },

    '&::-webkit-scrollbar-thumb': {
      '&:vertical, &:horizontal': {
        backgroundColor: '#c4c4c4',
        borderRadius: 10,
        '&:hover': {
          backgroundColor: '#b6b6b6',
        },
      },
    },
  },
}))(({ classes, className, ...props }) => (
  <div {...props} className={clsx(classes.root, className)} />
));
