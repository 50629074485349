import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { patientApi } from '../../../../services';
import { selectTimelinePatientId } from './selectTimelinePatientId';
import { map } from 'lodash'

// Selector que mescla o patientId da timeline, busca a query de patient facts com este id e mapeia o resultado
export const selectTimelinePatientFactsSelector = createSelector(
  selectTimelinePatientId,
  (patientId) => patientApi.endpoints.getPatientFacts.select(patientId)
);
export const selectTimelinePatientFactsData = createSelector(
  [(state) => state, selectTimelinePatientFactsSelector],
  (state, selector) => selector(state)?.data
);
export const selectTimelinePatientFacts = createSelector(
  selectTimelinePatientFactsData,
  (data) => {
    const sources = (data ? map(data, '_source') : undefined)
    return sources;
  }
);
export const useTimelinePatientFacts = () => {
  return useSelector(selectTimelinePatientFacts);
};
