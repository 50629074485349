import { useMemo, useRef, useEffect, useCallback } from 'react';
import useKey from 'react-use/lib/useKey';
import { throttle, noop } from 'lodash';
import {
  useSetTimelineNextDay,
  useSetTimelinePrevDay,
  useSetTimelineNextIndicator,
  useSetTimelinePrevIndicator,
} from './slices';

const useThrottledCallback = (fn = noop, ms = 100) => {
  // O callback é guardado em uma ref para previnir a reinstancialização do lodash.throttle quando a referência da função mudar
  const fnRef = useRef(fn);
  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  // Define o callback
  const throttledFn = useMemo(() => {
    return throttle(() => fnRef.current(), ms, { trailing: false });
  }, [fnRef, ms]);

  // Cancela o callback ao desmontar componente
  useEffect(() => () => throttledFn.cancel(), [throttledFn]);

  return throttledFn;
};

const useTimelineKeyHandler = (key, handler) => {
  const throttledHandler = useThrottledCallback(handler);
  const keyHandler = useCallback(
    (e) => {
      // Filtra eventos que são originados de dropdowns, inputs, selects, etc
      if (
        ['input', 'select'].includes(e.target.tagName.toLowerCase()) ||
        e.target.getAttribute('role') === 'menuitem'
      )
        return;

      // Executa o handler quando não se trata de inputs
      throttledHandler();
    },
    [throttledHandler]
  );

  useKey(key, keyHandler, {}, [keyHandler]);
};

export const useTimelineKeyboard = () => {
  const setNextDay = useSetTimelineNextDay();
  const setPrevDay = useSetTimelinePrevDay();

  const setNextIndicator = useSetTimelineNextIndicator();
  const setPrevIndicator = useSetTimelinePrevIndicator();

  useTimelineKeyHandler('ArrowLeft', setNextDay);
  useTimelineKeyHandler('ArrowRight', setPrevDay);

  useTimelineKeyHandler('ArrowUp', setPrevIndicator);
  useTimelineKeyHandler('ArrowDown', setNextIndicator);
};
