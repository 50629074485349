import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

export const StatHelper = withStyles((theme) => ({
  root: {
    display: 'inline-block',
    marginRight: '6px',
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '17px',
  },
  // Color variants
  dark: {
    color: theme.palette.neutral.black['48'],
  },
  success: {
    color: theme.palette.timeline.feedback.success,
  },
  danger: {
    color: theme.palette.timeline.feedback.danger,
  },
}))(function StyledStatHelper({ classes, className, children, color }) {
  return <span className={clsx(classes.root, classes[color] || classes.dark, className)}>{children}</span>;
});
StatHelper.defaultProps = {
  color: 'dark'
}

