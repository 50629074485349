import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { Box } from '@material-ui/core';
import { SelectInput } from '../../molecules/Form';
import { useGetLoggedUserCompaniesQuery } from '../../../services/companies/companies.service';
import {IndicatorsTitle} from "../../molecules/indicators/IndicatorsTitle"
import { Chip } from '../../../components/atoms/Chip';

export const LoggedUserCompaniesForm = withStyles(() => ({
  cardClass: {
    padding: "10px"
  }
}))(function LoggedUserCompaniesForm({
  classes,
  companyId,
  variant = 'large'
}) {

  const {data: companiesFetched, isLoading: companiesLoading} = useGetLoggedUserCompaniesQuery();

  const companies = useMemo(() => {
    if (companiesLoading) {
      return;
    }
    if (!companiesFetched) {
      return [{value: "none", label: "Sem instituições", icon: <ApartmentIcon fontSize='small'/>}];
    };

    return companiesFetched.filter(company => {
      return companyId ? company.id === companyId : true
    }).map(val => ({value: val.id, label: val.name.length >= 30 ? val.name.substr(0, 30) + "..." : val.name, icon: <ApartmentIcon fontSize='small'/>, disabled: false}))
  }, [companiesFetched])

  const [selectedCompany, setSelectedCompany] = useState("");


  useEffect(() => {
    if (companies) {
      if (localStorage.getItem("selectedCompanyId")
            && companies.some(company => company.value === localStorage.getItem("selectedCompanyId"))) {
        setSelectedCompany(localStorage.getItem("selectedCompanyId"));
      } else {
        setSelectedCompany(companies[0].value);
        localStorage.setItem("selectedCompanyId", companies[0].value)
        window.location.reload();
      }

    }
  }, [companies])

  const handleSelectChange = useCallback(
    (e) => {

      if (e?.target?.value) {
        setSelectedCompany(e.target.value);
        localStorage.setItem("selectedCompanyId", e.target.value)
        window.location.reload();
      }
    },
    [setSelectedCompany]
  );

  return (
    <Box>
      {
        variant === 'dense' && (companies && companies.length) && (
          <Box display="flex" alignItems="center">
              <Box mr="8px">
                <IndicatorsTitle style={{ fontSize: '13px' }}>
                  Instituição:
                </IndicatorsTitle>
              </Box>
              <Box display="flex" alignItems="center">
                <Chip
                  label={companies[0].label}
                  style={{
                    marginRight: '4px'
                  }}
                />
              </Box>
          </Box>
        )
      }
      {(variant !== 'dense') && !!selectedCompany && !!companies && (<SelectInput
        margin="10px"
        padding="10px 20px"
        bgcolor="white"
        options={companies}
        value={selectedCompany}
        onChange={handleSelectChange}
      />)}
    </Box>
  );
});
