import { Grid } from '@material-ui/core';
import React from 'react';
import { GlobalStat } from './GlobalStat';
import { getStatisticValue, getIndicatorValue } from '../../../../../utils/formatters/formatDashboardIndicators'
import { useSelectedCompetence } from '../../slices';

export const DashboardStatistics = (props) => {
  const { indicators } = props;
  const competence = useSelectedCompetence();

  if(!indicators) {
    return ''
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} lg={3}>
        <GlobalStat
          title="Taxa de infecção"
          value={`${getStatisticValue(indicators, 'taxa_infeccao', competence)}%`}
          helper={`${getIndicatorValue(indicators, 'nns_confirmadas', competence)} / ${getIndicatorValue(indicators, 'taxa_infeccao_total_alta_media_baixa', competence)}`}
          info="Taxa de infecção (%) = (infecções confirmadas / total de pacientes de alta, média e baixa probabilidade) x 100"
        />
      </Grid>
      <Grid item sm={6} lg={3}>
        <GlobalStat
          title="Infecção confirmada em suspeitas com Alta probabilidade"
          value={`${getStatisticValue(indicators, 'infeccao_confirmada_alta_prob', competence)}%`}
          helper={`${getIndicatorValue(indicators, 'infeccao_confirmada_alta_prob_alta', competence)} / ${getIndicatorValue(indicators, 'infeccao_confirmada_alta_prob_total', competence)}`}
          info="Taxa de infecção confirmada em suspeitas de alta probabilidade (%) = (número de infecções de alta probabilidade confirmadas / total de suspeitas de alta probabilidade) x 100"
        />
      </Grid>
      <Grid item sm={6} lg={3}>
        <GlobalStat
          title="infecção confirmada em suspeitas com Média probabilidade"
          value={`${getStatisticValue(indicators, 'infeccao_confirmada_media_prob', competence)}%`}
          helper={`${getIndicatorValue(indicators, 'infeccao_confirmada_media_prob_media', competence)} / ${getIndicatorValue(indicators, 'infeccao_confirmada_media_prob_total', competence)}`}
          info="Taxa de infecção confirmada em suspeitas de média probabilidade (%) = (número de infecções de média probabilidade confirmadas / total de suspeitas de média probabilidade) x 100"
        />
      </Grid>
      <Grid item sm={6} lg={3}>
        <GlobalStat
          title="infecção confirmada em suspeitas com baixa probabilidade"
          value={`${getStatisticValue(indicators, 'infeccao_confirmada_baixa_prob', competence)}%`}
          helper={`${getIndicatorValue(indicators, 'infeccao_confirmada_baixa_prob_baixa', competence)} / ${getIndicatorValue(indicators, 'infeccao_confirmada_baixa_prob_total', competence)}`}
          info="Taxa de infecção confirmada em suspeitas de baixa probabilidade (%) = (número de infecções de baixa probabilidade confirmadas / total de suspeitas de baixa probabilidade) x 100"
        />
      </Grid>
      <Grid item sm={6} lg={3}>
        <GlobalStat
          title="NNS (Number Needed to Screen)"
          value={getStatisticValue(indicators, 'nns', competence)}
          helper={`${getIndicatorValue(indicators, 'nns_alta_media', competence)} / ${getIndicatorValue(indicators, 'nns_confirmadas', competence)}`}
          info="NNS (Number Needed to Screen) = número de suspeitas de alta e média probabilidade / número de infecções confirmadas"
        />
      </Grid>
      <Grid item sm={6} lg={3}>
        <GlobalStat
          title="Taxa de infecções fora da janela"
          value={`${getStatisticValue(indicators, 'infeccoes_fora_janela', competence)}%`}
          helper={`${getIndicatorValue(indicators, 'infeccoes_fora_janela_confirmadas', competence)} / ${getIndicatorValue(indicators, 'infeccoes_fora_janela_total', competence)}`}
          info="Taxa de infecções fora da janela (%) = (número de infecções confirmadas fora da janela / total de infecções confirmadas) x100"
        />
      </Grid>
      <Grid item sm={6} lg={3}>
        <GlobalStat
          title="Tempo de busca ativa"
          value="-"
          info="Tempo de busca ativa = soma do tempo total de busca ativa no mês (em horas:minutos)"
        />
      </Grid>
      <Grid item sm={6} lg={3}>
        <GlobalStat
          title="Tempo de busca ativa por paciente"
          value="-"
          info="Tempo de busca ativa por paciente = tempo total de busca no mês / número de pacientes avaliados no mês (confirmados e não confirmados)"
        />
      </Grid>
    </Grid>
  );
};
