import React from 'react';
import { Button, Box } from '@material-ui/core';
import { FeedbackMessage } from '../../../atoms/FeedbackMessage';
import { Dialog, DialogToolbar } from '../../../molecules/Dialog';

export const AssessmentAnalysisFormConfirmation = (props) => {
  const { open, onCancel, onConfirm, loading } = props;

  return (
    <Dialog
      loading={loading}
      title=""
      open={open}
      onClose={onCancel}
      footer={
        <DialogToolbar borderTop>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outlined" color="primary" onClick={onCancel}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirm}
              disabled={loading}
            >
              Sim, enviar
            </Button>
          </Box>
        </DialogToolbar>
      }
    >
      <FeedbackMessage
        title="Enviar análise?"
        subtitle="Você tem certeza que deseja enviar esta análise?"
        icon={
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="40" cy="40" r="40" fill="#16B8A7" fill-opacity="0.1" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M27.6536 27.0027L29.4736 39.7428L30.5026 40L29.4736 40.2573L27.6536 52.9973L53.6481 40L27.6536 27.0027ZM31.8599 37.5906L31.0132 31.664L47.6853 40L31.0132 48.3361L31.8599 42.4094L41.4975 40L31.8599 37.5906Z"
              fill="#16B8A7"
            />
          </svg>
        }
      />
    </Dialog>
  );
};
