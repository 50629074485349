import React, { useRef, useEffect, useCallback } from 'react';
import { Button } from '@material-ui/core';

import {
  usePostAssessmentAnalysisMutation,
  useGetPatientQuery,
  useGetAssessmentsListQuery,
} from '../../../services';
import { useToggleState } from '../../../utils/useToggleState';

import { AssessmentAnalysisDrawer } from './AssessmentAnalysisDrawer';
import { AssessmentAnalysisFormProvider } from './AssessmentAnalysisForm';

export const AssessmentAnalysis = (props) => {
  const { patientId, companyId } = props;
  const { data: patient, isLoading: isLoadingPatient } = useGetPatientQuery(
    patientId,
    {
      skip: !patientId,
    }
  );

  const { refetch: refetchAssessmentList } = useGetAssessmentsListQuery(
    patientId,
    { skip: true }
  );

  // Drawer
  const {
    open: drawerOpen,
    handleOpen: handleOpenDrawer,
    handleClose: handleCloseDrawer,
  } = useToggleState();

  // Mutation
  const lastMutation = useRef(undefined);
  const [postAssessmentAnalysis, postAssessmentAnalysisState] =
    usePostAssessmentAnalysisMutation();
  const {
    isLoading,
    isSuccess,
    isError,
    data: successResponse,
    error: errorResponse,
    startedTimeStamp: requestTimestamp,
  } = postAssessmentAnalysisState;

  // Debug
  // useEffect(() => {
  //   console.log(postAssessmentAnalysisState);
  // }, [postAssessmentAnalysisState]);

  // Cancelamento da requisição
  const handleCancel = useCallback(() => {
    if (lastMutation.current) lastMutation.current.abort();
  }, [lastMutation]);

  // Finaliza e reseta os estados de sucesso/resposta e erro
  const handleResetMutation = useCallback(() => {
    if (lastMutation.current) lastMutation.current.unsubscribe();
  }, [lastMutation]);

  // Reseta estado da mutation quando fecha a modal. (o estado do formulário persiste)
  // useEffect(() => {
  //   if (!drawerOpen) handleResetMutation();
  // }, [drawerOpen, handleResetMutation]);

  // Envio da requisição
  const handleSubmit = useCallback(
    async (values) => {
      // Executa a mutation
      const mutation = postAssessmentAnalysis({ patient, ...values });

      // Guardamos a mutation para chamá-la depois caso o usuário cancele ou finalize a submissão
      lastMutation.current = mutation;

      const result = await mutation;
      if (!result.error) {
        handleCloseDrawer();
        refetchAssessmentList();
      }
    },
    [
      postAssessmentAnalysis,
      patient,
      lastMutation,
      refetchAssessmentList,
      handleCloseDrawer,
    ]
  );

  return isLoadingPatient || !patient ? null : (
    <AssessmentAnalysisFormProvider
      companyId={companyId}
      patientId={patientId}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onFinish={handleResetMutation}
      requestTimestamp={requestTimestamp}
      isLoading={isLoading}
      isSuccess={isSuccess}
      successResponse={successResponse}
      errorResponse={errorResponse}
      isError={isError}
    >
      <Button
        color="primary"
        variant="contained"
        fullWidth
        onClick={handleOpenDrawer}
      >
        Avaliar
      </Button>
      <AssessmentAnalysisDrawer open={drawerOpen} onClose={handleCloseDrawer} />
    </AssessmentAnalysisFormProvider>
  );
};
