/**
 * PESO PARA ORDENAÇÃO DOS PROFISSIONAIS
 * NA LISTAGEM DA EVOLUCAO
 */
export const professionalOrderWeightENUM = {
  '[MEDICO(A)]': 12,
  '[ENFERMEIRO(A)]': 10,
  '[FISIOTERAPEUTA]': 8,
  '[TECNICO(A) EM ENFERMAGEM]': 6,
  '[FARMACEUTICO(A)]': 4,
  '[NUTRICIONISTA]': 2,
  '[DEMAIS]': 1,
};
