import React, { useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { bool, func } from 'prop-types';
import { DialogSubtitle } from './style';
import {keycloakConnect} from "../../../keycloak";

const DialogLogout = ({ open, setOpen }) => {
  const dialog = React.createRef();
  const [btnLabel, setBtnLabel] = useState('Confirmar');
  const [disabledBtn, setDisabledBtn] = useState(false);

  const handleCloseNone = (_) => setOpen(false);

  const handleClose = (_) => {
    setOpen(false);
    setDisabledBtn(false);
    setBtnLabel('Confirmar');
  };

  const handleConfirm = () => {
    setBtnLabel('Saindo');
    setDisabledBtn(true);
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    keycloakConnect.logout();
  }

  return (
    <Dialog onClose={handleClose} open={open} ref={dialog}>
      <DialogTitle onClose={handleClose}>
        Deseja sair?
        <IconButton
          className="closeButton"
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogSubtitle>Você tem certeza que deseja sair?</DialogSubtitle>
      </DialogContent>
      <DialogActions className="spaceBetween">
        <Button
          color="primary"
          onClick={handleCloseNone}
          className="buttonDialog buttonDialog-clean"
          disableRipple={true}
        >
          Cancelar
        </Button>
        <Button
          className="buttonDialog"
          disabled={disabledBtn}
          autoFocus
          onClick={handleConfirm}
        >
          {btnLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogLogout.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};
export default DialogLogout;
