import React from 'react';
import {
  withStyles,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  LinearProgress,
} from '@material-ui/core';

export const DialogPanelHeader = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiDialogTitle);

export const DialogPanelContent = withStyles((theme) => ({
  root: {
    padding: 0,
    width: '100%',
    minWidth: 'initial',
  },
}))(MuiDialogContent);

export const DialogPanelFooter = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiDialogActions);

export const DialogPanel = ({ loading, header, footer, children }) => {
  return (
    <>
      {loading && <LinearProgress />}
      {header && <DialogPanelHeader>{header}</DialogPanelHeader>}
      <DialogPanelContent>{children}</DialogPanelContent>
      {footer && <DialogPanelFooter>{footer}</DialogPanelFooter>}
    </>
  );
};
