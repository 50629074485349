import ChevronRight from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/styles';

export const ExamChevron = withStyles((theme) => ({
  root: {
    marginTop: '4px',
    padding: 0,
    color: theme.palette.neutral.black['72'],
    fontSize: '26px',
  },
}))(ChevronRight);
