import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setInfections } from '../slice';

export const useSetPatientsInfections = () => {
  const dispatch = useDispatch();

  const handleSetPatientsInfections = useCallback(
    (infections) => dispatch(setInfections(infections)),
    [dispatch]
  );

  return handleSetPatientsInfections;
};
