import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';

import { formatSuspicionLabel } from '../../../utils/formatters';
import { useSuspicionInfections } from '../../../utils/hooks';

import { InfectionChip } from '../../../components/atoms/Chip';
import { Card } from '../../../components/atoms/Card';

import { SuspicionCardTitle } from './styles';

export const SuspicionCard = (props) => {
  const { suspicion, ...cardProps } = props;
  const title = useMemo(() => formatSuspicionLabel(suspicion), [suspicion]);
  const infections = useSuspicionInfections(suspicion);

  return (
    <Card {...cardProps}>
      <SuspicionCardTitle>{title}</SuspicionCardTitle>
      <InfectionChip
        key="geral"
        infection="geral"
        probability={suspicion.max_prob}
        size="large"
        style={{
          marginBottom: '7px',
          marginRight: '7px',
        }}
      />
      {infections.map((infection) => (
        <InfectionChip
          key={infection.name}
          infection={infection.name}
          probability={infection.probability}
          size="large"
          style={{
            marginBottom: '7px',
            marginRight: '7px',
          }}
        />
      ))}
    </Card>
  );
};
