import React from 'react';
import { Box, withStyles } from '@material-ui/core';

export const DrawerToolbarWrapper = withStyles((theme) => ({
  root: {
    borderBottom: ({ borderBottom }) =>
      borderBottom
        ? `1px solid ${theme.palette.neutral.black['16']}`
        : undefined,
    borderTop: ({ borderTop }) =>
      borderTop ? `1px solid ${theme.palette.neutral.black['16']}` : undefined,
  },
}))(({ borderBottom, borderTop, ...rest }) => <Box {...rest} />);

// Caixa com espaçamentos e borda inferior
export const DrawerToolbar = (props) => {
  const { borderTop, borderBottom, wide, small, children, ...rest } = props;
  const paddingSize = small ? '16px' : '20px';

  return (
    <Box width="100%">
      <DrawerToolbarWrapper
        borderTop={borderTop}
        borderBottom={borderBottom}
        pt="16px"
        pb="16px"
        pl={wide ? paddingSize : 0}
        pr={wide ? paddingSize : 0}
        ml={!wide ? paddingSize : 0}
        mr={!wide ? paddingSize : 0}
        {...rest}
      >
        {children}
      </DrawerToolbarWrapper>
    </Box>
  );
};
DrawerToolbar.defaultProps = {
  wide: false, // Se a borda cobre toda a toolbar
  small: false, // Variante com espaçamentos do eixo y menores
};
