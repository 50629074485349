import styled from "styled-components";

export const DateRangeInput = styled.div`
  background-color: #F5F5F5;
  border-radius: 6px;
  margin-top: -5px;
  .MuiInputBase-root {
    width: 100%;
    font-size: 12px;
  }

  .border {
    position: relative;
    &.MuiFormControl-root {
      &:after {
        content: '';
        width: 1px;
        height: 25px;
        position: absolute;
        right: 0;
        top: 8px;
        background-color: rgba(143, 149, 163, 0.32);
      }
    }
  }
  .MuiFormControl-root {
    padding: 7px 0 7px 15px;
    margin: 0;
    width: 100%;
  }
  .MuiInput-underline:after, .MuiInput-underline:before {
    content: none;
  }
`;
