import { actionTypes } from '../actions/PatientActions';

const initialState = {
  patientVr: [],
}

export const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PATIENT_VR:
      return {
        ...state,
        patientVr: action.patientVr
      };

      default:
        return state;
  }
}
