import { actionTypes } from '../actions/AssessmentsHistoryActions';
import Dataset from '../services/Dataset';

const initialState = {
  loading: false,
  list: []
};

export const assessmentsHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ASSESSMENT_HISTORY_LOADING:
      return {
        loading: true,
        list: [
          ...state.list
        ]
      };
    case actionTypes.GET_ASSESSMENTS_HISTORY:
      const newState = {
        loading: false,
        list: []
      };
      const {
        assessmentsHistory,
        assessedInfections,
        filterParams: {
          positiveAssessments,
          negativeAssessments,
        },
      } = action;
      const {
        terms: {
          buckets: assessedArray = []
        }
      } = assessedInfections.aggregations || {terms: {buckets: []}}

      if (action.assessmentsHistory.length && assessedArray.length) {
        newState.list = [
          ...Dataset.patientsWithAssessments(assessmentsHistory, assessedArray, {
            positiva: positiveAssessments,
            negativa: negativeAssessments,
          })
        ];
      }

      return newState;
    case actionTypes.GET_ASSESSMENTS_HISTORY_ERROR:
      return {
        loading: false,
        list: [
          ...state.list
        ]
      };
    default:
      return state;
  }
};
