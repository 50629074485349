import React, { useMemo } from 'react';
import { GenericDrawer } from './GenericDrawer';

export const defaultBottomSheetHeaderProps = {
  small: true,
  wide: false,
  closePosition: 'right',
  closeSmall: true,
};

export const BottomSheetDrawer = (props) => {
  const { header, anchor, PaperProps, ...rest } = props;
  const headerProps = useMemo(
    () => ({
      ...defaultBottomSheetHeaderProps,
      ...header,
    }),
    [header]
  );

  const drawerPaperProps = useMemo(
    () => ({
      style: {
        ...PaperProps?.style,
        height: 'calc(100% - 72px)',
        [anchor === 'right' ? 'left' : 'right']: 'auto',
      },
      borderRadius: '8px 8px 0 0',
      ...PaperProps,
    }),
    [PaperProps, anchor]
  );

  return (
    <GenericDrawer
      header={headerProps}
      anchor="bottom"
      PaperProps={drawerPaperProps}
      {...rest}
    />
  );
};
BottomSheetDrawer.defaultProps = {
  anchor: 'right', // Apenas 'left' e 'right', pois o componente já fica fixa em 'bottom' no eixo y
};
