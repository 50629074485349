import React from 'react';
import { ButtonBase, withStyles } from '@material-ui/core';
import InsertChartOutlined from '@material-ui/icons/InsertChartOutlined';

export const ExtractedCriteriaItemButton = withStyles((theme) => ({
  root: {
    width: '100%',
    padding: '12px 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    justifyContent: 'flex-start',
  },
  bullet: {
    flex: '0 0 auto',
    width: '8px',
    height: '8px',
    borderRadius: '100%',
    marginRight: '8px',
    backgroundColor: theme.palette.timeline.probability[500],
  },
  label: {
    flex: '1 0 auto',
    fontSize: '13px',
    fontFamily: 'DM Mono',
    lineHeight: '15px',
    color: theme.palette.neutral.black[100],
    textAlign: 'left',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    color: theme.palette.timeline.probability[500],
  },
}))(function StyledExtractedCriteriaItemButton(props) {
  const { children, classes, ...buttonProps } = props;
  const { bullet, labelContainer, label, icon, ...buttonClasses } = classes;

  return (
    <ButtonBase {...buttonProps} classes={buttonClasses}>
      <div className={labelContainer}>
        <div className={bullet} />
        <div className={label}>{children}</div>
      </div>
      <InsertChartOutlined className={icon} />
    </ButtonBase>
  );
});
