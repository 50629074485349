import { createSelector } from '@reduxjs/toolkit';
import { selectHistorySlice } from './selectHistorySlice';
import { useSelector } from 'react-redux';
import { historyInitialState } from '../slice';
import { isEqual } from 'lodash';

export const selectHistoryFiltersCount = createSelector(
  selectHistorySlice,
  (historyState) =>
    [!isEqual(historyState.infections, historyInitialState.infections)].filter(
      Boolean
    ).length
);
export const useHistoryFiltersCount = () =>
  useSelector(selectHistoryFiltersCount);
