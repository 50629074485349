import React from 'react';
import { Badge, IconButton, withStyles, alpha } from '@material-ui/core';
import { useCommentsContext } from './CommentsContext';

const CommentsButtonWrapper = withStyles((theme) => ({
  root: {
    background: alpha(theme.palette.primary.main, .1),
    width: '40px',
    height: '40px',
    borderRadius: '8px',
  },
}))(IconButton);

const CommentsBadge = withStyles((theme) => ({
  badge: {
    background: theme.palette.timeline.probability['500'],
    border: '2px solid white',
    minWidth: 'initial',
    width: '12px',
    height: '12px',
    padding: 0,
  },
}))(Badge);

export const CommentsIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.00001 14.5362L8.05787 12H13.3333C14.0697 12 14.6667 11.4031 14.6667 10.6667V2.66668C14.6667 1.9303 14.0697 1.33334 13.3333 1.33334H2.66668C1.9303 1.33334 1.33334 1.9303 1.33334 2.66668V10.6667C1.33334 11.4031 1.9303 12 2.66668 12H4.00001V14.5362ZM7.67548 10.6667L5.33334 12.1305V10.6667H2.66668V2.66668H13.3333V10.6667H7.67548ZM4.66668 8.66668V7.33334H9.33334V8.66668H4.66668ZM4.66668 4.66668V6.00001H10.6667V4.66668H4.66668Z"
      fill="#16B8A7"
    />
  </svg>
);

export const CommentsButton = () => {
  const { handleOpen: openCommentsDrawer, comments } = useCommentsContext();

  return (
    <CommentsButtonWrapper onClick={openCommentsDrawer}>
      <CommentsBadge badgeContent={comments.length ? ' ' : undefined}>
        <CommentsIcon />
      </CommentsBadge>
    </CommentsButtonWrapper>
  );
};
