import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Card } from '../../../../components/atoms/Card';

export const DashboardCard = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(function StyledDashboardCard({
  classes,
  className,
  children,
  ...cardProps
}) {
  return (
    <Card className={clsx(classes.root, className)} {...cardProps}>
      {children}
    </Card>
  );
});
