import React from 'react';
import { Divider } from '@material-ui/core';
import { Card } from '../../../../../components/atoms/Card/Card';
import { MedicineRanges } from './MedicineRanges';

export const MedicinesCard = ({ medicines }) => {
  return (
    <Card color="gray" shadow={false} style={{ padding: '12px 8px'}}>
      {medicines.map(([medicine, range], medicineIndex) => (
        <React.Fragment key={medicine}>
          {medicineIndex !== 0 && <Divider style={{ margin: '12px 0' }} />}
          <MedicineRanges medicine={medicine} range={range} />
        </React.Fragment>
      ))}
    </Card>
  );
};
