import { createApi } from '@reduxjs/toolkit/query/react';
import moment from 'moment';
import { otherMicroorganism } from '../../constants/microorganisms';
import { baseQueryWithReauth } from '../baseQuery';
import { transformResponseHits } from '../common/utils/transformResponse';
import {
  assessedInfectionsRequest,
  listAssessmentsBody,
} from './assessments.body';

export const assessmentsApi = createApi({
  reducerPath: 'assessmentsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Assessments'],
  endpoints: (build) => ({
    getAssessmentsEvaluations: build.query({
      query: ({ infectionType, location, evaluator, ...filters }) => {
        const infectionTypes = infectionType.reduce((acc, item) => {
          return acc.concat(`&infectionType=${item}`);
        }, '');

        const infectionPlace = location.reduce((acc, item) => {
          return acc.concat(`&place=${item}`);
        }, '');

        const infectionMadeBy = evaluator.reduce((acc, item) => {
          return acc.concat(`&madeBy=${item}`);
        }, '');

        return {
          url: `/elk/avaliacoes?${infectionTypes}${infectionPlace}${infectionMadeBy}`,
          params: { ...filters },
          method: 'GET',
        };
      },
      providesTags: ['Assessments'],
    }),
    getAssessmentsList: build.query({
      query: (patientId) => ({
        url: `/elk/avaliacoes`,
        method: 'GET',
        params: {
          patient_id: patientId,
          itensPerPage: 1000,
          page: 1,
        },
      }),
      providesTags: ['Assessments'],
    }),
    getAssessmentsCount: build.query({
      query: (params) => ({
        url: `/elk/avaliacoes`,
        method: 'GET',
        params: {
          patient_id: '',
          itensPerPage: 1,
          page: 1,
          ...params,
        },
        transformResponse: (r) => r._metadata.totalCount,
      }),
    }),
    getAssessmentHistory: build.query({
      query: (params) => ({
        url: `/elk/`,
        method: 'POST',
        body: {
          path: `avaliacoes/_search?filter_path=aggregations.terms.buckets.key,
          aggregations.terms.buckets.pacientes_hits.hits.hits._source&scroll=1m`,
          body: assessedInfectionsRequest(params),
        },
      }),
    }),
    deleteAssessment: build.mutation({
      query(assessmentID) {
        return {
          url: `/elk/avaliacao/`,
          method: 'DELETE',
          body: {
            id: assessmentID,
          },
          responseHandler: (response) => response.text(),
        };
      },
      invalidatesTags: ['Assessments'],
      transformResponse: (response) => ({ message: response }),
    }),
    postAssessmentAnalysis: build.mutation({
      query(submitData) {
        const body = {
          suspeita_payload: submitData.selectedSuspicion,
          suspeita_id: submitData.suspicionId,
          tipo_infeccao: submitData.assessmentInfection,
          potencial_contaminacao: submitData.contaminationPotential,
          classificacao_infeccao: submitData.infectionClassification,
          outra_infec: submitData.otherInfection,
          paciente_id: submitData.patient.id,
          notificado: moment(submitData.patient.notificado), // * TODO
          dt_infeccao: moment(submitData.suspicionDateInfecction),
          local_infeccao: submitData.infectionLocation,
          avaliacao_comentario: submitData.assessmentComment,
          avaliacao_responsavel: localStorage.getItem('username') ?? '',
          obito_relacionado_a_infeccao: submitData.assessmentRelatedDeath,
          microrganismos: Array.isArray(submitData.microorganisms)
            ? submitData.microorganisms.map((m) => ({
                id: m.microorganism,
                resistente: m.resistant,
              }))
            : undefined,
          avaliacao_data: moment(),
          avaliacao_status: submitData.assessmentStatus,
          relacionado_cateter_sonda: submitData.relatedToCatheter,
          relacionado: submitData.relatedToCVC || submitData.relatedToSVD,
          comunitaria: submitData.communityInfection,
          aval_dt_infec: moment(submitData.assessmentDateInfection),
        };
        // console.log('Service post', submitData, body);

        return {
          url: '/elk/avaliar/',
          method: 'POST',
          body,
          responseHandler: async (response) => {
            // As vezes o serividor retorna um erro 502 ou 504 com o corpo de texto e não json
            const clone = response.clone();
            try {
              return await response.json();
            } catch (e) {
              return await clone.text();
            }
          },
        };
      },
      extraOptions: { maxRetries: 1 },
      invalidatesTags: ['Assessments'],
      transformResponse: (response) => ({ message: response }),
    }),
    updateAssessmentAnalysis: build.mutation({
      query(submitData) {
        const body = {
          suspeita_payload: submitData.selectedSuspicion,
          suspeita_id: submitData.suspicionId,
          tipo_infeccao: submitData.assessmentInfection,
          potencial_contaminacao: submitData.contaminationPotential,
          classificacao_infeccao: submitData.infectionClassification,
          outra_infec: submitData.otherInfection,
          paciente_id: submitData.patient.id,
          notificado: moment(submitData.patient.notificado), // * TODO
          dt_infeccao: moment(submitData.suspicionDateInfecction),
          local_infeccao: submitData.infectionLocation,
          avaliacao_comentario: submitData.assessmentComment,
          avaliacao_responsavel: localStorage.getItem('username') ?? '',
          obito_relacionado_a_infeccao: submitData.assessmentRelatedDeath,
          microrganismos: Array.isArray(submitData.microorganisms)
            ? submitData.microorganisms.map((m) => ({
                id: m.microorganism,
                resistente: m.resistant,
              }))
            : undefined,
          avaliacao_data: moment(),
          avaliacao_status: submitData.assessmentStatus,
          relacionado_cateter_sonda: submitData.relatedToCatheter,
          relacionado: submitData.relatedToCVC || submitData.relatedToSVD,
          comunitaria: submitData.communityInfection,
          aval_dt_infec: moment(submitData.assessmentDateInfection),
        };

        return {
          url: `/elk/avaliacoes/${submitData.assessmentId}`,
          method: 'PUT',
          body,
          responseHandler: async (response) => {
            // As vezes o serividor retorna um erro 502 ou 504 com o corpo de texto e não json
            const clone = response.clone();
            try {
              return await response.json();
            } catch (e) {
              return await clone.text();
            }
          },
        };
      },
      extraOptions: { maxRetries: 1 },
      invalidatesTags: ['Assessments'],
      transformResponse: (response) => ({ message: response }),
    }),
  }),
});

export const {
  useGetAssessmentsEvaluationsQuery,
  useGetAssessmentsCountQuery,
  useGetAssessmentsListQuery,
  useGetAssessmentHistoryQuery,
  useDeleteAssessmentMutation,
  usePostAssessmentAnalysisMutation,
  useUpdateAssessmentAnalysisMutation,
} = assessmentsApi;
