import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setIndicatorPopover } from '../slice';

export const useSetIndicatorPopover = () => {
  const dispatch = useDispatch();

  const handleSetIndicatorPopover = useCallback(
    (indicator) => dispatch(setIndicatorPopover(indicator)),
    [dispatch]
  );

  return handleSetIndicatorPopover;
};
