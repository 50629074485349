import { useCallback } from 'react';
import {
  useTimelineCursor,
  useTimelineNextDay,
  useTimelinePrevDay,
} from '../selectors';
import { useSetTimelineCursor } from './useSetTimelineCursor';

export const useSetTimelineCursorDate = () => {
  const setCursor = useSetTimelineCursor();
  const { indicator } = useTimelineCursor();

  const setCursorDate = useCallback(
    (date) => {
      return setCursor({ indicator, date });
    },
    [setCursor, indicator]
  );

  return setCursorDate;
};

export const useSetTimelineNextDay = () => {
  const setCursorDate = useSetTimelineCursorDate();
  const nextDay = useTimelineNextDay();

  const setNextDay = useCallback(() => {
    if (!nextDay) return;
    setCursorDate(nextDay);
  }, [setCursorDate, nextDay]);

  return setNextDay;
};

export const useSetTimelinePrevDay = () => {
  const setCursorDate = useSetTimelineCursorDate();
  const prevDay = useTimelinePrevDay();

  const setPrevDay = useCallback(() => {
    if (!prevDay) return;
    setCursorDate(prevDay);
  }, [setCursorDate, prevDay]);

  return setPrevDay;
};
