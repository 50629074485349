import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';

const SectionTitle = withStyles((theme) => ({
  root: {
    color: theme.palette.neutral.black['72'],
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '11px',
    lineHeight: '15px',
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
  },
}))(Typography);

export const TimelineSidebarSection = ({ title, badge, loading, children }) => {
  return (
    <Box mb="12px">
      <Box mb="6px">
        <SectionTitle fontFamily="secondary">
          {title}
          {typeof badge === 'number' && `(${badge})`}
        </SectionTitle>
      </Box>
      {loading ? <Typography variant='body2'>Carregando...</Typography> : <>{children}</>}
    </Box>
  );
};
