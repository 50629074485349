import React, { useEffect, useMemo } from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { formatIndicatorLabel } from '../../../utils/formatters';
import {
  CHART_INDICATORS,
  INDICATOR_LEGEND_WIDTH,
  INDICATOR_LEGEND_HEIGHT,
} from './constants';
import { useTimelineChartContext } from './TimelineChartProvider';
import { indicatorsText } from '../../../constants/indicatorsText';
import { withStyles } from '@material-ui/styles';

export const IndicatorLegendItem = ({ indicator, index }) => {
  const label = useMemo(() => formatIndicatorLabel(indicator), [indicator]);
  const explanation = useMemo(() => indicatorsText[indicator], [indicator]);

  return (
    <Box
      paddingLeft="12px"
      display="flex"
      alignItems="center"
      bgcolor={index % 2 === 0 ? '#f7f7f7' : '#f5f5f5'}
      borderRadius="4px 0 0 4px"
      color="#3E4247B8"
      width={INDICATOR_LEGEND_WIDTH}
      height={INDICATOR_LEGEND_HEIGHT}
      fontFamily="DM Mono"
      fontSize="12px"
    >
      <Box flexShrink={0} mr="2px">
        {label}
      </Box>
      <StyledTooltip title={explanation} placement="right">
        <IconButton
          size="small"
          onClick={() => console.log('indicator info', indicator)}
        >
          <InfoOutlinedIcon style={{ fontSize: 16, color: '#DADADA' }} />
        </IconButton>
      </StyledTooltip>
    </Box>
  );
};

export const IndicatorsLegends = () => {
  const { yScale } = useTimelineChartContext();

  const indicatorsLegends = useMemo(() => {
    return (
      <>
        {CHART_INDICATORS.map((indicator, i) => {
          const y = yScale(indicator);

          return (
            <foreignObject
              key={indicator}
              width={INDICATOR_LEGEND_WIDTH}
              height={INDICATOR_LEGEND_HEIGHT}
              x={0}
              y={y}
            >
              <IndicatorLegendItem indicator={indicator} index={i} />
            </foreignObject>
          );
        })}
      </>
    );
  }, [yScale]);

  return indicatorsLegends;
};

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: `#000`,
    fontFamily: theme.MuiTypography.family.secondary,
    fontSize: '12px',
  },
}))(Tooltip);
