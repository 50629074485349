import React from 'react';
import { Button } from './styles';
import { Box } from '@material-ui/core';

export const DataTableCreatePatientBtn = ({ onClick: handleOnClick }) => (
  <Button
    onClick={handleOnClick}
    variant="contained"
    color="primary"
    style={{
      maxWidth: 200
    }}
  >
    Novo paciente
  </Button>
);
