import { Box, withStyles } from '@material-ui/core';

export const AssessmentCardText = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '15px',
    letterSpacing: '0.01em',
    color: theme.palette.neutral.black['72'],
  },
}))(Box);

export const AssessmentCardTitle = withStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    color: ({ variant }) => {
      if( variant === 'success') return theme.palette.timeline.feedback.success;// Verde escuro
      if( variant === 'danger') return theme.palette.timeline.feedback.danger;
      return theme.palette.neutral.black['100']
    }
  },
}))(AssessmentCardText);

export const AssessmentCardSubtitle = withStyles((theme) => ({
  root: {
    textTransform: 'uppercase'
  },
}))(AssessmentCardText);

export const AssessmentCardContent = withStyles((theme) => ({
  root: {
    fontSize: '12px',
    lineHeight: '18px',
  },
}))(AssessmentCardText);

export const AssessmentCardAuthor = withStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    color: theme.palette.neutral.black['100'],
  },
}))(AssessmentCardContent);