import styled from "styled-components";

export const TimelineChart = styled.div`
  flex-basis: 100%;
  border: 1px solid #e4e6e9;
  border-right: none;
  border-bottom: none;
  height: 100%;

  .AxisLeft, .AxisBottom {
    &__label {
      font-family: 'DM Mono', 'monospace';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: #383A3D;
      //
    }
    &__line{
      stroke: #e4e6e9;
    }
    // SVGs gerados pelo D3
    .domain {
      stroke: #e4e6e9;
    }
  }

  .AxisBottom {
    &__label {
      font-size: 12px;
      color: #383A3D;
    }
    &__line {
      stroke: rgba(143, 149, 163, 0.16)
    }
  }

  .TimelineSVG {
    cursor: grab;
  }

  .dragstart {
    cursor: grabbing;
  }
  .polygon {
    cursor: pointer;
    stroke-linejoin: round;
    stroke-linecap:round;
    stroke-width: 3;
  }
  .polygon-positive {
    cursor: pointer;
    stroke-linejoin: bevel;
    stroke-linecap:bevel;
    stroke-width: 2;
  }
  .node {
    cursor: pointer;
    stroke-width: 2;
    stroke: white;
  }

  .node-text {
    font-size: 10px;
    line-height: 13px;
    color: #383A3D;
  }
  .node-text-error {
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    fill: red;
    cursor: pointer;
  }
  .node-line {
  cursor: pointer;
  }
`

export const TimelineTooltip = styled.div
`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  height:270px;
`
export const TimelineContainer  = styled.div
`
  position: relative;
`;
export const TimelineLoader = styled.div
`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: white;
  .MuiCircularProgress-root {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -18px 0 0 -18px;
  }
`

export const Title = styled.h2`
    @media (max-width: 960px) {
        margin-bottom: 20px;
    }
`

