import pendingAssessmentsRequestBody from "./query";
import {sendRequest} from "../api";
import {keycloakConnect} from "../../../keycloak";

const ENDPOINT = `filter_path=responses.hits.hits._source,responses.hits.total,
responses.aggregations.terms.buckets,responses.aggregations.my_buckets.buckets.key,
responses.aggregations.my_buckets.buckets.by_index.buckets`;

/**
 * Returns the list of patients with pending assessment on a given date
 * with probability level filter
 *
 * @returns {Promise<void>}
 * @param filterParams
 */
export async function getPatientsWithPendingAssessments(filterParams) {
  const token = keycloakConnect.token
  return await sendRequest({
    endpoint: ENDPOINT,
    data: pendingAssessmentsRequestBody({...filterParams}),
    headers: {
      'Content-Type': 'application/x-ndjson',
      'Authorization': `Bearer ${token}`
    }
  })
}


