import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { patientApi } from '../../../../services';
import { selectTimelinePatientId } from './selectTimelinePatientId';

// Selector que mescla o patientId da timeline, busca query de patient data com este id e retorna os detalhes do paciente
export const selectTimelinePatientDetailsSelector = createSelector(
  selectTimelinePatientId,
  (patientId) => patientApi.endpoints.getPatient.select(patientId)
);
export const selectTimelinePatientDetailsData = createSelector(
  [(state) => state, selectTimelinePatientDetailsSelector],
  (state, selector) => selector(state)?.data
);
export const selectTimelinePatientDetails = createSelector(
  selectTimelinePatientDetailsData,
  (data) => data
);
export const useTimelinePatientDetails = () => {
  return useSelector(selectTimelinePatientDetails);
};
