import { Box } from '@material-ui/core';
import React from 'react';
import { InfectionChip } from '../../atoms/Chip';
import { formatToPercentageString, getPatientInfections } from './utils';

export const DataTableSuspicions = ({ patient }) => {
  if (!patient) return '--';
  const maxProb = patient.maxProb || patient.max_prob;
  const infections = getPatientInfections(patient);

  const hasInfections = infections.length;

  if (!hasInfections) {
    const percentual = formatToPercentageString(maxProb, 2);
    return (
      <Box my={0.5} lineHeight="1">
        <InfectionChip
          probability={maxProb}
          label={`OUTRA: ${percentual}`}
          style={{ margin: '2px 0 2px 2px' }}
        />
      </Box>
    );
  }

  /* Renderiza chips */
  return infections.map(({ label, value, percentual }, index) => {
    return (
      <InfectionChip
        key={`${label}-${index}`}
        probability={value}
        label={`${label}: ${percentual}`}
        style={{ margin: '2px 0 2px 2px' }}
      />
    );
  });
};
