import React from 'react';
import { Box, LinearProgress } from '@material-ui/core';

export const TimelineSidebarWrapper = (props) => {
  const { heading, loading, children} = props;

  return (
    <Box
      borderRadius="8px 8px 0 0"
      height="100%"
      width="100%"
      position='absolute'
      top='0'
      left='0'
      right='0'
      bottom='0'
      bgcolor="grey.50"
      overflow="hidden"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        position="relative"
      >
        <Box position="absolute" top="0" left="0" right="0" width="100%">
          {loading && <LinearProgress />}
        </Box>
        <Box flex="0 0 auto" padding="16px 16px 12px">
          {heading}
        </Box>
        <Box flex="1 1 100%" overflow="auto" padding="0 16px 16px">
          {children}
        </Box>
      </Box>
    </Box>
  );
};
