import { probabilityLevelsEnum } from '../../../../../constants/probabilities';

export const probabilityLevelThemeEnum = {
  [probabilityLevelsEnum.ALTA]: 'rgb(254, 60, 76)',
  [probabilityLevelsEnum.MEDIA]: 'rgb(255, 179, 0)',
  [probabilityLevelsEnum.BAIXA]: 'transparent',
};

/**
 * Retorna a cor de um tema com base em um nivel de probabilidade
 *
 * @param {{'ALTA' | 'MEDIA' | 'BAIXA'}} level nivel de probavilidade
 * @returns Retorna uma cor, de acordo com o `level` inserido
 *
 * @example
 *
 * import { probabilityLevelsEnum } from 'constants/probabilities';
 *
 * formatProbabilityLevel(probabilityLevelsEnum.ALTA) // rgb(254, 60, 76)
 * formatProbabilityLevel(probabilityLevelsEnum.MEDIA) // rgb(255, 179, 0)
 * formatProbabilityLevel(probabilityLevelsEnum.BAIXA) // transparent
 */
export function mapProbabilityLevelToTheme(level) {
  const theme = probabilityLevelThemeEnum[level] || 'transparent';
  return theme;
}
