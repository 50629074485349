import React from 'react';
import {
  Badge as MuiBadge,
  Box,
  Button as MuiButton,
  Grid,
  withStyles,
} from '@material-ui/core';

import { DataTableSearch } from './DataTableSearch';

export const DataTableHeader = ({ toggleGroup, action, onSearch }) => {
  return (
    <Box display="flex" alignItems="center" p={1.5}>
      {toggleGroup && <Box mr={1.5}>{toggleGroup}</Box>}
      <Box>
        <DataTableSearch onSearch={onSearch} />
      </Box>
      <Box ml={1.5} width="100%">{action}</Box>
    </Box>
  );
};
