import React from 'react';
import { Fade } from '@material-ui/core';
import { TimelineChartProvider } from './TimelineChartProvider';
import { TimelineChartLayout } from './TimelineChartLayout';
import { TimelineGrid } from './TimelineGrid';
import { TimelineEntries } from './TimelineEntries';

export const TimelineChart = React.memo(({ loading }) => (
  <TimelineChartProvider>
    <TimelineChartLayout>
      <TimelineGrid loading={loading} />
      <Fade in={!loading}>
        <g>
          <TimelineEntries />
        </g>
      </Fade>
    </TimelineChartLayout>
  </TimelineChartProvider>
));
