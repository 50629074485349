import React, { useEffect, useRef, useState } from 'react';
import { Content, IconMenu, MyBadge, Item, Logo } from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import { withTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import PlatformVersion from '../PlatformVersion';
import Grid from '@material-ui/core/Grid';
import DialogLogout from '../../organisms/DialogLogout';
import { DropMenu, DropMenuItem } from '../DropMenu';
import { HIDE_FROM } from '../../../pages/hideAnonymousEvaluation';

const MoreVerticalIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5ZM12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14ZM12 21C10.8954 21 10 20.1046 10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21Z"
      fill="white"
    />
  </svg>
);

const StatusIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.66675C5.88514 1.66675 2.5 2.93617 2.5 5.00008V10.0001V15.0001C2.5 17.064 5.88514 18.3334 10 18.3334C14.1149 18.3334 17.5 17.064 17.5 15.0001V10.0001V5.00008C17.5 2.93617 14.1149 1.66675 10 1.66675ZM15.8333 10.0001V7.18409C14.4538 7.92099 12.3454 8.33341 10 8.33341C7.65456 8.33341 5.5462 7.92099 4.16667 7.18409V10.0001C4.16667 10.6976 6.75107 11.6667 10 11.6667C13.2489 11.6667 15.8333 10.6976 15.8333 10.0001ZM4.16667 12.1841C5.5462 12.921 7.65456 13.3334 10 13.3334C12.3454 13.3334 14.4538 12.921 15.8333 12.1841V15.0001C15.8333 15.6976 13.2489 16.6667 10 16.6667C6.75107 16.6667 4.16667 15.6976 4.16667 15.0001V12.1841ZM15.8333 5.00112L15.8333 5.00008C15.8333 4.30256 13.2489 3.33341 10 3.33341C6.75107 3.33341 4.16667 4.30256 4.16667 5.00008L4.16667 5.00034C4.16731 5.69784 6.75147 6.66675 10 6.66675C13.2473 6.66675 15.8308 5.69856 15.8333 5.00112Z"
      fill="#3E4247"
    />
  </svg>
);

const ChangeLogIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66666 4.99992C1.66666 5.92039 2.41285 6.66659 3.33332 6.66659C4.2538 6.66659 4.99999 5.92039 4.99999 4.99992C4.99999 4.07944 4.2538 3.33325 3.33332 3.33325C2.41285 3.33325 1.66666 4.07944 1.66666 4.99992ZM18.3333 4.16658V5.83325H6.66666V4.16658H18.3333ZM18.3333 10.8333V9.16659H6.66666V10.8333H18.3333ZM6.66666 15.8333H18.3333V14.1666H6.66666V15.8333ZM1.66666 14.9999C1.66666 15.9204 2.41285 16.6666 3.33332 16.6666C4.2538 16.6666 4.99999 15.9204 4.99999 14.9999C4.99999 14.0794 4.2538 13.3333 3.33332 13.3333C2.41285 13.3333 1.66666 14.0794 1.66666 14.9999ZM3.33332 11.6666C2.41285 11.6666 1.66666 10.9204 1.66666 9.99992C1.66666 9.07944 2.41285 8.33325 3.33332 8.33325C4.2538 8.33325 4.99999 9.07944 4.99999 9.99992C4.99999 10.9204 4.2538 11.6666 3.33332 11.6666Z"
      fill="#3E4247"
    />
  </svg>
);

const SettingsIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5468 17.4141L17.3945 15.5665L16.7437 13.2024L17.0272 12.5253L19.1667 11.3181V8.70529L17.0333 7.49303L16.7546 6.81714L17.4135 4.45188L15.5645 2.60561L13.2002 3.25615L12.5247 2.9729L11.3174 0.833252H8.70454L7.49228 2.96662L6.81655 3.24531L4.45166 2.58559L2.6056 4.43166L3.25624 6.79631L2.97299 7.47187L0.833344 8.6792V11.2913L2.96479 12.5062L3.24378 13.1827L2.58498 15.5476L4.43175 17.3943L6.79641 16.7437L7.47204 17.027L8.6794 19.1657H11.2915L12.5056 17.0342L13.1819 16.7553L15.5468 17.4141ZM15.7106 11.3546L14.9841 13.0897L15.5302 15.0737L15.0565 15.5474L13.0767 14.9959L11.3408 15.7118L10.3228 17.4991H9.65244L8.64285 15.7106L6.90964 14.9839L4.92456 15.5301L4.4517 15.0573L5.00322 13.0775L4.28736 11.3417L2.50001 10.3229V9.65245L4.28926 8.64284L5.01601 6.90955L4.4698 4.92447L4.94169 4.45259L6.92148 5.00488L8.65796 4.28871L9.67443 2.49992H10.3441L11.3537 4.28917L13.087 5.01591L15.0725 4.4696L15.5465 4.94297L14.9951 6.92238L15.7112 8.65871L17.5 9.67518V10.3449L15.7106 11.3546ZM10 13.3333C8.15906 13.3333 6.66668 11.8409 6.66668 9.99992C6.66668 8.15897 8.15906 6.66659 10 6.66659C11.841 6.66659 13.3333 8.15897 13.3333 9.99992C13.3333 11.8409 11.841 13.3333 10 13.3333ZM11.6667 9.99992C11.6667 10.9204 10.9205 11.6666 10 11.6666C9.07953 11.6666 8.33334 10.9204 8.33334 9.99992C8.33334 9.07944 9.07953 8.33325 10 8.33325C10.9205 8.33325 11.6667 9.07944 11.6667 9.99992Z"
      fill="#3E4247"
    />
  </svg>
);

const IconMenuComponent = (props) => {
  const { pendingAssessments, theme } = props;
  const [userName, setUserName] = useState(localStorage.getItem('username'));
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const navigate = (url) => {
    history.push(url);
  };

  const activeStyle = {
    background: 'rgba(0, 0, 0, 0.08)',
    borderLeft: '4px solid #16B8A7',
  };

  const gridStyle = {
    minHeight: '100%',
  };

  return (
    <IconMenu>
      <Content theme={theme}>
        <Grid
          container
          direction="column"
          justify="space-between"
          wrap="nowrap"
          style={gridStyle}
        >
          <Grid item>
            <Logo theme={theme}>
              <img src="/images/logo-q-negative.png" />
            </Logo>
            <Item style={location.pathname === '/dashboard' ? activeStyle : {}}>
              <MyBadge badgeContent={0} color="primary" theme={theme}>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={(e) => navigate('/dashboard')}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="24 / basic / home">
                      <path
                        id="icon"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.88867 10L11.89 3.99867L17.8913 10H17.89V20H5.89001V10H5.88867ZM3.89001 11.9987L2.4132 13.4755L1 12.0623L10.477 2.58529C11.2574 1.8049 12.5226 1.8049 13.303 2.58529L22.78 12.0623L21.3668 13.4755L19.89 11.9987V20C19.89 21.1046 18.9946 22 17.89 22H5.89001C4.78545 22 3.89001 21.1046 3.89001 20V11.9987Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </IconButton>
              </MyBadge>
            </Item>
            <Item
              style={/^\/paciente/.test(location.pathname) ? activeStyle : {}}
            >
              <MyBadge color="primary" theme={theme}>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={(e) => navigate('/pacientes')}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19 10C19 5.94082 16.7616 3.1235 13.8654 2.27771C13.7605 2.00636 13.5948 1.7541 13.3695 1.54243C12.5997 0.81919 11.4003 0.81919 10.6305 1.54243C10.4057 1.75364 10.2402 2.00525 10.1353 2.27592C7.23535 3.11803 5 5.92919 5 10C5 12.6339 4.46898 14.1098 3.48596 15.1793C3.32161 15.3582 2.87632 15.7678 2.57468 16.0453L2.57465 16.0453L2.57465 16.0453L2.5745 16.0454C2.43187 16.1766 2.32138 16.2783 2.28796 16.3119L2 16.604V20.0141H8.08798C8.29384 21.0761 8.87009 21.7867 9.9122 22.4226C11.1941 23.2049 12.8059 23.2049 14.0878 22.4226C15.0075 21.8614 15.6241 20.9989 15.8743 20.0141H22V16.604L21.712 16.3119C21.6817 16.2812 21.5757 16.1834 21.437 16.0555C21.1363 15.7781 20.6823 15.3592 20.5154 15.1769C19.5317 14.1024 19 12.6246 19 10ZM13.7367 20.0141H10.1786C10.3199 20.2769 10.5607 20.4754 10.954 20.7154C11.5963 21.1073 12.4037 21.1073 13.046 20.7154C13.3434 20.5339 13.5758 20.2937 13.7367 20.0141ZM19.0402 16.5274C19.2506 16.7573 19.7016 17.1774 20 17.4519V18.0141H4V17.4524C4.29607 17.1811 4.74843 16.7613 4.95849 16.5327C6.29422 15.0794 7 13.1178 7 10C7 6.21989 9.33277 4.01238 12 4.01238C14.6597 4.01238 17 6.23129 17 10C17 13.1078 17.706 15.07 19.0402 16.5274Z"
                      fill="white"
                    />
                  </svg>
                </IconButton>
              </MyBadge>
            </Item>
            <Item style={location.pathname === '/historico' ? activeStyle : {}}>
              <MyBadge badgeContent={0} color="primary" theme={theme}>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={(e) => navigate('/historico')}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22 19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V11.7947L2.08085 11.6061L5.10236 4.55926C5.55303 3.64142 6.12144 3.07301 7 3H17L17.1308 3.00859C17.8837 3.10794 18.4351 3.65935 18.9191 4.60608L22 11.7947V19ZM4 13V19H20V13H16C16 14.1046 15.1046 15 14 15H10C8.89543 15 8 14.1046 8 13H4ZM4.51654 11H10V13H14V11H19.484L17.1107 5.45729C16.9987 5.23944 16.8953 5.08654 16.8118 4.99993L7.17592 4.99623C7.0987 5.07946 7.00333 5.22529 6.91915 5.39392L4.51654 11Z"
                      fill="white"
                    />
                  </svg>
                </IconButton>
              </MyBadge>
            </Item>
          </Grid>
          <Grid item>
            {/* Settings link */}
            <Item style={location.pathname === '/settings' ? activeStyle : {}}>
              <DropMenu
                buttonProps={{ icon: MoreVerticalIcon }}
                menuProps={{
                  anchorOrigin: { vertical: -58, horizontal: 58 },
                }}
              >
                {!HIDE_FROM.includes(userName) && (
                  <DropMenuItem
                    title="Configurações"
                    icon={SettingsIcon}
                    onClick={(e) => navigate('/settings')}
                  />
                )}
                <DropMenuItem title="Status do Sistema" icon={StatusIcon} onClick={() => navigate('/health')}/>
                <DropMenuItem title="Changelog" icon={ChangeLogIcon} onClick={() => navigate('/changelog')} />
              </DropMenu>
            </Item>
            {/* Logout link */}
            <Item style={logoutDialogOpen ? activeStyle : {}}>
              <MyBadge badgeContent={0} color="primary" theme={theme}>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={() => setLogoutDialogOpen(true)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.79288 12.793L18.3787 12.793L15.0858 16.0859L16.5 17.5001L22.2071 11.793L16.5 6.08586L15.0858 7.50008L18.3787 10.793L7.79288 10.793L7.79288 12.793ZM3.79288 20.793L12.7929 20.793L12.7929 18.793L3.79288 18.793L3.79288 4.79297L12.7929 4.79297L12.7929 2.79297L3.79288 2.79297C2.68831 2.79297 1.79288 3.6884 1.79288 4.79297L1.79288 18.793C1.79288 19.8975 2.68831 20.793 3.79288 20.793Z"
                      fill="white"
                    />
                  </svg>
                </IconButton>
              </MyBadge>
              <DialogLogout
                open={logoutDialogOpen}
                setOpen={setLogoutDialogOpen}
              />
            </Item>
            <PlatformVersion />
          </Grid>
        </Grid>
      </Content>
    </IconMenu>
  );
};

const mapStateToProps = (state) => ({
  pendingAssessments: state.pendingAssessments.list,
});

export default connect(mapStateToProps, null)(withTheme(IconMenuComponent));
