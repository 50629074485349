import React, { useCallback } from 'react';
import { Drawer } from '../../../components/molecules/Drawer';
import { TimelineDrawerNavigation } from './TimelineDrawerNavigation/TimelineDrawerNavigation';
import { TimelineDrawerContent } from './TimelineDrawerContent';
import { useTimelineCursor, resetTimelineCursor } from '../slices';
import { useDispatch } from 'react-redux';

const drawerStyle = { pointerEvents: 'none'}
const paperProps = { style: { pointerEvents: 'all'}};
const headerProps = { wide: false, small: true }

export const TimelineDrawer = () => {
  const dispatch = useDispatch();
  const handleResetCursor = useCallback(
    () => dispatch(resetTimelineCursor()),
    [dispatch]
  );
  const { indicator, date } = useTimelineCursor();
  const hasSelection = !!indicator && !!date;

  return (
    <Drawer
      style={drawerStyle}
      PaperProps={paperProps}
      hideBackdrop
      header={headerProps}
      open={hasSelection}
      onClose={handleResetCursor}
      title={hasSelection && <TimelineDrawerNavigation />}
    >
      {hasSelection && (
        <TimelineDrawerContent indicator={indicator} date={date} />
      )}
    </Drawer>
  );
};
