import React, { useMemo } from 'react';
import { SQUARE_WIDTH, SQUARE_HEIGHT } from '../../constants';
import { useTimelineChartContext } from '../../TimelineChartProvider';
import { SquareLabel } from './SquareLabel'

export const SquareEntry = (props) => {
  const { indicator, date, color, label, onClick } = props;
  const { xScale, yScale } = useTimelineChartContext();

  const x = useMemo(() => xScale(date), [xScale, date]);
  const y = useMemo(() => yScale(indicator), [yScale, indicator]);

  return (
    <g data-name={`${indicator}:${date}`} transform={`translate(${x} ${y})`}>
      <rect
        className="indicator-entry"
        width={SQUARE_WIDTH}
        height={SQUARE_HEIGHT}
        x={0}
        y={0}
        cursor="pointer"
        fill={color}
        onClick={onClick}
      />
      {label && <SquareLabel background={color} label={label} />}
    </g>
  );
};
