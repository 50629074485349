import React, { useMemo } from 'react';
import { VerticalBarChart } from '../VerticalBarChart';
import { getIndicatorValue } from '../../../../../utils/formatters/formatDashboardIndicators'

import { useTheme } from '@material-ui/core';
import { StatStack } from '../../DashboardStat';
import { useSelectedCompetence } from '../../slices';

const randomValue = () => Math.floor(Math.random() * 10 * (Math.random() * 20));

export const InfectionsByAge = ({ indicators }) => {
  const competence = useSelectedCompetence();

  const theme = useTheme();
  const isLoading = false;
  const dataset = useMemo(
    () => [
      {
        label: 'CONFIRMADO',
        color: 'rgba(43, 172, 118, 0.74)',
        values: [
          {
            label: 'ATÉ 10',
            value: getIndicatorValue(indicators, 'infeccoes_confirmadas_faixa_etaria_ate_10', competence),
          },
          {
            label: '11-20',
            value: getIndicatorValue(indicators, 'infeccoes_confirmadas_faixa_etaria_11_ate_20', competence),
          },
          {
            label: '21-30',
            value: getIndicatorValue(indicators, 'infeccoes_confirmadas_faixa_etaria_21_ate_30', competence),
          },
          {
            label: '31-40',
            value: getIndicatorValue(indicators, 'infeccoes_confirmadas_faixa_etaria_31_ate_40', competence),
          },
          {
            label: '41-50',
            value: getIndicatorValue(indicators, 'infeccoes_confirmadas_faixa_etaria_41_ate_50', competence),
          },
          {
            label: '51-60',
            value: getIndicatorValue(indicators, 'infeccoes_confirmadas_faixa_etaria_51_ate_60', competence),
          },
          {
            label: '61-70',
            value: getIndicatorValue(indicators, 'infeccoes_confirmadas_faixa_etaria_61_ate_70', competence),
          },
          {
            label: '71-80',
            value: getIndicatorValue(indicators, 'infeccoes_confirmadas_faixa_etaria_71_ate_80', competence),
          },
          {
            label: '81-90',
            value: getIndicatorValue(indicators, 'infeccoes_confirmadas_faixa_etaria_81_ate_90', competence),
          },
          {
            label: '91-100',
            value: getIndicatorValue(indicators, 'infeccoes_confirmadas_faixa_etaria_91_ate_100', competence),
          },
          {
            label: '101+',
            value: getIndicatorValue(indicators, 'infeccoes_confirmadas_faixa_etaria_ate_100_mais', competence),
          },
        ],
      },
      {
        label: 'NÃO CONFIRMADO',
        color: 'rgba(254, 72, 105, 0.74)',
        values: [
          {
            label: 'ATÉ 10',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmadas_faixa_etaria_ate_10', competence),
          },
          {
            label: '11-20',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmadas_faixa_etaria_11_ate_20', competence),
          },
          {
            label: '21-30',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmadas_faixa_etaria_21_ate_30', competence),
          },
          {
            label: '31-40',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmadas_faixa_etaria_31_ate_40', competence),
          },
          {
            label: '41-50',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmadas_faixa_etaria_41_ate_50', competence),
          },
          {
            label: '51-60',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmadas_faixa_etaria_51_ate_60', competence),
          },
          {
            label: '61-70',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmadas_faixa_etaria_61_ate_70', competence),
          },
          {
            label: '71-80',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmadas_faixa_etaria_71_ate_80', competence),
          },
          {
            label: '81-90',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmadas_faixa_etaria_81_ate_90', competence),
          },
          {
            label: '91-100',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmadas_faixa_etaria_91_ate_100', competence),
          },
          {
            label: '101+',
            value: getIndicatorValue(indicators, 'infeccoes_nao_confirmadas_faixa_etaria_ate_100_mais', competence),
          },
        ],
      },
    ],
    [theme, indicators, competence]
  );

  return (
    <>
      <StatStack
        items={[
          { label: 'Mediana confirmado', value: 43 },
          { label: 'Mediana não confirmado', value: 37 },
        ]}
      />
      <VerticalBarChart
        isLoading={isLoading}
        dataset={dataset}
        aspectRatio={2.5}
        // height={200}
      />
    </>
  );
};
