import React, { useState, useEffect, Suspense } from 'react';
import InfectionTypeFormField from '../../molecules/InfectionTypeFormField/index';
import Grid from '@material-ui/core/Grid';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import {
  setInfectionParamAction,
  InfectionParams,
  saveInfectionParams,
} from '../../../actions/SettingsActions';

const InfectionsForm = (props) => {
  const {
    infectionParams,
    updateInfectionParam,
    sendInfectionParams,
    getInfectionParams,
  } = props;
  // const MEDIUM_RANGE_INDEX = 1;
  // const infectionParams = {
  //   geral: {
  //     ranges: [
  //       {from: 90, to: 100},
  //       {from: 20, to: 89},
  //       {from: 0, to: 19}
  //     ]
  //   }
  // };
  // const [formInfectionParams, setFormInfectionParams] = useState([20,90]);
  const [formInfectionValues, setFormInfectionValues] = useState({});

  // const mediumValues = [
  //   infectionParams?.geral.ranges[MEDIUM_RANGE_INDEX]
  //     ?.from,
  //   infectionParams?.geral.ranges[MEDIUM_RANGE_INDEX]?.to,
  // ];


  const changeInfectionParam = (infection, range) => {
    // setFormInfectionParams(range);
    updateInfectionParam(infection, range);
  }

  const handleSubmit = (e) => {
    sendInfectionParams(infectionParams);
  };

  const updateInfectionValues = () => {
    setFormInfectionValues({geral: [infectionParams.geral.ranges[1].to, infectionParams.geral.ranges[2].to]});
  };

  useEffect(() => {
    getInfectionParams(extractInfectionNames());
  }, [getInfectionParams]);

  useEffect(() => {
    updateInfectionValues();
  }, [infectionParams])

  const extractInfectionNames = () => {
    return Object.keys(infectionParams);
  };

  return (
    <>
        <Suspense fallback={<div>Carregando...</div>}>
          <Grid container spacing={3}>
            {
              !isEmpty(formInfectionValues) && <InfectionTypeFormField
                values={formInfectionValues}
                infection={'geral'}
                changeData={(infection, range) =>
                  changeInfectionParam(infection, range)
                }
              />
            }
          </Grid>
        </Suspense>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  sendInfectionParams: (infectionList) =>
    dispatch(saveInfectionParams(infectionList)),
  updateInfectionParam: (infection, range) =>
    dispatch(setInfectionParamAction(infection, range)),
  getInfectionParams: (infectionsList) =>
    dispatch(InfectionParams(infectionsList)),
});

const mapStateToProps = (state) => ({
  infectionParams: state.settings.infections,
});

export default connect(mapStateToProps, mapDispatchToProps)(InfectionsForm);
