
export const infectionTypesRequestBody = {
  query:{
    bool : {
      must : {
        range: {
          dt_infeccao: {
            gte: '2020-01-01 00:00:00+00',
            lte: '2021-09-30 00:00:00+00'
          }
        }
      },
      filter: {
        terms: {
          prob_grupo_isa: [
            'MEDIA',
            'ALTA']
        }
      }
    }
  },
  aggs: {
    traqueo: {
      range: {
        field: 'traqueo',
        ranges: [
          {
            from: 0.01
          }
        ]
      }
    },
    pav: {
      range: {
        field: 'pav',
        ranges: [
          {
            from: 0.01
          }
        ]
      }
    },
    ipcs: {
      range: {
        field: 'ipcs',
        ranges: [
          {
            from: 0.01
          }
        ]
      }
    },
    itu: {
      range: {
        field: 'itu',
        ranges: [
          {
            from: 0.01
          }
        ]
      }
    },
    pnm: {
      range: {
        field: 'pnm',
        ranges: [
          {
            from: 0.01
          }
        ]
      }
    },
    isc: {
      range: {
        field: 'isc',
        ranges: [
          {
            from: 0.01
          }
        ]
      }
    }
  }
};

