import React from 'react';
import { Button, Box, withStyles } from '@material-ui/core';
import { FeedbackMessage } from '../../../atoms/FeedbackMessage';
import { Dialog, DialogToolbar } from '../../../molecules/Dialog';

const DangerButton = withStyles((theme) => ({
  root: {
    background: theme.palette.timeline.probability['500'],
    color: 'white',
    '&:hover': {
      background: theme.palette.timeline.probability['400'],
    },
  },
}))(Button);

export const DeleteCommentDialog = (props) => {
  const { open, onCancel, onConfirm, loading } = props;

  return (
    <Dialog
      title=""
      loading={loading}
      open={open}
      onClose={onCancel}
      footer={
        <DialogToolbar borderTop>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outlined" color="primary" onClick={onCancel}>
              Cancelar
            </Button>
            <DangerButton
              disabled={loading}
              variant="contained"
              onClick={onConfirm}
            >
              Excluir
            </DangerButton>
          </Box>
        </DialogToolbar>
      }
    >
      <FeedbackMessage
        title="Excluir comentário?"
        subtitle="Você tem certeza que deseja excluir este comentário?"
        icon={
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="40" cy="40" r="40" fill="#F86975" fill-opacity="0.1" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M36 25.3334H44C45.4727 25.3334 46.6667 26.5273 46.6667 28V29.3334H50.6667C52.1394 29.3334 53.3333 30.5273 53.3333 32V34.6667C53.3333 36.1395 52.1394 37.3334 50.6667 37.3334H50.5598L49.3333 52C49.3333 53.4728 48.1394 54.6667 46.6667 54.6667H33.3333C31.8606 54.6667 30.6667 53.4728 30.6713 52.1108L29.4398 37.3334H29.3333C27.8606 37.3334 26.6667 36.1395 26.6667 34.6667V32C26.6667 30.5273 27.8606 29.3334 29.3333 29.3334H33.3333V28C33.3333 26.5273 34.5272 25.3334 36 25.3334ZM29.3333 32L33.3333 32H46.6667L50.6667 32V34.6667H29.3333V32ZM32.1153 37.3334H47.8843L46.6713 51.8893L46.6667 52H33.3333L32.1153 37.3334ZM44 28V29.3334H36V28H44ZM35.0572 40.9428L36.9428 39.0572L40 42.1144L43.0572 39.0572L44.9428 40.9428L41.8856 44L44.9428 47.0572L43.0572 48.9428L40 45.8856L36.9428 48.9428L35.0572 47.0572L38.1144 44L35.0572 40.9428Z"
              fill="#F86975"
            />
          </svg>
        }
      />
    </Dialog>
  );
};
