import React, { useCallback, useState, useMemo, useEffect } from 'react';
import moment from 'moment';

import { IndicatorDateCard } from '../../IndicatorDateCard';

import { useTimelineIndicator, useTimelineVitalSigns } from '../../../slices';
import { VitalSignsList } from './VitalSignsList';
import { useIndicatorsPeriod } from '../../hooks/useIndicatorsPeriod';

import GraphDialogComponent from '../../../../../components/organisms/GraphDialog';
import HistogramChart from '../../../../../components/atoms/HistogramChart';
import { vitalSignsList } from '../../../../../constants/vitalSigns';
import { orderBy } from 'lodash';

export const PopoverContentSinaisVitais = ({ indicator }) => {
  const { startDate, endDate } = useIndicatorsPeriod();
  const vitalSigns = useTimelineVitalSigns();
  const allVitalSigns = useTimelineIndicator('sinais-vitais');

  const periodItems = useMemo(() => {
    return vitalSigns.filter(
      (e) => e.hasCriteria && e.momentDate.isBetween(startDate, endDate)
    );
  }, [vitalSigns, startDate, endDate]);

  const [cursor, setCursor] = useState(false);

  const handleClick = useCallback((item) => setCursor(item), [setCursor]);

  const histogramDate = useMemo(() => {
    if (!cursor?.date) return undefined;

    return new Date(cursor.date);
  }, [cursor]);

  const histogramDataset = useMemo(() => {
    if (!cursor?.date || !cursor?.signal || !vitalSigns?.length)
      return undefined;
    const subDays = moment(cursor.momentDate)
      .add({ days: -7 })
      .startOf('day')
      .toDate();
    const addDays = moment(cursor.momentDate)
      .add({ days: 7 })
      .endOf('day')
      .toDate();

    return allVitalSigns
      .filter(
        ({ tipo_sinal, _meta }) =>
          tipo_sinal === cursor.signal &&
          _meta.momentTime.isBetween(subDays, addDays)
      )
      .map((_source) => ({ date: _source._meta.momentDate.toDate(), _source }));
  }, [cursor, vitalSigns, allVitalSigns]);

  const histogramOpen = !!histogramDataset && !!histogramDate;

  const renderedDateCards = useMemo(
    () => (
      <>
        {periodItems.map(({ date, momentDate, signals }, cardIndex) => (
          <IndicatorDateCard
            key={date}
            style={{
              marginTop: cardIndex !== 0 ? '8px' : 0,
            }}
            momentDate={momentDate}
            date={date}
            indicator={indicator}
          >
            <div style={{ marginTop: '-8px' }}>
              <VitalSignsList
                signals={orderBy(
                  signals.filter((s) => s.hasCriteria),
                  ({ signal }) => vitalSignsList.indexOf(signal),
                  ['asc']
                )}
                onClick={handleClick}
              />
            </div>
          </IndicatorDateCard>
        ))}
      </>
    ),
    [periodItems, indicator, handleClick]
  );

  return (
    <>
      {renderedDateCards}
      <GraphDialogComponent
        title={cursor?.signal || ''}
        unit={cursor?.measure || ''}
        open={histogramOpen}
        setOpen={setCursor}
      >
        <HistogramChart dataset={histogramDataset} date={histogramDate} />
      </GraphDialogComponent>
    </>
  );
};
