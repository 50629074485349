import { createSelector } from '@reduxjs/toolkit';
import { selectTimelineEncontroTerms } from './selectTimelineEncontroTerms';
import { selectTimelineSearchWord } from './selectTimelineSearchWord';
import {
  keywords,
  createRegexKeywords,
} from '../../../../components/atoms/TimelineChart/Tooltips/constants';
import { useSelector } from 'react-redux';

export const selectTimelineSearchKeyword = createSelector(
  [selectTimelineEncontroTerms, selectTimelineSearchWord],
  (aiKeywords, searchWord) => {
    return aiKeywords.includes(searchWord) || keywords.includes(searchWord)
      ? searchWord
      : { keyword: searchWord };
  }
);
export const useTimelineSearchKeyword = () =>
  useSelector(selectTimelineSearchKeyword);

export const selectTimelineSearchRegex = createSelector(
  selectTimelineSearchKeyword,
  (searchKeyword) => {
    return createRegexKeywords([searchKeyword]);
  }
);
export const useTimelineSearchRegex = () =>
  useSelector(selectTimelineSearchRegex);

export const selectTimelineKeywordsRegex = createSelector(
  [selectTimelineEncontroTerms, selectTimelineSearchKeyword],
  (aiKeywords, searchKeyword) => {
    const terms = [searchKeyword, ...aiKeywords, ...keywords];
    return createRegexKeywords(terms);
  }
);
export const useTimelineKeywordsRegex = () =>
  useSelector(selectTimelineKeywordsRegex);
