import React from 'react';
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import useStyles from './styles';
import SearchIcon from '@material-ui/icons/Search';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import {Icon} from '@material-ui/core';
import { withTheme } from "@material-ui/core/styles";
import {isEmpty} from 'lodash';

const SearchTextField = props => {
  const {
    clearInput,
    ...newProps
  } = {...props};
  const classes = useStyles();
  const clearSearch = () => clearInput();

  return (
    <Box classes={{ root: classes.container }}>
      <Icon
        component={SearchIcon}
        style={{ color: props.theme.palette.primary.main }}
        classes={{ root: classes.searchIcon }}
      />
      <TextField
        classes={{ root: classes.root }}
        {...newProps}
        variant="outlined"
        data-testid="search-bar"
      />
      {isEmpty(newProps.value) ? null : (
        <Icon
          component={CancelSharpIcon}
          classes={{ root: classes.clearIcon }}
          onClick={clearSearch}
        />
      )}
    </Box>
  );
}

export default withTheme(SearchTextField);
