import React from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {DateRangeInput} from './styles';
import TodayIcon from '@material-ui/icons/Today';

// Mais informações sobre a formatação do calendário: https://momentjs.com/docs/#/displaying/
export default (props) => {
  const [initialDate, setInitialDate] = props.initialDate ?? [
    // Travado nesta data 01/01/2020 pois só existem dados a partir deste período
    moment('2020-01-01'),
    (date) => {}
  ];

  const [finalDate, setFinalDate] = props.finalDate ?? [
    moment(), (date) => {}];

  const handleDateChange = (date, initial = false) => {
    if(initial) {
      setInitialDate(date);
    } else {
      setFinalDate(date);
    }
  };

  return (
    <DateRangeInput>
      <MuiPickersUtilsProvider locale='pt-br' utils={MomentUtils}>
        <Grid container justify='flex-end'>
          <Grid item xs={6} style={{'textAlign': 'right'}}>
            <KeyboardDatePicker
              disableToolbar
              placeholder='Data inicial'
              variant='inline'
              format={'DD/MM/YYYY'}
              margin='normal'
              id='date-picker-first'
              autoOk
              value={initialDate}
              maxDate={finalDate.clone().subtract(1, 'days')}
              onChange={(date) => handleDateChange(date, true)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className='border'
              keyboardIcon={<TodayIcon fontSize='small' />}
            />
          </Grid>
          <Grid item xs={6} style={{'textAlign': 'right'}}>
            <KeyboardDatePicker
              disableToolbar
              placeholder='Data final'
              variant='inline'
              format={'DD/MM/YYYY'}
              margin='normal'
              autoOk
              id='date-picker-last'
              value={finalDate}
              maxDate={moment()}
              onChange={(date) => handleDateChange(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              keyboardIcon={<TodayIcon fontSize='small' />}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </DateRangeInput>
  );
}
