import { makeStyles } from '@material-ui/styles';
import { alpha } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '20px 12px',
    borderRadius: '6px',

    '&:not(:last-child)': {
      marginBottom: '6px',
    },

    '&.default': {
      backgroundColor: theme.palette.gray[50],
    },

    '&.danger': {
      backgroundColor: alpha(theme.palette.timeline.probability[500], .08),
    },
  },

  dot: {
    minWidth: '8px',
    minHeight: '8px',
    borderRadius: '50%',
    transform: 'translateY(50%)',
    marginRight: '16px',

    '&.danger': {
      backgroundColor: theme.palette.timeline.probability[500],
    },
  },

  title: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: 1.44,
    color: theme.palette.neutral.black[100],
    fontFamily: '"DM Mono", monospace',
    marginBottom: '2px',
  },

  content: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: 1.36,
    color: theme.palette.neutral.black[72],
    fontFamily: '"DM Mono", monospace',
    display: 'block',
  },
}));
