import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../baseQuery';

export const patientPrescriptionsApi = createApi({
  reducerPath: 'patientPrescriptionsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['PatientPrescriptions'],
  endpoints: (build) => ({
    getPatientPrescriptions: build.query({
      query: (filters) => {
        return {
          url: `/prescriptions`,
          method: 'GET',
          params: { ...filters }
        }
      }
    })
  })
})

export const {
  useGetPatientPrescriptionsQuery
} = patientPrescriptionsApi;
