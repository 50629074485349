import { Typography, withStyles } from '@material-ui/core';

export const SuspicionCardTitle = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '13px',
    letterSpacing: '0.08em',
    textAlign: 'left',
    marginBottom: '8px',
    color: theme.palette.neutral.black['72'],
  },
}))(Typography);