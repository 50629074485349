import styled from "styled-components";
import {makeStyles} from '@material-ui/core/styles';
import {Box, Chip} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  saibaMais: {
    width: '100%',
    background: 'none',
    border: 'none',
    paddingTop: '12px',
    marginTop: '10px',
    borderTop: '1px solid rgba(143, 149, 163, 0.16)',
    textAlign: 'end',
    fontFamily: 'DM Mono',
    fontWeight: '400',
    fontSize: '14px',
    color: '#16B8A7'
  },
  modalTitle: {
    '& h2': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    }
  },
  dataEncontro: {
    fontFamily: 'DM Mono',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '16.93px',
    marginBottom: '10px'
  },
  accordionDetails: {
    display: 'block!important',
    paddingBottom: 0
  },
  accordion: {
    display: 'block!important',
    background: 'none',
    boxShadow: 'none'
  }
}))

export const Tag = styled.div `
  font-family: 'DM Mono';
  font-weight: 400;
  font-size: 13px;
  line-height: 16.93px;
  padding: 5px 13px;
  margin: 3px;
  color: #8F95A3;
  border: 1px solid #8F95A3;
  border-radius: 20px;
  background-color: ${props => props.isHighLight && props.backgroundColor};
  border: ${props => props.isHighLight && `${props.border} !important`};
  color: ${props => props.isHighLight && `${props.color} !important`};
`

export const TooltipContainer = styled.div `
  padding: 15px 10px 15px 15px;
  width: 340px;

  background: #FFFFFF;
  box-shadow: 0px 32px 56px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
`
export const TermosTexto = styled.div `
  background-color: #F5F5F5;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100px;
  margin: 20px 0px;
`
export const TooltipContent = styled.div`
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px;
`;
export const TooltipBlock = styled(Box) `
  display: block;
  width: 100%;
  padding-bottom: 5px;
`

export const TooltipBlockSmall = styled(Box) `
  position: relative;
  display: block;
  width: ${props=> props.highlight ? 'calc(100% + 38px)' : '100%'};
  transform: ${props=> props.highlight ? 'translateX(-10px)' : 'none'};
  margin: ${props=> props.highlight ? '0 -10px 0 -10px' : '0'};
  padding: ${props=> props.highlight ? '15px 20px 15px 20px' : '10px 0 10px 0'};
  background: ${props=> props.highlight ? 'rgba(0,0,0,.05)' : 'none'};
  cursor: ${props=> props.onClick ? 'pointer' : 'normal'};
  border-bottom: ${props => {
    if(!props.highlight && !props.lastItem){
      return '1px solid rgba(0,0,0,0.05)'
    }
    return  'none';
  }};
  &:last-child {
    border: none;
  }


`

export const Title = styled.div `
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #383A3D;
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
`
export const ChipPositivo = styled(Chip)`
  margin-top: 7px;

`
export const ChipPositivoInline = styled(Chip)`
  margin: 0 0 0 7px;
  font-size: 11px!important;
  height: 14px!important;
`
export const Percentual = styled.div `

`
export const TitleDate = styled.div `
  display: inline-block;
  vertical-align: middle;
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #8F95A3;
  padding: 0;
  margin: 0;
  margin-top: -2px;
  margin-left: 8px;
`

export const List = styled(Box) `
  margin-bottom: 4px;
`
export const ListItem = styled(Box) `
  background-color: #F5F5F5;
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 8px 10px 0 10px;
  border: 1px solid transparent;
  &.highlight {
    background-color: rgba(254, 60, 76, 0.1);
    border: 1px solid rgba(254, 60, 76, 0.2);
    &.hover:hover {
      border: 1px solid rgba(254, 60, 76, 0.7);
    }
  }
  &.exames-item {
    padding: 0;
  }
  &.hover {
    transition: 300ms ease-out;
    cursor: pointer;
  }
  &.hover:hover {
    border-color: #16B8A7;
  }
`

export const ItemDate = styled(Box) `
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #8F95A3;
`
export const TooltipGraphItem = styled.div `
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);

`
export const ItemDateSmall = styled.div `
  vertical-align: middle;
  margin-right: 10px;
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #8F95A3;
`

export const ItemName = styled(Box) `
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: ${props=> props.details ? '500' : 'none'};
  font-size: 13px;
  color: #383A3D;


`
export const ItemPercentual = styled.div `
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #FE3C4C;
`
export const ItemText = styled.div `
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #383A3D;
`
export const ItemTextSmall = styled.div `
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: ${props=> props.highlight ? 'red' : '#383A3D'};
`
export const ItemHint = styled.div `
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #8F95A3;
`

export default useStyles;
