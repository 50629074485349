import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../baseQuery';

export const patientEvaluationsApi = createApi({
  reducerPath: 'patientEvaluationsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['PatientEvaluations'],
  endpoints: (build) => ({
    getPatientEvaluations: build.query({
      query: ({ infectionType, ...filters }) => {
        const infectionTypes = infectionType.reduce((acc, item) => {
          return acc.concat(`&infectionType=${item}`)
        }, '')

        return {
          url: `/elk/avaliacoes?${infectionTypes}`,
          params: { ...filters },
          method: 'GET',
        }
      }
    }),
    getExportPatientEvaluations: build.query({
      query: ({ itensPerPage, page, ...filters }) => {
        return {
          url: `/elk/avaliacoes?itensPerPage=1500&page=1`,
          params: { ...filters },
          method: 'GET'
        }
      }
    })
  })
})

export const {
  useGetPatientEvaluationsQuery,
  useGetExportPatientEvaluationsQuery
} = patientEvaluationsApi;
