import React, { useMemo } from 'react';
import { useSelectedCompetence } from '../../slices'
import { useTheme } from '@material-ui/core';
import { DonutsChart } from '../DonutsChart';
import { getIndicatorValue } from '../../../../../utils/formatters/formatDashboardIndicators'

export const PatientsBySex = ({ indicators }) => {
  const competence = useSelectedCompetence()
  const theme = useTheme();
  const isLoading = false;
  const dataset = useMemo(
    () => [
      {
        label: 'HOMENS',
        value: getIndicatorValue(indicators, 'pacientes_feminino', competence),
        color: theme.palette.timeline.secondary.blue,
      },
      {
        label: 'MULHERES',
        value: getIndicatorValue(indicators, 'pacientes_masculino', competence),
        color: theme.palette.timeline.secondary.purple,
      },
    ],
    [theme, competence, indicators]
  );

  return <DonutsChart isLoading={isLoading} dataset={dataset} />;
};
