import React from 'react';
import { Card as MuiCard, Box, withStyles } from '@material-ui/core';
import { useSingleCommentContext } from '../SingleCommentContext';
import { CommentMenu } from './CommentMenu';

const Card = withStyles((theme) => ({
  root: {
    position: 'relative',
    padding: '16px 12px',
    backgroundColor: theme.palette.neutral.gray.xxlt,
    '& .comment-card-menu': {
      opacity: 0,
      visibility: 'hidden',
    },
    '&:hover': {
      backgroundColor: theme.palette.neutral.gray.xlt,
      '& .comment-card-menu': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
}))(MuiCard);

const Author = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '17px',
    color: theme.palette.neutral.black['100'],
  },
}))(Box);

const Time = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '17px',
    color: theme.palette.neutral.black['48'],
  },
}))(Box);

const Content = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '18px',
    color: theme.palette.neutral.black['72'],
  },
}))(Box);

export const CommentCard = () => {
  const { author, date, content } = useSingleCommentContext();

  return (
    <Card elevation={0}>
      <Box className='comment-card-menu' position="absolute" right="12px" top="16px">
        <CommentMenu />
      </Box>
      <Author component="h5" mb="2px">
        {author}
      </Author>
      <Time component="time" mb="6px">
        {date}
      </Time>
      <Content>{content}</Content>
    </Card>
  );
};
