import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

export const statValueFormatter = new Intl.NumberFormat('pt-BR')

export const StatValue = withStyles((theme) => ({
  root: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    color: theme.palette.neutral.black['88'],
  },
  // Size variants
  small: {
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '22px',
  },
  large: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '27px',
  },
}))(function StyledStatValue({ classes, className, children, size }) {
  return (
    <div
      className={clsx(classes.root, classes[size] || classes.small, className)}
    >
      {children}
    </div>
  );
});
StatValue.defaultProps = {
  size: 'small',
};
