import React from 'react';
import * as d3 from 'd3';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { isaTheme } from '../../../styles/isa.theme';
import { ThemeProvider } from '@material-ui/core/styles';
import {store} from '../../../store';
import {each} from "lodash";
// import {filter} from "lodash";
// import {groupByDate} from "./Helper";

export default class D3Plot {
  // Atributos principais
  plot = null;
  container = null;
  collection = null;
  margin = null;

  // Referências dos eixos do gráfico
  x = null;
  y = null;

  // Estilos dos ícones plotados
  radius = 8;
  fillColorHighlight = 'rgba(254, 60, 76)';
  fillColorHiglightVisited = 'rgba(254, 60, 76, 0.4)';
  fillColor = 'rgba(143, 149, 163)';
  fillColorVisited = 'rgba(143, 149, 163, 0.5)';

  /**
   * Adiciona os dados no gráfico
   * @param container Container onde ser inserido
   * @param collection Coleção com os dados
   * @param x objeto do eixo X
   * @param y objeto do eixo Y
   * @param margin marges do gráfico para compor o tamanho real
   */
  constructor(container, collection, x, y, margin) {
    // Definindo atributos
    this.container = container;
    this.collection = collection;
    this.x = x;
    this.y = y;
    this.margin = margin;

    // Criando container container
    this.plot = this.container
      .append('g')
      .attr('class', 'Plot')
      .attr('transform', `translate(${this.margin.left},0)`);
  }

  /**
   * Inicializa a aplicação e insere os dados no gráfico
   */
  initialize() {
    [
      {
        data: this.collection.getDiagnosticos(),
        label: 'Suspeitas',
      },
      {
        data: this.collection.getMovimentacao(),
        label: 'Evolução',
      },
      {
        data: this.collection.getExames(),
        label: 'Exames',
      },
      {
        data: this.collection.getMedicamentos(),
        label: 'Antimicrobianos',
      },
      {
        data: this.collection.getSinaisVitais(),
        label: 'Sinais vitais',
      },
      {
        data: this.collection.getNotas(),
        label: 'Laudos',
      },
      {
        data: this.collection.getProcedimentos(),
        label: 'Procedimentos',
      },
      {
        data: this.collection.getInfeccoes(),
        label: 'Infecções',
      },
    ].forEach((node, index) => {
      let nodes = this.plot;
      node.data.forEach((data) => this.drawLine(nodes, node, data));
      node.data.forEach((data) => this.drawNode(nodes, node, data));
    });
  }

  /**
   * Desenha as linhas do gráfico
   *
   * @param nodes array
   * @param node object
   * @param data
   */
  drawLine(nodes, node, data) {
    const classScope = this;
    if (Array.isArray(data.date)) {
      // console.log(data)
      nodes
        .append('line')
        .attr('class', 'node-line')
        .attr('x1', this.x(data.date[0]))
        .attr('x2', this.x(data.date[1]))
        .attr('y1', this.y(node.label))
        .attr('y2', this.y(node.label))
        .attr('stroke-width', 6)
        .style('opacity', '0.4')
        .attr('stroke', (d) => this.color(data))
        .on('click', function () {
          d3.select(this).attr('style', 'opacity: 0.5');
          classScope.click(data);
        });
    }
  }

  /**
   * Desenha o node no svg
   * @param nodes lista com todos os nodes
   * @param node node a ser desenhado
   * @param data referencia do eixoX
   */
  drawNode(nodes, node, data) {
    const classScope = this;
    let nodeContainer = null;

    // Verifica se é um array de datas
    if (Array.isArray(data.date)) {
      // Seta deitada para direita
      nodeContainer = nodes
        .append('path')
        .attr('class', 'polygon')
        .attr('d', 'M 0,0 0,14 12,7 Z')
        .attr('stroke', data.color ? data.color : this.color(data))
        .attr('fill', data.color ? data.color : this.color(data))
        .attr(
          'transform',
          `translate(${this.x(data.date[1]) + 1},${this.y(node.label) - 7})`
        )
        .on('click', function () {
            d3.select(this).attr('style', 'opacity: 0.5');
            // .attr('stroke', classScope.color(data, true));

          classScope.click(data);
        });

      nodes
        .append('path')
        .attr('class', 'polygon')
        .attr('d', 'M 0,0 0,14 -12,7 Z')
        .attr('stroke', data.color ? data.color : this.color(data))
        .attr('fill', data.color ? data.color : this.color(data))
        .attr(
          'transform',
          `translate(${this.x(data.date[0]) - 1},${this.y(node.label) - 7})`
        );

      nodes
        .append('text')
        .text((d) => data.total)
        .attr('class', 'node-text')
        .attr('x', this.x(data.date[0]) + 7)
        .attr('y', this.y(node.label) - 7);
    }
    // Verifica se uma data única
    if (data.date instanceof Date) {

      // Verifica se o objeto existe um padrão pro node
      if (data.triangle && !data.circle) {
        nodeContainer = nodes
          .append('path')
          .attr('class', 'polygon')
          .attr('d', 'M 0,0 5,-10 10,0 Z')
          .attr('stroke', () => '#9c27b0')
          .attr('fill',() => '#9c27b0')
          .attr('transform', `translate(${this.x(data.date.getTime())-5},${this.y(node.label)+4})`)
          .on('click', function () {
            d3.select(this).attr('style', 'opacity: 0.5');
            classScope.click(data);
          });
      } else if (data.diamond && !data.circle) {
        nodeContainer = nodes
          .append('rect')
          .attr('class', 'polygon')
          .attr('width', 10)
          .attr('height', 10)
          .attr('stroke', () => '#FE3C4C')
          .attr('fill',() => '#FE3C4C')
          .attr('transform', `translate(${this.x(data.date.getTime())},${this.y(node.label)-6}) rotate(45)`)
          .on('click', function () {
            d3.select(this).attr('style', 'opacity: 0.5');
            classScope.click(data);
          });
      }
      else if (data.triangle2) {
        nodeContainer = nodes
          .append('path')
          .attr('class', 'polygon')
          .attr('d', 'M 0,0 5,-10 10,0 Z')
          .attr('stroke', () => '#6BCAFF')
          .attr('fill',() => '#6BCAFF')
          .attr('transform', `translate(${this.x(data.date.getTime())-5},${this.y(node.label)+4})`)
          .on('click', function () {
            d3.select(this).attr('style', 'opacity: 0.5');
            classScope.click(data);
          });
      } else  {
        nodeContainer = nodes
          .append('circle')
          .attr('class', 'node')
          .attr('r', this.radius)
          .attr('cx', this.x(data.date.getTime()))
          .attr('cy', this.y(node.label))
          .attr('fill', () => {
            return data.color ? data.color : this.color(data)
          })
          .on('click', function () {
            d3.select(this).attr('style', 'opacity: 0.5');

            classScope.click(data);
          });
        // .on('blur', () => this.removeTooltip())
      }
      if (data.circle) {
        if (data.diamond) {
          nodeContainer = nodes
            .append('g')
            .attr('transform', `translate(${this.x(data.date.getTime())},${this.y(node.label)}) rotate(45)`)
            .on('click', function () {
              d3.select(this).attr('style', 'opacity: 0.5');
              classScope.click(data);
            });

          nodeContainer
            .append('circle')
            .attr('class', 'node-circle')
            .attr('r', 10)
            .attr('fill', '#FE3C4C')

          nodeContainer
            .append('rect')
            .attr('class', 'polygon-positive')
            .attr('width', 10)
            .attr('height', 10)
            .attr('stroke', () => 'white')
            .attr('fill',() => '#FE3C4C')
            .attr('transform', `translate(-5,-5)`)
        }
        if(data.triangle) {
          nodeContainer = nodes
            .append('circle')
            .attr('class', 'node-circle')
            .attr('r', 10)
            .attr('cx', this.x(data.date.getTime()))
            .attr('cy', this.y(node.label))
            .attr('fill', '#9c27b0')

          nodeContainer = nodes
            .append('path')
            .attr('class', 'polygon-positive')
            .attr('d', 'M 0,0 5,-10 10,0 Z')
            .attr('stroke', () => 'white')
            .attr('fill', '#9c27b0')
            .attr('transform', `translate(${this.x(data.date.getTime())-5},${this.y(node.label)+4})`)
            .on('click', function () {
              d3.select(this).attr('style', 'opacity: 0.5');
              classScope.click(data);
            });
        }
      } else  if(data.triangle) {

      }
      this.setExamesTitle(nodes, node, nodeContainer, data);
      nodes
        .append('text')
        .text((d) => data.total)
        .attr('class', 'node-text')
        .attr('x', this.x(data.date) + 7)
        .attr('y', this.y(node.label) - 7);
    }
  }

  /**
   * Define o resumo da bolinha de exames
   * @param nodes
   * @param nodeContainer
   * @param data
   */
  setExamesTitle(nodes, node, nodeContainer, data) {
    let highlights = 0;
    let message = '';
    const componentProps = data.component.props;
    if(componentProps.label === 'Exames'){
      each(componentProps.items, (exame)=> {
        if(exame.highlight){
          message += exame.name + '\n';
          highlights ++;
        }
      })
    }
    if(highlights > 1){
      const highlightText = nodes
        .append('text')
        .text((d) => highlights)
        .attr('class', 'node-text-error')
        .attr('x', this.x(data.date) + 7)
        .attr('y', this.y(node.label) + 15);
    }
    nodeContainer
      .append('title')
      .text(message)
  }


  /**
   * Define a cor de um item do gráfico
   * @param d (objeto do gráfico)
   * @param visited booleano que define se irá retornar a cor com transparência
   * @returns {string}
   */
  color(d, visited = false) {
    if (d.highlight) {
      return visited ? this.fillColorHiglightVisited : this.fillColorHighlight;
    } else {
      return visited ? this.fillColorVisited : this.fillColor;
    }
  }

  /**
   * Evento de click
   * @param node objeto d3 clicado
   */
  click(node) {
    if (node.component) {
      let ToolTipComponent = node.component;
      this.container.node().getBoundingClientRect();

      // Adiciona os dados do gráfico de sinais vitais
      if (node.component.props.label === 'Sinais vitais') {
        // Recria o componente com os novos dados
        ToolTipComponent = React.cloneElement(
          node.component,
          {
            sinaisVitais: this.collection.getSinaisVitaisChartTooltip(ToolTipComponent.props),
          }
        );
      }
      if (node.component.props.label === 'Exames') {
        // Recria o componente com os novos dados
        ToolTipComponent = React.cloneElement(
          node.component,
          {
            exames: this.collection.getLeucocitosChartTooltip(ToolTipComponent.props)
          }
        );
      }
      if (node.component.props.label === 'Evolução') {
        // Recria o componente com os novos dados
        ToolTipComponent = React.cloneElement(
          node.component,
          {
            openModal: true,
            ...node.component.props,
          },
        );
      }
      ReactDOM.render(<Provider store={store}><ThemeProvider theme={isaTheme}>{ToolTipComponent}</ThemeProvider></Provider>, document.getElementById('rootTooltip'));

      const tooltipWidth = 340;
      const left =
        d3.event.pageX + tooltipWidth + 10 > window.innerWidth
          ? d3.event.pageX - tooltipWidth - 10
          : d3.event.pageX + 10;

      const tooltipHeight = 470;
      const top =
        d3.event.pageY + tooltipHeight + 10 > window.innerHeight
          ? window.innerHeight - tooltipHeight - 20
          : d3.event.pageY + 10;

      d3.select('#rootTooltip')
        // .append(node.component)
        .style('left', left + 'px')
        .style('top', top + 'px')
        .node()
        .focus();

      d3.select(document).on('mousedown', (_) => {
        var container = document.getElementById('rootTooltip');
        var containerGraphDialog = document.getElementById('GraphDialog');
        if (container && !container.contains(d3.event.target) && (!containerGraphDialog || !containerGraphDialog.contains(d3.event.target))) {
          this.removeTooltip();
        }
      });
    }
  }

  /**
   * Remove o componente tooltip
   */
  removeTooltip() {
    // const component = ReactDOM.findDOMNode(
    //   document.getElementById('rootTooltip')
    // );
    // ReactDOM.unmountComponentAtNode(component);
  }
}
