import React from 'react';
import { Typography } from '@material-ui/core';
import reactStringReplace from 'react-string-replace';

export const HighlightedWord = (props) => {
  const { color, match } = props;

  return (
    <Typography
      data-keyword-match={match}
      variant="inherit"
      display="inline"
      style={{
        backgroundColor: color,
      }}
    >
      {match}
    </Typography>
  );
};

export const HighlightedContent = React.memo(function MemoHighlightedContent(
  props
) {
  const { textContent, regex, searchWord, aiKeywords } = props;
  return (
    <>
      {reactStringReplace(textContent, regex, (match, i) => (
        <HighlightedWord
          key={'text.highlight.' + i}
          color={
            searchWord === match
              ? '#FB8E6C'
              : aiKeywords.includes(match)
              ? '#16b8a752'
              : '#FFC656'
          }
          match={match}
        />
      ))}
    </>
  );
});
