import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Grid, Divider } from '@material-ui/core';
import moment from 'moment';

import { CollapsibleGridItem } from '../../../atoms/CollapsibleGridItem';
import {
  RadioGroupField,
  SelectField,
  DateField,
  AutocompleteField,
  MultiselectField,
  TextField,
} from '../../../molecules/Form';

import { useGetMicroorganismsQuery } from '../../../../services/microorganisms/microorganisms.service';
import { useAssessmentAnalysisFormContext } from './AssessmentAnalysisFormProvider';
import { assessmentStatusesEnum } from '../../../../constants/assessments';
import {
  infectionTypesEnum,
  noInfection,
  otherInfection,
} from '../../../../constants/infections';
import { otherMicroorganism } from '../../../../constants/microorganisms';
import { MicroorganismsField } from './MicroorganismsField';

export const AssessmentAnalysisFormBody = () => {
  const today = useMemo(() => moment(), []);
  // Opções dos campos
  const {
    suspicions,
    assessmentInfectionOptions,
    microorganismsOptions,
    contaminationPotentialOptions,
    infectionClassificationOptions,
    relatedToCatheterOptions,
    relatedToCVCOptions,
    relatedToSVDOptions,
    suspicionsOptions,
    infectionLocationOptions,
    communityInfectionOptions,
    assessmentStatusOptions,
    assessmentRelatedDeathOptions,
    resistantMicroorganismsOptions
  } = useAssessmentAnalysisFormContext();

  // Valores e estado atuais do formulário
  const { values, setFieldValue } = useFormikContext();
  const {
    assessmentInfection,
    assessmentStatus,
    relatedToCatheter,
    suspicionId,
  } = values;

  const {
    data: microorganisms,
    isFetching: isMicroorganismsOptionsLoading,
  } = useGetMicroorganismsQuery(null, {
    skip: !values.microorganisms
  });

  // Flags utilizadas para show/hide e disabled de campos específicos
  const noCriteria = assessmentInfection === noInfection;

  const isItu = assessmentInfection === infectionTypesEnum.itu;
  const isIpcs = assessmentInfection === infectionTypesEnum.ipcs;
  const isIsc = assessmentInfection === infectionTypesEnum.isc;
  const isOtherInfection = assessmentInfection === otherInfection;

  const relatedToCatheterVisible = isItu || isIpcs;
  const relatedToCVCVisible = relatedToCatheter && isIpcs;
  const relatedToSVDVisible = relatedToCatheter && isItu;

  // Objeto da Suspeita selecionada no campo janela de suspeita
  const selectedSuspicion = useMemo(() => {
    return (suspicions || []).find((s) => s._source.id === suspicionId)
      ?._source;
  }, [suspicions, suspicionId]);

  // Debug
  // useEffect(() => {
  //   console.log(`Form values changed`, values);
  // }, [values]);

  // Valores que mudam de acordo com a seleção do tipo de infeção
  useEffect(() => {
    if (noCriteria) {
      setFieldValue('assessmentStatus', assessmentStatusesEnum.negativa);
      setFieldValue('infectionLocation', '');
    } else {
      setFieldValue('assessmentStatus', assessmentStatusesEnum.positiva);
    }
  }, [noCriteria, setFieldValue]);

  // Seta o valor da data da infecção e do objeto da suspeita de acordo com a janela de suspeita selecionada
  useEffect(() => {
    setFieldValue('selectedSuspicion', selectedSuspicion);
    if (!selectedSuspicion) return;
    const suspicionInfectionDate = moment(selectedSuspicion.dt_infeccao);
    setFieldValue('assessmentDateInfection', suspicionInfectionDate, true);
    setFieldValue('suspicionDateInfecction', suspicionInfectionDate, true);
  }, [selectedSuspicion, setFieldValue]);

  // Sempre que o infection type for comunitária, o comunitária vai receber true
  useEffect(() => {
    setFieldValue(
      'communityInfection',
      assessmentInfection === 'comunitaria',
      true
    );
  }, [setFieldValue, assessmentInfection]);

  return (
    <Grid container spacing={2}>
      <RadioGroupField
        label="Tipo de infecção"
        name="assessmentInfection"
        options={assessmentInfectionOptions}
        grid
      />
      <RadioGroupField
        label="Potencial de contaminação:"
        name="contaminationPotential"
        options={contaminationPotentialOptions}
        hidden={!isIsc}
        grid
      />
      <RadioGroupField
        label="Classificação da Infecção:"
        name="infectionClassification"
        options={infectionClassificationOptions}
        hidden={!isIsc}
        grid
      />
      <TextField
        label="Outra Infecção"
        name="otherInfection"
        placeholder="Infecção"
        grid
        hidden={!isOtherInfection}
      />
      <SelectField
        label="Janela de suspeita"
        name="suspicionId"
        options={suspicionsOptions}
        grid={{ xs: 6 }}
      />
      <DateField
        label="Data da infecção"
        name="assessmentDateInfection"
        maxDate={today}
        disabled={noCriteria}
        grid={{ xs: 6 }}
      />
      <AutocompleteField
        label="Local de infecção"
        name="infectionLocation"
        options={infectionLocationOptions}
        disabled={noCriteria}
        grid
      />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <MicroorganismsField
          name="microorganisms"
          label="Microrganismos"
          options={microorganisms}
          loading={isMicroorganismsOptionsLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ margin: '-8px 0 12px' }} />
      </Grid>
      <RadioGroupField
        label="A infecção está relacionada a cateter/sonda?"
        name="relatedToCatheter"
        options={relatedToCatheterOptions}
        hidden={!relatedToCatheterVisible}
        grid
      />
      <RadioGroupField
        label="A infecção está relacionada a Cateter Venoso Central (CVC)?"
        name="relatedToCVC"
        options={relatedToCVCOptions}
        hidden={!relatedToCVCVisible}
        grid
      />
      <RadioGroupField
        label="A infecção está relacionada a Sonda Vesical de Demora (SVD)?"
        name="relatedToSVD"
        options={relatedToSVDOptions}
        hidden={!relatedToSVDVisible}
        grid
      />
      {[
        relatedToCatheterVisible,
        relatedToCVCVisible,
        relatedToSVDVisible,
      ].some(Boolean) && (
        <Grid item xs={12}>
          <Divider style={{ margin: '12px 0' }} />
        </Grid>
      )}
      <RadioGroupField
        label="Você confirma a infecção?"
        name="assessmentStatus"
        options={assessmentStatusOptions}
        disabled={noCriteria}
        grid
      />
      <TextField
        label="Comentário"
        placeholder="Comentário"
        name="assessmentComment"
        multiline
        rows={10}
        grid
      />
      <RadioGroupField
        label="Óbito relacionado a infecção?"
        name="assessmentRelatedDeath"
        options={assessmentRelatedDeathOptions}
        grid
      />
    </Grid>
  );
};
