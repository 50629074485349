import styled from 'styled-components'

export const Date = styled.p`
    font-family: 'DM Mono';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #8F95A3;
    padding-left: 40px;
    @media (max-width: 960px) {
        margin-bottom: 15px;
    }
`
export const TagTitle = styled.h3`
    font-family: 'DM Mono';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #383A3D;
`

export const TimelineContainer = styled.div`
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const MyNavigation = styled.div`
  .MuiButtonGroup-root {
    max-width: 85px;
  }
`;
