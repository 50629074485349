import React from 'react';
import { DrawerProvider } from '../DrawerBase';
import { DrawerHeader, DrawerPanel } from '../DrawerPanel';

export const GenericDrawer = (props) => {
  const { children, loading, header, title, subToolbar, footer, ...drawerProps } = props;

  return (
    <DrawerProvider {...drawerProps}>
      <DrawerPanel
        loading={loading}
        header={<><DrawerHeader title={title} {...header} />{subToolbar}</>}
        footer={footer}
      >
        {children}
      </DrawerPanel>
    </DrawerProvider>
  );
};
GenericDrawer.defaultProps = {
  width: '480px',
}
