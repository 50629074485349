import { useState, useCallback, useMemo } from 'react';

const initialState = {
  id: undefined,
  author: undefined,
  date: undefined,
  content: undefined,
  editing: false,
};

export const useEditComment = (state = initialState) => {
  const [edit, setEdit] = useState(state);

  const handleStartEdit = useCallback(
    (comment) => {
      setEdit({ ...comment, editing: true });
    },
    [setEdit]
  );

  const handleCancelEdit = useCallback(() => {
    setEdit(initialState);
  }, [setEdit]);

  const result = useMemo(
    () => ({ edit, handleStartEdit, handleCancelEdit }),
    [edit, handleStartEdit, handleCancelEdit]
  );

  return result;
};
