import {
  getInfectionParams,
  setInfectionParams,
} from '../services/api/settings/settings.service';

export const actionTypes = {
  SET_INFECTION_PARAMS: 'SET_INFECTION_PARAMS',
  GET_INFECTION_PARAMS_SUCCESS: 'GET_INFECTION_PARAMS_SUCCESS',
  GET_INFECTION_PARAMS_ERROR: 'GET_INFECTION_PARAMS_ERROR',
  SET_INFECTION_PARAMS_SUCCESS: 'SET_INFECTION_PARAMS_SUCCESS',
  SET_INFECTION_PARAMS_ERROR: 'SET_INFECTION_PARAMS_ERROR',
  SET_SETTINGS_LOADING: 'SET_SETTINGS_LOADING',
};

export const setInfectionParamAction = (infection, range) => ({
  type: actionTypes.SET_INFECTION_PARAMS,
  infection,
  range,
});

export const getInfectionParamsSuccessAction = (infectionParams) => ({
  type: actionTypes.GET_INFECTION_PARAMS_SUCCESS,
  infectionParams,
});

export const getInfectionParamsErrorAction = (error) => ({
  type: actionTypes.GET_INFECTION_PARAMS_ERROR,
  error,
});

export const setLoadingAction = () => ({
  type: actionTypes.SET_SETTINGS_LOADING,
});

export const InfectionParams = (infectionsList) => async (dispatch) => {
  try {
    dispatch(setLoadingAction());
    const result = await getInfectionParams(infectionsList);
    dispatch(getInfectionParamsSuccessAction(result));
  } catch (error) {
    console.log(error);
    dispatch(getInfectionParamsErrorAction(error));
  }
};

export const setInfectionParamsSuccessAction = (infectionParams) => ({
  type: actionTypes.SET_INFECTION_PARAMS_SUCCESS,
  infectionParams,
});

export const setInfectionParamsErrorAction = (error) => ({
  type: actionTypes.SET_INFECTION_PARAMS_ERROR,
  error,
});

export const saveInfectionParams = (infectionsList) => async (dispatch) => {
  try {
    const result = await setInfectionParams(infectionsList);
    dispatch(setInfectionParamsSuccessAction(result));
  } catch (error) {
    console.log(error);
     dispatch(setInfectionParamsErrorAction(error));
  }
};
