import React, { useCallback, useState, useEffect } from 'react';
import { TextField, Box, Button, withStyles } from '@material-ui/core';
import { Dialog, DialogToolbar } from '../../../molecules/Dialog';

const CommentTextField = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      padding: '12px',
      backgroundColor: theme.palette.neutral.gray.xxlt,
      borderRadius: '8px',
      fontFamily: 'DM Mono',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '15px',
    },
  },
}))(TextField);

export const CommentsForm = (props) => {
  const {
    title,
    open,
    onCancel,
    onSubmit,
    submitLabel,
    cancelLabel,
    placeholder,
    initialMessage,
    isSubmitting,
    isError,
  } = props;
  const [message, setMessage] = useState(initialMessage);

  useEffect(() => {
    setMessage(initialMessage);
  }, [initialMessage, setMessage]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(message);
    },
    [onSubmit, message]
  );

  const handleChange = useCallback(
    (e) => setMessage(e.target.value),
    [setMessage]
  );

  const handleCancel = useCallback(
    (e) => {
      e.preventDefault();
      setMessage(initialMessage)
      onCancel();
    },
    [onCancel, setMessage, initialMessage]
  );

  return (
    <Dialog
      loading={isSubmitting}
      open={open}
      onClose={onCancel}
      title={title}
      PaperProps={{ component: 'form', onSubmit: handleSubmit }}
      footer={
        <DialogToolbar>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancel}
            >
              {cancelLabel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting || !message || message === initialMessage}
            >
              {submitLabel}
            </Button>
          </Box>
        </DialogToolbar>
      }
    >
      <Box px="20px">
        <CommentTextField
          disabled={isSubmitting}
          placeholder={placeholder}
          value={message}
          onChange={handleChange}
          name="message"
          rows={10}
          variant="filled"
          error={!!isError}
          helperText={isError && 'Ocorreu um erro'}
          InputProps={{
            disableUnderline: true,
          }}
          multiline
          fullWidth
        />
      </Box>
    </Dialog>
  );
};
CommentsForm.defaultProps = {
  title: 'Adicionar comentário',
  submitLabel: 'Adicionar',
  cancelLabel: 'Cancelar',
  placeholder: 'Comentário',
  initialMessage: '',
};
