import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { any, array, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { Title, Header } from '../styles';
import PacientesTemplate from '../../../templates/Pacientes';
import DateRangeInput from '../../../components/molecules/DateRangeInput/index';
import TopFilter from '../../../components/organisms/TopFilter';
import PatientCardList from '../../../components/organisms/PatientCardList';
import { FilterSidebar } from '../../../components/organisms/FilterSidebar';
import { FilterGroup } from '../../../templates/Pacientes/styles';
import { getPendingAssessments } from '../../../actions/PendingAssessmentsActions';
import {
  setProbabilityFilterAction,
  setInfectionFilterAction,
  setOnlyInconclusiveFilterAction,
  setOrderByFilterAction,
  resetFiltersAction,
  setPendingAssessmentsPeriodDate,
} from '../../../actions/PendingAssessmentsFiltersActions';
import EvaluationTooltip from '../../../components/atoms/EvaluationTooltip';
import { setDashboardPeriodDate } from '../../../actions/DashboardPeriodFilterActions';
import { useGetInfectionTypesQuery } from '../../../services';
import { useGetPatientSuspicionsQuery } from '../../../services/patientSuspicions/patientSuspicions.service';
import { DateRangeTemplate } from '../../../templates/DateRangeTemplate';
import DataTable from '../../../components/organisms/DataTable/DataTable';
import { Badge, Button } from '../../../components/organisms/DataTable/styles';
import { PatientColumnsDef } from './PatientColumnDef';
import { DataTableHeader } from '../../../components/organisms/DataTable/DataTableHeader';
import { DataTableHeaderToggleGroup } from '../../../components/organisms/DataTable/DataTableHeaderToggleGroup';
import { useMemo } from 'react';
import { useToggleState } from '../../../utils/useToggleState';
import { Drawer } from '../../../components/molecules/Drawer';
import { Box } from '@material-ui/core';
import { CheckboxField } from '../../../components/molecules/Form';
import { infectionTypesEnum } from '../../../constants/infections';
import { FilterIcon } from './FilterIcon';
import { PatientsFilterDrawer } from './PatientsDrawer';
import {
  usePatientsFilters,
  usePatientsFiltersCount,
  usePatientsEvaluated,
  useSetPatientsEvaluated,
  useSetPatientsItemsPerPage,
  useSetPatientsOrderBy,
  useSetPatientsName,
  useSetPatientsPage,
} from './slices';
import { withStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DataTableFilterBtn } from '../../../components/organisms/DataTable/DataTableFilterBtn';
import { sortedUniq } from 'lodash';
import { PatientsCreateDrawer } from './PatientCreateDrawer';
import { DataTableCreatePatientBtn } from '../../../components/organisms/DataTable/DataTableCreatePatientBtn';

import {LoggedUserCompaniesForm} from "../../../components/organisms/LoggedUserCompaniesForm"

const ActionComponent = ({ isLoading }) => {
  const { open: filterOpen, handleClose: handleCloseFilter, handleOpen: handleOpenFilter } = useToggleState();
  const { open: createOpen, handleClose: handleCloseCreate, handleOpen: handleOpenCreate } = useToggleState();
  const filterCount = usePatientsFiltersCount();
  return (
    <>
      <Box display="flex" flexDirection="row">
        <DataTableFilterBtn onClick={handleOpenFilter} filterCount={filterCount} />
        <Box ml="auto">
          <DataTableCreatePatientBtn onClick={handleOpenCreate} />
        </Box>
      </Box>
      <PatientsFilterDrawer
        open={filterOpen}
        onClose={handleCloseFilter}
        isLoading={isLoading}
      />
      <PatientsCreateDrawer
        open={createOpen}
        onClose={handleCloseCreate}
        isLoading={isLoading}
      />
    </>
  );
};

const EVALUATION_RULES = [
  'Não possui nenhuma avaliação;',
  'Robô ISA identificou uma ou mais suspeitas, mas todas elas ainda não foram avaliadas;',
  // 'A mesma já estiver avaliada. Será necessário excluir a avaliação para depois avaliar;'
];

const Patients = (props) => {
  const patientsEvaluated = usePatientsEvaluated();
  const setPatientsEvaluated = useSetPatientsEvaluated();
  const patientsFilters = usePatientsFilters();
  const handleSearch = useSetPatientsName();
  const setPatientsPage = useSetPatientsPage();
  const setOrderBy = useSetPatientsOrderBy();
  const handlePageSizeChange = useSetPatientsItemsPerPage();
  const history = useHistory();

  const {
    data: patientSuspicions,
    isLoading: patientSuspicionsLoading,
    isFetching: patientSuspicionsFetching,
  } = useGetPatientSuspicionsQuery(patientsFilters);

  const isLoading = patientSuspicionsLoading || patientSuspicionsFetching;

  const handlePagination = useCallback(
    (currentPage) => {
      setPatientsPage(currentPage + 1)
    },
    [setPatientsPage]
  );

  const toggleGroupOptions = useMemo(
    () => [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Não avaliadas',
        value: 'nao-avaliadas',
      },
      {
        label: 'Avaliadas',
        value: 'avaliadas',
      },
    ],
    []
  );

  const results = useMemo(() => patientSuspicions?.results || [], [
    patientSuspicions,
  ]);
  const idList = useMemo(
    () => sortedUniq(results.map(({ paciente_id }) => parseInt(paciente_id))),
    [results]
  );
  const handleRowClick = useCallback(
    ({ row }) => {
      const patientId = row.paciente_id;
      if (!!patientId) {
        history.push({
          pathname: `/paciente/${patientId}`,
          state: { idList },
        });
      }
    },
    [history, idList]
  );
  const handleSortModelChange = (model) => {
    if (model.length > 0) {
      setOrderBy(model[0].field + ' ' + model[0].sort);
      return;
    }
    setOrderBy('');
  }


  return (
    <DateRangeTemplate
      title="Suspeitas"
      info={`Pendente: ${EVALUATION_RULES.join('')}`}
      extraActions={[<LoggedUserCompaniesForm></LoggedUserCompaniesForm>]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box height="90vh">
            <DataTable
              rows={results}
              columns={PatientColumnsDef}
              loading={isLoading}
              pagination={patientSuspicions?._metadata}
              onPageChange={handlePagination}
              onRowClick={handleRowClick}
              onPageSizeChange={handlePageSizeChange}
              onSortModelChange={handleSortModelChange}
              onCellClick={console.log}
              header={
                <DataTableHeader
                  onSearch={handleSearch}
                  toggleGroup={
                    <DataTableHeaderToggleGroup
                      options={toggleGroupOptions}
                      value={patientsEvaluated}
                      onChange={setPatientsEvaluated}
                    />
                  }
                  action={<ActionComponent isLoading={isLoading} />}
                />
              }
            />
          </Box>
        </Grid>
      </Grid>
    </DateRangeTemplate>
  );
};

Patients.propTypes = {
  infectionTypes: array,
  patientList: array,
  initialDate: any,
  finalDate: any,
  highProbability: bool,
  mediumProbability: bool,
  lowProbability: bool,
  onlyInconclusive: bool,
  getPendingAssessmentList: func,
  updatePendingAssessmentsInitialDate: func,
  updatePendingAssessmentsFinalDate: func,
  updateProbabilityFilter: func,
  updateInfectionFilter: func,
  updatedOnlyInconclusiveFilter: func,
  updateOrderByFilter: func,
  resetFilters: func,
  infections: array,
};

// initialDate
// finalDate
// highProbability
// mediumProbability
// lowProbability
// onlyInconclusive
// orderByField
// infections

const mapStateToProps = (state) => ({
  // TODO remover reducers não utilizadas
  // initialDate: state.periods.dashboard.initialDate,
  patientList: state.pendingAssessments.list,
  initialDate: state.periods.dashboard.initialDate,
  finalDate: state.periods.dashboard.finalDate,
  highProbability: state.pendingAssessmentsFilter.highProbability,
  mediumProbability: state.pendingAssessmentsFilter.mediumProbability,
  lowProbability: state.pendingAssessmentsFilter.lowProbability,
  onlyInconclusive: state.pendingAssessmentsFilter.onlyInconclusive,
  orderByField: state.pendingAssessmentsFilter.orderByField,
  infections: state.pendingAssessmentsFilter.infections,
  // globalStateInfections: state.pendingAssessmentsFilter.infections,
  // globalStateOnlyInconclusive: state.pendingAssessmentsFilter.onlyInconclusive,
  // globalStateOrderBy: state.pendingAssessmentsFilter.orderByField,
});

const mapDispatchToProps = (dispatch) => ({
  getPendingAssessmentList: (filterParams) =>
    dispatch(getPendingAssessments(filterParams)),

  setInitialDate: (date, initial) =>
    dispatch(setDashboardPeriodDate(date, initial)),
  setFinalDate: (date, initial = false) =>
    dispatch(setDashboardPeriodDate(date, initial)),
  // TODO remover actions não utilizadas
  updatePendingAssessmentsInitialDate: (date) =>
    dispatch(setPendingAssessmentsPeriodDate(date)),
  // TODO remover actions não utilizadas
  updatePendingAssessmentsFinalDate: (date) =>
    dispatch(setPendingAssessmentsPeriodDate(date, false)),

  updateProbabilityFilter: (probabilities) =>
    dispatch(setProbabilityFilterAction(probabilities)),

  updateInfectionFilter: (infections) =>
    dispatch(setInfectionFilterAction(infections)),

  updatedOnlyInconclusiveFilter: (onlyInconclusive) =>
    dispatch(setOnlyInconclusiveFilterAction(onlyInconclusive)),

  updateOrderByFilter: (fieldName) =>
    dispatch(setOrderByFilterAction(fieldName)),

  resetFilters: () => dispatch(resetFiltersAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
