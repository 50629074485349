import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import {string} from "prop-types";
import {ISACard} from "./styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const ISACardComponent = (props) => {
  const { loading } = props;
  return (
    <ISACard>
      <Card variant="outlined">
        { loading ? <LinearProgress /> : '' }
        <CardHeader title={props.title}/>
        <CardContent>
          {props.children}
        </CardContent>
      </Card>
    </ISACard>
  )
};

ISACardComponent.propTypes = {
  title: string.isRequired,
};

export default ISACardComponent;
