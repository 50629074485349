import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setTimelineCursor } from '../slice';

export const useSetTimelineCursor = () => {
  const dispatch = useDispatch();

  const handleSetCursor = useCallback(
    ({ indicator, date }) => dispatch(setTimelineCursor({ indicator, date })),
    [dispatch]
  );

  return handleSetCursor;
};
