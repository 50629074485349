import React from 'react';
import { Box, withStyles } from '@material-ui/core';
import { useTimelineCursor } from '../../slices';
import { SelectIndicator } from './SelectIndicator';
import { DateNavigation } from './DateNavigation';

const SelectedDate = withStyles((theme) => ({
  root: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '17px',
    color: theme.palette.neutral.black['72'],
  },
}))(Box);

export const TimelineDrawerNavigation = () => {
  const { date } = useTimelineCursor();

  return (
    <Box display="flex">
      <Box flex="1 0 auto">
        <SelectIndicator />
        <SelectedDate>{date.format('DD/MM/YYYY')}</SelectedDate>
      </Box>
      <Box flex="0 0 auto">
        <DateNavigation />
      </Box>
    </Box>
  );
};
