import assessmentsHistoryRequestBody from "./query";
import {sendRequest, httpClient} from "../api";
import {keycloakConnect} from "../../../keycloak";

const ENDPOINT = `suspeitas/_search?filter_path=_scroll_id,hits.hits._source,hits.total&scroll=1m`
const ASSESSMENT_ENDPOINT = `/elk/avaliacao/`

/**
 * Returns the list of patients which has assessments on a given date
 *
 * @param filterParams
 * @returns {Promise<void>}
 */
export async function getAssessmentsHistory(filterParams) {
  const token = keycloakConnect.token

  return await sendRequest({
    endpoint: ENDPOINT,
    data: assessmentsHistoryRequestBody({...filterParams}),
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
}

export async function removeAssessment(assessmentId) {
  const token = keycloakConnect.token
  return httpClient.delete(ASSESSMENT_ENDPOINT, {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: {
      id: assessmentId
    },
  })
}
