import React, { useMemo, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { RadioGroup } from '@material-ui/core';
import { withFieldControl } from '../FieldControl';
import { withFormikField } from '../utils';
import { RadioOption } from './RadioOption';

export const BaseRadioGroupInput = (props) => {
  const { options, ...radioGroupProps } = props;

  const renderedOptions = useMemo(
    () =>
      (options || []).map((option) => (
        <RadioOption key={option.value} {...option} />
      )),
    [options]
  );

  return <RadioGroup {...radioGroupProps}>{renderedOptions}</RadioGroup>;
};
BaseRadioGroupInput.defaultProps = {
  row: true,
};

// Campo com FormControl (label, helperText, disabled)
export const RadioGroupInput = withFieldControl(BaseRadioGroupInput);
RadioGroupInput.defaultProps = {
  margin: 'none',
};

// Componente pronto para utilização como formik field
export const RadioGroupField = withFormikField(
  React.forwardRef((props, ref) => {
    const { setFieldValue, validateOnChange } = useFormikContext();
    const { name, value, onChange } = props;

    const handleChange = useCallback(
      (e, value) => {
        if (typeof value === 'string' && ['true', 'false'].includes(value)) {
          setFieldValue(name, value === 'true', validateOnChange);
        } else {
          onChange(e, value);
        }
      },
      [setFieldValue, onChange, name, validateOnChange]
    );

    return (
      <RadioGroupInput
        {...props}
        value={typeof value === 'undefined' ? '' : value}
        onChange={handleChange}
        ref={ref}
      />
    );
  })
);
