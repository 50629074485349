import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& p': {
      flexDirection: 'column',
      color: '#000000',
      alignItems: 'baseline',
      fontSize: '16px',
      itemContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }
    }
  },
  dialogButton: {
    textTransform: 'uppercase',
    position: 'absolute',
    top: '20px',
    right: '10px',
  },
  vrDialogButton: {
    textTransform: 'uppercase',
    position: 'absolute',
    top: '20px',
    right: '150px',
  },
  itemTitle: {
    fontFamily: 'DM Mono',
    fontWeight: '500',
    fontSize: '14px',
    margin: '20px 0px 10px'
  },
  itemText: {
    fontFamily: 'DM Mono',
    fontWeight: '400',
    fontSize: '13px'
  },
  itemContainer: {
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '10px',
      '& svg': {
        marginRight: '10px'
      },
      '& ul': {
        listStyle: 'none',

      }
    }
  },
  tableStyle: {
    'border': 'none'
  },
  tableRow: {
    'border-bottom': '1px solid rgba(0,0,0,0.05)',
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    }
  },
  tableHead: {
    background: '#F5F5F5',
  },
  itemContainerVr: {
    '& + &': { marginTop: 20 },
    '& > p:first-child': {
      color: '#383A3D',
      marginRight: 5,
      fontWeight: 'bold'
    },
    '& > p:last-child': {
      color: '#8F95A3',
    }
  }
}));

export default useStyles;
