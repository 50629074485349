import React, { useMemo } from 'react';
import {
  SideSheetDrawer,
  BottomSheetDrawer,
} from './DrawerVariants';

export const drawerVariantMap = {
  side: SideSheetDrawer,
  bottom: BottomSheetDrawer,
};
export const getDrawerComponent = (variant) =>
  drawerVariantMap[variant] || drawerVariantMap.side;

export const Drawer = (props) => {
  const { variant, ...drawerProps } = props;
  const DrawerVariantComponent = useMemo(
    () => getDrawerComponent(variant),
    [variant]
  );

  return <DrawerVariantComponent {...drawerProps} />;
};
Drawer.defaultProps = {
  variant: 'side',
};
