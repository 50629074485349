import styled from "styled-components";

export const ISACard = styled.div`
  .MuiCard-root {
    overflow: hidden;
    position: relative;
  }
  .MuiLinearProgress-root {
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
  .MuiCard-root {
    background-color: #F5F5F5;
    border-radius: 8px;
    border: none;
  }
  .MuiCardHeader-root {
    border-bottom: 1px solid rgba(143, 149, 163, 0.32);
  }
  .MuiCardHeader-root .MuiTypography-root {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    font-family: 'Nunito',serif;
    /* Gray/Dark */

    color: #383A3D;
  }
`;
