import { withStyles, alpha } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

export const StatIcon = withStyles((theme) => ({
  root: {
    width: '40px',
    height: '40px',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: '1em',
      color: 'currentColor',
    },
  },
  // Size variants
  small: {
    fontSize: 16,
  },
  large: {
    fontSize: 20,
  },
  // Color variants
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  info: {
    color: '#47ABE2',
    backgroundColor: alpha('#47ABE2', 0.1),
  },
  high: {
    color: theme.palette.timeline.probability['500'],
    backgroundColor: alpha(theme.palette.timeline.probability['500'], 0.1),
  },
  med: {
    color: theme.palette.timeline.probability['300'],
    backgroundColor: alpha(theme.palette.timeline.probability['300'], 0.1),
  },
  low: {
    color: theme.palette.timeline.probability['100'],
    backgroundColor: alpha(theme.palette.timeline.probability['100'], 0.1),
  },
}))(function StyledStatIcon({ classes, className, children, color, size }) {
  return (
    <div
      className={clsx(
        classes.root,
        classes[color] || classes.primary,
        classes[size] || classes.large,
        className
      )}
    >
      {children}
    </div>
  );
});
StatIcon.defaultProps = {
  color: 'primary',
  size: 'large',
};
