import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setProbabilities } from '../slice';

export const useSetPatientsProbabilities = () => {
  const dispatch = useDispatch();

  const handleSetPatientsProbabilities = useCallback(
    (probabilities) => dispatch(setProbabilities(probabilities)),
    [dispatch]
  );

  return handleSetPatientsProbabilities;
};
