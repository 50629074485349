import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { ExtractedCriteriaItem } from './ExtractedCriteriaItem';

export const ExtractedCriteriaList = (props) => {
  const { onClick, items } = props;

  return (
    <>
      {items.map(({ key: criteria, value }, index) => {
        return (
          <React.Fragment key={criteria}>
            {index !== 0 && (
              <Divider style={{ marginLeft: '24px', marginRight: '8px' }} />
            )}
            <ExtractedCriteriaItem
              criteria={criteria}
              value={value}
              onClick={onClick}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
