import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setEvaluator } from '../slice';

export const useSetHistoryEvaluator = () => {
  const dispatch = useDispatch();

  const handleSetHistoryEvaluator = useCallback(
    (evaluator) => dispatch(setEvaluator(evaluator)),
    [dispatch]
  );

  return handleSetHistoryEvaluator;
};
