import React, { useMemo } from 'react';
import { Button } from '@material-ui/core';
import CsvDownloader from 'react-csv-downloader';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useGetMicroorganismsQuery } from '../../../services/microorganisms/microorganisms.service';

const DownloadIcon = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7937 11.3333V12.6666C14.6346 12.6666 16.127 11.1742 16.127 9.33329C16.127 7.6566 14.889 6.26904 13.2773 6.03479C12.7103 4.07037 10.8994 2.66663 8.79366 2.66663C7.22928 2.66663 5.79967 3.44334 4.94025 4.70014C2.97168 4.9556 1.46033 6.63914 1.46033 8.66663C1.46033 10.6486 2.90185 12.2939 4.79366 12.6113V11.2493C3.64351 10.9533 2.79366 9.90919 2.79366 8.66663C2.79366 7.23359 3.92655 6.05806 5.35343 6.00205L5.72077 5.98763L5.9046 5.66928C6.49653 4.64425 7.58859 3.99996 8.79366 3.99996C10.4277 3.99996 11.8139 5.18413 12.081 6.7774L12.1762 7.34541L12.752 7.33372C12.7728 7.33336 12.7728 7.33336 12.7937 7.33329C13.8982 7.33329 14.7937 8.22872 14.7937 9.33329C14.7937 10.4379 13.8982 11.3333 12.7937 11.3333ZM8.12699 7.99996V12.1688L6.5984 10.6728L5.65559 11.5955L8.79366 14.6666L11.9317 11.5955L10.9889 10.6728L9.46033 12.1688V7.99996H8.12699Z"
      fill="#16B8A7"
    />
  </svg>
);

const cols = [
  {
    id: 'nome',
    displayName: 'Nome',
  },
  {
    id: 'id',
    displayName: 'Número do Atendimento',
  },
  {
    id: 'paciente_id',
    displayName: 'Prontuário',
  },
  {
    id: 'dtInfeccao',
    displayName: 'Data da infecção',
  },
  {
    id: 'tipoInfeccao',
    displayName: 'Infecção',
  },
  {
    id: 'localInfeccao',
    displayName: 'Setor/Unidade',
  },
  {
    id: 'status',
    displayName: 'Status',
  },
  {
    id: 'relacionadoCateterSonda',
    displayName: 'Relacionada a cateter/sonda',
  },
  {
    id: 'relacionado',
    displayName: 'Relacionada a Sonda Vesical de Demora (SVD)',
  },
  {
    id: 'microorganismos',
    displayName: 'Microorganismos'
  },
  {
    id: 'comentario',
    displayName: 'Observações'
  }
];

export const DownloadCsv = ({ results, disabled }) => {
  const {
    data: microorganisms,
  } = useGetMicroorganismsQuery();

  const assessments = useMemo(() => {
    if (!results) return null;

    return cloneDeep(results).map((item) => {
      if (item.localInfeccao === 'undefined' || item.localInfeccao === '') {
        item.localInfeccao = '-';
      }

      if (item.relacionado) {
        item.relacionado = 'Sim';
      } else {
        item.relacionado = 'Não';
      }

      if (item.relacionadoCateterSonda) {
        item.relacionadoCateterSonda = 'Sim';
      } else {
        item.relacionadoCateterSonda = 'Não';
      }

      item.comentario = item.comentario.replace(/(\r\n|\n|\r)/gm, "").trim();
      item.paciente_id = item.paciente?.id || '';
      item.nome = item.paciente?.name || '';
      item.dtInfeccao = moment(item.dtInfeccao).format('DD/MM/YYYY');

      if (item.status === 'positiva') {
        item.status = 'confirmada';
      }
      if (item.status === 'negativa') {
        item.status = 'não confirmada';
      }

      item.microorganismos = item.microorganismos?.reduce((acc, item) => {
        const microorganism = microorganisms?.find(microorganism => microorganism.value === item.microorganismo_id);
        const isResistantMicroorganism = () => {
          if (item.resistente == 'true') return ', Resistente: sim'
          if (item.resistente == 'false') return ', Resistente: não'
          return '';
        }

        return acc.concat(`Microorganismo: ${microorganism?.label}${isResistantMicroorganism()}. `)
      }, '')

      return item;
    });
  }, [results, microorganisms]);

  const csvDate = useMemo(() => new Date(), [assessments]);

  const ButtonComponent = useMemo(() => {
    return (btnProps) => {
      return (
        <CsvDownloader
          extension=".csv"
          columns={cols}
          datas={assessments || []}
          separator=";"
          filename={`ROBOISA_avaliacoes_${csvDate}`}
          {...btnProps}
        />
      );
    };
  }, [assessments, csvDate]);

  return (
    <Button
      color="primary"
      variant="outlined"
      component={ButtonComponent}
      startIcon={<DownloadIcon />}
      style={{ marginLeft: 10 }}
      disabled={disabled || !assessments?.length}
    >
      Download
    </Button>
  );
};
