import React from 'react';
import { indicatorsEnum } from '../../../../../constants/indicators';
import { SquareEntry } from '../SquareEntry';
import { IndicatorEntryAntimicrobianos } from './IndicatorEntryAntimicrobianos';
import { IndicatorEntryEvolucao } from './IndicatorEntryEvolucao';
import { IndicatorEntryExames } from './IndicatorEntryExames';
import { IndicatorEntryInfeccoes } from './IndicatorEntryInfeccoes';
import { IndicatorEntryLaudos } from './IndicatorEntryLaudos';
import { IndicatorEntryProcedimentos } from './IndicatorEntryProcedimentos';
import { IndicatorEntrySinaisVitais } from './IndicatorEntrySinaisVitais';
import { IndicatorEntrySuspeitas } from './IndicatorEntrySuspeitas';

export const indicatorEntryComponentMap = {
  [indicatorsEnum['suspeitas']]: IndicatorEntrySuspeitas,
  [indicatorsEnum['sinais-vitais']]: IndicatorEntrySinaisVitais,
  [indicatorsEnum['procedimentos']]: IndicatorEntryProcedimentos,
  [indicatorsEnum['laudos']]: IndicatorEntryLaudos,
  [indicatorsEnum['infeccoes']]: IndicatorEntryInfeccoes,
  [indicatorsEnum['exames']]: IndicatorEntryExames,
  [indicatorsEnum['encontros']]: IndicatorEntryEvolucao,
  [indicatorsEnum['medicamentos']]: IndicatorEntryAntimicrobianos,
};

export const IndicatorEntry = ({ indicator, date, onClick }) => {
  const EntryComponent = indicatorEntryComponentMap[indicator] || SquareEntry;

  return (
    <EntryComponent
      indicator={indicator}
      date={date}
      onClick={onClick}
    />
  );
};
