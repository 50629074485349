import { formatProbability, formatProbabilityLevel } from '../../../../../../utils/formatters';

export function formatItems(infections, settings) {
  return [
    {
      label: 'Geral',
      value: formatProbability(infections.prob_perc),
      level: formatProbabilityLevel(settings, infections.prob_perc),
    },
    {
      label: 'Traqueo',
      value: formatProbability(infections.traqueo),
      level: formatProbabilityLevel(settings, infections.traqueo),
    },
    {
      label: 'PNM',
      value: formatProbability(infections.pnm),
      level: formatProbabilityLevel(settings, infections.pnm),
    },
    {
      label: 'PAV',
      value: formatProbability(infections.pav),
      level: formatProbabilityLevel(settings, infections.pav),
    },
    {
      label: 'ITU',
      value: formatProbability(infections.itu),
      level: formatProbabilityLevel(settings, infections.itu),
    },
    {
      label: 'ISC',
      value: formatProbability(infections.isc),
      level: formatProbabilityLevel(settings, infections.isc),
    },
    {
      label: 'IPCS',
      value: formatProbability(infections.ipcs),
      level: formatProbabilityLevel(settings, infections.ipcs),
    },
  ];
}
