import React, { useCallback, useRef } from 'react';

import {
  useGetAssessmentsListQuery,
  useGetPatientQuery,
  useUpdateAssessmentAnalysisMutation,
} from '../../../services';

import { AssessmentAnalysisDrawer } from './AssessmentAnalysisDrawer';
import { AssessmentAnalysisFormProvider } from './AssessmentAnalysisForm';

export const AssessmentAnalysisEdit = (props) => {
  const { patientId, open, handleClose, assessment } = props;
  const { data: patient, isLoading: isLoadingPatient } = useGetPatientQuery(
    patientId,
    {
      skip: !patientId,
    }
  );

  const { refetch: refetchAssessmentList } = useGetAssessmentsListQuery(
    patientId,
    { skip: true }
  );

  // Mutation
  const lastMutation = useRef(undefined);

  // Mutation para edição da avaliação
  const [updateAssessmentAnalysis, updateAssessmentAnalysisState] =
    useUpdateAssessmentAnalysisMutation();

  const {
    isLoading,
    isSuccess,
    isError,
    data: successResponse,
    error: errorResponse,
    startedTimeStamp: requestTimestamp,
  } = updateAssessmentAnalysisState;

  // Debug
  // useEffect(() => {
  //   console.log(postAssessmentAnalysisState);
  // }, [postAssessmentAnalysisState]);

  // Cancelamento da requisição
  const handleCancel = useCallback(() => {
    if (lastMutation.current) lastMutation.current.abort();
  }, [lastMutation]);

  // Finaliza e reseta os estados de sucesso/resposta e erro
  const handleResetMutation = useCallback(() => {
    if (lastMutation.current) lastMutation.current.unsubscribe();
  }, [lastMutation]);

  // Reseta estado da mutation quando fecha a modal. (o estado do formulário persiste)
  // useEffect(() => {
  //   if (!drawerOpen) handleResetMutation();
  // }, [drawerOpen, handleResetMutation]);

  const handleEdit = useCallback(
    async (values) => {
      const mutation = updateAssessmentAnalysis({
        patient,
        ...values,
        assessmentId: assessment.id,
      });
      // Guardamos a mutation para chamá-la depois caso o usuário cancele ou finalize a submissão
      lastMutation.current = mutation;
      const result = await mutation;
      if (!result.error) {
        handleClose();
        refetchAssessmentList();
      }
    },
    [
      updateAssessmentAnalysis,
      refetchAssessmentList,
      handleClose,
      patient,
      assessment,
    ]
  );

  return (
    <AssessmentAnalysisFormProvider
      patientId={patientId}
      onCancel={handleCancel}
      onFinish={handleResetMutation}
      requestTimestamp={requestTimestamp}
      isLoading={isLoading}
      isSuccess={isSuccess}
      successResponse={successResponse}
      errorResponse={errorResponse}
      isError={isError}
      assessment={assessment}
      isEditing={true}
      onEdit={handleEdit}
    >
      <AssessmentAnalysisDrawer open={open} onClose={handleClose} />
    </AssessmentAnalysisFormProvider>
  );
};
