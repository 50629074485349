import React, { useLayoutEffect, useRef } from 'react';
import D3VerticalBarChart from './D3VerticalBarChart';
import { VerticalBarChartContainer } from './VerticalBarChartContainer';

export const VerticalBarChart = (props) => {
  const { isLoading, dataset, ...rest } = props;
  const refChart = useRef();

  useLayoutEffect(() => {
    if (!refChart.current) return;

    const chart = new D3VerticalBarChart({
      container: refChart.current,
      dataset,
      isLoading,
    });

    const updateSize = () => chart.changeSizes();
    updateSize();

    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [isLoading, refChart, dataset]);

  return <VerticalBarChartContainer ref={refChart} {...rest} />;
};
