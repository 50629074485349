import React, { useMemo } from 'react';
import { useSelectedCompetence } from '../../slices/selectors'
import { VerticalBarChart } from '../VerticalBarChart';
import { getIndicatorValue } from '../../../../../utils/formatters/formatDashboardIndicators'

import { useTheme } from '@material-ui/core';

const randomValue = () => Math.floor(Math.random() * 10 * (Math.random() * 20));

export const SuspicionsByInfection = ({ indicators }) => {
  const competence = useSelectedCompetence();

  const theme = useTheme();
  const isLoading = false;
  const dataset = useMemo(
    () => [
      {
        label: 'ALTA',
        color: theme.palette.timeline.probability.ALTA,
        values: [
          {
            label: 'IPCS',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_IPCS_alta', competence),
          },
          {
            label: 'ISC',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_ISC_alta', competence),
          },
          {
            label: 'ITU',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_ITU_alta', competence),
          },
          {
            label: 'PAV',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_PAV_alta', competence),
          },
          {
            label: 'PNM',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_PNM_alta', competence),
          },
          {
            label: 'TRAQUEO',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_TRAQUEO_alta', competence),
          },
        ],
      },
      {
        label: 'MÉDIA',
        color: theme.palette.timeline.probability.MEDIA,
        values: [
          {
            label: 'IPCS',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_IPCS_media', competence),
          },
          {
            label: 'ISC',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_ISC_media', competence),
          },
          {
            label: 'ITU',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_ITU_media', competence),
          },
          {
            label: 'PAV',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_PAV_media', competence),
          },
          {
            label: 'PNM',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_PNM_media', competence),
          },
          {
            label: 'TRAQUEO',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_TRAQUEO_media', competence),
          },
        ],
      },
      {
        label: 'CONFIRMADAS',
        type: 'line',
        color: '#2BAC76',
        values: [
          {
            label: 'IPCS',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_IPCS_confirmadas', competence),
          },
          {
            label: 'ISC',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_ISC_confirmadas', competence),
          },
          {
            label: 'ITU',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_ITU_confirmadas', competence),
          },
          {
            label: 'PAV',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_PAV_confirmadas', competence),
          },
          {
            label: 'PNM',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_PNM_confirmadas', competence),
          },
          {
            label: 'TRAQUEO',
            value: getIndicatorValue(indicators, 'suspeitas_por_tipo_TRAQUEO_confirmadas', competence),
          },
        ],
      },
    ],
    [theme, competence, indicators]
  );

  return (
    <VerticalBarChart
      isLoading={isLoading}
      dataset={dataset}
      aspectRatio={2.01}
      // height={200}
    />
  );
};
