import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import { Box, Button } from '@material-ui/core';
import { Drawer, DrawerToolbar } from '../../../components/molecules/Drawer';
import {
  TextField,
  DateField,
  RadioGroupField
} from '../../../components/molecules/Form';

import { DangerButtonOutline } from '../../../components/atoms/DangerButtonOutline';
import moment from 'moment';
import { usePostCreatePatientMutation } from '../../../services';
import { PatientCreateFormProvider } from './PatientCreateFormProvider';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const sex = [
  { label: "Masculino", value: "M" },
  { label: "Feminino", value: "F" }
]

export const PatientsCreateDrawer = ({ open, onClose, isLoading: loadingPatient }) => {
  const [isError, setIsError] = useState({
    open: false,
    message: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [valueForm, setValuesForm] = useState();
  const [createPatientMutation, createPatientMutationState] = usePostCreatePatientMutation();
  const history = useHistory();

  const isCreating = createPatientMutationState.isLoading || isLoading || loadingPatient;

  const handleSubmit = async (data, actions) => {
    setValuesForm(data);
    setIsError({
      open: false,
      message: ""
    });

    const todayAge = moment().diff(data?.birthDate, 'years')

    const payload = [data].map(item => ({...item, todayAge}))[0]

    const response = await createPatientMutation(payload);

    if (response?.data) {
      setIsLoading(true)
      setTimeout(() => {
        onClose();
        history.push({
          pathname: `/paciente/${data.id}`
        });
        setIsLoading(false)
      }, 3000)
      } else {
        setIsError({
          open: true,
          message: response?.error?.data?.message || 'Erro interno do servidor'
        });
      }
      actions.setSubmitting(false);
  }

  const handleReset = async () => {
    setValuesForm({
      id: "",
      name: "",
      sex: sex[0].value,
      birthDate: moment()
    })
  }

  return (
    <>
      <Formik
        initialValues={{
          id: valueForm?.id || "",
          name: valueForm?.name || "",
          sex: valueForm?.sex || sex[0].value,
          birthDate: valueForm?.birthDate || moment()
        }}
        validationSchema={PatientCreateFormProvider}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Drawer
          title="Criar Paciente"
          open={open}
          loading={isCreating}
          onClose={onClose}
          PaperProps={{ component: Form }}
          footer={
            <DrawerToolbar borderTop wide>
              <FormFooter loading={isCreating} handleReset={handleReset} />
            </DrawerToolbar>
          }
        >
          <Box margin="8px 16px">
            <FormBody loading={isCreating} />
          </Box>
        </Drawer>
      </Formik>
      {/* Snackbars */}
      <Snackbar
        severity="error"
        open={isError.open}
        title="Operação não realizada:"
        subtitle={isError.message}
      />
    </>
  );
};

const FormFooter = ({ loading, handleReset }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <DangerButtonOutline
        type="reset"
        onClick={handleReset}
        disabled={loading}
      >
        Limpar campos
      </DangerButtonOutline>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
      >
        Criar
      </Button>
    </Box>
  );
};

const FormBody = ({ loading }) => {
  const today = useMemo(() => moment(), []);

  return (
    <Box>
      <TextField
        variant="filled"
        name="id"
        label="Prontuário"
        placeholder="Prontuário"
        type="number"
        grid={{ xs: 15 }}
        disabled={loading}
      />
      <TextField
        variant="filled"
        name="name"
        label="Nome"
        placeholder="Nome"
        grid={{ xs: 15 }}
        disabled={loading}
      />
      <DateField
        label="Data de nascimento"
        name="birthDate"
        maxDate={today}
        grid={{ xs: 15 }}
        disabled={loading}
      />
      <RadioGroupField
        label="Sexo"
        name="sex"
        options={sex}
        grid
        disabled={loading}
      />
    </Box>
  );
};
