import moment from "moment"

/**
 * Recebe os dados do antimicrobiano e retorna uma label formatada
 * 
 * @param {Object} sign Dados do antimicrobiano
 * @returns {string} ex: '8:25 - VANCOMICINA 500 MG INJ FR - AMP 2,0000 EV - FRASCO AMPOLA'
 */
export const formatAntimicrobialsLabel = (item) => {
    return `${moment(item.dthr_prescricao).format('hh:mm')} - ${item.medicamento} ${item.dose} ${item.via} - ${item.unidade}`
}