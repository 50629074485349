import React, { useEffect, useMemo } from 'react';

/** LIBS **********************/
import moment from 'moment';
import reactStringReplace from 'react-string-replace';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { chunk, map, upperFirst } from 'lodash';

/** M-UI COMPONENTS**********************/
import { Box, Typography, withTheme, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { PatientEvolutionDateNavigation } from './PatientEvolutionDateNav';
/** SLICES **********************/
import {
  useTimelinePatientDetails,
  setTimelinePatientId,
  useTimelinePatientId,
  useTimelineActiveIndicator,
} from '../Timeline/slices';

/** SERVICES **********************/
import {
  useGetPatientQuery,
  useGetPatientEvolutionQuery,
} from '../../services';

/** STYLES **********************/
import useStyles, {
  EvolutionPageContainer,
  EvolutionPageDescription,
  EvolutionPageHeader,
  EvolutionPageTitle,
  MeetingReport,
  PatientNameBox,
} from './styles';

/** CONSTANTS **********************/
import {
  keywords,
  createRegexKeywords,
} from '../../components/atoms/TimelineChart/Tooltips/constants';
import { useQuery } from './utils';
import { parseTextoEncontro } from '../../utils/parseTextoEncontro';

const PatientEvolutionSkeleton = () => {
  const arr = new Array(14).fill(1);
  return (
    <Box marginTop="20px">
      <Grid container spacing={1}>
        <Box marginBottom="20px" paddingLeft="4px">
          <Grid container spacing={1}>
            <Grid item xs="auto">
              <Skeleton variant="rect" width={280} height={16} />
            </Grid>
            <Grid item xs="auto">
              <Skeleton variant="rect" width={280} height={16} />
            </Grid>
            <Grid item xs="auto" marginBottom="60px">
              <Skeleton variant="rect" width={280} height={16} />
            </Grid>
          </Grid>
        </Box>
        {arr.map((_, index) => (
          <Grid item xs="auto" key={index}>
            <Skeleton variant="rect" width={502} height={16} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

function PatientEvolution() {
  // Router parameters
  const { id } = useParams();
  const query = useQuery();
  const dt_encontro = moment(query.get('dt_encontro'));

  // Define o id do paciente da timeline
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTimelinePatientId(id));
  }, [dispatch, id]);

  const patientId = useTimelinePatientId();

  const {
    isLoading: isLoadingPatientQuery,
    isFetching: isFetchingPatientQuery,
  } = useGetPatientQuery(patientId, {
    skip: !patientId,
  });

  const {
    isLoading,
    isFetching,
    data: patientEvolutionData,
  } = useGetPatientEvolutionQuery(
    { patientId },
    {
      skip: !patientId,
      selectFromResult: (result) => {
        if (!result.isSuccess) return result;
        return {
          ...result,
          data: {
            allItems: result.data,
            itemsOfDate: result.data.filter((item) =>
              moment(item._source.dt_encontro).isSame(dt_encontro)
            ),
          },
        };
      },
    }
  );

  const patientDetails = useTimelinePatientDetails();

  /**
   * Adiciona highlight para as palavras-chaves
   * mapeadas na constante keyword
   */
  const setHighlight = (meeting) => {
    const terms = keywords;
    const text = {
      termos_texto: 'None',
      texto_evolucao_agg: meeting.texto_evolucao_agg
    }

    if (!text.termos_texto) return <></>;
    const regex = createRegexKeywords(terms);
    const indexedText = parseTextoEncontro(text);

    return (
      <>
        {map(indexedText, (value, key) => (
          <Box>
            <MeetingReport>
              <Box component="span" display="block">
                {upperFirst(key.replace(/^\[|\]$/g, '').toLowerCase())}
              </Box>
              <Box component="span" display="block">
                {moment(meeting.dt_encontro).format('DD/MM/YYYY, HH:mm')}
              </Box>
              <Box component="span" display="block">
                Local: {meeting.local_encontro}
              </Box>
            </MeetingReport>
            <MeetingReport>
              {reactStringReplace(value, regex, (match, i) => (
                <MeetingReport
                  key={'text.highlight.' + i}
                  variant="inherit"
                  display="inline"
                  {...(keywords.map(key => key.toLowerCase()).includes(match.toLowerCase()) && { highlight: true })}
                >
                  {match}
                </MeetingReport>
              ))}
            </MeetingReport>
          </Box>
        ))}
      </>
    );
  };

  return (
    <EvolutionPageContainer>
      <EvolutionPageHeader>
        <Box>
          <EvolutionPageTitle variant="h1">
            Evolução Completa
          </EvolutionPageTitle>
          <EvolutionPageDescription>
            {dt_encontro.format('DD/MM/YYYY')}
          </EvolutionPageDescription>
        </Box>
        <Box>
          {!isLoading && (
            <PatientEvolutionDateNavigation
              entries={patientEvolutionData?.allItems}
            />
          )}
        </Box>
      </EvolutionPageHeader>
      {patientDetails?.nome && (
        <PatientNameBox>
          <MeetingReport noMargin>Paciente {patientDetails.nome}</MeetingReport>
        </PatientNameBox>
      )}
      {(isLoading || isFetching) && <PatientEvolutionSkeleton />}
      {!isFetching &&
        patientEvolutionData?.itemsOfDate?.map((item) => (
          <>{setHighlight(item._source)}</>
        ))}
    </EvolutionPageContainer>
  );
}

export default withTheme(PatientEvolution);
