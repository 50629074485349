import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { Formik } from 'formik';
import moment from 'moment';

import {
  useGetSuspicionsQuery,
  useGetHospitalUnityQuery,
} from '../../../../services';

import { AssessmentAnalysisFormSchema } from './AssessmentAnalysisFormSchema';
import { formatSuspicionLabel } from '../../../../utils/formatters';
import {
  contaminationPotentialsEnum,
  infectionClassificationsEnum,
  infectionTypesEnum,
  noInfection,
  otherInfection,
} from '../../../../constants/infections';
import {
  assessmentStatusesEnum,
  assessmentRelatedDeathEnum,
} from '../../../../constants/assessments';
import { deathFromInfectionsEnum } from '../../../../constants/deathfrominfections';
import {
  microorganismsEnum,
  otherMicroorganism,
} from '../../../../constants/microorganisms';

export const AssessmentAnalysisFormContext = createContext({
  // Callbacks
  onSubmit: () => {},
  onCancel: () => {},
  onFinish: () => {},
  onEdit: () => {},
  // Estado da requisição
  requestTimestamp: 0,
  isLoading: false,
  isSuccess: false,
  isEditing: false,
  successResponse: undefined,
  isError: false,
  errorResponse: undefined,
  // Opções estáticas
  assessmentInfectionOptions: [],
  microorganismsOptions: [],
  communityInfectionOptions: [],
  assessmentStatusOptions: [],
  assessmentRelatedDeathOptions: [],
  relatedToCatheterOptions: [],
  relatedToCVCOptions: [],
  relatedToSVDOptions: [],
  resistantMicroorganismsOptions: [],
  // Suspicions (Opções dinâmicas)
  suspicions: [],
  suspicionsOptions: [],
  isLoadingSuspicions: false,
  errorLoadingSuspicions: false,
  // Locations (Opções dinâmicas)
  infectionLocationOptions: [],
  isLoadingLocations: false,
  errorLoadingLocations: false,
});
export const useAssessmentAnalysisFormContext = () =>
  useContext(AssessmentAnalysisFormContext);

const booleanRadioOptions = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
];

export const AssessmentAnalysisFormProvider = (props) => {
  const {
    children,
    onSubmit,
    onCancel,
    onFinish,
    requestTimestamp,
    isLoading,
    isSuccess,
    successResponse,
    isError,
    errorResponse,
    patientId,
    companyId,
    assessment,
    isEditing,
    onEdit,
  } = props;

  // Este handler é usado apenas pelo formik.
  // O submit dos dados do formulário é o método onSubmit recebido e utilizado no dialog de confirmação.
  const handleFormikSubmit = useCallback(() => {}, []);

  // Tipo de infeção
  const assessmentInfectionOptions = useMemo(
    () => [
      {
        value: infectionTypesEnum.ipcs,
        label: infectionTypesEnum.ipcs.toUpperCase(),
      },
      {
        value: infectionTypesEnum.isc,
        label: infectionTypesEnum.isc.toUpperCase(),
      },
      {
        value: infectionTypesEnum.itu,
        label: infectionTypesEnum.itu.toUpperCase(),
      },
      {
        value: infectionTypesEnum.pav,
        label: infectionTypesEnum.pav.toUpperCase(),
      },
      {
        value: infectionTypesEnum.pnm,
        label: infectionTypesEnum.pnm.toUpperCase(),
      },
      {
        value: infectionTypesEnum.traqueo,
        label: infectionTypesEnum.traqueo.toUpperCase(),
      },
      {
        value: infectionTypesEnum.comunitaria,
        label: 'INFECÇÃO COMUNITÁRIA',
      },
      { value: otherInfection, label: 'Outros' },
      { value: noInfection, label: 'Sem critério p/ infecção' },
    ],
    []
  );

  // Potencial de contaminação
  const contaminationPotentialOptions = useMemo(
    () => [
      { value: contaminationPotentialsEnum.limpa, label: 'Limpa' },
      {
        value: contaminationPotentialsEnum.potencialmente_contaminada,
        label: 'Potencialmente contaminada',
      },
      { value: contaminationPotentialsEnum.contaminada, label: 'Contaminada' },
      { value: contaminationPotentialsEnum.infectada, label: 'Infectada' },
      {
        value: contaminationPotentialsEnum.sem_informacoes_suficientes,
        label: 'Sem informações suficientes',
      },
    ],
    []
  );

  // Classificação da infecção
  const infectionClassificationOptions = useMemo(
    () => [
      { value: infectionClassificationsEnum.superficial, label: 'Superficial' },
      {
        value: infectionClassificationsEnum.profunda,
        label: 'Profunda',
      },
      {
        value: infectionClassificationsEnum.orgao_cavidade,
        label: 'Orgão/cavidade',
      },
      {
        value: infectionClassificationsEnum.sem_informacoes_suficientes,
        label: 'Sem informações suficientes',
      },
    ],
    []
  );

  // Microorganismos
  const microorganismsOptions = useMemo(
    () => [
      ...Object.entries(microorganismsEnum).map(([value, label]) => ({
        value,
        label,
      })),
      { value: otherMicroorganism, label: 'Outro' },
    ],
    []
  );

  // Janela de suspeita
  const {
    data: suspicions,
    isLoading: isLoadingSuspicions,
    error: errorLoadingSuspicions,
  } = useGetSuspicionsQuery(patientId);

  const suspicionsOptions = useMemo(
    () =>
      (suspicions || []).map((s) => {
        return {
          value: s._source.id,
          label: formatSuspicionLabel(s._source),
        };
      }),
    [suspicions]
  );

  // Local da infecção
  const {
    data: hospitalUnity,
    isLoading: isLoadingLocations,
    error: errorLoadingLocations,
  } = useGetHospitalUnityQuery(companyId);

  const locations = useMemo(
    () =>
      (hospitalUnity || []).map((location) => {
        const { unidades: value } = location._source;
        return { value, label: value };
      }),
    [hospitalUnity]
  );

  const infectionLocationOptions = useMemo(
    () => [
      { value: '', label: 'Selecione o local', disabled: true },
      ...locations,
    ],
    [locations]
  );

  // Opções booleanas (Sim/Não)
  const communityInfectionOptions = booleanRadioOptions;
  const relatedToCatheterOptions = booleanRadioOptions;
  const relatedToCVCOptions = booleanRadioOptions;
  const relatedToSVDOptions = booleanRadioOptions;
  const resistantMicroorganismsOptions = booleanRadioOptions;

  // Causa do óbito relacionado a infecção
  const assessmentRelatedDeathOptions = useMemo(
    () => [
      { value: deathFromInfectionsEnum.sim, label: 'Sim' },
      { value: deathFromInfectionsEnum.nao, label: 'Não' },
      { value: deathFromInfectionsEnum.naoAvaliado, label: 'Não Avaliado' },
    ],
    []
  );

  // Confirmação da análise
  const assessmentStatusOptions = useMemo(
    () => [
      { value: assessmentStatusesEnum.positiva, label: 'Confirmar' },
      { value: assessmentStatusesEnum.negativa, label: 'Descartar' },
      { value: assessmentStatusesEnum.inconclusiva, label: 'Inconclusivo' },
    ],
    []
  );

  // Valores iniciais do form
  const initialValues = useMemo(() => {
    const firstSuspicion = suspicions?.[0]?._source;
    const suspicionId = firstSuspicion ? firstSuspicion.id : '';
    const suspicionDateInfecction = firstSuspicion
      ? moment(firstSuspicion.dt_infeccao)
      : moment();

    const microorganisms = assessment?.microorganismos?.map((m) => ({
      microorganism: m.microorganismo_id,
      resistant: m.resistente,
    }));

    return {
      selectedSuspicion: firstSuspicion,
      suspicionId: suspicionId,
      otherInfection: assessment?.outraInfec || '',
      assessmentInfection: assessment?.tipoInfeccao || '',
      microorganisms: microorganisms?.length ? microorganisms : false,
      contaminationPotential: assessment?.potencialContaminacao || '',
      infectionClassification: assessment?.classificacaoInfeccao || '',
      suspicionDateInfecction:
        moment(assessment?.dtInfeccao) || suspicionDateInfecction,
      assessmentDateInfection:
        moment(assessment?.dtInfeccao) || suspicionDateInfecction,
      infectionLocation: assessment?.localInfeccao || '',
      assessmentStatus: assessment?.status || 'positiva',
      assessmentComment: assessment?.comentario || '',
      relatedToCatheter: assessment?.relacionadoCateterSonda || false,
      relatedToCVC: false,
      relatedToSVD: false,
      communityInfection: assessment?.comunitaria || false,
      assessmentRelatedDeath: assessment?.obitoRelacionadoAInfeccao || false,
    };
  }, [suspicions, assessment]);

  // Memoização do estado do contexto
  const contextState = useMemo(
    () => ({
      // Callbacks
      onSubmit,
      onCancel,
      onFinish,
      onEdit,
      // Estado da requisição
      requestTimestamp,
      isLoading,
      isSuccess,
      isEditing,
      successResponse,
      isError,
      errorResponse,
      // Opções estáticas
      assessmentInfectionOptions,
      microorganismsOptions,
      communityInfectionOptions,
      contaminationPotentialOptions,
      infectionClassificationOptions,
      assessmentStatusOptions,
      assessmentRelatedDeathOptions,
      relatedToCatheterOptions,
      relatedToCVCOptions,
      relatedToSVDOptions,
      resistantMicroorganismsOptions,
      // Suspicions (Opções dinâmicas)
      suspicions,
      suspicionsOptions,
      isLoadingSuspicions,
      errorLoadingSuspicions,
      // Locations (Opções dinâmicas)
      infectionLocationOptions,
      isLoadingLocations,
      errorLoadingLocations,
    }),
    [
      // Callbacks
      onSubmit,
      onCancel,
      onFinish,
      onEdit,
      // Estado da requisição
      requestTimestamp,
      isLoading,
      isSuccess,
      isEditing,
      successResponse,
      isError,
      errorResponse,
      // Opções estáticas
      assessmentInfectionOptions,
      microorganismsOptions,
      assessmentRelatedDeathOptions,
      communityInfectionOptions,
      contaminationPotentialOptions,
      infectionClassificationOptions,
      assessmentStatusOptions,
      relatedToCatheterOptions,
      relatedToCVCOptions,
      relatedToSVDOptions,
      resistantMicroorganismsOptions,
      // Suspicions (Opções dinâmicas)
      suspicions,
      suspicionsOptions,
      isLoadingSuspicions,
      errorLoadingSuspicions,
      // Locations (Opções dinâmicas)
      infectionLocationOptions,
      isLoadingLocations,
      errorLoadingLocations,
    ]
  );

  return (
    <AssessmentAnalysisFormContext.Provider value={contextState}>
      <Formik
        onSubmit={handleFormikSubmit}
        validationSchema={AssessmentAnalysisFormSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        <>{children}</>
      </Formik>
    </AssessmentAnalysisFormContext.Provider>
  );
};
