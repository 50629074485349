import React, { useState, useCallback, useMemo } from 'react';
import { DateRangeInput } from '../../components/molecules/Form';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core';

import { setDashboardPeriodDate } from '../../actions/DashboardPeriodFilterActions';
import clsx from 'clsx';

const today = moment();
export const selectStartDate = (state) => state.periods.dashboard.initialDate;
export const useStartDate = () => useSelector(selectStartDate);
export const useSetStartDate = () => {
  const dispatch = useDispatch();
  return useCallback((date) => dispatch(setDashboardPeriodDate(date, true)));
};
export const selectEndDate = (state) => state.periods.dashboard.finalDate;
export const useEndDate = () => useSelector(selectEndDate);
export const useSetEndDate = () => {
  const dispatch = useDispatch();
  return useCallback((date) => dispatch(setDashboardPeriodDate(date, false)));
};

export const DateRange = withStyles((theme) => ({
  root: {
    width: '296px'
  },
}))(function StyledDateRange({ classes, className }) {
  const startDate = useStartDate();
  const endDate = useEndDate();
  const setStartDate = useSetStartDate();
  const setEndDate = useSetEndDate();

  const minDate = undefined; // useMemo(() => moment(endDate).add({ months: -6 }), [endDate]);
  const maxDate = today; // useMemo(() => today, []);

  const [errorMessage, setError] = useState(null);
  const handleError = useCallback((err) => setError(err), [setError]);
  const handleChangeStartDate = useCallback(
    (date) => {
      setError(null);
      setStartDate(date);
    },
    [setError, setStartDate]
  );
  const handleChangeEndDate = useCallback(
    (date) => {
      setError(null);
      setEndDate(date);
    },
    [setError, setEndDate]
  );

  return (
    <DateRangeInput
      controlClasses={{ root: clsx(classes.root, className) }}
      error={!!errorMessage}
      helperText={errorMessage}
      bgcolor="white"
      margin="none"
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      maxDate={maxDate}
      onError={handleError}
      onChangeStartDate={handleChangeStartDate}
      onChangeEndDate={handleChangeEndDate}
    />
  );
});
