import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

export const PageTemplate = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutral.gray.xlt,
    minHeight: '100vh',
  },
  container: {
    width: '100%',
    maxWidth: '100%',
    paddingLeft: '88px',
    paddingRight: '24px',
    paddingBottom: '12px',
  },
}))(function StyledPageTemplate({ classes, className, children }) {
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.container}>{children}</div>
    </div>
  );
});
