import styled from 'styled-components'
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableCell from "@material-ui/core/TableCell";
import {Table, TableContainer, TableRow} from "@material-ui/core";

export const Header = styled.header`

`
export const Title = styled.h2`
  display: inline-block;
  margin-right: 8px;
    @media (max-width: 960px) {
      margin-bottom: 20px;
    }
`

export const StyledTableCell = withStyles({
  root: {
    fontFamily: "'DM Mono', monospace",
    fontStyle: 'normal',
    color: '#383A3D',
    background: '#FFFFFF',
    border: 'none',
    padding: '8px',
    borderRadius: '8px'
  },
  body: {
    fontSize: '13px',
    lineHeight: '17px',
    '& span': {
      fontFamily: "'DM Mono', monospace",
      fontSize: '13px',
    }
  },
  head: {
    fontFamily: "Nunito, monospace",
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '18px',
    background: 'none',
    padding: '10px 5px',
  }
})(TableCell);

export const StyledTableContainer = withStyles({
  root: {
    background: '#F5F5F5',
    borderRadius: '8px 8px 8px 8px',
    padding: '16px',
    boxShadow: 'none',
    position: 'relative',
    '& .MuiLinearProgress-root': {
      width: '100%',
      left: '0',
      top: '0',
      position: 'absolute',
    },
    '& h3': {
      fontWeight: 'bold',
      fontSize: '13px',
      color: '#383A3D',
      paddingLeft: '5px',
      paddingTop: '6px',
      paddingBottom: '16px',
      borderBottom: '1px solid rgba(143, 149, 163, 0.32)',
      marginBottom: '10px',
    }
  }
})(TableContainer);

export const StyledTableRow = withStyles({
  root: {
    border: 'none',
    borderBottom: '10px solid #F5F5F5',
    borderTop: '10px solid #F5F5F5',
    borderRadius: '8px 8px 8px 8px',
    position: 'relative',
    cursor: 'pointer',
    // boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16)',
    '&:before': {
      content: '',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      border: '20px solid #F5F5F5',
      borderRadius: '8px',
    }
  }
})(TableRow);

export const StyledTable = withStyles({
  root: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 'calc(100vh - 310px)',
    borderRadius: '8px 8px 8px 8px',
  }
})(Table);

const styles = makeStyles({
  textRight: {
    textAlign: 'right'
  },
  grayish: {
    color: '#8F95A3',
  },
  padding1: {
    paddingLeft: '6px',
  },
  paddingRight3: {
    paddingRight: '16px',
  },
  normalCursor: {
    cursor: 'default'
  }
});

export default styles;
