import {
  Paper as MuiPaper,
  withStyles,
  TextIn,
  withTheme,
  Chip,
} from '@material-ui/core';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { DeleteIcon } from './DeleteIcon';

export const Paper = withStyles((theme) => ({
  root: {
    borderRadius: '8px',
  },
}))(MuiPaper);
Paper.defaultProps = {
  elevation: 8,
};

export const Autocomplete = withStyles((theme) => ({
  option: {
    padding: '12px',
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
  },
  loading: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
  },
  noOptions: {
    fontFamily: 'DM Mono',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
  },
  root: {
    '& .MuiInput-root': {
      display: 'flex',
      flexWrap: 'wrap',
    },
    '& .MuiInput-input': {
      width: 'auto',
      flex: '1 1 auto',
    },
  },
}))(MuiAutocomplete);

export const Tag = withStyles((theme) => ({
  root: {
    fontFamily: theme.MuiTypography.family.secondary,
  },
  deleteIcon: {
    color: theme.palette.neutral.black[48],
  },
}))(Chip);
